import React, { useState } from 'react'
import { Button, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import moment from 'moment'

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import heic2any from 'heic2any'

function QaKontrol({ selectedKontrol }) {
    const [modalOpenImage, setmodalOpenImage] = useState(false)
    const [selectedImage, setselectedImage] = useState()
    const [blobData, setblobData] = useState()
    const [isBlob, setisBlob] = useState(false)
    const [loadingImage, setloadingImage] = useState(false)
    const normalizeStringSpace = (_str) => {
        var endSpace = /\s$/;
        var str = _str;
        return _str.replaceAll(" ", "%20")
        if (endSpace.test(str)) {
            window.console.error("ends with space");
            return _str
            //return false; //commented since snippet is throwing an error
        } else {
            return _str
        }
    }
    const replaceTagsUser = (word) => {
        if (!word || word == null) return word

        //console.log(word)
        let result = word.replace(/{nama}/gi, `<b>${selectedKontrol.dokul_user.first_name} ${selectedKontrol.dokul_user.last_name} </b>`)
        result = result.replace(/{sex}/gi, `<b>${selectedKontrol.dokul_user.gender}</b>`)
        // const _statusKawin=userData.accountinfo.gender=="Pria"?statusPria[userData.accountinfo.status_kawin]:status[userData.accountinfo.status]
        result = result.replace(/{status}/gi, `<b>${selectedKontrol.dokul_user.status_kawin}</b>`)
        let usia = moment().diff(selectedKontrol.dokul_user.tgl_lahir, 'years');
        result = result.replace(/{usia}/gi, `<b>${usia}</b>`)
        return result
    }
    const convertImage = async (url) => {

        fetch(`${process.env.REACT_APP_IMAGE_HOST}${url}`)
            .then(response => response.blob())
            .then(blob => new Promise(callback => {
                let reader = new FileReader();
                reader.onload = function () { callback(this.result) };
                reader.readAsDataURL(blob);
                console.log(reader)
                const resultImg = heic2any({
                    // required: the HEIF blob file
                    blob: blob,
                    // (optional) MIME type of the target file
                    // it can be "image/jpeg", "image/png" or "image/gif"
                    // defaults to "image/png"
                    toType: "image/jpeg",
                    // conversion quality
                    // a number ranging from 0 to 1
                    quality: 0.5
                }).then(res => {
                    setblobData(res)
                    setloadingImage(false)
                })

            }));


    }
    return (
        <div>
            <ListGroup>
                {selectedKontrol.qa.map((x, idx) => {
                    return <div key={idx} ><ListGroupItem color="success">
                        <p dangerouslySetInnerHTML={{ __html: x.dokul_robot_question ? replaceTagsUser(x.dokul_robot_question.pertanyaan) : "Tujuan kontrol" }} />
                    </ListGroupItem>
                        <ListGroupItem color="info">
                            <p dangerouslySetInnerHTML={{ __html: x.dokul_robot_answer?.jawaban }} />
                            {x.answer && x.answer != "" && !x.answer.includes("fakepath") && <p dangerouslySetInnerHTML={{ __html: x.answer }} />}
                            {x.image_url && <button className="btn btn-primary btn-xs" onClick={() => {
                                setblobData()
                                setloadingImage(true)
                                const imgSPlit=x.image_url.split("/")
                                const imageresult=`${imgSPlit[0]}/${imgSPlit[1]}/${imgSPlit[2]}/${imgSPlit[3]}/${imgSPlit[4]}/${selectedKontrol.dokul_user.email.trim()}/${imgSPlit[5]}`
                                if (x.image_url &&( x.image_url.toLowerCase().includes(".heic") ||  x.image_url.toLowerCase().includes(".heif"))) {
                                   
                                    setisBlob(true)
                                    convertImage(imageresult)
                                    setselectedImage(imageresult)
                                }
                                else{
                                    
                                    setselectedImage(imageresult)
                                }

                                setmodalOpenImage(true)
                            }}>view</button>}
                            {/* {!x.image_url && <button className='btn btn-xs btn-info' onClick={() => {
                                let msg = new SpeechSynthesisUtterance();
                                const regex = /(<([^>]+)>)/ig;
                                msg.text = x.answer ? x.answer.replace(regex, '') : x.dokul_robot_answer.jawaban.replace(regex, '')
                                msg.lang = 'id-ID';
                                window.speechSynthesis.speak(msg);
                            }}>suara</button>
                            } */}
                        </ListGroupItem>
                    </div>
                })}
               <ListGroupItem color="success">
                        <Button className='float-end'>Request Revisi Jawaban</Button>
                    </ListGroupItem>
            </ListGroup>

            <Modal isOpen={modalOpenImage} toggle={() => {
                
                setblobData()
                setisBlob(false)
                setmodalOpenImage(false)
                setselectedImage()
            }} size={selectedKontrol ? "xl" : "md"} centered >
                {!selectedKontrol && <ModalHeader className="bg-primary" toggle={() => {
                    
                    setblobData()
                    setisBlob(false)
                    setmodalOpenImage(false)
                    setselectedImage()
                }}></ModalHeader>}
                {selectedImage&&<ModalBody className="bg-light p-1" style={{ justifyContent: "center", alignItems: "center" }} >

                    

                    {!selectedKontrol && <InnerImageZoom src={`${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`} zoomSrc={`${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`} />}
                    {selectedKontrol && <TransformWrapper
                        initialScale={1}
                        wheel={{ wheelDisabled: true }}
                        // initialPositionX={100}
                        // initialPositionY={100}
                        // disabled
                        centerOnInit

                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <div className="tools m-2 border rounded">
                                    <button className='btn btn-outline btn-xs border m-1' onClick={() => zoomIn()}>+ Zoom In</button>
                                    <button className='btn btn-outline btn-xs border m-1' onClick={() => zoomOut()}>- Zoom Out</button>
                                    <button className='btn btn-info btn-xs m-1' onClick={() => resetTransform()}>Reset</button>
                                    <button className='btn btn-danger btn-xs m-1 float-end' onClick={() => {
                                        setselectedImage()
                                        setblobData()
                                        setisBlob(false)
                                        setmodalOpenImage(false)
                                    }}>CLose</button>
                                    {rest.state.scale}
                                </div>
                                {console.log(isBlob)}
                                <TransformComponent>
                                    <div className="d-flex justify-content-center flex-row">
                                        
                                        {blobData && !loadingImage && <img src={URL.createObjectURL(blobData)} alt="test" style={{ width: "100%", margin: "auto" }} />}
                                        {!loadingImage && !isBlob && <img src={`${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`} alt="test" style={{ width: "100%", margin: "auto" }} />}
                                    </div>
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                    }
                    {loadingImage && <div className="d-flex justify-content-center flex-column">
                        <div className="d-flex justify-content-center flex-row">
                            <Spinner className='self-align-center' animation="grow" variant="info" color='primary' title='sdfjhsdf' />
                        </div>
                        <Label className='text-center text-dark'>Load image from server</Label>
                    </div>}

                    {isBlob == false && <img style={{ display: loadingImage ? 'none' : 'none' }} onLoad={() => setloadingImage(false)} src={`${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`} className="img-fluid image_zoom_1 blur-up lazyloaded m-2" />}
                </ModalBody>}
            </Modal>
        </div>
    )
}

export default QaKontrol