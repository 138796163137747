
import React, { Component } from 'react';



export class Errorpage extends Component {

    render() {
        const {errorCode,errorMessage}=this.props
        return (
            <div className="page-wrap d-flex flex-row align-items-center" style={{minHeight:'100vh'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-1 d-block">{errorCode}</span>
                        <div className="mb-4 lead">{errorMessage}</div>
                        <a href="/" className="btn btn-link">Back to Home</a>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default Errorpage
