import React, { Component, Fragment } from 'react'

import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from 'moment'
import DatePicker from "react-datepicker";

import { Collapse, Button, CardBody, Card, Input, FormText, Modal, Form, Label, ModalBody } from 'reactstrap';
import Loading from '../common/loading';
const pointType = [
    { type: 11, desc: "Tambahan dari order", title: "Debit" },
    { type: 12, desc: "Tambahan manual", title: "Debit" },
    { type: 13, desc: "Tambahan dari migrasi", title: "Debit" },
    { type: 21, desc: "Pengurangan dari order", title: "Kredit" },
    { type: 23, desc: "Pengurangan dari migrasi", title: "Kredit" },
    { type: 3, desc: "Tambahan dari refund", title: "Debit" },
    { type: 4, desc: "Pengurangan dari expired", title: "Kredit" },
]
export class PointLogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: this.props.data.pointlogs.totalPages,
            loading: false,
            checkedValues: [],
            myData: this.props.data.pointlogs.PointLogs,
            isOpen: false,
            monthSelected: 0,
            dateSelected: 0,
            selectedDate: Date(),
            selectedBranch: 0,
            selectedstate: undefined,
            emailModal:false,
            emailSearch:undefined

        }
        this.fetchData = this.fetchData.bind(this);
        // this.handleStatusChange = this.handleStatusChange.bind(this);
    }

    // handleStatusChange(status) {
    //     console.log(status)
    //     this.setState({
    //         pages: this.props.data.pointlogs.totalPages,
    //         myData: this.props.data.pointlogs.PointLogs,
    //     });
    //   }

    async fetchData(state, instance) {

        // if(!instance){
        //     state=this.state.selectedstate
        // }

        this.setState({loading:true})

        let id;
        let date;
        let balik;
        let query = '';
        // let query='branch_id='+this.props.selectedBranch;

        state.filtered.forEach(element => {

            if (element.id == 'created_date') {
                var d = moment(element.value)
                var od = moment(this.state.selectedDate)
                if (d.date() == od.date())
                    balik = true;
                date = element.value
                query = `${query}${element.id}=${date}&`

            } else {
                //balik = true;
                query = `${query}&${element.id}=${element.value}&`
            }

        })
        if (balik) return
        if (this.state.selectedBranch != 0) {
            query = `${query}&${'branch_id'}=${this.state.selectedBranch}`
        }
        if(this.state.emailSearch){
            query = `${query}&${'email'}=${this.state.emailSearch}`
        }

        const t = await this.props.getNextData(state.pageSize, state.page, query)

        this.setState({
            myData: t.data.pointlogs.PointLogs,
            pages: t.data.pointlogs.totalPages,
            selectedDate: date,
            selectedstate: state,
            loading:false,
            emailModal:false,
            emailSearch:undefined
        })

        // console.log(t)
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        //this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        //   requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
        //     res => {
        //       // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        //       this.setState({
        //         // data: res.rows,
        //         // pages: res.pages,
        //         loading: false
        //       });
        //     }
        //   );
    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    openFilter = (i) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { selectedBranch, myData, pages, loading, isOpen, selectedDate } = this.state
        const columns = [];
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));

        // console.log(this.props)
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Order ID")}</b>,
        //         accessor: "id",
        //         filterable: true,
        //         Cell: (data) => {
        //             return '#' + data.original.id

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("ID")}</b>,
        //         accessor: "id",
        //         filterable: true,
        //         Cell: (data) => {
        //             return `#${data.original.id}`

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        columns.push(
            {
                Header: <b>{this.Capitalize("tanggal")}</b>,
                accessor: "created_date",
                //filterable: true,
                Cell: (data) => {
                    var d = moment(data.original.created_date)
                    return d.format("DD MMM YYYY");

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("ORDER")}</b>,
                accessor: "order_id",
                //filterable: true,
                Cell: (data) => {
                    var d = moment(data.original.created_date)
                    return `#${data.original.order_id?data.original.order_id:'-'}`

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Userid")}</b>,
                accessor: "user_id",
                filterable: true,
                Cell: (data) => {
                    return data.original.user_id

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("log type")}</b>,
                accessor: "log_type",
                width: 200,
                filterable: true,
                Filter: ({ filter, onChange }) => {

                    return <select className="form-control form-control-sm" value={filter ? filter.value : ""} onChange={event => {
                        this.setState({ selectedStatus: event.target.value })
                        onChange(event.target.value)

                    }
                    }>
                        <option selected>Semua</option>

                        {pointType.map(x => <><option className="border bg-success" value={x.type} >{x.title} - {x.desc}</option> </>)}

                    </select>
                },
                Cell: (data) => {
                    return <>
                        <span className={`rounded ${pointType.find(_pt => _pt.type == data.original.log_type)?.title == 'Kredit' ? 'bg-red' : 'bg-success'} p-1`}>{pointType.find(_pt => _pt.type == data.original.log_type)?.title}</span><br />
                        <FormText color="muted">{pointType.find(_pt => _pt.type == data.original.log_type)?.desc}</FormText>
                    </>
                    //return data.original.earnedpoints!=0?<span className="badge badge-success">Earned</span>:<span className="badge badge-info">Redeem</span>

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Sebelum")}</b>,
                //accessor: "order_status",
                Cell: (data) => {
                    return data.original.before_point
                    //return data.original.earnedpoints!=0?data.original.earnedpoints:data.original.redeempoints

                },
                style: {
                    textAlign: 'center'
                }
            });

        columns.push(
            {
                Header: <b>{this.Capitalize("Poin")}</b>,
                //accessor: "order_status",
                Cell: (data) => {
                    return data.original.point
                    //return data.original.earnedpoints!=0?data.original.earnedpoints:data.original.redeempoints

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Sesudah")}</b>,
                //accessor: "order_status",
                Cell: (data) => {
                    return data.original.after_point

                },
                style: {
                    textAlign: 'center'
                }
            });

        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("order date")}</b>,
        //         accessor: "created_date",
        //         filterable: true,
        //         Filter: ({ filter, onChange }) => (
        //             <Input
        //                 type="date"
        //                 name="date"
        //                 id="exampleDate"
        //                 placeholder="date placeholder"
        //                 onChange={event => {
        //                     console.log(event.target)
        //                     onChange(event.target.value)
        //                 }
        //                 }
        //                 value={filter ? filter.value : ""}
        //             />
        //             //             <DatePicker 
        //             //             selected={selectedDate}
        //             // onChange={date =>this.changeDate(date)}
        //             // dropdownMode="select"
        //             // />
        //         ),
        //         Cell:(row)=>{
        //             return moment(row.original.created_date).format("YYYY-MM-DD HH:mm:ss")
        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Total")}</b>,
        //         accessor: "grand_total",
        //         Cell: (data) => {
        //             return data.original.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Payment")}</b>,
        //         accessor: "payment_method_text",
        //         Cell: (data) => {
        //             const pay = data.original.payment_method_text.toLowerCase()
        //             return <span className={`badge badge-${pay.includes('bca') ? 'info' : pay.includes('mandiri') ? 'warning' : pay.includes('qris') ? 'primary' : 'light'}`}>{`${pay.includes('bca') ? 'BCA' : pay.includes('mandiri') ? 'MANDIRI' : pay.includes('qris') ? 'QRIS' : pay}`}</span>

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });


        return (
            <Fragment>
                <Loading show={loading} />
                <Modal isOpen={this.state.emailModal} centered toggle={()=>this.setState({emailModal:false})}>
                    <ModalBody className='bg-white' >
                    <Label>Input Email</Label>
                    <Input value={this.state.emailSearch} onChange={(e)=>{
                        this.setState({emailSearch:e.target.value})
                    }}></Input>
                    <Button className='text-center mt-2' onClick={() => this.fetchData(this.state.selectedstate)}>Submit</Button>
                    </ModalBody>
                </Modal>
                <div className="container-fluid">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">

                                <div className="card-body order-datatable">
                                    <div className="d-flex justify-content-between">
                                        {user && user.dokul_user_roles.some(x=>x.role_id==5 || x.role_id==4) && <div className="d-flex w-100">

                                            <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => this.setState({ selectedBranch: 1 }, () => this.fetchData(this.state.selectedstate))}>JAKARTA</div>
                                            <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() => this.setState({ selectedBranch: 2 }, () => this.fetchData(this.state.selectedstate))}>BALIKPAPAN</div>
                                            <div className={`p-2 mr-auto  pointer ${selectedBranch == 0 ? 'bg-primary' : ''}`} onClick={() => this.setState({ selectedBranch: 0 }, () => this.fetchData(this.state.selectedstate))}>ALL</div>
                                            <div className={`p-2`}><Button onClick={()=>this.setState({emailModal:true})}>Find By Email</Button></div>
                                        </div>}


                                    </div>
                                    <ReactTable
                                        data={myData}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className={"-striped -highlight"}
                                        showPagination={pagination}
                                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                        pages={pages} // Display the total number of pages
                                        loading={loading} // Display the loading overlay when we need it
                                        onFetchData={this.fetchData} // Request new data when things change
                                        sorted={this.state.sorted}
                                        onSortedChange={(newSort, column) => {
                                            this.setState({ sorted: newSort });
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PointLogs
