import React, { Component, Fragment } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';

import moment from 'moment'
export class Reservasi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            imageHasChange: false,
            myData: this.props.data.reservasis.data,
            file: user,
            selectedService: null
        };
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    onDelete = () => {

    }
    onEdit = (cat) => {
        this.setState({
            selectedService: cat
        }, () => this.onOpenModal())
    }
    handleChange = (e) => {
        const { name, value } = e.target
        let selectedService = this.state.selectedService
        selectedService[name] = value
        this.setState({
            selectedService: selectedService
        })
        console.log(selectedService)
    }
    saveService = () => {
        let newService = this.state.selectedService
        if (this.state.imageHasChange) {
            const formData = new FormData()
            formData.append('uploaded_file', this.state.file, `${newService.name}_${this.state.file.name}`)


            fetch('http://localhost:9000/api/upload', {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    console.log(data)
                    newService.picture = `/assets/images/uploads/${data.d[0].filename}`
                    newService.deskripsi = newService.name
                    // newService.type = 2
                    newService.banner = `/assets/images/uploads/${data.d[0].filename}`
                    const t = this.props.saveService({
                        variables: {
                            data: newService
                        },
                        onCompleted: (saveService) => {
                            console.log("Sukses")
                            // history.push('/products');
                        }, onError: ({ graphQLErrors, networkError }) => {
                            console.log("ada eror")
                            console.log(networkError)
                            console.log(graphQLErrors)
                        },
                    }).then(data => {
                        // console.log(data) 
                        this.onCloseModal()
                    })

                })

                .catch(error => {
                    console.error(error)
                })
        } else {
            const t = this.props.saveService({
                variables: {
                    data: newService
                },
                onCompleted: (saveService) => {
                    console.log("Sukses")
                    // history.push('/products');
                }, onError: ({ graphQLErrors, networkError }) => {
                    console.log("ada eror")
                    console.log(networkError)
                    console.log(graphQLErrors)
                },
            }).then(data => {
                // console.log(data) 
                this.onCloseModal()
            })

        }



    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    renderImage = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                suppressContentEditableWarning
                dangerouslySetInnerHTML={{
                    __html: `<img src=${process.env.REACT_APP_IMAGE_HOST}${this.state.myData[cellInfo.index][cellInfo.column.id]} style="width:50px;height:50px;"/>`
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    changeImageContent = (e, showmodal) => {
        let selectedService = this.state.selectedService
        let reader = new FileReader();
        let file = e.target.files[0];




        reader.onloadend = () => {
            selectedService.picture = reader.result;
            this.setState({
                imageHasChange: true,
                file: file,
                selectedService: selectedService
            })

        }
        reader.readAsDataURL(file)

    }


    render() {

        const { open, selectedService, myData, imageHasChange } = this.state
        const { saveCategory } = this.props
        console.log(this.props.data)
        const columns = [];
        const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
        columns.push(
            {
                Header: <b>{this.Capitalize("waktu reservasi")}</b>,
                accessor: "create_date",
                Cell: (cellInfo) => moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("waktu layanan")}</b>,
                accessor: "date_selected",
                width: 100,
                Cell: (cellInfo) => moment(cellInfo.original.date_selected).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'center',
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Customer")}</b>,
                accessor: "fullName",
                Cell: (cellInfo) => cellInfo.original.fullName,
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("phone")}</b>,
                accessor: "phone",
                //Cell: (cellInfo) => status[cellInfo.original.status],
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("layanan")}</b>,
                accessor: "nama_layanan",
                //Cell: (cellInfo) => cellInfo.original.status == 1 ? 'Oleh dokter' : 'Oleh perawat',
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("klinik")}</b>,
                //accessor: "klinik",
                Cell: (cellInfo) => cellInfo.original.dokul_klinik.name,
                style: {
                    textAlign: 'center'
                }
            });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("klinik")}</b>,
        //         accessor: "picture",
        //         width: 300,
        //         Cell: (cellInfo) => {
        //             return <ListGroup>{cellInfo.original.dokul_kliniks.map(kl => {
        //                 return <ListGroupItem className="justify-content-between">{kl.name}</ListGroupItem>
        //             })}
        //             </ListGroup>
        //         },
        //         style: {
        //             textAlign: 'center',

        //         }
        //     });
        columns.push(
            {
                Header: <b>Action</b>,
                id: 'delete',
                width: 100,
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <span onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this item?')) {
                                let data = myData;
                                data.splice(row.index, 1);
                                this.setState({ myData: data });
                                toast.success("Successfully Deleted !")
                            }
                            //toast.success("Successfully Deleted !")

                        }}>
                            <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }} onClick={this.onDelete}
                            ></i>
                        </span>

                        <span onClick={() => {
                            this.setState({
                                selectedService: row.original
                            }, () => this.onOpenModal())

                        }}>
                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                        </span>



                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )

        return (
            <Fragment>
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Reservasi layanan</h5>
                                </div>
                                <div className="card-body">
                                    <div className="btn-popup pull-right">

                                        {/* <button type="button" className="btn btn-primary" onClick={() => {
                                            this.setState({
                                                selectedService: {picture:user,dokul_kliniks:[],type:1,imageHasChange:false}
                                            }, () => this.onOpenModal())
                                        }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Add Service</button> */}
                                        {!selectedService?"":<Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{selectedService.id ? 'Reservasi detail' : 'Add service'}</h5>
                                            </div>
                                            <div className="modal-body">
                                                <div className="card" >

                                                    <div className="card-body row">
                                                        <div className="col-4">
                                                            <img src={`${process.env.REACT_APP_IMAGE_HOST}${selectedService.dokul_service.picture}`}/>
                                                        </div>
                                                        <div className="col-8">
                                                            <h2 className="card-title">{selectedService?.nama_layanan}</h2>
                                                            <p className="card-text">{selectedService?.dokul_klinik?.name}</p>
                                                            <p className="card-text">{selectedService?.dokul_klinik?.address}</p>

                                                            <dl class="row">
                                                            <dt className="col-sm-4">Tanggal reservasi</dt>
                                                            <dd className="col-sm-8">{selectedService?.create_date}</dd>
                                                            <hr />

                                                            <dt className="col-sm-4">Tanggal layanan</dt>
                                                            <dd className="col-sm-8">{selectedService?.date_selected}</dd>
                                                            <hr />

                                                            <dt className="col-sm-4">Nama</dt>
                                                            <dd className="col-sm-8">{selectedService?.fullName}</dd>
                                                            <hr />

                                                            <dt className="col-sm-4">Status pasien</dt>
                                                            <dd className="col-sm-8">{selectedService?.status_pasien==1?'Pasien Baru':'Pasien Lama'}</dd>
                                                            <hr />

                                                            <dt className="col-sm-4 text-truncate">Email</dt>
                                                            <dd className="col-sm-8">{selectedService?.email}</dd>
                                                            <hr />

                                                            <dt className="col-sm-4">Phone</dt>
                                                            <dd className="col-sm-8">{selectedService?.phone}</dd>
                                                            <hr />

                                                        </dl>
                                                        </div>
                                                        
                                                    </div>

                                                    
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                {/* <button type="button" className="btn btn-primary" onClick={this.saveService}>Save</button> */}
                                                <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button>
                                            </div>
                                        </Modal>}
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">
                                        {/* <Datatable
                                            onDelete={this.onDelete}
                                            editLink={"/services/edit/"}
                                            onEdit={this.onEdit}
                                            multiSelectOption={false}
                                            myData={this.props.data.layanans}
                                            hideFields={['__typename', 'id', 'banner', 'type', 'dokul_kliniks', 'dokul_content_rows', 'deskripsi', 'dokul_page']}
                                            listFields={[]}
                                            pageSize={10}
                                            pagination={true}
                                            class="-striped -highlight"
                                        /> */}
                                        <ReactTable
                                            data={myData}
                                            columns={columns}
                                            defaultPageSize={10}
                                            className={"-striped -highlight"}
                                            showPagination={true}
                                        />
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Reservasi

