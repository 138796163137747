import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings } from 'react-feather'

export class Tabset_profile extends Component {
    render() {
        const { dataproduk, datakeranjang, setKranjang, filterProduct, dataLayanans,dataUser,setSelectedProductDrag } = this.props
        return (
            <div>
                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                        <Tab className="nav-link"><User className="mr-2" />Profile</Tab>
                        <Tab className="nav-link"><Settings className="mr-2" />Product</Tab>
                        <Tab className="nav-link"><User className="mr-2" />Layanan</Tab>
                        <Tab className="nav-link"><Settings className="mr-2" />Konsultasi</Tab>
                        <Tab className="nav-link"><Settings className="mr-2" />Klinik</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="tab-pane fade show active">
                            <h5 className="f-w-600 f-16">Profile</h5>
                            <div className="table-responsive profile-table">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>First Name:</td>
                                            <td>{dataUser?.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Last Name:</td>
                                            <td>{dataUser?.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Display Name:</td>
                                            <td>{dataUser?.display_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td>{dataUser?.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Grade:</td>
                                            <td>{dataUser?.current_grade}</td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Number:</td>
                                            <td>{dataUser?.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Jenis Kulit:</td>
                                            <td>{dataUser?.jenis_kulit}</td>
                                        </tr>
                                        <tr>
                                            <td>Warna Kulit:</td>
                                            <td>{dataUser?.warna_kulit}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        {/* <div className="tab-pane fade"> */}
                        <hr />
                        <div className="headind_srch">
                            <div className="recent_heading">
                                <h4>Data Produk</h4>
                            </div>
                            <div className="srch_bar">
                                <div className="stylish-input-group">
                                    <input type="text" className="search-bar" placeholder="Search" onChange={filterProduct} />
                                    <span className="input-group-addon">
                                        <button type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                                    </span> </div>
                            </div>
                        </div>
                        <div className="data-produk-konsul">
                            <div className="row">
                                {dataproduk.items.map(kr => {
                                    return <div className="col-md-6" 
                                    onClick={() => {
                                        var newIt = datakeranjang.items
                                        const idx = datakeranjang.items.indexOf(kr)
                                        if (datakeranjang.items.includes(kr))
                                            newIt.splice(idx, 1)
                                        else
                                            newIt.push(kr)
                                        setKranjang({ items: newIt })

                                    }}>
                                        <div style={datakeranjang.items.includes(kr) ? { backgroundColor: "gainsboro", margin: 5, padding: 5, textAlign: "center", cursor: "pointer" } : { backgroundColor: "unset", cursor: "pointer", margin: 5, padding: 5, textAlign: "center" }}>


                                            <img draggable onDrag={(event) =>{setSelectedProductDrag({prod:kr,type:2})}} src={`${process.env.REACT_APP_IMAGE_HOST}${kr.dokul_product_images[0]?.image_url}`} height="100px" alt="" /><br />
                                            <span className="text-center">{kr.name}</span>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        {/* </div> */}
                    </TabPanel>
                    <TabPanel>
                        {/* <div className="tab-pane fade"> */}
                        <hr />

                        <div className="data-produk-konsul">
                            <div className="row">
                                {dataLayanans.map(ly => {
                                    return <div className="col-md-6" onClick={() => {


                                    }}>
                                        <div style={datakeranjang.items.includes(ly) ? { backgroundColor: "gainsboro", margin: 5, padding: 5, textAlign: "center", cursor: "pointer" } : { backgroundColor: "unset", cursor: "pointer", margin: 5, padding: 5, textAlign: "center" }}>


                                            <img draggable onDrag={(event) =>{setSelectedProductDrag({prod:ly,type:3})}} src={`${process.env.REACT_APP_IMAGE_HOST}${ly.picture}`} height="100px" alt="" /><br />
                                            <span className="text-center">{ly.name}</span>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        {/* </div> */}
                    </TabPanel>
                    <TabPanel>
                        {/* <div className="tab-pane fade"> */}
                        <hr />

                        <div className="data-dokter-konsul">

                            {this.props.data.dokters.map(item => {
                                return <div className="row" >
                                    {/* <div className="col-md-6" onClick={() => {
                                                

                                            }}> */}
                                    <div className="card mb-3" style={{ maxWidth: 540 }}>
                                        <div className="row no-gutters">
                                            <div className="col-md-4 text-center">
                                                <img src={`${process.env.REACT_APP_IMAGE_HOST}${item.picture}`} alt="..." style={{ margin: 10 }} />
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-body">
                                                    <h5 className="card-title">{item.fullname}</h5>
                                                    <ul className="list-group">
                                                        
                                                        <li className="list-group-item">Konsultasi online <span className={`badge badge-${item.dokul_dokter_konsultasis.find(t=>t.type==2)?'success':'cred'}`}>{item.dokul_dokter_konsultasis.find(t=>t.type==1)?'AVAILABLE':'UNAVAILABLE'}</span></li>
                                                        <li className="list-group-item">Konsultasi clinic <span className={`badge badge-${item.dokul_dokter_konsultasis.find(t=>t.type==1)?'success':'cred'}`}>{item.dokul_dokter_konsultasis.find(t=>t.type==1)?'AVAILABLE':'UNAVAILABLE'}</span></li>
                              
                                                    </ul>

                                                    <p className="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div style={datakeranjang.items.includes(item) ? { backgroundColor: "gainsboro", margin: 5, padding: 5, textAlign: "center", cursor: "pointer" } : { backgroundColor: "unset", cursor: "pointer", margin: 5, padding: 5, textAlign: "center" }}>


                                                    <img src={`${process.env.REACT_APP_IMAGE_HOST}${item.picture}`} height="100px" alt="" /><br />
                                                    <span className="text-center">{item.fullname}</span>
                                                </div> */}
                                    {/* </div> */}
                                </div>
                            })}

                        </div>
                        {/* </div> */}
                    </TabPanel>
                    <TabPanel>
                        {/* <div className="tab-pane fade"> */}
                        <hr />

                        <div className="data-dokter-konsul">

                            {this.props.data.kliniks.map(item => {
                                return <div className="row" >
                                    {/* <div className="col-md-6" onClick={() => {
                                                

                                            }}> */}
                                    <div className="card mb-3" style={{ maxWidth: 540 }}>
                                        <div className="row no-gutters">
                                            <div className="col-md-4 text-center">
                                                <img src={`${process.env.REACT_APP_IMAGE_HOST}${item.banner}`} alt="..." style={{ margin: 10 }} />
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-body">
                                                    <h5 className="card-title">{item.name}</h5>
                                                    <ul className="list-group">
                                                        
                                                       
                                                    </ul>

                                                    <p className="card-text"><small class="text-muted">{item.address}</small></p>
                                                    <p className="card-text"><small class="text-muted">{item.tlp}</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div style={datakeranjang.items.includes(item) ? { backgroundColor: "gainsboro", margin: 5, padding: 5, textAlign: "center", cursor: "pointer" } : { backgroundColor: "unset", cursor: "pointer", margin: 5, padding: 5, textAlign: "center" }}>


                                                    <img src={`${process.env.REACT_APP_IMAGE_HOST}${item.picture}`} height="100px" alt="" /><br />
                                                    <span className="text-center">{item.fullname}</span>
                                                </div> */}
                                    {/* </div> */}
                                </div>
                            })}

                        </div>
                        {/* </div> */}
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default Tabset_profile
