import React, { Component, Fragment, forwardRef, useState, useMemo, useEffect, useCallback } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import { Col, Collapse, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../../common/datatable';
import ReactTable from 'react-table';

// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import CKEditors from "react-ckeditor-component";
import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { GET_DOKTERS, listMasalah, GET_KLINIKS, GET_LIST_KONSULTASI_EKSPRESS, GET_LIST_KONSULTASI_KONTROL, GET_LIST_SUBMIT_KONSULTASI, GET_RESERVASI, GET_SLOT_KONSULTASI, UPDATE_DOKTER, GET_PRODUCTS, GET_ROLES, UPDATE_USER_ROLE, UPDATE_USER_KONTROL, GET_CONFIGS, GET_PRODUCTS_SIMPLE } from '../../../constants/graph';
import data from '../../../assets/data/physical_list';
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { withApollo } from '../../../apollo'
import ReactImageMagnify from 'react-image-magnify';
import TableView from './table.view';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CreateProductKhusus from './create.produk'
import { updateSlotKonsul } from "../../../provider/socket/emits";
import Ckinput from './ckinput';
import HistoryKontrol from './History.kontrol';
import QaKontrol from './Qa.kontrol';
import DetailPasien from './Detail.pasien';
import RekomendasiProduk from './Rekomendasi.produk';
import ResultKontrol from './Result.kontrol';
import PermintaanTambahan from './request.tambahan'

registerLocale('id', es)
moment.locale('id')
function DetailKontrol(props) {
    const [updateUserRoles] = useMutation(UPDATE_USER_ROLE)
    const [dataKonsul, setdataKonsul] = useState(props.dataKonsul)
    const [createProduct, setcreateProduct] = useState(false)
    const [loadingImage, setloadingImage] = useState(false)
    const [loadingSubmit, setloadingSubmit] = useState(false)
    const { loading: lProduct, data: dProduct, error: eProduct } = useQuery(GET_PRODUCTS_SIMPLE,{
        variables:{
            brand:'all',
            txtSearch:'all'
        }
    });
    const { loading: lConfig, data: dConfig, error: eConfig } = useQuery(GET_CONFIGS);
    const [modalOpenChangeRole, setModalOpenChangeRole] = useState(false)
    const [colapseFilter, setcolapseFilter] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date())
    const [page, setpage] = useState(0)
    const [totalPages, settotalPages] = useState(0)
    const [pageSize, setpageSize] = useState(10)
    const [graphType, setgraphType] = useState(2)
    const [keperluan, setkeperluan] = useState(3)
    const [branchId, setbranchId] = useState(props.branch_id)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpenImage, setmodalOpenImage] = useState(false)
    const [selectedImage, setselectedImage] = useState()
    const [content, setContent] = useState("")
    const [content2, setContent2] = useState("")

    const changeContent=useCallback(
      (val) => {
        setContent(val)
      },
      [content],
    )
     
    // console.log("RENDERRRRR")

    const [contentUserRekamMedis, setcontentUserRekamMedis] = useState(null)

    const [selectedKontrol, setselectedKontrol] = useState(props.selectedKontrol)
    const [selectedProduct, setselectedProduct] = useState([])
    const [modalProduk, setmodalProduk] = useState(false)
    const [textSeach, settextSeach] = useState("")
    const [stateQuery, setstateQuery] = useState([])
    const status = ['', 'Menunggu pembayaran', 'Upload struk', 'Belum dijawab', 'Sudah dijawab', '', '', '', '', 'Batal oleh system']
    const [statusSelected, setstatusSelected] = useState(0)

    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const [jawaban1, setjawaban1] = useState(true)
    const [jawaban2, setjawaban2] = useState(true)
    const { loading, error, data } = useQuery(GET_SLOT_KONSULTASI)
    const { loading: lRoles, error: eRoles, data: dRoles } = useQuery(GET_ROLES)
    const [requestParam, setrequestParam] = useState("")
    const [editedValue, seteditedValue] = useState({})
    // const { loading: lKonsulEks, error: eKonsulEks, data: dKonsulEks } = useQuery(GET_LIST_KONSULTASI_KONTROL)
    const [getDataSubmit, { loading: lSubmitKonsul, error: eSubmitKonsul, data: dSubmitKonsul, refetch }] = useLazyQuery(GET_LIST_KONSULTASI_KONTROL, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setpage(data.submitkontrol.currentPage)
            settotalPages(data.submitkontrol.totalPages)
            // setlistData(data.submitkontrol.data)
        }
    })

    useEffect(() => {

        fetchData()
    }, [keperluan, branchId, page, pageSize, statusSelected])

    const updateCache = (cache, { data }) => {
        // console.log(data)
        const _updatedKontrol = { ...data.updateSubmitKontrol }

        const fragmentResult = cache.writeFragment({
            id: `SubmitKontrol:${_updatedKontrol.id}`,
            fragment: gql`
        fragment submitkontrol on SubmitKontrol{
            id
            status  
        }
      `,
            data: _updatedKontrol
        });
    };
    const [updateStatusKonsul] = useMutation(UPDATE_USER_KONTROL, { update: updateCache })
    // const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });


    // const selectedDokter = client.readFragment({
    //     id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment readDokter on Dokter{
    //         id
    //         dokul_dokter_konsultasis
    //         fullname
    //         picture
    //         picture
    //         available
    //       }
    //     `,
    // }, false);
    // const { loading: loadingDokter, error: errorDokter, data: dataDokter } = useQuery(GET_DOKTERS)
    async function getQ_A(_id) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolQa/${_id}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            ///body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    async function updateKonsultasi(_data) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/updateKonsultasi/${_data.id}`, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(_data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    async function getHistory(_id) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolHistory/${_id}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            ///body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    async function submitControlResult(_id, data, resultText = false, answerQuestion = false, medicalNote = false, product = false) {
        // console.log(data)
        // console.log(selectedKontrol)
        // return
        setloadingSubmit(true)
        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolResult/${_id}`, {
            method: selectedKontrol.status > 3 ? 'PUT' : 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        setloadingSubmit(false)
        props.fetchData()
        return response.json(); // parses JSON response into native JavaScript objects
    }
    async function updateControlResult(_id, data, resultText = false, answerQuestion = false, medicalNote = false, product = false, note = false, refund = false, marked = false) {
        // console.log("update")
        // console.log(data)
        // return
        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/updateResult/${_id}`, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.

            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                "resultText": resultText,
                "answerQuestion": answerQuestion,
                "medicalNote": medicalNote,
                "product": product,
                "note": note,
                "refund": refund,
                "marked": marked,
                "idResult": selectedKontrol.result.dokul_konsultasi_kontrol_result?.id
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        props.fetchData()
        return response.json(); // parses JSON response into native JavaScript objects
    }
    const fetchData = (_state, instance) => {
        const state = _state ? _state : stateQuery
        // console.log(state)
        let param = `page=${page}&size=${pageSize}&`
        let email
        if (state && Array.isArray(state)) state.forEach(element => {
            if (element.id == 'email') email = element.value
            param = param + element.id + '=' + element.value + '&'
        })
        param = param + `branch_id=${branchId}&masalah=${keperluan == 2 ? 'rutin' : keperluan == 1 ? 'iritasi' : keperluan == 4 ? 'perdana' : ''}&status=${statusSelected}&startDate=${startDate}&startDate=${endDate}`
        //setstateQuery(state)
        setrequestParam(param)
        // getDataSubmit({
        //     variables: {
        //         page: 0,
        //         size: 10,
        //         keperluan: keperluan,
        //         email: email,
        //         params: `${param}`

        //     }
        // })

    }
    const refetchData = () => {
        const param = `page=${page}&size=${pageSize}&branch_id=${branchId}&masalah=${keperluan == 2 ? 'rutin' : keperluan == 1 ? 'iritasi' : ''}&status=${statusSelected}&startDate=${startDate}&startDate=${endDate}`
        //setstateQuery(state)
        // getDataSubmit({
        //     variables: {
        //         page: 0,
        //         size: 10,
        //         keperluan: keperluan,
        //         email: undefined,
        //         params: `${requestParam}`

        //     }
        // })

    }
    useEffect(() => {
        fetchData()

    }, [])
    useEffect(() => {
        if (selectedKontrol) {
            setModalOpen(true)
        }
    }, [selectedKontrol])
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }
    const handelValueChange = (e) => {
        const { value, name } = e.target
        let _c = stateQuery.find(x => x.id == name)
        if (!_c) {
            setstateQuery(x => [...x, { id: name, value: value }])
        } else {

            setstateQuery(x => {
                const _idx = x.findIndex(y => y.id == name)
                x.splice(_idx, 1)
                return [...x, { id: name, value: value }]
            })

        }

    }
    const sleep = () => {
        return new Promise(resolve => setTimeout(resolve, 1000))
      }
    return (
        <div className="modal-content bg-white p-2">
            <div className="modal-body">

                {selectedKontrol && <Row className="bg-white">
                    {/* <Col sm={12}>
                        <h4 className="bg-primary p-2 "> Riwayat konsul pasien <span className="float-end"><i className="close" /></span></h4>
                        <div className="d-flex flex-row flex-wrap">
                            {selectedKontrol.history.map((x, idx) =>
                                <Badge key={idx} className={`border p-2 m-2 ${x.id == selectedKontrol.id ? "bg-primary" : "bg-light text-dark"}`} onClick={async () => {
                                    const _dataQa = await getQ_A(x.id)
                                    // const _dataHistory = await getHistory(row.original.user_id)

                                    // console.log(_dataQa)
                                    let _selectedKontrol = { ...x, dokul_user: selectedKontrol.dokul_user }
                                    _selectedKontrol["qa"] = _dataQa ? _dataQa.qa : []
                                    _selectedKontrol["result"] = _dataQa ? _dataQa.result : {}
                                    _selectedKontrol["history"] = selectedKontrol.history
                                    if (_dataQa.result) {
                                        setContent(_dataQa.result.dokul_konsultasi_kontrol_result?.result_text)
                                        const _cproduct = _dataQa.result.dokul_konsultasi_kontrol_result_produks.map(x2 => {
                                            return dProduct.products.find(p => p.id == x2.produk_id)
                                        })
                                        setselectedProduct(_cproduct)
                                    }
                                    setselectedKontrol(_selectedKontrol)
                                    // setselectedKontrol({ ...x, history: selectedKontrol.history })
                                }}>{moment(x.created_date).format("DD MMMM YYYY")}</Badge>
                            )}


                        </div>
                    </Col> */}
                    
                    <Col sm={7}>
                        <h4 className="bg-primary p-2 "> pertanyaan & jawaban</h4>

                        {/* <ListGroup>
                            {selectedKontrol.qa.map((x, idx) => {
                                return <div key={idx} ><ListGroupItem color="success">
                                    <p dangerouslySetInnerHTML={{ __html: x.dokul_robot_question ? x.dokul_robot_question.pertanyaan : "Tujuan kontrol" }} />
                                </ListGroupItem>
                                    <ListGroupItem color="info">
                                        <p dangerouslySetInnerHTML={{ __html: x.dokul_robot_answer?.jawaban }} />
                                        {x.answer && x.answer != "" && !x.answer.includes("fakepath") && <p dangerouslySetInnerHTML={{ __html: x.answer }} />}
                                        {x.image_url && <button className="btn btn-primary btn-xs" onClick={() => {
                                            setloadingImage(true)
                                            setselectedImage(x.image_url)
                                            setmodalOpenImage(true)
                                        }}>view</button>}
                                        {!x.image_url && <button className='btn btn-xs btn-info' onClick={() => {
                                                    let msg = new SpeechSynthesisUtterance();
                                                    const regex = /(<([^>]+)>)/ig;
                                                    msg.text = x.answer ? x.answer.replace(regex, '') : x.dokul_robot_answer.jawaban.replace(regex, '')
                                                    msg.lang = 'id-ID';
                                                    window.speechSynthesis.speak(msg);
                                                }}>suara</button>
                                                }
                                    </ListGroupItem>
                                </div>
                            })}
                        </ListGroup> */}
                        <QaKontrol selectedKontrol={selectedKontrol} />

                    </Col>
                    <Col sm={5}>
                        <h4 className="bg-primary p-2 ">info pasien</h4>
                        
                       <DetailPasien selectedKontrol={selectedKontrol} updateControlResult={updateControlResult} dRoles={dRoles} dProducts={dProduct.productssimple}/>
                       <HistoryKontrol selectedKontrol={selectedKontrol} setselectedKontrol={setselectedKontrol} />
                       <PermintaanTambahan selectedKontrol={selectedKontrol} />
                        <ResultKontrol ansTemplate={props.ansTemplate} dConfig={props.dConfig} selectedKontrol={selectedKontrol} submitControlResult={submitControlResult} updateControlResult={updateControlResult} refetchData={refetchData} setModalOpen={props.setModalOpenRoot} selectedProductRoot={props.selectedProduct} />

                        <div className="mt-2"><button className={`btn btn-light w-100`} onClick={async () => {


props.setModalOpenRoot(false)
                        }}>Close</button> </div>
                    </Col>
                    {/* <Col>
                            <h4 className="bg-primary p-2 ">hasil dari dokter</h4>
                        </Col> */}
                </Row>}
            </div>
            {/* <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let _dokter = { ...data.templates[idxDokter] }
                            let _dokul_dokter_konsultasis = [..._dokter.dokul_dokter_konsultasis]
                            let _currentKonsultasi = { ..._dokul_dokter_konsultasis[idxKlinik] }
                            let currentJadwals = [..._currentKonsultasi.dokul_dokter_jadwals]

                            currentJadwals.push({
                                id_konsultasi: _currentKonsultasi.id,
                                name: jadwalBaru.name,
                                tanggal: moment(tgl).format("YYYY/MM/DD"),
                                jumlah_sesi: jadwalBaru.jumlah_sesi,
                                terpakai: 0,

                            })


                            _dokter['dokul_dokter_jadwal'] = currentJadwals
                            updateDokter({ variables: { id: 1, type: _dokter }, })


                            setModalOpen(false)
                        }}>Simpan</button>
                    </div> */}

        </div>
    )
}

export default DetailKontrol