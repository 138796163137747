import React, { Component, Fragment } from 'react'

import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
//export class CreateUserAdd_user extends Component {

   const UserAdd = ({ match }) => {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5> Tambah pengguna</h5>
                                </div>
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                    <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Roles</label>
    <select className="form-control  col-xl-3 col-md-3" id="exampleFormControlSelect1">
      <option>Administrator</option>
      <option>Customer</option>
      <option>Editor</option>
      <option>Subscriber</option>
      <option>shop_manager</option>
    </select>
  </div>
                                      
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Nama depan</label>
                                            <input className="form-control col-xl-8 col-md-7" id="validationCustom0" type="text" required="" />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Last Name</label>
                                            <input className="form-control col-xl-8 col-md-7" id="validationCustom1" type="text" required="" />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Email</label>
                                            <input className="form-control col-xl-8 col-md-7" id="validationCustom2" type="text" required="" />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Password</label>
                                            <input className="form-control col-xl-8 col-md-7" id="validationCustom3" type="password" required="" />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Confirm Password</label>
                                            <input className="form-control col-xl-8 col-md-7" id="validationCustom4" type="password" required="" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }


export default withApollo(UserAdd)
