import React, { useState, useEffect, Fragment } from 'react';
import { GET_CATEGORIES, GET_TAGS, GET_PRODUCTS, GET_GUDANGS } from '../../../constants/graph'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import { AvField, AvForm, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvInput } from 'availity-reactstrap-validation';
import one from '../../../assets/images/pro3/1.jpg'
import user from '../../../assets/images/user.png';
import { Edit, Trash2, Plus } from 'react-feather'
import { Alert, Badge, FormGroup, Label, Input, CustomInput, ListGroupItem, ListGroup, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import history from '../../../history';
import { useQuery, useMutation, readQuery, useApolloClient } from '@apollo/react-hooks';
import CategorySelect from '../category/category.select'
import ProdukDetail from '../layout/produk-detail';
import ProdukVariant from '../layout/produk-variant';
import Loading from '../../common/loading'
//import { useApolloClient, NormalizedCacheObject, QUERY } from '@apollo/client';

import gql from 'graphql-tag';
const UPDATE_TODO = gql`
  mutation UpdateTodo($id: String!, $type: String!) {
    updateTodo(id: $id, type: $type) {
      id
      type
    }
  }
`;
const ADD_PRODUCT = gql`
  mutation addMutation(
    $data: any
    $id:ID!
  ) {
    login(id:$id,body: $data)
    @rest(
      type: "Post"
      path: "api/products"
      method: "POST"
      bodyKey: "body"
    ) {
    completed
  }
  }
`;
const Hello = () => {
    const { loading, error, data } = useQuery(GET_CATEGORIES,);
    if (loading) return <p>Loading ...</p>;
    return data;
}
const AddProduct = (props) => {
    const [isLoading, setisLoading] = useState(false)
    // useQuery(GET_GUDANGS,)
    //console.log(props)
    // const [content, setBg] = useState("white")
    // const setBg = () => {
    //     const randomColor = Math.floor(Math.random()*16777215).toString(16);
    //   }
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    // const imgResource = 'http://localhost:81'//process.env.PUBLIC_URL
    const [activeImg, setActiveImg] = useState(0)
    //const [categories, setCategories] = useState([])
    const [popoverOpen, setPopoverOpen] = useState(false);
    const categories = []
    //const { loading2, data, error1 } = useQuery(GET_CATEGORIES,);
    //const { loading3, tags,categerror2 } = useQuery(GET_TAGS,);
    // const [, updateState] = React.useState();
    // const forceUpdate = React.useCallback(() => updateState({}), []);

    const toggle = () => setPopoverOpen(!popoverOpen);
    const [file, setFile] = useState()
    const [files, setFiles] = useState([])
    const [skuFiles, setSkuFiles] = useState([])
    const [pictures, setPicture] = useState([])

    // const [product,setProduct]=useState({})
    const [sku, setSku] = useState([])
    const [content, setContent] = useState("")
    const [productSkus, setproductSkus] = useState([])
    //const client = useApolloClient();
    const [product, setProduct] = useState({
        "id": null,
        "wcid": null,
        "name": "",
        "description": "<p>deskripsi</p>\n",
        "shodt_desc": null,
        "price": null,
        "reg_price": null,
        "status": "0",
        "stock": null,
        "avaibility": null,
        "image_url": null,
        "total_sales": 0,
        "branch_id": null,
        "tags": "",
        "discount": 0,
        "rating": 0,
        "is_restricted": 0,
        "video": null,
        "nick_name": null,
        "dokul_tags_categories": [],
        "dokul_products_skus": [],
        "dokul_product_images": [],
        "dokul_role_products": [],
        "dokul_product_descriptions": [],
        "dokul_product_reviews": [],
        "dokul_points": []

    })
    const [saveProduct] = useMutation(ADD_PRODUCT);

    const handeleUpdateProduct = (_items) => {
        // let _prod={...product}
        // _prod.dokul_products_skus=_items.dokul_products_skus
        // console.log("handeleUpdateProduct")
        // console.log(_items)
        if (_items.branch_id != product.branch_id)
            _items.dokul_products_skus = []
        setProduct(_items)

    }
    const handeleUpdateProductSkus = (_items) => {
        // let _prod = { ...product }
        // _prod.dokul_products_skus = _items.dokul_products_skus
        // console.log("handeleUpdateProductitems")
        // console.log(_items)
         setProduct(_items)

    }

    const handleValidSubmit = (event, values) => {
        // if (product.dokul_product_images.length == 0) {
        //     setimageError(true)
        //     return
        // }
        // if (product.dokul_products_skus.length == 0) {
        //     setwarningChangeBranch(true)
        //     return
        // }
        //         console.log("----------------------")
        //   console.log(product)
        //   console.log(props.skuFiles)
        //   return
       
        let _product = { ...product }
        // console.log(product)
        // console.log(skuFiles)
        // return 
        // setisLoading(true)
        // _product.dokul_products_skus = [...sku]
        const formData = new FormData()
        files.forEach((f, i) => {

            formData.append('uploaded_file', f, `prod_${i}_${f.name}`)
        })

        if (!product.id) {
            // skuFiles.forEach((f, i) => {

            //     formData.append('uploaded_file', f, `sku_${i}_${f.name}`)
            // })
            _product.dokul_products_skus.forEach((f,i)=>{
                formData.append('uploaded_file', f.file, `sku_${i}_${f.file.name}`)
            })
        }

        fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                var _newListImage = []
                data.d.forEach(ud => {
                    const isProd = ud.originalname.split("_")
                    // let _sku = {..._skus[isProd[1]]};
                    if (isProd[0] == 'prod') {
                        // let listImg = [..._product.dokul_product_images]
                        // let imgProd = { ...listImg[isProd[1]] }
                        //imgProd.image_url = `/assets/images/uploads/${ud.filename}`
                        _newListImage.push({
                            image_url: `/assets/images/uploads/${ud.filename}`,
                            product_id: null
                        })
                        // listImg[isProd[1]] = imgProd
                        _product.dokul_product_images = _newListImage;
                        //_product.dokul_product_images[isProd[1]]=imgProd//.image_url=`/assets/images/uploads/${ud.filename}`
                    }
                    if (isProd[0] == 'sku') {

                        let listSku = [..._product.dokul_products_skus]
                        let imgSku = { ...listSku[isProd[1]] }
                        imgSku.image_url = `/assets/images/uploads/${ud.filename}`
                        delete imgSku.file
                        listSku[[isProd[1]]] = imgSku
                        _product.dokul_products_skus = listSku//.image_url=`/assets/images/uploads/${ud.filename}`
                        //_sku.image_url=`/assets/images/uploads/${ud.filename}`
                    }
                    //_skus[isProd[1]] = _sku;
                    // console.log(isProd)?



                })
                // console.log(_product)


                saveProduct({
                    variables: {
                        data: _product,
                        id: _product.id
                    },
                    onCompleted: ({ login }) => {
                        //localStorage.setItem("AUTH_TOKEN", login.token);
                        // console.log(login)
                        //document.cookie = 'token=' + login.token
                        // document.cookie = 'signedin=true'
                        // isLoggedInVar(true);

                    }, onError: ({ graphQLErrors, networkError }) => {
                        // console.log(networkError)

                        // console.log(graphQLErrors)
                        //  localStorage.setItem("AUTH_TOKEN", login.token);
                        //history.push('/');
                    },
                }).then(data => {
                    setisLoading(false)
                    history.push('/products/list');
                })



            })
            .catch(error => {
                console.error(error)
                setisLoading(false)
            })
    }

    const handleSaveProduct = () => {
        // console.log(product)
        // console.log(files)
        // console.log(skuFiles)
        handleValidSubmit()
    }


    const UPDATE_MUTATION = gql`
    mutation updateMutation(
      $data: any
      $id:ID!
    ) {
      login(id:$id,body: $data)
      @rest(
        type: "Post"
        path: "api/products/{args.id}"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;




    return (

        <Fragment>
            {!product || isLoading ? <Loading /> : <>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header"><h5>Add Product</h5></div>
                                <div className="card-body">
                                    <ProdukDetail dRole={props.dRole} product={product} categories={props.dCategory.categories} setisLoading={setisLoading} handeleUpdateProduct={handeleUpdateProduct} skuFiles={skuFiles} setFiles={setFiles} />


                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header"><h5>Variant</h5></div>
                                <div className="card-body p-1">
                                    {/* <ProdukDetail product={product} categories={props.data.categories} setisLoading={setisLoading} handeleUpdateProduct={handeleUpdateProduct} skuFiles={skuFiles}/> */}
                                    {product.dokul_products_skus.map((x, idx) =>
                                        <ProdukVariant _index={idx} item={x} key={idx} product={product} handeleUpdateProduct={handeleUpdateProductSkus} setSkuFiles={setSkuFiles} />
                                    )}
                                </div>
                                <div className="card-footer">
                                    <button className='btn btn-primary btn-sm ' onClick={()=>{
                                        let _product={...product}
                                        let _variants=[..._product.dokul_products_skus,{
                                            id: null,
                                            discount: 0,
                                            image_url: "/assets/images/noimage.png",
                                            name: "",
                                            price: 0,
                                            dokul_gudang_skus: [],// listGudang.data.gudangs.data.filter(_gd => _gd.branch_id == product.branch_id).map(gd => { return { gudang_id: gd.id, stock: 0, avaibility: 1, notif: 0, status: 1 } }),
                                            product_id: product?.id,
                                            reg_price: 0,
                                            status: "instock",
                                            stock: 0,
                                            total_sales: 0
                                        }]
                                        _product.dokul_products_skus=_variants
                                        setProduct(_product)
                                        
                                    }}>Tambah variant</button>
                                </div>
                            </div>
                            <div className="container mb-3 bg-white p-2">
                                <button className="btn btn-primary" onClick={() => handleSaveProduct()}>Save</button>
                                <button className="btn btn-light" onClick={() => history.push('/products/list')}>Cancel</button>
                            </div>
                        </div>

                    </div>


                </div>
            </>}

        </Fragment>
    )
}

export default AddProduct
