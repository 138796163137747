import React, { useState, Fragment, PureComponent } from 'react'
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import { Alert, Badge, FormGroup, Label, Input,CustomInput } from 'reactstrap';
import {GET_CATEGORIES} from '../../../constants/graph'


export const CategorySelect = (selected) => {
    console.log(selected)
    const { loading, data, error } = useQuery(GET_CATEGORIES,);
    if (error ) {
       
        return <div>Error!</div>;
    }
    if (loading ) {
       
        return <div>loading!</div>;
    }
    

    return (
        <PureComponent>
        <div>
            {data.categories.map((cat,i)=>{
               return <Input  key={i} type="checkbox" id="exampleCustomCheckbox" label={"kategori.description"} checked={true} />
            })}
            

        </div>
        </PureComponent>
    )

}

export default withApollo(CategorySelect)
