



import React, { useState, useEffect, Fragment } from 'react';
import { GET_CATEGORIES, GET_TAGS, GET_PRODUCTS, GET_GUDANGS, GET_KLINIKS, ADD_SERVICE, UPDATE_SERVICE } from '../../../constants/graph'
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import { AvField, AvForm, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvInput } from 'availity-reactstrap-validation';
import one from '../../../assets/images/pro3/1.jpg'
import user from '../../../assets/images/user.png';
import { Edit, Trash2, Plus } from 'react-feather'
import { Alert, Badge, FormGroup, Label, Input, CustomInput, ListGroupItem, ListGroup, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import history from '../../../history';
import { useQuery, useMutation, readQuery, useApolloClient } from '@apollo/react-hooks';


//import { useApolloClient, NormalizedCacheObject, QUERY } from '@apollo/client';

import gql from 'graphql-tag';
const UPDATE_TODO = gql`
  mutation UpdateTodo($id: String!, $type: String!) {
    updateTodo(id: $id, type: $type) {
      id
      type
    }
  }
`;

const ServiceDetail = (props) => {
    const listKliniks = useQuery(GET_KLINIKS);
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    // const imgResource = 'http://localhost:81'//process.env.PUBLIC_URL
    const [activeImg, setActiveImg] = useState(0)
    //const [categories, setCategories] = useState([])
    const [popoverOpen, setPopoverOpen] = useState(false);
    const categories = []
    //const { loading2, data, error1 } = useQuery(GET_CATEGORIES,);
    //const { loading3, tags,categerror2 } = useQuery(GET_TAGS,);
    // const [, updateState] = React.useState();
    // const forceUpdate = React.useCallback(() => updateState({}), []);

    const toggle = () => setPopoverOpen(!popoverOpen);
    const [file, setFile] = useState()
    const [files, setFiles] = useState([])
    const [skuFiles, setSkuFiles] = useState([])
    const [pictures, setPicture] = useState([])

    // const [product,setProduct]=useState({})
    const [sku, setSku] = useState([])
    const [content, setContent] = useState("")
    //const client = useApolloClient();
    const [product, setProduct] = useState(props.product)
    const [item, setitem] = useState(props.data)
    const [imageHasChange, setimageHasChange] = useState(false)

    const client = useApolloClient();

    const [addService, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(UPDATE_SERVICE,);
    const saveService = (newitem) => {
        return addService({
            variables: {
                data: newitem
            },
            onCompleted: (saveService) => {
                console.log("Sukses")
                history.push('/services');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })

    }



    useEffect(() => {

        if (product) {
            setPicture(product.dokul_product_images)
            setContent(product.description)
            setSku(product.dokul_products_skus)

        }
        //console.log(prod)
    }, [product])
    const UPDATE_SKU = gql`
    mutation updateSkus(
      $data: any
      $id:ID!
    ) {
      skus(id:$id,body: $data)
      @rest(
        type: "Post"
        path: "api/products/sku/{args.id}"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;



    const handleValidSubmit = (event, values) => {
        let newService = { ...item }
        if (imageHasChange) {
            const formData = new FormData()
            formData.append('uploaded_file', file, `${newService.name}_${file.name}`)


            fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    console.log(data)
                    newService.picture = `/assets/images/uploads/${data.d[0].filename}`
                    newService.deskripsi = newService.name
                    // newService.type = 2
                    newService.banner = `/assets/images/uploads/${data.d[0].filename}`
                    addService({
                        variables: {
                            data: newService
                        },
                        onCompleted: (saveService) => {
                            console.log("Sukses")
                            // history.push('/products');
                        }, onError: ({ graphQLErrors, networkError }) => {
                            console.log("ada eror")
                            console.log(networkError)
                            console.log(graphQLErrors)
                        },
                    }).then(data => {
                        // console.log(data) 
                        // this.onCloseModal()
                    })

                })

                .catch(error => {
                    console.error(error)
                })
        } else {
            addService({
                variables: {
                    data: newService
                },
                onCompleted: (saveService) => {
                    console.log("Sukses")
                    // history.push('/products');
                }, onError: ({ graphQLErrors, networkError }) => {
                    console.log("ada eror")
                    console.log(networkError)
                    console.log(graphQLErrors)
                },
            }).then(data => {
                // console.log(data) 
                // this.onCloseModal()
            })

        }



    }

    const handleInvalidSubmit = (event, errors, values) => {
        //this.setState({ email: values.email, error: true });
    }

    //image upload
    const _handleSubmit = (e) => {
        e.preventDefault();
    }

    const _handleImgChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        var oldItem = { ...item }
        // var oldpic = { ...pictures[i] }
        // var oldfiles = [...files]
        // const { dummyimgs } = state;
        // oldfiles[i] = { idx: i, file: file };
        //console.log(file);
        reader.onloadend = () => {
            oldItem.picture = reader.result;
            // oldpictures[i] = oldpic;
            setFile(file)
            setitem(oldItem)
            setimageHasChange(true)
            // setPicture(oldpictures)
            // setFiles(oldfiles)

        }
        reader.readAsDataURL(file)
    }


    if (listKliniks.loading) return "loading"


    return (

        <Fragment>
            <AvForm className="needs-validation add-product-form" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
                <div className="form form-label-center">

                    <div className='form-group row mb-2' style={{ borderBottom: 'solid 1px ', borderColor: 'darkgrey' }}>

                        <div className="col-sm-3">
                            {/* <div className="file-upload-product d-flex flex-nowrap"> */}

                            <div className='img-front m-1' style={{ padding: 0, width: "100%" }}>
                                <div className="box-input-file-lg"  >
                                    <label for="fileUpload" style={{ cursor: "pointer" }}>
                                        <div className="product-hover">
                                            <Edit className="bg-white" />
                                        </div>
                                        <img src={`${item.picture.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${item.picture}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                    </label>


                                    <input id="fileUpload" type="file" onChange={(e) => _handleImgChange(e)} style={{
                                        display: "none",
                                        cursor: "pointer",
                                        // margin: "auto"
                                    }} />




                                </div>
                            </div>


                            {/* </div> */}
                        </div>

                        <div className="col-sm-9">
                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Nama :</label>
                                <div className="col-xl-8 col-sm-7">
                                    <AvField className="form-control mb-0" name="skuName" id="validationCustom02" value={item.name} type="text" required onChange={(e) => {
                                        let _item = { ...item }
                                        _item.name = e.target.value
                                        setitem(_item)

                                    }} />
                                </div>
                                <div className="valid-feedback">Looks good!</div>
                            </div>

                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Tindakan oleh :</label>
                                <div className="col-xl-8 col-sm-7">
                                    <div class="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={item.type == 1} onChange={(e) => {
                                            if (e.target.checked) {
                                                let _item = { ...item }
                                                _item.type = 1
                                                setitem(_item)
                                            }

                                        }} />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            Oleh Dokter
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={item.type == 2} onChange={(e) => {
                                            if (e.target.checked) {
                                                let _item = { ...item }
                                                _item.type = 2
                                                setitem(_item)
                                            }

                                        }} />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Oleh Perawat
                                        </label>
                                    </div>
                                </div>
                                <div className="valid-feedback">Looks good!</div>
                            </div>

                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Klinik :</label>
                                <div className="col-xl-8 col-sm-7">


                                    {listKliniks.data.kliniks.map((kl, idx) => {
                                        return <div className="form-check" key={idx}>
                                            <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" checked={item.id_kliniks.includes(kl.id)} onChange={(e) => {
                                            let _item = { ...item }
                                            let _kliniks=[..._item.dokul_kliniks]
                                            
                                            if (e.target.checked) {
                                                _kliniks.push(kl)
                                                
                                            }else{
                                                let _klinik=_kliniks.find(_kl=>_kl.id==kl.id)
                                                let _idx=_kliniks.indexOf(_klinik)
                                                _kliniks.splice(_idx,1)
                                            }
                                            _item.dokul_kliniks=_kliniks
                                            const id_kliniks=_kliniks.map(kl=>kl.id)
                                            _item.id_kliniks=id_kliniks.join(',')
                                            setitem(_item)

                                        }} />
                                            <label className="form-check-label" for="flexRadioDefault2">
                                                {kl.name}
                                            </label>
                                        </div>
                                    })}

                                </div>
                                <div className="valid-feedback">Looks good!</div>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="offset-xl-1 offset-sm-2">
                    <button type="submit" className="btn btn-success">Update</button>
                    <button type="button" className="btn btn-light" onClick={(e) => history.push('/services/list')}>Cancel</button>
                </div>
            </AvForm>
        </Fragment>
    )
    // }
}

export default ServiceDetail

