import React, { Component, Fragment, forwardRef, useState, useEffect } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import CurrencyInput from 'react-currency-input-field';

import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Input } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { ADD_JADWAL_LIBUR, DELETE_JADWAL_LIBUR, DELETE_SESI_TEMPLATE, DELETE_TEMPLATE, GET_DOKTERS, GET_JADWAL_LIBUR, GET_JADWAL_TEMPLATES, GET_KLINIKS, GET_RESERVASI, SAVE_SESI_TEMPLATE, SAVE_TEMPLATE, UPDATE_DOKTER } from '../../constants/graph';
import data from '../../assets/data/physical_list';
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
registerLocale('id', es)

const layananType = ["", "Konsultasi", "Perawatan", "Tindakan"]

const liburFor = ["", "Klinik", "Dokter", "Layanan"]

const JadwalLibur = (props) => {
    const { loading: dokterLoading, error: dokterError, data: dokterData } = useQuery(GET_DOKTERS)
    const [getLiburs, { loading: liburLoading, error: liburError, data: listLibur }] = useLazyQuery(GET_JADWAL_LIBUR, { fetchPolicy: 'network-only' })

    const [modalOpen, setModalOpen] = useState(false)
    const [modalSesiOpen, setmodalSesiOpen] = useState(false)
    const [newSesi, setnewSesi] = useState({})
    const [newTemplate, setnewTemplate] = useState({
        message: "",
        id_dokter: "",
        libur_for: 0,
        id_klinik: "",
        id_layanan: "",

    })
    const [allSesi, setallSesi] = useState(true)
    const [jamSesi, setjamSesi] = useState()
    const [jamSesiEnd, setjamSesiEnd] = useState()
    const [tgl, setTgl] = useState(new Date())
    const [idxDokter, setIdxDokter] = useState(0)
    const [selectedHari, setselectedHari] = useState([])
    const [selectedObject, setselectedObject] = useState()
    const [selectedDokter, setselectedDokter] = useState([])
    const [selectedKlinik, setselectedKlinik] = useState([])
    const [selectedLayanan, setselectedLayanan] = useState([])
    const [timeFrom, settimeFrom] = useState()
    const [timeTo, settimeTo] = useState()
    const [idxKlinik, setIdxKlinik] = useState(0)
    const [jadwalBaru, setJadwalBaru] = useState({})
    const columns = [];
    const listHari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
    const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const [getTemplates, { loading, error, data }] = useLazyQuery(GET_JADWAL_TEMPLATES, { fetchPolicy: 'network-only' })
    const client = useApolloClient()
    const updateCacheTemplate = (cache, { data }) => {
        // console.log(data.saveTemplate)
        getTemplates()
        setModalOpen(false)

        // console.log(data)
        //     const _updatedDokter = { ...data.updateDokter }
        //     const fragmentResult = cache.writeFragment({
        //         id: `Dokter:${_updatedDokter.id}`,
        //         fragment: gql`
        //     fragment dokter on Dokter{
        //         id
        //         dokul_dokter_konsultasis
        //         fullname
        //         picture
        //         picture
        //         available
        //     }
        //   `,
        //         data: _updatedDokter
        //     });
    };
    const [addTemplate] = useMutation(SAVE_TEMPLATE, { update: updateCacheTemplate });
    const [addSesiTemplate] = useMutation(SAVE_SESI_TEMPLATE, {
        onCompleted: () => {
            getTemplates()
        }
    });
    const [saveNewLibur] = useMutation(ADD_JADWAL_LIBUR, {
        onCompleted: (data) => {
            getLiburs()
        }
    });
    const [deleteLibur] = useMutation(DELETE_JADWAL_LIBUR, {
        onCompleted: (data) => {
            getLiburs()
        }
    });
    useEffect(() => {
        getTemplates()
        getLiburs()

        return () => {
            return false
        }
    }, [])
    const getDokter = (id) => {
        return dokterData.dokters.find(x => x.id == id)
    }

    const updateCache = (cache, { data }) => {

        const _updatedDokter = { ...data.updateDokter }
        const fragmentResult = cache.writeFragment({
            id: `Dokter:${_updatedDokter.id}`,
            fragment: gql`
        fragment dokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
        }
      `,
            data: _updatedDokter
        });
    };
    const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });
    // const selectedDokter = client.readFragment({
    //     id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment readDokter on Dokter{
    //         id
    //         dokul_dokter_konsultasis
    //         fullname
    //         picture
    //         picture
    //         available
    //       }
    //     `,
    // }, false);
    const { loading: loadingKlinik, error: errorKlinik, data: dataKlinik } = useQuery(GET_KLINIKS)


    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }
    const getKlinikDetail = (idklinik) => {
        return dataKlinik.kliniks.find(x => x.id == idklinik)
    }
    const getDokterDetail = (iddokter) => {
        return dokterData.dokters.find(x => x.id == iddokter)
    }

    if (loading || loadingKlinik || dokterLoading || !data || liburLoading || !listLibur) return "Loading"
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">

                    <div className="col-sm-12">
                        {/* <div className="card"> */}
                        {/* <div className="card-header bg-primary text-white">
                                <span className="float-left">
                                    <div className="d-flex flex-row bd-highlight ">
                                        <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">Sesi Konsultasi</h4></div>
                                      

                                    </div>
                                </span>

                            </div> */}

                        {/* <div className="card-body"> */}
                        <ul className="list-group">

                            <li className={`list-group-item bg-primary w-100`}>Jadwal Libur<span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                setmodalSesiOpen(true)
                            }}
                            >ADD</span></li>
                            <Table className='table table-sm' >
                                <thead>
                                    <tr>
                                        <th className='text-center'>Object</th>
                                        <th className='text-center'>Klinik</th>
                                        <th className='text-center'>Dokter</th>
                                        <th className='text-center'>Layanan</th>
                                        <th className='text-center'>Tanggal - sesi</th>
                                        <th className='text-center'>Message</th>
                                        <th className='text-center'>#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listLibur.data.map((libur, idxKon) => {
                                        const _klinik = getKlinikDetail
                                        return <tr key={idxKon}>
                                            <td className='text-center'>{liburFor[libur.libur_for]}</td>
                                            <td className='text-center'>
                                                <div className='d-flex flex-column'>
                                                    {libur.id_klinik.split(',').map(kl => <p key={kl} color='light' className='m-1'>{getKlinikDetail(kl)?.name}</p>)}
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <div className='d-flex flex-column'>
                                                    {libur.id_dokter.split(',').map(kl => <p  key={kl}  className='m-1'>{getDokterDetail(kl)?.fullname}</p>)}
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <div className='d-flex flex-column justify-content-around'>
                                                    {libur.id_layanan.split(',').map(kl => <p   key={kl} className='m-1'>{layananType[kl]}</p>)}
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                               <span>{libur.start_date} - {libur.end_date}</span> <br/>
                                               <span>{libur.jam}</span> 
                                            </td>
                                            <td className='text-center'>
                                                {libur.message}
                                            </td>
                                            <td className='text-center'>
                                                <span className="bg-cred rounded p-1 float-right m-2 col-1" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                    deleteLibur(
                                                        {
                                                            variables: {
                                                                id: libur.id
                                                            }
                                                        }
                                                    )
                                                    // let updateListTmp = []
                                                    // let currentTemp = { ...data.templates[idxDokter] }
                                                    // let newListTmp = [...currentTemp.dokul_jadwal_sesis]

                                                    // let idx = newListTmp.indexOf(kon)

                                                    // newListTmp.splice(idx, 1)

                                                    // // newListTmp.push(newListTmp)
                                                    // currentTemp.dokul_jadwal_sesis = newListTmp
                                                    // updateListTmp.push(currentTemp)
                                                    // deleteSesiTemplate({
                                                    //     variables: {
                                                    //         type: kon.id
                                                    //     },
                                                    // })

                                                }}><i className="icofont icofont-ui-close" /></span>
                                            </td>
                                        </tr>
                                    }
                                    )}
                                </tbody>
                            </Table>

                        </ul>
                        {/* <div className="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                            <ToastContainer />
                        </div>

                        {/* {data.dokters[idxDokter].dokul_dokter_konsultasis.filter(ykon => ykon.type == 2).length > 0 ?
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                        <h6 className="border-bottom border-info"><i className="icofont icofont-globe" /> ONLINE </h6>
                                        <div className="p-2 bd-highlight mb-2">
                                            <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-left">
                                                <div className="d-flex flex-row">
                                           
                                                </div>
                                                <Link
                                                    to={`/products/edit/${data.dokters[idxDokter].dokul_dokter_konsultasis.find(onk => onk.type == 2).id_product}`}
                                                >
                                                    <span className="p-2 bd-highlight rounded bg-success">EDIT PRODUK </span>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                } */}
                    </div>
                </div>
            </div>
            <div className="clearfix" />
            {/* </div> */}
            {/* </div> */}


            <Modal isOpen={modalSesiOpen} toggle={() => setmodalSesiOpen(false)} centered size='md' >
                <div className="modal-content bg-white">
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title">Tambah Jadwal Libur</h5>

                    </div>
                    <div className="modal-body">
                        <h6 className="font-weight-bold">{data.templates[idxDokter]?.fullname}</h6>
                        <label className='w-100 p-2 bg-light text-dark' >Object  :</label>
                        <div className='d-flex flex-row flex-wrap'>
                            <Badge className={`text-dark m-2 p-2 ${selectedObject == 1 && " bg-cred"}`} color={selectedObject == 1 ? 'danger' : 'light'} style={{ cursor: "pointer" }} onClick={() => {
                                setselectedObject(1)

                            }}>Klinik</Badge>
                            <Badge className={`text-dark m-2 p-2 ${selectedObject == 2 && " bg-cred"}`} color={selectedObject == 2 ? 'danger' : 'light'} style={{ cursor: "pointer" }} onClick={() => {
                                setselectedObject(2)

                            }}>Dokter</Badge>
                            <Badge className={`text-dark m-2 p-2 ${selectedObject == 3 && " bg-cred"}`} color={selectedObject == 3 ? 'danger' : 'light'} style={{ cursor: "pointer" }} onClick={() => {
                                setselectedObject(3)

                            }}>Layanan</Badge>
                        </div>

                        <h6 className="font-weight-bold">{data.templates[idxDokter]?.fullname}</h6>
                        <label className='w-100 p-2 bg-light text-dark' >Klinik</label>
                        <div className='d-flex flex-row flex-wrap'>
                            {dataKlinik.kliniks.map((klinik, idxh) => {
                                return <Badge key={idxh} className={`text-dark m-2 p-2${selectedKlinik.some(y => y == klinik.id) && " bg-cred"}`} color={selectedKlinik.some(y => y == klinik.id) ? 'danger' : 'light'} style={{ cursor: "pointer" }} onClick={() => {
                                    const isEksist = selectedKlinik.some(x => x == klinik.id)
                                    if (isEksist) {
                                        let _list = [...selectedKlinik]
                                        const deletedHari = _list.findIndex(x => x == klinik.id)
                                        _list.splice(deletedHari, 1)
                                        setselectedKlinik(_list)

                                    } else
                                    setselectedKlinik(x => [...x, klinik.id])

                                }}>{klinik.name}</Badge>
                            })}
                        </div>

                        <h6 className="font-weight-bold">{data.templates[idxDokter]?.fullname}</h6>
                        <label className='w-100 p-2 bg-light text-dark' >Dokter :</label>
                        <div className='d-flex flex-row flex-wrap'>
                            {dokterData.dokters.map((dokter, idxh) => {
                                return <Badge key={idxh} className={`text-dark m-2 p-2${selectedHari.some(y => y == dokter.id) && " bg-cred"}`} color={selectedHari.some(y => y == dokter.id) ? 'danger' : 'light'} style={{ cursor: "pointer" }} onClick={() => {
                                    const isEksist = selectedHari.some(x => x == dokter.id)
                                    if (isEksist) {
                                        let _list = [...selectedHari]
                                        const deletedHari = _list.findIndex(x => x == dokter.id)
                                        _list.splice(deletedHari, 1)
                                        setselectedHari(_list)

                                    } else
                                        setselectedHari(x => [...x, dokter.id])

                                }}>{dokter.fullname}</Badge>
                            })}
                        </div>

                        <h6 className="font-weight-bold">{data.templates[idxDokter]?.fullname}</h6>
                        <label className='w-100 p-2 bg-light text-dark' >Layanan :</label>
                        <div className='d-flex flex-row flex-wrap'>
                            {layananType.filter(x=>x!='').map((layanan, idxh) => {
                                const idxLay=idxh+1
                                return <Badge key={idxh} className={`text-dark m-2 p-2${selectedLayanan.some(y => y == idxLay) && " bg-cred"}`} color={selectedLayanan.some(y => y == idxLay) ? 'danger' : 'light'} style={{ cursor: "pointer" }} onClick={() => {
                                    const isEksist = selectedLayanan.some(x => x == idxLay)
                                    if (isEksist) {
                                        let _list = [...selectedLayanan]
                                        const deletedHari = _list.findIndex(x => x == idxLay)
                                        _list.splice(deletedHari, 1)
                                        setselectedLayanan(_list)

                                    } else
                                    setselectedLayanan(x => [...x, idxLay])

                                }}>{layanan}</Badge>
                            })}
                        </div>
                        <label className='w-100 p-2 bg-light text-dark' >Detail :</label>
                        <form>
                            <Row>

                                <Col md={3}>
                                    Tanggal Mulai :
                                </Col>
                                <Col md={9}>
                                    <DatePicker
                                        className='m-2'
                                        selected={timeFrom}
                                        onChange={(date) => {
                                            let _newSesi = { ...newSesi }
                                            settimeFrom(date)
                                            _newSesi.timeFrom = moment(date).format("DD-MM-YYYY")
                                            setnewSesi(_newSesi)
                                        }}
                                        // showTimeSelect
                                        // showTimeSelectOnly
                                        // timeIntervals={30}
                                        // timeCaption="Tanggal mulai"
                                        dateFormat="dd/MMMM/yyyy"
                                    />
                                </Col>
                                <Col md={3}>
                                    Tanggal Akhir :
                                </Col>
                                <Col md={9}>
                                    <DatePicker
                                        className='m-2'
                                        selected={timeTo}
                                        onChange={(date) => {
                                            settimeTo(date)
                                            let _newSesi = { ...newSesi }
                                            _newSesi.timeTo = moment(date).format("DD-MM-YYYY")
                                            setnewSesi(_newSesi)
                                        }}
                                        // showTimeSelect
                                        // showTimeSelectOnly
                                        // timeIntervals={30}
                                        // timeCaption="Jam mulai"
                                        dateFormat="dd/MMMM/yyyy"
                                    />
                                </Col>
                               

                                <Col md={3}>
                                   <Input type='checkbox' checked={allSesi} onChange={()=>{
                                    setallSesi(!allSesi)
                                    setjamSesi()
                                    setjamSesiEnd()
                                   }} /> All Sesi
                                </Col>
                                <Col md={4}>
                                    <DatePicker
                                    disabled={allSesi}
                                        className='m-2'
                                        selected={jamSesi}
                                        onChange={(date) => {
                                            setjamSesi(date)
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption={allSesi?"ALL SESI":"Sesi mulai"}
                                        placeholderText={allSesi?"ALL SESI":'Sesi mulai'}
                                        dateFormat="HH:mm"
                                    />
                                </Col>
                                
                                <Col md={5}>
                                    <DatePicker
                                     disabled={allSesi}
                                        className='m-2'
                                        selected={jamSesiEnd}
                                        onChange={(date) => {
                                            setjamSesiEnd(date)
                                            
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption={allSesi?"ALL SESI":"Sesi akhir"}
                                        placeholderText={allSesi?"ALL SESI":'Sesi akhir'}
                                        dateFormat="HH:mm"
                                    />
                                </Col>
                                <Col md={3}>
                                    Message :
                                </Col>
                                <Col md={9}>
                                    <input type="text" className="form-control" id="jumlahpasien" onChange={(e) => {
                                        let _newSesi = { ...newSesi }
                                        _newSesi.message = e.target.value
                                        setnewSesi(_newSesi)

                                    }} />
                                </Col>
                            </Row>

                            {/* <div className="form-group">
                                <label for="jammulai">Jam Mulai :</label>
                                <input type="time" className="form-control mt-1" id="jammulai" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.timeFrom = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div>
                            <div className="form-group">
                                <label for="jamakhir">Jam Akhir</label>
                                <input type="time" className="form-control" id="jamakhir" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.timeTo = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div> */}

                            {/* <div className="form-group">
                                <label for="jumlahpasien">Jumlah pasien</label>
                                <input type="number" className="form-control" id="jumlahpasien" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.jumlah_pasien = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div> */}


                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            // let updateListTmp = []
                            // let currentTemp = { ...data.templates[idxDokter] }
                            // let newListTmp = [...listLibur]

                            let newSesiTmp = {
                                status: 1,
                                start_date: moment(timeFrom).format("YYYY-MM-DD"),
                                end_date: moment(timeTo).format("YYYY-MM-DD"),
                                message: newSesi.message,
                                id_dokter: selectedHari.toString(),
                                libur_for:selectedObject,
                                id_klinik:selectedKlinik.toString(),
                                id_layanan:selectedLayanan.toString(),
                                jam:allSesi?"ALL":`${moment(jamSesi).format('HH:mm')}-${moment(jamSesiEnd).format('HH:mm')}`

                            }
                            // newListTmp.push(newSesiTmp)
                            saveNewLibur({
                                variables: {
                                    body: newSesiTmp
                                }
                            })
                            // setlistLibur(newListTmp)
                            // currentTemp.dokul_jadwal_sesis = newListTmp
                            // updateListTmp.push(currentTemp)

                            // const newListTmpFilter = newListTmp.filter(x => !x.id)
                            // console.log(newListTmpFilter)
                            // return
                            // addSesiTemplate({
                            //     variables: {
                            //         type: newListTmpFilter
                            //     },
                            // })

                            setmodalSesiOpen(false)
                        }}>Simpan</button>
                    </div>
                </div>
            </Modal>

            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}


export default withApollo(JadwalLibur)

