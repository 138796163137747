import React , { Component,Fragment,useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject,NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
import {GET_CATEGORIES,GET_TAGS,GET_DASHBOARD_DATA,GET_ORDERS, GET_PRODUK_DIMINATI} from '../../constants/graph'

import Loading from '../common/loading'
import Errorpage from '../common/Errorpage'
const Produkdiminati = ({ props }) => {
    //const [data,setData]=useState(props.data)
   const { loading, data, error,fetchMore , refetch, networkStatus} = useQuery(GET_PRODUK_DIMINATI);
   if (loading) return <Loading />;
   if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    return (
        <div className="card height-equal">
        <div className="card-header">
            <h5>Produk Paling diminati</h5>
        </div>
        <div className="card-body">
            <div className="user-status table-responsive products-table">
                <table className="table table-bordernone mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Nama</th>
                            <th scope="col">Jumlah penjualan</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.produkDiminati.map((p, index) =>
                            <tr key={index}>
                                <td>{p.product_name}</td>
                                <td className="digits">{p.prodcount}</td>
                                <td className="font-primary">{p.dokul_order.branch_id==1?"JAKARTA":"BALIKPAPAN"}</td>

                            </tr>

                        )}

                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}
export default withApollo(Produkdiminati)
//export default ordertoday
