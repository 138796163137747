import React, { useState, Fragment, useEffect } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import Product_list from './physical/product-list';
import Product_detail from './physical/product-detail'
import Product_add from './physical/add-product'
import CategoryList from './physical/category'
import Product_edit from './physical/edit-product'
import { GET_PRODUCTS, GET_ROLES } from '../../constants/graph';
import Loading from '../common/loading';

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

const ADD_PRODUCT = gql`
    mutation updateMutation(
      $data: any
    ) {
      login(body: $data)
      @rest(
        type: "Post"
        path: "api/products"
        method: "POST"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
  const UPDATE_STOCK = gql`
    mutation updateStock(
      $data: any
    ) {
      updateStock(body: $data)
      @rest(
        type: "Product"
        path: "api/products/gudang/sku"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
const ADD_CATEGORIES = gql`
    mutation updateCategories(
      $data: any
    ) {
      updateCategories(body: $data)
      @rest(
        type: "Post"
        path: "api/products/categories"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
const GET_CATEGORIES = gql`query categories {
    categories @rest(type: "Category",path: "api/products/categories") {
        id,
        name,
        description
    }
  }
`;
const GET_TAGS = gql`query tags {
    tags @rest(type: "Tag",path: "api/products/tags") {
        id,
        name,
        description
    }
  }
`;
const REMOVE_PRODUCT = gql`
  mutation removeProduct($_id: String) {
    removeProduct(_id: $_id) {
      _id
    }
  }
`;
const Products = ({ match }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setpage] = useState(0)
  const [textSearch, settextSearch] = useState('all')
  const [categoriesSearch, setcategoriesSearch] = useState([])
  const [brandSearch, setbrandSearch] = useState([])
  const [typeStock, settypeStock] = useState(0)
  const { loading:lRole, error:eRole, data:dRole } = useQuery(GET_ROLES)
  if (!Cookies.get('signedin')) {
    history.push('/');
  }
  const user = JSON.parse(localStorage.getItem('CURRENT_USER'));

  //const params = useParams();

  if (match.params.page) {
    // console.log(match.params.page)
    // console.log(match.params.id)


  }
  
  const { loading, data, error,fetchMore } =useQuery(GET_PRODUCTS, {
    variables: {
        page: 0,
        brand: brandSearch.join(','),
        categories: categoriesSearch.join(','),
        textSearch: textSearch,
        typeStock:typeStock
    },
    fetchPolicy:'cache-and-network'
  });
  const { loading:lCategory, data:dCategory, error:eCategory } =useQuery(GET_CATEGORIES, {
    fetchPolicy:'cache-and-network'
  });
  // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
  // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
  if (error) {
    // console.log(error.message);
    Cookies.remove('signedin', '/');
    Cookies.remove('jwt');



    history.push('/');
    //return <div>Error!</div>;
  }
  const [updateStock, {loading: updateLoading}] = useMutation(UPDATE_STOCK, {
    refetchQueries: [{query: GET_PRODUCTS}],
    awaitRefetchQueries: true,
  });

  

  const client = useApolloClient();
  const refetch=(_id=30)=>{
    const existingTodos = client.cache.readQuery({ query: GET_PRODUCTS });
       const newTodos = existingTodos.products.filter((t) => (t.id !== _id));
       client.cache.writeQuery({
         query: GET_PRODUCTS,
         data: {products: newTodos}
       });
      
    
    // client.cache.modify({
    //   fields: {
    //     Product(data, { readField }) {
    //       return data.products.filter(
    //         taskRef => _id !== readField('_id', taskRef),
    //       );
    //     },
    //   },
    // });
    // removeTask({
    //   variables: { _id },
    //   update(cache) {
    //     cache.modify({
    //       fields: {
    //         tasks(existingTaskRefs, { readField }) {
    //           return existingTaskRefs.filter(
    //             taskRef => _id !== readField('_id', taskRef),
    //           );
    //         },
    //       },
    //     });
    //   },
    // });
    // client.resetStore().then(data=>console.log(data))
  }
  //const client = null;
  // const { datal } = useQuery(IS_LOGGED_IN);
  // const [datas, setData] = useState(datanya)
  let product;
  const [addProduct, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(ADD_PRODUCT,);
  const saveProduct = (product) => {
    addProduct({
      variables: {
        data: product
      },
      onCompleted: ({ login }) => {
        // console.log("Sukses")
        history.push('/products');
      }, onError: ({ graphQLErrors, networkError }) => {
        // console.log("ada eror")
        // console.log(networkError)
        // console.log(graphQLErrors)
      },
    })

  }

  const [addCategory, { loading: mutationCategoryLoading, error: mutationCategoryError, called: mutationCategoryCalled },] = useMutation(ADD_CATEGORIES,);
  const saveCategory = (category) => {
    const t = addCategory({
      variables: {
        data: category
      },
      onCompleted: (data) => {
        console.log("Sukses")
        history.push('/products/categories');
      }, onError: ({ graphQLErrors, networkError }) => {
        console.log("ada eror")
        console.log(networkError)
        console.log(graphQLErrors)
      },
    })
    return t

  }
  const [selectedBranch, setselectedBranch] = useState(3)
  useEffect(() => {
    if(selectedBranch==3){
      setbrandSearch([])
    }else{
      setbrandSearch([selectedBranch])
    }
  
    return () => {
      return false
    }
  }, [selectedBranch])
  

  if (match.params.page == 'edit') {
    // product = client.readFragment({
    //   id: `Product:${match.params.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
    //   fragment: gql`
    //       fragment editProduct on Product {
    //         name,
    //         dokul_products_skus
    //         branch_id,
    //         dokul_tags_categories,
    //         dokul_product_images
    //         id
    //         description
    //         dokul_product_descriptions
    //         tags
    //         is_restricted 
    //         nick_name
    //         video
    //         dokul_role_products
    //         is_premium
    //       }
    //     `,
    // }, false);
    // product=data?.products.data.find(x=>x.id==match.params.id)
  }

  const handlePagination = () => {

    setpage(page+1)
    setIsLoading(true)
    fetchMore({
        variables: {
            page: page+1,
            brand: brandSearch.join(','),
            categories: categoriesSearch.join(','),
        textSearch: textSearch,
        typeStock:typeStock

        },
        updateQuery: (previousResult, { fetchMoreResult,variables }) => {
            // console.log(previousResult);
            // console.log(fetchMoreResult);
            setIsLoading(false)
            if (!fetchMoreResult) return previousResult;

            return Object.assign({}, previousResult, {
                products: {
                    __typename: previousResult.products.__typename,
                    data: [...previousResult.products.data, ...fetchMoreResult.products.data],
                    totalItems: fetchMoreResult.products.totalItems,
                    totalPages: fetchMoreResult.products.totalPages,
                    currentPage: fetchMoreResult.products.currentPage,
                    hasMore:fetchMoreResult.products.hasMore
                },

            });
            // const previousEntry = previousResult.entry;
            // const newComments = fetchMoreResult.moreComments.comments;
            // const newCursor = fetchMoreResult.moreComments.cursor;

            // return {
            //   // By returning `cursor` here, we update the `fetchMore` function
            //   // to the new cursor.
            //   cursor: newCursor,
            //   entry: {
            //     // Put the new comments in the front of the list
            //     comments: [...newComments, ...previousEntry.comments]
            //   },
            //   __typename: previousEntry.__typename
            // };
        }
    })
   
}


  //daricahce
  // const { products } = client.readQuery({
  //     query: gql`
  //       query ReadProducts {
  //         products {
  //           id
  //         }
  //       }
  //     `,
  //   },false);

  // const todo = client.readFragment({
  //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
  //     fragment: gql`
  //       fragment myTodo on Product {
  //         id
  //         name
  //       }
  //     `,
  // });
  //console.log(data);
  // setData(datanya)
 

  return (
    <Fragment>
      {loading || lCategory ? <Loading show={true} />: <>
        <Breadcrumb title="Pengelolaan produk" parent="" />
        {user&&user.dokul_user_roles.some(x=>x.role_id==5 || x.role_id==4)&&<div className="d-flex flex-row mb-5">
          <div className="p-2">Dokterkulitku : </div>
          <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(1)}>JAKARTA</div>
          <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(2)}>BALIKPAPAN</div>
          <div className={`p-2  pointer ${selectedBranch == 3 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(3)}>ALL</div>
        </div>}
        {
          match.params.page == 'list' ?
            <Product_list typeStock={typeStock} settypeStock={settypeStock} setbrandSearch={setbrandSearch} brandSearch={brandSearch} setcategoriesSearch={setcategoriesSearch} categoriesSearch={categoriesSearch} txtSearch={textSearch} searchText={settextSearch} isLoading={isLoading}  nextData={handlePagination} dRole={dRole} dCategory={dCategory} dataproducts={data.products.data} saveCategory={saveCategory} refetch={refetch} updateStock={updateStock} selectedBranch={selectedBranch}/>
            : match.params.page == 'detail' ?
              <Product_detail />
              : match.params.page == 'edit' ?
                <Product_edit dRole={dRole} data={data} dCategory={dCategory} id={match.params.id} product={data.products.data.find(x=>x.id==match.params.id)} />
                : match.params.page == 'add' ?
                  <Product_add dRole={dRole}  data={data} dCategory={dCategory} saveProduct={saveProduct} history={history} mutationCalled={mutationCalled} mutationError={mutationError} />
                  : match.params.page == 'categories' ?
                    <CategoryList dCategory={dCategory} saveCategory={saveCategory} />
                    :
                    <Product_list dCategory={dCategory} saveCategory={saveCategory} />
        }
      </>
      }

    </Fragment>
  )

}

export default withApollo(Products)
