import React, { Component } from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap';


export class BadgeCheck extends Component {
    render() {
        return (
            <Badge className='p-2 m-2 border text-dark' color='white'>
                <span className={`rounded-circle border p-1 ${this.props.check && 'bg-primary'}`} style={{ cursor: 'pointer' }} onClick={() => this.props.onChange(!this.props.check)}>{this.props.check ? <i className='fa fa-check' /> : <i className='fa fa-circle-o' />}</span>
                {/* <span className='rounded-circle border p-1'><i className='icofont icofont-toggle-off'/></span> */}
                {this.props.text}
                <span className="bg-success rounded p-1 m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={this.props.addJadwal}><i className="icofont icofont-plus" /></span>
                <span className="bg-info rounded p-1 m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={this.props.editPatern}>Pattern</span>
            </Badge>
        )
    }
}

export default BadgeCheck
