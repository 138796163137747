import React, { Component, Fragment, useState } from 'react'
import Breadcrumb from '../common/breadcrumb';
//import data from '../../assets/data/listCoupons';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { withApollo } from '../../apollo'
import { Collapse } from 'reactstrap';
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { GET_LIST_KONSULTASI, GET_DOKPOINTS, GET_PRODUCTS, SAVE_POINT_PRODUCTS, SAVE_POINT, GET_VOUCHERS, SAVE_VOUCHER_PRODUCTS, SAVE_VOUCHER, SAVE_VOUCHER_RULES, SAVE_VOUCHER_CATEGORIES } from '../../constants/graph';
import { Alert, Badge, FormGroup, Label, Input, Button, ListGroup, ListGroupItem } from 'reactstrap';
import Modal from 'react-responsive-modal';
import { Edit, Trash2, Plus, XCircle, X, Search } from 'react-feather'
import gql from 'graphql-tag';
const listBranch = [{ id: 1, branch: "Jakarta" }, { id: 2, branch: "Balikpapan" }]
const listKurir = ['jnt', 'jne', 'sicepat']
const ListPoint = ({listProduct}) => {
    const { loading, data: dokVouchers, error } = useQuery(GET_VOUCHERS,);
    // const { loading: loadingProduct, data: listProduct, error: errorProduct } = useQuery(GET_PRODUCTS,);
    const [filterProduct, setfilterProduct] = useState("")
    const [selectedIndex, setsselectedIndex] = useState(0)
    const [expandEarned, setexpandEarned] = useState(false)
    const [expandResProd, setexpandResProd] = useState(false)
    const [expandresCat, setexpandresCat] = useState(false)
    const [expandRules, setexpandRules] = useState(false)
    const userGrade = ['', 'Classic', 'Silver', 'Gold', 'Platinum']
    const valueCheckold = ['', 'ORDER::USEPOINT', 'ORDER::TOTAL', 'ORDERITEM::QUANTITY', 'ORDERITEM::SUBTOTAL', 'ORDER::BRANCH', 'ORDER::KURIR', 'ORDER::MULTIPLEVOUCHER']
    const valueCheck = ['', 'ORDER::USEPOINT::DISABLE', 'ORDER::TOTAL', 'ORDERITEM::QUANTITY', 'ORDERITEM::SUBTOTAL', 'ORDER::BRANCH', 'ORDER::KURIR', 'ORDER::MULTIPLEVOUCHER::DISABLE','PRODUCT::FIELD::IS_PREMIUM']
    const ruleMath = ['', 'SAMADENGAN', 'TIDAK_SAMADENGAN', 'LEBIH_KECIL', 'LEBIHBESAR']

    const [openModalProduct, setModalProduct] = useState(false)
    const [selectedRules, setselectedRules] = useState([])
    const [openModalRules, setopenModalRules] = useState(false)
    const [openModalCat, setModalCat] = useState(false)
    const [openModalAdd, setopenModalAdd] = useState(false)
    const [editedPoint, seteditedPoint] = useState(null)
    const [newPoint, setnewPoint] = useState(null)
    const client = useApolloClient()
    const getProduct = (idproduct) => client.readFragment({
        id: `Product:${idproduct}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment editProduct on Product {
            name,
            dokul_products_skus
            branchId,
            dokul_tags_categories,
            dokul_product_images
            id
            description
            dokul_product_descriptions
            tags
            is_restricted 
            nick_name
            video
          }
        `,
    }, false);

    const getCategories = (idcategori) => client.readFragment({
        id: `Category:${idcategori}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment getCategori on Category {
            id,
      name,
      description
          }
        `,
    }, false);

    const updateCachePoint = (cache, { data }) => {

        console.warn(data)
        const _newListProduct = [...data.saveVoucherProducts]
        let _point = { ...dokVouchers.vouchers[selectedIndex] }
        _point.dokul_voucher_products = _newListProduct
        const fragmentResult = cache.writeFragment({
            id: `Voucher:${_point.id}`,
            fragment: gql`
        fragment voucher on Voucher{
            id,
      name,
      create_date
      expired_date
      auto_apply
      is_limited
      is_active
      is_fixed
      voucher_value
      is_private
      branch_id
      dokul_voucher_rules,
      dokul_voucher_products
      dokul_voucher_categories
      dokul_voucher_grades
        }
      `,
            data: _point
        });
    };
    const [savePointProducts] = useMutation(SAVE_VOUCHER_PRODUCTS, { update: updateCachePoint });

    const updateCacheCategories = (cache, { data }) => {

        console.warn(data)
        const _newListProduct = [...data.saveVoucherCategories]
        let _point = { ...dokVouchers.vouchers[selectedIndex] }
        _point.dokul_voucher_categories = _newListProduct
        const fragmentResult = cache.writeFragment({
            id: `Voucher:${_point.id}`,
            fragment: gql`
         fragment voucher on Voucher{
             id,
       name,
       create_date
       expired_date
       auto_apply
       is_limited
       is_active
       is_fixed
       voucher_value
       is_private
       branch_id
       voucher_for
       earn_type
       auto_show
       dokul_voucher_rules,
       dokul_voucher_products
       dokul_voucher_categories
       dokul_voucher_grades
         }
       `,
            data: _point
        });
    };
    const [savePointCategories] = useMutation(SAVE_VOUCHER_CATEGORIES, { update: updateCacheCategories });

    const updateCacheRules = (cache, { data }) => {

        console.warn(data)
        const _newListProduct = [...data.saveVoucherRules]
        let _point = { ...dokVouchers.vouchers[selectedIndex] }
        _point.dokul_voucher_rules = _newListProduct
        const fragmentResult = cache.writeFragment({
            id: `Voucher:${_point.id}`,
            fragment: gql`
         fragment voucher on Voucher{
             id,
       name,
       create_date
       expired_date
       auto_apply
       is_limited
       is_active
       is_fixed
       voucher_value
       is_private
       branch_id
       voucher_for
       voucher_max
       earn_type
       auto_show
       dokul_voucher_rules,
       dokul_voucher_products
       dokul_voucher_categories
       dokul_voucher_grades
         }
       `,
            data: _point
        });
        setopenModalRules(false)
    };
    const [savePointRules] = useMutation(SAVE_VOUCHER_RULES, { update: updateCacheRules });





    const [savePoint, { loading: mutationCategoryLoading, error: mutationCategoryError, called: mutationCategoryCalled },] = useMutation(SAVE_VOUCHER,);

    if (loading ) return "loading"

    return (
        <Fragment>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>DokVouchers <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                setnewPoint({
                                    is_active: 0, is_limited: 0, is_fixed: 1, dokul_voucher_details: [], dokul_voucher_rules: [],
                                    dokul_voucher_grades: [
                                        { grade_id: 1, voucher_value: 0, }, { grade_id: 2, voucher_value: 0, }, { grade_id: 3, voucher_value: 0, }, { grade_id: 4, voucher_value: 0, }
                                    ]
                                })
                                setopenModalAdd(true)
                            }}
                            >ADD</span></li>
                            {/* <li className={`list-group-item ${selectedGudang == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => { }}>Semua gudang</li> */}


                            {dokVouchers.vouchers.filter(x=>x.isPrivate!=1).map((poin, idx) =>
                                <li key={idx} className={`list-group-item ${selectedIndex == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    seteditedPoint(null)
                                    setsselectedIndex(idx)

                                }}>{poin.name}</li>

                            )}

                        </ul>

                    </div>
                    <div className="col-sm-8">
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Voucher detail </li>

                            <li className={`list-group-item bg-white w-100`}>

                                <div className="form-group row mb-2">

                                    <label className="col-xl-3 col-md-4">Name</label>
                                    <div className="col-md-7">
                                        <input className="form-control" name="name" disabled={editedPoint == null ? true : false} id="name-point" type="text" value={dokVouchers.vouchers[selectedIndex].name} required="" onChange={(e) => {
                                            let _editedPoint = { ...editedPoint }
                                            _editedPoint.name = e.target.value
                                            seteditedPoint(_editedPoint)


                                        }} />
                                    </div>
                                </div>


                                <div className="form-group row mb-2 ">
                                    <label className="col-xl-3 col-md-4">Active</label>
                                    <div className="col-md-7">
                                        <div className=" checkbox-space">
                                            <label className="d-block">
                                                <input className="checkbox_animated" disabled={editedPoint == null ? true : false} id="chk-ani2" type="checkbox" checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].is_active == 1 ? true : false : editedPoint.is_active == 1 ? true : false} onChange={(e) => {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.is_active = e.target.checked ? 1 : 0
                                                    seteditedPoint(_editedPoint)

                                                }} />

                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mb-2 ">
                                    <label className="col-xl-3 col-md-4">Auto show</label>
                                    <div className="col-md-7">
                                        <div className=" checkbox-space">
                                            <label className="d-block">
                                                <input className="checkbox_animated" disabled={editedPoint == null ? true : false} id="auto_show" type="checkbox" checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].auto_show == 1 ? true : false : editedPoint.auto_show == 1 ? true : false} onChange={(e) => {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.auto_show = e.target.checked ? 1 : 0
                                                    seteditedPoint(_editedPoint)

                                                }} />

                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mb-2 ">
                                    <label className="col-xl-3 col-md-4">Limited</label>
                                    <div className="col-md-7">
                                        <div className=" checkbox-space">
                                            <label className="d-block">
                                                <input className="checkbox_animated" disabled={editedPoint == null ? true : false} id="chk-ani2" type="checkbox" checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].is_limited == 1 ? true : false : editedPoint.is_limited == 1 ? true : false} onChange={(e) => {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.is_limited = e.target.checked ? 1 : 0
                                                    seteditedPoint(_editedPoint)

                                                }} />

                                            </label>
                                            {/* {(editedPoint == null && dokVouchers.vouchers[selectedIndex].is_limited == 1) || (editedPoint != null && editedPoint.is_limited == 1) ? <>
                                                <input disabled={editedPoint == null ? true : false} className="form-control mt-2" name="earned_type" id="earned_type-point" type="text" value={editedPoint == null ? dokVouchers.vouchers[selectedIndex].dokul_voucher_details.length : editedPoint.dokul_voucher_details.length} required="" onChange={(e) => {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.voucher_value = e.target.value
                                                    seteditedPoint(_editedPoint)

                                                }} />
                                                <small id="passwordHelpBlock " className="form-text text-mutedI text-warning">
                                                    Jumlah voucher saat ini
                                                </small>
                                            </> : ""} */}

                                            {(editedPoint == null && dokVouchers.vouchers[selectedIndex].is_limited == 1) || (editedPoint != null && editedPoint.is_limited == 1) ? <>
                                                <input disabled={editedPoint == null ? true : false} className="form-control mt-2" name="earned_type" id="earned_type-point" type="number" value={editedPoint == null ? dokVouchers.vouchers[selectedIndex].voucher_max : editedPoint.voucher_max} required="" onChange={(e) => {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.voucher_value = e.target.value
                                                    seteditedPoint(_editedPoint)

                                                }} />
                                                <small id="passwordHelpBlock " className="form-text text-mutedI text-warning">
                                                    Max voucher
                                                </small>
                                            </> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mb-2 ">
                                    <label className="col-xl-3 col-md-4">Voucher type </label>
                                    <div className="col-md-7">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="is_fixed" name="is_fixed" className="custom-control-input" disabled={editedPoint == null ? true : false} checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].is_fixed == 1 ? true : false : editedPoint.is_fixed == 1 ? true : false} onChange={(e) => {
                                                let _editedPoint = { ...editedPoint }
                                                _editedPoint.is_fixed = e.target.checked ? 1 : 0
                                                seteditedPoint(_editedPoint)

                                            }} />
                                            <label className="custom-control-label ml-2" htmlFor="customRadioInline1"> Fixed</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline mt-2">
                                            <input type="radio" id="is_fixed" name="is_fixed" className="custom-control-input mr-2" disabled={editedPoint == null ? true : false} checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].is_fixed == 0 ? true : false : editedPoint.is_fixed == 0 ? true : false} onChange={(e) => {
                                                let _editedPoint = { ...editedPoint }
                                                _editedPoint.is_fixed = e.target.checked ? 0 : 1
                                                seteditedPoint(_editedPoint)

                                            }} />
                                            <label className="custom-control-label ml-2" htmlFor="customRadioInline2"> Persentase</label>
                                        </div>
                                        {(editedPoint == null && dokVouchers.vouchers[selectedIndex].is_fixed == 0) || (editedPoint != null && editedPoint.is_fixed == 0) ? <>
                                            <input disabled={editedPoint == null ? true : false} className="form-control mt-2 col-5" name="earned_type" id="earned_type-point" type="number" value={editedPoint == null ? dokVouchers.vouchers[selectedIndex].voucher_value : editedPoint.voucher_value} required="" onChange={(e) => {
                                                let _editedPoint = { ...editedPoint }
                                                _editedPoint.voucher_value = e.target.value
                                                seteditedPoint(_editedPoint)

                                            }} />
                                            <small id="passwordHelpBlock " className="form-text text-warning">
                                                ini adalah base perkalian persentase yang didapat
                                            </small>
                                        </> : ""}
                                    </div>
                                </div>

                                <div className="form-group row mb-2 ">
                                    <label className="col-xl-3 col-md-4">Voucher earn type</label>
                                    <div className="col-md-7">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="earn_type" name="earn_type" className="custom-control-input" disabled={editedPoint == null ? true : false} checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].earn_type == 1 ? true : false : editedPoint.earn_type == 1 ? true : false} onChange={(e) => {
                                                if (e.target.checked) {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.earn_type = 1
                                                    seteditedPoint(_editedPoint)
                                                }


                                            }} />
                                            <label className="custom-control-label ml-2" htmlFor="customRadioInline1"> Discount</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline mt-2">
                                            <input type="radio" id="earn_type" name="earn_type" className="custom-control-input mr-2" disabled={editedPoint == null ? true : false} checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].earn_type == 2 ? true : false : editedPoint.earn_type == 2 ? true : false} onChange={(e) => {
                                                if (e.target.checked) {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.earn_type = 2
                                                    seteditedPoint(_editedPoint)
                                                }


                                            }} />
                                            <label className="custom-control-label ml-2" htmlFor="customRadioInline2"> Dokpoin cashback</label>
                                        </div>

                                    </div>
                                </div>

                                {((editedPoint&&editedPoint.earn_type==1))&&<div className="form-group row mb-2 ">
                                    <label className="col-xl-3 col-md-4"> Voucher for </label>
                                    <div className="col-md-7">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="voucher_for" name="voucher_for" className="custom-control-input" disabled={editedPoint == null ? true : false} checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].voucher_for == 1 ? true : false : editedPoint.voucher_for == 1 ? true : false} onChange={(e) => {
                                                if (e.target.checked) {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.voucher_for = 1
                                                    seteditedPoint(_editedPoint)
                                                }


                                            }} />
                                            <label className="custom-control-label ml-2" htmlFor="customRadioInline1"> Ongkir</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline mt-2">
                                            <input type="radio" id="voucher_for" name="voucher_for" className="custom-control-input mr-2" disabled={editedPoint == null ? true : false} checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].voucher_for == 2 ? true : false : editedPoint.voucher_for == 2 ? true : false} onChange={(e) => {
                                                if (e.target.checked) {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.voucher_for = 2
                                                    seteditedPoint(_editedPoint)
                                                }


                                            }} />
                                            <label className="custom-control-label ml-2" htmlFor="customRadioInline2"> Total order</label>
                                        </div>

                                    </div>
                                </div>}

                                {(!editedPoint&&dokVouchers.vouchers[selectedIndex].earn_type == 1)&&<div className="form-group row mb-2 ">
                                    <label className="col-xl-3 col-md-4"> Voucher for </label>
                                    <div className="col-md-7">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="voucher_for" name="voucher_for" className="custom-control-input" disabled={editedPoint == null ? true : false} checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].voucher_for == 1 ? true : false : editedPoint.voucher_for == 1 ? true : false} onChange={(e) => {
                                                if (e.target.checked) {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.voucher_for = 1
                                                    seteditedPoint(_editedPoint)
                                                }


                                            }} />
                                            <label className="custom-control-label ml-2" htmlFor="customRadioInline1"> Ongkir</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline mt-2">
                                            <input type="radio" id="voucher_for" name="voucher_for" className="custom-control-input mr-2" disabled={editedPoint == null ? true : false} checked={editedPoint == null ? dokVouchers.vouchers[selectedIndex].voucher_for == 2 ? true : false : editedPoint.voucher_for == 2 ? true : false} onChange={(e) => {
                                                if (e.target.checked) {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.voucher_for = 2
                                                    seteditedPoint(_editedPoint)
                                                }


                                            }} />
                                            <label className="custom-control-label ml-2" htmlFor="customRadioInline2"> Total order</label>
                                        </div>

                                    </div>
                                </div>}

                                <div className="form-group row align-items-start">
                                    <label className="col-xl-3 col-md-4">Voucher value by grade</label>
                                    <div className="col-md-7">

                                        {[1, 2, 3, 4].map((grade, idx) => {
                                            let currentreward = editedPoint == null ? dokVouchers.vouchers[selectedIndex].dokul_voucher_grades?.find(rew => rew.grade_id == grade) : editedPoint.dokul_voucher_grades.find(rew => rew.grade_id == grade)
                                            const rewards = currentreward ? currentreward.voucher_value : 0
                                            return <div key={idx} className="form-group row mb-2">
                                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label"><span className={`p-2 bg-${grade == 1 ? 'classic' : grade == 2 ? 'silver' : grade == 3 ? 'gold' : 'platinum'} text-${grade == 1 ? 'light' : grade == 2 ? 'dark' : grade == 3 ? 'dark' : 'dark'} w-100 rounded`}>{userGrade[grade]}</span></label>
                                                <div className="col-sm-8">
                                                    <input disabled={editedPoint == null ? true : false} type="number" min="1" className="form-control" value={rewards} onChange={(e) => {
                                                        let _currentreward = { ...currentreward }
                                                        let _editedPoint = { ...editedPoint }
                                                        let listRewGrade = [..._editedPoint.dokul_voucher_grades]

                                                        let _idx = listRewGrade.findIndex(rew => rew.grade_id == grade)
                                                        _currentreward.voucher_value = e.target.value
                                                        listRewGrade[_idx] = _currentreward
                                                        _editedPoint.dokul_voucher_grades = listRewGrade
                                                        seteditedPoint(_editedPoint)


                                                    }} />
                                                </div>
                                            </div>
                                        }
                                        )}
                                    </div>
                                </div>
                                <button type="button" className={`btn float-end mt-2 ${editedPoint == null ? "btn-light" : "btn-primary "}`} onClick={() => {
                                    if (editedPoint == null)
                                        seteditedPoint(dokVouchers.vouchers[selectedIndex])
                                    else {
                                        savePoint({
                                            variables: {
                                                data: editedPoint
                                            }
                                        }).then(res => seteditedPoint(null))

                                    }
                                }}>{editedPoint == null ? "Edit" : "Save"}</button>
                            </li>
                            {/* <li className={`list-group-item  w-100`}
                                onClick={() => {
                                    setexpandEarned(!expandEarned)
                                }}>Poin Earned</li> */}
                            <li className={`list-group-item  w-100`}>
                                <Collapse isOpen={true}>
                                    <div className="tab-pane bg-white fade active show">
                                        <form className="needs-validation" noValidate="">

                                            <div className="row">
                                                <div className="col-sm-12">

                                                    {/* <div className="form-group row mb-2">
                                                        <label className="col-xl-3 col-md-4">Basic earned</label>
                                                        <div className="col-md-7">
                                                            <input disabled={editedPoint == null ? true : false} className="form-control " name="earned_type" id="earned_type-point" type="text" value={editedPoint == null ? dokVouchers.vouchers[selectedIndex].earned : editedPoint.earned} required="" onChange={(e) => {
                                                                let _editedPoint = { ...editedPoint }
                                                                _editedPoint.earned = e.target.value
                                                                seteditedPoint(_editedPoint)

                                                            }} />
                                                        </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Collapse>
                            </li>

                            <li className={`list-group-item  w-100`}
                                onClick={() => {
                                    setexpandResProd(!expandResProd)
                                }}>Product restriction</li>
                            <li className={`list-group-item  w-100`}>
                                <Collapse isOpen={expandResProd}>
                                    <div className="tab-pane card fade active show">
                                        <form className="needs-validation" noValidate="">
                                            <div className="form-group row">

                                                <div className="col-xl-8 col-sm-7">
                                                    <ListGroup>
                                                        {dokVouchers.vouchers[selectedIndex]?.dokul_voucher_products?.map(product => {
                                                            let _prod = getProduct(product.product_id)
                                                            return <ListGroupItem className="justify-content-between" ><i className='fa fa-close' style={{ cursor: 'pointer', }}></i><span style={{ verticalAlign: "middle" }}> {_prod?.name} </span></ListGroupItem>

                                                            //     <Button close aria-label="Cancel">
                                                            //     <span aria-hidden>&ndash; {product.name}</span>
                                                            //   </Button>
                                                        })}
                                                        <ListGroupItem className="justify-content-between" ><button type="button" className="btn btn-primary" onClick={(e) => { setModalProduct(true) }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">EDIT </button></ListGroupItem>
                                                    </ListGroup>

                                                </div>

                                            </div>

                                        </form>

                                    </div>

                                </Collapse>
                            </li>

                            <li className={`list-group-item  w-100`}
                                onClick={() => {
                                    setexpandresCat(!expandresCat)
                                }}>Category restriction</li>
                            <li className={`list-group-item  w-100`}>
                                <Collapse isOpen={expandresCat}>
                                    <div className="tab-pane card fade active show">
                                        <form className="needs-validation" noValidate="">
                                            <div className="form-group row">

                                                <div className="col-xl-8 col-sm-7">
                                                    <ListGroup>
                                                        {dokVouchers.vouchers[selectedIndex]?.dokul_voucher_categories?.map((cat,icat) => {
                                                            let _categori = getCategories(cat.category_id)
                                                            return <ListGroupItem key={icat} className="justify-content-between" ><i className='fa fa-close' style={{ cursor: 'pointer', }}></i><span style={{ verticalAlign: "middle" }}> {_categori?.name} </span></ListGroupItem>

                                                            //     <Button close aria-label="Cancel">
                                                            //     <span aria-hidden>&ndash; {product.name}</span>
                                                            //   </Button>
                                                        })}
                                                        <ListGroupItem className="justify-content-between" ><button type="button" className="btn btn-primary" onClick={(e) => { setModalCat(true) }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">EDIT </button></ListGroupItem>
                                                    </ListGroup>

                                                </div>

                                            </div>

                                        </form>

                                    </div>

                                </Collapse>
                            </li>

                            <li className={`list-group-item  w-100 pointer`}
                                onClick={() => {
                                    setexpandRules(!expandRules)
                                }}>Voucher rules</li>
                            <li className={`list-group-item  w-100`}>
                                <Collapse isOpen={expandRules}>
                                    <div className="tab-pane card fade active show">
                                        <form className="needs-validation" noValidate="">
                                            <div className="form-group row">

                                                <div className="col">
                                                    <ListGroup>
                                                        {dokVouchers.vouchers[selectedIndex]?.dokul_voucher_rules.map((rule, idx) => {
                                                            return <ListGroupItem key={idx} className="justify-content-between" >
                                                                <button type="button" className="btn btn-xs btn-light">
                                                                    <i className='fa fa-close text-danger' style={{ cursor: 'pointer', }}></i>
                                                                </button>
                                                                {/* <button type="button" className="btn btn-xs btn-light" onClick={() => {
                                                                    
                                                                }}>
                                                                    <i className='fa fa-edit text-success' style={{ cursor: 'pointer', }}></i>
                                                                </button> */}
                                                                <span className="ml-2" style={{ verticalAlign: "middle" }}>
                                                                    <span className="border p-2">
                                                                        {valueCheck[rule.voucher_rule]}
                                                                        {rule.voucher_rule != 1 && rule.voucher_rule != 7 && `::${ruleMath[rule.rule_math]}`}
                                                                        {rule.voucher_rule != 1 && rule.voucher_rule != 7 && (rule.voucher_rule == 5 && rule.rule_value == 1 ? "::JAKARTA" : rule.voucher_rule == 5 && rule.rule_value == 2 ? "::BALIKPAPAN" : `::${rule.rule_value}`)}
                                                                    </span>
                                                                    {/* {rule.voucher_rule != 1 && rule.voucher_rule != 7 && <>
                                                                        <span className="border p-2 m-2">{ruleMath[rule.rule_math]}</span>
                                                                        <span className="border p-2">{rule.voucher_rule == 5 && rule.rule_value == 1 ? "JAKARTA" : rule.voucher_rule == 5 && rule.rule_value == 2 ? "BALIKPAPAN" : rule.rule_value}</span>
                                                                    </>
                                                                    } */}
                                                                </span>
                                                            </ListGroupItem>

                                                            //     <Button close aria-label="Cancel">
                                                            //     <span aria-hidden>&ndash; {product.name}</span>
                                                            //   </Button>
                                                        })}
                                                        <ListGroupItem className="justify-content-between" ><button type="button" className="btn btn-primary" onClick={(e) => {

                                                            setopenModalRules(true)
                                                            setselectedRules(dokVouchers.vouchers[selectedIndex].dokul_voucher_rules)
                                                        }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Edit</button></ListGroupItem>
                                                    </ListGroup>

                                                </div>

                                            </div>

                                        </form>

                                    </div>

                                </Collapse>
                            </li>
                        </ul>

                    </div>
                    {/* <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Dokpoin rules</h5>
                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="category-table order-table coupon-list-delete">
                                        <ReactTable
                                            data={myData}
                                            columns={columns}
                                            defaultPageSize={pageSize}
                                            className={myClass}
                                            showPagination={pagination}
                                        />
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
            <Modal open={openModalRules} onClose={() => setopenModalRules(false)} >
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">Voucher rules</h5>
                </div>
                <div className="modal-body">
                    <div className="d-flex flex-column bd-highlight mb-3">
                        {valueCheck.map((x, idx) => {
                            const isEnable = selectedRules.find(cx => cx.voucher_rule == idx)

                            if (idx == 4 || idx == 3 || idx == 0) return ""
                            else if (idx == 7 || idx ==  1) return <div key={idx} className="d-flex flex-column bd-highlight mb-3">
                                <div className="p-2 bd-highlight  bg-main">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={isEnable && isEnable.voucher_rule == idx} onChange={(e) => {
                                            let curRules = [...selectedRules]
                                            if (e.target.checked) {
                                                curRules.push({
                                                    description: "",
                                                    rule_math: 1,
                                                    rule_value: "false",
                                                    voucher_id: dokVouchers.vouchers[selectedIndex].id,
                                                    voucher_rule: idx
                                                })

                                            } else {
                                                const index = curRules.findIndex(cr => cr.voucher_rule == idx)
                                                curRules.splice(index, 1)

                                            }
                                            setselectedRules(curRules)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            {x}
                                        </label>
                                    </div>
                                </div>
                                {isEnable && isEnable.voucher_rule == idx && <div className="p-2 bd-highlight">
                                    <div className="form-group">
                                        <textarea type="text" rows="2" value={isEnable && isEnable.description} className="form-control" id="description" placeholder="description" onChange={(e) => {
                                            let curRules = [...selectedRules]
                                            const index = curRules.findIndex(cr => cr.voucher_rule == idx)
                                            curRules[index].description = e.target.value
                                            setselectedRules(curRules)
                                        }} />
                                    </div>
                                </div>}
                            </div>
                            else return <div key={idx} className="d-flex flex-column bd-highlight mb-3">
                                <div className="p-2 bd-highlight bg-main">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={isEnable && isEnable.voucher_rule == idx} onChange={(e) => {
                                            let curRules = [...selectedRules]
                                            if (e.target.checked) {
                                                curRules.push({
                                                    description: "",
                                                    rule_math: 0,
                                                    rule_value: "",
                                                    voucher_id: dokVouchers.vouchers[selectedIndex].id,
                                                    voucher_rule: idx
                                                })

                                            } else {
                                                const index = curRules.findIndex(cr => cr.voucher_rule == idx)
                                                curRules.splice(index, 1)

                                            }
                                            setselectedRules(curRules)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            {x}
                                        </label>
                                        
                                        
                                    </div>
                                </div>
                                {isEnable && isEnable.voucher_rule == idx && <>
                                    <div className="p-2 bd-highlight">
                                        <div className="form-group">
                                            <select className="form-control" id="rule_math" onChange={(e) => {
                                                let curRules = [...selectedRules]
                                                const index = curRules.findIndex(cr => cr.voucher_rule == idx)
                                                curRules[index].rule_math = e.target.value
                                                setselectedRules(curRules)
                                            }} >
                                                {ruleMath.map((y, idy) => <option key={idy} value={idy} selected={isEnable && isEnable.rule_math == idy}>{y}</option>)}

                                            </select>
                                        </div>
                                    </div>
                                    <div className="p-2 bd-highlight">

                                        {idx == 6 &&

                                            listKurir.map((kur, kidx) =>
                                                <div key={kidx} className="form-check">
                                                    <input className="form-check-input" type="checkbox" name={`rule_value${kidx}${idx}`} id={`rule_value${kidx}${idx}`} value={kur} checked={isEnable.rule_value!=""&&isEnable.rule_value.includes(kur)} onChange={(e) => {
                                                        let curRules = [...selectedRules]
                                                        const index = curRules.findIndex(cr => cr.voucher_rule == idx)
                                                        let listCurKurir=curRules[index].rule_value.split(",")
                                                        if (e.target.checked) {
                                                            
                                                            listCurKurir.push(e.target.value)
                                                            curRules[index].rule_value = listCurKurir.join()

                                                        } else {
                                                           const idxKurir=listCurKurir.indexOf(e.target.value)
                                                           
                                                           listCurKurir.splice(idxKurir, 1)
                                                           curRules[index].rule_value = listCurKurir.join()
                                                        }
                                                        setselectedRules(curRules)
                                                    }} />
                                                    <label className="form-check-label" htmlFor={`rule_value${kidx}${idx}`}>
                                                        {kur}
                                                    </label>
                                                </div>
                                            )

                                        }
                                        {idx == 5 && listBranch.map((bra, bidx) => <div key={bidx} className="form-check">
                                            <input className="form-check-input" type="radio" name={`rule_value${idx}`} id={`rule_value${idx}`} value={bra.id} checked={isEnable.rule_value == bra.id} onChange={(e) => {
                                                        let curRules = [...selectedRules]
                                                        const index = curRules.findIndex(cr => cr.voucher_rule == idx)
                                                       
                                                        if (e.target.checked) {
                                                            curRules[index].rule_value = e.target.value

                                                        }
                                                        setselectedRules(curRules)
                                                    }} />
                                            <label className="form-check-label" htmlFor={`rule_value${idx}`}>
                                                {bra.branch}
                                            </label>
                                        </div>)}
                                        {(idx == 2 || idx==8 )&& <div className="form-group">
                                            <input type="text" value={isEnable && isEnable.rule_value} className="form-control" id="rule_value" placeholder="Nilai" onChange={(e) => {
                                                let curRules = [...selectedRules]
                                                const index = curRules.findIndex(cr => cr.voucher_rule == idx)
                                                curRules[index].rule_value = e.target.value
                                                setselectedRules(curRules)
                                            }} />
                                        </div>}

                                    </div>
                                    <div className="p-2 bd-highlight">
                                        <div className="form-group">
                                            <textarea type="text" rows="2" value={isEnable && isEnable.description} className="form-control" id="description" placeholder="description" onChange={(e) => {
                                                let curRules = [...selectedRules]
                                                const index = curRules.findIndex(cr => cr.voucher_rule == idx)
                                                curRules[index].description = e.target.value
                                                setselectedRules(curRules)
                                            }} />
                                        </div>
                                    </div>
                                </>
                                }

                            </div>

                            // <option key={idx} value={x} selected={selectedRule.voucher_rule == idx}>{x}</option>

                        }
                        )}


                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="voucher_rule">Rule</label>
                        <select className="form-control" id="voucher_rule">
                            {valueCheck.map((x, idx) => {
                                if (idx == 4 || idx == 3) return ""
                                else return <option key={idx} value={x} selected={selectedRule.voucher_rule == idx}>{x}</option>

                            }
                            )}


                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="rule_math">Operator</label>
                        <select className="form-control" id="rule_math">
                            {ruleMath.map((x, idx) => <option key={idx} value={x} selected={selectedRule.rule_math == idx}>{x}</option>)}

                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="rule_value">Nilai</label>
                        <input type="text" value={selectedRule.rule_value} className="form-control" id="rule_value" placeholder="Nilai" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea type="text" rows="2" value={selectedRule.description} className="form-control" id="description" placeholder="description" />
                    </div> */}


                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => {
                        let curPoint = { ...dokVouchers.vouchers[selectedIndex] }
                        curPoint.dokul_voucher_rules = selectedRules
                        savePointRules({
                            variables: { type: curPoint }
                        })

                    }}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setopenModalRules(false)}>Close</button>
                </div>
            </Modal>
            <Modal open={openModalProduct} onClose={() => setModalProduct(false)} >
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">{'Add product to point'}</h5>
                </div>
                <div className="modal-body">
                    <div className="row">


                        <div className="col-sm-12 ">

                            <div className="form-group">

                                <input className={"form-control-plaintext "} type="search" placeholder="Search.." onChange={(e) => {
                                    setfilterProduct(e.target.value)

                                }} />
                                <span className="d-sm-none mobile-search"><Search /></span>
                            </div>
                            {listProduct.filter(p => p.name.toLowerCase().includes(filterProduct)).map((p, i) =>
                                // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                <FormGroup key={i}>
                                    <Input key={i} type="checkbox" id={`prd ${i}`} label={p.name} checked={dokVouchers.vouchers[selectedIndex].dokul_voucher_products.some(ct1 => ct1.product_id == p.id)} value={p} onClick={(e) => {
                                        let curPoint = { ...dokVouchers.vouchers[selectedIndex] }
                                        let selecttedProducts = [...curPoint.dokul_voucher_products]

                                        if (e.target.checked) {
                                            let poinPro = {
                                                product_id: p.id,
                                                status: 1,
                                                voucher_id: curPoint.id
                                            }
                                            selecttedProducts.push(poinPro)
                                        }
                                        else {
                                            const index = selecttedProducts.findIndex(pr => pr.product_id == p.id)
                                            selecttedProducts.splice(index, 1)
                                        }
                                        curPoint.dokul_voucher_products = selecttedProducts
                                        savePointProducts({
                                            variables: { type: curPoint }
                                        })
                                        // this.setState({
                                        //     point: curPoint

                                        // })

                                    }} />
                                    <Label>{p.name} </Label>
                                </FormGroup>

                            )}

                        </div>

                    </div>
                </div>
                <div className="modal-footer">
                    {/* <button type="button" className="btn btn-primary" onClick={() => this.addRules()}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button> */}
                </div>
            </Modal>

            <Modal open={openModalCat} onClose={() => setModalCat(false)} >
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">{'Add category to point'}</h5>
                </div>
                <div className="modal-body">
                    <div className="row">


                        <div className="col-sm-12 ">

                            {/* <div className="form-group">

                                <input className={"form-control-plaintext "} type="search" placeholder="Search.." onChange={(e) => {
                                    setfilterProduct(e.target.value)

                                }} />
                                <span className="d-sm-none mobile-search"><Search /></span>
                            </div> */}
                            {listProduct.categories?.map((p, i) =>
                                // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                <FormGroup key={i} check>
                                    <Input key={i} type="checkbox" id={`prd ${i}`} label={p.name} checked={dokVouchers.vouchers[selectedIndex].dokul_voucher_categories.some(ct1 => ct1.category_id == p.id)} value={p} onClick={(e) => {
                                        let curPoint = { ...dokVouchers.vouchers[selectedIndex] }
                                        let selecttedProducts = [...curPoint.dokul_voucher_categories]

                                        if (e.target.checked) {
                                            let poinPro = {
                                                category_id: p.id,
                                                status: 1,
                                                voucher_id: curPoint.id
                                            }
                                            selecttedProducts.push(poinPro)
                                        }
                                        else {
                                            const index = selecttedProducts.findIndex(pr => pr.category_id == p.id)
                                            selecttedProducts.splice(index, 1)
                                        }
                                        curPoint.dokul_voucher_categories = selecttedProducts
                                        savePointCategories({
                                            variables: { type: curPoint }
                                        })
                                        // this.setState({
                                        //     point: curPoint

                                        // })

                                    }} />
                                    <Label>{p.name} </Label>
                                </FormGroup>

                            )}

                        </div>

                    </div>
                </div>
                <div className="modal-footer">
                    {/* <button type="button" className="btn btn-primary" onClick={() => this.addRules()}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button> */}
                </div>
            </Modal>

            <Modal open={openModalAdd} onClose={() => setopenModalAdd(false)} >
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">{'Add new voucher'}</h5>
                </div>
                {newPoint == null ? "" : <div className="modal-body">
                    <div className="row">


                        <div className="col-sm-12 ">

                            <ul className="list-group">
                                <li className={`list-group-item bg-primary w-100`}>VOUCHER DETAIL </li>

                                <li className={`list-group-item bg-white w-100`}>

                                    <div className="form-group row mb-2">

                                        <label className="col-xl-3 col-md-4">Name</label>
                                        <div className="col-md-7">
                                            <input className="form-control" name="name" id="name-point" type="text" value={newPoint.name} required="" onChange={(e) => {
                                                let _editedPoint = { ...newPoint }
                                                _editedPoint.name = e.target.value
                                                setnewPoint(_editedPoint)


                                            }} />
                                        </div>
                                    </div>


                                    <div className="form-group row mb-2 ">
                                        <label className="col-xl-3 col-md-4">Aktif</label>
                                        <div className="col-md-7">
                                            <div className=" checkbox-space">
                                                <label className="d-block">
                                                    <input className="checkbox_animated" type="checkbox" checked={newPoint.is_active == 1 ? true : false} onChange={(e) => {
                                                        let _editedPoint = { ...newPoint }
                                                        _editedPoint.is_active = e.target.checked ? 1 : 0
                                                        setnewPoint(_editedPoint)

                                                    }} />

                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-2 ">
                                        <label className="col-xl-3 col-md-4">Limited</label>
                                        <div className="col-md-7">
                                            <div className=" checkbox-space">
                                                <label className="d-block">
                                                    <input className="checkbox_animated" id="chk-ani2" type="checkbox" checked={newPoint.is_limited == 1 ? true : false} onChange={(e) => {
                                                        let _editedPoint = { ...newPoint }
                                                        _editedPoint.is_limited = e.target.checked ? 1 : 0
                                                        setnewPoint(_editedPoint)

                                                    }} />

                                                </label>
                                                {(newPoint.is_limited == 1) ? <>
                                                    <input className="form-control mt-2" name="earned_type" id="earned_type-point" type="text" value={newPoint.dokul_voucher_details.length} required="" onChange={(e) => {
                                                        let _editedPoint = { ...newPoint }
                                                        _editedPoint.voucher_value = e.target.value
                                                        setnewPoint(_editedPoint)

                                                    }} />
                                                    <small id="passwordHelpBlock " className="form-text text-mutedI text-warning">
                                                        Jumlah voucher saat ini
                                                    </small>
                                                </> : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-2 ">
                                        <label className="col-xl-3 col-md-4">Voucher type </label>
                                        <div className="col-md-7">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="is_fixed" name="nis_fixed" className="custom-control-input" checked={newPoint.is_fixed == 1 ? true : false} onChange={(e) => {
                                                    let _editedPoint = { ...newPoint }
                                                    _editedPoint.is_fixed = e.target.checked ? 1 : 0
                                                    setnewPoint(_editedPoint)

                                                }} />
                                                <label className="custom-control-label ml-2" htmlFor="customRadioInline1"> Fixed</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline mt-2">
                                                <input type="radio" id="is_fixed" name="nis_fixed" className="custom-control-input mr-2" checked={newPoint.is_fixed == 0 ? true : false} onChange={(e) => {
                                                    let _editedPoint = { ...newPoint }
                                                    _editedPoint.is_fixed = e.target.checked ? 0 : 1
                                                    setnewPoint(_editedPoint)

                                                }} />
                                                <label className="custom-control-label ml-2" htmlFor="customRadioInline2"> Persentase</label>
                                            </div>
                                            {(newPoint.is_fixed == 0) ? <>
                                                <input className="form-control mt-2" name="earned_type" id="earned_type-point" type="text" value={newPoint.voucher_value} required="" onChange={(e) => {
                                                    let _editedPoint = { ...newPoint }
                                                    _editedPoint.voucher_value = e.target.value
                                                    setnewPoint(_editedPoint)

                                                }} />
                                                <small id="passwordHelpBlock " className="form-text text-warning">
                                                    ini adalah base perkalian persentase yang didapat
                                                </small>
                                            </> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group row align-items-start">
                                        <label className="col-xl-3 col-md-4">Voucher value by grade</label>
                                        <div className="col-md-7">

                                            {[1, 2, 3, 4].map(grade => {
                                                let currentreward = newPoint != null ? newPoint.dokul_voucher_grades.find(rew => rew.grade_id == grade) : 0
                                                const rewards = currentreward ? currentreward.voucher_value : 0
                                                return <div className="form-group row mb-2">
                                                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label"><span className={`p-2 bg-${grade == 1 ? 'classic' : grade == 2 ? 'silver' : grade == 3 ? 'gold' : 'platinum'} text-${grade == 1 ? 'light' : grade == 2 ? 'dark' : grade == 3 ? 'dark' : 'dark'} w-100 rounded`}>{userGrade[grade]}</span></label>
                                                    <div className="col-sm-8">
                                                        <input type="number" min="1" className="form-control" value={rewards} onChange={(e) => {
                                                            let _currentreward = { ...currentreward }
                                                            let _editedPoint = { ...newPoint }
                                                            let listRewGrade = [..._editedPoint.dokul_voucher_grades]

                                                            let _idx = listRewGrade.findIndex(rew => rew.grade_id == grade)
                                                            _currentreward.voucher_value = e.target.value
                                                            listRewGrade[_idx] = _currentreward
                                                            _editedPoint.dokul_voucher_grades = listRewGrade
                                                            setnewPoint(_editedPoint)


                                                        }} />
                                                    </div>
                                                </div>
                                            }
                                            )}
                                        </div>
                                    </div>

                                </li>

                            </ul>

                        </div>

                    </div>
                </div>}
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => {
                        savePoint({
                            variables: {
                                data: newPoint
                            }
                        }).then(res => setopenModalAdd(false))
                    }}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setopenModalAdd(false)}>Cancel</button>
                </div>
            </Modal>

        </Fragment>
    )
}
// }
export default withApollo(ListPoint)
