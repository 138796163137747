
import React, { Component } from 'react'

import { Popover, PopoverHeader, Badge, Button, Media, Modal, ModalHeader, ModalBody } from 'reactstrap'
import moment from 'moment'
import Tooltipitem from './TooltipItem'
import PoperItem from './popoveritem'
import { withApollo } from '../../../apollo'
import { GET_USER_DETAIL, GET_USER_DETAIL_QUERY } from '../../../constants/graph'
import Profile from '../../users/user.detail'

const gradeList = ["Unknown", "Classic", "Silver", "Gold", 'Platinum']
const orderStatusArray = [
    // { id: 1, status: 1, text: "Order Diterima" },
    { id: 1, status: 1, text: "Menunggu pembayaran" },
    { id: 99, status: 12, text: "Verifikasi struk pembayaran" },
    { id: 2, status: 2, text: "Pembayaran terverifikasi" },
    { id: 3, status: 31, text: "Packing untuk expedisi" },
    { id: 3, status: 32, text: "Packing untuk driver" },
    { id: 99, status: 311, text: "Sedang rekap" },
    // { id: 2, status: 321, text: "Packing untuk driver sudah print" },
    // { id: 2, status: 33, text: "Dalam pengiriman" },
    { id: 2, status: 21, text: "Menunggu stock beberapa item" },
    { id: 99, status: 22, text: "Menunggu stock " },
    { id: 4, status: 41, text: "Batal beberapa item" },
    { id: 4, status: 42, text: "Batal oleh admin" },
    { id: 99, status: 43, text: "Batal oleh system" },
    { id: 2, status: 5, text: "Sudah dikirim" },
    { id: 2, status: 51, text: "Selesai sudah review" }

]

class ModelOrderDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderHistory: [],
            popoverOpen3: false,
            showStruk: false,
            order: this.props.order,
            paymentDetail: {},
            userDetail:undefined,
            modalDetailUser :false,
            isLoading:false
        }
    }
    // handleGetUserDetail=()=>{
    //     getUserDetail({
    //         variables: {
    //             id: selectedKontrol.user_id
    //         }
    //     }).then((result) => {
    //         if (result.data) {

    //             // setuserDetail(result.data.userDetail)
    //             // setmodalDetailUser(true)
    //         }
    //     })
    // }
    componentDidMount() {

        fetch(`${process.env.REACT_APP_API_HOST}/api/order/history/${this.props.order.id}`, { credentials: 'include', })
            .then(response => response.json())
            .then(data => {
                this.setState({ orderHistory: data })
                const payMethode = this.props.config ? this.props.config.configs.find(x => x.id == this.props.order.branch_id).dokul_paymethods.find(y => y.id == this.props.order.payment_method) : {}
                if (payMethode.type > 30)
                    this.getPaymentDetail(this.props.order.id)

            })
    }
    toggle3 = () => {
        this.setState({
            popoverOpen3: !this.state.popoverOpen3
        })

    }
    changePaymentStatus = (status) => {

        let order = { ...this.props.order }
        order.payment_status = status
        order.order_status = status
        order.order_status_text = status == 2 ? "Pembayaran terkonfirmasi" : "Menunggu pembayaran kembali"

        this.props.updateOrder({ variables: { id: order.id, type: order } });
        this.setState({
            order: order,
            popoverOpen3: !this.state.popoverOpen3
        })


    }
    isActiveXenditPay = () => {
        // if (order.dokul_paymethod.type > 30 && order.dokul_paymethod.type < 40) {

        // } else if (order.dokul_paymethod.type > 40 && order.dokul_paymethod.type < 50) {
        //     const minuteExpired=order.dokul_paymethod.type==41?1:30
        //     return (moment.utc(paymentDetail.created).local().diff(moment(), "minute")+minuteExpired) > 0

        // } else if (order.dokul_paymethod.type > 50) {
        //     return moment.utc(paymentDetail.expiration_date).local().diff(moment(), "hour") > 0
        // }
        return true


    }
    getPaymentDetail = async (konsulId) => {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/order/xenditPayDetail/${konsulId}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: JSON.stringify({ pertanyaan: pertanyaan }) // body data type must match "Content-Type" header
        });
        if (response.ok) {
            const jsonData = await response.json();
            const chargeObj = JSON.parse(jsonData.charge.data_submit)
            this.setState({ paymentDetail: chargeObj })
        }
        return "ok"



    }
    // componentDidUpdate=(prev,curr)=>{
    //     console.log(prev)
    //     console.log(curr)
    // }
    changeOrderStatus = (id, order) => {
        //console.log("ganti status")
        // let order = { ...this.state.order }
        // order.order_status = status
        // order.order_status_text = this.state.orderStatus[status]
        this.props.updateOrder({
            variables: { id: id, type: order },
        });
        // this.setState({
        //     order: order,
        //     popoverOpen: !this.state.popoverOpen
        // })


    }
    getOrderStatus = (order) => {
        // const order=this.props.order
        if ((order.order_status == 1 || order.order_status == 2 || order.order_status == 12) && order.payment_status == 2) {
            return <span className={`p-2 rounded badge bg-info`}>Pembayaran terverifikasi</span>
        }
        else if ((order.order_status == 2)) {
            return <span className={`p-2 rounded badge bg-info`}>Pembayaran terverifikasi manual</span>
        }
        else if ((order.order_status == 12)) {
            return <span className={`p-2 rounded badge bg-info text-white`}>{order.order_status_text}</span>
        }
        else if (order.dokul_order_item_refunds && order.dokul_order_item_refunds.length > 0) {
            return <>
                <span className={`p-2 rounded badge order_status_${order.order_status}`}>{order.order_status_text}</span>
                {/* <br /> */}
                {order.order_status != 41 && order.order_status != 42 ? <span className={`p-2 rounded badge text-white`}>Beberapa item cancel</span> : ""}
            </>
        } else {
            return <>
                {order.order_status == 321 || order.order_status == 311 ? <i className="icofont icofont-print p-1" /> : ""}
                {/* <br /> */}
                <span className={`p-2 rounded badge order_status_${order.order_status} text-white`} >{order.order_status_text}</span>
            </>
        }
    }
    
    setmodalDetailUser=(val,userDetail)=>{
        this.setState({
            userDetail:userDetail
            
        },()=>this.setState({
            modalDetailUser:val,
            isLoading:false
        }))

    }
    testclient = (id) => {
        // console.log(this.props)
        // return
        this.setState({isLoading:true})
        this.props.client.mutate({
            variables: { id: id },
            mutation: GET_USER_DETAIL,

        }).then(result => { this.setmodalDetailUser(true,result.data.userDetail) })

    }
    render() {

        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        const allBranchRole = [5, 4]
        const jktRole = [2, 11]
        const bpnRole = [3, 10]
        const columns = [];
        const apotekROle=[16,17]
        const adminPremiumRole=[18]

        const packerJkt=[19]
        const packerBpn=[15]

        const itemStatus = ['', 'Menunggu stock', "Dibatalkan", 'Selesai']
        const { orderHistory, popoverOpen3, order, paymentDetail,userDetail,modalDetailUser,isLoading } = this.state
        const { config, setviewDetailOrder } = this.props
        const payMethode = config ? config.configs.find(x => x.id == order.branch_id).dokul_paymethods.find(y => y.id == order.payment_method) : {}

        return (
            <div className="card">
                <Modal isOpen={modalDetailUser} toggle={() => this.setmodalDetailUser(false,undefined)} centered fullscreen>
                <ModalHeader  className='bg-white' toggle={() => this.setmodalDetailUser(false,undefined)} ></ModalHeader>
                <ModalBody className='bg-light'> 
                        {userDetail&&<Profile 
                        dPrivateVoucher={this.props.dPrivateVoucher}//{userDetail.dokul_voucher_privates}
                         dProducts={this.props.dProduct.productssimple}//{userDetail.dokul_user_products} 
                         setselectedUser={(val)=>console.log(val)} 
                         setModalOpenChangeRole={(val)=>console.log(val)} 
                         dUserDetail={userDetail} 
                         closeModal={() => this.setmodalDetailUser(false,undefined)} 
                         isNew={false}  />}
                         </ModalBody>
            </Modal>

                <div className="container">
                    <div className="row">
                        {/* <nav className="navbar navbar-default"> */}
                        <div className="col-12 mt-2"  >
                            <div className="d-flex flex-row bd-highlight  justify-content-left">
                                <button className="btn p-2 btn-light" onClick={() => setviewDetailOrder(false)}><i className="icofont icofont-arrow-left" /> Kembali</button>
                                <h5 className="card-title m-3">{`${order.branch_id == 1 ? "J" : "B"}${order.id} - ${order.dokul_user.display_name}`}</h5>
                            </div>
                        </div>

                        {/* </nav> */}
                        <div className="col-8">

                            <div className="card mr-0">

                                <div className="card-body pb-0">
                                    <h5 className="card-title  border-bottom text-info font-weight-bold">Info</h5>
                                    <dl className="row">
                                        <dt className="col-sm-4 mb-2">Tanggal</dt>
                                        <dd className="col-sm-8">{moment(order.created_date).format("DD,MM-YYYY")}</dd>
                                        <dt className="col-sm-4 mb-2">Status</dt>
                                        <dd className="col-sm-8">{this.getOrderStatus(order)}</dd>
                                        <dt className="col-sm-4 mb-2">Dokpoin digunakan</dt>
                                        <dd className="col-sm-8">{order.is_usepoint == 1 ? order.point_used + ' Dokpoin' : 'Tidak ada'} </dd>
                                        <dt className="col-sm-4 mb-2">Kupon digunakan</dt>
                                        <dd className="col-sm-8">{order.is_usecoupon == 1 ? order.coupon_used : 'Tidak ada'}</dd>
                                    </dl>

                                    {(order.order_status != 5) && <div className="d-flex flex-row bd-highlight">
                                        {/* <span className="border p-2 rounded m-1"  >
                                    <Link
                                        to={`/sales/orders/detail/${order.id}`}
                                    >
                                        <i className="icofont icofont-box" style={{ fontSize: 20, padding: 0, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                                    </Link>
                                </span> */}




                                        <div className="p-2 bd-highlight">
                                            {[
                                                {
                                                    placement: "top",
                                                    text: "Menunggu beberapa stock barang",
                                                    title: "Pilih item",
                                                    icon: "icofont icofont-hour-glass",
                                                    warna: "rgb(255, 102, 102)",
                                                    skus: order.dokul_orders_items,
                                                    statusDirubah: 1,
                                                    status: 21
                                                },

                                            ].map((tooltip, i) => {
                                                return <PoperItem key={i} item={tooltip} id={`${order.id}-${i}-2`} icon={"icofont icofont-adjust "} aksi={
                                                    (skus) => {
                                                        let _order = { ...order }
                                                        _order.dokul_orders_items = skus
                                                        _order.order_status = tooltip.status
                                                        _order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                        this.changeOrderStatus(_order.id, _order)
                                                        this.setState({
                                                            order: _order
                                                        })
                                                    }
                                                } />;
                                            })}
                                            {[
                                                {
                                                    placement: "top",
                                                    text: "Menunggu stock barang",
                                                    icon: "icofont icofont-hour-glass",
                                                    warna: "rgb(204, 102, 0)",
                                                    status: 22

                                                },

                                            ].map((tooltip, i) => {
                                                return <Tooltipitem key={i} item={tooltip} id={`${order.id}-${i}-2`} icon={"icofont icofont-adjust "} aksi={
                                                    (e) => {
                                                        let _order = { ...order }
                                                        let newItems = _order.dokul_orders_items.map(it => {
                                                            let _it = { ...it }
                                                            _it.status = 1
                                                            return _it
                                                        })
                                                        _order.dokul_orders_items = newItems
                                                        _order.order_status = tooltip.status
                                                        _order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text

                                                        this.changeOrderStatus(_order.id, _order)
                                                        this.setState({
                                                            order: _order
                                                        })
                                                    }
                                                } />;
                                            })}
                                        </div>

                                        <div className="p-2 bd-highlight">
                                            {[
                                                {
                                                    placement: "top",
                                                    text: "Batalkan beberapa barang",
                                                    title: "Pilih item",
                                                    icon: "icofont icofont-exclamation-square",
                                                    warna: "rgb(255, 102, 102)",
                                                    skus: order.dokul_orders_items,
                                                    statusDirubah: 2,
                                                    status: 41
                                                },

                                            ].map((tooltip, i) => {
                                                return <PoperItem key={i} item={tooltip} id={`${order.id}-${i}-3`} icon={"icofont icofont-adjust "} aksi={
                                                    (skus) => {
                                                        let _order = { ...order }
                                                        _order.dokul_orders_items = skus
                                                        _order.order_status = tooltip.status
                                                        _order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                        this.changeOrderStatus(_order.id, _order)
                                                        this.setState({
                                                            order: _order
                                                        })
                                                    }
                                                } />;
                                            })}

                                            {[

                                                {
                                                    placement: "right",
                                                    text: "Batalkan pesanan",
                                                    icon: "icofont icofont-close-squared",
                                                    warna: "rgb(255, 0, 0)",
                                                    status: 42
                                                }
                                            ].map((tooltip, i) => {
                                                return <Tooltipitem key={i} item={tooltip} id={`${order.id}-${i}-3`} icon={"icofont icofont-adjust "} aksi={
                                                    (e) => {
                                                        let _order = { ...order }
                                                        let newItems = _order.dokul_orders_items.map(it => {
                                                            let _it = { ...it }
                                                            _it.status = 2
                                                            return _it
                                                        })
                                                        _order.dokul_orders_items = newItems
                                                        _order.order_status = tooltip.status
                                                        _order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                        this.changeOrderStatus(_order.id, _order)
                                                        this.setState({
                                                            order: _order
                                                        })
                                                    }
                                                } />;
                                            })}
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            {[
                                                {
                                                    placement: "left",
                                                    text: `Packing  ${order.layanan_kurir.toLowerCase() == '0' ? 'pengambilan diklinik' : `Expedisi ${order.layanan_kurir.toLowerCase()}`}`,
                                                    icon: `${order.layanan_kurir.toLowerCase().includes('jne') ? "iconjne" : order.layanan_kurir.toLowerCase().includes('pos') ? "iconpos" : order.layanan_kurir.toLowerCase().includes('j&t') ? "iconjnt" : order.layanan_kurir.toLowerCase().includes('sicepat') ? "iconsicepat" : "icofont icofont-box"}`,
                                                    warna: "rgb(0, 128, 255)",
                                                    status: 31
                                                },

                                            ].map((tooltip, i) => {
                                                return <Tooltipitem key={i} item={tooltip} id={`${order.id}-${i}`} icon={"icofont icofont-adjust "} aksi={
                                                    (e) => {
                                                        let _order = { ...order }
                                                        _order.order_status = tooltip.status
                                                        _order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                        //ganti status item yang selain cancel menjadi 0 agar ikut status order
                                                        let _orderItems = _order.dokul_orders_items.filter(oif => oif.status != 2).map(oi => {
                                                            let _oi = { ...oi }
                                                            _oi.status = 0
                                                            return _oi
                                                        })
                                                        _order.dokul_orders_items = _orderItems;

                                                        this.changeOrderStatus(_order.id, _order)
                                                        this.setState({
                                                            order: _order
                                                        })
                                                    }
                                                } />;
                                            })}
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            {/* <span className="border p-2 rounded m-1" onClick={()=>this.onOpenModal(row.index)}><i className="icofont icofont-eye" style={{ fontSize: 20, padding: 0, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                              */}


                                            {[

                                                {
                                                    placement: "left",
                                                    text: "Pembayaran Diterima",
                                                    icon: "icofont icofont-thumbs-up",
                                                    warna: "rgb(40, 167, 69)",
                                                    status: 2
                                                },
                                                {
                                                    placement: "left",
                                                    text: "Sedang Rekap",
                                                    icon: "icofont icofont-print",
                                                    warna: "rgb(177, 230, 84)",
                                                    status: 311
                                                },
                                                {
                                                    placement: "left",
                                                    text: "Dalam Pengiriman",
                                                    icon: "icofont icofont-paper-plane",
                                                    warna: "rgb(0, 128, 255)",
                                                    status: 5
                                                },
                                            ].map((tooltip, i) => {
                                                return <Tooltipitem key={i} item={tooltip} id={`${order.id}-${i}-4`} icon={"icofont icofont-adjust "} aksi={
                                                    (e) => {
                                                        let _order = { ...order }
                                                        _order.order_status = tooltip.status
                                                        _order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                        this.changeOrderStatus(_order.id, _order)
                                                        this.setState({
                                                            order: _order
                                                        })
                                                    }
                                                } />;
                                            })}


                                        </div>





                                    </div>
                                    }

                                </div>
                                <div className="card-body pb-0">
                                    <h5 className="card-title  border-bottom text-info font-weight-bold">Customer</h5>
                                    <dl className="row">
                                        <dt className="col-sm-12 mb-2">{order.dokul_user.display_name}</dt>
                                        <dd className="col-sm-12 mb-2">{order.dokul_user.phone}</dd>
                                        <dd className="col-sm-12 mb-2">{order.dokul_user.email}</dd>
                                        {gradeList[order.current_grade] ? <dd className="col-sm-12 mb-2">{gradeList[order.current_grade]}  Shopper</dd> : <dd className="col-sm-12 mb-2">{order.current_grade}</dd>}
                                    </dl>
                                    <Button onClick={() => this.testclient(order.dokul_user.id)} disabled={isLoading}>{isLoading?"Loading":"Detail customer"}</Button>

                                </div>
                                <div className="card-body">



                                    <h5 id="payment-pop" className="card-title  border-bottom text-info font-weight-bold">Pembayaran</h5>
                                    {!payMethode && <h4>{order.payment_method_text}</h4>}
                                    {payMethode && <div className="row">
                                        <div className="col-6 mb-2">
                                            <div className="mt-2 mr-2 bd-highlight">Pembayaran melalui  </div>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <div className="mt-2 mr-2 ml-auto bd-highlight">
                                                {payMethode.type < 30 && payMethode.name}
                                                {payMethode.type > 30 && payMethode.type < 40 && <>
                                                    {paymentDetail && paymentDetail.card_brand == "VISA" && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/visa-logo.svg`} className='img-fluid  w-25' /><br /><strong>Card Number : {paymentDetail.masked_card_number}</strong></>}
                                                    {paymentDetail && paymentDetail.card_brand == "MASTERCARD" && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/mc-logo.svg`} className='img-fluid  w-25' /><br /><strong>Card Number : {paymentDetail.masked_card_number}</strong></>}
                                                    {paymentDetail && paymentDetail.card_brand == "JCB" && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/jcb-logo.svg`} className='img-fluid  w-25' /><br /><strong>Card Number : {paymentDetail.masked_card_number}</strong></>}

                                                </>}
                                                {payMethode.type === 41 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/ovo-logo.svg`} className='img-fluid  w-50' />}
                                                {payMethode.type === 42 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/shopeepay-logo.svg`} className='img-fluid  w-50' />}
                                                {payMethode.type === 43 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/dana-logo.svg`} className='img-fluid  w-50' />}

                                                {payMethode.type === 51 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bca-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
                                                {payMethode.type === 52 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/mandiri-logo.svg`} className='img-fluid w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
                                                {payMethode.type === 53 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bri-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
                                                {payMethode.type === 54 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bni-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
                                                {payMethode.type === 55 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/permata-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
                                                {payMethode.type === 56 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bsi-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
                                                {payMethode.type === 61 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/qris-logo.svg`} className='img-fluid  w-50' />}

                                            </div>
                                            {payMethode.type < 30 && <div className="mt-2 mr-2 ml-auto bd-highlight"> {`${payMethode.nm_pemilik} / ${payMethode.no_rek}`}</div>}
                                        </div>
                                        <div className="col-6 mb-2">
                                            <div className="mt-2 mr-2 bd-highlight">Status  </div>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <div className="mt-2 mr-2 ml-auto bd-highlight">: <span className={`p-2 rounded mt-3 mb-3 ${order.payment_status == 2 || order.order_status == 2 || order.payment_status == 21 || order.order_status_text.includes('pembayaran-diteri') || order.order_status_text.includes('pembayaran-telah-') ? 'bg-success' : 'bg-cred text-light'}`}>{order.payment_status == 2 || order.order_status_text.includes('pembayaran-diteri') || order.order_status_text.includes('pembayaran-telah-') ? 'Terkonfirmasi' : order.order_status == 2 || order.payment_status == 21 ? "Terkonfirmasi Manual" : 'Belum terkonfirmasi'}</span></div>
                                        </div>
                                        <div className="col-6 mb-2">

                                        </div>
                                        <div className="col-6 mb-2">
                                            <div className="p-2 bd-highlight">
                                                {/* <span className="border p-2 rounded m-1" onClick={()=>this.onOpenModal(row.index)}><i className="icofont icofont-eye" style={{ fontSize: 20, padding: 0, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                              */}


                                                {[

                                                    {
                                                        placement: "left",
                                                        text: "Pembayaran terkonfirmasi",
                                                        icon: "icofont icofont-thumbs-up",
                                                        warna: "rgb(40, 167, 69)",
                                                        status: 2
                                                    },
                                                    {
                                                        placement: "right",
                                                        text: "Menunggu pembayaran kembali",
                                                        icon: "icofont icofont-thumbs-down",
                                                        warna: "rgb(255, 102, 102)",
                                                        status: 1
                                                    },

                                                ].map((tooltip, i) => {
                                                    return <Tooltipitem key={i} item={tooltip} id={`${order.id}-${i + 200}-4`} icon={"icofont icofont-adjust "} aksi={
                                                        (e) => {
                                                            this.changePaymentStatus(tooltip.status)

                                                        }
                                                    } />;
                                                })}


                                            </div>
                                        </div>
                                        {/* <div className="col-12 border-top mt-3 p-2 text-center"> */}
                                        {/* <Button color="secondary" id="payment-pop">Ganti status</Button> */}

                                        {/* </div> */}
                                        {order.bukti_bayar ? <div className="col-12  border-top mt-3 text-center">


                                            <>
                                                <b style={{ cursor: "pointer" }} className="text-info" onClick={() => this.setState({ showStruk: !this.state.showStruk })}>Struk pembayaran</b>
                                                {this.state.showStruk && <img className="img-fluid blur-up bg-img lazyloaded" src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/uploads/buktibayar/${order.bukti_bayar}`} />}

                                            </>

                                        </div> : ""}
                                    </div>}


                                    {/* <dl className="row">
                                        <dt className="col-sm-12 mb-2">{payMethode.type == 11 || order.payment_method_text.includes('bca') ? 'Bank Transfer BCA' : payMethode.type == 12 || order.payment_method_text.includes('mandiri') ? 'Bank Transfer MANDIRI' : payMethode.type == 21 || payMethode.type == 2 ? 'QRIS' : 'TIDAK DIKENAL'} : <span className="float-right">{order.payment_method_text}</span></dt>
                                        <dd className={`col-sm-12 mb-2 mt-2`}><span className={`p-2 rounded mt-3 mb-3 ${order.payment_status == 2 || order.order_status_text.includes('pembayaran-diteri') || order.order_status_text.includes('pembayaran-telah-') ? 'bg-success' : 'bg-danger text-danger'}`}>{order.payment_status == 2 || order.order_status_text.includes('pembayaran-diteri') || order.order_status_text.includes('pembayaran-telah-') ? 'Terkonfirmasi' : 'Belum terkonfirmasi'}</span></dd>

                                    </dl> */}

                                    {/* <Popover
                                        placement="right"
                                        isOpen={popoverOpen3}
                                        target="payment-pop"
                                        toggle={this.toggle3}
                                    >
                                        <PopoverHeader>Select status</PopoverHeader>

                                        <div className="text-justify">
                                            <div className="row" style={{ margin: 10 }}>
                                                <div className="col">


                                                    <label className="d-block mb-2" >
                                                        <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.payment_status == 2 ? true : false} onClick={(e) => this.changePaymentStatus(2)} />
                                                        <span className="badge badge-success" style={{ padding: 5 }}>Konfirmasi</span>
                                                    </label>
                                                    <label className="d-block mb-2" >
                                                        <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.payment_status == 1 ? true : false} onClick={(e) => this.changePaymentStatus(1)} />
                                                        <span className="badge badge-cred" style={{ padding: 5 }}>Belum Terkonfirmasi</span>
                                                    </label>


                                                </div>
                                            </div>
                                            
                                        </div>

                                    </Popover> */}

                                </div>
                                <div className="card-body">
                                    <h5 className="card-title  border-bottom text-info font-weight-bold">Pengiriman</h5>


                                    {/* {!order.dokul_address ?
                                        <dl className="row">
                                            <dt className="col-sm-12 mb-2">{order.alamat_kirim && order.alamat_kirim != "" ? order.layanan_kurir : "Ambil diklinik"}</dt>
                                            <dd className="col-sm-12 mb-2">{order.dokul_address.first_name} {order.dokul_address.last_name}</dd>
                                            <dd className="col-sm-12 mb-2">{order.dokul_address.phone}</dd>
                                            <dd className="col-sm-12 mb-2">{order.dokul_address.address_1}</dd>
                                            <dd className="col-sm-12 mb-2">{order.dokul_address.address_state}</dd>
                                            <dd className="col-sm-12 mb-2">{order.dokul_address.address_city}</dd>
                                            <dd className="col-sm-12 mb-2">{order.dokul_address.address_2}</dd>
                                            <dd className="col-sm-12 mb-2">{order.dokul_address.address_postcode}</dd>
                                        </dl> : */}
                                    <dl className="row">

                                        <dt className="col-sm-12 mb-2">{order.alamat_kirim && order.alamat_kirim != "" ? order.layanan_kurir : "Ambil diklinik"}</dt>
                                        {order.shipping_address_id != -1 ? <>
                                            {order.nomor_resi && <dd className="col-sm-12 mb-2">Nomor Resi : {order.nomor_resi} </dd>}
                                            <p dangerouslySetInnerHTML={{ __html: order.alamat_kirim?.replaceAll('/n', '<br />') }} />

                                        </> : ""}

                                    </dl>


                                </div>

                            </div>

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title  border-bottom text-info font-weight-bold">Item</h5>
                                        {/* {order.dokul_orders_items.map((item, index) => */}
                                        {user && (allBranchRole.concat(adminPremiumRole).some(x => user.dokul_user_roles.some(y => y.role_id == x)))
                                        ?order.all_item.map((item, index) =>
                                            <div key={index} className="d-flex d-flex-row  justify-content-between border pt-2 pb-2 mb-2">
                                                <div className="col-8 d-flex d-flex-row">
                                                    <img className="col-sm-3 img-fluid m-2" height={50} src={`${process.env.REACT_APP_IMAGE_HOST}${item.image_url}`} />
                                                    <div className="col-sm-9 d-flex flex-column">
                                                        <h6>{item.product_name}</h6>
                                                        {!packerBpn.concat(packerJkt,apotekROle).some(x => user.dokul_user_roles.some(y => y.role_id == x))?<>
                                                        <span className="w-100">{item.price} X {item.quaintiy}</span>
                                                        <span className="w-100">{order.is_usecoupon == 1 || order.is_usepoint == 1 ? <del>{item.point_earned} Dokpoin</del> : item.point_earned + ' Dokpoin'} </span>
                                                        <span className={`rounded  w-100 item_status_${item.status}`}>{itemStatus[item.status]}</span>
                                                        </>: <span className="w-100">Jumlah: {item.quaintiy}</span>
    }
                                                    </div>
                                                </div>
                                                {!packerBpn.concat(packerJkt,apotekROle).some(x => user.dokul_user_roles.some(y => y.role_id == x))&&<div className="col-4"><h6 className="text-end m-2">{item.subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6></div>}
                                            </div>
                                        ):order.dokul_orders_items.map((item, index) =>
                                        <div key={index} className="d-flex d-flex-row  justify-content-between border pt-2 pb-2 mb-2">
                                            <div className="col-8 d-flex d-flex-row">
                                                <img className="col-sm-3 img-fluid m-2" height={50} src={`${process.env.REACT_APP_IMAGE_HOST}${item.image_url}`} />
                                                <div className="col-sm-9 d-flex flex-column">
                                                    <h6>{item.product_name}</h6>
                                                    {!packerBpn.concat(packerJkt,apotekROle).some(x => user.dokul_user_roles.some(y => y.role_id == x))?<> 
                                                    <span className="w-100">{item.price} X {item.quaintiy}</span>
                                                    <span className="w-100">{order.is_usecoupon == 1 || order.is_usepoint == 1 ? <del>{item.point_earned} Dokpoin</del> : item.point_earned + ' Dokpoin'} </span>
                                                    <span className={`rounded  w-100 item_status_${item.status}`}>{itemStatus[item.status]}</span>
                                                    </>:<span className="w-100">Jumlah : {item.quaintiy}</span>
    }
                                                </div>
                                            </div>
                                            {!packerBpn.concat(packerJkt,apotekROle).some(x => user.dokul_user_roles.some(y => y.role_id == x))&&<div className="col-4"><h6 className="text-end m-2">{item.subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6></div>}
                                        </div>
                                    )}

                                        {!packerBpn.concat(packerJkt,apotekROle).some(x => user.dokul_user_roles.some(y => y.role_id == x))&&<div className="border p-2 pb-2 mb-2">

                                            <dl className="row">
                                                <dt className="col-sm-6 mb-2">Total</dt>
                                                <dd className="col-sm-6 text-success text-end mr-2">{order.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                                <dt className="col-sm-6 mb-2">Admin</dt>
                                                <dd className="col-sm-6 text-success text-end">{order.admin_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                                <dt className="col-sm-6 mb-2">Kode bayar</dt>
                                                <dd className="col-sm-6 text-success text-end">{order.unix_payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                                <dt className="col-sm-6 mb-2">Ongkos kirim</dt>
                                                <dd className="col-sm-6 text-success text-end">{order.ongkir.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>

                                                {order.point_used > 0 && <dt className="col-sm-6 mb-2">Dokpoin</dt>}
                                                {order.point_used > 0 && <dd className="col-sm-6 text-danger text-end">{order.point_used.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>}
                                                {order.is_usecoupon > 0 && <dt className="col-sm-8 mb-2">
                                                    <div className='d-flex flex-column'>
                                                        <div className=''>Total Coupon/Voucher </div>
                                                        {order.dokul_order_vouchers && order.dokul_order_vouchers.map((x, idx) => {
                                                            return <div key={idx} className='p-2 text-muted '><small>{x.voucher_name} </small></div>
                                                        })}
                                                    </div>
                                                    {/* ({order.coupon_used})  */}
                                                </dt>}
                                                {order.coupon_discount > 0 && <dd className="col-sm-4 text-danger text-end">
                                                    <div className='d-flex flex-column'>
                                                        <div className=''>{order.coupon_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
                                                        {order.dokul_order_vouchers && order.dokul_order_vouchers.map((x, idx) => {
                                                            return <div key={idx} className='p-2 text-muted '><small>{x.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </small></div>
                                                        })}
                                                    </div>


                                                </dd>}


                                                <dt className="col-sm-6 mb-2">Grand total</dt>
                                                <dd className="col-sm-6 fw-bold text-end">{order.grand_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                            </dl>
                                        </div>
    }







                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4 bg-white">

                            <div className="card-body bg-white">
                                <h5 className="card-title  border-bottom text-info font-weight-bold">History</h5>
                                {/* <ul className="list-group"> */}
                                {/* {orderHistory.map((oh,index)=>
                                    <li key ={index} className={`list-group-item list-group-item-${index%2==0?"secondary":"light"}`}>{oh.action}</li>
                                )} */}
                                {/* <li className="list-group-item list-group-item-primary">Pembayaran di terima </li>
                                <li className="list-group-item list-group-item-secondary">Order status dirubah packing untuk driver oleh : maryati</li>
                                <li className="list-group-item list-group-item-success">Order status dirubah Dalam pengiriman oleh : maryati dengan nomor resi:1232321321321</li>
                                <li className="list-group-item list-group-item-danger">Order status dirubah Selesai oleh : maryati</li> */}
                                {/* </ul> */}
                                <ul className="timeline">
                                    {orderHistory.map((oh, index) =>
                                        <li key={index} className="timeline-inverted">
                                            <div className="timeline-badge warning"><i className="icofont icofont-credit-card"></i></div>
                                            <div className="timeline-panel">
                                                <div className="timeline-heading">
                                                    <h6 className="timeline-title fw-bold">{moment(oh.create_date).format("DD,MM-YYYY HH:mm")}</h6>
                                                </div>
                                                <div className="timeline-body">
                                                    <p>{oh.action}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>




                        </div>


                    </div>
                </div>

            </div>
        )
    }
}

export default withApollo(ModelOrderDetail)
