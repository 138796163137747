import React , { Component,Fragment,useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject,NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
import {GET_CATEGORIES,GET_TAGS,GET_DASHBOARD_DATA,GET_ORDERS, GET_PRODUCT_CART} from '../../constants/graph'

import Loading from '../common/loading'
import Errorpage from '../common/Errorpage'
const Produkcart = ({ props }) => {
    //const [data,setData]=useState(props.data)
   const { loading, data, error,fetchMore , refetch, networkStatus} = useQuery(GET_PRODUCT_CART);
   if (loading) return <Loading />;
   if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    return (
        <div className="col-xl-6 xl-100">
        <div className="card height-equal">
            <div className="card-header">
                <h5>Produk dalam keranjang</h5>
            </div>
            <div className="card-body">
                <div className="user-status table-responsive products-table">
                    <table className="table table-bordernone mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Nama</th>
                                <th scope="col">Quantity</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.pCart.map((p, index) =>
                                <tr key={index} >
                                    <td>{p.dokul_product.name}</td>
                                    <td className="digits">{p.prodcount}</td>
                                    <td className="font-primary">{p.dokul_product.branch_id}</td>
                                </tr>
                            )}


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    )
}
export default withApollo(Produkcart)
//export default ordertoday
