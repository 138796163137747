import React, { Component, useState, useContext, useEffect } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings } from 'react-feather'
import Modal from 'react-responsive-modal';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, writeQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { GET_CONFIGS, UPDATE_CONFIGS, UPDATE_SHIPMENT_METHOD } from '../../constants/graph'
import SocketContext from '../../provider/socketctx/context';
import DispatchContext from '../../provider/socketctx/disptachContext';
import gql from 'graphql-tag';
import QRCode from 'qrcode.react'
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
const GET_LIST_KOTA = gql`query kotas {
    kotas @rest(type: "Kota",path: "api/integrasi/getKota") {
        city_id
        city_name
        postal_code
        province
        province_id
        type

    }
    
  }
  `;
     const GET_SHIP = gql`query payship {
        
        shipmethod @rest(type: "ShipMethod",path: "api/branch/shipmethod") {
            branch_id
            gudang_id
            id
            name
            status
            image_url
            kode_kurir
        }
      }
      `;
//   const GET_LIST_KECAMATAN = gql`query kecamatans {
//     kecamatans @rest(type: "Kecamatan",path: "api/integrasi/getKecamatan") {
//         rajaongkir

//     }

//   }
//   `;
const GET_LIST_KECAMATAN = gql`
  query kecamatans(
  $id: String!
) {
    kecamatans(id: $id)
  @rest(
    type: "Kecamatan"
    path: "api/integrasi/getKecamatan/{args.id}"
    method: "GET"
  ) {
    rajaongkir
}
}
`;
const BranchGudang = (props) => {
    var { loading: lKota, data: dKota, error: eKota } = useQuery(GET_LIST_KOTA,);
    var [getKecamatan, { loading: lKecamatan, data: dKecamatan, error: eKecamatan }] = useLazyQuery(GET_LIST_KECAMATAN,);
    var { loading: lShip, data: dShip, error: eShip,refetch:refetchShip } = useQuery(GET_SHIP,);
    const [mapedKota, setmapedKota] = useState({})
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownKabkotOpen, setdropdownKabkotOpen] = useState(false)
    const [dropdownKecamatanOpen, setdropdownKecamatanOpen] = useState(false)
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const toggleKabkot = () => setdropdownKabkotOpen(prevState => !prevState);
    const toggleKecamatan = () => setdropdownKecamatanOpen(prevState => !prevState);
    const [provinsi, setprovinsi] = useState()
    const [kabkot, setkabkot] = useState()
    const [subdistrict, setsubdistrict] = useState()
    const { dokul_configs, dokul_paymethods, dokul_gudangs } = props.data
    const { positionInLine, queueLength, message, socketId, waconnect } = useContext(SocketContext);
    const newMessage = useContext(DispatchContext)
    const { loading, data, error, fetchMore } = useQuery(GET_CONFIGS,);

    const [waCode, setWaCode] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedGudang, setselectedGudang] = useState(null)
    const [idxGudang, setidxGudang] = useState(null)
    const [selectedShipment, setselectedShipment] = useState([])
    const [updateOption, setupdateOption] = useState("")
    useEffect(() => {
        if (dKota) {
            var bar = new Promise((resolve, reject) => {
                let _dkota = {}
                dKota.kotas.forEach((value, index, array) => {
                    if (!_dkota[value.province]) _dkota[value.province] = [];
                    _dkota[value.province].push(value)

                    if (index === array.length - 1) resolve(_dkota);
                });
            });

            bar.then((res) => {
                setmapedKota(res)
            });

        }
    }, [dKota])
    useEffect(() => {
        if (dKecamatan) {


        }
    }, [dKecamatan])
    const updateCache = (cache, { data }) => {



        if (selectedGudang != null && dShip.shipmethod) {
            setselectedGudang(null)
            setidxGudang(null)
            refetchShip()
            return
        }
        return
        let currentConfigs = { ...props.data }
        let currentGudangs = [...currentConfigs.dokul_gudangs]

        let idxGudang = currentGudangs.findIndex(dt => dt.id == data.updateConfigs.gudang_id)

        let currentGudang = { ...currentGudangs[idxGudang] }
        let currentShipings = [...currentGudang.dokul_shippingmethods]
        let idxShipment = currentShipings.findIndex(dt => dt.id == data.updateConfigs.id)
        let currentShipment = { ...currentShipings[idxShipment] }





        currentShipment.status = data.updateConfigs.status
        currentShipings[idxShipment] = currentShipment

        currentGudang.dokul_shippingmethods = currentShipings


        currentGudangs[idxGudang] = currentGudang
        currentConfigs.dokul_gudangs = currentGudangs

        const fragmentResult = cache.writeFragment({
            id: `Configs:${currentConfigs.id}`,
            fragment: gql`
          fragment Configs on Configs{
            about_banner
            about_text
            address_pickup
            admin_fee
            dokul_configs
            dokul_paymethods
            dokul_shippingmethods
            dokul_gudangs
            dokul_kliniks
            id
            is_pickup_klinik
            konsul_banner
            konsul_text
            name
            page_meta
            page_title
            picture
          }
        `,
            data: currentConfigs
            //  {
            //   __typename: 'Configs',
            //   id:1,
            //   dokul_shippingmethods:currentSM
            // }
        });
        // console.log(fragmentResult)
        // Add the new todo to the cache
        // const newTodo = data.insert_todos.returning[0];
        // cache.writeQuery({
        //   query: GET_ROBOT_GUIDE,
        //   data: data.updateRobot
        // });
    };
    const [updateconfigs, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(UPDATE_SHIPMENT_METHOD, { update: updateCache });


    useEffect(() => {
        console.log(waconnect)
        if (waconnect.sessid) {
            delete waconnect['sessid'];
            fetch("http://localhost:9001/api/integrasi/authWa", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(waconnect)
            }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        setModalOpen(false)

                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {

                    }
                )


        }

    }, [waconnect])

    const getWaInit = () => {
        let myheaders = {
            "sessid": socketId
        }
        fetch("http://localhost:9001/api/integrasi/initWa", { headers: myheaders })
            .then(res => res.json())
            .then(
                (result) => {
                    setWaCode(result.code)
                    setModalOpen(true)

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                }
            )
    }

    if (loading || lShip) return "LOADING"
    //console.log(data)

    return (
        <div className="row">

            <div className="col-sm-12">
                {dokul_gudangs.map((gud, gudIdx) =>
                    <div className="account-setting">

                        <h5 className="f-w-600 f-16 border-bottom text-info pb-2">{gud.nama}</h5>
                        <div className="form-group row mb-2">
                            <label className="col-xl-3 col-md-3">Nama</label>
                            <div className="col-md-9">
                                <input className="form-control" name="name" disabled={selectedGudang != null && idxGudang == gudIdx ? false : true} id="name-point" value={selectedGudang != null && idxGudang == gudIdx ? selectedGudang.nama : gud.nama} type="text" required="" onChange={(e) => {
                                    let _editRow = { ...selectedGudang }
                                    _editRow.nama = e.target.value
                                    setselectedGudang(_editRow)
                                }} />
                            </div>
                        </div>
                        <div className="form-group mb-3 row align-items-start">
                            <label className="col-xl-2 col-sm-3 mb-0">Ekspedisi :</label>
                            <div className="col-xl-10 col-sm-9">

                                <div className="">
                                    {dShip.shipmethod.filter(x => x.branch_id == gud.branch_id && x.gudang_id==gud.id).map((dsp, idxShip) =>
                                        <label className="d-block" >
                                            <input className="checkbox_animated" id="chk-ani" type="checkbox" disabled={selectedGudang != null && idxGudang == gudIdx ? false : false} checked={dsp.status == 1} onChange={(e) => {
                                                // setupdateOption("shipment")
                                                // let listUpdate=[...selectedShipment]
                                                // if(e.target.checked){
                                                //     listUpdate.push(dsp)
                                                // }else{
                                                //     const idx=listUpdate.findIndex(x=>x.id==dsp.id)
                                                //    listUpdate.splice(idx,1)


                                                // }
                                                // setselectedShipment(listUpdate)

                                                updateconfigs({
                                                    context: {
                                                        headers: {
                                                            "option": "SHIPMENT"
                                                        }
                                                    },
                                                    variables: {
                                                        body: dsp,
                                                        id: "shipment"
                                                    }
                                                })

                                            }} />{dsp.name}</label>
                                    )}

                                </div>

                            </div>
                            <label className="col-xl-2 col-sm-3 mb-0 mt-3 ">Alamat asal :</label>
                            <div className="col-xl-10 col-sm-9 mt-3 p-0 ">

                                <div className="col-auto">
                                    <label className="sr-only" for="inlineFormInputGroup"></label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Provinsi</div>
                                        </div>
                                        {selectedGudang == null && idxGudang != gudIdx ? <input type="text" className="form-control" id="inlineFormInputGroup" disabled={selectedGudang != null && idxGudang == gudIdx ? false : true} placeholder="" value={selectedGudang != null && idxGudang == gudIdx ? selectedGudang.gudang_prov : gud.gudang_prov} name="prov" onChange={(e) => {
                                            let _editRow = { ...selectedGudang }
                                            _editRow.gudang_prov = e.target.value
                                            setselectedGudang(_editRow)
                                        }} /> : <Dropdown isOpen={dropdownOpen} toggle={toggle} color="light" className="bg-white">
                                            <DropdownToggle caret color="light">
                                                {provinsi ? provinsi : 'Pilih provinsi'}
                                            </DropdownToggle>
                                            <DropdownMenu modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '500px',
                                                            },
                                                        };
                                                    },
                                                },
                                            }}>
                                                <DropdownItem header>Provinsi</DropdownItem>
                                                {Object.keys(mapedKota).sort().map((_pr, _ipr) => <DropdownItem key={_ipr} onClick={() => {

                                                    setprovinsi(_pr)
                                                    setkabkot()
                                                }
                                                }>{_pr}</DropdownItem>)}

                                            </DropdownMenu>
                                        </Dropdown>}
                                    </div>
                                </div>

                                <div className="col-auto">
                                    <label className="sr-only" for="inlineFormInputGroup"></label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Kota/Kab</div>
                                        </div>
                                        {selectedGudang == null && idxGudang != gudIdx ? <input type="text" className="form-control" id="inlineFormInputGroup" disabled={selectedGudang != null && idxGudang == gudIdx ? false : true} id="inlineFormInputGroupUsername" placeholder="" value={selectedGudang != null && idxGudang == gudIdx ? selectedGudang.gudang_kabkot : gud.gudang_kabkot} name="kbkot" onChange={(e) => {
                                            let _editRow = { ...selectedGudang }
                                            _editRow.gudang_kabkot = e.target.value
                                            setselectedGudang(_editRow)
                                        }} />
                                            : provinsi ? <Dropdown isOpen={dropdownKabkotOpen} toggle={toggleKabkot} className="bg-light" >
                                                <DropdownToggle caret color="light">
                                                    {kabkot ? `${kabkot.type} ${kabkot.city_name}` : 'Pilih Kabupaten  / Kota'}
                                                </DropdownToggle>
                                                <DropdownMenu modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true,
                                                        order: 890,
                                                        fn: (data) => {
                                                            return {
                                                                ...data,
                                                                styles: {
                                                                    ...data.styles,
                                                                    overflow: 'auto',
                                                                    maxHeight: '500px',
                                                                },
                                                            };
                                                        },
                                                    },
                                                }}>
                                                    <DropdownItem header>Kabupate  / Kota</DropdownItem>
                                                    {mapedKota[provinsi].map((_pr, _ipr) => <DropdownItem key={_ipr} onClick={() => {

                                                        setkabkot(_pr)
                                                        getKecamatan({ variables: { id: _pr.city_id } })
                                                    }}>{_pr.type} {_pr.city_name}</DropdownItem>)}

                                                </DropdownMenu>
                                            </Dropdown> : <small className="m-2">Pilih provinsi untuk menapilkan kabupaten/kota</small>}
                                    </div>
                                </div>

                                <div className="col-auto">
                                    <label className="sr-only" for="inlineFormInputGroup"></label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Kecamatan</div>
                                        </div>
                                        {selectedGudang == null && idxGudang != gudIdx ? <input type="text" className="form-control" id="inlineFormInputGroup" disabled={selectedGudang != null && idxGudang == gudIdx ? false : true} id="inlineFormInputGroupUsername" placeholder="" value={selectedGudang != null && idxGudang == gudIdx ? selectedGudang.gudang_kec : gud.gudang_kec} name="kbkot" onChange={(e) => {
                                            let _editRow = { ...selectedGudang }
                                            _editRow.gudang_kec = e.target.value
                                            setselectedGudang(_editRow)
                                        }} />
                                            : kabkot ? <Dropdown isOpen={dropdownKecamatanOpen} toggle={toggleKecamatan} className="bg-light" >
                                                <DropdownToggle caret color="light">
                                                    {subdistrict ? `${subdistrict.subdistrict_name}` : 'Pilih kecamatan'}
                                                </DropdownToggle>
                                                <DropdownMenu modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true,
                                                        order: 890,
                                                        fn: (data) => {
                                                            return {
                                                                ...data,
                                                                styles: {
                                                                    ...data.styles,
                                                                    overflow: 'auto',
                                                                    maxHeight: '500px',
                                                                },
                                                            };
                                                        },
                                                    },
                                                }}>
                                                    <DropdownItem header>Kecamatan</DropdownItem>
                                                    {dKecamatan && dKecamatan.kecamatans.rajaongkir.results.map((_pr, _ipr) => <DropdownItem key={_ipr} onClick={() => {

                                                        setsubdistrict(_pr)
                                                    }}>{_pr.subdistrict_name}</DropdownItem>)}

                                                </DropdownMenu>
                                            </Dropdown> : <small className="m-2">Pilih kabupaten/kota untuk menampilkan kecamatan</small>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <label className="sr-only" for="inlineFormInputGroup"></label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Kelurahan</div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroup" disabled={selectedGudang != null && idxGudang == gudIdx ? false : true} id="inlineFormInputGroupUsername" placeholder="" value={selectedGudang != null && idxGudang == gudIdx ? selectedGudang.gudang_kel : gud.gudang_kel} name="tinggi" onChange={(e) => {
                                            let _editRow = { ...selectedGudang }
                                            _editRow.gudang_kel = e.target.value
                                            setselectedGudang(_editRow)
                                        }} readonly />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <label className="sr-only" for="inlineFormInputGroup"></label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Kodepos</div>
                                        </div>
                                        <input type="text" className="form-control" id="inlineFormInputGroup" disabled={selectedGudang != null && idxGudang == gudIdx ? false : true} id="inlineFormInputGroupUsername" placeholder="" value={selectedGudang != null && idxGudang == gudIdx ? selectedGudang.gudang_poscode : gud.gudang_poscode} name="tinggi" onChange={(e) => {

                                            let _editRow = { ...selectedGudang }
                                            _editRow.gudang_poscode = e.target.value
                                            setselectedGudang(_editRow)
                                        }} readonly />
                                    </div>
                                </div>


                                <button type="button" className={`btn float-end mt-2 ${selectedGudang != null && idxGudang == gudIdx ? "btn-primary" : "btn-light"}`} onClick={() => {
                                    if (selectedGudang == null) {
                                        setidxGudang(gudIdx)
                                        setselectedGudang(gud)
                                    } else {
                                        let _sectedGudang = { ...selectedGudang }
                                        _sectedGudang.prov_id = kabkot.province_id
                                        _sectedGudang.city_id = kabkot.city_id
                                        _sectedGudang.kec_id = subdistrict.subdistrict_id

                                        _sectedGudang.gudang_kec = subdistrict.subdistrict_name
                                        _sectedGudang.gudang_kabkot = `${kabkot.type} ${kabkot.city_name}`
                                        _sectedGudang.gudang_prov = kabkot.province
                                        setupdateOption("address")
                                        updateconfigs({
                                            context: {
                                                headers: {
                                                    "option": "GUDANG"
                                                }
                                            },
                                            variables: {
                                                body: _sectedGudang,
                                                id: "gudang"
                                            }
                                        })
                                    }

                                }}>{selectedGudang != null && idxGudang == gudIdx ? "Save" : "Edit alamat"}</button>
                                {selectedGudang != null && idxGudang == gudIdx && <button type="button" className="btn float-end mt-2 btn-light" onClick={() => {
                                    setselectedGudang(null)
                                    setidxGudang(null)
                                }}>Cancel</button>}
                            </div>

                        </div>

                    </div>
                )}
            </div>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} center={true}>
                <div className="card-body">
                    <h6 className="card-title">Connect whatsapp</h6>
                    <QRCode size={256} value={waCode} />
                </div>
                {/* <ModelOrderDetail order={this.props.data.orders.orders[indexSelectedOrder]} updateOrder={this.props.updateOrder}  /> */}
            </Modal>
        </div>
    )
}

export default withApollo(BranchGudang)
