import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, List, ListGroupItem, Media, Row } from 'reactstrap'
import { useEffect } from 'react'
import { Edit } from 'react-feather'

const listImage = [
    "/assets/images/uploads/uploaded_file-1649391942671-105402843-prod_0_Upload.jpg",
    "/assets/images/uploads/uploaded_file-1649391942671-105402843-prod_0_Upload.jpg",
    "/assets/images/uploads/uploaded_file-1649391942671-105402843-prod_0_Upload.jpg"
]

const listTemplateProduk = [
    { "harga": 200000, "nama_produk": "Facial Wash", "berat": 120, "limit_order": 1, "image": "prem.jpg", "kode": "", alias: "" },
    { "harga": 200000, "nama_produk": "Day Cream", "berat": 100, "limit_order": 1, "image": "prem.jpg", "kode": "", alias: "" },
    { "harga": 200000, "nama_produk": "Night Cream I", "berat": 100, "limit_order": 1, "image": "prem.jpg", "kode": "", alias: "" },
    { "harga": 200000, "nama_produk": "Night Cream II", "berat": 100, "limit_order": 1, "image": "prem.jpg", "kode": "", alias: "" },
    { "harga": 200000, "nama_produk": "other", "": 0, "limit_order": 1, "image": "prem.jpg", "kode": "", alias: "" },
]

const listProduk = [
    { "kode_produk": 81, "nama_produk": "T5C12D4", ket: "keterangan" },

]


function CreateProduct(props) {
    const [selectedImage, setselectedImage] = useState(0)
    const [selectedTemplateProduk, setselectedTemplateProduk] = useState(listTemplateProduk[0])
    const [dropdownOpen, setdropdownOpen] = useState(false)
    const [pengiriman, setpengiriman] = useState("Balikpapan")
    const [limitOrder, setlimitOrder] = useState(0)
    const [status, setstatus] = useState(1)
    const [gudangId, setgudangId] = useState(1)
    const [kodeProduk, setkodeProduk] = useState(90)
    const [kodeHarga, setkodeHarga] = useState(90)
    const [hasilKode, sethasilKode] = useState()

    const [file, setFile] = useState()
    const [files, setFiles] = useState([])
    const [skuFiles, setSkuFiles] = useState([])
    const [pictures, setPicture] = useState([])

    useEffect(() => {
        combineCode([9, 9], 13)
    
      return () => {
        return false
      }
    }, [])
    
    const combineCode = (array, total) => {
        const kHarga = parseInt(kodeHarga)
        const kProduk = parseInt(kodeProduk)
        let kdPrd1=0
        let kdPrd2=0
        let kdPrd3=0
       
        do {
             const rdm3= (Math.floor(Math.random() * (99 - 10)) + 10);
            console.log(rdm3);
           
            kdPrd1=rdm3.toString().at(0)
            kdPrd2=rdm3.toString().at(1)
            if(kodeProduk>80){
                kdPrd1=9
            }
        } while ((kdPrd1*kdPrd2)>=kProduk||(kProduk-(kdPrd1*kdPrd2))>9);
         kdPrd3 =kProduk-(kdPrd1*kdPrd2)
        // console.log(nilai3);
        // var maxLoops = 9999, randomized;
        // do {
        //      rdm2= (Math.floor(Math.random() * (99 - 10)) + 10).toString().at(0)+0;
           
        //    console.log(nilai2);
        //     maxLoops--;
            
        // } while  (maxLoops > 0 &&(nilai2+rdm2)!== kProduk);
        // console.log("hasil");
        // sethasilKode(kdPrd1+""+kdPrd2+""+nilai3);
        // console.log(randomized[0]*randomized[1]+randomized[2]);
// return
        // var maxLoops2 = 999, randomized2;
        // do {
        //     randomized2 = array.map(function (value) {
        //         return Math.floor(Math.random() * (value + 1));
        //     });
        //     maxLoops2--;
        // } while (maxLoops2 > 0 && randomized2.reduce(function (a, b, cIndex) {
        //     if (cIndex != 2) return a * b;
        //     if (cIndex == 2) return a + b;
        // }) !== kHarga);
        let kdHrg1=0
        let kdHrg2=0
        let kdHrg3=0
        do {
             const rdm3= (Math.floor(Math.random() * (99 - 10)) + 10);
            console.log(rdm3);
           
            kdHrg1=rdm3.toString().at(0)
            kdHrg2=rdm3.toString().at(1)
            if(kodeHarga>80){
                kdHrg1=9
            }
        } while ((kdHrg1*kdHrg2)>=kHarga||(kHarga-(kdHrg1*kdHrg2))>9);
        kdHrg3 =kHarga-(kdHrg1*kdHrg2)
        const rdm = Math.floor(Math.random() * (99 - 10)) + 10;
        const hasil = `${rdm.toString().at(0)}${kdPrd1+""+kdPrd2+""+kdPrd3}${kdHrg1+""+kdHrg2+""+kdHrg3}${rdm.toString().at(1)}`
        sethasilKode(hasil)
        let curentData = { ...selectedTemplateProduk }
        curentData.alias = hasil
        setselectedTemplateProduk(curentData)
    }
    const changeValue = (e) => {
        e.preventDefault()
        // [name, value ]=e.target

        let curentData = { ...selectedTemplateProduk }
        curentData[e.target.name] = e.target.value
        // console.log(curentData)
        setselectedTemplateProduk(curentData)

    }
    const handleSubmit = async (event) => {
        // 👇️ prevent page refresh 
        let imagePath=`/assets/images/bpn/${selectedTemplateProduk.image}`
        event.preventDefault();
        if (file) {
            const formData = new FormData()
            formData.append('uploaded_file', file, `prod_${selectedTemplateProduk.alias}_${file.name}`)


            // if (!product.id) {
            //     props.skuFiles.forEach((f, i) => {

            //         formData.append('uploaded_file', f, `sku_${i}_${f.name}`)
            //     })
            // }

            const uploadResponse = await fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
                method: 'POST',
                body: formData
            })
            // .then(response => response.json())
            // .then(data => {
            //     // console.log(data)


            let data
            if (uploadResponse.ok) {
                data = await uploadResponse.json()
                imagePath=`/assets/images/uploads/${data.d[0].filename}`
            }
        }




        // })
        const defaultProduk = {
            "name": selectedTemplateProduk.nama_produk,
            "description": "Produk khusus " + props.selectedKontrol.masalah,
            "tags": "Premium produk",
            "is_premium":1,
            "is_restricted": 1,
            "branch_id": 2,

            dokul_product_images: [{
                image_url: imagePath,
                product_wcid: 0,
                branch_id: 2
            }],
            dokul_products_skus: [{
                name: `pw-${selectedTemplateProduk.alias}`,
                price: selectedTemplateProduk.harga,
                reg_price: selectedTemplateProduk.harga,
                status: 1,
                stock: "",
                avaibility: "0",
                image_url: imagePath,
                total_sales: "",
                discount: 0,
                sale: 0,
                suscribe_change: "",
                discount_min: "",
                discount_grade: "",
                panjang: "",
                lebar: "",
                tinggi: "",
                berat: 100,
                nick_name: selectedTemplateProduk.alias,
                is_deleted: "",
                dokul_gudang_skus: [{
                    "gudang_id": 2,
                    "stock": null,
                    "avaibility": 1,
                    "notif": 0
                }]

            }]
        }

        let newProdukKhusus = {
            produk: defaultProduk,
            userProduk: {
                limit_order: limitOrder,
                kontrol_id: props.selectedKontrol.id,
                produk_id: null,
                status: limitOrder > 0 ? 3 : status,
                user_id: props.privateUser?props.privateUser.id:props.selectedKontrol.user_id,
                sku_id: null
            },
            gudang_id: gudangId
        }
        const url=props.privateUser?`${process.env.REACT_APP_API_HOST}/api/konsultasi/produkKhususPrivate`:`${process.env.REACT_APP_API_HOST}/api/konsultasi/produkKhusus`
        
        fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(newProdukKhusus) // body data type must match "Content-Type" header
        }).then(response => response.json())
            .then(newdata => {
                // const newdata = await response.json()
                props.handleCloseModal({...newdata.newProduk, limit_order: limitOrder, status: limitOrder > 0 ? 3 : status })
            })
    };
    const _handleImgAdd = (e) => {
        e.preventDefault();
        // setimageError(false)
        let _product = { ...selectedTemplateProduk }
        let reader = new FileReader();
        let file_ = e.target.files[0];
        let _pictures = [...pictures]
        //const jmlPic = pictures.length;
        const newPic = { image_url: "" }
        // if (product.id)
        //     files.push({ idx: i, file: file });
        // else
        // files.push(file);
        reader.onloadend = () => {
            // newPic.image_url = reader.result;
            // _pictures.push(newPic)
            // _product.dokul_product_images = _pictures
            _product.image = URL.createObjectURL(file_)

            setselectedTemplateProduk(_product)
            setFile(file_)
            // setProduct(_product)
            // setFiles(files)

        }
        reader.onerror = (e) => {
            console.log("error" + e)
        }
        reader.readAsDataURL(file_)
    }
    return (
        <section>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={4}>
                                <Label><b>Gambar produk</b></Label>
                                {/* <div className='d-flex flex-row justify-content-around m-2'> */}
                                {/* {listImage.map((x,idx)=>
                <div key={idx} className={`p-2 w-25 border ${selectedImage==idx?"border-success":"border-light"} rounded pointer`}>
                    <Media className='rounded' src={`${process.env.REACT_APP_IMAGE_HOST}${x}`} onClick={()=>setselectedImage(idx)}/>
                </div>
                )} */}

                                <div className="col-xl-8 col-sm-7">
                                    <div className="file-upload-product d-flex flex-nowrap">
                                        <div className='img-front m-1' style={{ padding: 0, width: 150 }}>
                                            <div className="box-input-file-lg"  >
                                                {file && <img src={selectedTemplateProduk.image} className="img-fluid image_zoom_1 blur-up lazyloaded" />}
                                                {!file && <img src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bpn/${selectedTemplateProduk.image}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />}

                                                <div className="product-hover">
                                                    <ul>
                                                        <li>
                                                            <input className="upload" type="file" accept="image/*" onChange={_handleImgAdd} style={{ width: "100%", height: "100%" }} />
                                                            <button className="btn" type="button">
                                                                <Edit className="editBtn" />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className={`p-2 border ${props.branch_id == 1 ? "border-warning" : "border-success"} rounded pointer mb-2`}>
                                    <Media className='rounded' src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bpn/${selectedTemplateProduk.image}`} />
                                    <input id="fileUpload" type="file" accept="image/*" onChange={(e) => _handleImgAdd(e)}  style={{
                                            // position: "absolute",
                                            // width: "100%",
                                            // height: "100%",
                                            //left: 0,
                                            //right: 0,
                                            //opacity: 0,
                                            display: "none",
                                            cursor: "pointer" 
                                            //å cursor: "pointer",
                                            // margin: "auto"
                                        }} />
                                </div> */}
                            </Col>
                            <Col md={8}>
                                <Label><b>Template Produk</b></Label>
                                <List>
                                    {listTemplateProduk.map((x, idx) =>
                                        <ListGroupItem key={idx} className={`${selectedTemplateProduk.nama_produk == x.nama_produk ? 'bg-primary' : 'bg-light'} text-dark pointer`} onClick={() => setselectedTemplateProduk(x)}>
                                            {x.nama_produk}
                                        </ListGroupItem>
                                    )}


                                </List>

                            </Col>
                            <FormGroup>
                                <Label for="aliasproduk">
                                    <strong>Kode</strong>
                                </Label>
                                <div className='d-flex flex-column bg-silver mt-2 p-2'>
                                    {/* <div className='p-2'>Catatan sebelumnya : {selectedKontrol.history[0].}</div> */}
                                    <Row className='mb-2'>
                                        <Col md='3'>
                                            <div className='p-2'>Produk :</div>
                                            <Input type='number' max={90} value={kodeProduk} onChange={(e) => setkodeProduk(e.target.value)}></Input>
                                        </Col>
                                        <Col md='3'>
                                            <div className='p-2'>Harga :</div>
                                            <Input type='number' max={90} value={kodeHarga} onChange={(e) => setkodeHarga(e.target.value)}></Input>
                                        </Col>
                                        <Col md='6'>
                                            <div className='p-2'>Hasil :</div>
                                            <Input type='text' value={hasilKode}></Input>
                                        </Col>
                                    </Row>

                                    <Button onClick={() => combineCode([9, 9], 13)}>Refresh</Button>

                                    {/* {selectedKontrol.result.dokul_konsultasi_kontrol_result && <>
                                            <div className="bg-light p-2" dangerouslySetInnerHTML={{ __html: selectedKontrol.result.dokul_konsultasi_kontrol_result.note }} />
                                        </>} */}
                                </div>

                            </FormGroup>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Label><b>Detail produk</b></Label>
                        <FormGroup>
                            <Label for="nmproduk">
                                Nama produk
                            </Label>
                            <Input
                                id="nmproduk"
                                name="nama_produk"
                                value={selectedTemplateProduk.nama_produk}
                                placeholder="Nama produk"

                                onChange={changeValue}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="aliasproduk">
                                Alias
                            </Label>
                            <Input
                                id="aliasproduk"
                                name="alias"
                                placeholder="Alias"
                                value={selectedTemplateProduk.alias}
                                onChange={changeValue}
                                type="text"
                            />
                        </FormGroup>


                        <FormGroup>
                            <Label for="hargaproduk">
                                Harga
                            </Label>
                            <Input
                                id="hargaproduk"
                                name="harga"
                                value={selectedTemplateProduk.harga}
                                onChange={changeValue}
                                type='number'
                            />
                        </FormGroup>
                        <Row form>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="beratproduk">
                                        Berat (gram)
                                    </Label>
                                    <Input
                                        id="beratproduk"
                                        name="berat"
                                        value={selectedTemplateProduk.berat}

                                        onChange={changeValue}
                                        type='number'
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="maxorder">
                                        Limit order
                                    </Label>
                                    <Input
                                        id="maxorder"
                                        name="maxorder"
                                        defaultValue={limitOrder}
                                        onChange={(e) => setlimitOrder(e.target.value)}
                                        type='number'
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>

                            </Col>

                        </Row>
                        <FormGroup>
                            <Label for="beratproduk"    >
                                Pengiriman
                            </Label>
                            <Dropdown isOpen={dropdownOpen} toggle={() => setdropdownOpen(!dropdownOpen)}>
                                <DropdownToggle color='light' className='border' caret>
                                    {pengiriman}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>Gudang Pengiriman</DropdownItem>
                                    <DropdownItem onClick={() => setpengiriman("Balikpapan")}>Balikpapan</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </FormGroup>



                    </Col>
                </Row>


                {/* </div> */}

                {/* <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="nmproduk">
                                Nama produk
                            </Label>
                            <Input
                                id="nmproduk"
                                name="nmproduk"
                                value={selectedTemplateProduk.nama_produk}
                                placeholder="Nama produk"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="aliasproduk">
                                Alias
                            </Label>
                            <Input
                                id="aliasproduk"
                                name="aliasproduk"
                                placeholder="Alias"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="aliasproduk">
                                Kode
                            </Label>
                            <Input
                                id="aliasproduk"
                                name="aliasproduk"
                                placeholder="Alias"
                                type="text"
                            />
                        </FormGroup>
                    </Col>

                </Row>

                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="hargaproduk">
                                Harga
                            </Label>
                            <Input
                                id="hargaproduk"
                                name="hargaproduk"
                                value={selectedTemplateProduk.harga}
                                type='number'
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="beratproduk">
                                Berat (gram)
                            </Label>
                            <Input
                                id="beratproduk"
                                name="beratproduk"
                                value={selectedTemplateProduk.berat}
                                type='number'
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="maxorder">
                                Limit order
                            </Label>
                            <Input
                                id="maxorder"
                                name="maxorder"
                                defaultValue={selectedTemplateProduk.max_order}
                                type='number'
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="beratproduk"    >
                                Pengiriman
                            </Label>
                            <Dropdown isOpen={dropdownOpen} toggle={() => setdropdownOpen(!dropdownOpen)}>
                                <DropdownToggle color='light' className='border' caret>
                                    {pengiriman}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>Pilih Pengiriman</DropdownItem>
                                    <DropdownItem onClick={() => setpengiriman("Balikpapan")}>Balikpapan</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => setpengiriman("Jakarta")}>Jakarta</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </FormGroup>
                    </Col>

                </Row> */}
                <Row form>


                </Row>
                {/* <FormGroup check>
                    <Input
                        id="exampleCheck"
                        name="check"
                        type="checkbox"
                    />
                    <Label
                        check
                        for="exampleCheck"
                    >
                       Jakarta
                    </Label>
                </FormGroup> */}
                <Button>
                    Submit
                </Button>
            </Form>
        </section>
    )
}

export default CreateProduct
