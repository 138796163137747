import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import CreateProductKhusus from './create.produk'
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { ADD_REQUEST_TAMBAHAN, DELETE_REQUEST_TAMBAHAN, GET_LIST_KONSULTASI_HISTORY_PRODUK, GET_LIST_KONSULTASI_KONTROL, GET_PRODUCTS } from '../../../constants/graph';

import moment from 'moment'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import heic2any from 'heic2any'
function RequestTambahan({ selectedProduct, setselectedProduct, selectedKontrol, showProduct, setshowProduct, setSinggleProduct, produkTags }) {
    const [addRequestTambahan, { loading: addLoading }] = useMutation(ADD_REQUEST_TAMBAHAN, {
        onCompleted: (data) => {
            // console.log(data)
            setlistNewRequest(cr => [...cr, data.addRequestTambahan.id])
            setmodalPertanyaan(false)
        }
    })
    const [deleteRequestTambahan, { loading: deleteLoading }] = useMutation(DELETE_REQUEST_TAMBAHAN, {
        onCompleted: (data) => {
            console.log(data)
          
        }
    })
    const [modalPertanyaan, setmodalPertanyaan] = useState(false)
    const [createProduct, setcreateProduct] = useState(false)
    // const [selectedProduct, setselectedProduct] = useState(resultProduct)
    const [modalOpenImage, setmodalOpenImage] = useState(false)
    const [selectedImage, setselectedImage] = useState()
    const [blobData, setblobData] = useState()
    const [isBlob, setisBlob] = useState(false)
    const [loadingImage, setloadingImage] = useState(false)
    const [newRequest, setnewRequest] = useState({})
    const [listNewRequest, setlistNewRequest] = useState(selectedKontrol.result.dokul_konsultasi_kontrol_rerequests)



    const _handleChangeValue = (e) => {
        let _newVal = { ...newRequest }
        _newVal.re_req = e.target.value
        setnewRequest(_newVal)
    }
    const convertImage = async (url) => {

        fetch(`${process.env.REACT_APP_IMAGE_HOST}/assets/images/uploads/konsul/${url}`)
            .then(response => response.blob())
            .then(blob => new Promise(callback => {
                let reader = new FileReader();
                reader.onload = function () { callback(this.result) };
                reader.readAsDataURL(blob);
                const resultImg = heic2any({
                    // required: the HEIF blob file
                    blob: blob,
                    // (optional) MIME type of the target file
                    // it can be "image/jpeg", "image/png" or "image/gif"
                    // defaults to "image/png"
                    toType: "image/jpeg",
                    // conversion quality
                    // a number ranging from 0 to 1
                    quality: 0.5
                }).then(res => {
                    setblobData(res)
                    setloadingImage(false)
                })

            }));


    }
    const handleViewImage = (image_url) => {
        // console.log(image_url)

        setblobData()
        setloadingImage(true)
        // const imgSPlit = image_url.split("/")
        const imageresult = `${selectedKontrol.dokul_user.email.trim()}/${image_url}`
        // `${imgSPlit[0]}/${imgSPlit[1]}/${imgSPlit[2]}/${imgSPlit[3]}/${imgSPlit[4]}/${selectedKontrol.dokul_user.email}/${imgSPlit[5]}`

        if (image_url && image_url.toLowerCase().includes(".heic")) {
            setisBlob(true)
            convertImage(imageresult)
            setselectedImage(imageresult)
        }
        else {
            setselectedImage(imageresult)
        }

        setmodalOpenImage(true)
    }



    return (
        <div>
            <h4 className="bg-primary p-2 mt-2 ">Permintaan tambahan</h4>

            <div className='d-flex flex-row'>
                <div className='p-2'>
                    <button className="btn btn-primary btn-xs float-end" onClick={async () => {
                        setmodalPertanyaan(true)
                    }}>Tambah permintaan</button>
                </div>
            </div>
            <ListGroup className='bg-silver p-2' style={{ minHeight: 100 }}>
                {/* {console.log(selectedKontrol.result.dokul_konsultasi_kontrol_rerequests)} */}
                {/* {selectedKontrol && listNewRequest.length > 0 ? listNewRequest.map((x, idx) => {
                    // const _pr = dProduct.products.find(_xpr => _xpr.id == x.produk_id)
                    return <ListGroupItem key={idx} color="light" className="">
                        <Row>

                            <div className='col-10'>
                                <span className="p-2">{x.re_req}</span>
                            </div>

                            <div className='col-1'>
                                <button className='btn btn-xs btn-danger' onClick={() => {
                                    let _currentSelectedRequest = [...selectedKontrol.result.dokul_konsultasi_kontrol_rerequests]
                                    const idxProd = _currentSelectedRequest.findIndex(_x => _x.id == x.id)
                                    selectedKontrol.result.dokul_konsultasi_kontrol_rerequests.splice(idxProd, 1)
                                   
                                }}><i className='fa fa-times'></i></button>
                            </div>


                            <div className='col-12'>
                                {x.re_ans == "" ? <small className='text-muted p-2'>Belum ada jawaban</small> : <>
                                    {x.type == 2 && <span className="p-2">{x.re_ans}</span>}
                                    {x.type == 1 && <button className="btn btn-primary btn-xs m-2" onClick={() => handleViewImage(x.re_ans)}>View</button>

                                    }</>
                                }
                            </div>
                        </Row>
                    </ListGroupItem>


                }) : <div className="bg-light p-2 text-center text-muted"><h4>Belum ada permintaan tambahan</h4></div>
                } */}
                {listNewRequest.length>0? listNewRequest.map((nr, nri) => {
                   
                    return <ListGroupItem key={nri} color="light" className="">
                        <Row >
                        <div className='col-12'>
                                <span className="p-2 m1 text-small text-muted"><small>{moment(nr.req_date).format('DD MMM YYYY HH:mm')}</small></span>
                            </div>
                            <div className='col-10'>
                                <span className="p-2 m1">{nr.re_req}</span>
                            </div>

                            <div className='col-1'>
                                <button className='btn btn-xs btn-danger m-1' onClick={() => {
                                    let _currentSelectedProduct = [...listNewRequest]
                                    const idxProd = _currentSelectedProduct.findIndex(_x => _x.id == nr.id)
                                    _currentSelectedProduct.splice(idxProd, 1)
                                    setlistNewRequest(_currentSelectedProduct)
                                    
                                    deleteRequestTambahan(
                                        {
                                            variables:{
                                                id:nr.id
                                            }
                                        }
                                    )
                                }}><i className='fa fa-times'></i></button>
                            </div>


                            <div className='col-12'>
                                {(nr.status !=2) ? <small className='text-muted p-2'>Belum ada jawaban</small> : <>
                                <small className='text-muted p-2'>{moment(nr.ans_date).format('DD MM YYYY HH:mm')}</small>
                                    {nr.type == 2 && <div className="p-2" style={{backgroundColor:"#cff4fc"}}>{nr.re_ans}</div>}
                                    {nr.type == 1 && <button className="btn btn-primary btn-xs m-2"  onClick={() => handleViewImage(nr.re_ans)}>View</button>

                                    }</>
                                }
                            </div>
                        </Row>
                    </ListGroupItem>
                }):  <div className="bg-light p-2 text-center text-muted"><h4>Belum ada permintaan tambahan</h4></div>
            
            }
            </ListGroup>

            <Modal isOpen={modalPertanyaan} toggle={() => {
                setmodalPertanyaan(false)
            }} centered size={`${createProduct ? "md" : "lg"}`}>
                <ModalHeader className="bg-primary" toggle={() => {
                    setmodalPertanyaan(false)
                }}>Tambah permintaan ke pasien</ModalHeader>
                <ModalBody className="bg-light text-dark">

                    <section>


                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="permintaan">
                                        Permintaan
                                    </Label>
                                    <Input
                                        id="nmproduk"
                                        name="nmproduk"
                                        placeholder="Permintaan"
                                        value={newRequest.re_req}
                                        type="text"
                                        onChange={(e) => {
                                            // console.log(e)
                                            _handleChangeValue(e)
                                        }}

                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup tag="fieldset">
                                    <Label>
                                        Jenis permintaan
                                    </Label>
                                    <FormGroup check>
                                        <Input
                                            name="requesttype"
                                            type="radio"
                                            onChange={(e) => {
                                                if (e.target.checked)
                                                    setnewRequest(r => { return { ...r, type: 1 } })
                                            }}
                                        />
                                        {' '}
                                        <Label check>
                                            Photo
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Input
                                            name="requesttype"
                                            type="radio"
                                            onChange={(e) => {
                                                if (e.target.checked)
                                                    setnewRequest(r => { return { ...r, type: 2 } })
                                            }}
                                        />
                                        {' '}
                                        <Label check>
                                            Text bebas
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>


                        <Button disabled={!newRequest.type} onClick={() => {
                            addRequestTambahan({
                                variables: {
                                    id: selectedKontrol.id,
                                    data: { status: 1, kontrol_id: selectedKontrol.id, type: newRequest.type, re_req: newRequest.re_req, re_ans: "" }
                                }
                            })
                        }}>
                            Submit
                        </Button>

                    </section>

                </ModalBody>



            </Modal>


            <Modal isOpen={modalOpenImage} toggle={() => {
                setselectedImage()
                setblobData()
                setisBlob(false)
                setmodalOpenImage(false)
            }} size={selectedKontrol ? "xl" : "md"} centered >
                {!selectedKontrol && <ModalHeader className="bg-primary" toggle={() => {
                    setselectedImage()
                    setblobData()
                    setisBlob(false)
                    setmodalOpenImage(false)
                }}></ModalHeader>}
                <ModalBody className="bg-light p-1" style={{ justifyContent: "center", alignItems: "center" }} >



                    {selectedImage && <TransformWrapper
                        initialScale={1}
                        wheel={{ wheelDisabled: true }}
                        // initialPositionX={100}
                        // initialPositionY={100}
                        // disabled
                        centerOnInit

                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <div className="tools m-2 border rounded">
                                    <button className='btn btn-outline btn-xs border m-1' onClick={() => zoomIn()}>+ Zoom In</button>
                                    <button className='btn btn-outline btn-xs border m-1' onClick={() => zoomOut()}>- Zoom Out</button>
                                    <button className='btn btn-info btn-xs m-1' onClick={() => resetTransform()}>Reset</button>
                                    <button className='btn btn-danger btn-xs m-1 float-end' onClick={() => {
                                        setselectedImage()
                                        setblobData()
                                        setisBlob(false)
                                        setmodalOpenImage(false)
                                    }}>CLose</button>
                                    {rest.state.scale}
                                </div>
                                <TransformComponent>
                                    <div className="d-flex justify-content-center flex-row">
                                        {blobData && !loadingImage && <img src={URL.createObjectURL(blobData)} alt="test" style={{ width: "100%", margin: "auto" }} />}
                                        {!loadingImage && !isBlob && <img src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/uploads/konsul/${selectedImage}`} alt="test" style={{ width: "100%", margin: "auto" }} />}
                                    </div>
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                    }
                    {loadingImage && <div className="d-flex justify-content-center flex-column">
                        <div className="d-flex justify-content-center flex-row">
                            <Spinner className='self-align-center' animation="grow" variant="info" color='primary' title='sdfjhsdf' />
                        </div>
                        <Label className='text-center text-dark'>Load image from server</Label>
                    </div>}

                    {isBlob == false && <img style={{ display: loadingImage ? 'none' : 'none' }} onLoad={() => setloadingImage(false)} src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/uploads/konsul/${selectedImage}`} className="img-fluid image_zoom_1 blur-up lazyloaded m-2" />}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default RequestTambahan