import React, { Component, Fragment, forwardRef, useState, useEffect } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
// import Modal from 'react-responsive-modal';
import CurrencyInput from 'react-currency-input-field';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge, Modal, Col, Row, Label, Container } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import { Collapse } from 'reactstrap';
import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { ADD_JADWAL_SESI, GET_DOKTERS, GET_JADWAL_PERAWATAN, GET_JADWAL_TEMPLATES, GET_KLINIKS, GET_RESERVASI, UPDATE_DOKTER, UPDATE_JADWAL_SESI, UPDATE_JADWAL_PERAWATAN, UPDATE_STATUS_SESI, UPDATE_JADWAL_STATUS } from '../../constants/graph';
import data from '../../assets/data/physical_list';
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import BadgeCheck from '../common/badge-check';
registerLocale('id', es)

const JadwalTindakan = (props) => {
    const listHari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
    const [listJadwal, setlistJadwal] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalSesiOpen, setmodalSesiOpen] = useState(false)
    const [addFromTemplate, setaddFromTemplate] = useState(false)
    const [tgl, setTgl] = useState(new Date())
    const [idxDokter, setIdxDokter] = useState(0)
    const [idxKlinik, setIdxKlinik] = useState(0)
    const [idxKliniks, setIdxKliniks] = useState([])
    const [jadwalBaru, setJadwalBaru] = useState({})
    const [selectedTmp, setselectedTmp] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date())


    const [startDateFilter, setStartDateFilter] = useState(new Date());
    const [endDateFilter, setendDateFilter] = useState(moment().add(1, "d").toDate())

    const [timeFrom, settimeFrom] = useState("")
    const [timeTo, settimeTo] = useState("")
    const columns = [];
    const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const [getJadwalPerawatan, { data: dataPerawatan, loading: loadingperawatan }] = useLazyQuery(GET_JADWAL_PERAWATAN, {
        variables: {
            param: `dateFrom=${moment(startDateFilter).format("YYYY-MM-DD")}&dateTo=${moment(endDateFilter).format("YYYY-MM-DD")}&type=2&idklinik=2`
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => reloadData(),

    })
    const { loading, error, data } = useQuery(GET_DOKTERS)
    const { loading: tmpLoad, error: tmpErr, data: tmpData } = useQuery(GET_JADWAL_TEMPLATES)
    const client = useApolloClient()
    const updateCache = (cache, { data }) => {

        const _updatedDokter = { ...data.updateDokter }
        const fragmentResult = cache.writeFragment({
            id: `Dokter:${_updatedDokter.id}`,
            fragment: gql`
        fragment dokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
        }
      `,
            data: _updatedDokter
        });
    };
    const [updateJadwalPerawatan] = useMutation(UPDATE_JADWAL_PERAWATAN,);
    const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });
    const [updateSesi] = useMutation(UPDATE_JADWAL_SESI);
    const [addSesi] = useMutation(ADD_JADWAL_SESI);
    const [updateSesiStatus] = useMutation(UPDATE_STATUS_SESI, { onCompleted: () => getJadwalPerawatan() });
    const [updateJadwalStatus] = useMutation(UPDATE_JADWAL_STATUS, { onCompleted: () => getJadwalPerawatan() });
    const selectedDokter = client.readFragment({
        id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment readDokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
          }
        `,
    }, false);
    const { loading: loadingKlinik, error: errorKlinik, data: dataKlinik } = useQuery(GET_KLINIKS)


    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    useEffect(() => {
        getJadwalPerawatan()
    }, [])
    useEffect(() => {
        if (dataPerawatan) {
            reloadData()
        }
    }, [dataPerawatan])
    useEffect(() => {
        if (dataKlinik) {
            const _idxKliniks = dataKlinik.kliniks.map(x => x.id)
            setIdxKliniks(_idxKliniks)
        }
    }, [dataKlinik])



    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }
    const compareDateMin = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() <= d2.getTime()
    }
    const filterKlinikDate = (klinik, date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }
    const getTemplateView = (jdwlcur, jadwal) => {
        // tmpData.templates.
        //jika template brati tgl adalah batas
        // const tmp = tmpData.templates.find(x => x.dokul_jadwal_sesis.some(x2 => x2.id == jadwal.template_id))
        if (!tmpData || jadwal.status!=1) return ""
        const detailSesis = jadwal.dokul_services_jadwal_sesis.find(x => moment(jdwlcur).diff(moment(x.tanggal), 'days') == 0)

        // console.log(detailSesis)

        const tmp = tmpData.templates.find(x => x.id == jadwal.template_id)
        // const jp=tmp.dokul_jadwal_sesis.find(x2 => x2.id == jadwal.template_id)
        const dayCur = moment(jdwlcur).day().toString()
        const listIdDisable = jadwal.dokul_services_jadwal_sesis.length == 0 || !detailSesis ? [] : jadwal.dokul_services_jadwal_sesis[0].disable_sesi.split(",")
        const listIdDStop = jadwal.dokul_services_jadwal_sesis.length == 0 || !detailSesis ? [] : jadwal.dokul_services_jadwal_sesis[0].stop_sesi.split(",")
        const idSesi = jadwal.dokul_services_jadwal_sesis.length == 0 || !detailSesis ? undefined : jadwal.dokul_services_jadwal_sesis[0].id
        // console.log(listIdDisable)
        // console.log(listIdDStop)
        const result = tmp.dokul_jadwal_sesis.filter(x => x.hari.includes(dayCur)).map((jp,idxjp) => {
            // console.debug(jp)
            // console.log(dayCur)
            const jumlahReservasi = jadwal.dokul_reservasis.filter(x => x.sesi == jp.sesi)
            const isStop = listIdDStop.indexOf(jp.id.toString()) != -1
            const isDisable = listIdDisable.indexOf(jp.id.toString()) != -1
            const isFull = jumlahReservasi.length == parseInt(jp.jumlah_pasien)

            return <Row className=' mt-2' key={idxjp}>
                <Col md='4'>{jp.sesi}</Col>
                <Col md='1'><Badge className='rounded-circle' color='success'>{jp.jumlah_pasien}</Badge></Col>
                <Col md='1'><Badge className='rounded-circle' color='secondary'>{jumlahReservasi.length}</Badge></Col>
                <Col md='2'><Badge className={`p-1 rounded ${isDisable ? "bg-cred" : isStop ? "bg-warning" : "bg-success"}`}>{isDisable ? "Dibatalkan" : isStop ? "Distop" : isFull ? "Penuh" : "Tersedia"}</Badge></Col>
                <Col md='3'>
                    <div className="d-flex flex-row">
                        <span className="bg-warning rounded p-1 float-right mx-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                            let _sesi = { ...jp }
                            _sesi.status = 2
                            console.log(_sesi)
                            updateSesiStatus({
                                variables: {
                                    id: detailSesis ? idSesi : undefined,
                                    idsesi: jp.id,
                                    status: isStop ? 1 : 2,
                                    jadwalid: jadwal.id,
                                    tanggal: moment(jdwlcur).format("YYYY-MM-DD"),
                                    body: jp
                                }
                            })
                            //  updateSesi({ variables: { type: _sesi }, })
                        }}>{isStop ? <i className="icofont icofont-undo text-white" /> : <i className="icofont icofont-not-allowed" />}</span>
                        <span className="bg-cred text rounded p-1 float-right mx-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                            let _sesi = { ...jp }
                            _sesi.status = 3
                            updateSesiStatus({
                                variables: {
                                    id: detailSesis ? idSesi : undefined,
                                    idsesi: jp.id,
                                    status: isDisable ? 1 : 3,
                                    jadwalid: jadwal.id,
                                    tanggal: moment(jdwlcur).format("YYYY-MM-DD"),
                                    body: jp
                                }
                            })
                            console.log(_sesi)
                            //  updateSesi({ variables: { type: _sesi }, })
                        }}>{isDisable ? <i className="icofont icofont-undo text-white" /> : <i className="icofont icofont-ui-delete" />}</span>
                        <span className="bg-success rounded p-1 float-right mx-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => setmodalSesiOpen(true)}><i className="icofont icofont-eye" /></span>
                    </div>
                </Col>
            </Row>
            // return <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-between">
            //     <div className="d-flex flex-row">
            //         <div className="p-2 bd-highlight col-2">{jp.sesi}</div>
            //         <div className="p-2 bd-highlight ml-5 "> <Badge className='rounded-circle' color='success'>{jp.jumlah_pasien}</Badge></div>
            //     </div>
            //     <div className="p-2 bd-highlight"><Badge className='rounded-circle' color='secondary'>{jumlahReservasi.length}</Badge> </div>
            //     <div className="p-2 bd-highlight"><Badge className={`p-1 rounded ${isBlock ? "bg-warning" : isStop ? "bg-danger" : "bg-success"}`}>{isStop ? "Block" : isBlock ? "Cancel" : jp.status == 4 ? "Full" : "Available"}</Badge></div>
            //     <div className="d-flex flex-row">
            //         <span className="bg-warning rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
            //             let _sesi = { ...jp }
            //             _sesi.status = 2
            //             //  updateSesi({ variables: { type: _sesi }, })
            //         }}><i className="icofont icofont-not-allowed" /></span>
            //         <span className="bg-danger text rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
            //             let _sesi = { ...jp }
            //             _sesi.status = 3
            //             //  updateSesi({ variables: { type: _sesi }, })
            //         }}><i className="icofont icofont-ui-delete" /></span>
            //         <span className="bg-success rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => setmodalSesiOpen(true)}><i className="icofont icofont-eye" /></span>
            //     </div>
            // </div>
        }
        )
        return result
    }
    const reloadData = () => {
        const dif = moment(endDateFilter).startOf('day').diff(moment(startDateFilter).startOf('day'), 'days');
        // console.log(dif)
        let _tmpJadwal = []
        for (let index = 0; index <= dif; index++) {

            _tmpJadwal.push({ date: moment(startDateFilter).startOf('day').add(index, 'day').toDate() })

        }
        // console.log(_tmpJadwal)
        setlistJadwal(_tmpJadwal)
    }

    if (loading || loadingKlinik) return "Loading"
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">

                    <div className="col-sm-12">
                        <Row>

                            <Col md={12}>
                                <div className='d-flex flex-row flex-wrap'>
                                    {dataKlinik.kliniks.map((kln, idxkln) =>
                                        <BadgeCheck check={idxKliniks.indexOf(kln.id) != -1} key={idxkln} text={kln.name} onChange={(n) => {
                                            if (n)
                                                setIdxKliniks(x => [...x, kln.id])
                                            else {
                                                let _idxKliniks = [...idxKliniks]
                                                const _idx = _idxKliniks.indexOf(kln.id)
                                                _idxKliniks.splice(_idx, 1)
                                                setIdxKliniks(_idxKliniks)

                                            }
                                        }}

                                            editPatern={() => {
                                                setaddFromTemplate(true)
                                                setIdxKlinik(idxkln)
                                                if (dataPerawatan) {
                                                    const currKlinitTemplate = dataPerawatan.jadwalPerawatan.filter(x => x.id_klinik == kln.id && x.is_template).map(x => {
                                                        return {
                                                            id: x.id,
                                                            template_id: x.template_id,
                                                            tanggal: x.tanggal,
                                                            sesi: x.sesi,
                                                            jumlah: x.jumlah,
                                                            id_services: x.id_services,
                                                            id_klinik: x.id_klinik,
                                                            is_template: x.is_template,
                                                            status: x.status
                                                        }
                                                    })
                                                    console.log(currKlinitTemplate)
                                                    setselectedTmp(currKlinitTemplate)
                                                }
                                                setModalOpen(true)

                                            }}
                                            addJadwal={() => {
                                                setaddFromTemplate(false)
                                                setIdxKlinik(idxkln)
                                                setModalOpen(true)

                                            }}

                                        />
                                    )}
                                </div>
                                {/* <h6 className="font-weight-bold">{moment(tgl).format("dddd DD, MMMM YYYY")}</h6> */}
                                <div className="input-group p-2  d-flex ">
                                    <span className="input-group-text" id="basic-addon1">Start</span>
                                    <DatePicker
                                        selected={startDateFilter}
                                        onChange={(date) => setStartDateFilter(date)}
                                        selectsStart
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                        dateFormat="dd MMMM yyyy"
                                    />
                                    <span className="input-group-text" id="basic-addon1">End</span>
                                    <DatePicker
                                        selected={endDateFilter}
                                        onChange={(date) => setendDateFilter(date)}
                                        selectsEnd
                                        startDate={startDateFilter}
                                        endDate={endDateFilter}
                                        minDate={startDateFilter}
                                        filterDate={(date) => {
                                            const dif = moment(startDateFilter).diff(moment(date), 'months');
                                            return dif == 0
                                        }}
                                        dateFormat="dd MMMM yyyy"
                                    />
                                    <button className='btn btn-primary btn-xs' onClick={() => {
                                        reloadData()
                                        getJadwalPerawatan()
                                    }}>Reload</button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {listJadwal.map((jdwl, jdwlindex) =>
                                <div className="card col-6" key={jdwlindex}>
                                    <div className="rounded bg-primary text-white mt-2 mb-2 p-2">
                                        <span className="float-left">
                                            <div className="d-flex flex-row bd-highlight ">
                                                <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">{moment(jdwl.date).format("dddd DD, MMMM YYYY")}</h4></div>
                                                {/* <div className="p-0 bd-highlight"><DatePicker
                                            locale={"id"}
                                            dateFormat="dd MMMM yyyy"
                                            selected={tgl}
                                            onChange={(date) => setTgl(date)}
                                            customInput={<ExampleCustomInput />}
                                        /></div> */}

                                            </div>
                                        </span>
                                        {/* <h5>Jadwal konsultasi</h5> */}

                                    </div>
                                    <div className="">
                                        {/* <div className="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                                        <div className="clearfix"></div>
                                        <div id="basicScenario" className="product-physical">
                                            <ToastContainer />
                                        </div>

                                        {dataKlinik.kliniks.filter(x => idxKliniks.indexOf(x.id) != -1).map((kln, idxkln) =>
                                            <div key={idxkln} className="d-flex flex-column bd-highlight mb-3">
                                                <div className='d-flex flex-row border-bottom border-info justify-content-between'>
                                                    <h6 className=" float-end"><i className="icofont icofont-hospital" /> {kln.name}</h6>
                                                    {/* <div><span className="bg-success rounded p-1 mx-auto m-2" style={{ fontSize: 10 }} onClick={() => {
                                                        setaddFromTemplate(false)
                                                        setIdxKlinik(idxkln)
                                                        setModalOpen(true)
                                                    }}><i className="icofont icofont-plus" /> Tambah </span>
                                                        <span className="bg-info rounded p-1 m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                            setaddFromTemplate(true)
                                                            setIdxKlinik(idxkln)
                                                            setModalOpen(true)
                                                        }}>Pattern</span>
                                                    </div> */}
                                                </div>
                                                <Row >
                                                    <Col md='4'>Sesi</Col>
                                                    <Col md='1'></Col>
                                                    <Col md='1'></Col>
                                                    <Col md='2'>Status</Col>
                                                    <Col md='3'>Action</Col>

                                                </Row>
                                                {dataPerawatan && dataPerawatan.jadwalPerawatan.filter(x => x.id_klinik == kln.id && ((x.is_template && compareDateMin(jdwl.date, x.tanggal)) || filterKlinikDate(kln, x.tanggal, jdwl.date))).map((jp, jpi) =>
                                                    <div key={jpi} className="p-2 bd-highlight border mb-2 ">
                                                        {!jp.is_template ?
                                                            // <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-between">
                                                            //     <div className="d-flex flex-row">
                                                            //         <div className="p-2 bd-highlight">{jp.name}</div>
                                                            //         <div className="p-2 bd-highlight ml-5">{jp.status == 2 || jp.status == 3 ? <del>Jumlah sesi : {jp.jumlah_sesi}</del> : <>Jumlah sesi : {jp.jumlah_sesi}</>}</div>
                                                            //     </div>
                                                            //     <div className="p-2 bd-highlight">Terpakai : {jp.dokul_reservasis.length}</div>
                                                            //     <div className="p-2 bd-highlight"><Badge className={`p-1 rounded ${jp.status == 2 ? "bg-warning" : jp.status == 3 ? "bg-danger" : "bg-success"}`}>{jp.status == 2 ? "Block" : jp.status == 3 ? "Cancel" : jp.status == 4 ? "Full" : "Available"}</Badge></div>
                                                            //     <div className="d-flex flex-row">
                                                            //         <span className="bg-warning rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                            //             let _sesi = { ...jp }
                                                            //             _sesi.status = 2

                                                            //         }}><i className="icofont icofont-not-allowed" /></span>
                                                            //         <span className="bg-danger text rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                            //             let _sesi = { ...jp }
                                                            //             _sesi.status = 3

                                                            //         }}><i className="icofont icofont-ui-delete" /></span>
                                                            //         <span className="bg-success rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => setmodalSesiOpen(true)}><i className="icofont icofont-eye" /></span>
                                                            //     </div>
                                                            // </div>
                                                            <Row >
                                                                <Col md='4'>{jp.sesi}</Col>
                                                                <Col md='1'><Badge className='rounded-circle' color='success'>{jp.jumlah}</Badge></Col>
                                                                <Col md='1'><Badge className='rounded-circle' color='secondary'>{jp.dokul_reservasis.length}</Badge></Col>
                                                                <Col md='2'><Badge className={`p-1 rounded ${jp.status == 2 ? "bg-warning" : jp.status == 3 || jp.jumlah == jp.dokul_reservasis.length ? "bg-cred" : "bg-success"}`} >{jp.status == 2 ? "Distop" : jp.status == 3 ? "Dibatalkan" : jp.jumlah == jp.dokul_reservasis.length ? "Penuh" : "Tersedia"}</Badge></Col>

                                                                <Col md='3'>
                                                                    <div className="d-flex flex-row">
                                                                        <span title='Stop sesi' className="bg-warning rounded p-1 float-right mx-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                                            let _sesi = { ...jp }
                                                                            _sesi.status = 2
                                                                            console.log(_sesi)
                                                                            updateJadwalStatus({
                                                                                variables: {
                                                                                    id: _sesi.id,
                                                                                    body: _sesi,
                                                                                    status: jp.status == 2 ? 1 : 2
                                                                                }
                                                                            })

                                                                        }}>{jp.status == 2 ? <i className="icofont icofont-undo text-white" /> : <i className="icofont icofont-not-allowed" />}</span>
                                                                        <span title='Batalkan sesi' className="bg-cred text rounded p-1 float-right mx-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                                            let _sesi = { ...jp }
                                                                            _sesi.status = 3
                                                                            updateJadwalStatus({
                                                                                variables: {
                                                                                    id: _sesi.id,
                                                                                    body: _sesi,
                                                                                    status: jp.status == 3 ? 1 : 3
                                                                                }
                                                                            })
                                                                            console.log(_sesi)

                                                                        }}>{jp.status == 3 ? <i className="icofont icofont-undo text-white" /> : <i className="icofont icofont-ui-delete" />}</span>
                                                                        <span className="bg-success rounded p-1 float-right mx-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => setmodalSesiOpen(true)}><i className="icofont icofont-eye" /></span>
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                            : getTemplateView(jdwl.date, jp)}
                                                    </div>
                                                )}
                                                {/* {kon.dokul_dokter_jadwals.filter(xjd => compareDate(xjd.tanggal, tgl)).length > 0 ?
                                                kon.dokul_dokter_jadwals.filter(xjd => compareDate(xjd.tanggal, tgl)).map(jd =>
                                                    <div className="p-2 bd-highlight border mb-2 ">
                                                        <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-between">
                                                            <div className="d-flex flex-row">
                                                                <div className="p-2 bd-highlight">{jd.name}</div>
                                                                <div className="p-2 bd-highlight ml-5">{jd.status == 2 || jd.status == 3 ? <del>Jumlah sesi : {jd.jumlah_sesi}</del> : <>Jumlah sesi : {jd.jumlah_sesi}</>}</div>
                                                            </div>
                                                            <div className="p-2 bd-highlight">Terpakai : {jd.terpakai}</div>
                                                            <div className="p-2 bd-highlight"><Badge className={`p-1 rounded ${jd.status == 2 ? "bg-warning" : jd.status == 3 ? "bg-danger" : "bg-success"}`}>{jd.status == 2 ? "Block" : jd.status == 3 ? "Cancel" : jd.status == 4 ? "Full" : "Available"}</Badge></div>
                                                            <div className="d-flex flex-row">
                                                                <span className="bg-warning rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                                    let _sesi = { ...jd }
                                                                    _sesi.status = 2
                                                                    updateSesi({ variables: { type: _sesi }, })
                                                                }}><i className="icofont icofont-not-allowed" /></span>
                                                                <span className="bg-danger text rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                                    let _sesi = { ...jd }
                                                                    _sesi.status = 3
                                                                    updateSesi({ variables: { type: _sesi }, })
                                                                }}><i className="icofont icofont-ui-delete" /></span>
                                                                <span className="bg-success rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => setmodalSesiOpen(true)}><i className="icofont icofont-eye" /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <h6 className="text-center"> Tidak ada jadwal</h6>
                                            } */}

                                            </div>

                                        )}
                                        {/* {data.dokters[idxDokter].dokul_dokter_konsultasis.filter(ykon => ykon.type == 2).length > 0 ?
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                        <h6 className="border-bottom border-info"><i className="icofont icofont-globe" /> ONLINE </h6>
                                        <div className="p-2 bd-highlight mb-2">
                                            <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-left">
                                                <div className="d-flex flex-row">
                                           
                                                </div>
                                                <Link
                                                    to={`/products/edit/${data.dokters[idxDokter].dokul_dokter_konsultasis.find(onk => onk.type == 2).id_product}`}
                                                >
                                                    <span className="p-2 bd-highlight rounded bg-success">EDIT PRODUK </span>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                } */}
                                    </div>
                                </div>)}
                        </Row>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
            <Modal isOpen={modalSesiOpen} toggle={() => setmodalSesiOpen(false)} size='lg' centered  >
                <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Sesi konsultasi detail</h5>

                    </div>
                    <div className="modal-body">
                        <h6 className="font-weight-bold">{data.dokters[idxDokter]?.fullname}</h6>
                        <h6 className="font-weight-bold">{data.dokters[idxDokter]?.dokul_dokter_konsultasis[idxKlinik].dokul_klinik?.name}</h6>
                        <h6 className="font-weight-bold">{moment(tgl).format("dddd DD, MMMM YYYY")}</h6>



                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let _dokter = { ...data.dokters[idxDokter] }
                            let _dokul_dokter_konsultasis = [..._dokter.dokul_dokter_konsultasis]
                            let _currentKonsultasi = { ..._dokul_dokter_konsultasis[idxKlinik] }
                            let currentJadwals = [..._currentKonsultasi.dokul_dokter_jadwals]

                            if (addFromTemplate) {
                                let _listSesi = tmpData.templates[selectedTmp].dokul_jadwal_sesis.map(_sesi => {
                                    return {
                                        id_konsultasi: _currentKonsultasi.id,
                                        name: _sesi.sesi,
                                        tanggal: moment(tgl).format("YYYY/MM/DD"),
                                        jumlah_sesi: _sesi.jumlah_pasien,
                                        terpakai: 0,

                                    }
                                })
                                currentJadwals = [...currentJadwals, ..._listSesi]
                            } else {
                                currentJadwals.push({
                                    id_konsultasi: _currentKonsultasi.id,
                                    name: jadwalBaru.name,
                                    tanggal: moment(tgl).format("YYYY/MM/DD"),
                                    jumlah_sesi: jadwalBaru.jumlah_sesi,
                                    terpakai: 0,

                                })
                            }

                            ///_currentKonsultasi.dokul_dokter_jadwals=currentJadwals
                            // _dokul_dokter_konsultasis[idxKlinik]=_currentKonsultasi
                            _dokter['dokul_dokter_jadwal'] = currentJadwals
                            updateDokter({ variables: { id: 1, type: _dokter }, })


                            setModalOpen(false)
                        }}>Simpan</button>
                    </div>

                </div>
            </Modal>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} size='lg' centered >


                <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Tambah jadwal</h5>

                    </div>
                    <div className="modal-body ">
                        <Row>

                            <Col md={3}>
                                Klinik
                            </Col>
                            <Col md={9}>
                                <h6 className="font-weight-bold">{dataKlinik.kliniks[idxKlinik]?.name}</h6>

                            </Col>
                            {!addFromTemplate && <> <Col md={3}>
                                Tanggal
                            </Col>
                                <Col md={9}>
                                    <div className="input-group p-2  d-flex ">
                                        <span className="input-group-text" id="basic-addon1">Start</span>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="dd MMMM yyyy"
                                        />
                                        <span className="input-group-text" id="basic-addon1">End</span>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setendDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            filterDate={(date) => {
                                                const dif = moment(startDate).diff(moment(date), 'months');
                                                return dif == 0
                                            }}
                                            dateFormat="dd MMMM yyyy"
                                        />
                                    </div>
                                </Col></>
                            }
                        </Row>

                        {addFromTemplate ? <>
                            {tmpData?.templates.filter(x=>x.id_layanan.includes(3)).map((template, idx) =>
                                <div key={idx}>
                                    <li className={`list-group-item ${selectedTmp == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                        // setselectedTmp(idx)

                                    }}>{template.template_name}
                                        {
                                            selectedTmp.find(x => x.template_id == template.id)?.status != 1 ?
                                                <span className='float-end text-danger' onClick={() => {
                                                    let _selectedTmp = [...selectedTmp]
                                                    const idx_ = _selectedTmp.findIndex(x => x.template_id == template.id)
                                                    if (idx_==-1) {
                                                        setselectedTmp(x => [...x,
                                                        {
                                                            template_id: template.id,
                                                            jadwal_type: 2,
                                                            tanggal: moment().format('YYYY-MM-DD'),
                                                            sesi: "0",
                                                            jumlah: 0,
                                                            id_services: 0,
                                                            id_klinik: dataKlinik.kliniks[idxKlinik]?.id,
                                                            is_template: 1,
                                                            status: 1

                                                        }
                                                        ])
                                                    }else{
                                                        
                                                        let _tmp = _selectedTmp[idx_]
                                                        _tmp.status = 1
                                                        _selectedTmp[idx_] = _tmp
                                                        // _selectedTmp.splice(idx_, 1)
                                                        setselectedTmp(_selectedTmp)
                                                    }
                                                }
                                                }><i className='icofont icofont-toggle-off' style={{ fontSize: "24px" }} /></span>
                                                :
                                                <span className='float-end text-success' onClick={() => {
                                                    let _selectedTmp = [...selectedTmp]
                                                    const idx_ = _selectedTmp.findIndex(x => x.template_id == template.id)
                                                    let _tmp = _selectedTmp[idx_]
                                                    _tmp.status = 3
                                                    _selectedTmp[idx_] = _tmp
                                                    // _selectedTmp.splice(idx_, 1)
                                                    setselectedTmp(_selectedTmp)

                                                }}><i className='icofont icofont-toggle-on' style={{ fontSize: "24px" }} /></span>
                                        }
                                    </li>

                                    <Collapse isOpen={selectedTmp.find(x => x.template_id == template.id)?.status == 1}>
                                        <div className="d-flex flex-column bd-highlight mb-3 w-100">
                                            <div className="d-flex flex-row-reverse">


                                                <DatePicker
                                                    className='mt-2 border-success'
                                                    selected={moment(selectedTmp.find(x => x.template_id == template.id)?.tanggal).toDate()}
                                                    onChange={(date) => {
                                                        let _selectedTmp = [...selectedTmp]
                                                        const _idx = _selectedTmp.findIndex(x => x.template_id == template.id)
                                                        _selectedTmp[_idx] = { ..._selectedTmp[_idx], tanggal: moment(date).format("YYYY-MM-DD") }
                                                        setselectedTmp(_selectedTmp)
                                                        //setendDate(date)
                                                    }}
                                                    dateFormat="dd MMMM yyyy"
                                                />

                                            </div>
                                            {template.dokul_jadwal_sesis.map((sesi, idxs) => <div key={idxs} className="d-flex flex-row bd-highlight">
                                                <div className="p-2 bd-highlight col-6">{sesi.hari.split(",").map((hari, idxh) => <Badge key={idxh} className="p-1 m-1">{listHari[hari]}</Badge>)}</div>
                                                <div className="p-2 bd-highlight col-4"><span className="">{sesi.sesi}</span></div>
                                                <div className="p-2 bd-highlight mx-auto"><span className="float-end">{sesi.jumlah_pasien} Pasien</span></div>
                                            </div>
                                            )}

                                        </div>

                                    </Collapse>
                                </div>
                            )}


                        </> : <>

                            {/* <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1" >Sesi</span>
                                </div>
                                <input type="text" className="form-control" onChange={(e) => setJadwalBaru({ name: e.target.value, jumlah_sesi: jadwalBaru.jumlah_sesi })} />
                            </div> */}

                            <Row>
                                <Col md={3}>
                                    Biaya reservasi :
                                </Col>
                                <Col md={9}>
                                    {/* <input type="number" className="form-control m-2" onChange={(e) => setJadwalBaru({ jumlah_sesi: e.target.value, name: jadwalBaru.name })} /> */}
                                    <CurrencyInput
                                        className='p-2 m-2 border rounded border-light'
                                        id="input-example"
                                        name="input-name"
                                        placeholder="Please enter a number"
                                        defaultValue={0}
                                        decimalsLimit={2}
                                        onValueChange={(value, name) => setJadwalBaru({ biaya: value, name: jadwalBaru.name, jumlah_sesi: jadwalBaru.jumlah_sesi })}
                                    />
                                </Col>
                                <Col md={3}>
                                    Jam Mulai :
                                </Col>
                                <Col md={9}>
                                    <DatePicker
                                        className='m-2'
                                        selected={timeFrom}
                                        onChange={(date) => {
                                            settimeFrom(date)
                                            setJadwalBaru({ name: `${moment(date).format("HH:mm")}-${moment(timeTo).format("HH:mm")}`, jumlah_sesi: jadwalBaru.jumlah_sesi, biaya: jadwalBaru.biaya })

                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Jam mulai"
                                        dateFormat="HH:mm"
                                    />
                                </Col>
                                <Col md={3}>
                                    Jam Akhir :
                                </Col>
                                <Col md={9}>
                                    <DatePicker
                                        className='m-2'
                                        selected={timeTo}
                                        onChange={(date) => {
                                            settimeTo(date)

                                            setJadwalBaru({ name: `${moment(timeFrom).format("HH:mm")}-${moment(date).format("HH:mm")}`, jumlah_sesi: jadwalBaru.jumlah_sesi, biaya: jadwalBaru.biaya })

                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Jam mulai"
                                        dateFormat="HH:mm"
                                    />
                                </Col>
                                <Col md={3}>
                                    Jumlah pasien :
                                </Col>
                                <Col md={9}>
                                    <input type="number" className="form-control m-2 w-25" onChange={(e) => setJadwalBaru({ jumlah_sesi: e.target.value, name: jadwalBaru.name, biaya: jadwalBaru.biaya })} />

                                </Col>
                            </Row>
                            {/* <div className="form-group mb-3">
                                <label for="jammulai">Jam Mulai :</label>
                                <DatePicker
                                    className='m-2'
                                    selected={timeFrom}
                                    onChange={(date) => {
                                        settimeFrom(date)
                                        setJadwalBaru({ name: `${moment(date).format("HH:mm")}-${moment(timeTo).format("HH:mm")}`, jumlah_sesi: jadwalBaru.jumlah_sesi })

                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Jam mulai"
                                    dateFormat="HH:mm"
                                />
                              
                            </div>
                            <div className="form-group mb-3">
                                <label for="jamakhir" >Jam Akhir : </label>
                                <DatePicker
                                    className='m-2'
                                    selected={timeTo}
                                    onChange={(date) => {
                                        settimeTo(date)

                                        setJadwalBaru({ name: `${moment(timeFrom).format("HH:mm")}-${moment(date).format("HH:mm")}`, jumlah_sesi: jadwalBaru.jumlah_sesi })

                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Jam mulai"
                                    dateFormat="HH:mm"
                                />
                              
                            </div> */}

                            {/* <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon12" >Jumlah</span>
                                </div>
                                <input type="number" className="form-control" onChange={(e) => setJadwalBaru({ jumlah_sesi: e.target.value, name: jadwalBaru.name })} />
                            </div> */}
                        </>}


                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            console.log(selectedTmp)
                            if (addFromTemplate) {
                                updateJadwalPerawatan({
                                    variables: {
                                        type: selectedTmp
                                    }
                                })
                            } else {
                                let newJadwals = []
                                let dtF = moment(startDate)
                                let dtT = moment(endDate);

                                const jumlahTanggal = dtT.diff(dtF, 'days')
                                // console.log(jumlahTanggal)
                                // console.log(jadwalBaru)

                                for (let index = 0; index < (jumlahTanggal + 1); index++) {
                                    newJadwals.push({
                                        tanggal: dtF.format("YYYY/MM/DD"),
                                        sesi: jadwalBaru.name,
                                        jadwal_type: 2,
                                        biaya: jadwalBaru.biaya,
                                        jumlah: jadwalBaru.jumlah_sesi,
                                        id_services: 0,
                                        id_klinik: dataKlinik.kliniks[idxKlinik]?.id,
                                        is_template: 0,
                                        status: 1

                                    })
                                    dtF = dtF.add(1, 'days')

                                }

                                updateJadwalPerawatan({
                                    variables: {
                                        type: newJadwals
                                    }
                                })
                            }

                            setModalOpen(false)
                        }}>Simpan</button>
                    </div>

                </div>

            </Modal>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}


export default JadwalTindakan

