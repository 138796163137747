import React, { useState, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
//import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../../apollo'
import { useQuery, useMutation, readQuery, writeQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import OrderList from './order.list';
import OrderRefund from "./order.refund"
import OrderDetail from './model.order.detail'
import TransactionList from '../transactions-sales'
import Loading from '../../common/loading';
import { GET_CATEGORIES, GET_TAGS, GET_PRODUCTS, GET_ORDERS, GET_CONFIGS, GET_LIST_VOUCHER_PRIVATE, GET_IDPRODUCTS_PREORDER, GET_PRODUCTS_SIMPLE } from '../../../constants/graph'
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;
const UPDATE_ORDER = gql`
  mutation UpdateOrder($id: String!, $type: String!) {
    updateOrder(id: $id, body: $type) 
    @rest(
      type: "Orders"
      path: "api/order"
      method: "PUT"
      bodyKey: "body"
    ){
      __typename
      id 
            admin_fee
            billing_address_id
            branch_id
            coupon_discount
            coupon_type
            coupon_used
            created_date
            current_grade
            grand_total
            is_usecoupon
            is_usepoint
            layanan_kurir
            modify_by
            modify_date
            ongkir
            order_status
            order_status_text
            payment_method
            payment_method_text
            point_earned
            point_used
            shipping_address_id
            total
            total_discount
            unix_payment
            user_id
            dokul_address,
            dokul_orders_items  
            payment_status,
            bukti_bayar,
            nomor_resi
    }
  }
`;
const UPDATE_ORDER_BULK = gql`
  mutation UpdateOrderBulk($id: String!, $type: String!) {
    updateOrderBulk(id: $id, body: $type) 
    @rest(
      type: "Orders"
      path: "api/order/bulk"
      method: "PUT"
      bodyKey: "body"
    ){
      listId
      order_status
      order_status_text
    }
  }
`;
const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $email: String!
    $password: String!
  ) {
    orders(input: "Foo", body: $email)
    @rest(
      type: "Orders"
      path: "api/order?size=1"
      method: "GET"
      bodyKey: "body"
    ) {
    orders @type(name: "Order") { 
        id 
        admin_fee 
        is_usecoupon
        is_usepoint
        created_date
        grand_total
        total
        order_status
        order_status_text
        payment_method_text
        payment_method,
        dokul_address,
        dokul_orders_items             
      } ,
    currentPage
    totalItems
    totalPages
  }
  }
`;

// const GET_CATEGORIES = gql`query categories {
//     categories @rest(type: "Category",path: "api/products/categories") {
//         id
//         name
//         description
//     }
//   }
// `;
// const GET_TAGS = gql`query tags {
//     tags @rest(type: "Tag",path: "api/products/tags") {
//         id,
//         name,
//         description
//     }
//   }
// `;
const Orders = ({ match }) => {
  const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        const allBranchRole = [5, 4]
        const jktRole = [2, 11]
        const bpnRole = [3, 10]
        const columns = [];
        const apotekROle=[16,17]
        const adminPremiumRole=[18]
        const packerJkt=[19]
        const packerBpn=[15]
  const [viewDetailOrder, setviewDetailOrder] = useState(false)
  const [gridSetting, setgridSetting] = useState()
  const { loading: lPrivateVoucher, error: ePrivateVoucher, data: dPrivateVoucher } = useQuery(GET_LIST_VOUCHER_PRIVATE,{
    fetchPolicy:'network-only'
})

const { loading: lProductPo, data: dProductPo, error: eProductPo } = useQuery(GET_IDPRODUCTS_PREORDER, {
  fetchPolicy:'cache-and-network'
});
// const { loading: lProduct, data: dProduct, error: eProduct } = useQuery(GET_PRODUCTS, {
//   variables: {
//       // type: selectedCategory,
//       // priceMax: selectedPrice.max,
//       // priceMin: selectedPrice.min,
//       // color: selectedColor,
//       page: 0,
//       brand: 'all',
//       categories: 'all',
//       textSearch: 'all'
             
//       // indexFrom: 0,
//       // limit: limit
//   },
//   fetchPolicy:'cache-and-network'
// });
const { loading: lProduct, data: dProduct, error: eProduct } = useQuery(GET_PRODUCTS_SIMPLE,{
  variables:{
      brand:'all',
      txtSearch:'all'
  }
});
  if (!Cookies.get('signedin')) {
    history.push('/');
  }
  const { loading: lConfig, data: dConfig, error: errConfig } = useQuery(GET_CONFIGS,);
  //const params = useParams();

  if (match.params.page) {

    console.log(match.params.id)
  }

  const updateCache = (cache, { data }) => {

    // Fetch the todos from the cache
    // const existingTodos = cache.readQuery({
    //   query: GET_ORDERS
    // });
    // console.log("HOLAAAAAA")
    //console.log(data)
    const fragmentResult = cache.writeFragment({
      id: `Order:${data.updateOrder.id}`,
      fragment: gql`
          fragment order on Order{
            id 
            admin_fee
            billing_address_id
            branch_id
            coupon_discount
            coupon_type
            coupon_used
            created_date
            current_grade
            grand_total
            is_usecoupon
            is_usepoint
            layanan_kurir
            modify_by
            modify_date
            ongkir
            order_status
            order_status_text
            payment_method
            payment_method_text
            point_earned
            point_used
            shipping_address_id
            total
            total_discount
            unix_payment
            user_id
            dokul_address,
            dokul_orders_items  
            payment_status,
            bukti_bayar,
            nomor_resi
            order_notes
          }
        `,
      data: data.updateOrder
      //  {
      //   __typename: 'Order',
      //   order_status_text: '1952',
      //   total:1
      // }
    });
    // console.log(fragmentResult)
    // Add the new todo to the cache
    // const newTodo = data.insert_todos.returning[0];
    // cache.writeQuery({
    //   query: GET_ORDERS,
    //   data: {orders: [{}, ...existingTodos.orders.orders]}
    // });
  };

  const updateCacheBulk = (cache, { data }) => {

    // Fetch the todos from the cache
    const existingTodos = cache.readQuery({
      query: GET_ORDERS
    });
    let _et2 = { ...existingTodos }
    let _et = { ..._et2.orders }

    let updatesOrder = _et.orders.map(ud => {
      let _ud = { ...ud }
      if (data.updateOrderBulk.listId.includes(_ud.id)) {
        _ud.order_status = data.updateOrderBulk.order_status
        _ud.order_status_text = data.updateOrderBulk.order_status_text

      }
      return _ud
    })
    _et.orders = updatesOrder
    _et2.orders = _et
    cache.writeQuery({
      query: GET_ORDERS,
      data: _et2
    });
  };
  const [updateOrder] = useMutation(UPDATE_ORDER, { update: updateCache });
  const [updateOrderBulk] = useMutation(UPDATE_ORDER_BULK, { update: updateCacheBulk });
  const [selectedBranch, setselectedBranch] = useState(1)

  const [getOrderData,{ loading, data, error, fetchMore }] = useLazyQuery(GET_ORDERS, {
    variables: {
      page: 0,
      size: 10,
    },
    // fetchPolicy:'network-only'
  });
  // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
  // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
  if (error) {
    console.log(error.message);
    Cookies.remove('signedin', '/');
    Cookies.remove('jwt');



    history.push('/');
    //return <div>Error!</div>;
  }


  const client = useApolloClient();
  // const { datal } = useQuery(IS_LOGGED_IN);
  // const [datas, setData] = useState(datanya)
  const [order, setorder] = useState()
  const [orders] = useMutation(LOGIN_MUTATION, {

    update(cache, { data: { orders } }) {
      // const { orders } = cache.readQuery({
      //   query: GET_PRODUCTS
      // });
      // console.log(orders)
      // const updatedLinks = feed.links.map((feedLink) => {
      //   if (feedLink.id === link.id) {
      //     return {
      //       ...feedLink,
      //       votes: [...feedLink.votes, vote]
      //     };
      //   }
      //   return feedLink;
      // });

      cache.writeQuery({
        query: GET_ORDERS,
        variables: {
          page: 0,
          size: 10

        },
        data: {
          orders: []
        }
      });
    }


  });
  const getNextData = (cpagesize, rpage, id, date, displayname, grandTotal, statusText, layanan_kurir,premiumOrder) => {

    //     const UPDATE_MUTATION = gql`
    //     mutation updateMutation(
    //       $data: any
    //       $id:ID!
    //     ) {
    //       login(id:$id,body: $data)
    //       @rest(
    //         type: "Post"
    //         path: "api/products/{args.id}"
    //         method: "PUT"
    //         bodyKey: "body"
    //       ) {
    //       completed
    //     }
    //     }
    //   `;

    //    client.writeQuery({
    //     GET_CATEGORIES,
    //         data: {
    //           orders: {
    //             orders: [],
    //           },
    //         },
    //       });
    // orders().then(data=>{
    //     console.log(data)
    // })
    let _premiumOrder=adminPremiumRole.some(x => user.dokul_user_roles.some(y => y.role_id == x))?'pw_':undefined
    if(rpage==0 && !data)
    return getOrderData({
      variables:{
        page: rpage,
        size: cpagesize,
        id: id,
        date: date,
        displayname: displayname,
        grand_total: grandTotal,
        statusText: statusText,
        layanan_kurir: layanan_kurir,
        branchid: selectedBranch,
        premiumOrder:premiumOrder?premiumOrder:_premiumOrder

      }
    })
    else
    return fetchMore({
      variables: {
        page: rpage,
        size: cpagesize,
        id: id,
        date: date,
        displayname: displayname,
        grand_total: grandTotal,
        statusText: statusText,
        layanan_kurir: layanan_kurir,
        branchid: selectedBranch,
        premiumOrder:premiumOrder?premiumOrder:_premiumOrder

      },
    })
    //   .then(dt=>{
    //       console.log(dt)
    //     return dt.data
    //   });
    //       const { orders,error } = client.readQuery({
    //         query: GET_ORDERS,
    //         variables: {
    //           page:rpage,
    //           size: 10

    //   }
    //       });
    //       if(error)
    //         return
    //return orders
  }

  const viewDetail = (prod) => {
    setviewDetailOrder(true)
    setorder(prod)
    history.replace({ pathname: `/sales/orders/detail/${prod.id}` })
    // return <OrderDetail order={prod} updateOrder={updateOrder} config={dConfig} />
  }

  // if(match.params.page=='edit' || match.params.page=='detail'){
  //     let _order = client.readFragment({
  //         id: `Order:${match.params.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
  //         fragment: gql`
  //           fragment readOrder on Order{
  //             id 
  //             admin_fee
  //             billing_address_id
  //             branch_id
  //             coupon_discount
  //             coupon_type
  //             coupon_used
  //             created_date
  //             current_grade
  //             grand_total
  //             is_usecoupon
  //             is_usepoint
  //             layanan_kurir
  //             modify_by
  //             modify_date
  //             ongkir
  //             order_status
  //             order_status_text
  //             payment_method
  //             payment_method_text
  //             point_earned
  //             point_used
  //             shipping_address_id
  //             total
  //             total_discount
  //             unix_payment
  //             user_id
  //             dokul_address,
  //             dokul_orders_items  
  //             payment_status,
  //             dokul_user
  //             bukti_bayar,
  //             nomor_resi
  //             alamat_kirim
  //             email
  //             order_notes
  //           }
  //         `,
  //     }, false);
  //    setorder(_order)
  //     if(!order) return <div/>
  // }




  //daricahce
  // const { products } = client.readQuery({
  //     query: gql`
  //       query ReadProducts {
  //         products {
  //           id
  //         }
  //       }
  //     `,
  //   },false);

  // const todo = client.readFragment({
  //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
  //     fragment: gql`
  //       fragment myTodo on Product {
  //         id
  //         name
  //       }
  //     `,
  // });
  //console.log(data);
  // setData(datanya)
  // if (loading || lProduct|| !dConfig) return <Loading  show={true}/>
  return (
    <Fragment>
      {loading || lProduct|| !dConfig  || !dProductPo ?  <Loading  show={true}/>: <>
        <Breadcrumb title={`${match.params.page == "refund" ? "Pengembalian" : match.params.page == "transactions" ? "Log transaksi bank" : "Pesanan"}`} parent="Penjualan" />
        {/* {!viewDetailOrder && <div className="d-flex flex-row mb-5">
          <div className="p-2">Dokterkulitku : </div>
          <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(1)}>JAKARTA</div>
          <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(2)}>BALIKPAPAN</div>
          <div className={`p-2  pointer ${!selectedBranch ? 'bg-primary' : ''}`} onClick={() => setselectedBranch()}>ALL</div>
        </div>} */}
        {
          match.params.page == 'list' ?
            <OrderList listPreorder={dProductPo.productspo}  dProduct={dProduct} dPrivateVoucher={dPrivateVoucher}  data={data} selectedBranch={selectedBranch}  />
            : match.params.page == 'detaili' || viewDetailOrder ?
              <OrderDetail  dProduct={dProduct} dPrivateVoucher={dPrivateVoucher}  order={data.orders.orders[0]} updateOrder={updateOrder} config={dConfig} setviewDetailOrder={setviewDetailOrder}  />
              : match.params.page == 'edit' ?
                <OrderDetail  dProduct={dProduct} dPrivateVoucher={dPrivateVoucher}  data={data} id={match.params.id} order={order} />
                : match.params.page == 'refund' ?
                  <OrderRefund data={data} getNextData={getNextData} client={client} updateOrder={updateOrder} updateOrderBulk={updateOrderBulk} />
                  : match.params.page == 'transdactions' ?
                    <TransactionList data={data} />
                    :
                    <OrderList listPreorder={dProductPo.productspo} premiumOrder={adminPremiumRole.some(x => user.dokul_user_roles.some(y => y.role_id == x))?'pw_':undefined} selectedBranch={selectedBranch} setselectedBranch={setselectedBranch} config={dConfig} gridSetting={gridSetting} setgridSetting={setgridSetting} viewDetail={viewDetail}  data={data} getNextData={getNextData} client={client} updateOrder={updateOrder} updateOrderBulk={updateOrderBulk} dProduct={dProduct} dPrivateVoucher={dPrivateVoucher} />
        }
      </>
      }

    </Fragment>
  )

}

export default withApollo(Orders)
