import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings,Home } from 'react-feather'
import Breadcrumb from '../common/breadcrumb';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { GET_CONFIGS } from '../../constants/graph'
import BranchKontak from './branch.kontak'
import BranchOrder from './branch.order'
import BranchKlinik from './branch.klinik'
import QRCode from 'qrcode.react'

const Tabset_profile = ({match}) => {

    const { loading, data, error, fetchMore } = useQuery(GET_CONFIGS,);

    if (loading) return "LOADING"
    console.log(match.params.branch)
    const { jakarta, all, balikpapan } = data.configs
    console.log(data.configs)
    return (
        <Fragment>
            <Breadcrumb title="Profile" parent="Settings" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card profile-card">
                            <div className="card-body">
                                {/* <QRCode value="1@QvVM7IruC/ZWsTxF7VrpSF37Y9XKufpBh0mQhJg/QttEGTGRJ8BCNugNQbaEl6n1kvvCOCTtTKUm8A==,v8FTXaHi3AEyRXb/WSshjeMoAFQMMONvwL1OB02etCg=,bAtwe95PSTxLYbeAliz9SA==" /> */}
                                <Tabs>
                                    <TabList className="nav nav-tabs tab-coupon" >
                                        <Tab className="nav-link"><User className="mr-2" />Kontak</Tab>
                                        <Tab className="nav-link"><Settings className="mr-2" />Order</Tab>
                                        <Tab className="nav-link"><User className="mr-2" />About page</Tab>
                                        <Tab className="nav-link"><Settings className="mr-2" />Konsul page</Tab>
                                    </TabList>

                                    <TabPanel>

                                        <BranchKontak  data={data.configs.find(br=>br.name.toLowerCase().includes(match.params.branch))}/>
                                    </TabPanel>
                                    <TabPanel>
                                        {/* <div className="tab-pane fade"> */}
                                        <BranchOrder  data={data.configs.find(br=>br.name.toLowerCase().includes(match.params.branch))} />
                                        {/* </div> */}
                                    </TabPanel>
                                    
                                    <TabPanel>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Banner</h5>
                                                        <p className="card-text">Jl. hsds dihaskjd djsahdjksa dsakjhdsa </p>
                                                        {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Tetang</h5>
                                                        <p className="card-text">On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains. </p>
                                                        {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Banner</h5>
                                                        <p className="card-text">Jl. hsds dihaskjd djsahdjksa dsakjhdsa </p>
                                                        {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Tetang</h5>
                                                        <p className="card-text">On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains. </p>
                                                        {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withApollo(Tabset_profile)
