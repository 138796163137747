import React, { useState, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import { GET_LAYANANS, GET_PRODUCTS } from '../../constants/graph';


const ProductResult = (props) => {
   
    

  
    
    return (
     
         
        <div className="d-flex">
        <div className="p-1"> <img  src={`${props.img_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${props.img_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
            </div>
        
        <div className="p-1 ml-auto"><i className="icofont icofont-close text-danger "  style={{cursor:"pointer"}} onClick={()=>props.onDetailDelete()}/> </div>
      </div>
  
    )

}

export default ProductResult
