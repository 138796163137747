import React, { useState, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
//import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery,writeQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import UserAdd from './create-user'
import ROLES from './roles'
import { GET_PRODUCTS, GET_PRODUCTS_SIMPLE, GET_USERS,GET_USER_DETAIL, UPDATE_USER, UPDATE_USER_ROLE } from '../../constants/graph';
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;


const Users = ({ match }) => {
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    const [lastVar, setlastVar] = useState()
    //const params = useParams();

    if (match.params.page) {
        // console.log(match.params.page)
        // console.log(match.params.id)


    }
    const { loading:lProducts, data:dProducts, error:eProducts } = useQuery(GET_PRODUCTS_SIMPLE, {
      variables: {
          brand: 'all',
          textSearch:'all'
      }
  });
    
    const [getUsers,{ loading, data, error,fetchMore,refetch }] = useLazyQuery(GET_USERS,
      // {
      //   fetchPolicy:"network-only",
      //   variables: {
      //       page: 0,
      //       size: 10,
      //       role:1
      //   }
    // }
    );
    const updateCahce=(cache, {data})=> {
        console.log(data.updateUser)
        const fragmentResult = cache.writeFragment({
            id: `User:${data.updateUser[0].id}`,
            fragment: gql`
                fragment order on Order{
                  id 
                  admin_fee
                  billing_address_id
                  branch_id
                  coupon_discount
                  coupon_type
                  coupon_used
                  created_date
                  current_grade
                  grand_total
                  is_usecoupon
                  is_usepoint
                  layanan_kurir
                  modify_by
                  modify_date
                  ongkir
                  order_status
                  order_status_text
                  payment_method
                  payment_method_text
                  point_earned
                  point_used
                  shipping_address_id
                  total
                  total_discount
                  unix_payment
                  user_id
                  dokul_address,
                  dokul_orders_items  
                  payment_status,
                  bukti_bayar,
                  nomor_resi
                  order_notes
                }
              `,
            data: data.updateUser[0]
            //  {
            //   __typename: 'Order',
            //   order_status_text: '1952',
            //   total:1
            // }
          });
      
    // console.log(users)
         
    
    //     cache.writeQuery({
    //       query: gql`
    //       query WriteUser($id: Int!) {
    //         User(id: $id) {
    //             id
    //             username
    //             email
    //             password
    //             first_name
    //             last_name
    //             display_name
    //             create_date
    //             modify_date
    //             role
    //             status
    //             wcid
    //             current_point
    //             current_grade
    //             current_slot
    //             current_address
    //             phone
    //             jenis_kulit
    //             warna_kulit
    //             branch_id
    //             role_id
    //         }
    //       }`,
    //       data: {
    //         User: {
    //             __typename: 'User',
    //         id: 45252,
    //         email: 'Buy grapes',
    //         first_name: "false"}
    //       }
    //     });
      }
    const [updateUser, {loading: updateLoading}] = useMutation(UPDATE_USER, {
        //  fetchPolicy:"no-cache",
        //  refetchQueries: [{query: GET_USERS,variables:lastVar}],
        //  awaitRefetchQueries: true,
        update:updateCahce
      });
      const [updateUserRoles] = useMutation(UPDATE_USER_ROLE)
    const [getUserDetail,{ loading:lUserDetail, data:dUserDetail, error:eUserDetail }] = useMutation(GET_USER_DETAIL);
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    if (error ) {
        // console.log(error.message);
        Cookies.remove('signedin','/');
        Cookies.remove('jwt');
        


        history.push('/');
        //return <div>Error!</div>;
    }
    
   
    const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    let order;
    // const [orders] = useMutation(LOGIN_MUTATION, {
        
    //     update(cache, { data: { orders } }) {
    //         // const { orders } = cache.readQuery({
    //         //   query: GET_PRODUCTS
    //         // });
    //     console.log(orders)
    //         // const updatedLinks = feed.links.map((feedLink) => {
    //         //   if (feedLink.id === link.id) {
    //         //     return {
    //         //       ...feedLink,
    //         //       votes: [...feedLink.votes, vote]
    //         //     };
    //         //   }
    //         //   return feedLink;
    //         // });   
        
    //         cache.writeQuery({
    //           query: GET_ORDERS,
    //             variables: {
    //                 page: 0,
    //                 size: 10
                
    //         },
    //           data: {
    //             orders: []
    //           }
    //         });
    //       }
        
            
    // });
    const updateUserData=(user,variable)=>{
        updateUser({variables:{data:user}})

    }
    const getNextData=(cpagesize,rpage,role,email,first_name,date,grade)=>{
        setlastVar({
            page:rpage,
            size: cpagesize,
            email:email,
            first_name:first_name,
            date:date,
            role:role,
            grade:grade
        })
        if(rpage==0 && !data)
    return getUsers({
      variables: {
        page:rpage,
            size: cpagesize,
            email:email,
            first_name:first_name,
            date:date,
            role:role,
            grade:grade

      }
    })
    else
    return fetchMore({
        
        variables: {
            page:rpage,
            size: cpagesize,
            email:email,
            first_name:first_name,
            date:date,
            role:role,
            grade:grade
        
    },
      })
    }
if(match.params.page=='edit' || match.params.page=='detail'){
    order = client.readFragment({
        id: `User:${match.params.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment readOrder on Order{
            id
            username
            email
            password
            first_name
            last_name
            display_name
            create_date
            modify_date
            role
            status
            wcid
            current_point
            current_grade
            current_slot
            current_address
            phone
            jenis_kulit
            warna_kulit
            branch_id
          }
        `,
    }, false);
    if(!order) return <div/>
}
    

    //daricahce
    // const { products } = client.readQuery({
    //     query: gql`
    //       query ReadProducts {
    //         products {
    //           id
    //         }
    //       }
    //     `,
    //   },false);

    // const todo = client.readFragment({
    //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment myTodo on Product {
    //         id
    //         name
    //       }
    //     `,
    // });
    //console.log(data);
    // setData(datanya)
    //if(!data) return "LOADING"

    //console.log(data.users.users)
  

    return (
        <Fragment>
            {loading||lProducts? <div style={{width:'100%',height:'100%',backgroundColor:'darkorange'}}>Loading</div>:<>
            <Breadcrumb title="Data Pengguna" parent="" />
            {
                match.params.page == 'roles' ?
                <ROLES data={data} dProducts={dProducts.productssimple} getNextData={getNextData} getUserDetail={getUserDetail} refetch={updateUserRoles} dUserDetail={dUserDetail}  />
                    : <UserAdd />
            }
            </>
        }

        </Fragment>
    )

}

export default withApollo(Users)
