import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
//images import
import man from '../../../assets/images/dashboard/man.png'
import Cookies from 'js-cookie'
import { isLoggedInVar } from '../../../cache';
import { useQuery, useMutation, readQuery, useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import history from '../../../history';

const LOGOUT_MUTATION = gql`
    mutation logoutMutation( $data: any) {
      logout(body:$data)
      @rest(
        type: "Post"
        path: "auth/local/logout"
        method: "post"
        bodyKey: "body"
      ) {
      message
    }
}
  `;

const User_menu = () => {
    
    
    const client = useApolloClient()

    const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
    //const handleLogout = (e) => {

        const [doLogout] = useMutation(LOGOUT_MUTATION,{
            variables: {
                data: { clear: true }
            },
            onCompleted: ({doLogout}) => {
                Cookies.set('signedin',false)
                console.log(Cookies.get('signedin'))
                isLoggedInVar(false)
                Cookies.remove('signedin');
                client.clearStore().then(() => {
                    Cookies.remove('token', { path: '/' });
                    Cookies.remove('jwt', { path: '/' });
                    Cookies.remove('jwt', { path: '/' ,domain:"api.dokterkulitku.com"});
                    Cookies.remove('jwt', { path: '/' ,domain:"admin.dokterkulitku.com"});
                    Cookies.remove('signedin', { path: '/' });
                    Cookies.remove('signedin', { path: '/sales' });
                    Cookies.remove('signedin', { path: '/products' });
                    history.push('/')
                })

            }, onError: ({ graphQLErrors, networkError }) => {
                console.log(networkError)
                console.log(graphQLErrors)
            }
        })

 //   }


    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    {/* <img className="align-self-center pull-right img-30 rounded-circle blur-up lazyloaded" src={`${process.env.REACT_APP_IMAGE_HOST}/${user.image_url}`}  alt="header-user" /> */}
                    <button className="btn btn-xs btn-primary text-danger" onClick={doLogout} >Logout <i data-feather="log-out" className="icofont icofont-logout"></i></button>
                    {/* <div className="dotted-animation"><span className="animate-circle"></span><span className="main-circle"></span></div> */}
                </div>
                {/* <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover"> */}
                    {/* <li><Link to={`${process.env.PUBLIC_URL}/settings/profile`} ><i data-feather="user"></i>Edit Profile</Link></li> */}
                    {/* <li><a href="#"><i data-feather="mail"></i>Inbox</a></li>
                    <li><a href="#"><i data-feather="lock"></i>Lock Screen</a></li>
                    <li><a href="#"><i data-feather="settings"></i>Settings</a></li> */}
                    {/* <li><button onClick={doLogout} ><i data-feather="log-out"></i>Logout</button></li> */}
                {/* </ul> */}
            </li>
        </Fragment>
    )

}

export default withRouter(User_menu)
