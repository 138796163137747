import React, { Component, Fragment, useMemo, useState,useEffect } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import OptionHeader from '../common/header_components/optionheader';
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import { Collapse } from 'reactstrap';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';


import moment from 'moment'
const GET_RESERVASI = gql`query reservasiKonsul(
    $page: String!
    $size: String!
    $qry:String!
) {
    reservasiKonsul(page:$page,size: $size,qry:$qry) @rest(type: "reservasiKonsul",path: "api/konsultasi/reservasi?page={args.page}&size={args.size}&{args.qry}") {
        data @type(name: "ReservasiKonsul") { 
          id
          id_klinik
          id_parent
          create_by
          create_date
          modify_date
          email
          fullName
          description
          date_selected
          status_pasien
          phone
          sesi
          tgl_lahir
          nama_layanan
          status
          tujuan
          status_text
          dokul_service
          dokul_klinik
          dokul_dokter_konsultasi           
        } ,
      currentPage
      totalItems
      totalPages
        
    }

    }
  `;
const Reservasi = (props) => {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         filterDokterOpen:true,
    //         filterSesiOpen:true,
    //         open: false,
    //         imageHasChange: false,
    //         myData: [],
    //         file: user,
    //         selectedReservasi: { dokul_dokter_konsultasi: { dokul_dokter: {} } },
    //         indexReservasi: 0
    //     };
    // }
    // onOpenModal = () => {
    //     this.setState({ open: true });
    // };

    // onCloseModal = () => {
    //     this.setState({ open: false });
    // };
    // onDelete = () => {

    // }
    // onEdit = (cat) => {
    //     this.setState({
    //         selectedReservasi: cat
    //     }, () => this.onOpenModal())
    // }
    // handleChange = (e) => {
    //     const { name, value } = e.target
    //     let selectedReservasi = this.state.selectedReservasi
    //     selectedReservasi[name] = value
    //     this.setState({
    //         selectedReservasi: selectedReservasi
    //     })
    //     console.log(selectedReservasi)
    // }
    // saveService = () => {
    //     let newService = this.state.selectedReservasi
    //     if (this.state.imageHasChange) {
    //         const formData = new FormData()
    //         formData.append('uploaded_file', this.state.file, `${newService.name}_${this.state.file.name}`)


    //         fetch('http://localhost:9000/api/upload', {
    //             method: 'POST',
    //             body: formData
    //         }).then(response => response.json())
    //             .then(data => {
    //                 console.log(data)
    //                 newService.picture = `/assets/images/uploads/${data.d[0].filename}`
    //                 newService.deskripsi = newService.name
    //                 // newService.type = 2
    //                 newService.banner = `/assets/images/uploads/${data.d[0].filename}`
    //                 const t = this.props.saveService({
    //                     variables: {
    //                         data: newService
    //                     },
    //                     onCompleted: (saveService) => {
    //                         console.log("Sukses")
    //                         // history.push('/products');
    //                     }, onError: ({ graphQLErrors, networkError }) => {
    //                         console.log("ada eror")
    //                         console.log(networkError)
    //                         console.log(graphQLErrors)
    //                     },
    //                 }).then(data => {
    //                     // console.log(data) 
    //                     this.onCloseModal()
    //                 })

    //             })

    //             .catch(error => {
    //                 console.error(error)
    //             })
    //     } else {
    //         const t = this.props.saveService({
    //             variables: {
    //                 data: newService
    //             },
    //             onCompleted: (saveService) => {
    //                 console.log("Sukses")
    //                 // history.push('/products');
    //             }, onError: ({ graphQLErrors, networkError }) => {
    //                 console.log("ada eror")
    //                 console.log(networkError)
    //                 console.log(graphQLErrors)
    //             },
    //         }).then(data => {
    //             // console.log(data) 
    //             this.onCloseModal()
    //         })

    //     }



    // }
    // selectRow = (e, i) => {
    //     if (!e.target.checked) {
    //         this.setState({
    //             checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
    //         });
    //     } else {
    //         this.state.checkedValues.push(i);
    //         this.setState({
    //             checkedValues: this.state.checkedValues
    //         })
    //     }
    // }

    // handleRemoveRow = () => {
    //     const selectedValues = this.state.checkedValues;
    //     const updatedData = this.state.myData.filter(function (el) {
    //         return selectedValues.indexOf(el.id) < 0;
    //     });
    //     this.setState({
    //         myData: updatedData
    //     })
    //     toast.success("Successfully Deleted !")
    // };

    // renderEditable = (cellInfo) => {
    //     return (
    //         <div
    //             style={{ backgroundColor: "#fafafa" }}
    //             contentEditable
    //             suppressContentEditableWarning
    //             onBlur={e => {
    //                 const data = [...this.state.myData];
    //                 data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
    //                 this.setState({ myData: data });
    //             }}
    //             dangerouslySetInnerHTML={{
    //                 __html: this.state.myData[cellInfo.index][cellInfo.column.id]
    //             }}
    //         />
    //     );
    // }
    // renderImage = (cellInfo) => {
    //     return (
    //         <div
    //             style={{ backgroundColor: "#fafafa" }}
    //             suppressContentEditableWarning
    //             dangerouslySetInnerHTML={{
    //                 __html: `<img src=${process.env.REACT_APP_IMAGE_HOST}${this.state.myData[cellInfo.index][cellInfo.column.id]} style="width:50px;height:50px;"/>`
    //             }}
    //         />
    //     );
    // }

    // Capitalize(str) {
    //     return str.charAt(0).toUpperCase() + str.slice(1);
    // }
    // changeImageContent = (e, showmodal) => {
    //     let selectedReservasi = this.state.selectedReservasi
    //     let reader = new FileReader();
    //     let file = e.target.files[0];




    //     reader.onloadend = () => {
    //         selectedReservasi.picture = reader.result;
    //         this.setState({
    //             imageHasChange: true,
    //             file: file,
    //             selectedReservasi: selectedReservasi
    //         })

    //     }
    //     reader.readAsDataURL(file)

    // }
    // componentDidMount = async () => {
    //     const { loading, error, data } = await this.props.client.query({ query: GET_RESERVASI })
    //     console.log(data)
    //     this.setState({
    //         myData: data.reservasiKonsul.data
    //     })

    // }


    // render() {

    // const { open, selectedReservasi, myData, imageHasChange ,filterDokterOpen,filterSesiOpen} = this.state
    const [filterSesiOpen, setfilterSesiOpen] = useState(true)
    const [filterDokterOpen, setfilterDokterOpen] = useState(true)
    const [open, setopen] = useState(false)
    const [selectedReservasi, setselectedReservasi] = useState({ dokul_dokter_konsultasi: { dokul_dokter: {} } })
    const [filterString, setfilterString] = useState("")
   const [myData, setmyData] = useState([])
    const [selectedDate, setselectedDate] = useState(moment().format('YYYY-MM-DD'))
    const { loading, error, data,refetch } = useQuery(GET_RESERVASI, {
        variables: {
            page: 0,
            size: 100,
            qry: `date_selected=${selectedDate}`
        }
    })

    const sesiList = ["11:00-11:30", "11:30-12:00", "12:00-12:30", "12:30-13:00", "13:00-13:30", "13:30-14:00", "14:00-14:30", "14:30-15:00"]
    const dokterList = ["DR Simon", "DR Lusi", "DR Arthur"]

    useEffect(() => {
        if(!loading)
        setmyData(data.reservasiKonsul.data)
    }, [data])

    useEffect(() => {
        refetch()
    }, [selectedDate])

    useEffect(() => {
        if(selectedReservasi.id)
            setopen(true)
    }, [selectedReservasi])

    const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']

    const columns = useMemo(
        () => [

            {
                Header: <b>Pasien</b>,
                //accessor: "create_date",
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.fullName}
                    </div>
                    <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div>
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Konsultasi</b>,
                accessor: "fullName",
                width: 200,
                Cell: (cellInfo) =>
                    <div className="d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight">
                            {moment(cellInfo.original.date_selected).format("dddd DD, MMMM YYYY")}
                        </div>
                        <div className="p-2 bd-highlight">
                            {cellInfo.original.sesi}
                        </div>
                        <div className="p-2 bd-highlight"><span className="bg-info p-2 rounded">{status[cellInfo.original.status]}</span></div>

                    </div>,
                style: {
                    textAlign: 'left',
                }
            },
            {
                Header: <b>Dokter</b>,
                //accessor: "date_selected",
                Cell: (cellInfo) => cellInfo.original.dokul_dokter_konsultasi.dokul_dokter.fullname,
                style: {
                    textAlign: 'left',
                }
            },
            {
                Header: <b>Klinik</b>,
                // accessor: "date_selected",
                Cell: (cellInfo) => cellInfo.original.dokul_klinik?.address,
                style: {
                    textAlign: 'center',
                }
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                width: 100,
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <span onClick={() => {
                            setselectedReservasi(row.original)

                        }}>
                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                        </span>



                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        ])

    if (loading) return "Loading"

    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3">
                        <div className="col-sm-12">


                            <div className="list-group">
                                <li className="list-group-item active" aria-current="true">Filter</li>
                                <li className="list-group-item bg-light text-dark" aria-current="true">Tanggal</li>
                                <li className="list-group-item bg-white text-dark" aria-current="true">
                                    <input className="form-control" name="name" id="name-point" type="date" defaultValue={selectedDate} onChange={(e) => {
                                        console.log(e.target.value)
                                        setselectedDate(e.target.value)
                                    }} />
                                </li>

                                <li className="list-group-item bg-light text-dark" aria-current="true" onClick={() => {
                                    setfilterSesiOpen(!filterSesiOpen)
                                }}>Sesi</li>
                                <Collapse isOpen={filterSesiOpen}>
                                    {sesiList.map((kl, idx) => {
                                        return <label className="list-group-item" key={idx}>
                                            <input className="form-check-input me-1" type="checkbox" value={kl.id} onChange={(e) => {
                                                let _curData=[...myData]

                                                if(e.target.checked){
                                                    let res=_curData.filter(dt=>dt.sesi==kl)
                                                    setmyData(res)

                                                }else{
                                                    setmyData(data.reservasiKonsul.data)
                                                }
                                            }} />
                                            {kl}
                                        </label>
                                    })}
                                </Collapse>
                                <li className="list-group-item bg-light text-dark" aria-current="true" onClick={() => {
                                    setfilterDokterOpen(!filterDokterOpen)
                                }}>Dokter</li>
                                <Collapse isOpen={filterDokterOpen}>
                                    {dokterList.map((dr, idx) =>
                                        <label className="list-group-item" key={idx}>
                                            <input className="form-check-input me-1" type="checkbox" value={dr.id} onChange={(e) => {


                                            }} />
                                            {dr}
                                        </label>
                                    )}
                                </Collapse>

                            </div>


                        </div>
                    </div>
                    <div className="col-sm-9">

                        {/* <div className="card">
                                <div className="card-header">
                                    <h5>Reservasi</h5>

                                </div>
                                <div className="card-body"> */}


                        {/* <div className="btn-popup pull-right"> */}

                        {/* <button type="button" className="btn btn-primary" onClick={() => {
                                            this.setState({
                                                selectedReservasi: {picture:user,dokul_kliniks:[],type:1,imageHasChange:false}
                                            }, () => this.onOpenModal())
                                        }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Add Service</button> */}
                        <Modal open={open} onClose={() => setopen(false)} >
                            <div className="modal-header">
                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{selectedReservasi.id ? 'Reservasi detail' : 'Add service'}</h5>
                            </div>


                            <div className="modal-body">
                                {/* <form autoComplete='off'>

                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className='img-front'><div className="box-input-file-lg text-center"  >
                                                                <img src={`${selectedReservasi.dokul_dokter_konsultasi.dokul_dokter?.picture?.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${selectedReservasi.dokul_dokter_konsultasi.dokul_dokter?.picture}`} width={100} height={100} className="img-fluid image_zoom_1 blur-up lazyloaded"></img>


                                                            </div>
                                                                <h4 className="m-10 text-center">{selectedReservasi.dokul_dokter_konsultasi.dokul_dokter.fullname}</h4>
                                                            </div>
                                                            <hr />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <dl className="dl-horizontal">
                                                                <dt >Tanggal reservasi</dt>
                                                                <dd >{selectedReservasi.create_date}</dd>

                                                                <dt >Jadwal konsultasi</dt>
                                                                <dd >
                                                                    {selectedReservasi.date_selected}
                                                                </dd>

                                                                <dt >Klinik</dt>
                                                                <dd >{selectedReservasi.dokul_klinik?.name}</dd>
                                                                <dt >konsultasi</dt>
                                                                <dd >{selectedReservasi.nama_layanan}</dd>
                                                                <dt >Status reservasi</dt>
                                                                <dd >{selectedReservasi.status_text}</dd>

                                                            </dl>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <dl className="dl-horizontal">


                                                                <dt >Nama pasien</dt>
                                                                <dd >{selectedReservasi.fullName}</dd>
                                                                <dt >Email pasien</dt>
                                                                <dd >{selectedReservasi.email}</dd>
                                                                <dt >Telepon pasien</dt>
                                                                <dd >{selectedReservasi.phone}</dd>
                                                                <dt >Status pasien</dt>
                                                                <dd >{statusPasien[selectedReservasi.status_pasien]}</dd>
                                                                <dt >Keluhan</dt>
                                                                <dd >{statusPasien[selectedReservasi.description]}</dd>


                                                            </dl>
                                                        </div>
                                                    </div>






                                                </form> */}
                                <div className="card" >
                                    <div className="card-body row">
                                        <div className="col-4">
                                            <img src={`${selectedReservasi.dokul_dokter_konsultasi?.dokul_dokter?.picture?.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${selectedReservasi.dokul_dokter_konsultasi.dokul_dokter?.picture}`} ></img>
                                        </div>
                                        <div className="col-8">
                                            <h2 className="card-title">{selectedReservasi.dokul_dokter_konsultasi?.dokul_dokter.fullname}</h2>
                                            <p className="card-text">{selectedReservasi.dokul_klinik?.name}</p>
                                            <p className="card-text">{selectedReservasi?.dokul_klinik?.address}</p>

                                            <dl class="row">
                                                <dt className="col-sm-5">Tanggal reservasi</dt>
                                                <dd className="col-sm-7">{moment(selectedReservasi?.create_date).format("dddd ,DD MMMM YYYY HH:mm A")}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Tanggal konsultasi</dt>
                                                <dd className="col-sm-7">{moment(selectedReservasi?.date_selected).format("dddd ,DD MMMM YYYY HH:mm A")}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Nama</dt>
                                                <dd className="col-sm-7">{selectedReservasi?.fullName}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Status pasien</dt>
                                                <dd className="col-sm-7">{selectedReservasi?.status_pasien == 1 ? 'Pasien Baru' : 'Pasien Lama'}</dd>
                                                <hr />

                                                <dt className="col-sm-5 text-truncate">Email</dt>
                                                <dd className="col-sm-7">{selectedReservasi?.email}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Phone</dt>
                                                <dd className="col-sm-7">{selectedReservasi?.phone}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Keluhan</dt>
                                                <dd className="col-sm-7">{selectedReservasi.description}</dd>
                                                <hr />


                                            </dl>
                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-danger" onClick={() => { }}>Batalkan reservasi</button>
                                <button type="button" className="btn btn-secondary" onClick={() => setopen(false)}>Close</button>
                            </div>
                        </Modal>
                        {/* </div> */}
                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                            {/* <Datatable
                                            onDelete={this.onDelete}
                                            editLink={"/services/edit/"}
                                            onEdit={this.onEdit}
                                            multiSelectOption={false}
                                            myData={this.props.data.layanans}
                                            hideFields={['__typename', 'id', 'banner', 'type', 'dokul_kliniks', 'dokul_content_rows', 'deskripsi', 'dokul_page']}
                                            listFields={[]}
                                            pageSize={10}
                                            pagination={true}
                                            className="-striped -highlight"
                                        /> */}
                            <ReactTable
                                data={myData}
                                columns={columns}
                                defaultPageSize={10}
                                className={"-striped -highlight"}
                                showPagination={true}
                                NoDataComponent={()=><div className="rt-noData">Data tidak ditemukan</div>}
                            />
                            {/* <ToastContainer /> */}
                        </div>
                    </div>
                </div>
                {/* </div>
                    </div> */}
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}
// }
export default withApollo(Reservasi)

