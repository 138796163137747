export const MESSAGE_FROM_GUEST="MESSAGE_FROM_GUEST"
export const MESSAGE_FROM_ADMIN_TO_GUEST="MESSAGE_FROM_ADMIN_TO_GUEST"
export const MESSAGE_FROM_USER_TO_ADMIN="MESSAGE_FROM_USER_TO_ADMIN"
export const MESSAGE_FROM_USER_TO_DOKTER="MESSAGE_FROM_USER_TO_DOKTER"
export const MESSAGE_FROM_ADMIN_TO_USER="MESSAGE_FROM_ADMIN_TO_USER"
export const MESSAGE_FROM_DOKTER_TO_USER="MESSAGE_FROM_DOKTER_TO_USER"
export const MESSAGE_FROM_DOKTER_TO_ADMIN="MESSAGE_FROM_DOKTER_TO_ADMIN"
export const MESSAGE_FROM_ADMIN_TO_DOKTER="MESSAGE_FROM_ADMIN_TO_DOKTER"
export const MESSAGE_FROM_SYSTEM_TO_DOKTER="MESSAGE_FROM_SYSTEM_TO_DOKTER"
export const MESSAGE_FROM_SYSTEM_TO_ADMIN="MESSAGE_FROM_SYSTEM_TO_ADMIN"
export const MESSAGE_FROM_SYSTEM_TO_USER="MESSAGE_FROM_SYSTE_TO_USER"
export const MESSAGE_FROM_SYSTEM_TO_GUEST="MESSAGE_FROM_SYSTE_TO_USER"
export const MESSAGE_FROM_GUEST_ROOM="MESSAGE_FROM_GUEST_ROOM"
export const MESSAGE_FROM_ADMIN_TO_WA_GUEST="MESSAGE_FROM_ADMIN_TO_GUEST"
export const MESSAGE_FROM_WA_GUEST_ROOM="MESSAGE_FROM_WA_GUEST_ROOM"
export const JOIN_TO_DOKTER_PRIVATE_ROOM="JOIN_TO_DOKTER_PRIVATE_ROOM"
export const JOIN_TO_KONSUL_ROOM="JOIN_TO_KONSUL_ROOM"
