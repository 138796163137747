import React, { Component } from 'react'
import ReactToPrint from 'react-to-print';
import PRINTOUT from "./printout"
import { saveAs } from "file-saver";
import { Packer } from "docx";
import { DocumentCreator } from "./wordgenerator";
import { experiences, education, skills, achievements } from "./data";
class ModelOrderPrintout extends Component {
    //function ModelOrderPrintout(props) {
    constructor(props) {
        super(props)
        this.state = {
            ordersReadyPrint: [],
            ordersHasPrint: [],
            

        }
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_HOST}/api/order/readyprint`, { credentials: 'include', })
        .then(response => response.json())
        .then(data => {
            this.setState({
                ordersReadyPrint: data.filter(dt => dt.order_status == 31 || dt.order_status == 32),
                ordersHasPrint: data.filter(dt => dt.order_status == 311 || dt.order_status == 321),

            })

        })
    }
    handleUpdateToPrinted() {
        
        let updatesOrder = this.state.ordersReadyPrint.filter(dt => dt.order_status == 31 || dt.order_status == 32).map((rp,index) => { 
            let _ord={...rp}
            _ord.order_status=rp.order_status + "1"
            _ord.nomor_cetak=this.state.ordersHasPrint.length+index
            return _ord
        })
        fetch(`${process.env.REACT_APP_API_HOST}/api/order/updateprint`, {
            credentials: 'include',
            method:"PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(updatesOrder)
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    // ordersReadyPrint: data.filter(dt => dt.order_status == 31 || dt.order_status == 32),
                    // ordersHasPrint: data.filter(dt => dt.order_status == 311 || dt.order_status == 321),

                })

            })
       
    }
    handleGenerateWord(){
        const documentCreator = new DocumentCreator();
    const doc = documentCreator.create([
        experiences,
        this.state.ordersReadyPrint,
        this.state.ordersHasPrint,
        achievements
    ]);

    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, "example.docx");
      console.log("Document created successfully");
    });

    }
    render() {
        const { ordersReadyPrint, ordersHasPrint } = this.state
        const itemStatus = ['', 'Menunggu stock', "Dibatalkan", 'Selesai']
        const pageStyle = `
  @page {
    size: 10mm 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
        return (

            <div className="container">
                <div className="row align-items-top mt-5">
                    {ordersReadyPrint.length > 0 || ordersHasPrint.length > 0 ?
                        <>
                            <div className="col-12 border">
                                {ordersReadyPrint.length > 0?
                                <>
                                <ReactToPrint
                                    // pageStyle={pageStyle}
                                    onAfterPrint={(res) => {
                                        console.log(res)

                                    }}
                                    trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <a href="#" className="btn btn-info btn-small m-2">Cetak</a>;
                                    }}
                                    content={() => this.componentRef}
                                />

                                <a href="#" className="btn btn-primary btn-small m-2" onClick={()=>this.handleUpdateToPrinted()}><small>Tandai  sudah di cetak</small></a>
                                
                                <a href="#" className="btn btn-primary btn-small m-2" onClick={()=>this.handleGenerateWord()}><small>Generate DOCX</small></a>
                                
                                </>:""
                    }

                            </div>
                            {/* <div className="col-9">
                                
                            </div> */}

                            <div className="col-sm-6">
                                <h6 className="ml-3 mt-2 mb-2">{ordersReadyPrint.length > 0?"Order belum di cetak dengan status packing":"Belum ada order dengan status packing"}</h6>
                                <PRINTOUT orders={ordersReadyPrint} startAt={ordersHasPrint.length} ref={el => (this.componentRef = el)} />
                            </div>

                            <div className="col-sm-6">
                                <h6 className="ml-3 mt-2 mb-2 w-100">Order sudah di cetak </h6>
                                <small className="text-danger">Note: Check label untuk cetak ulang</small>
                                <div className="border mr-0" style={{ margin: 0, padding: "0" }}>
                                    <div className="card-body " >
                                        {ordersHasPrint.map((order, index) =>
                                        

                                            <div key={index} className="row align-items-center border-bottom">
                                                <div className="col-sm-2" >
                                                    <input type="checkbox" onChange={(e)=>{
                                                        let rePrint={...order}
                                                        let readyPrint=[...ordersReadyPrint]
                                                        if(e.target.checked)
                                                            readyPrint.push(rePrint)
                                                        else{
                                                            let delPrint=readyPrint.find(rd=>rd.id==order.id)
                                                            let idx=readyPrint.indexOf(delPrint)
                                                            readyPrint.splice(idx,1)
                                                        }


                                                        this.setState({
                                                            ordersReadyPrint:readyPrint
                                                        })

                                                    }} />
                                                </div>

                                                <dl className=" m-2 pb-2 col-sm-8" style={{ fontSize: 10 }}>
                                                    <dt className="col-sm-12 mb-0">{order.shipping_address_id != 0 ? "" : "Ambil diklinik"}</dt>
                                                    {order.shipping_address_id != 0 ? <>
                                                        <dd className="col-sm-12 mb-0">{order.nomor_cetak ? order.nomor_cetak+1 : index + 1}  {order.dokul_address.first_name} {order.dokul_address.last_name} {order.dokul_address.phone}</dd>
                                                        {/* <dd className="col-sm-12 mb-0">{order.dokul_address.phone}</dd> */}
                                                        <dd className="col-sm-12 mb-0">{order.dokul_address.address_1}</dd>
                                                        <dd className="col-sm-12 mb-0">({order.dokul_address.address_state}) {order.dokul_address.address_city} {order.dokul_address.address_2} {order.dokul_address.address_postcode}</dd>
                                                    </> : 
                                                    <dd className="col-sm-12 mb-0">{order.nomor_cetak ? order.nomor_cetak : index + 1} {order.dokul_user.first_name} {order.dokul_user.last_name} {order.dokul_user.phone}</dd>}
                                                    <dd className="col-sm-12 mb-2">Dari : Dokterkulitku 0817821107</dd>

                                                    <dd className="col-sm-12 mb-0">{order.layanan_kurir.split(" ")[0].toLowerCase()} : {order.dokul_orders_items.map(item => `${item.sku_alias}(${item.quaintiy}) , `)} </dd>

                                                </dl>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix" />
                        </>
                        :

                        <div className="text-white bg-danger mb-0 w-100" >
                            <div className="card-header">CETAK LABEL</div>
                            <div className="card-body">
                                <h5 className="card-title text-light">Maaf belum ada order dengan status packing ...!</h5>
                            </div>
                        </div>

                    }
                </div>
            </div>



        )
    }
}

export default ModelOrderPrintout
