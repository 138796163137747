import React, { Component, useState, useContext, useEffect } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings } from 'react-feather'
import Modal from 'react-responsive-modal';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { GET_CONFIGS, UPDATE_SHIPMENT_METHOD } from '../../constants/graph'
import SocketContext from '../../provider/socketctx/context';
import DispatchContext from '../../provider/socketctx/disptachContext';
import QRCode from 'qrcode.react'
import { gql } from 'graphql-tag';
const BranchKontak = (props) => {
    const { positionInLine, queueLength, message, socketId, waconnect } = useContext(SocketContext);
    const newMessage = useContext(DispatchContext)
    const { loading, data, error, fetchMore } = useQuery(GET_CONFIGS,);
    const [waCode, setWaCode] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpenEdit, setModalOpenEdit] = useState(false)
    const [editData, seteditData] = useState(null)
    const [editDataKlinik, seteditDataKlinik] = useState(null)
    const [idxDataKlinik, setidxDataKlinik] = useState(0)
    useEffect(() => {
        console.log(waconnect)
        if (waconnect.sessid) {
            delete waconnect['sessid'];
            fetch("https://dokterkulit-integrasi.herokuapp.com/api/integrasi/authWa", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Origin':'http://localhost:3000'
                },
                body: JSON.stringify(waconnect)
            }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        setModalOpen(false)
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                    }
                )
        }
    }, [waconnect])
    const authWhatsapp = () => {
        let auth = {
            sessid: socketId,
            WABrowserId: '"Js9vUCYhUmk7p+/4rhIzWg=="',
            WASecretBundle: '{"key":"MDIXLJAKGoQzI0IKxU2JJMLfWGAM8Osbmyx0F6v6y/s=","encKey":"1XMMGbwE1TloHwoVtcL6sAygMAYCQhHNzimMe8RAyRE=","macKey":"MDIXLJAKGoQzI0IKxU2JJMLfWGAM8Osbmyx0F6v6y/s="}',
            WAToken1: '"c4xsVO5XOePAJZpndLBf1tOezQZ5XNH00co58Gnc7To="',
            WAToken2: '"1@d8NnJiK+V4KxzHNuXtWrGTO6ne6zR2aF710q6aodw9DNiaxXJr6XKFEwotBjyCD8goN0lZf40Uw/Cw=="'
        }
        fetch("https://dokterkulit-integrasi.herokuapp.com/api/integrasi/authWa", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin':'http://localhost:3000'
            },
            body: JSON.stringify(auth)
        }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    setModalOpen(false)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }
    const getWaInit = () => {
        let myheaders = {
            "sessid": socketId,
            'Origin':'http://localhost:3000'

        }
        fetch("https://dokterkulit-integrasi.herokuapp.com/api/integrasi/initWa", { headers: myheaders })
            .then(res => res.json())
            .then(
                (result) => {
                    setWaCode(result.code)
                    setModalOpen(true)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }
    const updateCache = (cache, { data }) => {
        seteditData(null)
        seteditDataKlinik(null)
        setidxDataKlinik(null)
        console.log(data)
    };
    const [updateconfigs, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(UPDATE_SHIPMENT_METHOD, { update: updateCache });
    if (loading) return "LOADING"
    console.log(data)
    const { dokul_configs, dokul_paymethods, dokul_kliniks } = props.data
    return (
        <div className="row">
            <div className="col-12 tab-pane fade show active">
                <h5 className="f-w-600 f-16 border-bottom text-info">Kontak</h5>
                <div className="table-responsive profile-table mb-3">
                    <ul className="list-group">
                        <li className={`list-group-item bg-white w-100`}>
                            <div className="form-group row mb-2">
                                <label className="col-xl-3 col-md-3">Email</label>
                                <div className="col-md-7">
                                    <input className="form-control" name="name" disabled={editData == null ? true : false} id="name-point" value={editData != null ?editData[0].nilai: dokul_configs.find(ktk => ktk.name.includes("email")).nilai} type="text" required="" onChange={(e) => {
                                    let _editRows=[...editData]
                                    let _editRow={..._editRows[0]}
                                    _editRow.nilai=e.target.value
                                    _editRows[0]=_editRow
                                    seteditData(_editRows)

                                    }} />
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-xl-3 col-md-3">Telepon</label>
                                <div className="col-md-7">
                                    <input className="form-control" name="name" disabled={editData == null ? true : false} id="name-point" value={editData != null ?editData[1].nilai: dokul_configs.find(ktk => ktk.name.includes("tlp")).nilai} type="text" required="" onChange={(e) => {
                                    let _editRows=[...editData]
                                    let _editRow={..._editRows[1]}
                                    _editRow.nilai=e.target.value
                                    _editRows[1]=_editRow
                                    seteditData(_editRows)
                                    }} />
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-xl-3 col-md-3">Fax</label>
                                <div className="col-md-7">
                                    <input className="form-control" name="name" disabled={editData == null ? true : false} id="name-point" value={editData != null ?editData[2].nilai: dokul_configs.find(ktk => ktk.name.includes("fax")).nilai} type="text" required="" onChange={(e) => {
                                    let _editRows=[...editData]
                                    let _editRow={..._editRows[2]}
                                    _editRow.nilai=e.target.value
                                    _editRows[2]=_editRow
                                    seteditData(_editRows)
                                    }} />
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-xl-3 col-md-3">Whatsapp</label>
                                <div className="col-md-4">
                                    <input className="form-control" name="name" disabled={editData == null ? true : false} id="name-point" type="text" value={editData != null ?editData[3].nilai: dokul_configs.find(ktk => ktk.name.includes("wa")).nilai} required="" onChange={(e) => {
                                    let _editRows=[...editData]
                                    let _editRow={..._editRows[3]}
                                    _editRow.nilai=e.target.value
                                    _editRows[3]=_editRow
                                    seteditData(_editRows)
                                    }} />
                                </div>
                                <div className="col-md-5">
                                    <div className="d-flex flex-row">
                                        <div className="p-2"><span className="bg-success p-1 rounded align-middle" style={{ cursor: "pointer" }} onClick={() => getWaInit()}><i className="icofont icofont-brand-whatsapp" /></span> </div>
                                        <div className="p-2"><span className="bg-success p-1 rounded" style={{ cursor: "pointer" }} onClick={() => authWhatsapp()}> reconnect</span></div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className={`btn float-end mt-2 ${editData == null ? "btn-light" : "btn-primary "}`} onClick={() => {
                                if (editData == null) {
                                    let editedRows=[{...dokul_configs.find(ktk => ktk.name.includes("email"))},{...dokul_configs.find(ktk => ktk.name.includes("tlp"))},{...dokul_configs.find(ktk => ktk.name.includes("fax"))},{...dokul_configs.find(ktk => ktk.name.includes("wa"))}]
                                    seteditData(editedRows)
                                }
                                else {
                                    updateconfigs({
                                        context: {
                                            headers: {
                                                "option": "CONFIG"
                                            }
                                        },
                                        variables: {
                                            body: editData,
                                            id: "config"
                                        }
                                    })
                                }
                            }}>{editData == null ? "Edit" : "Save"}</button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="col-12 tab-pane fade show active">
                <h5 className="f-w-600 f-16 border-bottom text-info">Klinik</h5>
                {dokul_kliniks.map((kl, idxKl) =>
                    <div key={idxKl} className="table-responsive profile-table mb-3">
                        <ul className="list-group">
                            <li className={`list-group-item bg-white w-100`}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="file-upload-product d-flex flex-nowrap">

                                            <div className='img-front m-1' style={{ padding: 0 }}>
                                                <div className="box-input-file-lg"  >
                                                    <img src={`${kl.banner.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.banner}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />

                                                    {/* <img src={`${pictures[activeImg] && pictures[activeImg]?.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${pictures[activeImg]?.image_url}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" /> */}
                                                    <div className="product-hover">
                                                        <ul>
                                                            <li>
                                                                <div className="box-input-file-lg"  >
                                                                    <button className="btn" type="button">
                                                                        Edit
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="form-group row mb-2">
                                            <label className="col-xl-3 col-md-3">Name</label>
                                            <div className="col-md-9">
                                                <input className="form-control" name="name" disabled={editDataKlinik != null && idxDataKlinik == idxKl ? false : true} id="name-point" value={editDataKlinik != null && idxDataKlinik == idxKl ? editDataKlinik.name : kl.name} type="text" required="" onChange={(e) => {
                                                let _editedData={...editDataKlinik}
                                                _editedData.name=e.target.value
                                                seteditDataKlinik(_editedData)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-xl-3 col-md-3">Telepon</label>
                                            <div className="col-md-9">
                                                <input className="form-control" name="name" disabled={editDataKlinik != null && idxDataKlinik == idxKl ? false : true} id="name-point" value={editDataKlinik != null && idxDataKlinik == idxKl ? editDataKlinik.tlp : kl.tlp} type="text" required="" onChange={(e) => {
                                                let _editedData={...editDataKlinik}
                                                _editedData.tlp=e.target.value
                                                seteditDataKlinik(_editedData)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-xl-3 col-md-3">Alamat</label>
                                            <div className="col-md-9">
                                                <textarea className="form-control" name="name" disabled={editDataKlinik != null && idxDataKlinik == idxKl ? false : true} id="name-point" value={editDataKlinik != null && idxDataKlinik == idxKl ? editDataKlinik.address : kl.address} type="text" required="" onChange={(e) => {
                                                    let _editedData={...editDataKlinik}
                                                    _editedData.address=e.target.value
                                                    seteditDataKlinik(_editedData)

                                                }} />
                                            </div>
                                        </div>

                                        <button type="button" className={`btn float-end mt-2 ${editDataKlinik != null && idxDataKlinik == idxKl ? "btn-primary" : "btn-light"}`} onClick={() => {
                                            if (editDataKlinik == null) {
                                                seteditDataKlinik(kl)
                                                setidxDataKlinik(idxKl)
                                            }
                                            else {
                                                updateconfigs({
                                                    context: {
                                                        headers: {
                                                            "option": "KLINIK"
                                                        }
                                                    },
                                                    variables: {
                                                        body: editDataKlinik,
                                                        id: "klinik"
                                                    }
                                                })
                                            }

                                        }}>{editDataKlinik != null && idxDataKlinik == idxKl ? "Save" : "Edit"}</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
            </div>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)} center={true}>
                <div className="card-body">
                    <h6 className="card-title">Connect whatsapp</h6>
                    <QRCode size={256} value={waCode} />
                </div>
                {/* <ModelOrderDetail order={this.props.data.orders.orders[indexSelectedOrder]} updateOrder={this.props.updateOrder}  /> */}
            </Modal>
            <Modal open={modalOpenEdit} onClose={() => setModalOpenEdit(false)} center={true}>
                <div className="card-body">
                    <h6 className="card-title">Connect whatsapp</h6>
                    <QRCode size={256} value={waCode} />
                </div>
                {/* <ModelOrderDetail order={this.props.data.orders.orders[indexSelectedOrder]} updateOrder={this.props.updateOrder}  /> */}
            </Modal>
        </div>
    )
}
export default withApollo(BranchKontak)
