import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
// import data from '../../../assets/data/category';
import Datatable from '../../common/datatable';
import { ToastContainer, toast } from 'react-toastify';
export class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedCategory: {}
        };
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    onDelete = () => {

    }
    onEdit = (cat) => {
        this.setState({
            selectedCategory: cat
        }, () => this.onOpenModal())
    }
    handleChange = (e) => {
        const { name, value } = e.target
        let selectedCategory = this.state.selectedCategory
        selectedCategory[name] = value
        this.setState({
            selectedCategory: selectedCategory
        })
    }
    saveCategory = () => {
        this.props.saveCategory(this.state.selectedCategory).then(result => {
            this.onCloseModal()
        })



    }

    render() {
        const { open, selectedCategory } = this.state
        const { saveCategory,data } = this.props
        if(!data || !data.roles) return "Loading"
        return (
            <Fragment>
                {/* <!-- Container-fluid starts--> */}
                {/* <div className="container-fluid"> */}
                {/* <div className="row"> */}
                {/* <div className="col-sm-12">
                    <div className="mb-2">

                        <button type="button" className="btn btn-primary btn-small float-right" onClick={() => {
                            this.setState({
                                selectedCategory: {}
                            }, () => this.onOpenModal())
                        }}
                        >Tambah Kategori</button>
                    </div>
                </div> */}
                <div className="col-sm-12">
                    {/* <div className="card"> */}

                    <div className="mt-2">
                    
                        <div className="clearfix"></div>
                        <div id="basicScenario" className="">
                            <ul className="list-group">
                            <li className={`list-group-item bg-info w-100`}>User role produk </li>
                                <li className={`list-group-item ${selectedCategory.role_name == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    this.setState({ selectedCategory: {} })
                                    this.props.refreshProduct({} )
                                    }}>Semua role</li>
                                {data.roles.map((cat,idx) =>
                                    <li key={idx}  className={`list-group-item ${selectedCategory.role_name == cat.role_name ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ selectedCategory: cat })
                                        this.props.refreshProduct(cat)

                                    }}>{cat.role_name}</li>
                                )}
                            </ul>
                            {/* <Datatable
                                            onDelete={this.onDelete}
                                            onEdit={this.onEdit}
                                            multiSelectOption={false}
                                            myData={this.props.data.categories} 
                                            hideFields={['__typename','id']}
                                            pageSize={10} 
                                            pagination={true}
                                            class="-striped -highlight" 
                                        /> */}
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Roles

