import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Edit, Trash2, Plus, XCircle, X, Search } from 'react-feather'
import { Alert, Badge, FormGroup, Label, Input, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import history from '../../history';
import Modal from 'react-responsive-modal';
export class PointTabset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeShow: true,
            open: false,
            startDate: new Date(),
            //myData: this.props.point?.dokul_point_rules,
            filterProduct: '',
            modalType: 0,
            resultSimulated: '',
            simulateVal1: '',
            simulateVal2: 0,
            idxSelectedRule: 0,
            selectedRule: {},
            newPoint: {dokul_point_grades:[]},
            point: this.props.point
        }
        // this.handleChange = this.handleChange.bind(this)
    }
    onOpenModal = (mt, i) => {
        let selectedRule = {}
        let newRule = {}
        if (mt == 3)
            selectedRule = this.state.point?.dokul_point_rules[i];



        this.setState({
            modalType: mt,
            idxSelectedRule: i,
            selectedRule: selectedRule,
            newRule: newRule
        }, () => this.setState({ open: true }));
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.point?.dokul_point_rules.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    addRules = () => {

        let point = { ...this.state.point }
        let rules = [...this.state.point.dokul_point_rules]
        if (this.state.modalType == 2) {
            rules.push(this.state.selectedRule)

        } else if (this.state.modalType == 3) {

            rules[this.state.idxSelectedRule] = this.state.selectedRule

        }

        point.dokul_point_rules = rules


        this.setState({
            point: point
        }, () => this.onCloseModal())

    }
    handleFormChangeNewrule = (e) => {
        const { name, value } = e.target
        let selectedRule = { ...this.state.selectedRule }
        selectedRule[name] = value;
        this.setState({
            selectedRule: selectedRule
        })
        //console.log(this.state.newRule)

    }

    Capitalize(str) {
        const arrStr = str.split('_')
        str = arrStr.length > 1 ? arrStr[1] : arrStr[0]
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    _handleSave = () => {

        console.log(this.state.point)
        let newPoint = { ...this.state.point }
        newPoint.create_by = 1;
        newPoint.modify_by = 1;
        this.props.savePoint({
            variables: {
                data: newPoint
            },
            onCompleted: (saveService) => {
                console.log("Sukses")
                // history.push('/products');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        }).then(data => {
            // console.log(data) 
            history.push('/point/list');
        })

    }
    render() {
        const { pageSize, myClass, multiSelectOption, pagination, savePoint } = this.props;
        const { myData, open, filterProduct, modalType, resultSimulated, simulateVal1, simulateVal2, idxSelectedRule, point, selectedRule } = this.state
        const valueCheck = ['', 'ORDER::USEPOINT', 'ORDER::TOTAL', 'ORDERITEM::QUANTITY', 'ORDERITEM::SUBTOTAL']
        const valueMath = ['', '>', '<', "="]
        const value=[
            {'1':['Ya','Tidak']},
            {'2':[]}
        ]
        const earnedType = ['', 'percentage', 'fixed']
        const multipier = ['', 'PRODUCT::PRICE', 'ORDER::TOTAL', 'ORDERITEM::SUBTOTAL']

        const userGrade = ['', 'Classic', 'Silver', 'Gold', 'Platinum']
        const columns = [];


        // columns.push(
        //     {
        //         Header: <b>Name</b>,
        //         accessor: "name",
        //         //Cell: editable,
        //         style: {
        //             textAlign: 'center',
        //             width: 200
        //         }
        //     });
        columns.push(
            {
                Header: <b>Rule</b>,
                accessor: "rule_entity",
                Cell: (cellInfo) => {
                    return (
                        `${valueCheck[cellInfo.original.rule_code]}${cellInfo.original.rule_math}${cellInfo.original.rule_value}`
                    );
                },
                style: {
                    textAlign: 'center',
                    width: 200
                }
            });
        // columns.push(
        //     {
        //         Header: <b>Point</b>,
        //         accessor: "rule_entity",
        //         Cell: (cellInfo) => {
        //             const earned=cellInfo.original.rule_earnedtype?.toLowerCase()=='percentage'?`${multipier[cellInfo.original.multipier_code]} X ${cellInfo.original.rule_earned} %`:` ${cellInfo.original.rule_earned}`
        //             return (

        //                 `${earned}`
        //             );
        //         },
        //         style: {
        //             textAlign: 'center',
        //             width: 200
        //         }
        //     });



        columns.push(
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                width: 200,
                Cell: (row) => (
                    <div>
                        <span onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this item?')) {
                                let data = myData;
                                data.splice(row.index, 1);
                                this.setState({ myData: data });
                            }
                            toast.success("Successfully Deleted !")

                        }}>
                            <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }}
                            ></i>
                        </span>

                        {/* <span><i className="fa fa-play" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={(e) => this.onOpenModal(1, row.index)}></i></span> */}
                        <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={(e) => this.onOpenModal(3, row.index)}></i></span>

                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )

        return (
            <Fragment>
                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                        <Tab className="nav-link" onClick={(e) => this.clickActive(e)}>General</Tab>
                        <Tab className="nav-link" onClick={(e) => this.clickActive(e)}>Usage</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="tab-pane fade active show">
                            <form className="needs-validation" noValidate="">
                                <h4>General</h4>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group row">

                                            <label className="col-xl-3 col-md-4"><span>*</span> Name</label>
                                            <div className="col-md-7">
                                                <input className="form-control" name="name" id="name-point" type="text" value={point.name} required="" onChange={(e) => {
                                                    const { name, value } = e.target
                                                    let _point = { ...point }
                                                    _point[name] = value
                                                    this.setState({
                                                        point: _point
                                                    })

                                                }} />
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4">Status</label>
                                            <div className="col-md-7">
                                                <div className=" checkbox-space">
                                                    <label className="d-block">
                                                        <input className="checkbox_animated" id="chk-ani2" type="checkbox" checked={point.status == 1 ? true : false} onChange={(e) => {

                                                            let _point = { ...point }
                                                            _point["status"] = e.target.checked ? 1 : 0;
                                                            this.setState({
                                                                point: _point
                                                            })
                                                        }} />
                                                    Enable the rule
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Basic earned</label>
                                            <div className="col-md-7">
                                                <input className="form-control " name="earned_type" id="earned_type-point" type="text" value="100" required="" onChange={(e) => {
                                                    const { name, value } = e.target
                                                    let _point = { ...point }
                                                    _point[name] = value
                                                    this.setState({
                                                        point: _point
                                                    })

                                                }} />
                                                {/* <label className="d-block mb-2" >
                                                    <input className="radio_animated" id="edo-ani3" type="radio" name="rdo-ani1" checked={point.earned_type==1} />
                                            Persentase
                                                    </label>
                                                    <label className="d-block" >
                                        <input className="radio_animated" id="edo-ani3" type="radio" name="rdo-ani1"  checked={point.earned_type==2}  />
                                            Fix
                                                    </label> */}
                                            </div>
                                        </div>
                                        {/* <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Earn multipier</label>
                                            <div className="col-md-7">
                                                <input className="form-control " name="multipier_by" id="multipier_by-point" type="text" value={multipier[point.multipier_by]} required="" onChange={(e) => {
                                                    const { name, value } = e.target
                                                    let _point = { ...point }
                                                    _point[name] = value
                                                    this.setState({
                                                        point: _point
                                                    })

                                                }} />
                                            </div>
                                        </div> */}
                                        <div className="form-group row align-items-start">
                                            <label className="col-xl-3 col-md-4"><span>*</span>Percentage Earned</label>
                                            <div className="col-md-7">
                                                {/* <input className="form-control" name="earned" id="earned-point" type="text" value={point.earned} required="" onChange={(e) => {
                                                    const { name, value } = e.target
                                                    let _point = { ...point }
                                                    _point[name] = value
                                                    this.setState({
                                                        point: _point
                                                    })

                                                }} /> */}
                                                {[1, 2, 3, 4].map(grade =>{
                                                    let currentreward=point.dokul_point_grades.find(rew=>rew.grade_id==grade)
                                                    const rewards=currentreward?currentreward.earned:0
                                                    return <div class="form-group row">
                                                        <label for="staticEmail" className="col-sm-4 col-form-label"><span className={`p-2 bg-${grade == 1 ? 'classic' : grade == 2 ? 'silver' : grade == 3 ? 'gold' : 'platinum'} text-${grade == 1 ? 'light' : grade == 2 ? 'dark' : grade == 3 ? 'dark' : 'dark'} w-100 rounded`}>{userGrade[grade]}</span></label>
                                                        <div className="col-sm-8">
                                                            <input type="number" min="1" className="form-control" value={rewards}  onChange={(e)=>{
                                                                 const { name, value } = e.target
                                                                 let _point = { ...point }
                                                                 let _pointGrades=[..._point.dokul_point_grades]
                                                                 let currentrewardcp={...currentreward}
                                                                 if(currentreward){
                                                                    currentrewardcp.earned=value
                                                                    let idx=_pointGrades.indexOf(currentreward)
                                                                    _pointGrades[idx]=currentrewardcp
                                                                 }else{
                                                                    currentrewardcp={
                                                                        grade_id:grade,
                                                                        earned:value,
                                                                        point_id:point.id

                                                                    }
                                                                    _pointGrades.push(currentrewardcp)

                                                                 }
                                                                 _point.dokul_point_grades=_pointGrades
                                                                 this.setState({
                                                                     point: _point
                                                                 })

                                                            }}/>
                                                        </div>
                                                    </div>
                                                }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form className="needs-validation" noValidate="">
                                <h4>Restriction</h4>
                                <div className="form-group row">
                                    <label className="col-xl-3 col-md-4">Products</label>
                                    <div className="col-xl-8 col-sm-7">
                                        <ListGroup>
                                            {point.dokul_products.map(product => {
                                                return <ListGroupItem className="justify-content-between" ><i className='fa fa-close' style={{ cursor: 'pointer', }}></i><span style={{ verticalAlign: "middle" }}> {product.name} </span></ListGroupItem>

                                                //     <Button close aria-label="Cancel">
                                                //     <span aria-hidden>&ndash; {product.name}</span>
                                                //   </Button>
                                            })}
                                            <ListGroupItem className="justify-content-between" ><button type="button" className="btn btn-primary" onClick={(e) => this.onOpenModal(0)} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Add </button></ListGroupItem>
                                        </ListGroup>

                                    </div>
                                    <div className="valid-feedback">Please Provide a Product Name.</div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xl-3 col-md-4">Category</label>
                                    <div className="col-xl-8 col-sm-7">

                                        {this.props.data.categories.map((kategori, i) =>
                                            // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                            <Input key={i} type="checkbox" id={`ketgories ${i}`} label={kategori.description} checked={point.dokul_tags_categories.some(ct1 => ct1.id == kategori.id)} value={kategori} onClick={(e) => {

                                                let selecttedCategories = [...point.dokul_tags_categories]
                                                let curPoint = { ...point }
                                                if (e.target.checked)
                                                    selecttedCategories.push(kategori)
                                                else {
                                                    const index = selecttedCategories.indexOf(kategori)
                                                    selecttedCategories.splice(index, 1)
                                                }
                                                curPoint.dokul_tags_categories = selecttedCategories
                                                this.setState({
                                                    point: curPoint

                                                })



                                            }} />

                                        )}

                                    </div>
                                </div>
                            </form>
                            <div className="needs-validation" >
                                <h4>Rules</h4>
                                <ReactTable
                                    data={point?.dokul_point_rules}
                                    columns={columns}
                                    defaultPageSize={5}
                                    className={myClass}
                                    showPagination={false}
                                />
                            </div>
                            <ToastContainer />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <form className="needs-validation" noValidate="">
                            <h4>Usage Limits</h4>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4">Per Limit</label>
                                <input className="form-control col-md-7" id="validationCustom6" type="number" />
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4">Per Customer</label>
                                <input className="form-control col-md-7" id="validationCustom7" type="number" />
                            </div>
                        </form>
                    </TabPanel>
                </Tabs>
                <hr />
                <div className="pull-right">
                    <button type="button" className="btn btn-primary" onClick={this._handleSave}>Save</button>
                    <button type="button" className="btn btn-light" onClick={(e) => this.onOpenModal(2, 0)}>Add rule</button>
                </div>
                <Modal open={open} onClose={this.onCloseModal} >
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600" id="exampleModalLabel2">{modalType == 0 ? 'Add product to point' : modalType == 2 ? 'Add rule' : modalType == 3 ? 'Edit rule' : 'Simulate rule'}</h5>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {modalType == 0 ?

                                <div className="col-sm-12 ">

                                    <div className="form-group">

                                        <input className={"form-control-plaintext "} type="search" placeholder="Search.." onChange={(e) => {
                                            this.setState({
                                                filterProduct: e.target.value.toLowerCase()
                                            })

                                        }} />
                                        <span className="d-sm-none mobile-search"><Search /></span>
                                    </div>
                                    {this.props.data.products.filter(p => p.name.toLowerCase().includes(filterProduct)).map((p, i) =>
                                        // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                        <Input key={i} type="checkbox" id={`prd ${i}`} label={p.name} checked={point.dokul_products.some(ct1 => ct1.id == p.id)} value={p} onClick={(e) => {
                                            let selecttedProducts = [...point.dokul_products]
                                            let curPoint = { ...point }
                                            if (e.target.checked)
                                                selecttedProducts.push(p)
                                            else {
                                                const index = selecttedProducts.indexOf(p)
                                                selecttedProducts.splice(index, 1)
                                            }
                                            curPoint.dokul_products = selecttedProducts
                                            this.setState({
                                                point: curPoint

                                            })

                                        }} />

                                    )}

                                </div> :
                                modalType == 1 ?
                                    <div className="col-sm-12 ">

                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="recipient-name" className="col-form-label" >user grade :</label>
                                                <input type="text" className="form-control" value={selectedRule.rule_value} disabled />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="recipient-name" className="col-form-label" >multiper :</label>
                                                <input type="text" className="form-control" value={`${selectedRule.rule_earnedtype} : ${selectedRule.rule_earned}`} disabled />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="recipient-name" className="col-form-label" >product price:</label>
                                                <input type="text" className="form-control" onChange={(e) => {
                                                    const { value } = e.target
                                                    // if(simulateVal1=='silver')
                                                    const x = selectedRule.rule_earned
                                                    const y = selectedRule.rule_earnedtype.includes('percentage') ? 100 : 1
                                                    const z = selectedRule.rule_earnedtype.includes('percentage') ? Math.round(x * value / y) : selectedRule.rule_earned

                                                    //    if(myData[idxSelectedRule].rule_earnedtype.includes('percentage'))
                                                    //         this.setState({simulateVal1:myData[idxSelectedRule].rule_earned,simulateVal2:100})
                                                    //     else
                                                    //         this.setState({simulateVal1:myData[idxSelectedRule].rule_earned,simulateVal2:1})

                                                    this.setState({
                                                        resultSimulated: z

                                                    })



                                                }} />
                                            </div>
                                            {`${resultSimulated} Dokpoin`}
                                        </form>

                                    </div> :
                                    <section>
                                        <form>


                                            <div className="row" style={{ margin: 10 }}>
                                                <div className="col-md-4 ">
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Value check :</label>
                                                        {/* <input type="text" className="form-control" name="rule_math" onChange={this.handleFormChangeNewrule} /> */}
                                                        <select className="custom-select" name="rule_code" required="" onChange={this.handleFormChangeNewrule}>
                                                            {valueCheck.map((vc, index) => <option key={index} value={index} selected={index == selectedRule.rule_code}>{vc}</option>)}
                                                            {/* <option value="">--Select--</option>
                                                            <option value=">">USER::GRADE</option>
                                                            <option value="<">ORDER::TOTAL</option>
                                                            <option value="">ORDERITEM::QUANTITY</option>
                                                            <option value=">">ORDERITEM::SUBTOTAL</option>
                                                            <option value="=">All</option> */}

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Math :</label>
                                                        {/* <input type="text" className="form-control" name="rule_math" onChange={this.handleFormChangeNewrule} /> */}
                                                        <select className="custom-select" name="rule_math" required="" onChange={this.handleFormChangeNewrule}>
                                                            {valueMath.map((vc, index) => <option value={vc} selected={vc == selectedRule.rule_math}>{vc}</option>)}
                                                            {/* <option value="">--Select--</option>
                                                            <option value=">">{'>'}</option>
                                                            <option value="<">{'<'}</option>
                                                            <option value="=">{'='}</option> */}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >value :</label>
                                                        <input type="text" className="form-control" name="rule_value" value={selectedRule.rule_value} onChange={this.handleFormChangeNewrule} />
                                                    </div>
                                                </div>


                                            </div>



                                        </form>
                                    </section>
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => this.addRules()}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button>
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

export default PointTabset
