import React, { Component } from 'react';
import SocketContext from '../../provider/socketctx/context';
// imgaes import
import user from '../../assets/images/dashboard/user.png';
import user1 from '../../assets/images/dashboard/user1.jpg';
import user2 from '../../assets/images/dashboard/user2.jpg';
import user3 from '../../assets/images/dashboard/user3.jpg';
import man from '../../assets/images/dashboard/man.png';
import user5 from '../../assets/images/dashboard/user5.jpg';
import designer from '../../assets/images/dashboard/designer.jpg'
import {Link} from 'react-router-dom'

export class Right_sidebar extends Component {
    static contextType = SocketContext
    componentDidMount() {
        const user = this.context
      }
    render() {
        return (
            <div>
                <div className="right-sidebar" id="right_side_bar">
                    <div>
                        <div className="container p-0">
                            <div className="modal-header p-l-20 p-r-20">
                                <div className="col-sm-8 p-0">
                                    <h6 className="modal-title font-weight-bold">Chat list</h6>
                                </div>
                                <div className="col-sm-4 text-right p-0"><i className="mr-2" data-feather="settings"></i></div>
                            </div>
                        </div>
                        {/* <div className="friend-list-search mt-0">
                            <input type="text" placeholder="search friend" /><i className="fa fa-search" ></i>
                        </div> */}
                        <div className="p-l-30 p-r-30">
                            <div className="chat-box">
                                <div className="people-list friend-list">
                                    <ul className="list">
                                       
                                            {this.context.message.map((msg,index)=>
                                             <li className="clearfix">
                                        <Link to={`${process.env.PUBLIC_URL}/chat/user/${index}`} >
                                            <img className="rounded-circle user-image" src={user} alt="" />
                                            <div className="status-circle online"></div>
                                            <div className="about">
                                                <div className="name">{msg.userinfo.display_name?msg.userinfo.display_name:msg.message[0].from!=undefined?`WA-${msg.id.split("@")[0]}`:"GUEST"}</div>
                                                <div className="status"> {msg.message[0].body}</div>
                                            </div>
</Link>
                                        </li>
                                        )}
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Right_sidebar
