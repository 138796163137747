import React from 'react'
import { Label } from 'reactstrap'

export default function FilterReguler(props) {
    return (
        <div className="d-flex flex-column border p-2">

            <div className="d-flex flex-row ">
                <h6 className="title  m-2">Keperluan</h6>
                <div className="form-check p-2 ms-auto">
                    <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 3} onChange={() => props.setkeperluan(3)} />
                    <label className="form-check-label" htmlFor="byproduk">Semua</label>
                </div>
                <div className="form-check m-2">
                    <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 4} onChange={() => props.setkeperluan(4)} />
                    <label className="form-check-label" htmlFor="byproduk">Perdana</label>
                </div>
                <div className="form-check m-2">
                    <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 1} onChange={() => props.setkeperluan(1)} />
                    <label className="form-check-label" htmlFor="byproduk">Darurat</label>
                </div>
                <div className="form-check m-2">
                    <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 2} onChange={() => props.setkeperluan(2)} />
                    <label className="form-check-label" htmlFor="byproduk">Kontrol rutin </label>
                </div>

            </div>


            <hr />

            {/* <div className="d-flex flex-row ">
                                    <h6 className="title  m-2">Dokterkulitku</h6>
                                    <div className="form-check p-2 ms-auto">
                                        <input className="form-check-input" type="radio" name="gt" id="gt" checked={branchId == 1} onChange={() => setbranchId(1)} />
                                        <label className="form-check-label" htmlFor="gt">Jakarta</label>
                                    </div>
                                    <div className="form-check m-2">
                                        <input className="form-check-input" type="radio" name="gt" id="gt" checked={branchId == 2} onChange={() => setbranchId(2)} />
                                        <label className="form-check-label" htmlFor="gt">Balikpapan</label>
                                    </div>

                                </div>
                                <hr /> */}

            <div className="d-flex flex-row ">
                <h6 className="title  m-2">Status</h6>
                <div className="d-flex flex-column ms-auto">

                    <div className="form-check p-2 ">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 0} onChange={() => props.setstatusSelected(0)} />
                        <label className="form-check-label" htmlFor="status">Semua</label>
                    </div>
                    <div className="form-check p-2 ">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 1} onChange={() => props.setstatusSelected(1)} />
                        <label className="form-check-label" htmlFor="status">Menunggu pembayaran</label>
                    </div>
                    <div className="form-check p-2">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 2} onChange={() => props.setstatusSelected(2)} />
                        <label className="form-check-label" htmlFor="status">Upload struk</label>
                    </div>


                </div>
                <div className="d-flex flex-column ">



                    <div className="form-check p-2 ms-auto">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 3} onChange={() => props.setstatusSelected(3)} />
                        <label className="form-check-label" htmlFor="status">Belum dijawab 1</label>
                    </div>
                    <div className="form-check m-2">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 4} onChange={() => props.setstatusSelected(4)} />
                        <label className="form-check-label" htmlFor="status">Sudah dijawab 1</label>
                    </div>


                </div>
                <div className="d-flex flex-column ">




                    <div className="form-check p-2 ms-auto">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 5} onChange={() => props.setstatusSelected(5)} />
                        <label className="form-check-label" htmlFor="status">Belum dijawab 2</label>
                    </div>
                    <div className="form-check m-2">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 6} onChange={() => props.setstatusSelected(6)} />
                        <label className="form-check-label" htmlFor="status">Sudah dijawab 2</label>
                    </div>
                    <div className="form-check p-2 ms-auto">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 7} onChange={() => props.setstatusSelected(7)} />
                        <label className="form-check-label" htmlFor="status">Belum dijawab 1 & 2</label>
                    </div>

                </div>

            </div>
            <hr />
            <Label>Dokter :</Label>
            <div className="d-flex flex-row ">
                {props.dataDokter && props.dataDokter.users.users.filter(x=>x.role_id==12).map((dr, idxdr) => {
                    return <div key={idxdr} className="form-check p-2 m-2 ">
                        <input className="form-check-input" type="checkbox" name="status" value={dr.dokul_user.id} id="status" onChange={props.changeDokter} />
                        <label className="form-check-label" htmlFor="status">{dr.dokul_user.first_name} {dr.dokul_user.last_name}</label>
                    </div>
                })}



            </div>
            {/* <hr /> */}
            {/* <div className="d-flex flex-row justify-content-between"> */}

            {/* <h6 className="title m-2">Tanggal</h6>

                                    <DatePicker
                                        selected={startDate}
                                        className="ms-auto"
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd MMMM yyyy"
                                    />
                                    <Label className="p-2 align-self-center">--s/d--</Label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setendDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        filterDate={(date) => {
                                            const dif = moment(startDate).diff(moment(date), 'months');
                                            return dif == 0
                                        }}
                                        dateFormat="dd MMMM yyyy"
                                    /> */}


            {/* <div className="d-flex flex-column">
                            {true && <div className="input-group p-2  d-flex justify-content-end">
                                <span className="input-group-text" id="basic-addon1">Start</span>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd MMMM yyyy"
                                />
                                


                            </div>}
                            {true && <div className="input-group p-2  d-flex justify-content-end">
                               
                                <span className="input-group-text" id="basic-addon1">End</span>
                                <DatePicker
                                selected={endDate}
                                onChange={(date) => setendDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                filterDate={(date) => {
                                    const dif = moment(startDate).diff(moment(date), 'months');
                                    return dif == 0
                                }}
                                dateFormat="dd MMMM yyyy"
                            />

                            </div>}
                            </div> */}

            {/* </div> */}
        </div>
    )
}
