import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery, writeQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject, NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../../apollo'
import { GET_CATEGORIES, GET_TAGS, GET_DASHBOARD_DATA, GET_ORDERS, GET_7LAST_ORDER, GET_ORDER_STATUS, GET_ORDER_STATUSOK, GET_PRODUCTS, GET_ORDERS_NOPAGE, GET_CONFIGS } from '../../../constants/graph'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';
import { Pie, Doughnut, Bar, Line } from 'react-chartjs-2';
import {
    pieOptions,
    doughnutOption,
    lineOptions,
    buyOption,
    employeeData,
    employeeOptions
} from '../../../constants/chartData'
import Loading from '../../common/loading'
import Errorpage from '../../common/Errorpage'
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import id from 'date-fns/locale/id';
import moment from 'moment';
import { Badge, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { ExportToExcel } from '../order/exporttoexcel';
registerLocale('id', id)
setDefaultLocale('id');
const orderStatusArray = [
    // { id: 1, status: 1, text: "Order Diterima" },
    // { id: 1, status: 1, text: "Menunggu pembayaran" },
    // { id: 99, status: 12, text: "Verifikasi struk pembayaran" },
    // { id: 2, status: 2, text: "Pembayaran terverifikasi" },
    // { id: 3, status: 31, text: "Packing untuk expedisi" },
    // { id: 3, status: 32, text: "Packing untuk driver" },
    // { id: 99, status: 311, text: "Sedang rekap" },
    // { id: 2, status: 321, text: "Packing untuk driver sudah print" },
    // { id: 2, status: 33, text: "Dalam pengiriman" },
    // { id: 2, status: 21, text: "Menunggu stock beberapa item" },
    // { id: 99, status: 22, text: "Menunggu stock " },
    // { id: 4, status: 41, text: "Batal beberapa item" },
    { id: 4, status: 42, text: "Batal oleh admin" },
    { id: 99, status: 43, text: "Batal oleh system" },
    { id: 2, status: 5, text: "Sudah dikirim" },
    // { id: 2, status: 51, text: "Selesai sudah review" }

]
// ChartJS.register(
//     LinearScale,
//     CategoryScale,
//     BarElement,
//     PointElement,
//     LineElement,
//     Legend,
//     Tooltip
//   );

function triggerTooltip(chart) {
    const tooltip = chart?.tooltip;
    //console.log(chart)
    if (chart != null) {
        chart.update()
    }
    return;
    if (!tooltip) {
        return;
    }

    if (tooltip.getActiveElements().length > 0) {
        tooltip.setActiveElements([], { x: 0, y: 0 });
    } else {
        const { chartArea } = chart;

        tooltip.setActiveElements(
            [
                {
                    datasetIndex: 0,
                    index: 2,
                },
                {
                    datasetIndex: 1,
                    index: 2,
                },
            ],
            {
                x: (chartArea.left + chartArea.right) / 2,
                y: (chartArea.top + chartArea.bottom) / 2,
            }
        );
    }

    chart.update();
}

// import "react-datepicker/dist/react-datepicker.css";
const Statisticorder = (props) => {
    //const [data,setData]=useState(props.data)
    // const { loading:lProduct, data:dProduct, error:eProduct } = useQuery(GET_PRODUCTS);
    const [getData, { loading, data, error, fetchMore, refetch, networkStatus }] = useLazyQuery(GET_ORDER_STATUSOK, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: { option: "branch=2" }
    });
    const [getOrderData, { loading: loadingDetail, data: dataDetail, error: errorDetail }] = useLazyQuery(GET_ORDERS_NOPAGE);
    const { loading: lConf, data: dConf, error: eConf } = useQuery(GET_CONFIGS,);
    const chartRef = useRef(null);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date())
    const [graphType, setgraphType] = useState(2)
    const [branchSelected, setbranchSelected] = useState(0)
    const [selectedOrderStatus, setselectedOrderStatus] = useState([])
    const [selectedRange, setselectedRange] = useState(2)

    const [orderstatus, setorderstatus] = useState({ label: [] })
    const [selectedProduct, setselectedProduct] = useState()
    const [modalProduk, setmodalProduk] = useState(false)
    useEffect(() => {

        if (data) {
            console.log("data update")
            // setorderstatus(data.orderstatus)
            if (selectedProduct) updateGraphByProduk()
            else updateGraph()

            // chartRef.current.update()

        }
    }, [data])
    const hitunSemua = (key) => {
        if (key == "pointearned") {

            const tBpn = data?.orderstatus?.bpn?.asli?.filter(er => (parseInt(er.pointused) == 0 && parseInt(er.coupondiscount) == 0)).reduce((x, y) => x + parseInt(y[key]), 0);
            const tJkt = data?.orderstatus?.jkt?.asli?.filter(er => (parseInt(er.pointused) == 0 && parseInt(er.coupondiscount) == 0)).reduce((x, y) => x + parseInt(y[key]), 0);
            const result = tBpn + tJkt
            return isNaN(result) ? 0 : result
        } else {
            const tBpn = data?.orderstatus?.bpn?.asli?.reduce((x, y) => x + parseInt(y[key]), 0);
            const tJkt = data?.orderstatus?.jkt?.asli?.reduce((x, y) => x + parseInt(y[key]), 0);
            const result = tBpn + tJkt
            return isNaN(result) ? 0 : result
        }

    }
    const groupingPublicVoucher = (dataVoucher) => {
        if (!dataVoucher) return null
        let result = dataVoucher.reduce((res, val) => {
            if (res[val.voucher_name]) {
                res[val.voucher_name] = res[val.voucher_name] + val.amount

            } else {
                res[val.voucher_name] = val.amount
            }
            return res;

        }, {})
        return Object.keys(result).map(key => {
            const value = result[key];
            console.log(`Key: ${key}, Value: ${value}`);
            return  <div className="d-flex flex-row  border-start border-end "><h6 className="title  px-4">{key}</h6><h6 className="title  m-2 ms-auto fw-bold">{`${value.toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}`}</h6></div>
        })



    }
    useEffect(() => {
        if (selectedProduct) updateGraphByProduk()
        else updateGraph()
    }, [props.selectedBranch])
    const generateThumbLegend = () => {
        console.log("AMBIL")
        if (chartRef.current == null || !data) return "gagal"
        let chart = chartRef.current.chartInstance;
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        return items.map(x => <Label>sdsds</Label>)

    }

    const updateGraphByProduk = () => {
        if (chartRef.current == null || !data) return
        let chart = chartRef.current.chartInstance;
        chart.options = {
            animation: false,
            legend: { display: true },
            maintainAspectRatio: false,
            responsive: true,
            responsiveAnimationDuration: 0,
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        if (parseInt(tooltipItem.value) >= 1000) {
                            return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return tooltipItem.value;
                        }
                        // return tooltipItem?.value.toLocaleString("id-ID",{ style: 'currency', currency: 'IDR',maximumFractionDigits:0 });
                    }
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            if (parseInt(value) >= 1000) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        }
                    }
                }]
            }
        }
        let datasets = []
        let labels = []
        selectedProduct.forEach((x, y) => {
            if (data.orderstatus.jkt.label && (props.selectedBranch == 1 || props.selectedBranch == 3)) {
                labels = data.orderstatus.jkt.label.map((lbl, idx) => lbl.tanggal)


                datasets.push({
                    lagend: 'none',
                    label: `${x.name}`,
                    data: data.orderstatus.jkt.label.map((lbl, idx) => {
                        const _product = lbl.product.find(pr => pr.product_id == x.id)
                        if (_product)
                            return graphType == 1 ? _product.totalqty : _product.totalamt
                        else return 0
                    }),
                    borderColor: randomCssRgba(),
                    backgroundColor: 'rgba(255, 188, 88, 0.0)',

                })
            }
        })

        chart.data = {
            labels: labels,
            datasets: datasets
        }
        chart.update();




    }
    const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
    const randomByte = () => randomNumber(0, 255)
    const randomPercent = () => (randomNumber(50, 100) * 0.01).toFixed(2)
    const randomCssRgba = () => `rgba(${[randomByte(), randomByte(), randomByte(), randomPercent()].join(',')})`
    const updateGraph = () => {
        if (chartRef.current == null || !data) return
        let chart = chartRef.current.chartInstance;
        chart.options = {
            animation: false,
            legend: { display: false },
            maintainAspectRatio: false,
            responsive: true,
            responsiveAnimationDuration: 0,
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        if (parseInt(tooltipItem.value) >= 1000) {
                            return tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return tooltipItem.value;
                        }
                        // return tooltipItem?.value.toLocaleString("id-ID",{ style: 'currency', currency: 'IDR',maximumFractionDigits:0 });
                    }
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            if (parseInt(value) >= 1000) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        }
                    }
                }]
            }
        }
        let datasets = []
        let labels = []
        if (data.orderstatus.jkt.label && (props.selectedBranch == 1 || props.selectedBranch == 3)) {
            labels = data.orderstatus.jkt.label.map(lbl => lbl.tanggal)
            datasets.push({
                lagend: 'none',
                label: 'JKT::',
                data: data.orderstatus.jkt.label.map(lbl => graphType == 1 ? lbl.jmlcount : lbl.jumlah),
                borderColor: '#ffbc58',
                backgroundColor: 'rgba(255, 188, 88, 0.1)',

            })
        }
        if (data.orderstatus.bpn.label && (props.selectedBranch == 2 || props.selectedBranch == 3)) {
            labels = data.orderstatus.bpn.label.map(lbl => lbl.tanggal)
            datasets.push({
                label: 'BPN:',
                data: data.orderstatus.bpn.label.map(lbl => graphType == 1 ? lbl.jmlcount : lbl.jumlah),
                borderColor: '#81ba00',
                backgroundColor: 'rgba(129, 186, 0, 0.1)',
            })
        }
        chart.data = {
            labels: labels,
            datasets: datasets
        }
        chart.update();

    }
    const updateGraph2 = () => {
        if (chartRef.current == null) return
        let chart = chartRef.current.chartInstance;
        chart.options = {
            animation: false,
            legend: { display: false },
            maintainAspectRatio: false,
            responsive: true,
            responsiveAnimationDuration: 0,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            if (parseInt(value) >= 1000) {
                                return 'IDR' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return 'IDR' + value;
                            }
                        }
                    }
                }]
            }
        }
        let datasets = []
        let labels = []
        if (data.orderstatus.jkt.label) {
            labels = data.orderstatus.jkt.label.map(lbl => lbl.tanggal)
            datasets.push({
                lagend: 'none',
                label: 'JKT::',
                data: data.orderstatus.jkt.label.map(lbl => lbl.jumlah),
                borderColor: '#ffbc58',
                backgroundColor: 'rgba(255, 188, 88, 0.1)',

            })
        }
        if (data.orderstatus.bpn.label) {
            labels = data.orderstatus.bpn.label.map(lbl => lbl.tanggal)
            datasets.push({
                label: 'BPN:',
                data: data.orderstatus.bpn.label.map(lbl => lbl.jumlah),
                borderColor: '#81ba00',
                backgroundColor: 'rgba(129, 186, 0, 0.1)',
            })
        }
        chart.data = {
            labels: labels,
            datasets: datasets
        }
        chart.update();

    }
    const generateProductParam = () => {
        if (!selectedProduct) return undefined
        return selectedProduct.reduce((prev, cur) => {
            return prev + "&pid=" + cur.id

        }, "")
    }
    const getDataOrder = () => {
        let branch = props.selectedBranch == 1 ? 'branch=1' : props.selectedBranch == 2 ? 'branchbpn=2' : props.selectedBranch == 3 ? 'branchbpn=2&branch=1' : ''
        if (selectedRange == 1)
            getData({
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                variables: { option: `${branch}&startdate=${moment(startDate).startOf('month').format("YYYY-MM-DD")}&enddate=${moment(endDate).endOf("month").format("YYYY-MM-DD HH:mm:ss")}&graphType=${graphType}&dataType=${selectedRange}&orderStatus=${selectedOrderStatus.toString()}${generateProductParam()}` }
            })
        else
            getData({
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                variables: { option: `${branch}&startdate=${moment(startDate).format("YYYY-MM-DD")}&enddate=${moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")}&graphType=${graphType}&dataType=${selectedRange}&orderStatus=${selectedOrderStatus.toString()}${generateProductParam()}` }
            })

    }
    const getDetailReport = () => {
        let branch = props.selectedBranch == 1 ? 'branch=1' : props.selectedBranch == 2 ? 'branchbpn=2' : props.selectedBranch == 3 ? 'branchbpn=2&branch=1' : ''
        if (selectedRange == 1)
            getOrderData({
                variables: {
                    dateFrom: moment(startDate).startOf('month').format("YYYY-MM-DD"),
                    dateTo: moment(endDate).endOf("month").format("YYYY-MM-DD HH:mm:ss"),
                    statusText: 5,
                    branchid: props.selectedBranch

                }
            })
        else
            getOrderData({
                variables: {
                    dateFrom: moment(startDate).format("YYYY-MM-DD"),
                    dateTo: moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                    statusText: 5,
                    branchid: props.selectedBranch

                }
            })

    }
    if (loading) return <Loading />;
    if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    return (
        <div className="col-xl-12 xl-100 text-dark">
            {/* <Modal isOpen={modalProduk} toggle={() => setmodalProduk(false)} centered size="lg">
                <ModalHeader className="bg-primary" toggle={() => setmodalProduk(false)}>Add product statistic</ModalHeader>
                <ModalBody className="bg-light text-dark">
                    {dProduct&&dProduct.products.map((x,idx )=> <FormGroup key={idx} check>
                        <Label check>
                            <Input type="checkbox" onClick={(e)=>{
                                let _currentSelectedProduct=[...selectedProduct]
                                if(e.target.checked){
                                    _currentSelectedProduct.push(x)
                                }
                                setselectedProduct(_currentSelectedProduct)
                            }} />{' '}
                            {x.name}
                        </Label>
                    </FormGroup>)}
                </ModalBody>

            </Modal> */}
            <div className="row">
                <div className="col-7">
                    {/* <div className="d-flex flex-row border">
                        <h6 className="title m-2">Dokterkulitku</h6>
                        <div className="form-check p-2 ms-auto">
                            <input className="form-check-input" type="checkbox" name="branch" id="branch" checked={branchSelected == 1} onClick={() => setbranchSelected(1)} />
                            <label className="form-check-label" for="branch">Jakarta</label>
                        </div>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="checkbox" name="branch" id="branch" checked={branchSelected == 2} onClick={() => setbranchSelected(1)} />
                            <label className="form-check-label" for="branch">Balikpapan</label>
                        </div>

                    </div> */}
                    <div className="d-flex flex-column border">
                        <h6 className="title  m-2">Filter</h6>
                        <div className="d-flex flex-row border">
                            <h6 className="title  m-2">Product</h6>
                            <div className="form-check p-2 ms-auto">
                                <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={!selectedProduct} onClick={() => setselectedProduct()} />
                                <label className="form-check-label" for="byproduk">All</label>
                            </div>
                            <div className="form-check m-2">
                                <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={selectedProduct} onClick={() => setselectedProduct([])} />
                                <label className="form-check-label" for="byproduk">Selected</label>
                            </div>

                        </div>
                        {selectedProduct && <>
                            <div className="d-flex flex-row flex-wrap">
                                {selectedProduct.map((x, idx) => <Badge key={idx} className="m-2" >{x.name}</Badge>)}

                            </div>
                            <div className="d-flex flex-row p-2">
                                <button className="btn btn-primary btn-xs ms-auto " onClick={() => setmodalProduk(true)}>Add</button>
                            </div>
                        </>}

                    </div>

                    <div className="d-flex flex-row border">
                        <h6 className="title  m-2">Graph type</h6>
                        <div className="form-check p-2 ms-auto">
                            <input className="form-check-input" type="radio" name="gt" id="gt" checked={graphType == 1} onClick={() => setgraphType(1)} />
                            <label className="form-check-label" for="gt">Counter</label>
                        </div>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="radio" name="gt" id="gt" checked={graphType == 2} onClick={() => setgraphType(2)} />
                            <label className="form-check-label" for="gt">Amount</label>
                        </div>

                    </div>
                    <div className="d-flex flex-row border-end border-start">

                        <h6 className="title m-2">Data by</h6>
                        <div className="form-check p-2 ms-auto">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectedRange == 2} onClick={() => setselectedRange(2)} />
                            <label className="form-check-label" for="flexRadioDefault1">By Date</label>
                        </div>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectedRange == 1} onClick={() => setselectedRange(1)} />
                            <label className="form-check-label" for="flexRadioDefault1">By Month</label>
                        </div>

                    </div>

                    {selectedRange == 1 && <div class="input-group pb-2 d-flex justify-content-end border-end border-start border-bottom">
                        <span class="input-group-text" id="basic-addon1">Start</span>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                        />
                        <span class="input-group-text" id="basic-addon1">End</span>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setendDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                        />
                    </div>}
                    {selectedRange == 2 && <div class="input-group p-2  d-flex justify-content-end border-end border-start border-bottom">
                        <span class="input-group-text" id="basic-addon1">Start</span>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd MMMM yyyy"
                        />
                        <span class="input-group-text" id="basic-addon1">End</span>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setendDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            filterDate={(date) => {
                                const dif = moment(startDate).diff(moment(date), 'months');
                                return dif == 0
                            }}
                            dateFormat="dd MMMM yyyy"
                        />
                    </div>}
                    <div class=" p-0 mb-3 d-flex justify-content-end border-end border-start border-bottom">
                        <button className='btn btn-xs btn-primary m-2' onClick={() => getDataOrder()}>Submit</button>
                        {/* <button disabled={data == undefined} className='btn btn-xs btn-primary m-2' onClick={() => getDetailReport()}>download detail</button>
                        {dataDetail && <ExportToExcel configData={dConf.configs.find(br => br.id==2)} tipe={4} apiData={dataDetail.orders || []} fileName={"DETAIL_ORDER"} />} */}
                    </div>

                </div>
                <div className="col-5 m-0">
                    <div className="d-flex flex-row border-top border-start border-end m-0">
                        <h6 className="title  m-2">Total order</h6>
                        {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.asli?.reduce((x, y) => x + y.jumlahtransaksi, 0)}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.asli?.reduce((x, y) => x + parseInt(y.jumlahtransaksi), 0)}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("jumlahtransaksi")}</h6>}
                    </div>
                    <div className="d-flex flex-row border-top border-start border-end m-0">
                        <h6 className="title  m-2">Total amount</h6>
                        {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.asli?.reduce((x, y) => x + y.total, 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.asli?.reduce((x, y) => x + parseInt(y.total), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("total").toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                    </div>
                    <div className="d-flex flex-row border-top border-start border-end m-0">
                        <h6 className="title  m-2">Total DokPoin <small className="text-success" style={{ fontSize: 10 }}>Redeem</small></h6>
                        {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.asli?.reduce((x, y) => x + parseInt(y.pointused), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.asli?.reduce((x, y) => x + parseInt(y.pointused), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("pointused").toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                    </div>
                    {/* <div className="d-flex flex-row border-top border-start border-end m-0">
                        <h6 className="title  m-2">Total DokPoin <small className="text-danger" style={{fontSize:10}}>Earned</small></h6>
                        {props.selectedBranch == 1&& <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.asli?.filter(er=>(parseInt(er.pointused)==0 && parseInt(er.coupondiscount)==0)).reduce((x,y)=>x+parseInt(y.pointearned),0).toLocaleString("id-ID",{ style: 'currency', currency: 'IDR',maximumFractionDigits:0 })}</h6>}
                        {props.selectedBranch == 2&&<h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.asli?.filter(er=>(parseInt(er.pointused)==0 && parseInt(er.coupondiscount)==0)).reduce((x,y)=>x+parseInt(y.pointearned),0).toLocaleString("id-ID",{ style: 'currency', currency: 'IDR',maximumFractionDigits:0 })}</h6>}
                        {props.selectedBranch == 3&&<h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("pointearned").toLocaleString("id-ID",{ style: 'currency', currency: 'IDR',maximumFractionDigits:0 })}</h6>}
                    </div> */}
                    <div className="d-flex flex-row border-top border-start border-end m-0">
                        <h6 className="title  m-2">Total discount</h6>
                        {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.asli?.reduce((x, y) => x + parseInt(y.coupondiscount), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.asli?.reduce((x, y) => x + parseInt(y.coupondiscount), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("coupondiscount").toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}

                    </div>
                    <div className="d-flex flex-row  border-start border-end">
                        <h6 className="title  px-4">Private Voucher</h6>
                        {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.dataVourcher?.filter(x => x.is_private == 1).reduce((x, y) => x + parseInt(y.amount), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.dataVourcher?.filter(x => x.is_private == 1).reduce((x, y) => x + parseInt(y.amount), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("totalDiscountPrivate  ").toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}

                    </div>
                    {/* <div className="d-flex flex-row  border-start border-end "> */}
                        {/* <h6 className="title  px-4">Public Voucher</h6> */}
                        {props.selectedBranch == 1 && groupingPublicVoucher(data?.orderstatus?.jkt?.dataVourcher?.filter(x => x.is_private == 0))}
                        {props.selectedBranch == 2 && groupingPublicVoucher(data?.orderstatus?.bpn?.dataVourcher?.filter(x => x.is_private == 0))}
                        {/* {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.dataVourcher?.filter(x => x.is_private == 0).reduce((x, y) => x + parseInt(y.amount), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.dataVourcher?.filter(x => x.is_private == 0).reduce((x, y) => x + parseInt(y.amount), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("totalDiscountPublic").toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>} */}

                    {/* </div> */}

                    <div className="d-flex flex-row border-top border-start border-end m-0">
                        <h6 className="title  m-2">Total ongkir</h6>
                        {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.asli?.reduce((x, y) => x + parseInt(y.totalongkir), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.asli?.reduce((x, y) => x + parseInt(y.totalongkir), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("totalongkir").toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                    </div>
                    <div className="d-flex flex-row border-top border-start border-end m-0">
                        <h6 className="title  m-2">Total unik</h6>
                        {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.asli?.reduce((x, y) => x + parseInt(y.unixcode), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.asli?.reduce((x, y) => x + parseInt(y.unixcode), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("unixcode").toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                    </div>
                    <div className="d-flex flex-row border m-0">
                        <h6 className="title  m-2">Grandtotal</h6>
                        {props.selectedBranch == 1 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.jkt?.asli?.reduce((x, y) => x + parseInt(y.grandtotal), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 2 && <h6 className="title  m-2 ms-auto fw-bold">{data?.orderstatus?.bpn?.asli?.reduce((x, y) => x + parseInt(y.grandtotal), 0).toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                        {props.selectedBranch == 3 && <h6 className="title  m-2 ms-auto fw-bold">{hitunSemua("grandtotal").toLocaleString("id-ID", { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })}</h6>}
                    </div>

                </div>


            </div>

            {/* <div className="d-flex flex-column m-2 mb-5">
                <h4 className="title border-bottom p-2">Option</h4>
                <div className="d-flex flex-row border">
                    <div className="d-flex flex-column m-2 ">
                        <h6 className="title border-bottom p-2">Dokterkulitku</h6>
                        <button className='btn btn-xs btn-primary m-2' onClick={() => getData({
                            fetchPolicy: 'network-only',
                            notifyOnNetworkStatusChange: true,
                            variables: { option: `branch=1&startdate=${moment(startDate).format("YYYY-MM-DD")}&enddate=${moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")}` }
                        })}>Jakarta</button>

                        <button className='btn btn-xs btn-primary m-2' onClick={() => getData({
                            fetchPolicy: 'network-only',
                            notifyOnNetworkStatusChange: true,
                            variables: { option: `branchbpn=2&startdate=${moment(startDate).format("YYYY-MM-DD")}&enddate=${moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")}` }
                        })}>Balikpapan</button>

                        <button className='btn btn-xs btn-primary m-2' onClick={() => getData({
                            fetchPolicy: 'network-only',
                            notifyOnNetworkStatusChange: true,
                            variables: { option: `branchbpn=2&branch=1&startdate=${moment(startDate).format("YYYY-MM-DD")}&enddate=${moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")}` }
                        })}>ALL</button>



                    </div>
                   
                    <div className="d-flex flex-column m-2">
                        <h6 className="title border-bottom p-2">Order status</h6>
                        {orderStatusArray.map((x, idx) => <div key={idx} className="form-check">
                            <input className="form-check-input" type="checkbox" name="ot" id="ot" value={x.status} defaultChecked={selectedOrderStatus.some(xy => xy == x.status)} onClick={(e) => {
                                let curStat = [...selectedOrderStatus]
                                if (e.target.checked) {
                                    setselectedOrderStatus([...curStat, e.target.value])
                                } else {
                                    const idxx = curStat.indexOf(yy => yy == e.target.value)
                                    curStat = curStat.splice(idxx, 1)
                                    setselectedOrderStatus(curStat)

                                }
                            }} />
                            <label className="form-check-label" for="ot">{x.text}</label>
                        </div>)}
                    </div>
                    <div className="d-flex flex-column m-2">
                        <h6 className="title border-bottom p-2">Graph type</h6>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="gt" id="gt" checked={graphType == 1} onClick={() => setgraphType(1)} />
                            <label className="form-check-label" for="gt">Counter</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="gt" id="gt" checked={graphType == 2} onClick={() => setgraphType(2)} />
                            <label className="form-check-label" for="gt">Amount</label>
                        </div>
                    </div>
                    <div className="d-flex flex-column m-2">
                        <h6 className="title border-bottom p-2">Graph type</h6>
                        <button className='btn btn-xs btn-primary m-2' onClick={() => getDataOrder()}>Submit</button>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="gt" id="gt" checked={graphType == 2} onClick={() => setgraphType(2)} />
                            <label className="form-check-label" for="gt">Amount</label>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="order-graph xl-space">
                {/* {generateThumbLegend(chartRef)} */}

                {orderstatus && <div className="ct-4 flot-chart-container">
                    <Line ref={chartRef} data={{
                        labels: orderstatus.label.map(lbl => lbl.hari),
                        datasets: [
                            {
                                legend: {
                                    position: 'top',
                                },
                                data: orderstatus.label.map(lbl => lbl.jumlah),
                                borderColor: '#ff8084',
                                backgroundColor: 'rgba(255, 128, 132, 0.1)',
                                fill: 'origin',
                            }
                        ]
                    }} options={employeeOptions} />
                </div>}
            </div>
        </div >

    )
}
export default withApollo(Statisticorder)
//export default ordertoday
