import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { Collapse, Button, CardBody, Card, Input } from 'reactstrap';
import { Loader } from 'react-feather';


export class TableView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: this.props.totalPages,
            loading: false,
            checkedValues: [],
            myData: this.props.listData,
            isOpen: false,
            monthSelected: 0,
            dateSelected: 0,
            allPM:'',
            dState:[],
            selectedDate: Date()
        }
        this.fetchData = this.fetchData.bind(this);
    }
    async fetchData(state, instance) {
        // this.setState({dState:state},()=>console.log(""))
        // let id;
        // let date;
        // let balik;
        // let provider;
        // let nominal;

        // state.filtered.forEach(element => {
        //     console.log(element)
        //     if (element.id == 'id') id = element.value
        //     if (element.id == 'type') provider = element.value
        //     if (element.id == 'nominal') nominal = element.value
        //     if (element.id == 'tanggal') {
        //         var d = moment(element.value)
        //         var od = moment(this.state.selectedDate)
        //         if (d.date() == od.date())
        //             balik = true;
        //         date = element.value

        //     }

        // })
        // if (balik) return
        // _dataId=this.state.allPM.
        
        this.props.fetchData(state)
        // const t = await this.props.getNextData(state.pageSize, state.page, this.state.allPM.replace(",",""), date, provider,nominal)
        // console.log(t)
        // this.setState({
        //     myData: t.data.transactions.transactions,
        //     pages: t.data.transactions.totalPages,
        //     selectedDate: date
        // })

        // console.log(t)
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        //this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        //   requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
        //     res => {
        //       // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        //       this.setState({
        //         // data: res.rows,
        //         // pages: res.pages,
        //         loading: false
        //       });
        //     }
        //   );
    }
    getPayName=(id)=>{


    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };
    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    openFilter = (i) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
     CustomTableFilter = ({filter, onChange}) => {
        return (
          <Input type="search" value={filter ? filter.value : ''}
            onChange={event => (event ? onChange(event.currentTarget.value) : "")}/>
        );
      };
    render() {
        const { pageSize, myClass, multiSelectOption, pagination, dConfig,listData,page,totalPages,fetchData } = this.props;
        const { myData, pages, loading, isOpen, selectedDate,allPM } = this.state
        const columns = [

            {
                Header: <b>Tangggal</b>,
                accessor: "created_date",
                width: 200,

                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.created_date).format("DD MMMM YYYY HH:mm")}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Nominal</b>,
                accessor: "grand_total",
                width: 200,
                filterable: true,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.grand_total}
                    </div>

                </div>,
                Filter: this.CustomTableFilter,
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Masalah</b>,
                accessor: "masalah",
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.masalah}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Pasien</b>,
                accessor: "first_name",
                filterable: true,
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.dokul_user.first_name} {cellInfo.original.dokul_user.last_name}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),

                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Email</b>,
                accessor: "email",
                filterable: true,
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.dokul_user.email}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Status</b>,
                accessor: "status",
                width: 200,
                Cell: (cellInfo) => {
                    return <div className="d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight ">{cellInfo.original.status == 1 ? "Menunggu Pembayaran" : cellInfo.original.status == 2 ? "Pembayaran terverifikasi" : "Sudah dijawab"}</div>
                        {/* <div className="p-2 bd-highlight">{cellInfo.original.is_approved == 1 ? "Approved" : "Rejected"}</div> */}

                    </div>
                },
                style: {
                    textAlign: 'left',
                }
            },
            {
                Header: <b>Pembayaran</b>,
                accessor: "created_date",
                // filterable: true,
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.dokul_paymethod.name}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                width: 100,
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        {/* <span onClick={async () => {
                            const _dataQa = await getQ_A(row.original.id)
                            console.log(_dataQa)
                            let _selectedKontrol = { ...row.original }
                            _selectedKontrol["qa"] = _dataQa ? _dataQa.qa : []
                            _selectedKontrol["result"] = _dataQa ? _dataQa.result : {}
                            if (_dataQa.result) {
                                setContent(_dataQa.result.dokul_konsultasi_kontrol_result?.result_text)
                                const _cproduct = _dataQa.result.dokul_konsultasi_kontrol_result_produks.map(x => {
                                    return dProduct.products.find(p => p.id == x.produk_id)
                                })
                                setselectedProduct(_cproduct)
                            }
                            setselectedKontrol(_selectedKontrol)

                        }}> */}
                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                        {/* </span> */}



                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        ]
        return (
            <Fragment>
                <ReactTable
                            data={myData}
                            columns={columns}
                            defaultPageSize={pageSize}
                            manual={true}
                            className={"-striped -highlight"}
                            showPagination={true}
                            autoResetPage={true}
                            page={page}
                            pages={totalPages}
                            // onFilteredChange={(e) => fetchData(e)}
                            defaultFiltered={[]}
                            loadingText="Mengambil data dari server"
                            nextText="Selanjutnya"
                            previousText="Sebelumnya"
                            noDataText="Data tidak ditemukan"
                            showPaginationTop={true}
                            pageText="Halaman"
                            ofText="dari"
                            rowsText="Baris"
                            // onFetchData={(val) => fetchData(val)}
                            onFilteredChange={(val) => this.fetchData(val)}


                        />
            </Fragment>
        )
    }
}

export default TableView
