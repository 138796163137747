import React, { Component } from 'react';
import { Spinner, Modal, Label, Alert } from 'reactstrap';
import man from '../../assets/images/dashboard/man.png'

export class ActionQuestion extends Component {

    render() {
        return (
            <Modal isOpen={this.props.show} centered={true} >
                <Alert color='danger'>
                    <h4 className="alert-heading">
                       {this.props.title}
                    </h4>
                    <p>
                    {this.props.question}</p>
                    <hr />
                    <p className="d-flex flex-row justify-content-end">
                        <div className='p-2'>
                            <button className='btn btn-primary' onClick={()=>this.props.setOk()}>Ok</button>
                        </div>
                        <div className='p-2'>
                            <button className='btn bg-tred' onClick={()=>this.props.setCancel()}>Cancel</button>
                        </div>
                    </p>
                </Alert>

            </Modal>

            // <div className="d-flex justify-content-center vh-100" style={{ backgroundColor: 'rgba(52, 52, 52, 0.1)' }}>
            //     <Spinner animation="border" variant="primary" />

            // </div>


        )
    }
}

export default ActionQuestion
