import React, { Component, Fragment } from 'react'

import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from 'moment'
import DatePicker from "react-datepicker";

import { Collapse, Button, CardBody, Card, Input, Modal, ModalHeader, ModalBody, Table, ModalFooter } from 'reactstrap';
const pointType = [
    { type: 11, desc: "Tambahan dari order" },
    { type: 12, desc: "Tambahan manual" },
    { type: 13, desc: "Tambahan dari migrasi" },
    { type: 21, desc: "Pengurangan dari order" },
    { type: 23, desc: "Pengurangan dari migrasi" },
    { type: 3, desc: "Tambahan dari refund" },
    { type: 4, desc: "Pengurangan dari expired" },
]
export class PointOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: this.props.data.orderPoin.totalPages,
            loading: false,
            checkedValues: [],
            myData: this.props.data.orderPoin.orders,
            isOpen: false,
            monthSelected: 0,
            dateSelected: 0,
            selectedDate: Date(),
            openDetail: false,
            dataDetail: [],
            selectedBranch: 0,
            selectedstate: undefined,
            selectedOrder: { dokul_orders_items: [] },
            logType: 1,
            listSelectedOrder: [],
            selectAll: false,
            orderStatusSelected: "Semua"
        }
        this.fetchData = this.fetchData.bind(this);
        // this.handleStatusChange = this.handleStatusChange.bind(this);
    }

    // handleStatusChange(status) {
    //     console.log(status)
    //     this.setState({
    //         pages: this.props.data.pointlogs.totalPages,
    //         myData: this.props.data.pointlogs.PointLogs,
    //     });
    //   }

    async fetchData(state, instance) {


        let id;
        let date;
        let balik;
        let query = `logType=${this.state.logType}&`;
        // let query='branch_id='+this.props.selectedBranch;

        state.filtered.forEach(element => {

            if (element.id == 'created_date') {
                var d = moment(element.value)
                var od = moment(this.state.selectedDate)
                if (d.date() == od.date())
                    balik = true;
                date = element.value
                query = `${query}${element.id}=${date}&`

            } else {
                //balik = true;
                query = `${query}&${element.id}=${element.value}&`
            }

        })
        if (balik) return

        if (this.state.selectedBranch != 0) {
            query = `${query}&${'branch_id'}=${this.state.selectedBranch}`
        }
        const t = await this.props.getNextData(state.pageSize, state.page, query)

        this.setState({
            myData: t.data.orderPoin.orders,
            pages: t.data.orderPoin.totalPages,
            selectedDate: date,
            selectedstate: state
        })

        // console.log(t)
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        //this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        //   requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
        //     res => {
        //       // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        //       this.setState({
        //         // data: res.rows,
        //         // pages: res.pages,
        //         loading: false
        //       });
        //     }
        //   );
    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    async getDetailPoint(url = '', data = {}) {
        // Default options are marked with *
        // setLoading(true)
        console.log(data)
        // if (data.data&&data.data.label && data.data.label.props)
        //     delete data.data.label
        // setloadingupdate(true)
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/poin/detailPoint`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        // setLoading(false)
        // setloadingupdate(false)
        let result = await response.json()
        console.log(result)
        this.setState({ dataDetail: result }, () => this.setState({ openDetail: true, }))
        return true; // parses JSON response into native JavaScript objects
    }
    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
    delay = ms => new Promise(res => setTimeout(res, ms));

    async submitData(logtype) {
        // Default options are marked with *
        // setLoading(true)
        let listItemsAll = []
        await this.asyncForEach(this.state.listSelectedOrder, async (num) => {
            const res = await this.getUserLastPointLog("", num.user_id, num.branch_id)
            const lastPoint = res.length > 0 ? res[0].after_point : 0
            if (logtype == 1) {
                const items_ = num.dokul_orders_items.map(sld => {
                    return {
                        user_id: num.user_id,
                        user_wcid: 0,
                        log_type: 11,
                        order_id: num.id,
                        type_value: sld.id,
                        point: sld.point_earned,
                        before_point: lastPoint,
                        after_point: lastPoint + sld.point_earned,
                        create_by: 0,
                        branch_id: num.branch_id
                    }
                })
                listItemsAll = listItemsAll.concat(items_)
            }else if(logtype == 2) {
                const refundLog={
                    user_id: num.user_id,
                    user_wcid: 0,
                    log_type: 3,
                    order_id: num.id,
                    type_value: num.id,
                    point: num.point_used,
                    before_point: lastPoint,
                    after_point: lastPoint + num.point_used,
                    create_by: 0,
                    branch_id: num.branch_id
                }
                listItemsAll=[...listItemsAll,refundLog]
            }

            // console.log(res);
        })
        // console.log(listItemsAll);
        // this.state.listSelectedOrder.map(sl => {
        //     return sl.dokul_orders_items.map(sld=>{
        //         return {
        //             user_id: sl.user_id,
        //             user_wcid: 0,
        //             log_type: 11,
        //             order_id: sl.id,
        //             type_value: sld.id,
        //             point: sld.point_earned,
        //             before_point: 0,
        //             after_point: 0,
        //             create_by: 0,
        //             branch_id: sl.branch_id
        //         }
        //     })


        // })
        // this.state.listSelectedOrder.forEach(async x=>{

        // })
        // if (data.data&&data.data.label && data.data.label.props)
        //     delete data.data.label
        // setloadingupdate(true)
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/poin/submitPoint`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(listItemsAll) // body data type must match "Content-Type" header
        });
        // setLoading(false)
        // setloadingupdate(false)
        let result = await response.json()
        console.log(result)
        // this.setState({ dataDetail: result }, () => this.setState({ openDetail: true, }))
        // return true; // parses JSON response into native JavaScript objects
    }

    async getUserLastPointLog(url = '', iduser, branch) {
        // Default options are marked with *
        // setLoading(true)

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/poin/userLast/${iduser}/${branch}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        // setLoading(false)
        // setloadingupdate(false)
        // let result = await response.json()
        if (response.ok)
            return response.json(); // parses JSON response into native JavaScript objects
        else
            return false
    }



    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    openFilter = (i) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { selectedStatus, selectAll, listSelectedOrder, logType, selectedBranch, dataDetail, openDetail, myData, pages, loading, isOpen, selectedDate, selectedOrder } = this.state
        const columns = [];
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        // console.log(this.props)
        logType != 11 && ((selectedStatus == 5 && logType == 1) || (selectedStatus == "Batal" && logType == 2)) && columns.push(
            {
                headerClassName: '.hh',
                Header: <button className="m-0" onClick={() => this.submitData(logType)}>Debit Point</button>,
                accessor: "id",
                filterable: true,
                Filter: ({ filter, onChange }) => {
                    return <input type="checkbox" checked={selectAll} onClick={(e) => {

                        if (e.target.checked) {
                            this.setState({ listSelectedOrder: [...myData], selectAll: true })
                        } else {
                            this.setState({ listSelectedOrder: [], selectAll: false })

                        }
                    }}></input>
                },
                Cell: (data) => {
                    return <input type="checkbox" checked={listSelectedOrder.some(x => x.id == data.original.id)} onClick={(e) => {
                        if (e.target.checked) {
                            this.setState({ listSelectedOrder: [...listSelectedOrder, data.original], selectAll: false })
                        } else {
                            let _cd = [...listSelectedOrder]
                            const idx = _cd.findIndex(x => x.id == data.original.id)
                            _cd.splice(idx, 1)
                            this.setState({ listSelectedOrder: _cd, selectAll: false })

                        }
                    }}></input>

                },
                width: 100,
                style: {
                    textAlign: 'center',
                    padding: 0,
                    margin: 0
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("ORDER ID")}</b>,
                accessor: "id",
                filterable: true,
                width: 100,
                Cell: (data) => {
                    return `#${data.original.id}`

                },
                style: {
                    textAlign: 'justify'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("USERID")}</b>,
                accessor: "user_id",
                // filterable: true,
                Cell: (data) => {
                    return data.original.user_id

                },
                style: {
                    textAlign: 'justify'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("date")}</b>,
                accessor: "created_date",
                //filterable: true,
                Cell: (data) => {
                    var d = moment(data.original.created_date)
                    return d.format("DD MMM YYYY");

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("order status")}</b>,
                accessor: "order_status",
                filterable: true,
                width: 200,
                Filter: ({ filter, onChange }) => {

                    return <select className="form-control form-control-sm" value={filter ? filter.value : ""} onChange={event => {
                        this.setState({ selectedStatus: event.target.value })
                        onChange(event.target.value)

                    }
                    }>
                        <option selected>Semua</option>
                        <option >Progress</option>
                        <option >Batal</option>
                        <option value={5}>Selesai</option>

                    </select>
                },
                Cell: (data) => {
                    const status = data.original.order_status;
                    return <b className={`${[1, 12, 2, 31, 32, 311].some(x => x == status) ? 'text-info' : [5, 51].some(x => x == status) ? 'text-success' : [41, 42, 43].some(x => x == status) ? 'text-danger' : '-'}`}>{[1, 12, 2, 31, 32, 311].some(x => x == status) ? 'Progress' : [5, 51].some(x => x == status) ? 'Selesai' : [41, 42, 43].some(x => x == status) ? 'Batal' : '-'}</b>

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Poin status")}</b>,
                accessor: "order_status",
                width: 200,
                Cell: (data) => {
                    const logInfo = data.original.dokul_point_logs;
                    return <b className={`${logInfo.length == 0 ? 'text-info' : 'text-success'}`}>{logInfo.length == 0 ? 'Menunggu' : 'Selesai'}</b>

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("DokPoin")}</b>,
                accessor: "point_earned",
                filterable: true,
                Cell: (data) => {
                    return logType==1?data.original.point_earned:data.original.point_used

                },
                style: {
                    textAlign: 'center'
                }
            });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("log type")}</b>,
        //         //accessor: "order_status",
        //         Cell: (data) => {
        //             return pointType.find(_pt=>_pt.type==data.original.log_type)?.desc
        //             //return data.original.earnedpoints!=0?<span className="badge badge-success">Earned</span>:<span className="badge badge-info">Redeem</span>

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Point")}</b>,
        //         //accessor: "order_status",
        //         Cell: (data) => {
        //             return data.original.point
        //             //return data.original.earnedpoints!=0?data.original.earnedpoints:data.original.redeempoints

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Sebelumnya")}</b>,
        //         //accessor: "order_status",
        //         Cell: (data) => {
        //             return data.original.before_point
        //             //return data.original.earnedpoints!=0?data.original.earnedpoints:data.original.redeempoints

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Setelahnya")}</b>,
        //         //accessor: "order_status",
        //         Cell: (data) => {
        //             return data.original.after_point

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });

        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("order date")}</b>,
        //         accessor: "created_date",
        //         filterable: true,
        //         Filter: ({ filter, onChange }) => (
        //             <Input
        //                 type="date"
        //                 name="date"
        //                 id="exampleDate"
        //                 placeholder="date placeholder"
        //                 onChange={event => {
        //                     console.log(event.target)
        //                     onChange(event.target.value)
        //                 }
        //                 }
        //                 value={filter ? filter.value : ""}
        //             />
        //             //             <DatePicker 
        //             //             selected={selectedDate}
        //             // onChange={date =>this.changeDate(date)}
        //             // dropdownMode="select"
        //             // />
        //         ),
        //         Cell:(row)=>{
        //             return moment(row.original.created_date).format("YYYY-MM-DD HH:mm:ss")
        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Total")}</b>,
        //         accessor: "grand_total",
        //         Cell: (data) => {
        //             return data.original.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        columns.push(
            {
                Header: <b>#</b>,
                accessor: "payment_method_text",
                Cell: (data) => {

                    return <button onClick={() => {
                        if (logType == 1)
                            this.setState({ selectedOrder: data.original }, () => this.getDetailPoint("", { idItem: data.original.dokul_orders_items.map(x => x.id) }))
                        else
                            this.setState({ selectedOrder: data.original }, () => this.getDetailPoint("", { orderId: data.original.id }))
                    }
                    }>View Log</button>

                },
                style: {
                    textAlign: 'center'
                }
            });


        return (
            <Fragment>
                <div className="container-fluid">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                {/* <div className="card-header">
                                    <h5>Point logs</h5>
                                </div> */}
                                {/* <div className="container mt-2">
                                    <Button size="xs" color="primary" onClick={this.openFilter} style={{ marginBottom: '1rem' }}>Filter</Button>
                                    <Collapse isOpen={isOpen}>
                                        <Card>
                                            <CardBody>
                                                Anim pariatur cliche reprehenderit,
                                                enim eiusmod high life accusamus terry richardson ad squid. Nihil
                                                anim keffiyeh helvetica, craft beer labore wes anderson cred
                                                nesciunt sapiente ea proident.
          </CardBody>
                                        </Card>
                                    </Collapse>
                                </div> */}
                                <div className="card-body order-datatable">
                                    <div className="d-flex justify-content-between">
                                        {user && user.dokul_user_roles.some(x=>x.role_id==5 || x.role_id==4) && <div className="d-flex flex-row ">

                                            <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => this.setState({ selectedBranch: 1 }, () => this.fetchData(this.state.selectedstate))}>JAKARTA</div>
                                            <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() => this.setState({ selectedBranch: 2 }, () => this.fetchData(this.state.selectedstate))}>BALIKPAPAN</div>
                                            <div className={`p-2  pointer ${selectedBranch == 0 ? 'bg-primary' : ''}`} onClick={() => this.setState({ selectedBranch: 0 }, () => this.fetchData(this.state.selectedstate))}>ALL</div>
                                        </div>}
                                        <div className="d-flex flex-row ">

                                            <div className={`p-2 pointer ${logType == 1 ? 'bg-primary' : ''}`} onClick={() => this.setState({ logType: 1 }, () => this.fetchData(this.state.selectedstate))}>Earned</div>
                                            <div className={`p-2  pointer ${logType == 2 ? 'bg-primary' : ''}`} onClick={() => this.setState({ logType: 2 }, () => this.fetchData(this.state.selectedstate))}>Redeem</div>
                                        </div>

                                    </div>

                                    <ReactTable
                                        data={myData}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className={"-striped -highlight"}
                                        showPagination={pagination}
                                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                        pages={pages} // Display the total number of pages
                                        loading={loading} // Display the loading overlay when we need it
                                        onFetchData={this.fetchData} // Request new data when things change
                                        sorted={this.state.sorted}
                                        onSortedChange={(newSort, column) => {
                                            this.setState({ sorted: newSort });
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={openDetail} toggle={() => this.setState({ openDetail: false })} centered>
                    <ModalHeader className="bg-primary text-white">Log Dokpoin </ModalHeader>
                    <ModalBody className="bg-light text-dark">
                        {logType == 1 ? <Table>
                            <thead>
                                <tr>
                                    <th>Nama produk</th>
                                    <th>Dokpoin</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOrder?.dokul_orders_items.length > 0 ? selectedOrder.dokul_orders_items.map(x => {
                                    const detailItems = dataDetail.find(y => x.id == y.type_value)
                                    return <tr>
                                        <td>{x.product_name}</td>
                                        <td>{x.point_earned}</td>
                                        {/* <td>{x.log_type == 11 ? "POINT DIDAPAT DARI ORDER" : x.log_type == 12 ? "Poin koreksi manual" : x.log_type == 21 ? "Redeem" : x.log_type == 4 ? "Expired point" : "Unknown"}</td> */}

                                        <td>{detailItems ? <b className="text-success">Debit</b> : <b className="text-info">Menunggu</b>}</td>
                                        <td>{!detailItems ? <button className="btn btn-light btn-md"><i className="icofont icofont-thumbs-up text-success" /></button> : <button className="btn btn-light btn-md"><i className="icofont icofont-thumbs-down text-danger" /></button>}</td>

                                    </tr>



                                }) : <h4 className="text-center text-secondary">Data log poin tidak ditemukan / poin belum tercatat pada sistem</h4>}



                            </tbody>
                        </Table> :
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Dokpoin Sebelum</th>
                                        <th>Dokpoin Digunakan</th>
                                        <th>Dokpoin Sesudah</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataDetail.length > 0 ? dataDetail.map(x => {
                                        // const detailItems=dataDetail.find(y=>x.id==y.type_value)
                                        return <tr>
                                            <td>{x.before_point}</td>
                                            <td>{x.point}</td>
                                            <td>{x.after_point}</td>
                                            <td>{x.log_type == 21 ? "Terpotong" : "Dikembalikan"}</td>
                                            <td>{x.log_type == 21 ? <button className="btn btn-light btn-md"><i className="icofont icofont-block-left text-success" /></button> : <button className="btn btn-light btn-md"><i className="icofont icofont-block-right text-danger" /></button>}</td>

                                        </tr>



                                    }) : <h4 className="text-center text-secondary">Data log poin tidak ditemukan / poin belum tercatat pada sistem</h4>}



                                </tbody>
                            </Table>
                        }

                    </ModalBody>
                    <ModalFooter className="bg-light text-dark"><button className="btn btn-primary btn-md" onClick={() => this.setState({ openDetail: false })}>Tutup</button></ModalFooter>

                </Modal>

            </Fragment>
        )
    }
}

export default PointOrder
