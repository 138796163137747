import React, { useState, Fragment, useEffect } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import datanya from '../../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import history from '../../../history';
import CategoryList from '../physical/category'
import Gudang from './gudang';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import { GET_PRODUCTS, GET_ROLES } from '../../../constants/graph';
import ProdukThumb from '../layout/produk-thumb';
import Stock from './stock';
import Roles from './role.list';
import { Button, Spinner } from 'reactstrap';
// const IS_LOGGED_IN = gql`
//   query IsUserLoggedIn {
//     isLoggedIn @client
//   }
// `;
// const GET_PRODUCTS = gql`query products {
//     products @rest(type: "Product",path: "api/products") {
//         name,
//         pictures,
//         dokul_products_skus
//         branchId,id,
//         dokul_tags_categories,
//         dokul_product_images
//         dokul_tags
//     }
//   }
// `;
const Product_list = ({typeStock,settypeStock,brandSearch,categoriesSearch,setbrandSearch,setcategoriesSearch, txtSearch,searchText,isLoading,dRole, dCategory, dataproducts, selectedBranch, saveCategory, updateStock, refetch,nextData }) => {

    // if (!Cookies.get('signedin')) {
    //     history.push('/');
    // }
    //  const { loading, data, error } = useQuery(GET_PRODUCTS,);
    // if (error) {
    //     console.log(error.message);
    //     Cookies.remove('token');
    //     Cookies.remove('jwt');
    //     Cookies.remove('signedin');


    //     history.push('/');
    //     //return <div>Error!</div>;
    // }
    //const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    const [selectedCategory, setselectedCategory] = useState({})
    const [selectedRole, setselectedRole] = useState({})
    const [selectedStock, setselectedStock] = useState(0)
    const [dataProduct, setdataProduct] = useState([])
    const [textSearch, settextSearch] = useState()

    useEffect(() => {



        let _filteredData = [...dataproducts]


        if (selectedCategory.name != undefined){
            // _filteredData = _filteredData.filter(prod => prod.dokul_tags_categories.some(ct => ct.name == selectedCategory.name))
            setcategoriesSearch([selectedCategory.id])
        }
        if (selectedBranch != 3){
            // _filteredData = _filteredData.filter(_pr => _pr.branch_id == selectedBranch)
        
            // setbrandSearch([selectedBranch])
        }
        if (selectedBranch != 3){
            // _filteredData = _filteredData.filter(_pr => _pr.branch_id == selectedBranch)
            // setbrandSearch([])
        }
        if (selectedStock != 0 ){
            settypeStock(selectedStock)
            // _filteredData = _filteredData.filter(prod => prod.dokul_products_skus.some(ct => ct.dokul_gudang_skus.some(sk => sk.avaibility == selectedStock)))
        }
        if (selectedStock == 5){
            // _filteredData = _filteredData.filter(prod => prod.dokul_products_skus.some(ct => ct.dokul_gudang_skus.some(sk => sk.avaibility == 3 && sk.stock < 10)))
        }
        if (selectedStock == 2){
            // _filteredData = _filteredData.filter(prod => prod.dokul_products_skus.some(ct => ct.dokul_gudang_skus.some(sk => sk.avaibility == selectedStock || (sk.stock==0 && sk.avaibility==3))))
        }
        if (selectedRole.id != undefined){
            // _filteredData = _filteredData.filter(prod => prod.dokul_role_products.some(ct => ct.role_id == selectedRole.id))
        }
        if (textSearch != undefined && textSearch != ""){
            // _filteredData = _filteredData.filter(prod => prod.name.toLowerCase().includes(textSearch.toLowerCase()))
        }
        setdataProduct(_filteredData)
        // else
        //     setdataProduct(data.products.filter(_pr => _pr.branch_id == selectedBranch).filter(prod => prod.dokul_tags_categories.some(ct => ct.name == selectedCategory.name)))

    }, [selectedCategory, selectedBranch, selectedStock, dataproducts, selectedRole])

    const refreshProduct = () => {
        refetch()

    }
    const handleAddProduct = () => {
        history.push('/products/add');
    }
    //daricahce
    // const { products } = client.readQuery({
    //     query: gql`
    //       query ReadProducts {
    //         products {
    //           id
    //         }
    //       }
    //     `,
    //   },false);

    // const todo = client.readFragment({
    //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment myTodo on Product {
    //         id
    //         name
    //       }
    //     `,
    // });
    //console.log(data);
    // setData(datanya)

    return (
        <Fragment>
            {/* <Breadcrumb title="Product List" parent="" /> */}
            <div className="container-fluid bg-white">
                {/* <div className="row">
                    <div className="card col-md-12">
                        <Gudang  data={data} saveCategory={saveCategory} refreshProduct={setselectedCategory}  />
                        

                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-3">
                        <div className="card col-sm-12">
                            <h4 className="card-header">Filter</h4>

                        </div>
                        <div className="card col-sm-12">
                            <Gudang data={dCategory} saveCategory={saveCategory} refreshProduct={setselectedCategory} />
                        </div>

                        <div className="card col-sm-12">
                            <Stock data={dCategory} saveCategory={saveCategory} setselectedStock={settypeStock} typeStock={typeStock}/>
                        </div>

                        <div className="card col-sm-12">
                            <CategoryList data={dCategory} saveCategory={saveCategory} refreshProduct={setselectedCategory} />
                        </div>
                        <div className="card col-sm-12">
                            <Roles data={dRole} refreshProduct={setselectedRole} />
                        </div>

                    </div>
                    <div className="col-md-9">

                        <div className="row products-admin ratio_asos">
                            <div className="card col-sm-12">
                                <div className="d-flex">
                                    <h4 className="card-header">Daftar Produk</h4>
                                    <div className="ms-auto card-header">

                                        <button type="button" className="btn btn-primary btn-small float-left" onClick={() => {
                                            handleAddProduct()
                                        }}
                                        >Tambah Produk</button>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <input type="text" defaultValue={txtSearch!='all'?txtSearch:''}  className="form-control" placeholder="Cari nama produk" aria-label="Recipient's username with two button addons" onChange={(e) => settextSearch(e.target.value)} />
                                    <button onClick={()=>searchText(textSearch)} className="btn btn-outline-secondary" type="button"><i className="fa fa-search" /></button>
                                </div>

                            </div>
                            <div className="col-sm-12">

                            </div>

                            {/* {!loading ? */}

                            {dataProduct.filter(x=>x.dokul_products_skus ).map((myData, i) => {
                                return (
                                    <ProdukThumb key={i} refetch={refreshProduct} updateStock={updateStock} data={myData} />
                                )

                            })}
                            {/* : '' */}
                            {/* } */}
                            <Button disabled={isLoading} onClick={nextData}>{!isLoading?'MORE':<Spinner />}</Button>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default withApollo(Product_list)
