import React, { Component, Fragment } from 'react'
import { Search } from 'react-feather';
import { Collapse } from 'reactstrap';

export class OptionHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchbar: false,
            open: false,
            field: [{ title: "Tanggal", type: 0 }, { title: "Dokter", type: 1, fields: [{ name: "d", value: "Dr Simon" }, { name: "dr", value: "Dr Arthur" }] }, { title: "Status Pasien", type: 3 ,fields: [{ name: "d", value: "Pasien baru" }, { name: "dr", value: "Pasien lama" }]}]
        }
    }
    handleSearchClick = () => {
        this.setState({
            searchbar: !this.state.searchbar
        })
    }
    render() {
        return (
            <Fragment>



                {/* <div className="row">
                    <div className="col-12"> */}
                        <h4 className="pb-2 w-90 border-bottom">Filter <span className="float-right" style={{ cursor: "pointer", fontSize: 35 }} onClick={() => this.handleSearchClick()}><i className={`${this.state.searchbar ? "icofont icofont-toggle-on text-success" : "icofont icofont-toggle-off"}`} ></i></span></h4>
                    {/* </div>
                    <div className="col-12"> */}



                        <Collapse isOpen={this.state.searchbar}>
                            {this.state.field.map(item =>
                                item.type == 0 ?
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-5">{item.title}</label>
                                        <div className="col-md-7">
                                            <input className="form-control" name="name" id="name-point" type="date" />
                                        </div>
                                    </div>
                                    : item.type == 1 ?
                                        <div className="form-group row ">

                                            <label className="col-xl-3 col-md-3">{item.title}</label>
                                            <div className="col-md-7">
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    {item.fields.map(fields =>
                                                        <option>{fields.value}</option>
                                                    )}


                                                </select>
                                            </div>
                                        </div>
                                        : item.type == 2 ?
                                            <div className="form-group row ">

                                                <label className="col-xl-3 col-md-4">{item.title}</label>
                                                <div className="col-md-7">
                                                {item.fields.map(fields =>
                                                       <div className="form-check">
                                                       <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked />
                                                       <label className="form-check-label" for="gridRadios1">
                                                           {fields.value}
                                                       </label>
                                                   </div>
                                                )}
                                                    
                                                   
                                                </div>
                                            </div>
                                            : item.type == 3 ?
                                                <div className="form-group row ">

                                                    <label className="col-xl-3 col-md-4">{item.title}</label>
                                                    <div className="col-md-7">
                                                    {item.fields.map(fields =>
                                                       <div className="checkbox-space row">
                                                       <label className="d-block col">
                                                           <input className="checkbox_animated" id="chk-ani2" type="checkbox" />
                                                           {fields.value}
                                                       </label>
                                                   </div>
                                                )}
                                                        
                                                        
                                                    </div>
                                                </div> : ""
                            )}
                        </Collapse>
                    {/* </div>


                </div> */}


                {/* <form className="form-inline search-form">
                    <div className="form-group">
                        <input className={"form-control-plaintext " + (this.state.searchbar ? 'open' : '')} type="search" placeholder="Search.." />
                        <span className="d-sm-none mobile-search" onClick={() => this.handleSearchClick()}><Search /></span>
                    </div>
                </form> */}
            </Fragment>
        )
    }
}

export default OptionHeader
