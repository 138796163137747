import React, { useState } from "react";
import { Button, Tooltip, Popover, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

const TooltipItem = props => {
    const { item, id, icon, warna, backwarna, title,aksi,statusDirubah } = props;
    
    const [orderItem,setOrderItem]=useState(item.skus);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const itemStatus=[]
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const togglepop = () => setPopoverOpen(!popoverOpen);

    return (
        <span>
            <span className="border p-2 rounded m-1" id={"poper-" + id} >
                <i className={item.icon} style={{ fontSize: 20, padding: 0, color: `${item.warna}`, cursor: 'pointer' }}></i>
            </span>

            <Popover
                placement={"left"}
                isOpen={popoverOpen}
                target={"poper-" + id}
                toggle={togglepop}
            >
                <PopoverHeader>{item.title}</PopoverHeader>
                <PopoverBody>
                    <div class="d-flex flex-column bd-highlight" style={{ width: 200 }}>
                        {orderItem.map((sku,i) =>
                            <div key={i}  className="d-flex justify-content-between mb-2">
                                <span>{sku.product_name}</span>
                                <input className="checkbox_animated" id="chk-ani2" type="checkbox" checked={sku.status==item.statusDirubah} onChange={(e)=>{
                                        let _skus=[...orderItem]
                                        let _sku={...sku}
                                        let idx=_skus.indexOf(sku)
                                        _sku.status=e.target.checked?item.statusDirubah:0
                                        _skus[idx]=_sku
                                        setOrderItem(_skus)
                                }}/>
                            </div>
                        )}

                        <Button onClick={()=>{
                            aksi(orderItem)
                            togglepop()

                        }}>Simpan</Button>
                    </div>


                </PopoverBody>
            </Popover>
            <Tooltip
                placement={item.placement}
                autohide={true}
                isOpen={tooltipOpen}
                target={"poper-" + id}
                toggle={toggle}
            >
                {item.text}
            </Tooltip>
        </span>
        // <span>
        //   <span className="border p-2 rounded m-1" id={"Tooltip-" + id} >
        //   <i className={item.icon} style={{ fontSize: 20, padding: 0, color: `${item.warna}`, cursor: 'pointer' }}></i>
        // </span>
        //   <Tooltip
        //     placement={item.placement}
        //     isOpen={tooltipOpen}
        //     target={"Tooltip-" + id}
        //     toggle={toggle}
        //   >
        //      {item.text}
        //   </Tooltip>
        // </span>
    );
};
export default TooltipItem