import React, { Component, Fragment, useEffect, useState } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings, Home } from 'react-feather'
import Breadcrumb from '../common/breadcrumb';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { GET_CONFIGS } from '../../constants/graph'
import BranchKontak from './branch.kontak'
import BranchOrder from './branch.order'
import BranchGudang from './branch.gudang';
import BranchKlinik from './branch.klinik'
import QRCode from 'qrcode.react'
import Housekeeping from './housekeeping';

const branchList = ['', 'jakarta', 'balikpapan']
const Setings = () => {
  const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
  const { loading, data, error, fetchMore,refetch } = useQuery(GET_CONFIGS,);
  const [selectedBranch, setselectedBranch] = useState(1)
  const [dataConfig, setdataConfig] = useState()
  useEffect(() => {
    setdataConfig(data)
  }, [data])

  if (loading || !data) return null
  return (
    <div className="container bg-white" >
      <Breadcrumb title="Pengaturan" />
      {user&&user.dokul_user_roles.some(x=>x.role_id==5)&&<div className="d-flex flex-row mb-5">
        <div className="p-2">Dokterkulitku : </div>
        <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(1)}>JAKARTA</div>
        <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(2)}>BALIKPAPAN</div>
      </div>}

      <Tabs>
        <TabList className="nav nav-tabs tab-coupon" >
          <Tab className="nav-link">GENERAL</Tab>
          <Tab className="nav-link">ORDER</Tab>
          <Tab className="nav-link">GUDANG</Tab>
          <Tab className="nav-link">EMAIL TEMPLATE</Tab>
          <Tab className="nav-link">HOME BANNER</Tab>
          <Tab className="nav-link">HOME PARALAX</Tab>
          <Tab className="nav-link">PAGE HOME</Tab>
          <Tab className="nav-link">PAGE ONLINESHOP</Tab>
          <Tab className="nav-link">MEDIA</Tab>
        </TabList>
        <TabPanel>
          <div className="container bg-white">
            <BranchKontak data={data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch]))} branch_id={selectedBranch} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="container bg-white">
            {dataConfig&&<BranchOrder refetch={refetch} data={dataConfig.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch]))} branch_id={selectedBranch} />}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="container bg-white">
            <BranchGudang data={data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch]))} branch_id={selectedBranch} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="container bg-white">
            {/* <BranchGudang data={data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch]))} branch_id={selectedBranch} /> */}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div className="col-sm-12">
              
              <div className="card">
                <div className="card-body">
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Image</label>
                    <div className="col-md-9">
                      <div className="file-upload-product d-flex flex-nowrap">

                        <div className='img-front m-1' style={{ padding: 0, width: "100%" }}>
                          <div className="box-input-file-lg"  >
                            {/* {editPayMethod != null && idxPayMethod == idx ?
                              <img src={`${editPayMethod.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${editPayMethod.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                              : <img src={`${kl.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            } */}
                            <img src={`${ process.env.REACT_APP_IMAGE_HOST}${ data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`banner_home_${selectedBranch}_img`)).nilai}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            <div className="product-hover">
                              <ul>
                                <li>
                                  <input title={""} className="upload text-center" type="file" onChange={(e) => {}} />

                                  <i className="fa fa-edit" />

                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Title</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`banner_home_${selectedBranch}_title`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Description</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`banner_home_${selectedBranch}_desc`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">URL</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`banner_home_${selectedBranch}_url`)).nilai}</div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </TabPanel>
        <TabPanel>
        <div className="row">
            <div className="col-sm-12">
              
              <div className="card">
                <div className="card-body">
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Image</label>
                    <div className="col-md-9">
                      <div className="file-upload-product d-flex flex-nowrap">

                        <div className='img-front m-1' style={{ padding: 0, width: "100%" }}>
                          <div className="box-input-file-lg"  >
                            {/* {editPayMethod != null && idxPayMethod == idx ?
                              <img src={`${editPayMethod.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${editPayMethod.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                              : <img src={`${kl.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            } */}
                            <img src={`${ process.env.REACT_APP_IMAGE_HOST}${ data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_img`)).nilai}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            <div className="product-hover">
                              <ul>
                                <li>
                                  <input title={""} className="upload text-center" type="file" onChange={(e) => {}} />

                                  <i className="fa fa-edit" />

                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Title</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_title`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Subtitle</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_subtitle`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Description</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_desc`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">URL</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_url`)).nilai}</div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </TabPanel>
        <TabPanel>
        <div className="row">
            <div className="col-sm-12">
              
              <div className="card">
                <div className="card-body">
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Image</label>
                    <div className="col-md-9">
                      <div className="file-upload-product d-flex flex-nowrap">

                        <div className='img-front m-1' style={{ padding: 0, width: "100%" }}>
                          <div className="box-input-file-lg"  >
                            {/* {editPayMethod != null && idxPayMethod == idx ?
                              <img src={`${editPayMethod.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${editPayMethod.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                              : <img src={`${kl.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            } */}
                            <img src={`${ process.env.REACT_APP_IMAGE_HOST}${ data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_img`)).nilai}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            <div className="product-hover">
                              <ul>
                                <li>
                                  <input title={""} className="upload text-center" type="file" onChange={(e) => {}} />

                                  <i className="fa fa-edit" />

                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Title</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_title`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Subtitle</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_subtitle`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Description</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_desc`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">URL</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_url`)).nilai}</div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </TabPanel>
        <TabPanel>
        <div className="row">
            <div className="col-sm-12">
              
              <div className="card">
                <div className="card-body">
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Image</label>
                    <div className="col-md-9">
                      <div className="file-upload-product d-flex flex-nowrap">

                        <div className='img-front m-1' style={{ padding: 0, width: "100%" }}>
                          <div className="box-input-file-lg"  >
                            {/* {editPayMethod != null && idxPayMethod == idx ?
                              <img src={`${editPayMethod.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${editPayMethod.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                              : <img src={`${kl.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            } */}
                            <img src={`${ process.env.REACT_APP_IMAGE_HOST}${ data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_img`)).nilai}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            <div className="product-hover">
                              <ul>
                                <li>
                                  <input title={""} className="upload text-center" type="file" onChange={(e) => {}} />

                                  <i className="fa fa-edit" />

                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Title</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_title`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Subtitle</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_subtitle`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">Description</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_desc`)).nilai}</div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-xl-3 col-md-3">URL</label>
                    <div className="col-md-9">{data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch])).dokul_configs.find(_cf=>_cf.name.includes(`paralax_home_url`)).nilai}</div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </TabPanel>

        <TabPanel>
        <div className="container bg-white">
            <Housekeeping data={data.configs.find(br => br.name.toLowerCase().includes(branchList[selectedBranch]))} branch_id={selectedBranch} />
          </div>
        </TabPanel>

      </Tabs>
    </div>
    
  )
}
export default withApollo(Setings)


