import React, { Component, Fragment, forwardRef, useState, useMemo, useEffect } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import { Col, Collapse, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
//import Datatable from '../../common/datatable';
import ReactTable from 'react-table';

// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
////import user from '../../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import CKEditors from "react-ckeditor-component";
import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { GET_DOKTERS, listMasalah, GET_KLINIKS, GET_LIST_KONSULTASI_EKSPRESS, GET_LIST_KONSULTASI_KONTROL, GET_LIST_SUBMIT_KONSULTASI, GET_RESERVASI, GET_SLOT_KONSULTASI, UPDATE_DOKTER, GET_PRODUCTS, GET_ROLES, UPDATE_USER_ROLE, UPDATE_USER_KONTROL, GET_LIST_VOUCHER_PRIVATE } from '../../constants/graph';
//import data from '../../../assets/data/physical_list';
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
import ReactImageMagnify from 'react-image-magnify';
import voucher_codes from 'voucher-code-generator'
import * as XLSX from 'xlsx';
registerLocale('id', es)


const PrivateList = ({listProduct}) => {
    const [updateUserRoles] = useMutation(UPDATE_USER_ROLE)

    // const { loading: lProduct, data: dProduct, error: eProduct } = useQuery(GET_PRODUCTS);
    const [modalOpenChangeRole, setModalOpenChangeRole] = useState(false)
    const [colapseFilter, setcolapseFilter] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date())
    const [page, setpage] = useState(0)
    const [totalPages, settotalPages] = useState(0)
    const [pageSize, setpageSize] = useState(10)
    const [graphType, setgraphType] = useState(2)
    const [branchSelected, setbranchSelected] = useState(0)
    const [selectedOrderStatus, setselectedOrderStatus] = useState([])
    const [selectedRange, setselectedRange] = useState(2)
    const [keperluan, setkeperluan] = useState(3)
    const [branchId, setbranchId] = useState(1)
    const [orderstatus, setorderstatus] = useState({ label: [] })
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpenCreateVouhcer, setmodalOpenCreateVouhcer] = useState(false)
    const [modalOpenCreateVouhcerBulk, setmodalOpenCreateVouhcerBulk] = useState(false)
    const [selectedImage, setselectedImage] = useState()
    const [content, setContent] = useState("")
    const [modalEditText, setmodalEditText] = useState(false)
    const [editedText, seteditedText] = useState(1)
    const [jumlahVoucher, setjumlahVoucher] = useState(0)
    const [nominalVoucher, setnominalVoucher] = useState(0)
    const [voucherBranch, setvoucherBranch] = useState()
    const [jenisVoucher, setjenisVoucher] = useState()
    const [voucherFor, setvoucherFor] = useState()
    const [expiredDays, setexpiredDays] = useState()
    const [selectedKontrol, setselectedKontrol] = useState()
    const [selectedProduct, setselectedProduct] = useState([])
    const [modalProduk, setmodalProduk] = useState(false)
    const [textSeach, settextSeach] = useState("")
    const [stateQuery, setstateQuery] = useState([])
    const [listData, setlistData] = useState([])
    const status = ['', 'Kosong', 'Dimiliki', 'Terpakai', 'Expired']
    const [statusSelected, setstatusSelected] = useState(0)
    const [filterByName, setfilterByName] = useState()
    const [listDataUpload, setlistDataUpload] = useState([])
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const { loading, error, data } = useQuery(GET_SLOT_KONSULTASI)
    const { loading: lRoles, error: eRoles, data: dRoles } = useQuery(GET_ROLES)
    // const { loading: lKonsulEks, error: eKonsulEks, data: dKonsulEks } = useQuery(GET_LIST_KONSULTASI_KONTROL)
    const [getDataSubmit, { loading: lSubmitKonsul, error: eSubmitKonsul, data: dPrivateVoucher }] = useLazyQuery(GET_LIST_VOUCHER_PRIVATE, {
        onCompleted: (data) => {
            setpage(data.voucherPrivate.currentPage)
            settotalPages(data.voucherPrivate.totalPages)
            setlistData(data.voucherPrivate.data)
        }
    })
  

    useEffect(() => {

        fetchData()
    }, [keperluan, branchId, page, pageSize, statusSelected,filterByName])

    // const updateCache = (cache, { data }) => {
    //     console.log(data)
    //     const _updatedKontrol = { ...data.updateSubmitKontrol }

    //     const fragmentResult = cache.writeFragment({
    //         id: `SubmitKontrol:${_updatedKontrol.id}`,
    //         fragment: gql`
    //     fragment submitkontrol on SubmitKontrol{
    //         id
    //         status  
    //     }
    //   `,
    //         data: _updatedKontrol
    //     });
    // };
    // const [updateStatusKonsul] = useMutation(UPDATE_USER_KONTROL, { update: updateCache })
    // const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });


    // const selectedDokter = client.readFragment({
    //     id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment readDokter on Dokter{
    //         id
    //         dokul_dokter_konsultasis
    //         fullname
    //         picture
    //         picture
    //         available
    //       }
    //     `,
    // }, false);
    const { loading: loadingDokter, error: errorDokter, data: dataDokter } = useQuery(GET_DOKTERS)
    async function getQ_A(_id) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolQa/${_id}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            ///body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    async function getHistory(_id) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolHistory/${_id}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            ///body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    async function submitControlResult(_id, data, resultText = false, answerQuestion = false, medicalNote = false, product = false) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolResult/${_id}`, {
            method: selectedKontrol.status >= 3 ? 'PUT' : 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }

    async function submitBulkVoucher(data) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/voucher/generateBulk`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }

    var alphabet = "123456789ABCDEFGHJKLMNPQRSTUVWXYZ";
    var alphabetLen = alphabet.length;

    function nextDigit(digit) {
        const nextDigitPos = (alphabet.indexOf(digit) + 1) % alphabetLen;
        return alphabet.charAt(nextDigitPos);
    }


    function nextSerial(id) {
        if (id == null || id.length == 0) return "111";
        var digits = id.split("");
        digits[2] = nextDigit(digits[2]);
        if (digits[2] == "1") /* overflow */ {
            digits[1] = nextDigit(digits[1]);
            if (digits[1] == "1") /* overflow */ {
                digits[0] = nextDigit(digits[0])
            }
        }
        return digits.join("");
    }

    async function generateVoucher(jumlahVoucher, nominalVoucher) {
        const vcr = voucher_codes.generate({
            length: 8,
            count: jumlahVoucher,
            prefix: jenisVoucher == 1 ? "RF-" : "GF-",
            charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'//voucher_codes.charset("alphanumeric")
        });
        // console.log(vcr)
        // return

        let newVouchers = []
        const _expiredDate = moment().add(expiredDays, "days")
        for (let i = 0; i < jumlahVoucher; i++) {
            newVouchers.push({
                code: vcr[i],//Math.floor(10000 + Math.random() * 9000000),
                status: 1,
                voucher_for: voucherFor,
                amount: nominalVoucher,
                branch_id: voucherBranch,
                active_days:expiredDays,
                // expired_date: _expiredDate.format("YYYY-MM-DD")
            })
        }
        // alert(JSON.stringify(newVouchers))
        // return

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/voucher/generate`, {
            method: "POST",
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(newVouchers) // body data type must match "Content-Type" header
        })
        setjenisVoucher()
        setjumlahVoucher()
        setvoucherBranch()
        setnominalVoucher()
        setvoucherFor()
        setmodalOpenCreateVouhcer(false)
        return response.json(); // parses JSON response into native JavaScript objects
    }

    async function updateControlResult(_id, data, resultText = false, answerQuestion = false, medicalNote = false, product = false, note = false, refund = false) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/updateResult/${_id}`, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.

            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                "resultText": resultText,
                "answerQuestion": answerQuestion,
                "medicalNote": medicalNote,
                "product": product,
                "note": note,
                "refund": refund,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    const fetchData = (_state, instance) => {
        const state = _state ? _state : stateQuery
        
        let param = `page=${page}&size=${pageSize}&`
        if(filterByName)param=param+`dokul_user.first_name=${filterByName}&`
        if(branchId)param=param+`branch_id=${branchId}&`
        if(statusSelected)param=param+`status=${statusSelected}&`
        let email
        if (state && Array.isArray(state)) state.forEach(element => {
            if (element.id == 'email') email = element.value
            param = param + element.id + '=' + element.value + '&'
        })
        // param = param + `branch_id=${branchId}&masalah=${keperluan == 2 ? 'rutin' : keperluan == 1 ? 'iritasi' : ''}&status=${statusSelected}&startDate=${startDate}&startDate=${endDate}`
        //setstateQuery(state)
        getDataSubmit({
            variables: {
                page: 0,
                size: 10,
                keperluan: keperluan,
                email: email,
                params: `${param}`

            }
        })

    }
    useEffect(() => {
        fetchData()

    }, [])
    useEffect(() => {
        if (selectedKontrol) {
            setModalOpen(true)
        }
    }, [selectedKontrol])
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }
    const handelValueChange = (e) => {
        const { value, name } = e.target
        let _c = stateQuery.find(x => x.id == name)
        if (!_c) {
            setstateQuery(x => [...x, { id: name, value: value }])
        } else {

            setstateQuery(x => {
                const _idx = x.findIndex(y => y.id == name)
                x.splice(_idx, 1)
                return [...x, { id: name, value: value }]
            })

        }

    }
    const CustomTableFilter = ({ filter, onChange }) => {
        return (
            <Input type="search" value={filter ? filter.value : ''}
                onChange={event => (event ? onChange(event.currentTarget.value) : "")} />
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: <b>No</b>,
                accessor: "id",
                width: 50,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className={`p-2 ${cellInfo.original.branch_id == 1 ? "bg-warning" : "bg-success"} rounded text-dark`}>
                        {(cellInfo.index + 1) + (page * pageSize)}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'center',
                }
            },

            {
                Header: <b>Dibuat</b>,
                accessor: "created_date",
                width: 200,

                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.created_date).format("DD MMMM YYYY HH:mm")}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Expired</b>,
                accessor: "expired_date",
                width: 200,

                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    {cellInfo.original.expired_date ? <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.expired_date).format("DD MMMM YYYY HH:mm")}
                    </div> :
                        <div className="p-2 bd-highlight">
                            Unknown
                        </div>
                    }

                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Nominal</b>,
                accessor: "amount",
                width: 100,
                // filterable: true,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>

                </div>,
                // Filter: CustomTableFilter,
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>code</b>,
                accessor: "code",
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.code}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Customer</b>,
                accessor: "dokul_user.first_name",
                // filterable: true,
                // Filter: ({ filter, onChange }) => (
                //     <div >
                //         <span className={``}>
                //             <input type="text"  value={filterByName}
                //                 onChange={e =>setfilterByName(e.target.value)} />
                //         </span>
                //     </div>
                // ),
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    {cellInfo.original.dokul_user?<div className="p-2 bd-highlight">
                        {cellInfo.original.dokul_user.first_name} {cellInfo.original.dokul_user.last_name}
                    </div>:"Unknown"
            }
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),

                style: {
                    textAlign: 'justify',
                }
            },
            // {
            //     Header: <b>Email</b>,
            //     accessor: "email",
            //     // filterable: true,
            //     width: 200,
            //     Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
            //         <div className="p-2 bd-highlight">
            //             {cellInfo.original.dokul_user.email}
            //         </div>
            //         {/* <div className="p-2 bd-highlight">
            //             {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
            //         </div>
            //         <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
            //     </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
            //     style: {
            //         textAlign: 'justify',
            //     }
            // },
            {
                Header: <b>Status</b>,
                accessor: "status",
                width: 200,
                Cell: (cellInfo) => {
                    const _status = cellInfo.original.status > 10 ? cellInfo.original.status.toString().charAt(0) : cellInfo.original.status
                    let _nextQA = _status == ""
                    _nextQA = _status == 3 ? cellInfo.original.result_question ? 2 : 1 : ""
                    _nextQA = _status == 4 ? cellInfo.original.question_answer ? 2 : 1 : ""
                    return <div className="d-flex flex-column bd-highlight mb-3">
                        {statusSelected==4?<Badge className='p-2 bg-danger'>Expired</Badge>:<Badge className={`p-2 ${_status == 1 && "bg-success"} ${_status == 2 && "bg-secondary"} ${_status == 3 && "bg-info"} ${_status == 4 && "bg-warning"}`}>{status[_status] ? status[_status] : status[_status]}  {_nextQA} </Badge>}

                        {/* <div className="p-2 bd-highlight">{cellInfo.original.is_approved == 1 ? "Approved" : "Rejected"}</div> */}

                    </div>
                },
                style: {
                    textAlign: 'left',
                }
            },
            // {
            //     Header: <b>Pembayaran</b>,
            //     accessor: "created_date",
            //     // filterable: true,
            //     width: 80,
            //     Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
            //         <Badge className="p-2 bg-info">
            //             {cellInfo.original.dokul_paymethod.name}
            //         </Badge>
            //         {/* <div className="p-2 bd-highlight">
            //             {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
            //         </div>
            //         <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
            //     </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
            //     style: {
            //         textAlign: 'justify',
            //     }
            // },
            // {
            //     Header: <b>Action</b>,
            //     id: 'delete',
            //     width: 150,
            //     accessor: str => "delete",
            //     Cell: (row) => (
            //         <div>
            //             {(row.original.status == 1 || row.original.status == 2 || row.original.status == 11 || row.original.status == 21) && <span className="border p-2 m-2" onClick={async () => {
            //                 updateStatusKonsul(
            //                     {
            //                         variables: {
            //                             data: { status: 3 },
            //                             id: row.original.id
            //                         }
            //                     }
            //                 )

            //             }}>
            //                 <i className="fa fa-thumbs-up" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
            //             </span>}
            //             <span className="border p-2 m-2" onClick={async () => {
            //                 const _dataQa = await getQ_A(row.original.id)
            //                 const _dataHistory = await getHistory(row.original.user_id)

            //                 console.log(_dataQa)
            //                 let _selectedKontrol = { ...row.original }
            //                 _selectedKontrol["qa"] = _dataQa ? _dataQa.qa : []
            //                 _selectedKontrol["result"] = _dataQa ? _dataQa.result : {}
            //                 _selectedKontrol["history"] = _dataHistory ? _dataHistory.history : []
            //                 if (_dataQa.result) {
            //                     setContent(_dataQa.result.dokul_konsultasi_kontrol_result?.result_text)
            //                     const _cproduct = _dataQa.result.dokul_konsultasi_kontrol_result_produks.map(x => {
            //                         return dProduct.products.find(p => p.id == x.produk_id)
            //                     })
            //                     setselectedProduct(_cproduct)
            //                 }
            //                 setselectedKontrol(_selectedKontrol)

            //             }}>
            //                 <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
            //             </span>



            //         </div>
            //     ),
            //     style: {
            //         textAlign: 'center'
            //     },
            //     sortable: false
            // }
        ])

        const handleUpload = (e) => {

            e.preventDefault();
    
            var files = e.target.files, f = files[0];
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
    
                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

                const vcr = voucher_codes.generate({
                    length: 8,
                    count: dataParse.length,
                    prefix: "",
                    charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'//voucher_codes.charset("alphanumeric")
                });
                // console.log(vcr)
                // return
        
                let newVouchers = []
                // const _expiredDate = moment().add(expiredDays, "days")
                // for (let i = 0; i < jumlahVoucher; i++) {
                //     newVouchers.push({
                //         code: vcr[i],//Math.floor(10000 + Math.random() * 9000000),
                //         status: 1,
                //         voucher_for: voucherFor,
                //         amount: nominalVoucher,
                //         branch_id: voucherBranch,
                //         active_days:expiredDays,
                //         // expired_date: _expiredDate.format("YYYY-MM-DD")
                //     })
                // }
                const dtaMap = dataParse.slice(1).map((x,i) => {
                    const panjangNilai= x[1].toString().length-3
                   
                    const kode=x[1].toString().substring(0,panjangNilai)
                    const _expiredDate = moment().add(x[2], "days")
                    return {
                        code: x[3]==1?`RF${kode}-${vcr[i]}`:`GF${kode}-${vcr[i]}`,//Math.floor(10000 + Math.random() * 9000000),
                        status: 2,
                        voucher_for: x[3],
                        amount: x[1],
                        branch_id: x[4],
                        active_days:x[2],
                        user_email:x[0],
                        expired_date: _expiredDate.format("YYYY-MM-DD")
                    }
                })

                setlistDataUpload(dtaMap)
                setmodalOpenCreateVouhcerBulk(true)
                // console.log(dataParse);
                // console.log(dtaMap);
                // postData(`${process.env.REACT_APP_API_HOST}/api/users/sync/role`, dtaMap)
            };
            reader.readAsBinaryString(f)
        }
    if (loading || loadingDokter || lSubmitKonsul) return "Loading"

    // console.log(data.slots)
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="col-12 bg-main">
                    <div className=" p-0 mb-3 d-flex ">

                        <button className='btn btn-xs btn-primary m-2 float-right' onClick={() => setcolapseFilter(!colapseFilter)}>{colapseFilter ? "Hide filter" : "Show filter"}</button>
                        <button className='btn btn-xs btn-primary m-2 float-right' onClick={() => setmodalOpenCreateVouhcer(true)}>Buat voucher</button>
                        <label htmlFor="fileUpload" className="m-2">
                                            <Badge className="p-2 bg-primary pointer" style={{ cursor: "pointer" }}>
                                                Import Data : 
                                                <input id="fileUpload" type="file" multiple accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                    onChange={(e) => handleUpload(e)}
                                                    style={{ cursor: "pointer" }}/>
                                            </Badge></label>

                    </div>

                </div>
                <Collapse isOpen={colapseFilter}>
                    <div className="row">
                        <div className="col-8 m-0 boder">
                            {/* <div className="d-flex flex-row border">
                        <h6 className="title m-2">Dokterkulitku</h6>
                        <div className="form-check p-2 ms-auto">
                            <input className="form-check-input" type="checkbox" name="branch" id="branch" checked={branchSelected == 1} onClick={() => setbranchSelected(1)} />
                            <label className="form-check-label" htmlFor="branch">Jakarta</label>
                        </div>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="checkbox" name="branch" id="branch" checked={branchSelected == 2} onClick={() => setbranchSelected(1)} />
                            <label className="form-check-label" htmlFor="branch">Balikpapan</label>
                        </div>

                    </div> */}
                            <div className="d-flex flex-column border p-2">

                             

                                <div className="d-flex flex-row ">
                                    <h6 className="title  m-2">Dokterkulitku</h6>
                                    <div className="form-check p-2 ms-auto">
                                        <input className="form-check-input" type="radio" name="gt" id="gt" checked={branchId == 1} onChange={() => setbranchId(1)} />
                                        <label className="form-check-label" htmlFor="gt">Jakarta</label>
                                    </div>
                                    <div className="form-check m-2">
                                        <input className="form-check-input" type="radio" name="gt" id="gt" checked={branchId == 2} onChange={() => setbranchId(2)} />
                                        <label className="form-check-label" htmlFor="gt">Balikpapan</label>
                                    </div>

                                </div>
                                <hr />

                                <div className="d-flex flex-row ">
                                    <h6 className="title  m-2">Status</h6>
                                    <div className="d-flex flex-column ms-auto">

                                        <div className="form-check p-2 ">
                                            <input className="form-check-input" type="radio" name="status" id="status" checked={statusSelected == 0} onChange={() => setstatusSelected(0)} />
                                            <label className="form-check-label" htmlFor="status">Semua</label>
                                        </div>
                                        <div className="form-check p-2 ">
                                            <input className="form-check-input" type="radio" name="status" id="status" checked={statusSelected == 1} onChange={() => setstatusSelected(1)} />
                                            <label className="form-check-label" htmlFor="status">Belum assign</label>
                                        </div>
                                        <div className="form-check p-2">
                                            <input className="form-check-input" type="radio" name="status" id="status" checked={statusSelected == 2} onChange={() => setstatusSelected(2)} />
                                            <label className="form-check-label" htmlFor="status">Dimiliki</label>
                                        </div>


                                    </div>
                                    <div className="d-flex flex-column ">



                                        <div className="form-check p-2 ms-auto">
                                            <input className="form-check-input" type="radio" name="status" id="status" checked={statusSelected == 3} onChange={() => setstatusSelected(3)} />
                                            <label className="form-check-label" htmlFor="status">Terpakai</label>
                                        </div>
                                        <div className="form-check m-2">
                                            <input className="form-check-input" type="radio" name="status" id="status" checked={statusSelected == 4} onChange={() => setstatusSelected(4)} />
                                            <label className="form-check-label" htmlFor="status">Expired</label>
                                        </div>

                                    </div>

                                </div>
                                <hr />
                                <div className="d-flex flex-row justify-content-between">

                                    {/* <h6 className="title m-2">Tanggal</h6>

                                    <DatePicker
                                        selected={startDate}
                                        className="ms-auto"
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd MMMM yyyy"
                                    />
                                    <Label className="p-2 align-self-center">--s/d--</Label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setendDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        filterDate={(date) => {
                                            const dif = moment(startDate).diff(moment(date), 'months');
                                            return dif == 0
                                        }}
                                        dateFormat="dd MMMM yyyy"
                                    /> */}


                                    {/* <div className="d-flex flex-column">
                            {true && <div className="input-group p-2  d-flex justify-content-end">
                                <span className="input-group-text" id="basic-addon1">Start</span>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd MMMM yyyy"
                                />
                                


                            </div>}
                            {true && <div className="input-group p-2  d-flex justify-content-end">
                               
                                <span className="input-group-text" id="basic-addon1">End</span>
                                <DatePicker
                                selected={endDate}
                                onChange={(date) => setendDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                filterDate={(date) => {
                                    const dif = moment(startDate).diff(moment(date), 'months');
                                    return dif == 0
                                }}
                                dateFormat="dd MMMM yyyy"
                            />

                            </div>}
                            </div> */}

                                </div>
                            </div>


                        </div>
                        <div className="col-4 m-0 border">
                            <div className="d-flex flex-row ">
                                <h6 className="title  m-2">Amount voucher</h6>
                                <div className="form-check p-2 ms-auto">
                                    <Input type="search" name="amount" value={stateQuery.find(x => x.id == 'amount')?.value}
                                        onChange={handelValueChange} />
                                </div>

                            </div>


                            <div className="d-flex flex-row ">
                                <h6 className="title  m-2">Nama Pasien</h6>
                                <div className="form-check p-2 ms-auto">
                                    <Input type="search" name="first_name" value={stateQuery.find(x => x.id == 'first_name')?.value}
                                        onChange={handelValueChange} />
                                </div>

                            </div>
                            {/* <div className="d-flex flex-row ">
                                <h6 className="title  m-2">Email Pasien</h6>
                                <div className="form-check p-2 ms-auto">
                                    <Input type="search" name="email" value={stateQuery.find(x => x.id == 'email')?.value}
                                        onChange={handelValueChange} />
                                </div>

                            </div> */}

                        </div>
                        <div className="col-12">
                            <div className=" p-0 mb-3 d-flex justify-content-end border-end border-start border-bottom">
                                <button className='btn btn-xs btn-primary m-2' onClick={() => { fetchData() }}>Reload</button>
                            </div>
                        </div>
                    </div>
                </Collapse>
                <div className="row">
                    <div className="col-sm-3">


                    </div>
                    <div className="col-sm-12">
                        {/* <TableView pageSize={pageSize} listData={listData} page={page} totalPages={totalPages} fetchData={fetchData} /> */}

                        <ReactTable
                            data={dPrivateVoucher ? dPrivateVoucher.voucherPrivate.data : []}
                            columns={columns}
                            defaultPageSize={pageSize}
                            manual={true}
                            className={"-striped -highlight"}
                            showPagination={true}
                            autoResetPage={true}
                            page={page}
                            pages={totalPages}
                            onPageChange={(e) => setpage(e)}
                            onPageSizeChange={(e) => setpageSize(e)}
                            defaultFiltered={stateQuery}
                            loadingText="Mengambil data dari server"
                            nextText="Selanjutnya"
                            previousText="Sebelumnya"
                            showPaginationTop={true}
                            pageText="Halaman"
                            ofText="dari"
                            rowsText="Baris"
                            // onFetchData={(val) => fetchData(val)}
                            onFilteredChange={(val) => fetchData(val)}


                        />

                        {/* <div className="card"> */}
                        {/* <div className="card-header bg-primary text-white">
                                <span className="float-left">
                                    <div className="d-flex flex-row bd-highlight ">
                                        <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">Sesi Konsultasi</h4></div>
                                      

                                    </div>
                                </span>

                            </div> */}

                        {/* <div className="card-body"> */}

                        {/* <div className="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                            <ToastContainer />
                        </div>

                        {/* {data.dokters[idxDokter].dokul_dokter_konsultasis.filter(ykon => ykon.type == 2).length > 0 ?
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                        <h6 className="border-bottom border-info"><i className="icofont icofont-globe" /> ONLINE </h6>
                                        <div className="p-2 bd-highlight mb-2">
                                            <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-left">
                                                <div className="d-flex flex-row">
                                           
                                                </div>
                                                <Link
                                                    to={`/products/edit/${data.dokters[idxDokter].dokul_dokter_konsultasis.find(onk => onk.type == 2).id_product}`}
                                                >
                                                    <span className="p-2 bd-highlight rounded bg-success">EDIT PRODUK </span>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                } */}
                    </div>
                </div>
            </div>
            <div className="clearfix" />
            {/* </div> */}
            {/* </div> */}
            <Modal isOpen={modalProduk} toggle={() => setmodalProduk(false)} centered size="lg">
                <ModalHeader className="bg-primary" toggle={() => setmodalProduk(false)}>Tambah rekomendasi produk</ModalHeader>
                <ModalBody className="bg-light text-dark">
                    <Input className="mt-2 mb-3" type="search" placeholder="Cari produk" onChange={(e) => settextSeach(e.target.value)} />
                    <Row>
                        {selectedKontrol && listProduct.filter(x => x.branch_id == selectedKontrol.branch_id && x.name.toLowerCase().includes(textSeach.toLowerCase())).map((x, idx) =>
                            <FormGroup key={idx} check className="col-6">
                                <Label check >
                                    <Input type="checkbox" checked={selectedProduct.some(p => p.id == x.id)} onClick={(e) => {
                                        let _currentSelectedProduct = [...selectedProduct]
                                        if (e.target.checked) {
                                            _currentSelectedProduct.push(x)
                                        } else {
                                            const _idx = _currentSelectedProduct.findIndex(_p => _p.id == x.id)
                                            _currentSelectedProduct.splice(_idx, 1)
                                        }
                                        setselectedProduct(_currentSelectedProduct)
                                    }} />{' '}
                                    {x.name}{x.branch_id == 1 ? " ( JAKARTA )" : " ( BALIKPAPAN )"}
                                </Label>
                            </FormGroup>)}
                    </Row>
                </ModalBody>

            </Modal>
            <Modal isOpen={modalOpenCreateVouhcer} toggle={() => setmodalOpenCreateVouhcer(false)} centered >
                <ModalHeader className="bg-primary" toggle={() => setmodalOpenCreateVouhcer(false)}> Create vouchers</ModalHeader>
                <ModalBody className='bg-light text-dark'>
                    <FormGroup>
                        <Label htmlFor="nominal">Nominal</Label>
                        <Input type="number" name="nominal" id="nominal" placeholder="Nominal voucher" value={nominalVoucher} onChange={(e) => setnominalVoucher(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="jumlah">Jumlah</Label>
                        <Input type="number" name="jumlah" id="jumlah" placeholder="Jumlah voucher" value={jumlahVoucher} onChange={(e) => setjumlahVoucher(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="expired">Expired</Label>
                        <Input type="number" name="expired" id="expired" placeholder="Jumlah hari" value={expiredDays} onChange={(e) => setexpiredDays(e.target.value)} />
                    </FormGroup>
                    <Label className='mx-1 row' >Dokterkulit</Label>
                    <FormGroup check inline>

                        <Input
                            name="branch"
                            type="radio"
                            checked={voucherBranch == 1}
                            onChange={(e) => setvoucherBranch(e.target.checked ? 1 : 2)}
                        />
                        {' '}
                        <Label check>
                            Jakarta
                        </Label>
                    </FormGroup>

                    <FormGroup check inline>
                        <Input
                            name="branch"
                            type="radio"
                            checked={voucherBranch == 2}
                            onChange={(e) => setvoucherBranch(e.target.checked ? 2 : 1)}
                        />
                        {' '}
                        <Label check>
                            Balikpapan
                        </Label>
                    </FormGroup>
                    <Label className='mx-1 row' >Jenis voucher</Label>
                    <FormGroup check inline>

                        <Input
                            name="jenisvoucher"
                            type="radio"
                            checked={jenisVoucher == 1}
                            onChange={(e) => setjenisVoucher(e.target.checked ? 1 : 2)}
                        />
                        {' '}
                        <Label check>
                            REFUND
                        </Label>
                    </FormGroup>

                    <FormGroup check inline>
                        <Input
                            name="jenisvoucher"
                            type="radio"
                            checked={jenisVoucher == 2}
                            onChange={(e) => setjenisVoucher(e.target.checked ? 2 : 1)}
                        />
                        {' '}
                        <Label check>
                            GIFT
                        </Label>
                    </FormGroup>
                    <Label className='mx-1 row' >Penggunaan</Label>
                    <FormGroup check inline>

                        <Input
                            name="voucher_for"
                            type="radio"
                            checked={voucherFor == 1}
                            onChange={(e) => setvoucherFor(e.target.checked ? 1 : 2)}
                        />
                        {' '}
                        <Label check>
                            Belanja
                        </Label>
                    </FormGroup>

                    <FormGroup check inline>
                        <Input
                            name="voucher_for"
                            type="radio"
                            checked={voucherFor == 2}
                            onChange={(e) => setvoucherFor(e.target.checked ? 2 : 1)}
                        />
                        {' '}
                        <Label check>
                            Kunsultasi
                        </Label>
                    </FormGroup>
                </ModalBody>
                <ModalFooter className='bg-light'>
                    <button disabled={!voucherFor || !jenisVoucher || !voucherBranch || !jumlahVoucher || !nominalVoucher} className='btn btn-sm btn-primary' onClick={() => generateVoucher(jumlahVoucher, nominalVoucher)}>Kirim</button>

                </ModalFooter>
            </Modal>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} fullscreen centered >

                <ModalHeader className="bg-primary" toggle={() => setModalOpen(false)}>Detail Kontrol Konsultasi</ModalHeader>
                <div className="modal-content bg-white p-2">
                    <div className="modal-body">

                        {selectedKontrol && <Row className="bg-white">
                            <Col sm={12}>
                                <h4 className="bg-primary p-2 "> Riwayat konsul pasien <span className="float-end"><i className="close" /></span></h4>
                                <div className="d-flex flex-row flex-wrap">
                                    {selectedKontrol.history.map((x, idx) =>
                                        <Badge key={idx} className={`border p-2 m-2 ${x.id == selectedKontrol.id ? "bg-primary" : "bg-light text-dark"}`} onClick={async () => {
                                            const _dataQa = await getQ_A(x.id)
                                            // const _dataHistory = await getHistory(row.original.user_id)

                                            // console.log(_dataQa)
                                            let _selectedKontrol = { ...x, dokul_user: selectedKontrol.dokul_user }
                                            _selectedKontrol["qa"] = _dataQa ? _dataQa.qa : []
                                            _selectedKontrol["result"] = _dataQa ? _dataQa.result : {}
                                            _selectedKontrol["history"] = selectedKontrol.history
                                            if (_dataQa.result) {
                                                setContent(_dataQa.result.dokul_konsultasi_kontrol_result?.result_text)
                                                const _cproduct = _dataQa.result.dokul_konsultasi_kontrol_result_produks.map(x2 => {
                                                    return listProduct.find(p => p.id == x2.produk_id)
                                                })
                                                setselectedProduct(_cproduct)
                                            }
                                            setselectedKontrol(_selectedKontrol)
                                            // setselectedKontrol({ ...x, history: selectedKontrol.history })
                                        }}>{moment(x.created_date).format("DD MMMM YYYY")}</Badge>
                                    )}


                                </div>
                            </Col>
                            <Col sm={8}>
                                <h4 className="bg-primary p-2 "> pertanyaan & jawaban</h4>

                                <ListGroup>
                                    {selectedKontrol.qa.map((x, idx) => {
                                        return <div key={idx} ><ListGroupItem color="success">
                                            <p dangerouslySetInnerHTML={{ __html: x.dokul_robot_question ? x.dokul_robot_question.pertanyaan : "Tujuan kontrol" }} />
                                        </ListGroupItem>
                                            <ListGroupItem color="info">
                                                <p dangerouslySetInnerHTML={{ __html: x.dokul_robot_answer.jawaban }} />
                                                {!x.image_url && <p dangerouslySetInnerHTML={{ __html: x.answer }} />}
                                                {x.image_url && x.dokul_robot_answer.is_upload != 2 && <button className="btn btn-primary btn-xs" onClick={() => {
                                                    setselectedImage(x.image_url)
                                                    setmodalOpenCreateVouhcer(true)
                                                }}>view</button>}
                                            </ListGroupItem>
                                        </div>
                                    })}
                                </ListGroup>

                            </Col>
                            <Col sm={4}>
                                <h4 className="bg-primary p-2 ">info pasien</h4>
                                <dl className="row p-2">
                                    {/* <Row className="bg-grey"> */}
                                    <dt className="col-sm-5 ">Nama</dt>
                                    <dd className="col-sm-7">{selectedKontrol.dokul_user.first_name} {selectedKontrol.dokul_user.last_name}</dd>
                                    <dt className="col-sm-5 ">Role</dt>
                                    <dd className="col-sm-7">
                                        <div className="row bg-light text-dark">
                                            <div className="col-12">
                                                {selectedKontrol.dokul_user.dokul_user_roles.map(x => <Label className="w-100">{"- " + dRoles.roles.find(y => y.id == x.role_id).role_name}</Label>)}
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary btn-xs float-end m-2" onClick={() => {
                                                    setModalOpenChangeRole(true)
                                                }}>Edit</button></div>
                                        </div>

                                    </dd>
                                    {/* </Row> */}
                                    <dt className="col-sm-5">Jenis kelamin</dt>
                                    <dd className="col-sm-7">{selectedKontrol.dokul_user.gender}</dd>
                                    <dt className="col-sm-5">Usia</dt>
                                    <dd className="col-sm-7">{moment().diff(moment(selectedKontrol.dokul_user.tgl_lahir), 'years', false)}</dd>
                                    <dt className="col-sm-5">Status</dt>
                                    <dd className="col-sm-7">{selectedKontrol.dokul_user.status_kawin}</dd>
                                    <dt className="col-sm-5">Masalah utama</dt>
                                    <dd className="col-sm-7">{selectedKontrol.dokul_user.masalah_utama}</dd>
                                    <dt className="col-sm-5">Masalah lain</dt>
                                    <dd className="col-sm-7">
                                        {selectedKontrol.dokul_user.masalah_lain && selectedKontrol.dokul_user.masalah_lain.split(",").map(x => <Label>{listMasalah[x]}</Label>)}
                                    </dd>
                                    <dt className="col-sm-12">Catatan medis</dt>
                                    <dd className="col-sm-12 bg-light">
                                        {selectedKontrol.dokul_user.medical_note && <>
                                            <div className="bg-light p-2 text-dark" dangerouslySetInnerHTML={{ __html: selectedKontrol.dokul_user.medical_note }} />
                                        </>}
                                        <div className="bg-light p-2">
                                            <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                                seteditedText(3)
                                                setContent(selectedKontrol.dokul_user.medical_note)
                                                setmodalEditText(true)
                                            }}>Edit</button>
                                        </div>
                                    </dd>


                                </dl>
                                <h4 className="bg-primary p-2 mt-2">Hasil</h4>
                                <div className="d-flex flex-column">
                                    <h6>Refund biaya :</h6>

                                    <div className="bg-light p-2 text-dark">
                                        <input className="form-check-input" type="radio" name="refund" id="refund" value="0" checked={(!selectedKontrol.result.dokul_konsultasi_kontrol_result || selectedKontrol.result.dokul_konsultasi_kontrol_result?.refund == 0)} onClick={async () => {
                                            if (selectedKontrol.result.dokul_konsultasi_kontrol_result)
                                                await updateControlResult(selectedKontrol.result.dokul_konsultasi_kontrol_result.id, { refund: 0 }, false, false, false, false, false, true)
                                            let curResult = { ...selectedKontrol.result }
                                            let konResult = { ...curResult.dokul_konsultasi_kontrol_result }
                                            konResult.refund = 0
                                            curResult.dokul_konsultasi_kontrol_result = konResult
                                            setselectedKontrol({ ...selectedKontrol, result: curResult })
                                        }} />
                                        <label className="form-check-label mr-3" htmlFor="refund">Tidak</label>
                                        {" "}
                                        <input className="form-check-input" type="radio" name="refund" id="refund" value="1" checked={selectedKontrol.result.dokul_konsultasi_kontrol_result?.refund == 1} onClick={async () => {
                                            if (selectedKontrol.result.dokul_konsultasi_kontrol_result) {
                                                await updateControlResult(selectedKontrol.result.dokul_konsultasi_kontrol_result.id, { refund: 1 }, false, false, false, false, false, true)
                                                let curResult = { ...selectedKontrol.result }
                                                let konResult = { ...curResult.dokul_konsultasi_kontrol_result }
                                                konResult.refund = 1
                                                curResult.dokul_konsultasi_kontrol_result = konResult
                                                setselectedKontrol({ ...selectedKontrol, result: curResult })
                                            }
                                        }} />
                                        <label className="form-check-label" htmlFor="refund">Ya</label>
                                    </div>

                                    <h6>Catatan :</h6>
                                    {selectedKontrol.result.dokul_konsultasi_kontrol_result && <>
                                        <div className="bg-light p-2" dangerouslySetInnerHTML={{ __html: selectedKontrol.result.dokul_konsultasi_kontrol_result.note }} />
                                    </>}
                                    <div className="bg-light p-2">
                                        <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                            seteditedText(4)
                                            setContent(selectedKontrol.result.dokul_konsultasi_kontrol_result?.note)
                                            setmodalEditText(true)
                                        }}>Edit</button>
                                    </div>
                                    <h6>Dokter : </h6>
                                    {selectedKontrol.result.dokul_konsultasi_kontrol_result && <>
                                        <span className="text-muted text-small">{moment(selectedKontrol.result.dokul_konsultasi_kontrol_result.created_date).format("DD MMMM YYYY HH:mm")}</span>
                                        <div className="bg-light p-2" dangerouslySetInnerHTML={{ __html: selectedKontrol.result.dokul_konsultasi_kontrol_result.result_text }} />
                                    </>}
                                    <div className="bg-light p-2">
                                        <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                            seteditedText(1)
                                            setContent(selectedKontrol.result.dokul_konsultasi_kontrol_result?.result_text)
                                            setmodalEditText(true)
                                        }}>Edit</button>
                                    </div>
                                    {selectedKontrol.result.result_question && <>
                                        <h6 className="mt-2">Pasien :</h6>
                                        <span className="text-muted text-small">{moment(selectedKontrol.result.question_date).format("DD MMMM YYYY HH:mm")}</span>

                                        <div className="bg-light text-dark p-2" ><p dangerouslySetInnerHTML={{ __html: selectedKontrol.result.result_question }} /></div>
                                    </>}



                                    {selectedKontrol.result.result_question && <>
                                        <h6 className="mt-2">Dokter :</h6>
                                        <span className="text-muted text-small">{moment(selectedKontrol.result.answer_date).format("DD MMMM YYYY HH:mm")}</span>

                                        <div className="bg-light p-2" dangerouslySetInnerHTML={{ __html: selectedKontrol.result.question_answer }} />
                                        <div className="bg-light p-2">
                                            <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                                seteditedText(2)
                                                setContent(selectedKontrol.result.question_answer)
                                                setmodalEditText(true)
                                            }}>Edit</button>
                                        </div>
                                    </>}

                                </div>



                                <h4 className="bg-primary p-2 mt-2 ">Rekomendasi Produk </h4>
                                <ListGroup style={{ minHeight: 100 }}>

                                    {selectedProduct && selectedProduct.length > 0 ? selectedProduct.map((x, idx) => {
                                        return <ListGroupItem key={idx} color="light" className="justify-content-between">
                                            <img src={`${process.env.REACT_APP_IMAGE_HOST}${x.dokul_product_images[0].image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded w-25 m-2" />
                                            <span className="text-end">{x.name}</span>
                                        </ListGroupItem>


                                    }) : <div className="bg-light p-2 text-center text-muted"><h4>Belum ada produk direkomendasikan</h4></div>
                                    }
                                    <div className="bg-light p-2">
                                        <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                            setmodalProduk(true)
                                        }}>Edit produk</button>
                                    </div>
                                    {/* <span className="float-right"><button className="btn btn-success btn-xs" onClick={() => setmodalProduk(true)}>Add</button></span> */}
                                </ListGroup>
                                <div className="mt-2"><button className={`btn btn-success w-100`} onClick={async () => {
                                    const submitData = {
                                        resultText: selectedKontrol.result.dokul_konsultasi_kontrol_result.result_text,
                                        resultNote: selectedKontrol.result.dokul_konsultasi_kontrol_result.note,
                                        resultProduct: selectedProduct,

                                    }
                                    if (!selectedKontrol.result.dokul_konsultasi_kontrol_result.id) {
                                        const _result = await submitControlResult(selectedKontrol.id, submitData)

                                    } else {
                                        await updateControlResult(selectedKontrol.id, submitData, false, false, false, true)
                                    }

                                    setModalOpen(false)
                                }}>Submit</button> </div>
                            </Col>
                            {/* <Col>
                            <h4 className="bg-primary p-2 ">hasil dari dokter</h4>
                        </Col> */}
                        </Row>}
                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let _dokter = { ...data.templates[idxDokter] }
                            let _dokul_dokter_konsultasis = [..._dokter.dokul_dokter_konsultasis]
                            let _currentKonsultasi = { ..._dokul_dokter_konsultasis[idxKlinik] }
                            let currentJadwals = [..._currentKonsultasi.dokul_dokter_jadwals]

                            currentJadwals.push({
                                id_konsultasi: _currentKonsultasi.id,
                                name: jadwalBaru.name,
                                tanggal: moment(tgl).format("YYYY/MM/DD"),
                                jumlah_sesi: jadwalBaru.jumlah_sesi,
                                terpakai: 0,

                            })


                            _dokter['dokul_dokter_jadwal'] = currentJadwals
                            updateDokter({ variables: { id: 1, type: _dokter }, })


                            setModalOpen(false)
                        }}>Simpan</button>
                    </div> */}

                </div>

            </Modal>
            <Modal className="bg-white" isOpen={modalEditText} toggle={() => setmodalEditText(false)}>
                <ModalHeader>
                    {editedText == 1 ? "Hasil konsultasi/kontrol" : editedText == 2 ? "Jawaban pertanyaan pasien" : editedText == 3 ? "Catatan medis pasien" : editedText == 4 ? "Catatan konsultasi" : ""}

                </ModalHeader>
                <ModalBody className="bg-white">
                    <CKEditors
                        activeclassName="p10"
                        content={content}
                        events={{
                            "blur": (val) => {
                                // let _product = { ...product }
                                // _product.description = val.editor.getData()
                                // setProduct(_product)
                                setContent(val.editor.getData())
                            },
                            //"afterPaste": (val) => product.description=val.editor.getData(),
                            "change": (val) => {
                                // let _product = { ...product }
                                // // console.log(_product)
                                // //console.log(val.editor.getData())
                                // _product.description = val.editor.getData()
                                // setProduct(_product)
                                setContent(val.editor.getData())
                                // handleChangeDesc(val.editor.getData())
                            },
                        }}
                    />
                </ModalBody>
                <ModalFooter className="bg-light">
                    <button className="btn btn-primary btn-xs" onClick={() => {
                        let _selectedKontrol = { ...selectedKontrol }
                        let _user = { ...selectedKontrol.dokul_user }
                        let _result = { ..._selectedKontrol.result }
                        let _dokul_konsultasi_kontrol_result = { ..._result.dokul_konsultasi_kontrol_result }
                        switch (editedText) {
                            case 1:
                                _dokul_konsultasi_kontrol_result.result_text = content
                                if (_dokul_konsultasi_kontrol_result.id)
                                    updateControlResult(_dokul_konsultasi_kontrol_result.id, { result_text: content }, true)
                                //   setselectedKontrol(x=>{return {...x,result['dokul_konsultasi_kontrol_result'].result_text:content}})
                                break;
                            case 2:
                                _result.question_answer = content
                                updateControlResult(selectedKontrol.id, { question_answer: content }, false, true)
                                break;
                            case 3:
                                _user.medical_note = content
                                updateControlResult(_user.id, { medical_note: content }, false, false, true)
                                break;
                            case 4:
                                _dokul_konsultasi_kontrol_result.note = content
                                if (_dokul_konsultasi_kontrol_result.id)
                                    updateControlResult(_dokul_konsultasi_kontrol_result.id, { note: content }, false, false, false, false, true)
                                break;
                            default:
                            // code block
                        }
                        _result.dokul_konsultasi_kontrol_result = _dokul_konsultasi_kontrol_result
                        _selectedKontrol.result = _result
                        _selectedKontrol.dokul_user = _user
                        setselectedKontrol(_selectedKontrol)
                        setmodalEditText(false)

                    }}>Save</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalOpenChangeRole} toggle={() => setModalOpenChangeRole(false)} centered size={"sm"} >

                {selectedKontrol && <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Ganti role  - {selectedKontrol.dokul_user.first_name}</h5>

                    </div>
                    <div className="modal-body">
                        <div className="form-group row m-2">


                            {dRoles.roles.map((role, idx) =>
                                <div key={idx} className="form-check">
                                    <input className="form-check-input" value={role.id} type="checkbox" name={`role_selected${idx}`} id="role_selected" checked={selectedKontrol.dokul_user.dokul_user_roles.some(dur => dur.role_id == role.id)} onChange={(e) => {
                                        let cu = { ...selectedKontrol }
                                        let du = { ...cu.dokul_user }
                                        let _userRoles = [...du.dokul_user_roles]
                                        if (e.target.checked) {
                                            //cu.role_id = e.target.value
                                            _userRoles.push({
                                                email: selectedKontrol.dokul_user.email,
                                                id: `${selectedKontrol.dokul_user.email}${e.target.value}`,
                                                role_id: e.target.value,
                                                user_id: selectedKontrol.dokul_user.id
                                            })

                                        } else {
                                            const idxcu = _userRoles.findIndex(x => x.role_id == e.target.value)
                                            _userRoles.splice(idxcu, 1)

                                        }
                                        du.dokul_user_roles = _userRoles
                                        cu.dokul_user = du
                                        setselectedKontrol(cu)

                                    }} />
                                    <label className="form-check-label" htmlhtmlFor={`role_selected${idx}`}>
                                        {role.role_name}
                                    </label>
                                </div>
                            )}

                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary btn-sm" onClick={() => {
                            updateUserRoles({
                                variables: { data: selectedKontrol.dokul_user.dokul_user_roles }
                            })
                            // let _users = { ...users }
                            // let curentUsers = [..._users.users]


                            // const idx = curentUsers.findIndex(x => x.id == selectedUser.id)
                            // curentUsers = curentUsers.splice(idx, 1)
                            // _users.users = curentUsers

                            setModalOpenChangeRole(false)
                            // postData(`${process.env.REACT_APP_API_HOST}/api/users/sync/role`, [selectedUser])
                        }}>Submit</button>
                        <button className="btn btn-secondary btn-sm " onClick={() => setModalOpenChangeRole(false)}>Cancel</button>

                    </div>

                </div>
                }

            </Modal>
            <Modal isOpen={modalOpenCreateVouhcerBulk} toggle={() => setmodalOpenCreateVouhcerBulk(false)} centered size={"xl"} >


                <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Voucher bulk</h5>

                    </div>
                    <div className="modal-body">
                        <div className="form-group row">

                            <div className="col-md-12">
                            <div className="d-flex flex-row">
                                        <div className="p-2 col-4">Email</div>
                                        <div className="p-2  col-4"> Code</div>
                                        <div className="p-2  col-4">Nilai</div>
                                    </div>
                                {listDataUpload.map((vcr, idx) => {
                                   
                             return <div className="d-flex flex-row">
                                        <div className="p-2 col-4">{vcr.user_email}</div>
                                        <div className="p-2  col-4"> {vcr.code}</div>
                                        <div className="p-2  col-4">{vcr.amount}</div>
                                    </div>
                                   


                                }
                                )}

                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary btn-sm" onClick={async () => {
                          await submitBulkVoucher(listDataUpload)
                          setmodalOpenCreateVouhcerBulk(false)
                        }}>Submit</button>
                        <button className="btn btn-secondary btn-sm " onClick={() => setmodalOpenCreateVouhcerBulk(false)}>Batalkan</button>
                    </div>

                </div>

            </Modal>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}


export default withApollo(PrivateList)

