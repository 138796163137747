import { JOIN_TO_KONSUL_ROOM, JOIN_TO_DOKTER_PRIVATE_ROOM, MESSAGE_FROM_ADMIN_TO_GUEST, MESSAGE_FROM_DOKTER_TO_USER } from "../../constants/socket";
import { socket } from "./index";
export const addClientToQueue = () => {
  socket.emit('addClientIdToQueue');
};
export const getQueueLength = () => {
  socket.emit('queueLengthToSocket');
};
export const removeUserFromQueue = () => {
  socket.emit('removeUserFromQueue');
};
export const sendNewMessage = (message) => {
  console.log(MESSAGE_FROM_ADMIN_TO_GUEST)
  socket.emit(MESSAGE_FROM_ADMIN_TO_GUEST, message);
};
export const joinDokterPrivateRoom = (data) => {
  console.log(JOIN_TO_DOKTER_PRIVATE_ROOM)
  socket.emit(JOIN_TO_DOKTER_PRIVATE_ROOM, data);
};
export const sendNewMessageDokterToUser = (message) => {
  console.log(MESSAGE_FROM_ADMIN_TO_GUEST)
  console.log(message)
  socket.emit(MESSAGE_FROM_DOKTER_TO_USER, message);
};

export const joinKonsulRoom = (data) => {
  console.log(JOIN_TO_KONSUL_ROOM)
  socket.emit(JOIN_TO_KONSUL_ROOM, data);
};

export const updateSlotKonsul = (data) => {
  console.log("UPDATE_KONSUL_SLOT")
  socket.emit("UPDATE_KONSUL_SLOT", data);
};





