import React, { Component, Fragment, useState,useEffect } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Edit, Trash2 } from 'react-feather'
import data from '../../../assets/data/category';
import Datatable from '../../common/datatable';
import { ToastContainer, toast } from 'react-toastify';
import { withApollo } from '../../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { GET_GUDANGS } from '../../../constants/graph';
import { Alert, ModalBody } from 'reactstrap';
import { values } from 'lodash';
const ProductThumb = (props) => {
    // const { loading, data, error } = useQuery(GET_GUDANGS,);
    // const [selectedGudang, setselectedGudang] = useState(null)
    // const [myData, setmyData] = useState()
     const myData = props.data
    const [editStock, seteditStock] = useState(false)
    const [stockGudang, setstockGudang] = useState()
    const [stock, setstock] = useState({})
    const [avaibility, setavaibility] = useState(0)
    const [loading, setLoading] = useState(false)
    const [errorUpdate, seterrorUpdate] = useState(false)

    const handleDelete=async(id)=>{
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/products/${id}`, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: JSON.stringify(newData) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        props.refetch()
        // event.preventDefault();
        // setLoading(false)
        // setloadingupdate(false)
        // let result = await response.json()
        // if (response.ok)
        //    this.props.closeModal()
        // else
        //     this.setState({submitError:true})
    }

    const handleChange=(e)=>{

        const {value,name,checked}=e.target
        if(name=="avaibility" && checked){
            setavaibility(value)
        }
        
    }
    // useEffect(() => {
    //     setmyData(props.data)
    // }, [props.data])
    async function postData(url = '', data = {}) {
        // Default options are marked with *
         setLoading(true)
props.updateStock({variables:{
    data:data
}}).then(dt=>{
    if(!data.errors){
        setLoading(false)
        seteditStock(false)
        // props.refetch()
        // return data.json();
    }else{
        setLoading(false)
        seterrorUpdate(true)

    }
    
})
        //  fetch(url, {
        //     method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        //     mode: 'cors', // no-cors, *cors, same-origin
        //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     credentials: 'include', // include, *same-origin, omit
        //     headers: {
        //         'Content-Type': 'application/json'
        //         // 'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        //     redirect: 'follow', // manual, *follow, error
        //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //     body: JSON.stringify(data) // body data type must match "Content-Type" header
        // }).then(data=>{
            // if(data.status==200){
            //     setLoading(false)
            //     seteditStock(false)
            //     props.refetch()
            //     return data.json();
            // }else{
            //     setLoading(false)
            //     seterrorUpdate(true)

            // }
             
        // }).catch(err=>{

        // });
       
        // return response.json(); // parses JSON response into native JavaScript objects
    }
    
    // if(!data)return <div>Loading</div>
    return (
        <Fragment>
            <div className="col-xl-3 col-sm-6" >
                <div className="text-center">
                    <div className="products-admin">
                        <div className="card-body product-box">
                            <div className="img-wrapper">
                                <div className="lable-block">
                                    {(myData.tag === 'new') ? <span className="lable3">{myData.tag}</span> : ''}
                                    {(myData.discount === 'on sale') ? <span className="lable4">{myData.discount}</span> : ''}
                                </div>
                                <div className="front">
                                    <a className="bg-size"><img className="img-fluid blur-up bg-img lazyloaded" src={`${process.env.REACT_APP_IMAGE_HOST}${myData.dokul_product_images[0]?.image_url}`} /></a>
                                    <div className="product-hover">
                                        <ul>
                                            <li>
                                                <Link
                                                    to={`/products/edit/${myData.id}`}
                                                    className='btn'>
                                                    <Edit className="editBtn" />
                                                </Link>
                                            </li>
                                            <li>
                                                <button className="btn" type="button" onClick={()=>handleDelete(myData.id)}>
                                                    <Trash2 className="deleteBtn" />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="product-detail w-100">
                                <h6 className="text-center p-2">{myData.name}</h6>

                                {myData && myData.dokul_products_skus.map((sku, i) =>
                                    <div key={i} className="d-flex flex-row justify-content-between w-100  p-1 bg-light border rounded text-dark">
                                        <div className="p-2 mr-auto">
                                            <b className={sku.dokul_gudang_skus[0]?.avaibility == 2 || (sku.dokul_gudang_skus[0]?.avaibility == 3&&sku.dokul_gudang_skus[0]?.stock==0) ? 'text-danger' : 'text-dark'}>{sku.dokul_gudang_skus[0]?.avaibility == 1 ? "InStock" : sku.dokul_gudang_skus[0]?.avaibility == 2 ? "OutStock" : sku.dokul_gudang_skus[0]?.avaibility == 3 ? "Stock : " + sku.dokul_gudang_skus[0]?.stock :sku.dokul_gudang_skus[0]?.avaibility == 4 ? "Preorder Stock : " + sku.dokul_gudang_skus[0]?.stock : ""}</b>
                                            {/* <div className="p-0 text-center p-0 bg-light border rounded text-dark"><button className="btn btn-sm"><i className="fa fa-edit" /></button></div> */}
                                        </div>
                                        <div className="justify-content-end"> <button className="btn btn-sm p-1 m-1" onClick={() => {
                                     
                                            setstock(sku.dokul_gudang_skus[0].stock)
                                            setstockGudang(sku.dokul_gudang_skus[0])
                                            setavaibility(sku.dokul_gudang_skus[0].avaibility)
                                            seteditStock(true)

                                        }}><i className="fa fa-edit" /></button></div>
                                    </div>
                                )}
                                

                                {/* <div className="rating">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div> */}
                                {/* <a> <h6 >{myData.name}</h6></a> */}
                                {/* <hr />
                                                        <div className="d-flex flex-column">
                                                            {myData.dokul_products_skus.map((sku, i) =>
                                                                <div className="p-2 text-center">{sku.name}</div>

                                                            )}

                                                        </div> */}
                                {/* <ul className="ukuran-variant">
                                                            {myData.dokul_products_skus.map((sku, i) =>
                                                                <li key={i} ><span className="p-2 rounded bg-light0">{sku.name}</span></li>
                                                            )}

                                                        </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={editStock} onClose={() => seteditStock(false)} center >
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">Edit Stock</h5>
                </div>
                <div className="modal-body">
                    <div className="d-flex flex-column">
                        <div className="p-2"><input type="radio" name="avaibility" value={1} checked={avaibility==1} onChange={(e)=>handleChange(e)}/> InStock</div>
                        <div className="p-2"><input type="radio" name="avaibility" value={2}  checked={avaibility==2} onChange={(e)=>handleChange(e)}/>  OutStock</div>
                        <div className="p-2"><input type="radio" name="avaibility" value={3}  checked={avaibility==3} onChange={(e)=>handleChange(e)}/>  Manage Stock</div>
                        <div className="p-2"><input type="radio" name="avaibility" value={4}  checked={avaibility==4} onChange={(e)=>handleChange(e)}/>  Preorder</div>
                        {(avaibility==3||avaibility==4)&&<div className="p-2"><input type="number" name="stock" value={stock} onChange={(e)=>setstock(e.target.value)}/> </div>}
                    </div>
                    {errorUpdate&&<Alert color={"danger"}>Update stock gagal, silahkan ulangi</Alert>}

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={async ()=>{

                        let currentGudangsku={...stockGudang}
                        currentGudangsku.stock=stock
                        currentGudangsku.avaibility=avaibility
                        await postData(`${process.env.REACT_APP_API_HOST}/api/products/gudang/sku`,currentGudangsku)



                    }}>
                        {loading?<div className="spinner-border text-dark spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>:"Save"}</button>
                                    <button type="button" className="btn btn-secondary" onClick={() => seteditStock(false)}>Close</button>
                </div>


            </Modal>
        </Fragment>
    )
}
// }


export default withApollo(ProductThumb)

