import React, { useState, useCallback, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
//import datanya from '../../assets/data/physical_list';

import { Edit, Trash2, Search } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, writeQuery, useLazyQuery } from '@apollo/react-hooks';
import CKEditors from "react-ckeditor-component";
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import PRODUKRESULT from './produk.result'
import LAYANANRESULT from './layanan.result'
import IMAGERESULT from './image.result'
import { Badge, Col, CustomInput, FormGroup, Input, Label, ListGroup, ListGroupItem, ListGroupItemHeading, Media, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    removeElements,
    isNode,
    Background,
    updateEdge,
    Controls,
    MiniMap
} from 'react-flow-renderer';
import dagre from 'dagre';
import ButtonEdge from './ButtonEdge'

// import Modal from 'react-responsive-modal';

import initialElements from './initial-element';
import { GET_PRODUCTS, GET_ROBOT_GUIDE, UPDATE_ROBOT_GUIDE, UPDATE_ROBOT_GUIDE2 } from '../../constants/graph';
import './index.css';

const onLoad = (reactFlowInstance) => {
    ////console.log('flow loaded:', reactFlowInstance);
    reactFlowInstance.fitView();
};
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

// In order to keep this example simple the node width and height are hardcoded.
// In a real world app you would use the correct width and height values of
// const nodes = useStoreState(state => state.nodes) and then node.__rf.width, node.__rf.height

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (elements, direction = 'TB') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    elements.forEach((_el) => {

        let el = { ..._el }
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);

            dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
        } else {
            dagreGraph.setEdge(el.source, el.target);
        }
    });

    dagre.layout(dagreGraph);

    return elements.map((_el) => {
        let el = { ..._el }
        if (isNode(el)) {
            // console.log(el)
            //Object.preventExtensions(el);
            const nodeWithPosition = dagreGraph.node(el.id);
            el.targetPosition = isHorizontal ? 'left' : 'top';
            el.sourcePosition = isHorizontal ? 'right' : 'bottom';
            //console.log("==============node")
            //console.log(nodeWithPosition)

            // unfortunately we need this little hack to pass a slightly different position
            // to notify react flow about the change. Moreover we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            el.position = {
                x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        }

        return el;
    });
};

const edgeTypes = {
    buttonedge: ButtonEdge,
};

const OverviewFlow = ({ match, listProduct }) => {
    // const [robotsID, setRobotsID] = useState(1)
    const jenisKulit = ["Kering", "Normal", "Berminyak", "Sensitif"]
    // const kategoriUmur = ["Kurang dari 30 thn", "Lebih dari 30 thn kurang dari 45 thn", "Lebih dari 45 thn"]
    const jenisKelamin = ["Pria", "Wanita"]
    const status = ["Single",
        "Single akan menikah dalam waktu dekat",
        "Menikah, tidak menggunakan kontrasepsi",
        "Menikah, dan sudah kontrasepsi kurang dari 3 tahun",
        "Menikah, dan sudah kontrasepsi lebih dari 3 tahun",
        " Menikah, dan berencana hamil dalam waktu dekat",
        " Menikah dan menyusui"]
    const kategoriUmur = ["Kurang dari 18 thn", " 18 thn sampai dengan 30 thn", "Lebih dari 30 thn kurang dari 45 thn", "Lebih dari 45 tahun"]
    const statusPria = ["Single", "Menikah"]
    const listMasalah = [
        "komedo",
        "Jerawat milia/kecil/beruntus",
        "Jerawat besar/bernanah/meradang",
        "Flek hitam",
        "Flek agak abu²",
        "Flek hitam merah",
        "Bekas jerawat merah",
        "Bekas jerawat hitam/gelap",
        "Bekas jerawat bopeng",
        "Wajah mudah merah",
        "Kulit kendur/kerutan"
    ]
    const scoringRange = [
        { from: 30, to: 34, name: "Sensitif" },
        { from: 35, to: 59, name: "Kering" },
        { from: 60, to: 79, name: "Normal" },
        { from: 80, to: 100, name: "Berminyak" },
    ]
    const client = useApolloClient()
    const [file, setFile] = useState()
    const [picture, setpicture] = useState("/assets/images/noimage.png")
    const [editedResDetail, seteditedResDetail] = useState([])
    const _handleImgAdd = (e, i) => {
        e.preventDefault();
        // setimageError(false)assets
        // let _product = { ...product }
        let reader = new FileReader();
        let file = e.target.files[0];
        // let _pictures = [...pictures]
        //const jmlPic = pictures.length;
        // const newPic = { image_url: "", product_id: product.id }
        // if (product.id)
        //     files.push({ idx: i, file: file });
        // else
        // files.push(file);
        reader.onloadend = () => {
            setpicture(reader.result)
            // _pictures.push(newPic)
            // _product.dokul_product_images = _pictures
            setFile(file)
            // setProduct(_product)
            // setFiles(files)

        }
        reader.onerror = (e) => {
            //console.log("error" + e)
        }
        reader.readAsDataURL(file)
    }
    if (match.params.name) {
        // //console.log(match.params.page)
        ////console.log(match.params.name)



    }
    const [defPos, setdefPos] = useState({ x: -2200, y: -4000 })

    const [filterProduct, setfilterProduct] = useState("")
    const [openModalProduct, setModalProduct] = useState(false)

    const [loadingupdate, setloadingupdate] = useState(false)
    const [tipeJawabanQuestion, settipeJawabanQuestion] = useState()


    const { loading, data, error, fetchMore, refetch } = useQuery(GET_ROBOT_GUIDE, { variables: { name: match.params.name }, fetchPolicy: "network-only" });
    const updateCache = (cache, { data: dUpdate }) => {

        // Fetch the todos from the cache
        // const existingTodos = cache.readQuery({
        //   query: GET_ORDERS
        // });
        let updatedData = { ...dUpdate.updateRobot }
        updatedData.id = data.robots.id
        updatedData.name = data.robots.name

        //  //console.log("HOLAAAAAA")
        //     //console.log(dUpdate)
        //     const fragmentResult = cache.writeFragment({
        //         id: `Robot:1`,
        //         fragment: gql`
        //       fragment robot on Robot{
        //         question
        //         answer
        //         result
        //       }
        //     `,
        //         data: updatedData
        //         //  {
        //         //   __typename: 'Robot',
        //         //   answer: data.updateRobot.answer,
        //         //   question:data.updateRobot.answer,
        //         //   result:data.updateRobot.result
        //         // }
        //     });


        // //console.log(fragmentResult)
        // Add the new todo to the cache
        // const newTodo = data.insert_todos.returning[0];
        // cache.writeQuery({
        //   query: GET_ROBOT_GUIDE,
        //   data: data.updateRobot
        // });
    };
    const [updateAnswer, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(UPDATE_ROBOT_GUIDE, { update: updateCache });
    const [updateAnswer2, { loading: mutationLoading2, error: mutationError2, called: mutationCalled2 },] = useLazyQuery(UPDATE_ROBOT_GUIDE2);
    const [selectedId, setSelectedId] = useState(1)
    const [pilihanNode, setPilihanNode] = useState(1)
    const [listAnswer, setlistAnswer] = useState([])
    const [textNode, setTextNode] = useState("")
    const [textDescription, settextDescription] = useState("")

    const [modalOpen, setModalOpen] = useState(false)
    const getNodeId = (type) => `${type}${+new Date()}`;
    const [elements, setElements] = useState(initialElements);
    const [robots, setRobots] = useState([]);
    const [jsonData, setjsonData] = useState([])
    const [tampilGambar, settampilGambar] = useState(false)
    const [tipeJawaban, settipeJawaban] = useState(1)
    const [isTopic, setisTopic] = useState(0)
    const [produkJawaban, setprodukJawaban] = useState([])
    const [scoreTambahan, setscoreTambahan] = useState()
    const [izinUpload, setizinUpload] = useState(1)
    const [listFilter, setlistFilter] = useState({ jk: [], ku: [], jku: [], status: [], questionAnswer: [] })
    const onElementsRemove = (elementsToRemove) =>
        setElements((els) => removeElements(elementsToRemove, els));
    const onConnect = (params) => {
        console.log(params)

        if (params.source.includes("ans") && params.target.includes("ans"))
            return

        if (!params.source.includes("ans") && !params.target.includes("ans")) {
            setRobots((els) => {

                //console.log(params)
                let _els = [...els]
                let curTarget = _els.findIndex(_cs => _cs.id == params.source)
                _els[curTarget].data.target_isresult = params.target.includes("res") ? 1 : 0

                _els[curTarget].data.target_rq = params.target

                params.id = getNodeId("EDGE")
                params.animated = true
                params.type = 'straight'
                params.label = "X"
                params.labelStyle = { fill: '#CF0217', fontWeight: 700 }
                params.data = { label: "default label", img_url: `${process.env.REACT_APP_IMAGE_HOST}/assets/images/noimage.png` }

                return addEdge(params, _els)


            });
        } else {

            setRobots((els) => {

                //console.log(params)
                let _els = [...els]
                let curSource = _els.findIndex(_cs => _cs.id == params.source)
                let curTarget = _els.findIndex(_cs => _cs.id == params.target)
                if (_els[curTarget].type == "default" && _els[curTarget].id.includes("ans")) {
                    _els[curTarget].sources = params.source
                    //console.log(_els[curTarget])
                }
                if (_els[curSource].type == "default" && _els[curSource].id.includes("ans")) {
                    _els[curSource].targets = params.target
                    //console.log(_els[curSource])
                }
                params.id = getNodeId("EDGE")
                params.animated = true
                params.type = 'straight'
                params.label = "X"
                params.labelStyle = { fill: '#CF0217', fontWeight: 700 }
                params.data = { label: "default label", img_url: `${process.env.REACT_APP_IMAGE_HOST}/assets/images/noimage.png` }

                return addEdge(params, _els)


            });

        }
        postData("", params)
    }



    // gets called after end of edge gets dragged to another source or target
    const onEdgeUpdate = (oldEdge, newConnection) => {
        // console.log(newConnection)
        // console.log(oldEdge)

        setRobots((els) => {
            //let cur = {...els.find(e => e.id.includes(oldEdge.id))}
            let idx = els.findIndex(e => e.id == oldEdge.id)
            oldEdge.source = newConnection.source
            oldEdge.target = newConnection.target
            els[idx] = oldEdge
            return [...els]
        });
        postData("", { ...newConnection, id: "EDGE", isUpdate: true })
        // setRobots((els) => {
        //     updateEdge(oldEdge, newConnection, els)

        // });

    }



    const onAdd = useCallback((text, pos, pic, tampilGambar, listFilter, tipeJawabanQuestion) => {

        const newNode = parseQuestion({
            id: getNodeId("qu"),
            data: { label: text, img_url: picture, show_image: tampilGambar ? 1 : 0, filter: listFilter },
            position: pos
            // position: {
            //     x: -2200,
            //     y: -100,
            // },
        });
        //console.log(newNode)
        postData("", { id: newNode.id, position: newNode.position, isNew: true, data: { tipe_jawaban: tipeJawabanQuestion, urutan: 0, label: text, img_url: picture, show_image: tampilGambar ? 1 : 0, filter: listFilter } })
        setRobots((els) => els.concat(newNode));
    }, [setRobots]);

    const onDelete = useCallback((node) => {
        const _node = { ...node, isDeleted: true }
        // console.log(node)
        postData("", _node)
        // let _curRobots=[...robots]
        // _curRobots.splice(_deletedNodeId,1)

        // //console.log(robots)
        // //console.log(node)

        setRobots((els) => {
            let _deletedNodeId = els.filter(_rb => _rb.id != node.id)
            // _deletedNodeId = _deletedNodeId.filter(_rb => _rb.target != node.id)
            // _deletedNodeId = _deletedNodeId.filter(_rb => _rb.source != node.id)
            // //console.log(els)
            // //console.log(_deletedNodeId)
            // els.splice(_deletedNodeId,1)
            // //console.log(els)
            return [..._deletedNodeId]
        });
    }, [setRobots]);
    const onCopy = useCallback((node) => {

        const newFilter = {
            jk: node.data.filter.jk.split(","),
            jku: node.data.filter.jku.split(","),
            status: node.data.filter.status.split(","),
            ku: node.data.filter.ku.split(",")
        }
        let newNode = parseQuestion({
            id: getNodeId("qu"),
            data: node.data,
            isCopy: true,
            filter: newFilter,
            position: {
                x: node.position.x + 300,
                y: node.position.y + 50,
            },
        });
        postData("", { ...newNode, isCopy: true, data: { ...newNode.data, urutan: 0 } })
        setRobots((els) => els.concat(newNode));
    }, [setRobots]);

    const onCopy2 = useCallback((node) => {

        const idnode = getNodeId("res")
        //change product to new node id
        let newData = { ...node.data }
        newData.dokul_robot_result_details = newData.dokul_robot_result_details.map(x => {
            return {
                ...x,
                result_id: idnode.substring(3)
            }
        })


        let newNode = parseResult2({
            id: idnode,
            data: newData,
            position: {
                x: node.position.x + 300,
                y: node.position.y + 50,
            },
        });
        setRobots((els) => els.concat(newNode));
        let newPostData = { ...newNode.data }

        newPostData.dokul_robot_result_details = newData.dokul_robot_result_details.map(x => {
            return { ...x, id: null }
        })
        postData("", { ...newNode, isCopy: true, data: newPostData })

    }, [setRobots]);

    const onCopy3 = useCallback((node) => {

        const idnode = getNodeId("ans")
        //change product to new node id
        let newData = { ...node.data }
        newData.produkJawaban = newData.produkJawaban.map(x => {
            return {
                ...x,
                answer_id: idnode.substring(3),
                result_value: x.product_id
            }
        })

        let newNode = parseAnswer({
            id: idnode,
            data: newData,
            sources: 0,
            targets: 0,
            type: 'default',
            position: {
                x: node.position.x + 300,
                y: node.position.y + 50,
            },
        });
        setRobots((els) => els.concat(newNode));

        let newPostData = { ...newNode.data }

        newPostData.productJawaban = newData.produkJawaban.map(x => {
            return { ...x, id: null }
        })
        postData("", { ...newNode, isCopy: true, data: newPostData })

    }, [setRobots]);

    const onEdit = useCallback(async (dt) => {
        const postResult = await postData("", { ...dt, isUpdate: true })
        //hapus product dengn null id, ganti dengan yang result
        dt.data.dokul_robot_result_details = dt.data.dokul_robot_result_details.reduce((prev, cur) => {
            if (cur.id)
                return [...prev, cur]
            else
                return [...prev, { ...cur, id: "fake" }]
        }, [])
        // dt.data.dokul_robot_result_details = dt.data.dokul_robot_result_details.concat(postResult[0].dokul_robot_result_details)

        setRobots((els) => {
            // let cur = els.find(e => e.id.includes(dt.id))
            let idx = els.findIndex(e => e.id == dt.id)
            dt.position = els[idx].position

            let pr = parseResult2(dt)
            // //console.log(cur)
            els.splice(idx, 1)
            // els[idx] = pr
            return [...els, pr]
        });
    }, [setRobots]);

    const onUpdateResultDetail = useCallback((dt) => {

        postData("", dt)
        setRobots((els) => {
            let cur = els.find(e => e.id.includes(dt.id))
            let idx = els.indexOf(cur)
            dt.position = cur.position
            //parsing
            let pr = parseResult2(dt)
            els[idx] = pr
            return [...els]
        });
    }, [setRobots]);
    const onUpdateAnswerProduk = useCallback((dt) => {


        setRobots((els) => {
            let cur = els.find(e => e.id.includes(dt.id))
            let idx = els.indexOf(cur)
            dt.position = cur.position
            //parsing
            let pr = parseAnswer(dt)
            els[idx] = pr
            return [...els]
        });
    }, [setRobots]);


    const onAdd2 = useCallback((text, pos) => {
        //console.log(robots)
        // console.log(pos)
        const newNode = parseResult2({
            id: getNodeId("res"),
            type: 'output',
            data: { label: text, dokul_robot_result_details: [], show_image: 0, approvaltype: 1 },
            position: pos
        });
        postData("", { ...newNode, isNew: true })
        setRobots((els) => els.concat(newNode));
    }, [setRobots]);

    const onAdd22 = useCallback((text, pos) => {
        // console.log(defPos)
        const newNode = parseResult2({
            id: getNodeId("res"),
            type: 'output',
            data: { label: text, dokul_robot_result_details: [], show_image: 0, approvaltype: 2 },
            position: pos,
        });
        postData("", { ...newNode, isNew: true })
        setRobots((els) => els.concat(newNode));
    }, [setRobots]);

    const onAdd3 = useCallback((text, pos, pic, tampilGambar, listFilter, scoreTambahan, tipeJawaban, produkJawaban, izinUpload, isTopic, tipeJawabanQuestion, textDescription) => {
        //console.log(robots)
        let newData = {}
        newData.img_url = pic
        newData.show_image = tampilGambar ? 1 : 0
        newData.label = text
        newData.filter = listFilter
        newData.score = scoreTambahan ? scoreTambahan : 0
        newData.tipeJawaban = tipeJawaban
        newData.productJawaban = produkJawaban
        newData.izinUpload = izinUpload
        newData.produkJawaban = produkJawaban
        newData.description = textDescription
        newData.is_topic = isTopic

        const newNode = {
            id: getNodeId("ans"),
            sources: 0,
            targets: 0,
            isNew: true,
            type: 'default',
            data: newData,
            position: pos,
        }
        postData("", newNode)
        setRobots((els) => els.concat(parseAnswer(newNode)));
    }, [setRobots]);

    const onEdgeEnter = (event, edge) => {
        //console.log(edge)
        //console.log(event)
        //console.log(robots)
        const _curEdge = robots.find(_ed => _ed.id == edge.id)
        // setTextNode(_curEdge.data.label)
        // setpicture(_curEdge.data.img_url ? _curEdge.data.img_url : "")
        if (edge.source && edge.target) {
            // setSelectedId(edge.id)
            // setPilihanNode(3)
            // setModalOpen(true)
            onDelete(_curEdge)
        }

    }
    const onDragStop = (event, node) => {

        //    console.log(node)
        let _robots = [...robots]
        let curnode = _robots.find(rb => rb.id == node.id)
        let _node = { ...curnode }
        let idx = _robots.indexOf(curnode)
        _node.position = node.position
        _robots[idx] = _node
        setRobots(_robots)

        let rubahPos = { ...node, isUpdate: true }
        // console.log(rubahPos)
        // rubahPos.data.label=rubahPos.data.textLabel
        setdefPos({
            x: rubahPos.position.x + 300,
            y: rubahPos.position.y + 50,
        })
        rubahPos.data.productJawaban = rubahPos.data.produkJawaban
        postData("", rubahPos)


    }
    const onTextChange = useCallback((id, text, pic, _imageshow, filter, score, tipe, productJawaban, izinUpload, isTopic, tipeJawabanQuestion, textDescription) => {
        //console.log(_imageshow)
        // let curElements = [...robots]
        // let oldPart = curElements.find(oel => oel.id == id)
        // let idx = curElements.indexOf(oldPart)
        // let newPart = { ...oldPart }
        // let newData = { ...newPart.data }


        // newData.label = text
        // newPart.data = newData
        // newPart.label = text
        // //console.log(newPart)
        // if (newPart.type == undefined || newPart.type == "input") {
        //     if (newPart.source && newPart.target)
        //         curElements[idx] = newPart
        //     else
        //         curElements[idx] = parseQuestion(newPart)

        // } else {
        //     curElements[idx] = parseResult2(newPart)
        // }


        let newPart = {}
        let newData = {}



        setRobots((els) => {
            //let cur = {...els.find(e => e.id.includes(oldEdge.id))}
            let idx = els.findIndex(e => e.id == id)
            newPart = { ...els[idx] }
            newData = { ...newPart.data }


            newData.label = text
            newData.img_url = pic
            newData.show_image = _imageshow ? 1 : 0
            newData.filter = filter
            newData.score = score
            newData.type = tipe
            newData.productJawaban = productJawaban
            newData.is_upload = izinUpload
            newData.is_topic = isTopic
            newData.tipe_jawaban = tipeJawabanQuestion
            newData.description = textDescription

            newPart.data = newData


            newPart.label = text
            newPart.filter = filter
            newPart.score = score
            newPart.tipeJawaban = tipe
            newPart.productJawaban = productJawaban
            newPart.izinUpload = izinUpload
            // console.log(newPart)

            // testPostCllient(newPart)
            // console.log(newData)
            if (newPart.type == undefined || newPart.type == "buttonedge" || newPart.type == "input" || newPart.type == "default") {
                if (newPart.sources && newPart.targets) {
                    newPart.source = newPart.sources
                    newPart.target = newPart.targets
                    els[idx] = parseAnswer(newPart)
                }
                else
                    els[idx] = parseQuestion({ ...newPart })

            } else {
                els[idx] = parseResult2(newPart)
            }

            // els[idx] = newPart
            return [...els]
        });
        testPostCllient(newPart)
        // setRobots(curElements);
    }, [setRobots]);

    const testPostCllient = (newPart) => {
        postData("", { ...newPart, isUpdate: true })
    }
    const graphStyles = { width: window.innerWidth, height: window.innerHeight - 80 };
    const onLayout = useCallback(
        (direction) => {
            //console.log("onlay")
            //console.log(robots)
            const layoutedElements = getLayoutedElements(robots, direction);
            setRobots(layoutedElements);
        },
        [robots]
    );
    // const _mapEdgeIn=(arrEdge)=>{
    //     const promises = arrEdge.map(async (myValue) => {
    //         return {
    //             id: "my_id",
    //             myValue: await service.getByValue(myValue)
    //         }
    //     });
    //     return Promise.all(promises);
    // }
    useEffect(() => {
        // //console.log(robots)
        //  console.log(data)

        if (data && listProduct) {
            setRobots([])
            let arrayAns = []

            // setdefPos(data.robots.question[0].position)
            let res = parseResult(data.robots.result)

            let quest = data.robots.question.map(q => {
                // const curAns = data.robots.answer.find(_A => _A.source == q.id)
                //map edge question to question or result
                let _filter = {
                    jk: q.data.filter.jk != null ? q.data.filter.jk.split(",") : [],
                    jku: q.data.filter.jku != null ? q.data.filter.jku.split(",") : [],
                    ku: q.data.filter.ku != null ? q.data.filter.ku.split(",") : [],
                    status: q.data.filter.status != null ? q.data.filter.status.split(",") : []
                }
                let qs = parseQuestion({ ...q, filter: _filter })
                if (q.data.target_rq != null && q.data.target_rq != null) {
                    //console.log("NEWEDGE")
                    let newEdgeQr = {
                        id: "EDGE_TARGETRQ" + q.id,//getNodeId("ANS"),
                        type: 'straight',
                        source: q.id,
                        target: q.data.target_rq,
                        animated: true,
                        labelStyle: { fill: '#CF0217', fontWeight: 700 },
                        label: 'X'
                    }
                    // postData("",{...newEdgeQr})
                    // if (q.data.target_rq.length > 10)
                    //     arrayAns.push(newEdgeQr)

                }

                return qs

            })




            data.robots.answer.forEach(a => {
                let newEdg = {
                    id: "EDGE_SOURCE" + a.source + a.id,//getNodeId("ANS"),
                    type: 'straight',
                    source: a.source,
                    target: a.id,
                    animated: true,
                    labelStyle: { fill: '#CF0217', fontWeight: 700 },
                    label: "X"
                }
                // postData("",{...newEdg})
                // if (a.source.length > 10)
                //     arrayAns.push(newEdg)
                let newEdg2 = {
                    id: "EDGE_TARGET" + a.id,//getNodeId("ANS"),
                    type: 'straight',
                    source: a.id,
                    target: a.target,
                    animated: true,
                    labelStyle: { fill: '#CF0217', fontWeight: 700 },
                    label: 'X'
                }
                // postData("",{...newEdg2})
                // if (a.target.length > 10)
                //     arrayAns.push(newEdg2)
            })

            let arrayEdge = data.robots.edge.map(_edg => {
                return {
                    id: "EDGE_" + _edg.id,//getNodeId("ANS"),
                    type: 'straight',
                    source: _edg.source,
                    target: _edg.target,
                    animated: true,
                    labelStyle: { fill: '#CF0217', fontWeight: 700 },
                    label: 'X'
                }
            })



            let ans = data.robots.answer.map(a => {




                let _a = parseAnswer(a)
                // let _a = { ...a }
                // _a.type = 'buttonedge'
                // _a.data={label:a.data.label,img_url:`${process.env.REACT_APP_IMAGE_HOST}${picture}`}

                return _a
            })
            //const layoutedElements = getLayoutedElements(quest.concat(data.robots.answer, res), 'TB');
            setRobots(quest.concat(arrayAns, ans, res, arrayEdge))
            //for update to service ned pure json

            // setjsonData(data.robots);

        }
    }, [data, listProduct])

    // if(!listProduct) return "Loading"

    const handleCopyTree = (idnode) => {
        let needToCopy = [idnode]
        let robots_ = []
        setRobots(els => {
            robots_ = els
            return els
        })
        robots_.forEach(rb => {

            let newEdge = {}
            if (rb.id.includes("EDGE") && needToCopy.some(x => x.id == rb.source)) {
                newEdge = rb
                needToCopy.push(rb)
            }
            //cari target dari edge
            if (newEdge.target) {
                let newTarget = robots_.find(nt => nt.id == newEdge.target)
                needToCopy.push(newTarget)
                //ambil edge dari node hasil
                let newEdges = robots_.filter(nt => nt.source == newTarget.id)
                console.log(newEdges)
                needToCopy = needToCopy.concat(newEdges)

            }


        })

        // robots_.forEach(rb=>{

        //     if(needToCopy.some(x=> x.target==rb.id)) needToCopy.push(rb)


        // })

        // robots_.filter(x=>x.id.includes("EDGE")).forEach(rb=>{

        //     if(needToCopy.some(x=> x.id==rb.source && !needToCopy.some(y=>y.id==rb.id) )) needToCopy.push(rb)

        // })

        console.log(needToCopy)
    }

    const parseAnswer = (a) => {
        let pos = a.position
        //unutk posisi
        if (!pos) {
            pos = { x: 0, y: 5 }
            const curSource = data.robots.question.find(_q => _q.id == a.source)


            if (curSource) {
                pos.x = curSource.position.x + 10
                pos.y = curSource.position.y + 10

            }
        }
        return {
            id: a.id,
            type: 'default',
            style: { width: 250, fontSize: 11, textAlign: "left", borderColor: "#FFBC58" },
            sources: a.source,
            targets: a.target,
            data: {
                ...a.data,
                textLabel: a.data.label,
                img_url: a.data.img_url,
                show_image: a.data.show_image,
                label: (
                    <Media onClick={() => {

                    }}>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row bg-warning justify-content-between">
                                <div className="mt-2 mb-2">
                                    <button className="btn btn-xs btn-light" onClick={() => {
                                        handleCopyTree(a)
                                    }}>copy tree</button>
                                </div>
                                <div className=" text-end"><Badge color="danger" className="bg-red pointer m-2 border-light border" style={{ cursor: "pointer" }} onClick={() => {
                                    onDelete(a)

                                }}><i className="icofont icofont-close text-white " /></Badge>
                                    <Badge color="success" className="bg-success pointer m-2 border-light border" style={{ cursor: "pointer" }} onClick={() => {
                                        onCopy3(a)

                                    }}><i className="icofont icofont-copy text-white " /></Badge>
                                    <Badge color="info" className="bg-info pointer m-2 border-light border" style={{ cursor: "pointer" }} onClick={() => {
                                        setpicture(a.data.img_url ? a.data.img_url : "")
                                        settampilGambar(a.data.show_image == 1 ? true : false)
                                        setTextNode(a.data.label)
                                        settextDescription(a.data.description)
                                        setSelectedId(a.id)
                                        setisTopic(a.data.is_topic)
                                        settipeJawaban(a.data.type)
                                        setprodukJawaban(a.data.produkJawaban)
                                        setscoreTambahan(a.data.score)
                                        setizinUpload(a.data.is_upload)
                                        setPilihanNode(33)
                                        setModalOpen(true)

                                    }}><i className="icofont icofont-info text-white " /></Badge>

                                </div>
                            </div>
                        </div>
                        {a.data.show_image == 1 && <Media left href="#" style={{ textAlign: "left" }}>
                            <Media left style={{ width: 50 }} src={`${process.env.REACT_APP_IMAGE_HOST}${a.data.img_url}`} alt={a.data.label} />
                        </Media>}
                        <Media body>
                            <Media>
                                {a.data.type == 2 ? <Label className="w-100">Tipe : FREE TEXT</Label> : <Label className="w-100" dangerouslySetInnerHTML={{ __html: a.data.label }} />}
                                {a.data.is_upload == 1 ? <Label className="w-100">Upload: YA</Label> : a.data.is_upload == 2 ? <Label className="w-100">Upload: TIDAK</Label> : <Label className="w-100">Upload: WAJIB</Label>}
                                <Label className="w-100">Score: {a.data.score}</Label>
                                <Label className="w-100 border-bottom">Produk rekomendasi :</Label>
                            </Media>

                            {a.data.produkJawaban.map((pj, idxpj) => <PRODUKRESULT
                                key={idxpj + "ans"}
                                dataProduct={listProduct?.find(_p => _p.id == pj.product_id)} prodId={pj.product_id} onDetailDelete={async () => {
                                    let curresult = { ...a }
                                    let _data = { ...curresult.data }
                                    let produkJawaban = [..._data.produkJawaban]

                                    // let _resdets = [..._data.dokul_robot_result_details]
                                    // let _curresdet = _resdets.find(r => r.id == rdet.id)
                                    // let idxresdet = _resdets.findIndex(r => r.id == rdet.id)
                                    const idxdeletedProduct = produkJawaban.findIndex(cp => cp.product_id == pj.product_id)
                                    const deletedProduct = produkJawaban[idxdeletedProduct]
                                    await postData("", { ...deletedProduct, isDeletedProduct: true, id: "ans" })
                                    produkJawaban.splice(idxdeletedProduct, 1)
                                    _data.produkJawaban = produkJawaban
                                    curresult.data = _data
                                    // console.log(rdet.id)
                                    // console.log(rdet.result_value)
                                    // let robres=data.robots.result.find(roid=>roid.id==rstl.id)
                                    // _resdets.splice(idxresdet, 1)
                                    // _data.dokul_robot_result_details = _resdets
                                    // curresult.data = _data
                                    onUpdateAnswerProduk(curresult)

                                }} />)}


                        </Media>
                    </Media>
                    //     <div className="my-button" onClick={()=>{
                    //         setpicture(a.data.img_url ? a.data.img_url : "")
                    //             setTextNode(a.data.label)
                    //             setSelectedId(a.id)
                    //             setPilihanNode(3)
                    //             setModalOpen(true)
                    //     }}>
                    //     <img style={{width:50,alignSelf:"left"}} src={`${process.env.REACT_APP_IMAGE_HOST}${a.data.img_url}`} />
                    //     {a.data.label}
                    // </div>
                )
            },
            position: pos
        }
    }

    const parseResult = (resultdata) => {
        return resultdata.map(rstl => {

            return parseResult2(rstl)

        })
    }
    const parseResult2 = (rstl) => {
        // //console.log(rstl)
        //console.log(rstl)
        return {
            id: rstl.id,
            type: rstl.type,
            style: { width: 250, fontSize: 11, borderColor: rstl.data.approvaltype == 1 ? "green" : "red" },
            data: {
                textLabel: rstl.data.textLabel ? rstl.data.textLabel : rstl.data.label,
                img_url: rstl.data.img_url,
                show_image: rstl.data.show_image,
                dokul_robot_result_details: rstl.data.dokul_robot_result_details,
                approvaltype: rstl.data.approvaltype,
                label: (
                    <>

                        <div className="d-flex flex-column ">
                            <div className={rstl.data.approvaltype == 1 ? 'bg-success d-flex flex-row justify-content-between' : 'bg-red d-flex flex-row justify-content-between'}>

                                <div className="mt-2 mb-2">
                                    <button className="btn btn-xs btn-light">copy tree</button>
                                </div>
                                <div className="p-2 text-end"><Badge color="danger" className="bg-red pointer m-2 border-light border" style={{ cursor: "pointer" }} onClick={() => {
                                    onDelete(rstl)

                                }}><i className="icofont icofont-close text-white " /></Badge>
                                    <Badge color="success" className="bg-success pointer m-2 border-light border" style={{ cursor: "pointer" }} onClick={() => {
                                        onCopy2(rstl)

                                    }}><i className="icofont icofont-copy text-white " /></Badge>
                                    <Badge color="info" className="bg-info pointer m-2 border-light border" style={{ cursor: "pointer" }} onClick={() => {
                                        settampilGambar(rstl.data.show_image == 1 ? true : false)
                                        setpicture(rstl.data.img_url ? rstl.data.img_url : "/assets/images/noimage.png")
                                        setTextNode(rstl.data.label)
                                        setSelectedId(rstl.id)
                                        seteditedResDetail(rstl.data.dokul_robot_result_details)
                                        setPilihanNode(32)

                                        setModalOpen(true)

                                    }}><i className="icofont icofont-info text-white " /></Badge>

                                </div>
                            </div>
                            {rstl.data.show_image == 1 && <div className="p-2"><img src={`${process.env.REACT_APP_IMAGE_HOST}${rstl.data.img_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" /></div>}

                            <div className="p-2"><div dangerouslySetInnerHTML={{ __html: rstl.data.textLabel ? rstl.data.textLabel : rstl.data.label }} /></div>
                            {rstl.data.dokul_robot_result_details.map((rdet, rdetidx) =>

                                rdet.type == 1 ? <PRODUKRESULT key={rdetidx + "res"} dataProduct={listProduct?.find(_p => _p.id == rdet.result_value)} prodId={rdet.result_value} onDetailDelete={async () => {
                                    let curresult = { ...rstl }
                                    let _data = { ...curresult.data }
                                    let _resdets = [..._data.dokul_robot_result_details]
                                    // let _curresdet = _resdets.find(r => r.id == rdet.id)
                                    let idxresdet = _resdets.findIndex(r => r.id == rdet.id)
                                    // console.log(idxresdet)
                                    // console.log(rdet.id)
                                    // console.log(rdet.result_value)
                                    // let robres=data.robots.result.find(roid=>roid.id==rstl.id)
                                    const deletedProduct = _resdets[idxresdet]
                                    await postData("", { ...deletedProduct, isDeletedProduct: true, id: "res" })
                                    _resdets.splice(idxresdet, 1)
                                    _data.dokul_robot_result_details = _resdets
                                    curresult.data = _data
                                    // onUpdateAnswerProduk(curresult)
                                    // onUpdateResultDetail(curresult)

                                }} /> : rdet.type == 2 ? <LAYANANRESULT key={rdetidx} prodId={rdet.result_value} onDetailDelete={() => {
                                    let curresult = { ...rstl }
                                    let _data = { ...curresult.data }
                                    let _resdets = [..._data.dokul_robot_result_details]
                                    let _curresdet = _resdets.find(r => r.id == rdet.id)
                                    let idxresdet = _resdets.indexOf(_curresdet)

                                    // let robres=data.robots.result.find(roid=>roid.id==rstl.id)
                                    _resdets.splice(idxresdet, 1)
                                    _data.dokul_robot_result_details = _resdets
                                    curresult.data = _data
                                    onUpdateResultDetail(curresult)

                                }} /> : rdet.type == 4 ? <IMAGERESULT key={rdetidx} img_url={rdet.result_desc} onDetailDelete={() => {
                                    let curresult = { ...rstl }
                                    let _data = { ...curresult.data }
                                    let _resdets = [..._data.dokul_robot_result_details]
                                    let _curresdet = _resdets.find(r => r.id == rdet.id)
                                    let idxresdet = _resdets.indexOf(_curresdet)

                                    // let robres=data.robots.result.find(roid=>roid.id==rstl.id)
                                    _resdets.splice(idxresdet, 1)
                                    _data.dokul_robot_result_details = _resdets
                                    curresult.data = _data
                                    onUpdateResultDetail(curresult)

                                }} /> : <p key={rdetidx}></p>


                            )}

                            {/* <span className="bg-success p-1 rounded" style={{ cursor: "pointer" }} onClick={() => {
                                // let _idxcurresult = data.robots.result.indexOf(rstl)
                                // let _results = [...data.robots.result]
                                // let curresult = { ...rstl }
                                // let _data = { ...curresult.data }
                                // let _resdet = [..._data.dokul_robot_result_details]

                                // _data.dokul_robot_result_details = _resdet
                                // _data.label="wkwkwkwkwkwk"
                                // curresult.data = _data
                                // onUpdateResultDetail(curresult)
                                // settampilGambar(rstl.data.show_image==1?true:false)
                                // setpicture(rstl.data.img_url ? rstl.data.img_url : "")
                                // setTextNode(rstl.data.label)
                                // setSelectedId(rstl.id)
                                // setPilihanNode(3)
                                // setModalOpen(true)

                            }}><i className="icofont icofont-edit" /> label</span> */}
                            {/* <span className="bg-success p-1 mt-1 rounded" style={{ cursor: "pointer" }} onClick={() => {
                                // let _idxcurresult = data.robots.result.indexOf(rstl)
                                // let _results = [...data.robots.result]
                                // let curresult = { ...rstl }
                                // let _data = { ...curresult.data }
                                // let _resdet = [..._data.dokul_robot_result_details]

                                // _data.dokul_robot_result_details = _resdet
                                // _data.label="wkwkwkwkwkwk"
                                // curresult.data = _data
                                // onUpdateResultDetail(curresult)
                                setpicture("/assets/images/noimage.png")
                                setSelectedId(rstl.id)
                                setPilihanNode(4)
                                setModalOpen(true)

                            }}><i className="icofont icofont-plus" /> Image</span> */}
                            <span className="bg-info p-1 mt-1  rounded" style={{ cursor: "pointer" }} onClick={() => {
                                seteditedResDetail([])
                                setSelectedId(rstl.id)
                                setlistFilter({ jk: [], ku: [], jku: [], status: [], questionAnswer: [] })
                                setPilihanNode(32)
                                setModalProduct(true)

                                let _listEdge1 = []
                                let _listAnswer = []
                                let _listResult = []
                                const curEdge = data.robots.edge.find(x => x.target == rstl.id)
                                _listEdge1.push(curEdge)
                                data.robots.edge.forEach((p, c) => {
                                    const is_edgerelate = data.robots.edge.find(x => _listEdge1.some(y => y && y.source == x.target) && !_listEdge1.some(y => y.id == x.id))
                                    if (is_edgerelate) _listEdge1.push(is_edgerelate)

                                })
                                _listEdge1.forEach(x => {
                                    if (x.source.includes('ans') && !_listAnswer.some(y => y == x.source)) _listAnswer.push(x.source)
                                    if (x.target.includes('ans') && !_listAnswer.some(y => y == x.target)) _listAnswer.push(x.target)
                                })
                                setlistAnswer(_listAnswer)
                                //console.log(_listAnswer)

                            }}><i className="icofont icofont-plus" /> produk</span>
                            <span className="bg-primary p-1 mt-1 rounded"><i className="icofont icofont-plus" /> layanan</span>
                        </div>

                    </>),
                dokul_robot_result_details: rstl.data.dokul_robot_result_details


            },
            position: rstl.position
        }


    }
    const parseQuestion = (quest) => {

        return {
            id: quest.id,
            type: quest.type,
            style: { width: 250, fontSize: 11, borderColor: "#00A8FF" },
            data: {
                ...quest.data,
                textLabel: quest.data.label,
                img_url: quest.data.img_url,
                show_image: quest.data.show_image,
                target_isresult: quest.data.target_isresult,
                target_rq: quest.data.target_rq,
                label: (
                    <>

                        <div className="d-flex flex-column ">
                            <div className="d-flex flex-row bg-info justify-content-between">
                                <div className="mt-2 mb-2">
                                    <button className="btn btn-xs btn-light">copy tree</button>
                                </div>
                                <div className="p-2 text-end">
                                    <Badge color="danger" className="bg-red pointer m-2 border-light border" style={{ cursor: "pointer" }} onClick={() => {
                                        onDelete(quest)

                                    }}><i className="icofont icofont-close text-white " /></Badge>
                                    <Badge color="success" className="bg-success pointer m-2 border-light border " style={{ cursor: "pointer" }} onClick={() => {
                                        onCopy(quest)

                                    }}><i className="icofont icofont-copy text-white " /></Badge>
                                    <Badge color="info" className="bg-info pointer m-2 border-light border" style={{ cursor: "pointer" }} onClick={() => {
                                        setpicture(quest.data.img_url ? quest.data.img_url : "")
                                        settampilGambar(quest.data.show_image == 1 ? true : false)
                                        setTextNode(quest.data.label)
                                        setSelectedId(quest.id)
                                        setlistFilter(quest.filter)
                                        settipeJawabanQuestion(quest.data.tipe_jawaban)
                                        setPilihanNode(31)
                                        setModalOpen(true)

                                    }}><i className="icofont icofont-info text-white " /></Badge>
                                </div>
                            </div>
                            {quest.data.show_image == 1 && <div className="p-2"><img src={`${process.env.REACT_APP_IMAGE_HOST}${quest.data.img_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" /></div>}

                            <div className="p-2"><div dangerouslySetInnerHTML={{ __html: quest.data.label }} /></div>

                            {/* <span className="bg-success p-1 rounded" style={{ cursor: "pointer" }} onClick={() => {
                                // let _idxcurresult = data.robots.result.indexOf(rstl)
                                // let _results = [...data.robots.result]
                                // let curresult = { ...rstl }
                                // let _data = { ...curresult.data }
                                // let _resdet = [..._data.dokul_robot_result_details]

                                // _data.dokul_robot_result_details = _resdet
                                // _data.label="wkwkwkwkwkwk"
                                // curresult.data = _data
                                // onUpdateResultDetail(curresult)
                                setpicture(quest.data.img_url ? quest.data.img_url : "")
                                settampilGambar(quest.data.show_image == 1 ? true : false)
                                setTextNode(quest.data.label)
                                setSelectedId(quest.id)
                                setPilihanNode(3)
                                setModalOpen(true)

                            }}><i className="icofont icofont-edit" /> label</span> */}

                            <section className="border border-top p-2 mr-2 row">
                                <span className="w-100 col-12">FILTER : </span>
                                {quest.filter && quest.filter.jk && <div className="d-flex flex-row flex-wrap">
                                    {/* <span className="">Jenis kelamin</span> */}
                                    {quest.filter.jk.map((fl, idxfl) =>
                                        <span className="p-1 bg-success rounded m-1" key={idxfl}>{jenisKelamin[fl]}</span>
                                    )}
                                </div>
                                }
                                {quest.filter && quest.filter.status && <div className="d-flex flex-row flex-wrap">
                                    {/* <span className="">Jenis kelamin</span> */}
                                    {quest.filter.status.map((fl, idxfl) =>
                                        <span className="p-1 bg-info rounded m-1" key={idxfl}>{status[fl]}</span>
                                    )}
                                </div>
                                }
                                {quest.filter && quest.filter.jku && <div className="d-flex flex-row flex-no-wrap">
                                    {/* <span className="">Jenis kelamin</span> */}
                                    {quest.filter.jku.map((fl, idxfl) =>
                                        <span className="p-1 bg-warning rounded m-1" key={idxfl}>{jenisKulit[fl]}</span>
                                    )}
                                </div>
                                }
                                {quest.filter && quest.filter.ku && <div className="d-flex flex-row flex-no-wrap">
                                    {/* <span className="">Jenis kelamin</span> */}
                                    {quest.filter.ku.map((fl, idxfl) =>
                                        <span className="p-1 bg-secondary rounded m-1" key={idxfl}>{kategoriUmur[fl]}</span>
                                    )}
                                </div>
                                }



                            </section>


                        </div>

                    </>)


            },
            position: quest.position
        }


    }
    async function postData(url = '', data = {}) {
        // Default options are marked with *
        // setLoading(true)
        // console.log(data)
        if (data.data && data.data.label && data.data.label.props)
            delete data.data.label
        setloadingupdate(true)
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/branch/robotguide2/${match.params.name}`, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        // setLoading(false)
        setloadingupdate(false)
        return response.json(); // parses JSON response into native JavaScript objects
    }


    const handleValidSubmit = async () => {
        if (!file)
            return picture
        let _picture = ""
        // _product.dokul_products_skus = [...sku]
        const formData = new FormData()
        formData.append('uploaded_file', file, `robot_${file.name}`)
        // let _idxcurresult = data.robots.result.findIndex(_dt => _dt.id == selectedId)


        // let _results = [...data.robots.result]
        // let curresult = { ..._results[_idxcurresult] }
        // let _data = { ...curresult.data }
        // let _resdet = [..._data.dokul_robot_result_details]

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
            method: 'POST',
            body: formData
        })
        // .then(response => response.json())
        // .then(data => {

        //     data.d.forEach(ud => {
        //         _picture = `/assets/images/uploads/${ud.filename}`
        //     })

        //     setpicture(_picture)
        //     return _picture

        //     // _resdet.push({
        //     //     result_desc: _picture,
        //     //     result_id: curresult.id.substring(3),
        //     //     result_value: 87,
        //     //     robot_id: curresult.robot_id,
        //     //     type: 4,
        //     // })
        //     // _data.dokul_robot_result_details = _resdet
        //     // curresult.data = _data


        //     // onEdit(curresult)
        //     setFile()
        //     // setModalOpen(false)

        // })
        // .catch(error => {
        //     console.error(error)
        //     // setisLoading(false)
        //     setModalOpen(false)
        // })
        const uploadResult = await response.json()

        //console.log(uploadResult)

        uploadResult.d.forEach(ud => {
            _picture = `/assets/images/uploads/${ud.filename}`
        })
        return _picture
    }
    if (loading || mutationLoading || mutationError) return "Loading"

    if (robots && robots.length == 100000) {
        let x = 250
        let y = 0
        // //console.log(data)
        //let ans = [...data.robots.question.concat(data.robots.answer)]

        // data.robots[0].dokul_robot_questions.forEach((rb,quidx) => {
        //     let _ans = rb.dokul_robot_answers.map((ansx,ansidx) => {
        //         if (ansx.dokul_robot_result) {
        //             ans.push({
        //                 id: `res${ansx.dokul_robot_result.id}`,
        //                 type: 'output',
        //                 data: {dokul_robot_result_details:ansx.dokul_robot_result.dokul_robot_result_details,
        //                     label: (
        //                         <>

        //                             <div className="d-flex flex-column">
        //                                 <div className="p-2">{ansx.dokul_robot_result.text_result}</div>

        //                 {ansx.dokul_robot_result.dokul_robot_result_details.map((rdet,rdetidx) =>
        //                     // <div className="p-2">{rdet.result_desc}</div>
        //                     rdet.type==1?<PRODUKRESULT prodId={rdet.result_value} />:<LAYANANRESULT prodId={rdet.result_value} />
        // )}

        //                             <span className="bg-info p-1 rounded" onClick={()=>{
        //                                 let _robots=[...robots]

        //                                 //console.log(robots)
        //                                 return
        //                                 let _robot={..._robots[0]}

        //                                 let _questions=[..._robot.dokul_robot_questions]
        //                                 let _question={..._questions[quidx]}

        //                                 let _answers=[..._question.dokul_robot_answers]
        //                                 let _answer={..._answers[ansidx]}

        //                                 let _result={..._answer.dokul_robot_result}

        //                                 let _dresults=[..._result.dokul_robot_result_details]

        //                                 _dresults.push({
        //                                     type:2,
        //                                     result_value:45,
        //                                     result_id:_result.id
        //                                 })

        //                             }}><i className="icofont icofont-plus" /> produk</span>
        //                             <span className="bg-primary p-1 mt-1 rounded"><i className="icofont icofont-plus" /> layanan</span>
        //                             </div>
        //                         </>
        //                     )
        //                 },
        //                 position: { x: 400, y: 450 },
        //             })
        //         }
        //         return {
        //             id: `ans${ansx.id}`,
        //             source: `qu${ansx.id_pertanyaan}`,
        //             target: `${ansx.id_next != 0 ? `qu${ansx.id_next}` : `res${ansx.dokul_robot_result.id}`}`,
        //             animated: true,
        //             label: `${ansx.jawaban}`,
        //         }
        //     })
        //     let dt = {
        //         id: `qu${rb.id}`,
        //         data: {
        //             label: (
        //                 <>
        //                     <strong>{rb.pertanyaan}</strong>
        //                 </>
        //             ),
        //         },
        //         position: { x: x, y: y },
        //     }
        //     x = x + 100
        //     y = y + 150
        //     ans.push(dt);
        //     ans = ans.concat(_ans)
        // })

        // let result = data.robots.result.map(rstl => {

        //     return {
        //         id: rstl.id,
        //         type: rstl.type,
        //         data: {
        //             label: (
        //                 <>

        //                     <div className="d-flex flex-column">
        //                         <div className="p-2">{rstl.data.label}</div>
        //                         {rstl.data.dokul_robot_result_details.map((rdet, rdetidx) =>

        //                             rdet.type == 1 ? <PRODUKRESULT prodId={rdet.result_value} /> : <LAYANANRESULT prodId={rdet.result_value} />

        //                         )}
        //                         <span className="bg-info p-1 rounded" style={{cursor:"pointer"}} onClick={() => { 
        //                             let _idxcurresult=data.robots.result.indexOf(rstl)
        //                             let _results=[...data.robots.result]
        //                             let curresult=_results[_idxcurresult]

        //                             //let _resdet=[...curresult.dokul_robot_result_details]

        //                             let robres=robots.find(roid=>roid.id==rstl.id)
        //                            // relod()
        //                           //  _resdet.push({})
        //                         // setRobots(data.robots.question)
        //                           onLayout('TB')
        //                             //console.log("TBlayour")


        //                         }}><i className="icofont icofont-plus" /> produk</span>
        //                         <span className="bg-primary p-1 mt-1 rounded"><i className="icofont icofont-plus" /> layanan</span>
        //                     </div>

        //                 </>)


        //         },
        //         position: rstl.position
        //     }

        // })

        // ans = ans.concat([...data.robots.answer], result,)

        // //console.log(data.robots)

        //setRobots(ans.concat(result))

    }


    return (
        <div className="container w-100" >
            <div className="row">
                <div className="col-sm-12 border-bottom bg-white">
                    <div className="d-flex justify-content-between">
                        <div className="mt-3">
                            <button className="bg-info p-2 rounded mr-2" onClick={(e) => {
                                //console.log(e.clientX)
                                //console.log(e.clientY)
                                setPilihanNode(1)
                                setModalOpen(true)
                            }}><i className="icofont icofont-plus" /> Pertanyaan</button>

                            <button className="bg-warning p-2 rounded mr-2" onClick={(e) => {
                                setPilihanNode(5)
                                setModalOpen(true)
                            }}><i className="icofont icofont-plus" /> Jawaban</button>

                            <button className="bg-success p-2 rounded mr-2" onClick={(e) => {
                                setPilihanNode(2)
                                setModalOpen(true)
                            }}><i className="icofont icofont-plus" /> Hasil Disetujui</button>
                            <button className="bg-red p-2 rounded mr-2" onClick={(e) => {
                                setPilihanNode(22)
                                setModalOpen(true)
                            }}><i className="icofont icofont-plus" /> Hasil Ditolak</button>
                            <button className="bg-blue p-2 rounded mr-2" onClick={(e) => {
                                client.cache.reset().then((data) => {
                                    // refetch()
                                })


                            }}><i className="icofont icofont-refresh" /></button>
                            {/* <button className="bg-secondary p-2 rounded " onClick={() => {
                                // robots.forEach(el=>//console.log(JSON.stringify(el)))
                                //console.log(robots);
                                let _jsonDat = [...robots]
                                // _jsonDat.forEach((_dt, idx) => {
                                //     if (_jsonDat[idx].data && _jsonDat[idx].data.label && _jsonDat[idx].type != "buttonedge")
                                //         delete _jsonDat[idx].data.label

                                // })
                                _jsonDat = _jsonDat.filter(jd => !jd.id.includes("EDGE"))
                                // //console.log(JSON.stringify(robots))
                                // return

                                updateAnswer({
                                    variables: {
                                        body: _jsonDat,
                                        id: match.params.name
                                    },
                                    onCompleted: (id) => {
                                        //localStorage.setItem("AUTH_TOKEN", login.token);
                                        //console.log("Sukses")
                                        //document.cookie = 'token=' + login.token
                                        // document.cookie = 'signedin=true'
                                        // isLoggedInVar(true);

                                    }, onError: ({ graphQLErrors, networkError }) => {
                                        //console.log(networkError)

                                        //console.log(graphQLErrors)
                                        //  localStorage.setItem("AUTH_TOKEN", login.token);
                                        //history.push('/');
                                    },
                                })
                            }}><i className="icofont icofont-save" /> Simpan</button> */}
                        </div>
                        <h2 className="m-2 text-center" >{data.robots.name}</h2>
                        {/* <div>
                            <button onClick={() => onLayout('TB')}>vertical layout</button>
                            <button onClick={() => onLayout('LR')}>horizontal layout</button>
                        </div> */}
                    </div>
                </div>
                <div className="col-12">
                    <ReactFlow
                        elements={robots}
                        onElementsRemove={onElementsRemove}
                        onConnect={onConnect}
                        onLoad={onLoad}
                        snapToGrid={true}
                        snapGrid={[15, 15]}
                        style={graphStyles}
                        onEdgeUpdate={onEdgeUpdate}
                        onElementClick={onEdgeEnter}
                        onNodeDragStop={onDragStop}
                        edgeTypes={edgeTypes}

                        key="edge-with-button"

                    >
                        {/* <MiniMap
                            nodeStrokeColor={(n) => {
                                if (n.style?.background) return n.style.background;
                                if (n.type === 'input') return '#0041d0';
                                if (n.type === 'output') return '#ff0072';
                                if (n.type === 'default') return '#1a192b';

                                return '#eee';
                            }}
                            nodeColor={(n) => {
                                if (n.style?.background) return n.style.background;

                                return '#fff';
                            }}
                            nodeBorderRadius={2}
                        /> */}
                        {/* <MiniMap /> */}
                        <Controls />
                        <Background color="#aaa" gap={16} />

                    </ReactFlow>
                </div>


            </div>
            <Modal isOpen={loadingupdate || loading} centered backdrop>
                <ModalBody className="text-center">
                    <div className="spinner-border text-info spinner-border-lg" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalOpen} toggle={() => {
                setModalOpen(false)
                setTextNode("")
            }} size={"xl"} centered>
                <ModalHeader className="bg-primary text-light"> {pilihanNode == 1 ? "Tambah pertanyaan" : pilihanNode == 2 ? "Tambah hasil distetuji" : pilihanNode == 22 ? "Tambah hasil Ditolak" : pilihanNode == 3 ? "Edit label" : pilihanNode == 5 ? "Tambah pilihan jawaban" : pilihanNode == 31 ? "EDIT PERTANYAAN" : pilihanNode == 32 ? "EDIT HASIL" : pilihanNode == 33 ? "EDIT JAWABAN" : "UNKNOWN"}</ModalHeader>

                <ModalBody className="bg-white">

                    <section >


                        {tampilGambar && <div className="file-upload-product d-flex flex-nowrap w-50">

                            <div className='img-front m-1' style={{ padding: 0, width: "100%" }}>
                                <div className="box-input-file-lg"  >
                                    <img src={`${picture.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${picture}`} className="img-fluid image_zoom_1 blur-up lazyloaded w-50" />

                                    {/* <img src={`${pictures[activeImg] && pictures[activeImg]?.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${pictures[activeImg]?.image_url}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" /> */}
                                    <div className="product-hover w-50">
                                        <ul>
                                            <li>
                                                <input className="upload" type="file" onChange={(e) => _handleImgAdd(e)} style={{ width: "100%", }} />
                                                <button className="btn" type="button">
                                                    <Edit className="editBtn" />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>}
                        <div className="mb-3">

                            <Input className="m-2" type="checkbox" name="shoimage" id="shoimage" defaultChecked={tampilGambar} onClick={(e) => settampilGambar(!tampilGambar)} />
                            <Label className="m-1" >Tampilkan gambar {pilihanNode}</Label>
                        </div>
                    </section>
                    {pilihanNode != 33 && pilihanNode != 5 && <div className="mt-3">
                        <CKEditors
                            activeclassName="p10"
                            content={textNode}
                            config={{ extraAllowedContent: 'iframe[*];div(*);h4(*);ul(*);li(*);button(*)' }}
                            events={{
                                "blur": (val) => {

                                },
                                "afterPaste": (val) => console.log("val"),
                                "change": (val) => {
                                    ////console.log(val.editor.getData())
                                    //product.description=val.editor.getData()
                                    setTextNode(val.editor.getData())
                                },
                            }}
                        />

                        {/* <Input type="textarea" style={{ height: "200px" }} name="text" id="exampleText" value={textNode} onChange={(e) => setTextNode(e.target.value)} /> */}

                    </div>}

                    {pilihanNode == 32 && <div className="mt-3">
                        <span>REKOMENDASI PRODUK :</span>
                        {editedResDetail.map((x, idxprod) => {
                            const _product = listProduct?.find(_p => _p.id == x.result_value)
                            return <div key={idxprod} className={`d-flex flex-row m-2 ${idxprod % 2 == 0 ? 'bg-silver' : ''}`}>
                                <div className='col-4 align-self-center' ><span className="p-1 bg-light text-dark  rounded m-1" >{_product.name}::{_product.branch_id == 1 ? "JKT" : "BPN"}</span></div>
                                <div className="d-flex flex-column col-7">
                                    {x.filter_jk && x.filter_jk.split(",").map((val, idx2) => <span className="p-1 bg-success rounded m-1" key={idx2}>{jenisKelamin[val]}</span>)}
                                    {x.filter_jku && x.filter_jku.split(",").map((val, idx2) => <span className="p-1 bg-warning rounded m-1" key={idx2}>{scoringRange[val].from}-{scoringRange[val].to}</span>)}
                                    {x.filter_ku && x.filter_ku.split(",").map((val, idx2) => <span className="p-1 bg-secondary rounded m-1" key={idx2}>{kategoriUmur[val]}</span>)}
                                    {x.filter_status && x.filter_status.split(",").map((val, idx2) => <span className="p-1 bg-info rounded m-1" key={idx2}>{status[val]}</span>)}
                                    {x.dokul_robot_result_detail_answers && x.dokul_robot_result_detail_answers.map((val, idx2) => <span className="p-1 bg-light rounded m-1 text-dark" key={idx2}>{robots.find(y => y.id == `ans${val.answer_id}`).data.textLabel}</span>)}


                                </div>
                                <div className="align-self-center p-1 col-1"><button className="btn btn-danger btn-xs" onClick={async () => {
                                    await postData("", { ...x, isDeletedProduct: true, id: "res" })
                                    let _prod = [...editedResDetail]
                                    _prod.splice(idxprod, 1)
                                    seteditedResDetail(_prod)

                                }}>Hapus</button></div>
                            </div>
                        })}
                        {/* <Input type="textarea" style={{ height: "200px" }} name="text" id="exampleText" value={textNode} onChange={(e) => setTextNode(e.target.value)} /> */}

                    </div>}

                    {(pilihanNode == 33 || pilihanNode == 5) && <div className="mt-3">

                        <Label className="m-2">
                            Topic :
                        </Label>
                        <FormGroup check inline >
                            <Input type="radio" name="isTopic" id="isTopic" defaultChecked={isTopic == 1} onClick={(e) => setisTopic(1)} />
                            <Label check>Ya</Label>
                        </FormGroup>
                        <FormGroup check inline >
                            <Input type="radio" name="isTopic" id="isTopic" defaultChecked={isTopic == 0} onClick={(e) => setisTopic(0)} />
                            <Label check>Tidak</Label>
                        </FormGroup>

                    </div>}

                    {(pilihanNode == 33 || pilihanNode == 5) && <div className="mt-3">

                        <Label className="m-2">
                            Jawaban :
                        </Label>
                        <FormGroup check inline >
                            <Input type="radio" name="type_jawaban" id="shoitype_jawabanmage" defaultChecked={tipeJawaban == 1} onClick={(e) => settipeJawaban(1)} />
                            <Label check>Pilihan</Label>
                        </FormGroup>
                        <FormGroup check inline >
                            <Input type="radio" name="type_jawaban" id="shoitype_jawabanmage" defaultChecked={tipeJawaban == 2} onClick={(e) => settipeJawaban(2)} />
                            <Label check>Text bebas</Label>
                        </FormGroup>
                        <FormGroup check inline >
                            <Input type="radio" name="type_jawaban" id="shoitype_jawabanmage" defaultChecked={tipeJawaban == 3} onClick={(e) => settipeJawaban(3)} />
                            <Label check>Link</Label>
                        </FormGroup>
                        <Row>{tipeJawaban}
                            <Col >
                                {tipeJawaban != 99 && <Input className="m-2" type="text" value={textNode} onChange={(e) => {
                                    setTextNode(e.target.value)
                                }}></Input>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Input type="textarea" className="m-2" style={{ height: "200px" }} name="text" id="exampleText" value={textDescription} onChange={(e) => settextDescription(e.target.value)} />

                            </Col>
                        </Row>


                    </div>}
                    {(pilihanNode == 33 || pilihanNode == 5) && tipeJawaban == 1 && <div className="mt-3">
                        <Label className="m-2">
                            Tambahan rekomendasi produk :
                        </Label>
                        <button onClick={() => setModalProduct(true)}>+</button>
                        <Row>
                            <Col >
                                {tipeJawaban == 1 && produkJawaban.map((pr, idx) => {
                                    const _product = listProduct?.find(_p => _p.id == pr.product_id)
                                    return <div key={idx}>
                                        <div className="d-flex flex-row m-2">
                                            {/* <Media left style={{ width: 50 }} src={`${process.env.REACT_APP_IMAGE_HOST}${a.data.img_url}`} alt={a.data.label} /> */}
                                            <span>{_product?.name}</span>
                                        </div>
                                    </div>
                                }
                                )}
                            </Col>
                        </Row>

                        {/* <Input type="textarea" style={{ height: "200px" }} name="text" id="exampleText" value={textNode} onChange={(e) => setTextNode(e.target.value)} /> */}

                    </div>}
                    {(pilihanNode == 33 || pilihanNode == 5) && tipeJawaban == 1 && <div className="mt-3">

                        <FormGroup className="m-2" >
                            <Label >
                                Tambahan score :
                            </Label>

                            {tipeJawaban == 1 && <Input className="m-2" type="text" value={scoreTambahan} onChange={(e) => {
                                setscoreTambahan(e.target.value)
                            }}></Input>
                            }
                        </FormGroup>


                        {/* <Input type="textarea" style={{ height: "200px" }} name="text" id="exampleText" value={textNode} onChange={(e) => setTextNode(e.target.value)} /> */}

                    </div>}
                    {(pilihanNode == 33 || pilihanNode == 5) && <div className="mt-3">
                        <Label className="m-2">
                            Izinkan upload :
                        </Label>
                        <FormGroup check inline >
                            <Input type="radio" name="izin_upload" id="izin_upload" defaultChecked={izinUpload == 1} onClick={(e) => setizinUpload(1)} />
                            <Label check>Ya</Label>
                        </FormGroup>
                        <FormGroup check inline >
                            <Input type="radio" name="izin_upload" id="izin_upload" defaultChecked={izinUpload == 2} onClick={(e) => setizinUpload(2)} />
                            <Label check>Tidak</Label>
                        </FormGroup>
                        <FormGroup check inline >
                            <Input type="radio" name="izin_upload" id="izin_upload" defaultChecked={izinUpload == 3} onClick={(e) => setizinUpload(3)} />
                            <Label check>Harus</Label>
                        </FormGroup>

                        {/* <Input type="textarea" style={{ height: "200px" }} name="text" id="exampleText" value={textNode} onChange={(e) => setTextNode(e.target.value)} /> */}

                    </div>}

                    {(pilihanNode == 31 || pilihanNode == 1) && <div className="mt-3">
                        <Row>
                            <Label size="12">Jenis Kelamin</Label>
                            <Col >
                                {jenisKelamin.map((jk, jkidx) => <div className="p-2"><input type="checkbox" name="jk" checked={listFilter.jk.some(x => x == jkidx)} onChange={(e) => {
                                    let currentFilter = { ...listFilter }
                                    let listJk = [...currentFilter.jk]
                                    if (e.target.checked) {
                                        currentFilter.jk = [...currentFilter.jk, jkidx]
                                    } else {
                                        const idx = listJk.indexOf(jkidx)
                                        listJk.splice(idx, 1)
                                        currentFilter.jk = listJk
                                    }
                                    setlistFilter(currentFilter)
                                }} /><Label className="m-1">{jk}</Label></div>)}
                            </Col>
                        </Row>
                        <Row>
                            <Label size="12">Status</Label>
                            <Col >
                                {status.map((st, stidx) => <div className="p-2" style={{ whiteSpace: "nowrap" }}><input type="checkbox" name="status" checked={listFilter.status.some(x => x == stidx)} onChange={(e) => {
                                    let currentFilter = { ...listFilter }
                                    let listJk = [...currentFilter.status]
                                    if (e.target.checked) {
                                        currentFilter.status = [...currentFilter.status, stidx]
                                    } else {
                                        const idx = listJk.indexOf(stidx)
                                        listJk.splice(idx, 1)
                                        currentFilter.status = listJk
                                    }
                                    setlistFilter(currentFilter)
                                }} /><Label className="m-1">{st}</Label></div>)}

                            </Col>
                        </Row>
                        <Row>
                            <Label size="12">Jenis kulit</Label>
                            <Col >
                                {jenisKulit.map((jku, jkuidx) => <div className="p-2" style={{ whiteSpace: "nowrap" }}><input type="checkbox" name="jku" checked={listFilter.jku.some(x => x == jkuidx)} onChange={(e) => {
                                    let currentFilter = { ...listFilter }
                                    let listJk = [...currentFilter.jku]
                                    if (e.target.checked) {
                                        currentFilter.jku = [...currentFilter.jku, jkuidx]
                                    } else {
                                        const idx = listJk.indexOf(jkuidx)
                                        listJk.splice(idx, 1)
                                        currentFilter.jku = listJk
                                    }
                                    setlistFilter(currentFilter)
                                }} /><Label className="m-1">{jku}</Label></div>)}



                            </Col>
                        </Row>
                        <Row>
                            <Label size="12">Kategori usia</Label>
                            <Col >
                                {kategoriUmur.map((ku, kuidx) => <div className="p-2" style={{ whiteSpace: "nowrap" }}><input type="checkbox" name="ku" checked={listFilter.ku.some(x => x == kuidx)} onChange={(e) => {
                                    let currentFilter = { ...listFilter }
                                    let listJk = [...currentFilter.ku]
                                    if (e.target.checked) {
                                        currentFilter.ku = [...currentFilter.ku, kuidx]
                                    } else {
                                        const idx = listJk.indexOf(kuidx)
                                        listJk.splice(idx, 1)
                                        currentFilter.ku = listJk
                                    }
                                    setlistFilter(currentFilter)
                                }} /><Label className="m-1">{ku}</Label></div>)}

                            </Col>
                        </Row>
                        <Row>
                            <Label size="12">Tipe jawaban</Label>
                            <Col >
                                <div className="p-2" style={{ whiteSpace: "nowrap" }}>
                                    <input type="radio" id="tipeJawabanQuestion" name="tipeJawabanQuestion" checked={tipeJawabanQuestion == 1} onChange={(e) => {

                                        if (e.target.checked) {
                                            settipeJawabanQuestion(1)
                                        } else {

                                        }

                                    }} /><Label className="m-1">Flow</Label></div>

                                <div className="p-2" style={{ whiteSpace: "nowrap" }}>
                                    <input type="radio" id="tipeJawabanQuestion" name="tipeJawabanQuestion" checked={tipeJawabanQuestion == 2} onChange={(e) => {

                                        if (e.target.checked) {
                                            settipeJawabanQuestion(2)
                                        } else {

                                        }

                                    }} /><Label className="m-1">Scoring</Label></div>

                            </Col>
                        </Row>

                    </div>
                    }


                </ModalBody>

                <ModalFooter className="bg-white"><button type="button" className="btn btn-secondary" onClick={() => {
                    setModalOpen(false)
                    setTextNode("")
                }} >Batal</button>
                    <button type="button" className="btn btn-primary" onClick={async (e) => {
                        //onAdd(textNode)
                        if (pilihanNode == 1) {
                            const pic = await handleValidSubmit()
                            onAdd(textNode, defPos, pic, tampilGambar, listFilter, tipeJawabanQuestion)
                            setModalOpen(false)

                        } else if (pilihanNode == 2) {
                            onAdd2(textNode, defPos)
                            setModalOpen(false)
                        } else if (pilihanNode == 22) {
                            onAdd22(textNode, defPos)
                            setModalOpen(false)
                        } else if (pilihanNode == 3) {
                            const pic = await handleValidSubmit()
                            onTextChange(selectedId, textNode, pic, tampilGambar, listFilter)
                            setModalOpen(false)
                        } else if (pilihanNode == 31 || pilihanNode == 32 || pilihanNode == 33) {
                            const pic = await handleValidSubmit()
                            onTextChange(selectedId, textNode, pic, tampilGambar, listFilter, scoreTambahan, tipeJawaban, produkJawaban, izinUpload, isTopic, tipeJawabanQuestion, textDescription)
                            setModalOpen(false)
                        } else if (pilihanNode == 4) {

                            // const pic=handleValidSubmit()
                        } else if (pilihanNode == 5) {
                            const pic = await handleValidSubmit()
                            onAdd3(textNode, defPos, pic, tampilGambar, listFilter, scoreTambahan, tipeJawaban, produkJawaban, izinUpload, isTopic, tipeJawabanQuestion, textDescription)
                            setModalOpen(false)
                            // const pic=handleValidSubmit()
                        }


                        setTextNode("")
                    }}>Simpan</button></ModalFooter>

            </Modal>

            <Modal isOpen={openModalProduct} toggle={() => setModalProduct(false)} className="bg-white" size={"xl"}>
                <ModalHeader toggle={() => setModalProduct(false)}> <h5 className="modal-title f-w-600" id="exampleModalLabel2">{'Tambah produk'}</h5></ModalHeader>

                <div className="modal-body">
                    <FormGroup row>
                        {(pilihanNode == 32 || pilihanNode == 2) && <div className="mt-3 mb-3">
                            <Row>

                                <Col className="border p-0" >
                                    <Label size="12" className="border-bottom p-2 w-100 bg-success">Jenis Kelamin</Label>
                                    <Row className="px-3">
                                        {jenisKelamin.map((jk, jkidx) => <div className="p-2 col"><input type="checkbox" name="jk" checked={listFilter.jk.some(x => x == jkidx)} onChange={(e) => {
                                            let currentFilter = { ...listFilter }
                                            let listJk = [...currentFilter.jk]
                                            if (e.target.checked) {
                                                currentFilter.jk = [...currentFilter.jk, jkidx]
                                            } else {
                                                const idx = listJk.indexOf(jkidx)
                                                listJk.splice(idx, 1)
                                                currentFilter.jk = listJk
                                            }
                                            setlistFilter(currentFilter)
                                        }} /><Label className="m-1">{jk}</Label></div>)}
                                    </Row>
                                </Col>
                                {/* </Row>
                            <Row> */}
                                <Col className="border p-0">
                                    <Label size="12" className="border-bottom p-2 w-100 bg-warning">Score</Label>
                                    <Row className="px-3">
                                        {/* {jenisKulit.map((jku, jkuidx) => <div className="p-2 col" style={{ whiteSpace: "nowrap" }}><input type="checkbox" name="jku" checked={listFilter.jku.some(x => x == jkuidx)} onChange={(e) => {
                                            let currentFilter = { ...listFilter }
                                            let listJk = [...currentFilter.jku]
                                            if (e.target.checked) {
                                                currentFilter.jku = [...currentFilter.jku, jkuidx]
                                            } else {
                                                const idx = listJk.indexOf(jkuidx)
                                                listJk.splice(idx, 1)
                                                currentFilter.jku = listJk
                                            }
                                            setlistFilter(currentFilter)
                                        }} /><Label className="m-1">{jku}</Label></div>)} */}

                                        {scoringRange.map((sc, idxsc) => <div className="p-2" style={{ whiteSpace: "nowrap" }}><input type="checkbox" name="jku" checked={listFilter.jku.some(x => x == idxsc)} onChange={(e) => {
                                            let currentFilter = { ...listFilter }
                                            let listJk = [...currentFilter.jku]
                                            if (e.target.checked) {
                                                currentFilter.jku = [...currentFilter.jku, idxsc]
                                            } else {
                                                const idx = listJk.indexOf(idxsc)
                                                listJk.splice(idx, 1)
                                                currentFilter.jku = listJk
                                            }
                                            setlistFilter(currentFilter)
                                        }} /><Label className="m-1">{sc.name} ({sc.from}-{sc.to})</Label></div>)}

                                    </Row>

                                </Col>

                            </Row>
                            <Row>


                                {/* </Row>
                            <Row> */}
                                <Col className="border p-0">
                                    <Label size="12" className="border-bottom p-2 w-100 bg-info">Status</Label>
                                    {status.map((st, stidx) => <div className="p-2" style={{ whiteSpace: "nowrap" }}><input type="checkbox" name="status" checked={listFilter.status.some(x => x == stidx)} onChange={(e) => {
                                        let currentFilter = { ...listFilter }
                                        let listJk = [...currentFilter.status]
                                        if (e.target.checked) {
                                            currentFilter.status = [...currentFilter.status, stidx]
                                        } else {
                                            const idx = listJk.indexOf(stidx)
                                            listJk.splice(idx, 1)
                                            currentFilter.status = listJk
                                        }
                                        setlistFilter(currentFilter)
                                    }} /><Label className="m-1">{st}</Label></div>)}

                                </Col>
                                <Col className="border p-0">
                                    <Label size="12" className="border-bottom p-2 w-100 m-0 bg-secondary">Kategori usia</Label>
                                    {kategoriUmur.map((ku, kuidx) => <div className="p-2" style={{ whiteSpace: "nowrap" }}><input type="checkbox" name="ku" checked={listFilter.ku.some(x => x == kuidx)} onChange={(e) => {
                                        let currentFilter = { ...listFilter }
                                        let listJk = [...currentFilter.ku]
                                        if (e.target.checked) {
                                            currentFilter.ku = [...currentFilter.ku, kuidx]
                                        } else {
                                            const idx = listJk.indexOf(kuidx)
                                            listJk.splice(idx, 1)
                                            currentFilter.ku = listJk
                                        }
                                        setlistFilter(currentFilter)
                                    }} /><Label className="m-1">{ku}</Label></div>)}

                                </Col>
                            </Row>

                            <Row>


                                {/* </Row>
<Row> */}
                                <Col className="border p-0">
                                    <Label size="12" className="border-bottom p-2 w-100 bg-info">Selected answer</Label>
                                    <ListGroup>
                                        {robots.filter(x => x.id.includes("qu")).map(x => {
                                            // const _ans = data.robots.answer.find(y => y.id == x)
                                            // const edge_=data.robots.edge.find(e=>e.target==_ans.id)
                                            // const question_=data.robots.question.find(e=>e.id==edge_.source)
                                            // console.log(_ans)
                                            // console.log(edge_)
                                            // console.log(question_)
                                            return <>
                                                <ListGroupItem>
                                                    <ListGroupItemHeading className=' bg-silver'>
                                                        <div className='p-2' dangerouslySetInnerHTML={{ __html: x.data.textLabel }} />
                                                    </ListGroupItemHeading>
                                                    <br />
                                                    {robots.filter(x2 => x2.source == x.id).map(x3 => {
                                                        return robots.filter(x4 => x4.id == x3.target && x4.id.includes("ans")).map(x5 => {
                                                            return <><input type="checkbox" name={x5.id} checked={listFilter.questionAnswer.some(x6 => x6 == x5.id)} onChange={(e) => {
                                                                let currentFilter = { ...listFilter }
                                                                let listJk = [...currentFilter.questionAnswer]
                                                                if (e.target.checked) {
                                                                    currentFilter.questionAnswer = [...currentFilter.questionAnswer, x5.id]
                                                                } else {
                                                                    const idx = listJk.indexOf(x5.id)
                                                                    listJk.splice(idx, 1)
                                                                    currentFilter.questionAnswer = listJk
                                                                }
                                                                setlistFilter(currentFilter)
                                                            }} /><Label className='m-1'>{x5.data.textLabel}</Label><br /></>
                                                        })

                                                    })}
                                                </ListGroupItem>
                                                {/* <Label size="12" className="border-bottom p-2 w-100 bg-info">{question_.data.label}</Label> */}
                                                {/* <Label size="12" className="border-bottom p-2 w-100">{_ans.data.label}</Label> */}
                                            </>
                                        })}
                                    </ListGroup>

                                    {/* {status.map((st, stidx) => <div className="p-2" style={{ whiteSpace: "nowrap" }}><input type="checkbox" name="status" checked={listFilter.status.some(x => x == stidx)} onChange={(e) => {
                                        let currentFilter = { ...listFilter }
                                        let listJk = [...currentFilter.status]
                                        if (e.target.checked) {
                                            currentFilter.status = [...currentFilter.status, stidx]
                                        } else {
                                            const idx = listJk.indexOf(stidx)
                                            listJk.splice(idx, 1)
                                            currentFilter.status = listJk
                                        }
                                        setlistFilter(currentFilter)
                                    }} /><Label className="m-1">{st}</Label></div>)} */}

                                </Col>
                            </Row>

                        </div>
                        }

                        <Col sm={12}>
                            <Input type="text" name="textSearch" id="exampleEmail" placeholder="Search.." onChange={(e) => {
                                setfilterProduct(e.target.value)

                            }} />
                        </Col>
                    </FormGroup>
                    <div className="row">
                        <div className="col-sm-12 ">

                        </div>

                        <div className="col-sm-12 ">

                            <Row>
                                {listProduct.filter(fd => fd.name.toLowerCase().includes(filterProduct.toLowerCase())).map((p, i) =>
                                    <Col md={4}>
                                        <FormGroup key={i} check>
                                            <Label check>
                                                <Input type="checkbox" id={`prd ${i}`} value={p} onClick={(e) => {
                                                    if ((pilihanNode == 33 || pilihanNode == 5)) {
                                                        let newProd = {
                                                            result_desc: "Defaul description",
                                                            p_name: p.name,
                                                            result_value: p.id,
                                                            product_id: p.id,
                                                            p_image: p.dokul_product_images[0].image_url,
                                                            type: 1,
                                                        }
                                                        setprodukJawaban((hgk) => [...hgk, newProd])

                                                    } else {
                                                        let _results = [...data.robots.result]
                                                        let _idxcurresult = _results.findIndex(_dt => _dt.id == selectedId)
                                                        let curresult = { ..._results[_idxcurresult] }
                                                        let _listProd = [...editedResDetail]

                                                        let newProd = {
                                                            result_desc: "Defaul description",
                                                            result_id: curresult.id.substring(3),
                                                            result_value: p.id,
                                                            robot_id: curresult.robot_id,
                                                            type: 1,
                                                        }
                                                        _listProd.push(newProd)
                                                        // seteditedResDetail((hgk) => hgk.concat(newProd))
                                                        seteditedResDetail(_listProd)
                                                    }
                                                    // onEdit(curresult)

                                                }} /> {p.name} <span className={`${p.branch_id == 1 ? 'text-warning' : 'text-success'}`}>{p.branch_id == 1 ? "(JKT)" : "(BPN)"}</span>
                                            </Label>
                                        </FormGroup>
                                    </Col>


                                )}
                            </Row>

                        </div>

                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => {
                        if ((pilihanNode == 33 || pilihanNode == 5)) {

                        } else {
                            let _results = [...robots.filter(x => x.id.includes("res"))]
                            // let _results = [...data.robots.result]
                            let _idxcurresult = _results.findIndex(_dt => _dt.id == selectedId)
                            let curresult = { ..._results[_idxcurresult] }
                            let _data = { ...curresult.data }
                            let _resdet = [..._data.dokul_robot_result_details]

                            //add filter to selected
                            const _selectedProducts = editedResDetail.map(x => {
                                return {
                                    ...x,
                                    filter_jk: listFilter.jk.toString(),
                                    filter_jku: listFilter.jku.toString(),
                                    filter_status: listFilter.status.toString(),
                                    filter_usia: listFilter.ku.toString(),
                                    dokul_robot_result_detail_answers: listFilter.questionAnswer.map(x2 => {
                                        return { answer_id: x2.substring(3) }
                                    })
                                }
                            })


                            _data.dokul_robot_result_details = _resdet.concat(_selectedProducts)

                            curresult.data = _data


                            onEdit(curresult)
                            seteditedResDetail([])
                        }

                        setModalProduct(false)
                    }}>simpan</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setModalProduct(false)}>cancel</button>
                </div>
            </Modal>

        </div>

    );
};

export default withApollo(OverviewFlow);