import React, { Component, Fragment } from 'react'
import LoginTabset from './loginTabset';
import { ArrowLeft, Sliders } from 'react-feather';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import stats from '../../assets/images/dashboard/stats.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from 'js-cookie'

import history from '../../history';

import {withApollo} from '../../apollo'


const Login = ({ type }) => {
    
    if (Cookies.get('signedin')) {
        history.push('/products/list');
      }else{
          
        Cookies.remove('jwt', { path: '' });
      }
///export class Login extends Component {
    // componentDidMount(){
    //     var { loading, data } = useQuery(GET_PRODUCTS, {
    //         variables: {
    //            // type: type,
    //             indexFrom: 0,
    //             limit: 8
    //         }
    //     });
    // }
  //  render() {
     
    
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 p-0 card-left">
                                    <div className="card bg-primary">
                                        <div className="svg-icon">
                                            <img src={stats} className="Img-fluid" />
                                        </div>
                                        <Slider className="single-item" {...settings}>
                                            <div>
                                                <div>
                                                    <h3>Selamat datang</h3>
                                                    <p>Ini adalah halaman login untuk admin di dokterkulitku.com</p>
                                                </div>
                                            </div>
                                            
                                        </Slider >
                                    </div>
                                </div>
                                <div className="col-md-7 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <LoginTabset />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <a href="https://react.pixelstrap.com/multikart" target="_blank" className="btn btn-primary back-btn"><ArrowLeft />back</a> */}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
   // }
}

export default withApollo(Login)
