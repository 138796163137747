import {
  FrameAnchorType,
    HorizontalPositionAlign,
    VerticalPositionAlign,
    AlignmentType,
    Document,
    HeadingLevel,
    Packer,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,BorderStyle,
    Table, TableCell, TableRow
  } from "docx";
  const PHONE_NUMBER = "07534563401";
  const PROFILE_URL = "https://www.linkedin.com/in/dolan1";
  const EMAIL = "docx@docx.com";
  
  export class DocumentCreator {
    // tslint:disable-next-line: typedef
     create([experiences, educations, skills, achivements]) {
      const document = new Document({
        // sections: [
        //   {
        //     children: [
        //       // new Paragraph({
        //       //   text: "Dolan Miu",
        //       //   heading: HeadingLevel.TITLE
        //       // }),
        //      // this.createContactInfo(PHONE_NUMBER, PROFILE_URL, EMAIL),
        //      // this.createHeading("Education"),
        //       ...skills
        //         .map((order,index )=> {
        //           const arr = [];
        //           // arr.push(
        //           //   this.createInstitutionHeader(
        //           //     education.schoolName,
        //           //     `${education.startDate.year} - ${education.endDate.year}`
        //           //   )
        //           // );
        //       //     <dl className=" m-2 pb-2 col-sm-8" style={{ fontSize: 10 }}>
        //       //     <dt className="col-sm-12 mb-0">{order.shipping_address_id != 0 ? "" : "Ambil diklinik"}</dt>
        //       //     {order.shipping_address_id != 0 ? <>
        //       //         <dd className="col-sm-12 mb-0">{order.nomor_cetak ? order.nomor_cetak+1 : index + 1}  {order.dokul_address.first_name} {order.dokul_address.last_name} {order.dokul_address.phone}</dd>
        //       //         {/* <dd className="col-sm-12 mb-0">{order.dokul_address.phone}</dd> */}
        //       //         <dd className="col-sm-12 mb-0">{order.dokul_address.address_1}</dd>
        //       //         <dd className="col-sm-12 mb-0">({order.dokul_address.address_state}) {order.dokul_address.address_city} {order.dokul_address.address_2} {order.dokul_address.address_postcode}</dd>
        //       //     </> : <dd className="col-sm-12 mb-0">{order.nomor_cetak ? order.nomor_cetak : index + 1} {order.dokul_user.first_name} {order.dokul_user.last_name} {order.dokul_user.phone}</dd>}
        //       //     <dd className="col-sm-12 mb-2">Dari : Dokterkulitku 0817821107</dd>

        //       //     <dd className="col-sm-12 mb-0">{order.layanan_kurir.split(" ")[0].toLowerCase()} : {order.dokul_orders_items.map(item => `${item.sku_alias}(${item.quaintiy}) , `)} </dd>

        //       // </dl>
        //       if(order.shipping_address_id != 0){
        //         arr.push(
        //           this.createRoleText(
        //             order,index
        //           )
        //         );
        //           // arr.push(
        //           //   this.createRoleText(
        //           //     `${order.nomor_cetak ? order.nomor_cetak+1 : index + 1}  ${order.dokul_address.first_name} ${order.dokul_address.last_name} ${order.dokul_address.phone}`
        //           //   )
        //           // );
        //           // arr.push(
        //           //   this.createRoleText(
        //           //     `${order.dokul_address.address_1}`
        //           //   )
        //           // );
        //           // arr.push(
        //           //   this.createRoleText(
        //           //     `(${order.dokul_address.address_state}) ${order.dokul_address.address_city} ${order.dokul_address.address_2} ${order.dokul_address.address_postcode}`
        //           //   )
        //           // );

        //           // arr.push(
        //           //   this.createRoleText(
        //           //     order.layanan_kurir.split(" ")[0].toLowerCase()+" : "+order.dokul_orders_items.map(item => `${item.sku_alias}(${item.quaintiy}) , `)
        //           //   )
        //           // );
        //             }else{

        //             }

                  
        //           // const bulletPoints = this.splitParagraphIntoBullets(
        //           //   education.notes
        //           // );
        //           // bulletPoints.forEach(bulletPoint => {
        //           //   arr.push(this.createBullet(bulletPoint));
        //           // });
  
        //           return arr;
        //         })
        //         .reduce((prev, curr) => prev.concat(curr), []),
        //       // this.createHeading("Experience"),
        //       // ...experiences
        //       //   .map(position => {
        //       //     const arr = [];
  
        //       //     arr.push(
        //       //       this.createInstitutionHeader(
        //       //         position.company.name,
        //       //         this.createPositionDateText(
        //       //           position.startDate,
        //       //           position.endDate,
        //       //           position.isCurrent
        //       //         )
        //       //       )
        //       //     );
        //       //     arr.push(this.createRoleText(position.title));
  
        //       //     const bulletPoints = this.splitParagraphIntoBullets(
        //       //       position.summary
        //       //     );
  
        //       //     bulletPoints.forEach(bulletPoint => {
        //       //       arr.push(this.createBullet(bulletPoint));
        //       //     });
  
        //       //     return arr;
        //       //   })
        //       //   .reduce((prev, curr) => prev.concat(curr), []),
        //       // this.createHeading("Skills, Achievements and Interests"),
        //       // this.createSubHeading("Skills"),
        //       // this.createSkillList(skills),
        //       // this.createSubHeading("Achievements"),
        //       // ...this.createAchivementsList(achivements),
        //       // this.createSubHeading("Interests"),
        //       // this.createInterests(
        //       //   "Programming, Technology, Music Production, Web Design, 3D Modelling, Dancing."
        //       // ),
        //       // this.createHeading("References"),
        //       // new Paragraph(
        //       //   "Dr. Dean Mohamedally Director of Postgraduate Studies Department of Computer Science, University College London Malet Place, Bloomsbury, London WC1E d.mohamedally@ucl.ac.uk"
        //       // ),
        //       // new Paragraph("More references upon request"),
        //       // new Paragraph({
        //       //   text:
        //       //     "This CV was generated in real-time based on my Linked-In profile from my personal website www.dolan.bio.",
        //       //   alignment: AlignmentType.CENTER
        //       // })
        //     ]
        //   }
        // ]

//         sections: [
//           {
//               properties: {},
//               children: [
// ...skills.map(order=>
//                   new Paragraph({
//                       // frame: {
//                       //     position: {
//                       //         x: 1000,
//                       //         y: 3000,
//                       //     },
//                       //     width: 4000,
//                       //     height: 1000,
//                       //     anchor: {
//                       //         horizontal: FrameAnchorType.MARGIN,
//                       //         vertical: FrameAnchorType.MARGIN,
//                       //     },
//                       //     alignment: {
//                       //         x: HorizontalPositionAlign.CENTER,
//                       //         y: VerticalPositionAlign.TOP,
//                       //     },
//                       // },
//                       border: {
//                           top: {
//                               color: "auto",
//                               space: 1,
//                               style: BorderStyle.SINGLE,
//                               size: 6,
//                           },
//                           bottom: {
//                               color: "auto",
//                               space: 1,
//                               style: BorderStyle.SINGLE,
//                               size: 6,
//                           },
//                           left: {
//                               color: "auto",
//                               space: 1,
//                               style: BorderStyle.SINGLE,
//                               size: 6,
//                           },
//                           right: {
//                               color: "auto",
//                               space: 1,
//                               style: BorderStyle.SINGLE,
//                               size: 6,
//                           },
//                       },
//                       children: [
//                           new TextRun("Hello World"),
//                           new TextRun({
//                               text: "Foo Bar",
//                               bold: true,
//                               break:true
//                           }),
//                           new TextRun({
//                               text: "\tGithub is the best",
//                               bold: true,
//                           }),
//                       ],
//                   }),
// )
//               ],
//           },
//       ],
sections: [
  {
      children: [
          new Table({
              rows: [
                ...skills
                .map((order,index )=> {
                if(order.shipping_address_id != 0){
                  return new TableRow({
                      children: [
                       
                          new TableCell({
                            margins:10,
                            children: [this.createRoleText(order,index)],
                          }),
                       
                          
                      ],
                  })
                }else{
                  return new TableRow({
                    children: [
                     
                        new TableCell({
                            children: [],
                        }),
                     
                        
                    ],
                })
                }
              }
                )
              ],
          }),
      ],
  },
],
      });
  
      return document;
    }
  
     createContactInfo(
      phoneNumber,
      profileUrl,
      email
    ){
      return new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun(
            `Mobile: ${phoneNumber} | LinkedIn: ${profileUrl} | Email: ${email}`
          ),
          new TextRun({
            text: "Address: 58 Elm Avenue, Kent ME4 6ER, UK",
            break: 1
          })
        ]
      });
    }
  
     createHeading(text) {
      return new Paragraph({
        text: text,
        heading: HeadingLevel.HEADING_1,
        thematicBreak: true
      });
    }
  
     createSubHeading(text) {
      return new Paragraph({
        text: text,
        heading: HeadingLevel.HEADING_2
      });
    }
  
     createInstitutionHeader(
      institutionName,
      dateText
    ) {
      return new Paragraph({
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: TabStopPosition.MAX
          }
        ],
        children: [
          new TextRun({
            text: institutionName,
            bold: true
          }),
          new TextRun({
            text: `\t${dateText}`,
            bold: true
          })
        ]
      });
    }
  
     createRoleText(order,index) {
      return new Paragraph({
      //   frame: {
      //     position: {
      //         x: 1000,
      //         y: 3000,
      //     },
      //     width: 4000,
      //     height: 1000,
      //     anchor: {
      //         horizontal: FrameAnchorType.MARGIN,
      //         vertical: FrameAnchorType.MARGIN,
      //     },
      //     alignment: {
      //         x: HorizontalPositionAlign.CENTER,
      //         y: VerticalPositionAlign.TOP,
      //     },
      // },
      border: {
          top: {
              color: "auto",
              space: 1,
              style: BorderStyle.SINGLE,
              size: 6,
          },
          bottom: {
              color: "auto",
              space: 1,
              style: BorderStyle.SINGLE,
              size: 6,
          },
          left: {
              color: "auto",
              space: 1,
              style: BorderStyle.SINGLE,
              size: 6,
          },
          right: {
              color: "auto",
              space: 1,
              style: BorderStyle.SINGLE,
              size: 6,
          },
      },
        children: [
          new TextRun({
            margins:10,
            text: `${order.nomor_cetak ? order.nomor_cetak+1 : index + 1}  ${order.dokul_address.first_name} ${order.dokul_address.last_name} ${order.dokul_address.phone}`,
            break:true
          }),
          new TextRun({
            text:  `${order.dokul_address.address_1}`,
            break:true
          }),
          new TextRun({
            text: `(${order.dokul_address.address_state}) ${order.dokul_address.address_city} ${order.dokul_address.address_2} ${order.dokul_address.address_postcode}`,
            break:true
          }),
          new TextRun({
            text:  order.layanan_kurir.split(" ")[0].toLowerCase()+" : "+order.dokul_orders_items.map(item => `${item.sku_alias}(${item.quaintiy}) , `),
            break:true
          }),

         
        ]
      });
    }
  
     createBullet(text){
      return new Paragraph({
        text: text,
        bullet: {
          level: 0
        }
      });
    }
  
    // tslint:disable-next-line:no-any
     createSkillList(skills) {
      return new Paragraph({
        children: [new TextRun(skills.map(skill => skill.name).join(", ") + ".")]
      });
    }
  
    // tslint:disable-next-line:no-any
     createAchivementsList(achivements){
      return achivements.map(
        achievement =>
          new Paragraph({
            text: achievement.name,
            bullet: {
              level: 0
            }
          })
      );
    }
  
     createInterests(interests) {
      return new Paragraph({
        children: [new TextRun(interests)]
      });
    }
  
     splitParagraphIntoBullets(text) {
      return text.split("\n\n");
    }
  
    // tslint:disable-next-line:no-any
     createPositionDateText(
      startDate,
      endDate,
      isCurrent
    ) {
      const startDateText =
        this.getMonthFromInt(startDate.month) + ". " + startDate.year;
      const endDateText = isCurrent
        ? "Present"
        : `${this.getMonthFromInt(endDate.month)}. ${endDate.year}`;
  
      return `${startDateText} - ${endDateText}`;
    }
  
     getMonthFromInt(value) {
      switch (value) {
        case 1:
          return "Jan";
        case 2:
          return "Feb";
        case 3:
          return "Mar";
        case 4:
          return "Apr";
        case 5:
          return "May";
        case 6:
          return "Jun";
        case 7:
          return "Jul";
        case 8:
          return "Aug";
        case 9:
          return "Sept";
        case 10:
          return "Oct";
        case 11:
          return "Nov";
        case 12:
          return "Dec";
        default:
          return "N/A";
      }
    }
  }