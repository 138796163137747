import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";

const TooltipItem = props => {
  const { item, id ,icon,warna,backwarna,aksi} = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      {/* <Button className="mr-1" color="secondary" id={"Tooltip-" + id}>
        {item.text}
      </Button> */}
      <span className="border p-2 rounded m-1  action" id={"Tooltip-" + id} onClick={(e)=>aksi()}>
      
    <i className={item.icon} style={{ fontSize: 20, padding: 0, color: `${item.warna}`, cursor: 'pointer' }}></i>
    </span>
      <Tooltip
        placement={item.placement}
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
      >
         {item.text}
      </Tooltip>
    </span>
  );
};
export default TooltipItem