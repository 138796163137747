import React, { useState, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
//import datanya from '../../assets/data/physical_list';
import { Edit, Loader, Trash2 } from 'react-feather'
import { withApollo } from '../../../apollo'
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import TransactionList from './transaction.list'
import { GET_CONFIGS, GET_TRANSACTIONS,RELOAD_BANK_TRANS } from '../../../constants/graph';


const Transactions = ({ match }) => {
    const { loading:lCOnfig, data:dConfig, error:eCOnfig } = useQuery(GET_CONFIGS,);
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    //const params = useParams();

    if (match.params.page) {
        console.log(match.params.page)
        console.log(match.params.id)


    }
    const updateCache = (cache, { data }) => {

        // Fetch the todos from the cache
        // const existingTodos = cache.readQuery({
        //   query: GET_ORDERS
        // });
        // console.log("HOLAAAAAA")
        console.log(data)
        const fragmentResult = cache.writeFragment({
            id: `Robot:1`,
            fragment: gql`
          fragment robot on Robot{
            question
            answer
            result
          }
        `,
            data: data.updateRobot
            //  {
            //   __typename: 'Robot',
            //   answer: data.updateRobot.answer,
            //   question:data.updateRobot.answer,
            //   result:data.updateRobot.result
            // }
        });
        // console.log(fragmentResult)
        // Add the new todo to the cache
        // const newTodo = data.insert_todos.returning[0];
        // cache.writeQuery({
        //   query: GET_ROBOT_GUIDE,
        //   data: data.updateRobot
        // });
    };
    const [reloadTransactions, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(RELOAD_BANK_TRANS, { update: updateCache });
    
    
    const { loading, data, error,fetchMore } = useQuery(GET_TRANSACTIONS,{
        variables: {
            page: 0,
            size: 10
        }
    });
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    if (error ) {
        console.log(error.message);
        Cookies.remove('signedin','/');
        Cookies.remove('jwt');
        


        history.push('/');
        //return <div>Error!</div>;
    }
    
   
    const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    let order;
    // const [orders] = useMutation(LOGIN_MUTATION, {
        
    //     update(cache, { data: { orders } }) {
    //         // const { orders } = cache.readQuery({
    //         //   query: GET_PRODUCTS
    //         // });
    //     console.log(orders)
    //         // const updatedLinks = feed.links.map((feedLink) => {
    //         //   if (feedLink.id === link.id) {
    //         //     return {
    //         //       ...feedLink,
    //         //       votes: [...feedLink.votes, vote]
    //         //     };
    //         //   }
    //         //   return feedLink;
    //         // });
        
    //         cache.writeQuery({
    //           query: GET_ORDERS,
    //             variables: {
    //                 page: 0,
    //                 size: 10
                
    //         },
    //           data: {
    //             orders: []
    //           }
    //         });
    //       }
        
            
    // });
    const getNextData=(cpagesize,rpage,id,date,provider,nominal)=>{
       
    return fetchMore({
        variables: {
            page:rpage,
            size: cpagesize,
            id:id,
            date:date,
            provider:provider,
            nominal:nominal
        
    }
      })
    }
    // if(mutationLoading) return <div><Loader /></div>
    

    //daricahce
    // const { products } = client.readQuery({
    //     query: gql`
    //       query ReadProducts {
    //         products {
    //           id
    //         }
    //       }
    //     `,
    //   },false);

    // const todo = client.readFragment({
    //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment myTodo on Product {
    //         id
    //         name
    //       }
    //     `,
    // });
    //console.log(data);
    // setData(datanya)


    return (
        <Fragment>
            {loading? <div style={{width:'100%',height:'100%',backgroundColor:'darkorange'}}>Loading</div>:<>
            <Breadcrumb title="Log transaksi bank" parent="Penjualan" />
            {
                match.params.page == 'list' ?
                <TransactionList data={data} getNextData={getNextData} />
                    : match.params.page == 'detail' ?
                    <TransactionList data={data} getNextData={getNextData} />
                        : match.params.page == 'edit' ?
                        <TransactionList data={data} getNextData={getNextData} />
                            : match.params.page == 'add' ?
                            <TransactionList data={data} getNextData={getNextData} />
                                :match.params.page == 'transactions' ?
                                <TransactionList data={data} getNextData={getNextData} />
                                :
                                <TransactionList dConfig={dConfig} data={data} getNextData={getNextData} reloadTransactions={reloadTransactions} mutationLoading={mutationLoading} />
            }
            </>
        }

        </Fragment>
    )

}

export default withApollo(Transactions)
