import io from "socket.io-client"
import openSocket from 'socket.io-client';
import { socketEvents } from "./events";
import { getQueueLength } from "./emits";
//export const socket = io();
// export const socket = openSocket(`${process.env.REACT_APP_API_HOST}`,{
//   withCredentials: true,
//   //transports: ['websocket'],
//   extraHeaders: {
//     "my-custom-header": "abcd"
//   }});
export const socket = openSocket(`${process.env.REACT_APP_API_HOST}`,{//http://demo.integrasi.me
  // path:'/soket/socket.io',
  withCredentials: true,
  transports: ['websocket'],
  extraHeaders: {
    "my-custom-header": "abcd",
  }});
export const initSockets = ({ setValue }) => {
 
  socketEvents({ setValue });
  // setValue    ^ is passed on to be used by socketEvents
  getQueueLength();
};
export const initSockets2 = ({ setValue }) => {
    console.log("oookh")

    
  };
