import React, { Component } from 'react'
import Sidebar from './common/sidebar_components/sidebar';
import Right_sidebar from './common/right-sidebar';
import Footer from './common/footer';
import Header from './common/header_components/header';
import history from '../history';
import Loading from './common/loading';
const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
const adminRoleId=[5,10,11]
export class App extends Component {
    constructor(props){
        super(props);
        this.state ={
            ltr:true,
            divName:'RTL',
        }
    }
    ChangeRtl(divName){
        if(divName === 'RTL') {
            document.body.classList.add('rtl');
            this.setState({divName: 'LTR'});
        }else{
            document.body.classList.remove('rtl');
            this.setState({divName: 'RTL'});
        }
    }
    validatePage(page){
        if(page.includes('dashboard') || page.includes('point/list')|| page.includes('voucher/list')|| page.includes('rqa')|| page.includes('settings')) {
            if(!adminRoleId.some(x=>user.dokul_user_roles.some(dur=>dur.role_id==x))) return false
            
        }
        return true;
    }
    isDokterOnly(){
       
        if(user.dokul_user_roles.length==1 &&( user.dokul_user_roles.some(dur=>dur.role_id==12) ||  user.dokul_user_roles.some(dur=>dur.role_id==14)))
            return true
        return false;
    }
    isPackerOnly(){
       
        if(user.dokul_user_roles.length==1 &&( user.dokul_user_roles.some(dur=>dur.role_id==15) ))
            return true
        return false;
    }
    isApotekOnly(){
       
        if(user.dokul_user_roles.length==1 &&( user.dokul_user_roles.some(dur=>dur.role_id==16)||user.dokul_user_roles.some(dur=>dur.role_id==17) ))
            return true
        return false;
    }
    isAvailableMenu(){
        
        const listEnable=[3,4,5,10,11,16,17,18]
       console.log(user.dokul_user_roles);
        if(user.dokul_user_roles.some(dur=>listEnable.some(x=>dur.role_id==x)))
            return true
        return false;
    }
    // componentDidUpdate(pp,ps){
       
    //     if (!Cookies.get('signedin')) {
    //         history.push('/');
    //     }
    // }
    render() {
        if(!this.validatePage(this.props.location.pathname)){
            history.push('/sales/orders')
        }
        if(this.props.location.pathname.includes('rqa'))
        return (
            <div>
                {this.props.children}
                </div>
        )
        else
        return (
            <div>
               
                <div className="page-wrapper" >
                    <Header isDokterOnly={this.isDokterOnly()} isAvailableMenu={this.isAvailableMenu()} />
                    <div className="page-body-wrapper">
                        {this.isAvailableMenu()&&<Sidebar />}
                        <Right_sidebar />
                        <div className="page-body">
                            {this.props.children}
                        </div>
                            <Footer />
                    </div>
                </div>
                {/* <div className="btn-light custom-theme" onClick={ () => this.ChangeRtl(this.state.divName)}>{this.state.divName}</div> */}
            </div>
        )
    }
}

export default App
