import React, { Component, useState } from 'react';
import Modal from 'react-responsive-modal';
import Draggable from 'react-draggable'; // The default
import CKEditors from "react-ckeditor-component";
import { Badge, Input, Col, FormGroup, Label, legend, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap'
import history from '../../history';
import { Edit, Trash2, Plus } from 'react-feather'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
class servicEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            poppaverId: 1,
            addRow: false,
            editRow: false,
            popoverOpen: false,
            selectedItem: {},
            selectedRow: {},
            file: '',
            open: false,
            posisi:this.props.data.dokul_page?.dokul_content_rows
        }
    }

    handleStop = (e, d) => {
        // console.log(e.pageY)
        // console.log(d)
        // console.log(d.y)
        // console.log(d.node.offsetHeight+d.y)
        this.setState({})
    }
    onLoad = (e) => {
        console.log(e)
    }
    changePos=()=>{

         // 1. Make a shallow copy of the items
    
    // 5. Set the state to our new copy
    // this.setState({items});

    //     var posisi=this.state.posisi
    //     const index = 0//posisi.indexOf(item)
    //    // posisi[index].idx = Math.round(el.getBoundingClientRect().y)
    //     posisi[index].idx = 0//Math.round(el.getBoundingClientRect().y)

    }
    onOpenModal = () => {
        this.setState({ open: true, popoverOpen: false })
        //     let selectedItem = col

        //   //  selectedItem = col
        //     this.setState({
        //         selectedItem: selectedItem,
        //         selectedRow: row
        //     }, () => this.setState({ open: true }))

    };

    onCloseModal = () => {
        console.log(this.state.posisi)
        this.setState({ open: false }, () => this.setState({ editRow: false, addRow: false }));
    };
    onChangeContent = (e) => {
        let selectedItem={...this.state.selectedItem}
        selectedItem.content = e
        this.setState({selectedItem:selectedItem})

    }
    saveItem = () => {
        console.log(this.state.selectedItem.id)
        if (this.state.addRow) {
            let posisi = [...this.state.posisi]
            let row = this.state.selectedRow
            posisi.push(row)

            this.setState({
                posisi: posisi
            }, () => this.onCloseModal())

        } else if (this.state.editRow) {
            let posisi = [...this.state.posisi]
            let row = this.state.selectedRow
           // const index = posisi.indexOf(row)
            let index=posisi.findIndex(dt=>dt.idx==this.state.selectedRow.idx)
            posisi[index] = row

            this.setState({
                posisi: posisi
            }, () => this.onCloseModal())

        } else if (this.state.selectedItem.row_id || this.state.selectedItem.row_id === null) {
            let posisi = [...this.state.posisi]
            let index=posisi.findIndex(dt=>dt.idx==this.state.selectedRow.idx)
           // const index = posisi.indexOf(this.state.selectedRow)

            console.log(index)
            let updateRowsCols = this.state.selectedRow.dokul_content_cols.map(data => data.id == this.state.selectedItem.id ? this.state.selectedItem : data)

            posisi[index].dokul_content_cols = updateRowsCols
            console.log(index)
            this.setState({
                posisi: posisi
            }, () => this.onCloseModal())
        } else {
            console.log("Add content baru")
            let selectedItem = {...this.state.selectedItem}
            //selectedItem.id=null
            selectedItem.row_id = this.state.selectedRow.id
            let posisi = [...this.state.posisi]
            //const index = posisi.indexOf(this.state.selectedRow)
            let index=posisi.findIndex(dt=>dt.idx==this.state.selectedRow.idx)

            posisi[index].dokul_content_cols.push(selectedItem)
            this.setState({
                posisi: posisi
            }, () => this.onCloseModal())
        }


    }
    toggle = (e) => {
        this.state.popoverOpen = !this.state.popoverOpen
        this.setState({})

    }
    changeImageContent = (e, showmodal) => {
        let selectedItem;
        let reader = new FileReader();
        let file = e.target.files[0];
        if (showmodal)
            selectedItem = { type: 3 }
        else
            selectedItem = this.state.selectedItem



        reader.onloadend = () => {
            selectedItem.content = reader.result;
            this.setState({
                file: file,
                selectedItem: selectedItem
            }, () => {
                if (showmodal)
                    this.onOpenModal()
            })

        }
        reader.readAsDataURL(file)

    }
    onImageSelected = (e) => {
        this.changeImageContent(e, true)
        // this.setState({
        //     popoverOpen:false
        // },()=>this.changeImageContent(e,true))

    }
    deleteRow = (row) => {
        let posisi = this.state.posisi
        const index = posisi.indexOf(row)
        posisi.splice(index, 1)
        this.setState({
            posisi: posisi
        })


    }
    deleteCol = (row, col) => {
        let posisi = this.state.posisi
        const index = posisi.indexOf(row)
        const indexcol = row.dokul_content_cols.indexOf(col)
        row.dokul_content_cols.splice(indexcol, 1)
        posisi[index] = row
        this.setState({
            posisi: posisi
        })


    }
    componentDidMount(){
        // var posisi=Object.assign([], this.props.layanans?.find(l=>l.id==this.props.id).dokul_page?.dokul_content_rows);//this.props.layanans?.find(l=>l.id==1).dokul_page?.dokul_content_rows;
        // console.log(posisi)
        // this.setState({
        //     posisi:posisi
        // })
    }

    render() {

        const { posisi, open, selectedItem, popoverOpen, editRow, selectedRow, addRow } = this.state
        const config = {

            toolbarGroups: [
                { name: 'document', groups: ['mode', 'document', 'doctools'] },
                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                { name: 'forms', groups: ['forms'] },
                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                { name: 'links', groups: ['links'] },
                { name: 'insert', groups: ['insert'] },
                { name: 'styles', groups: ['styles'] },
                { name: 'colors', groups: ['colors'] },
                { name: 'tools', groups: ['tools'] },
                { name: 'others', groups: ['others'] },
                { name: 'about', groups: ['about'] },
                '/',
                '/'
            ],

            removeButtons: 'Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,TextColor,BGColor,About,Styles'
        };
        return (


            <div >
                {/* <Popover
                    placement="right"
                    isOpen={popoverOpen}
                    target="Popover1"
                    toggle={this.toggle}
                >
                    <PopoverHeader>Select content type</PopoverHeader>

                    <div className="text-justify">
                        <div className="row" style={{ margin: 10 }}>
                            <div className="col">

                                <div className="form-group">
                                    <label htmlFor="message-text" className="col-form-label">Image content :</label>
                                    <input className="form-control" id="validationCustom02" type="file" onChange={this.onImageSelected} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message-text" className="col-form-label">Editor content :</label>
                                    <button className='btn btn-dark' onClick={(e)=>{
                                        this.setState({
                                            selectedItem:{type:2,content:''}
                                        },()=>this.onOpenModal())
                                    }}>Open editor</button>
                                </div>


                            </div>
                        </div>
                        
                    </div>

                </Popover> */}



                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-primary" style={{ marginRight: 10 }} onClick={(e) => {
                            const t = this.props.saveRow({
                                variables: {
                                    data: posisi
                                },
                                onCompleted: (saveService) => {
                                    console.log("Sukses")
                                    // history.push('/products');
                                }, onError: ({ graphQLErrors, networkError }) => {
                                    console.log("ada eror")
                                    console.log(networkError)
                                    console.log(graphQLErrors)
                                },
                            }).then(data => {
                                // console.log(data) 
                                history.push('/services');
                            })
                           

                        }}>Save</button>
                        <button className="btn btn-secondary" onClick={(e) => {
                            this.setState({
                                addRow: true,
                                selectedRow: { dokul_content_cols: [], title: '', idx: 1000, parrent_id: this.props.data.dokul_page.id, parent_type: 2, row_type: 2 }
                            }, () => this.onOpenModal())

                        }}>Add section</button>
                    </div>
                </div>
                {
                    posisi?.map((item, i) => {
                        return <Draggable key={i} onStop={this.handleStop} ><section style={{ border: 'solid 1px', margin: 10, padding: 10 }} id={`${item.id}-ww`} ref={el => {
                            // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
                            if (!el) return;
                            // console.log(item.id)
                            // console.log(Math.round(el.getBoundingClientRect().y)); // prints 200px
                            
                            // var posisi=this.state.posisi
                             const index = posisi.indexOf(item)
                           // this.changePos()

                            let pitems = [...this.state.posisi];
                            // 2. Make a shallow copy of the item you want to mutate
                            let pitem = {...pitems[index]};
                            // 3. Replace the property you're intested in
                            pitem.idx = Math.round(el.getBoundingClientRect().y);
                            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                            pitems[index] = pitem;
                            this.state.posisi=pitems


                           // posisi[index].idx = Math.round(el.getBoundingClientRect().y)
                            //item.idx = Math.round(el.getBoundingClientRect().y)


                        }}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            {item.title}
                                        </h5>
                                        <div className="btn-popup pull-right">
                                            <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={(e) => {
                                                this.setState({
                                                    editRow: true,
                                                    selectedRow: item
                                                }, () => this.onOpenModal())
                                            }} ></i></span>
                                            {/* <span><i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }} id={"Popover" + item.id} onClick={(e) => {
                                                this.setState({
                                                    selectedRow:item,
                                                    popoverOpen: true
                                                })
                                            }} ></i></span> */}
                                            <PopoverItem key={i}
                                                onImageSelected={(t) => this.onImageSelected(t)}
                                                onClick={() => this.setState({ selectedRow: item })}
                                                setItem={(col) => {
                                                    this.setState({
                                                        selectedItem: col,
                                                        selectedRow: item
                                                    }, () => this.onOpenModal())
                                                }}
                                                item={{
                                                    placement: "right",
                                                    text: "Left",

                                                }} id={i} />;
                                            <span><i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: 'red' }} onClick={(e) => this.deleteRow(item)} ></i></span>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div className="row text-center" >

                                {item.dokul_content_cols.map((col, i2) => {
                                    return <div key={i2} className="col" >

                                        <div className="modal-header" style={{ backgroundColor: 'dimgrey' }}>
                                            <h5 className="modal-title">
                                                <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={(e) => {
                                                    this.setState({
                                                        selectedItem: col,
                                                        selectedRow: item
                                                    }, () => this.onOpenModal())
                                                }} ></i></span>
                                                <span><i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: 'red' }} onClick={(e) => this.deleteCol(item, col)} ></i></span>
                                            </h5>

                                        </div>
                                        <div className="modal-body" style={{ border: '1px dashed' }}>
                                            {col.type == 3 ? <img src={`${col.content.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${col.content}`} width='100%'></img> :
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: col.content
                                                    }}
                                                />
                                            }
                                        </div>
                                        <div className="modal-footer">

                                        </div>

                                    </div>
                                })}
                            </div>
                        </section></Draggable>
                    })
                }


                <Modal open={open} onClose={this.onCloseModal} >
                    <div className="modal-header">
                        <h5 className="modal-title f-w-600" id="exampleModalLabel2">{editRow ? 'Edit section' : addRow ? 'Add section' : selectedItem.id ? 'Edit ' : 'Add '}</h5>
                    </div>
                    <div className="modal-body">
                        <form autoComplete='off'>
                            {editRow || addRow ?
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label" >Title :</label>
                                    <input type="text" className="form-control" name="name" value={selectedRow.title} onChange={(e) => {
                                        selectedRow.title = e.currentTarget.value
                                        this.setState({})
                                    }} />
                                </div>
                                :
                                selectedItem.type != 3 ?
                                    <CKEditors
                                        activeclassName="p10"
                                        content={selectedItem.content}
                                        config={{ extraAllowedContent: 'iframe[*];div(*);h4(*);ul(*);li(*);button(*)' }}
                                        events={{
                                            "blur": (val) => {

                                            },
                                            "afterPaste": (val) => console.log("val"),
                                            "change": (val) => {
                                                //console.log(val.editor.getData())
                                                //product.description=val.editor.getData()
                                                this.onChangeContent(val.editor.getData())
                                            },
                                        }}
                                    /> : <div className='img-front'><div className="box-input-file-lg"  >

                                        <img src={`${selectedItem.content.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${selectedItem.content}`} className="img-fluid image_zoom_1 blur-up lazyloaded"></img>
                                        <div className="product-hover">
                                            <ul>
                                                <li><div className="box-input-file-lg"  >
                                                    <button className="btn" type="button">
                                                        <input className="upload" id="fileUpload" type="file" style={{ cursor: "pointer" }} onChange={this.changeImageContent} />
                                                        <Edit className="editBtn" />
                                                    </button>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    </div>

                            }


                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.saveItem}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button>
                    </div>
                </Modal>

            </div>

        );
    }
}

const PopoverItem = props => {
    const { id, item, onImageSelected, setItem } = props;

    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => setPopoverOpen(!popoverOpen);

    return (
        <span>
            {/* <Button
          className="me-1"
          color="secondary"
          id={"Popover-" + id}
          type="button"
        >
          {item.text}
        </Button> */}
            <span>
                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }} id={"Popover-" + id} onClick={props.onClick} >
                </i>
            </span>
            <Popover
                placement={item.placement}
                isOpen={popoverOpen}
                target={"Popover-" + id}
                toggle={toggle}
            >
                <PopoverHeader>Select content type</PopoverHeader>

                <div className="text-justify">
                    <div className="row" style={{ margin: 10 }}>
                        <div className="col">

                            <div className="form-group">
                                <label htmlFor="message-text" className="col-form-label">Image content :</label>
                                <input className="form-control" id="validationCustom02" type="file" onChange={(e) => {
                                    props.onImageSelected(e)
                                    toggle()
                                }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message-text" className="col-form-label">Editor content :</label>
                                <button className='btn btn-dark' onClick={(e) => {
                                    props.setItem({ type: 2, content: '' })
                                    toggle()
                                    // this.setState({
                                    //     selectedItem:{type:2,content:''}
                                    // },()=>this.onOpenModal())
                                }}>Open editor</button>
                            </div>


                        </div>
                    </div>
                    {/* <button type="button" className="btn btn-primary  mb-2" onClick={this.toggle}>Update status</button> */}
                </div>
            </Popover>
        </span>
    );
};

export default servicEdit;
