import React, { useState, useEffect, Fragment } from 'react';
import { GET_CATEGORIES, GET_TAGS, GET_PRODUCTS } from '../../../constants/graph'
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import { AvField, AvForm, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvInput } from 'availity-reactstrap-validation';
import one from '../../../assets/images/pro3/1.jpg'
import user from '../../../assets/images/user.png';
import { Edit, Trash2, Plus } from 'react-feather'
import { Alert, Badge, FormGroup, Label, Input, ListGroupItem, ListGroup, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import history from '../../../history';
import { useQuery, useMutation, readQuery, useApolloClient } from '@apollo/react-hooks';
import CategorySelect from '../category/category.select'

//import { useApolloClient, NormalizedCacheObject, QUERY } from '@apollo/client';

import gql from 'graphql-tag';
const UPDATE_TODO = gql`
  mutation UpdateTodo($id: String!, $type: String!) {
    updateTodo(id: $id, type: $type) {
      id
      type
    }
  }
`;
const Hello = () => {
    const { loading, error, data } = useQuery(GET_CATEGORIES,);
    if (loading) return <p>Loading ...</p>;
    return data;
}
const UPDATE_MUTATION = gql`
    mutation updateMutation(
      $data: any
      $id:ID!
    ) {
      login(id:$id,body: $data)
      @rest(
        type: "Post"
        path: "api/products/{args.id}"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
const ADD_PRODUCT = gql`
  mutation addMutation(
    $data: any
    $id:ID!
  ) {
    login(id:$id,body: $data)
    @rest(
      type: "Post"
      path: "api/products"
      method: "POST"
      bodyKey: "body"
    ) {
    completed
  }
  }
`;
const ProdukDetail = (props) => {
    const [activeImg, setActiveImg] = useState(0)
    const [popoverOpen, setPopoverOpen] = useState(false);

    const [popoverOpenRole, setPopoverOpenRole] = useState(false);
    const categories = []

    const toggle = () => setPopoverOpen(!popoverOpen);
    const toggleRole = () => setPopoverOpenRole(!popoverOpenRole);
    const [file, setFile] = useState()
    const [files, setFiles] = useState([])
    const [skuFiles, setSkuFiles] = useState([])
    const [pictures, setPicture] = useState([])
    const [content, setContent] = useState("")
    const [sku, setSku] = useState([])
    const [product, setProduct] = useState(props.product)
    const [imageError, setimageError] = useState(false)
    const [warningChangeBranch, setwarningChangeBranch] = useState(false)
    useEffect(() => {

        if (product) {
            setPicture(product.dokul_product_images)
            setContent(product.description)
            setSku(product.dokul_products_skus)


        }
        // if (!product.id) {
        console.log("useefect")
        props.handeleUpdateProduct(product)
        // }
    }, [product])
    useEffect(() => {


        // if (!product.id) {

        props.setFiles(files)
        // }
    }, [files])
    const handleChangeDesc = (desc) => {

        let _product = { ...props.product }
        console.log(_product)
        //console.log(val.editor.getData())
        _product.description = desc
        setProduct(_product)

    }
    useEffect(() => {
        if (content) {
            let _product = { ...product }
            _product.description = content
            setProduct(_product)
        }

    }, [content])




    const [updateProduct] = useMutation(UPDATE_MUTATION);
    const [saveProduct] = useMutation(ADD_PRODUCT);
    const handleValidSubmit = (event, values) => {
        if (product.dokul_product_images.length == 0) {
            setimageError(true)
            return
        }
        if (product.dokul_products_skus.length == 0) {
            setwarningChangeBranch(true)
            return
        }
        //         console.log("----------------------")
        //   console.log(product)
        //   console.log(props.skuFiles)
        //   return
        props.setisLoading(true)
        let _product = { ...product }
        _product.dokul_products_skus = [...sku]
        const formData = new FormData()
        files.forEach((f, i) => {

            formData.append('uploaded_file', f, `prod_${i}_${f.name}`)
        })

        if (!product.id) {
            props.skuFiles.forEach((f, i) => {

                formData.append('uploaded_file', f, `sku_${i}_${f.name}`)
            })
        }

        fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                var _newListImage = []
                data.d.forEach(ud => {
                    const isProd = ud.originalname.split("_")
                    // let _sku = {..._skus[isProd[1]]};
                    if (isProd[0] == 'prod') {
                        _newListImage.push({
                            image_url: `/assets/images/uploads/${ud.filename}`,
                            product_id: product.id
                        })
                        _product.dokul_product_images = _newListImage;
                        // let listImg = [..._product.dokul_product_images]
                        // let imgProd = { ...listImg[isProd[1]] }
                        // imgProd.image_url = `/assets/images/uploads/${ud.filename}`
                        // listImg[isProd[1]] = imgProd
                        // _product.dokul_product_images = listImg;
                        //_product.dokul_product_images[isProd[1]]=imgProd//.image_url=`/assets/images/uploads/${ud.filename}`
                    }
                    if (isProd[0] == 'sku') {
                        let listSku = [..._product.dokul_products_skus]
                        let imgSku = { ...listSku[isProd[1]] }
                        imgSku.image_url = `/assets/images/uploads/${ud.filename}`
                        listSku[[isProd[1]]] = imgSku
                        _product.dokul_products_skus = listSku//.image_url=`/assets/images/uploads/${ud.filename}`
                        //_sku.image_url=`/assets/images/uploads/${ud.filename}`
                    }
                    //_skus[isProd[1]] = _sku;



                })

                //setSku(_skus)
                if (product.id) {
                    updateProduct({
                        variables: {
                            data: _product,
                            id: _product.id
                        },
                        onCompleted: ({ login }) => {
                            //localStorage.setItem("AUTH_TOKEN", login.token);
                            console.log(login)
                            //document.cookie = 'token=' + login.token
                            // document.cookie = 'signedin=true'
                            // isLoggedInVar(true);

                        }, onError: ({ graphQLErrors, networkError }) => {
                            console.log(networkError)

                            console.log(graphQLErrors)
                            //  localStorage.setItem("AUTH_TOKEN", login.token);
                            //history.push('/');
                        },
                    }).then(data => {
                        //history.push('/products/list');
                        props.setisLoading(false)
                        history.push('/products/list');
                    })

                } else {

                    saveProduct({
                        variables: {
                            data: _product,
                            id: _product.id
                        },
                        onCompleted: ({ login }) => {
                            //localStorage.setItem("AUTH_TOKEN", login.token);
                            console.log(login)
                            //document.cookie = 'token=' + login.token
                            // document.cookie = 'signedin=true'
                            // isLoggedInVar(true);

                        }, onError: ({ graphQLErrors, networkError }) => {
                            console.log(networkError)

                            console.log(graphQLErrors)
                            //  localStorage.setItem("AUTH_TOKEN", login.token);
                            //history.push('/');
                        },
                    }).then(data => {
                        props.setisLoading(false)
                        history.push('/products/list');
                    })

                }

            })
            .catch(error => {
                console.error(error)
                props.setisLoading(false)
            })
    }

    const handleInvalidSubmit = (event, errors, values) => {
        //this.setState({ email: values.email, error: true });
    }
    const handleDeleteImage = (idx) => {
        let _product = { ...product }
        let _files = [...files]
        let _pictures = [...pictures]
        files.splice(idx, 1)
        _pictures.splice(idx, 1)

        setPicture(_pictures)
        setFiles(_files)
        _product.dokul_product_images = _pictures
        setProduct(_product)

    }
    const _handleImgAdd = (e, i) => {
        e.preventDefault();
        setimageError(false)
        let _product = { ...product }
        let reader = new FileReader();
        let file = e.target.files[0];
        let _pictures = [...pictures]
        //const jmlPic = pictures.length;
        const newPic = { image_url: "", product_id: product.id }
        // if (product.id)
        //     files.push({ idx: i, file: file });
        // else
        files.push(file);
        reader.onloadend = () => {
            newPic.image_url = reader.result;
            _pictures.push(newPic)
            _product.dokul_product_images = _pictures
            setFile(file)
            setProduct(_product)
            setFiles(files)

        }
        reader.onerror = (e) => {
            console.log("error" + e)
        }
        reader.readAsDataURL(file)
    }


    const handleProductValueChange = (e) => {
        const { name, value } = e.target
        let _product = { ...product }
        _product[name] = value

        setProduct(_product)
    }
    const handleProductRestrictChange = (e) => {
        let _product = { ...product }
        _product["is_restricted"] = e != 1 ? 1 : 0
        console.log(_product)
        setProduct(_product)

    }
    const handleProductPremiumChange = (e) => {
        let _product = { ...product }
        _product["is_premium"] =e
        setProduct(_product)

    }
    const handleChangeCategory = (e, i, kategori) => {
        let _product = { ...product }
        let _categories = [..._product.dokul_tags_categories]
        const found = _categories.find(element => element.id == kategori.id);
        if (e.target.checked)
            _categories.push(kategori)
        else {
            const index = _categories.indexOf(found)
            _categories.splice(index, 1)
        }
        _product.dokul_tags_categories = _categories;
        setProduct(_product)
        const pilu = `${file}${i}`;
        setFile(pilu)
    }
    const handleChangeRole = (e, i, role) => {
        let mapRole = { ...role }
        let _product = { ...product }
        let _roles = [..._product.dokul_role_products]
        const found = _roles.find(element => element.role_id == role.id);
        if (e.target.checked)
            _roles.push({
                role_id: role.id,
                product_id: product.id
            })
        else {
            const index = _roles.indexOf(found)
            _roles.splice(index, 1)
        }
        _product.dokul_role_products = _roles;
        setProduct(_product)
        const pilu = `${file}${i}`;
        setFile(pilu)
    }

    return (

        <Fragment>
            {!product ? <div>loading</div> : <>
                <div className="row product-adding">
                    <div className="col-xl-12">
                        <div >
                            <Alert color="danger" isOpen={warningChangeBranch} fade={true}>
                                Minimal terdapat 1 variant produk
                            </Alert>
                        </div>
                        <AvForm className="needs-validation add-product-form" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
                            <div className="form form-label-center">
                                <div className={`form-group mb-3 row border-danger ${imageError ? 'border' : ''}`}>

                                    <label className="col-xl-3 col-sm-4 mb-0">Gambar produk :</label>

                                    <div className="col-sm-1">
                                        <label htmlFor="fileUpload">
                                            <Plus style={{ cursor: "pointer" }} />
                                        </label>


                                        <input id="fileUpload" type="file" multiple accept="image/*" onChange={(e) => _handleImgAdd(e, product.dokul_product_images.length)} style={{
                                            // position: "absolute",
                                            // width: "100%",
                                            // height: "100%",
                                            //left: 0,
                                            //right: 0,
                                            //opacity: 0,
                                            display: "none",
                                            cursor: "pointer"
                                            //å cursor: "pointer",
                                            // margin: "auto"
                                        }} />
                                    </div>
                                    <div className="col-xl-7 col-sm-7 ">
                                        {imageError ? <small className="text-danger">Masukan setidaknya satu gambar</small> : ''}

                                        <div className="file-upload-product d-flex flex-nowrap">
                                            {
                                                pictures.map((res, i) => {
                                                    return (
                                                        <div key={i} className='img-front m-1' style={{ padding: 0, width: 100 }}>
                                                            <div className="box-input-file-lg"  >
                                                                <img src={`${res.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${res.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />

                                                                {/* <img src={`${pictures[activeImg] && pictures[activeImg]?.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${pictures[activeImg]?.image_url}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" /> */}
                                                                <div className="product-hover">
                                                                    <ul>
                                                                        <li>
                                                                            <div className="box-input-file-lg"  >
                                                                                <button className="btn" type="button" onClick={() => handleDeleteImage(i)}>
                                                                                    <Trash2 className="deleteBtn" />
                                                                                </button>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group mb-3 row">
                                    <label className="col-xl-3 col-sm-4 mb-0">Dokterkulitku :</label>
                                    <div className="col-xl-8 col-sm-7">
                                        <div className="form-check">
                                            <input name="branch_id" className="form-check-input" type="radio" defaultChecked={product.branch_id == 1} id="gridCheck1" value={1} onChange={handleProductValueChange} />
                                            <label className="form-check-label" htmlFor="gridCheck1">Jakarta</label>
                                        </div>
                                        <div className="form-check">
                                            <input name="branch_id" className="form-check-input" type="radio" defaultChecked={product.branch_id == 2} id="gridCheck1" value={2} onChange={handleProductValueChange} />
                                            <label className="form-check-label" htmlFor="gridCheck1">Balikpapan</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group mb-3 row">
                                    <label className="col-xl-3 col-sm-4 mb-0">Nama produk :</label>
                                    <div className="col-xl-8 col-sm-7">
                                        <AvField className="form-control" name="name" id="validationCustom01" value={product.name} type="text" required onChange={handleProductValueChange} />
                                    </div>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="form-group mb-3 row">
                                    <label className="col-xl-3 col-sm-4 mb-0">Singkatan :</label>
                                    <div className="col-xl-4 col-sm-3">
                                        <AvField className="form-control" name="nick_name" id="validationCustom01" value={product.nick_name} type="text" required onChange={handleProductValueChange} />
                                    </div>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="form-group mb-3 row">
                                    <label className="col-xl-3 col-sm-4 mb-0">Akses umum :</label>
                                    <div className="col-xl-8 col-sm-7">
                                        {/* <Badge color={`${product.is_restricted != 1 ? "success" : "danger"}`} style={{ cursor: 'pointer', backgroundColor: `${product.is_restricted != 1 ? "" : "red"}` }} className="p-2 " onClick={(e) => handleProductRestrictChange(product.is_restricted)}><span>{`${product.is_restricted != 1 ? "PUBLIC" : "ONLY SELECTED"}`}</span></Badge>
                                        <small className="ml-2 p-2 text-dark">{`${product.is_restricted != 1 ? "produk saat ini dapat diakses umum" : "produk saat ini hanya dapat diakses oleh member yang terpilih"}`}</small> */}
                                        {/* <AvField className="form-control" name="name" id="validationCustom01" value={product.name} type="text" required onChange={handleProductValueChange} /> */}
                                        <div className='m-2'>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input bg-main" type="checkbox" id="flexSwitchCheckDefault1" defaultChecked={!product.is_restricted} onChange={(e) => {
                                                     handleProductRestrictChange(product.is_restricted)
                                                }} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"><small className="ml-2 p-2 text-dark">{`${product.is_restricted != 1 ? "produk saat ini dapat diakses umum" : "produk saat ini hanya dapat diakses oleh member yang terpilih"}`}</small> </label>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="form-group mb-3 row">
                                    <label className="col-xl-3 col-sm-4 mb-0">Premium :</label>
                                    <div className="col-xl-8 col-sm-7">
                                        <div className='m-2'>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input bg-main" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={product.is_premium} onChange={(e) => {
                                                    // console.log(e.target.checked)
                                                    // setrequiredProduk(e.target.checked)
                                                    handleProductPremiumChange(e.target.checked?1:0)
                                                }} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="form-group mb-3 row align-items-start">
                                    <label className="col-xl-3 col-sm-4 mb-0">Kategori  :</label>
                                    <Popover
                                        placement="right"
                                        isOpen={popoverOpen}
                                        target="Popover-cat"
                                        toggle={toggle}
                                    >
                                        <PopoverHeader><div className="d-flex p-2"><span className="mr-3" onClick={toggle}><i className="fa fa-close" /></span>Pilih kategori </div></PopoverHeader>

                                        <div className="text-justify">
                                            <div className="row" style={{ margin: 10 }}>
                                                <div className="col">


                                                    <label className="d-block mb-2" >
                                                        {props.categories.map((kategori, i) =>
                                                            <FormGroup key={i} check>
                                                                <Label check>
                                                                    <Input type="checkbox" id={`ketgories ${i}`} label={kategori.description} checked={product.dokul_tags_categories.some(ct1 => ct1.id == kategori.id)} value={kategori} onClick={(e) => handleChangeCategory(e, i, kategori)} />{' '}
                                                                    {kategori.description}
                                                                </Label>
                                                            </FormGroup>
                                                            // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                                            // <>
                                                            //     
                                                            //     
                                                            // </>
                                                        )}
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </Popover>
                                    <div className="col-xl-8 col-sm-7">

                                        <Badge color="secondary" id="Popover-cat" style={{ cursor: 'pointer' }} className="p-2 "><i className="fa fa-pencil "></i><span>Edit</span></Badge>
                                        {/* <ListGroup>
                                                                {product.dokul_tags_categories.map(kl => {
                                                                    return <ListGroupItem className="justify-content-between">{kl.name}</ListGroupItem>
                                                                })}
                                                            </ListGroup> */}
                                        <div className="d-flex flex-wrap">
                                            {product.dokul_tags_categories.map((kl, klidx) => {
                                                // var letters = '123456789ABCDEF';
                                                // var color = '#';
                                                // for (var i = 0; i < 6; i++) {
                                                //     color += letters[Math.floor(Math.random() * 16)];
                                                // }
                                                // const randomColor = letters[Math.floor(Math.random() * 16777215).toString(16)];
                                                // const randomTextColor = Math.floor(Math.random() * 16777215).toString(16);
                                                // console.log(randomColor)
                                                return <div key={klidx} className="p-1"><Badge color="light" style={{ cursor: 'pointer', }} className="p-1 border rounded text-dark">{kl.name}</Badge></div>
                                            })}

                                        </div>

                                        {/* {props.data.categories.map((kategori, i) =>
                                                            // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                                           <CustomInput  key={i} type="checkbox" id={`ketgories ${i}`} label={kategori.description} checked={product.dokul_tags_categories.some(ct1=>ct1.id==kategori.id)} value={kategori} onClick={(e)=>handleChangeCategory(e,i,kategori)} />
                                                           
                                                        )} */}


                                    </div>
                                </div>
                                <div className="form-group mb-3 row align-items-start">
                                    <label className="col-xl-3 col-sm-4 mb-0">Role  :</label>
                                    <Popover
                                        placement="right"
                                        isOpen={popoverOpenRole}
                                        target="Popover-role"
                                        toggle={toggleRole}
                                    >
                                        <PopoverHeader><div className="d-flex p-2"><span className="mr-3" onClick={toggleRole}><i className="fa fa-close" /></span>Pilih role </div></PopoverHeader>

                                        <div className="text-justify">
                                            <div className="row" style={{ margin: 10 }}>
                                                <div className="col">


                                                    <label className="d-block mb-2" >
                                                        {props.dRole.roles.map((role, i) =>
                                                            <FormGroup key={i} check>
                                                                <Label check>
                                                                    <Input type="checkbox" id={`role ${i}`} checked={product.dokul_role_products.some(ct1 => ct1.role_id == role.id)} value={role} onClick={(e) => handleChangeRole(e, i, role)} />{' '}
                                                                    {role.role_name}
                                                                </Label>
                                                            </FormGroup>
                                                            // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                                            // <>
                                                            //     
                                                            //     
                                                            // </>
                                                        )}
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </Popover>
                                    <div className="col-xl-8 col-sm-7">

                                        <Badge color="secondary" id="Popover-role" style={{ cursor: 'pointer' }} className="p-2 "><i className="fa fa-pencil "></i><span>Edit</span></Badge>
                                        {/* <ListGroup>
                                                                {product.dokul_tags_categories.map(kl => {
                                                                    return <ListGroupItem className="justify-content-between">{kl.name}</ListGroupItem>
                                                                })}
                                                            </ListGroup> */}
                                        <div className="d-flex flex-wrap">
                                            {product.dokul_role_products.map((kl, klidx) => {
                                                // var letters = '123456789ABCDEF';
                                                // var color = '#';
                                                // for (var i = 0; i < 6; i++) {
                                                //     color += letters[Math.floor(Math.random() * 16)];
                                                // }
                                                // const randomColor = letters[Math.floor(Math.random() * 16777215).toString(16)];
                                                // const randomTextColor = Math.floor(Math.random() * 16777215).toString(16);
                                                // console.log(randomColor)
                                                return <div key={klidx} className="p-1"><Badge color="light" style={{ cursor: 'pointer', }} className="p-1 border rounded text-dark">{props.dRole.roles.find(x => x.id == kl.role_id).role_name}</Badge></div>
                                            })}

                                        </div>

                                        {/* {props.data.categories.map((kategori, i) =>
                                                            // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                                           <CustomInput  key={i} type="checkbox" id={`ketgories ${i}`} label={kategori.description} checked={product.dokul_tags_categories.some(ct1=>ct1.id==kategori.id)} value={kategori} onClick={(e)=>handleChangeCategory(e,i,kategori)} />
                                                           
                                                        )} */}


                                    </div>
                                </div>
                                <div className="form-group mb-3 row">
                                    <label className="col-xl-3 col-sm-4 mb-0">Meta tag :</label>
                                    <div className="col-xl-8 col-sm-7">
                                        <AvField className="form-control" name="tags" id="validationCtags" value={product.tags} type="text" required onChange={handleProductValueChange} />
                                    </div>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="form-group mb-3 row">
                                    <label className="col-xl-3 col-sm-4 mb-0">Video :</label>
                                    <div className="col-xl-8 col-sm-7">
                                        <AvField className="form-control" name="video" id="validationCustom01" value={product.video} type="text" onChange={handleProductValueChange} />
                                    </div>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>

                                <div className="form-group row align-items-start">
                                    <label className="col-xl-3 col-sm-4 text-align-top align-items-start">Deskripsi :</label>
                                    <div className="col-xl-8 col-sm-7 description-sm">
                                        <CKEditors
                                            activeclassName="p10"
                                            content={content}
                                            events={{
                                                "blur": (val) => {
                                                    // let _product = { ...product }
                                                    // _product.description = val.editor.getData()
                                                    // setProduct(_product)
                                                    setContent(val.editor.getData())
                                                },
                                                //"afterPaste": (val) => product.description=val.editor.getData(),
                                                "change": (val) => {
                                                    // let _product = { ...product }
                                                    // // console.log(_product)
                                                    // //console.log(val.editor.getData())
                                                    // _product.description = val.editor.getData()
                                                    // setProduct(_product)
                                                    setContent(val.editor.getData())
                                                    // handleChangeDesc(val.editor.getData())
                                                },
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row align-items-start">
                                    <div className="col-xl-3 col-sm-4 p-2"><label className=" d-flex align-items-start text-align-top">Deskripsi tambahan :</label> </div>

                                    <div className="col-xl- col-sm-7 description-sm">
                                        <div className="row mt-3">
                                            <div className="col-12 mb-2">
                                                <span className="bg-success p-2 rounded" onClick={() => {
                                                    let p = { ...product }
                                                    let desc = [...p.dokul_product_descriptions]


                                                    desc.push({ product_id: product.id })
                                                    p.dokul_product_descriptions = desc

                                                    setProduct(p)

                                                }}><i className="fa fa-plus " /> Tambahin deskripsinya</span>
                                            </div>
                                            <div className="col-12 mb-2">
                                                {product.dokul_product_descriptions.map((cd, cdidx) =>
                                                    <div key={cdidx} className="d-flex flex-row bd-highlight mb-3 w-100 clearfix">
                                                        <div className="p-2 bd-highlight border"><input type="text" className="form-group" value={cd.title} onChange={(e) => {
                                                            let p = { ...product }
                                                            let desc = [...p.dokul_product_descriptions]
                                                            let idx = desc.indexOf(cd)
                                                            let newDesc = { ...desc[idx] }
                                                            newDesc.title = e.target.value
                                                            desc[idx] = newDesc

                                                            p.dokul_product_descriptions = desc

                                                            setProduct(p)

                                                        }}></input></div>
                                                        <div className="p-2 bd-highlight"> <input type="text" className="form-group" value={cd.description} onChange={(e) => {
                                                            let p = { ...product }
                                                            let desc = [...p.dokul_product_descriptions]
                                                            let idx = desc.indexOf(cd)
                                                            let newDesc = { ...desc[idx] }
                                                            newDesc.description = e.target.value
                                                            desc[idx] = newDesc
                                                            p.dokul_product_descriptions = desc
                                                            setProduct(p)

                                                        }} /></div>

                                                        <div className="p-2 bd-highlight ">
                                                            <div className="float-right">
                                                                {/* <span className="bg-info p-1 mr-1 align-midle rounded"><i className="fa fa-edit" /></span> */}
                                                                <span className="bg-warning p-1 rounded" onClick={() => {
                                                                    let p = { ...product }
                                                                    let desc = [...p.dokul_product_descriptions]
                                                                    let idx = desc.indexOf(cd)

                                                                    desc.splice(idx, 1)
                                                                    p.dokul_product_descriptions = desc

                                                                    setProduct(p)

                                                                }}><i className="fa fa-close" /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {/* {product.dokul_product_descriptions.map(cd =>
                                                                    <div className="row border-bottom">
                                                                        <div className="col-4">
                                                                            <h6>{cd.title}</h6>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <input value={cd.description} />
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <span className="bg-info p-1 mr-1 align-midle rounded"><i className="fa fa-edit" /></span>
                                                                            <span className="bg-warning p-1 rounded"><i className="fa fa-close" /></span>
                                                                        </div>
                                                                    </div>
                                                                )} */}
                                        </div>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            {/* {product.id ? <>
                                <button type="button" className="btn btn-light" onClick={(e) => history.push('/products/list')}>Cancel</button>
                                <button type="submit" className="btn btn-primary">{product.id ? 'Update' : 'Save'}</button>
                            </> : ""} */}
                        </AvForm>
                    </div>
                </div>

            </>}
        </Fragment>
    )
    // }
}

export default ProdukDetail
