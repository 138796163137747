import React, { useState, Fragment, useEffect } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import ListKonsultasi from './listkonsultasi';
import ServiceEdit from './service.edit';
import ServiceReservasi from './service.reservasi';
import JadwalDokter from './konsultasi.jadwal';
import { GET_SLOT_KONSULTASI, ADD_SLOTS, ADD_ROWS } from '../../constants/graph'
import Report from './report';
import Branch from '../common/header_components/branchheader';
import Template from './template';


const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;




const Layanans = ({ match }) => {
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    const [selectedBranch, setselectedBranch] = useState(0)
    const { loading, data, error } = useQuery(GET_SLOT_KONSULTASI,);
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    if (error) {
        console.log(error.message);
        Cookies.remove('signedin', '/');
        Cookies.remove('jwt');
        history.push('/');
    }
    const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
    const client = useApolloClient();
    const [addSlots, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(ADD_SLOTS,);

    const saveSlots = (service) => {
        addSlots({
            variables: {
                data: service
            },
            onCompleted: (saveSlots) => {
                console.log("Sukses")
                history.push('/konsultasi/slot');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })

    }
    const [addRow, { loading: mutationCategoryLoading, error: mutationCategoryError, called: mutationCategoryCalled },] = useMutation(ADD_ROWS,);
    const saveRow = (category) => {
        const t = addRow({
            variables: {
                data: category
            },
            onCompleted: (data) => {
                console.log("Sukses")
                history.push('/products/categories');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })
        return t

    }


    const handleImageUpload = async (file, name) => {
        //const files = event.target.files
        const formData = new FormData()
        formData.append('uploaded_file', file, `${name}_${file.name}`)


        const response = await fetch('http://localhost:9000/api/upload', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                return data

            })
            .catch(error => {
                console.error(error)
            })
    }

    // useEffect(() => {
    //     if (user.dokul_user_roles.some(x => x.role_id == 5)) {
    //         setselectedBranch(3)
    //     } else if (false) {

    //     }
    // }, [])




    return (
        <Fragment>
            {loading ? <div>Loading</div> : <>
                <Breadcrumb title="Konsultasi" />
                <Branch selectedBranch={selectedBranch} setselectedBranch={setselectedBranch} />
                {
                    match.params.page == 'list' ?
                        <ListKonsultasi  user={user}  data={data} saveSlots={addSlots} handleImageUpload={handleImageUpload} mutationLoading={mutationLoading} mutationError={mutationError} />
                        : match.params.page == 'reservasi' ?
                            <ServiceReservasi data={data} client={client} />
                            : match.params.page == 'edit' ?
                                <ServiceEdit data={data.layanans.find(l => l.id == match.params.id)} id={match.params.id} saveRow={addRow} />
                                : match.params.page == 'add' ?
                                    <ListKonsultasi  user={user}  data={data} saveProduct={saveSlots} history={history} mutationCalled={mutationCalled} mutationError={mutationError} />
                                    : match.params.page == 'jadwal' ?
                                        <JadwalDokter data={data} saveSlots={addSlots} handleImageUpload={handleImageUpload} mutationLoading={mutationLoading} mutationError={mutationError} />
                                        : match.params.page == 'report' ? <Report selectedBranch={selectedBranch} />
                                            : match.params.page == 'template' ? <Template selectedBranch={selectedBranch} />
                                                : <ListKonsultasi data={data} saveSlots={addSlots} handleImageUpload={handleImageUpload} mutationLoading={mutationLoading} mutationError={mutationError} user={user} />
                }
            </>
            }

        </Fragment>
    )

}

export default withApollo(Layanans)
