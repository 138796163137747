import React, { Component, Fragment } from 'react'

import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from 'moment'
import DatePicker from "react-datepicker";

import { Collapse, Button, CardBody, Card, Input } from 'reactstrap';

export class PointLogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: this.props.data.pointlogs.totalPages,
            loading: false,
            checkedValues: [],
            myData: this.props.data.pointlogs.PointLogs,
            isOpen: false,
            monthSelected: 0,
            dateSelected: 0,
            selectedDate: Date()
        }
        this.fetchData = this.fetchData.bind(this);
    }
    async fetchData(state, instance) {
        
        let id;
        let date;
        let balik;
        let query='';
console.log(state.filtered)

        state.filtered.forEach(element => {
            
            if (element.id == 'created_date') {
                var d = moment(element.value)
                var od = moment(this.state.selectedDate)
                if (d.date() == od.date())
                    balik = true;
                date=element.value
                query=`${query}${element.id}=${date}&`

            }else{
                //balik = true;
                query=`${query}${element.id}=${element.value}&`
            }

        })
        if (balik) return

        const t = await this.props.getNextData(state.pageSize, state.page, query)
        console.log(query)
        this.setState({
            myData: t.data.pointlogs.PointLogs,
            pages: t.data.pointlogs.totalPages,
            selectedDate:date
        })

        // console.log(t)
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        //this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        //   requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
        //     res => {
        //       // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        //       this.setState({
        //         // data: res.rows,
        //         // pages: res.pages,
        //         loading: false
        //       });
        //     }
        //   );
    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    openFilter = (i) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { myData, pages, loading, isOpen, selectedDate } = this.state
        const columns = [];

        console.log(this.props)
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Order ID")}</b>,
        //         accessor: "id",
        //         filterable: true,
        //         Cell: (data) => {
        //             return '#' + data.original.id

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
            columns.push(
                {
                    Header: <b>{this.Capitalize("Order id")}</b>,
                    accessor: "orderid",
                    filterable: true,
                    Cell: (data) => {
                        return data.original.orderid
    
                    },
                    style: {
                        textAlign: 'center'
                    }
                });
                columns.push(
                    {
                        Header: <b>{this.Capitalize("date")}</b>,
                        accessor: "earneddate",
                        //filterable: true,
                        Cell: (data) => {
                            var d = moment.unix(data.original.earneddate)
                            return d.format("DD MMMM YYYY");
        
                        },
                        style: {
                            textAlign: 'center'
                        }
                    });
                columns.push(
                    {
                        Header: <b>{this.Capitalize("Userid")}</b>,
                        accessor: "userid",
                        filterable: true,
                        Cell: (data) => {
                            return data.original.userid
        
                        },
                        style: {
                            textAlign: 'center'
                        }
                    });
        columns.push(
            {
                Header: <b>{this.Capitalize("log type")}</b>,
                //accessor: "order_status",
                Cell: (data) => {
                    return data.original.earnedpoints!=0?<span className="badge badge-success">Earned</span>:<span className="badge badge-info">Redeem</span>

                },
                style: {
                    textAlign: 'center'
                }
            });
            columns.push(
                {
                    Header: <b>{this.Capitalize("Point")}</b>,
                    //accessor: "order_status",
                    Cell: (data) => {
                        return data.original.earnedpoints!=0?data.original.earnedpoints:data.original.redeempoints
    
                    },
                    style: {
                        textAlign: 'center'
                    }
                });
                columns.push(
                    {
                        Header: <b>{this.Capitalize("Total point")}</b>,
                        //accessor: "order_status",
                        Cell: (data) => {
                            return data.original.totalpoints
        
                        },
                        style: {
                            textAlign: 'center'
                        }
                    });
                    
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("order date")}</b>,
        //         accessor: "created_date",
        //         filterable: true,
        //         Filter: ({ filter, onChange }) => (
        //             <Input
        //                 type="date"
        //                 name="date"
        //                 id="exampleDate"
        //                 placeholder="date placeholder"
        //                 onChange={event => {
        //                     console.log(event.target)
        //                     onChange(event.target.value)
        //                 }
        //                 }
        //                 value={filter ? filter.value : ""}
        //             />
        //             //             <DatePicker 
        //             //             selected={selectedDate}
        //             // onChange={date =>this.changeDate(date)}
        //             // dropdownMode="select"
        //             // />
        //         ),
        //         Cell:(row)=>{
        //             return moment(row.original.created_date).format("YYYY-MM-DD HH:mm:ss")
        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Total")}</b>,
        //         accessor: "grand_total",
        //         Cell: (data) => {
        //             return data.original.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Payment")}</b>,
        //         accessor: "payment_method_text",
        //         Cell: (data) => {
        //             const pay = data.original.payment_method_text.toLowerCase()
        //             return <span className={`badge badge-${pay.includes('bca') ? 'info' : pay.includes('mandiri') ? 'warning' : pay.includes('qris') ? 'primary' : 'light'}`}>{`${pay.includes('bca') ? 'BCA' : pay.includes('mandiri') ? 'MANDIRI' : pay.includes('qris') ? 'QRIS' : pay}`}</span>

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });


        return (
            <Fragment>
                <div className="container-fluid">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                {/* <div className="card-header">
                                    <h5>Point logs</h5>
                                </div> */}
                                {/* <div className="container">
                                    <Button color="primary" onClick={this.openFilter} style={{ marginBottom: '1rem' }}><i className='fa fa-filter'></i></Button>
                                    <Collapse isOpen={isOpen}>
                                        <Card>
                                            <CardBody>
                                                Anim pariatur cliche reprehenderit,
                                                enim eiusmod high life accusamus terry richardson ad squid. Nihil
                                                anim keffiyeh helvetica, craft beer labore wes anderson cred
                                                nesciunt sapiente ea proident.
          </CardBody>
                                        </Card>
                                    </Collapse>
                                </div> */}
                                <div className="card-body order-datatable">

                                    <ReactTable
                                        data={myData}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className={"-striped -highlight"}
                                        showPagination={pagination}
                                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                        pages={pages} // Display the total number of pages
                                        loading={loading} // Display the loading overlay when we need it
                                        onFetchData={this.fetchData} // Request new data when things change
                                        sorted={this.state.sorted}
                                        onSortedChange={(newSort, column) => {
                                            this.setState({ sorted: newSort });
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PointLogs
