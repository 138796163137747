import { socket } from './index';
//import  store from '../../store'
import Cookies from 'js-cookie';
import { MESSAGE_FROM_GUEST, MESSAGE_FROM_GUEST_ROOM, MESSAGE_FROM_USER_TO_DOKTER,MESSAGE_FROM_WA_GUEST_ROOM } from '../../constants/socket';
export const socketEvents = ({ setValue }) => {
  // const{currentUser}=store.getState()
  console.log(Cookies.getJSON());


  if (false) {

    socket.on('admin:group:messagei', (message) => {
      console.log('ssss' + message)
      setValue(state => {
        var oledMessageList = state.message

        oledMessageList.push({ body: message, fromMe: false })
        return { ...state, oledMessageList }

      });
    });
  }
  socket.on(MESSAGE_FROM_GUEST_ROOM, (message) => {
    console.log('new')
    console.log(message)

    let id=message.from?message.from:message.id
    
    setValue(state => {
      console.log(state)
      var oledMessageList = state.message
      if(oledMessageList.length>0){
      //cari id jika ada push ke dalem message nya
      let oldMsgUser=oledMessageList.find(om=>om.id==id)
      if(oldMsgUser){
        oldMsgUser.userinfo=message.userinfo
        oldMsgUser.message.push({ body: message.message, fromMe: false,from:message.from,to:message.to,socketId:message.id })
      }else{
        oledMessageList.push({ id:id,message:[{ body: message.message, fromMe: false,create_date:Date(),from:message.from,to:message.to,socketId:message.id  }], userinfo: message.userinfo })
      }
    }else{
      oledMessageList.push({ id:id,message:[{ body: message.message, fromMe: false,create_date:Date(),from:message.from,to:message.to,socketId:message.id  }], userinfo: message.userinfo })
    }
      return { ...state, oledMessageList }

    });
    //setValue(state => { return { ...state, queueLength } });
  });

  socket.on(MESSAGE_FROM_WA_GUEST_ROOM, (message) => {
    console.log('new')
    console.log(message)
    
    setValue(state => {
      console.log(state)
      var oledMessageList = state.message
      if(oledMessageList.length>0){
      //cari id jika ada push ke dalem message nya
      let oldMsgUser=oledMessageList.find(om=>om.id==message.id)
      if(oldMsgUser){
        oldMsgUser.userinfo=message.userinfo
        oldMsgUser.message.push({ body: message.message, fromMe: false })
      }else{
        oledMessageList.push({ id:message.id,message:[{ body: message.message, fromMe: false,create_date:Date() }], userinfo: message.userinfo })
      }
    }else{
      oledMessageList.push({ id:message.id,message:[{ body: message.message, fromMe: false,create_date:Date() }], userinfo: message.userinfo })
    }
      return { ...state, oledMessageList }

    });
    //setValue(state => { return { ...state, queueLength } });
  });

  socket.on('connection', () => {
    console.log(`I'm connected with the back-end`);
    console.log(socket)
    setValue(state => {
      
      return { ...state, socketId:socket.id }

    });
  });
  socket.on("WA_AUTHETICATED", (data) => {
    setValue(state => {
      
      return { ...state, waconnect:data}

    });
    console.log(data)

  })

  socket.on(MESSAGE_FROM_USER_TO_DOKTER, (data) => {
    console.log(data);
    setValue(state => {
      console.log(state)
      var oledMessageList = state.konsulMessage
      if(oledMessageList.length>0){
      //cari id jika ada push ke dalem message nya
      let oldMsgUser=oledMessageList.find(om=>om.id==data.id)
      if(oldMsgUser){
        oldMsgUser.userinfo=data.userinfo
        oldMsgUser.message.push({ body: data.message.body, fromMe: false })
      }else{
        oledMessageList.push({ id:data.id,message:[{ body: data.message.body, fromMe: false,create_date:Date() }], userinfo: data.userinfo })
      }
    }else{
      oledMessageList.push({ id:data.id,message:[{ body: data.message.body, fromMe: false ,create_date:Date()}], userinfo: data.userinfo })
    }
      return { ...state, oledMessageList }

    });
  });

  
  socket.on('positionInLine', ({ positionInLine }) => {
    setValue(state => { return { ...state, positionInLine } });
  });

};