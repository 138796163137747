import React, { useContext, useRef, useEffect, useState, createContext } from "react";
import SocketContext from '../../provider/socketctx/context';
import DispatchContext from '../../provider/socketctx/disptachContext';
import { joinDokterPrivateRoom, joinKonsulRoom, sendNewMessage, sendNewMessageDokterToUser } from "../../provider/socket/emits";
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import moment from 'moment'
import Modal from 'react-responsive-modal';
//const SocketContext = createContext(null);
const GET_USERS_KONSULTASI = gql`query konsultasionline {
  
konsulonline @rest(type: "Konsulonline",path: "api/dokter/konsultasionline") {
    dokul_dokter
    dokul_users_konsultasis
    name
    iddokter
    id
  
}

    
  }
`;

const ADD_USER_CART = gql`
    mutation addUserCart(
      $data: any
    ) {
      userCart(body: $data)
      @rest(
        type: "Post"
        path: "api/dokter/createusercart"
        method: "POST"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
const Lobby = (props) => {
    const { loading, data, error } = useQuery(GET_USERS_KONSULTASI,);
    const { positionInLine, queueLength, message, konsulMessage } = useContext(SocketContext);
    const [addUserCart, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(ADD_USER_CART,);
    const [productModal, setProductModal] = useState(false)
    useEffect(() => {
        setProduk({ items: props.data.products })
    }, [productModal])
    const newMessage = useContext(DispatchContext)
    const bottomRef = useRef();
    const [selectedKonsul, setselectedKonsul] = useState(0)
    const [listKonsul, setlistKonsul] = useState([])

    const [mymessage, setMyMessage] = useState("")
    const [datakeranjang, setKranjang] = useState({ items: [] })
    const [dataproduk, setProduk] = useState({ items: props.data.products })
    const filterProduct = (e) => {
        if (e.target.value != "") {
            var prodFIlter = props.data.products.filter(pr => pr.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setProduk({ items: prodFIlter })
        }
        else
            setProduk({ items: props.data.products })


    }
    const scrollToBottom = () => {
        if (bottomRef.current)
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });

    };
    useEffect(() => {
        scrollToBottom()
    }, [message.join(",")])
    useEffect(() => {

    }, [message])
    /// joinDokterPrivateRoom({ idDokter: 1 })

    if (loading) return 'Loading...';
    if (listKonsul.length == 0) {
        let kolost = []
        data.konsulonline.forEach(element => {
            element.dokul_users_konsultasis.forEach(element2 => {
                joinKonsulRoom({ roomid: element2.roomid })
                let listChat = [...element2.dokul_chat_konsultasis]
                listChat.reverse().forEach(element3 => {
                    newMessage({ id: element3.roomid, message: { body: element3.message, fromMe: element3.from_id == 1, create_date: element3.create_date } }, true)

                });
                kolost.push(element2)
            });
        });

        setlistKonsul(kolost)
    }


    return (
        <div className="container">

            <h3 className=" text-center">KONSULTASI</h3>
            <div className="messaging">
                <div className="inbox_msg">
                    <div className="inbox_people">
                        <div className="headind_srch">
                            {/* <div className="recent_heading">
                                <h4></h4>
                            </div> */}
                            <div className="srch_bar">
                                <div className="stylish-input-group">
                                    <input type="text" className="search-bar" placeholder="Search" />
                                    <span className="input-group-addon">
                                        <button type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                                    </span> </div>
                            </div>
                        </div>
                        <div className="inbox_chat">
                            <div className="chat_list">
                                {/* {console.log(data)} */}
                                {
                                    //data?.konsulonline?.map((ko,index)=>{
                                    //  return 
                                    listKonsul.map((duk, idx) =>
                                        <div className={`chat_people ${idx == selectedKonsul ? "activechat" : ""}`} style={{}} onClick={() => setselectedKonsul(idx)}>

                                            <div className="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                                            <div className="chat_ib">
                                                <h5>{duk.dokul_user?.email}  </h5>
                                                {/* <p>{duk.status_text} </p>
                                                <p className="small text-dark">{duk.keluhan} </p>
                                                <p className="small text-dark">{duk.roomid} </p> */}
                                            </div>
                                        </div>
                                    )
                                    //})
                                }


                            </div>

                        </div>
                    </div>
                    <div className="mesgs">
                        <div className="msg_history">


                            {konsulMessage.find(km => km.id == listKonsul[selectedKonsul]?.roomid)?.message.map((msg, i) => {

                                return msg.fromMe ?
                                    <div key={i} ref={bottomRef} className="outgoing_msg">
                                        <div className="sent_msg">
                                            <p>{msg.body}</p>
                                            <span className="time_date"> {moment(msg.create_date).format("YYYY-MM-DD HH:mm:ss")} </span> </div>
                                    </div>
                                    :
                                    <div ref={bottomRef} key={i} className="incoming_msg"   >
                                        <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                                        <div className="received_msg">
                                            <div className="received_withd_msg">
                                                <p>{msg.body}</p>
                                                <span className="time_date"> {moment(msg.create_date).format("YYYY-MM-DD HH:mm:ss")} </span></div>
                                        </div>
                                    </div>


                            })}

                            {/* <div ref={bottomRef} className="list-bottom"></div> */}
                        </div>

                        <div className="type_msg">
                            <div className="input_msg_write">
                                <input value={mymessage} type="text" className="write_msg" placeholder="Type a message" onChange={(e) => setMyMessage(e.target.value)} />
                                <button className="msg_send_btn" type="button" onClick={() => {



                                    sendNewMessageDokterToUser({
                                        roomid: listKonsul[selectedKonsul].roomid,
                                        idUser: listKonsul[selectedKonsul].id_user,
                                        slotId: 1,
                                        idDokter: 1,
                                        message_type: 1,
                                        id: listKonsul[selectedKonsul].id,
                                        message: { body: mymessage, fromMe: true },
                                        userinfo: {}
                                    });

                                    //newMessage(props.props.info.email);
                                    newMessage({ id: listKonsul[selectedKonsul].roomid, message: { body: mymessage, fromMe: true } }, true)
                                    setMyMessage("")
                                    //scrollToBottom()
                                }}><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                            </div>
                        </div>

                    </div>
                    <div className="">
                        <div className="row">
                            <div className="card w-100" >

                                <div className="card-body">

                                    <div className="row">
                                        <h5 className="card-title col-12 text-info border-bottom pb-2  ">Data pasien</h5>
                                        <div className="col-md-6">
                                            <dl className="dl-inline ">
                                                <dt>Nama</dt>
                                                <dd>{listKonsul[selectedKonsul]?.dokul_user?.display_name}</dd>
                                                <dt>E-mail</dt>
                                                <dd>{listKonsul[selectedKonsul]?.dokul_user?.email}</dd>


                                            </dl>
                                        </div>
                                        <div className="col-md-6">
                                            <dt>Keluhan</dt>
                                            <dd>{listKonsul[selectedKonsul]?.keluhan}</dd>
                                            <dt>DX</dt>
                                            <dd>HTYFG</dd>
                                        </div>
                                        <h5 className="card-title col-12 text-info border-bottom pb-2">Keranjang pasien</h5>
                                        {datakeranjang.items.map((kr, index) => {
                                            return <div class="d-flex flex-row bd-highlight mb-2 justify-content-between border w-100" >
                                                <div className="p-2 bd-highlight">{kr.name}</div>
                                                <div className="p-2 bd-highlight">
                                                    <button type="button" className="close" aria-label="Close" onClick={() => {

                                                        var newIt = datakeranjang.items
                                                        const idx = datakeranjang.items.indexOf(kr)

                                                        const newCartItem = {
                                                            userId: listKonsul[selectedKonsul]?.dokul_user?.id,
                                                            product_id: kr.id,
                                                            sku_id: kr.dokul_products_skus[0].id,
                                                            quantity: 1,
                                                            id: null
                                                        }
                                                        //   console.log(newCartItem)
                                                        //   return;

                                                        addUserCart({
                                                            variables: {
                                                                data: newCartItem
                                                            },
                                                            onCompleted: (saveSlots) => {
                                                                console.log("Sukses")
                                                                // history.push('/products');
                                                            }, onError: ({ graphQLErrors, networkError }) => {
                                                                console.log("ada eror")
                                                                console.log(networkError)
                                                                console.log(graphQLErrors)
                                                            },
                                                        }).then(data => {
                                                            console.log(data)
                                                            // this.onCloseModal()
                                                        })

                                                        newIt.splice(idx, 1)
                                                        setKranjang({ items: newIt })
                                                    }} >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button></div>
                                            </div>



                                        })}
                                    </div>
                                </div>
                                <div className="card-body">

                                </div>

                                <div className="card-body">
                                    <a href="#" className="btn " onClick={() => setProductModal(true)}>Tambah produk</a>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>



            </div>
            <Modal open={productModal} onClose={() => setProductModal(false)} role="document">
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">Data produk</h5>
                </div>
                <div className="modal-body">

                    <div className="col-md-12">
                        <div className="row">
                            <div className="headind_srch col-md-12 w-100">

                                <div className="srch_bar">
                                    <div className="stylish-input-group">
                                        <input type="text" className="search-bar" placeholder="Cari nama produk" onChange={filterProduct} />
                                        <span className="input-group-addon">
                                            <button pb-2 e="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                                        </span> </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-produk-konsul">
                            <div className="row">
                                {dataproduk.items.map(kr => {
                                    return <div className="col-sm-5 m-1 border rounded" onClick={() => {
                                        var newIt = datakeranjang.items
                                        const idx = datakeranjang.items.indexOf(kr)


                                        const newCartItem = {
                                            userId: listKonsul[selectedKonsul]?.dokul_user?.id,
                                            product_id: kr.id,
                                            sku_id: kr.dokul_products_skus[0].id,
                                            quantity: 1,
                                            id: null
                                        }
                                        //   console.log(newCartItem)
                                        //   return;

                                        addUserCart({
                                            variables: {
                                                data: newCartItem
                                            },
                                            onCompleted: (saveSlots) => {
                                                console.log("Sukses")
                                                // history.push('/products');
                                            }, onError: ({ graphQLErrors, networkError }) => {
                                                console.log("ada eror")
                                                console.log(networkError)
                                                console.log(graphQLErrors)
                                            },
                                        }).then(data => {
                                            console.log(data)
                                            // this.onCloseModal()
                                        })

                                        if (datakeranjang.items.includes(kr))
                                            newIt.splice(idx, 1)
                                        else
                                            newIt.push(kr)
                                        setKranjang({ items: newIt })

                                    }}>
                                        
                                        <div style={datakeranjang.items.includes(kr) ? { backgroundColor: "gainsboro", margin: 5, padding: 5, textAlign: "center", cursor: "pointer" } : { backgroundColor: "unset", cursor: "pointer", margin: 5, padding: 5, textAlign: "center" }}>

                                        <div className="d-flex flex-row justify-content-start">
                                            <div className="p-2">
                                                <div className="d-flex flex-column">
                                                    <img src={`${process.env.REACT_APP_IMAGE_HOST}${kr.dokul_product_images[0]?.image_url}`} height="100px" alt="" /><br />
                                                    
                                                </div>
                                            </div>
                                            <div className="p-2">
                                                <div className="d-flex flex-column text-left">
                                                <div className="p-0"> <span className="text-justify">{kr.name}</span></div>
                                                <div className="p-0"> <span className="font-weight-bold">{kr.dokul_products_skus[0].price}</span></div>
                                                <div className="p-0"> <span className={`rounded p-1 mt-2 ${kr.dokul_products_skus[0].status=="instock"?"bg-success":kr.dokul_products_skus[0].status=="outstock"?"bg-danger text-danger":"bg-success"}`}>{kr.dokul_products_skus[0].status}</span></div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                           
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-small btn-primary" onClick={()=>setProductModal(false)} >Selesai</button>
                    </div>
            </Modal>

        </div>

    )
}
export default withApollo(Lobby)