import gql from 'graphql-tag';

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;
export const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $email: String!
    $password: String!
  ) {
    login(body: $email)
    @rest(
      type: "Post"
      path: "auth/local"
      method: "POST"
      bodyKey: "body"
    ) {
    token
    user
    data
  }
  }
`;
export const GET_SLOT_KONSULTASI = gql`query slotkonsultasi {
  
  slots @rest(type: "Slots",path: "api/konsultasi/slot?page=0&size=10") {
    data @type(name: "Slot") { 
      id
      create_date
      sold_date
      id_dokter
      id_user
      payment
      payment_text
      payment_status
      status
      description
      dokul_dokter
      dokul_user
      dokul_users_konsultasi
               
    } ,
  currentPage
  totalItems
  totalPages
    
}

  
}
`;
export const GET_LIST_KONSULTASI_EKSPRESS = gql`query konsultasiekspress {
  
  konsultasiekspress @rest(type: "KonsultasiEkspress",path: "api/konsultasi/konsultasiexpress") {
    id
    jawaban
    robot_id
}

  
}
`;
export const GET_LIST_KONSULTASI_HISTORY_PRODUK= gql`query historyProdukKonsultasi (
  $id:id!
){ 
  
  historyProdukKonsultasi(id:$id) @rest(type: "HistoryProdukKonsultasi",path: "api/konsultasi/kontrolHistoryProduk/{args.id}") {
    id
    dokul_konsultasi_kontrol_result_produks
}

  
}
`;
export const DELETE_LIST_KONSULTASI_HISTORY_PRODUK = gql`
    mutation deleteHistoryProduk(
      $id: String
    ) {
      deleteHistoryProduk(id: $id)
      @rest(
        type: "HistoryProdukKonsultasi"
        path: "api/konsultasi/kontrolHistoryProduk/{args.id}"
        method: "DELETE"
      ) {
        id
       
    }
    }
  `;

export const GET_LIST_KONSULTASI_KONTROL=gql`query submitkontrol (
  
  $params:params!
){
  
  submitkontrol(params:$params) @rest(type: "SubmitKontrols",path: "api/konsultasi/kontrolList?{args.params}") {
    data @type(name: "SubmitKontrol") { 
      id
      user_id
      dokter_id
      created_date
      modify_date
      status
      payment_id
      grand_total
      payment_log
      dokul_user
      branch_id
      dokul_dokter
      dokul_paymethod
      dokul_konsultasi_kontrol_result_produks
      masalah
      question_answer
      result_question
      bukti_bayar 
      is_marked
      question_date
      answer_date 
      is_rerequest
      status_admin
      by_dokter
      dokterhandle
      konsul_type
      konsul_id   
      produk_required    
    } ,
  currentPage
  totalItems
  totalPages
 
    
}

  
}
`;



export const GET_LIST_VOUCHER_PRIVATE=gql`query voucherPrivate (
  
  $params:params!
){
  
  voucherPrivate(params:$params) @rest(type: "VoucherPrivate",path: "api/voucher/private?{args.params}") {
    data @type(name: "VoucherPrivate") { 
      amount
      branch_id
      created_by    
      created_date
      id
      status
      used_date
      user_id
      voucher_for
      dokul_user
      code
      expired_date
      active_days
    } ,
  currentPage
  totalItems
  totalPages
 
    
}

  
}
`;

export const GET_LIST_SUBMIT_KONSULTASI = gql`query submitkonsultasi (
  $page:String!
  $size:String!
  $topicid:String!
  $robot_id:robot_id!
  $email:email!
){
  
  submitkonsultasis(email:$email,page:$page,size:$size,topicid:$topicid,robot_id:$robot_id) @rest(type: "SubmitKonsultasis",path: "api/konsultasi//konsultasiexpress/submitdata?page={args.page}&size={args.size}&topic_id={args.topicid}&robot_id={args.robot_id}&email={args.email}") {
    data @type(name: "SubmitKonsultasi") { 
      id
      idx_step
      is_approved
      is_publish
      result_msg
      robot_id
      topic
      topic_id
      user_id
      dokul_user
      date_created
               
    } ,
  currentPage
  totalItems
  totalPages
    
}

  
}
`;

export const GET_LIST_KONSULTASI = gql`query konsultasi {
  konsultasi @rest(type: "Konsultasi",path: "api/konsultasi/list") {
      id
      harga
      voucher_back
      refund_back
      payment_first
      id_klinik
      id_product
      id_sku
      iddokter
      name
      status
      type
      dokul_dokter
      dokul_klinik
      max_pasien
      root_question
      next_root
      is_unlimited
      dokul_user_konsultasi_kontrols
    }
  }
`;

// export const GET_PRODUCTS = gql`query products {
//     products @rest(type: "Product",path: "api/products") {
//         name,
//             dokul_products_skus
//             branchId,
//             dokul_tags_categories,
//             dokul_product_images
//             dokul_tags
//             id
//             description
//     }
//   }
// `;
export const GET_PRODUCTSold = gql`query products {
  products @rest(type: "Product",path: "api/products") {
      name,
          dokul_products_skus
          branch_id,
          dokul_tags_categories,
          dokul_product_images
          tags
          id
          description
          dokul_product_descriptions
          is_restricted
          video
          nick_name
          dokul_role_products
          is_premium
  }
  categories @rest(type: "Category",path: "api/products/categories") {
      id,
      name,
      description
  }
}
`;
export const GET_IDPRODUCTS_PREORDER = gql`query productspo {
  productspo @rest(type: "ProductPo",path: "api/products/produkPreOrder") {
          dokul_products_sku
  }
  
}
`;
export const GET_PRODUCTS = gql`query products(
  $page:String!
  $brand: String!
  $categories: String!
  $textSearch: String!
  $typeStock:String!
         
)  {
  products (page:$page,brand:$brand,categories:$categories,textSearch:$textSearch,typeStock:$typeStock)
  @rest(type: "Product",path: "api/products/admin?size=16&typeStock={args.typeStock}&page={args.page}&brand={args.brand}&categories={args.categories}&textSearch={args.textSearch}") {
      
          data
          totalItems
          totalPages
          currentPage
          hasMore
  }
  
}
`;

export const GET_PRODUCTS_SIMPLE = gql`query productssimple(
  $brand: String!
  $textSearch: String!
         
)  {
  productssimple (brand:$brand,textSearch:$textSearch)
  @rest(type: "Product",path: "api/products/simpleProducts?brand={args.brand}&textSearch={args.textSearch}") {
      
          branch_id
          id
          name
          nick_name
          dokul_products_skus
          dokul_product_images
  }
  
}
`;


export const GET_KONSUL_PAYMENT_DETAIL = gql`query konsulPaymentDetail (
$id:String
) {
  konsulPaymentDetail(id:$id) @rest(type: "KonsulPaymentDetail",path: "api/konsultasi/xenditPayDetail/{args.id}?all=true") {
    charge
    cb
  }
  
}
`;
export const UPDATE_USER = gql`
    mutation updateUser(
      $data: any
    ) {
      updateUser(body: $data)
      @rest(
        type: "User"
        path: "api/users/sync/role"
        method: "POST"
        bodyKey: "body"
      ) {
        id
        username
        email
        password
        first_name
        last_name
        display_name
        create_date
        modify_date
        role
        status
        wcid
        current_point
        current_grade
        current_slot
        current_address
        phone
        jenis_kulit
        warna_kulit
        branch_id
        role_id
    }
    }
  `;
  export const UPDATE_USER_ROLE = gql`
    mutation updateUserRole(
      $data: any
    ) {
      updateUserRole(body: $data)
      @rest(
        type: "UserRole"
        path: "api/users/update/role"
        method: "PUT"
        bodyKey: "body"
      ) {
        id
        username
        email
        password
        first_name
        last_name
        display_name
        create_date
        modify_date
        role
        status
        wcid
        current_point
        current_grade
        current_slot
        current_address
        phone
        jenis_kulit
        warna_kulit
        branch_id
        role_id
    }
    }
  `;
  export const UPDATE_USER_KONTROL = gql`
    mutation updateSubmitKontrol(
      $data: any
      $id: String!
    ) {
      updateSubmitKontrol(body: $data,id:$id)
      @rest(
        type: "SubmitKontrol"
        path: "api/konsultasi/kontrol/{args.id}"
        method: "PUT"
        bodyKey: "body"
      ) {
        id
        status
    }
    }
  `;
  export const ADD_REQUEST_TAMBAHAN = gql`
  mutation addRequestTambahan(
    $data: any
    $id: String!
  ) {
    addRequestTambahan(body: $data,id:$id)
    @rest(
      type: "RequestTambahan"
      path: "api/konsultasi/addRequest/{args.id}"
      method: "POST"
      bodyKey: "body"
    ) {
      id
      kontrol_id
      re_ans
      re_req
      status
      type
  }
  }
`;
export const DELETE_REQUEST_TAMBAHAN = gql`
  mutation deleteRequestTambahan(
    $id: String!
    
  ) {
    deleteRequestTambahan(body: $data,,id:$id)
    @rest(
      type: "RequestTambahan"
      path: "api/konsultasi/delRequest/{args.id}"
      method: "DELETE"
      bodyKey: "body"
    ) {
      id
  }
  }
`;
  export const DELETE_USER_KONTROL = gql`
  mutation deleteSubmitKontrol(
    $id: String!
  ) {
    deleteSubmitKontrol(body: $data,id:$id)
    @rest(
      type: "SubmitKontrol"
      path: "api/konsultasi/kontrol/{args.id}"
      method: "DELETE"
      bodyKey: "body"
    ) {
      id
      status
  }
  }
`;
  export const UPDATE_STOCK = gql`
    mutation updateStock(
      $data: any
    ) {
      updateStock(body: $data)
      @rest(
        type: "Product"
        path: "api/products/gudang/sku"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;

export const GET_GUDANGS = gql`query gudangs {
  gudangs @rest(type: "Gudangs",path: "api/gudang") {
    data @type(name: "Gudang") { 
      id
      nama
      alamat
      branch_id
      gudang_kabkkot
      gudang_prov 
      gudang_postcode
      gudang_kec        
    } ,
    currentPage
    totalItems
    totalPages
  }
  
}
`;
export const GET_LAYANANS = gql`query layanans {
  layanans @rest(type: "Layanan",path: "api/layanan") {
        id
        name
        deskripsi
        picture
        banner
        type
        dokul_page
        dokul_kliniks
        id_kliniks
    }
    reservasis @rest(type: "Reservasis",path: "api/reservasi/list?page=0&size=10") {
      data @type(name: "Reservasi") { 
        id
        id_klinik
        id_parent
        create_by
        create_date
        modify_date
        email
        fullName
        description
        date_selected
        status_pasien
        phone
        nama_layanan
        status
        tujuan
        status_text
        dokul_service
        dokul_klinik           
      } ,
    currentPage
    totalItems
    totalPages
      
  }
    kliniks @rest(type: "Klinik",path: "api/dokter/klinik") {
      id
      dokul_dokter_konsultasis
      address
      banner
      branchid
      name
      tlp
  }
    
  }
`;
export const GET_JADWAL_PERAWATAN = gql`query jadwalPerawatan(
$param: String!
) {
  jadwalPerawatan(param:$param) @rest(type: "JadwalPerawatan",path: "api/reservasi/jadwalperawatan?{args.param}") {
      id
      id_klinik
      id_services
      is_template
      jumlah
      sesi
      status
      tanggal
      template_id
      biaya
      jadwal_type
      parent_id
      dokul_reservasis
      dokul_services_jadwal_sesis
      time_cutoff
      start_date
      
  }
 
    
  }
`;
export const GET_DOKTERS = gql`query dokters {
dokters @rest(type: "Dokter",path: "api/dokter") {
  id
  dokul_dokter_konsultasis
  fullname
  picture
  picture
  available
}
  
}
`;
export const GET_JADWAL_TEMPLATES = gql`query templates {
  templates @rest(type: "JadwalTemplate",path: "api/konsultasi/template") {
    id
    template_name
    dokul_jadwal_sesis
    id_klinik
    id_dokter
    id_layanan
    is_static
  }
    
  }
  `;
export const GET_JADWAL_LIBUR = gql`query liburs {
  data @rest(type: "JadwalLibur",path: "api/reservasi/jadwallibur") {
    id
    message
    id_dokter
    start_date
    end_date
    id_klinik
    id_layanan
    libur_for
    jam
  }
    
  }
  `;
export const ADD_JADWAL_LIBUR= gql`
mutation addJadwalLibur( $body: String!) {
  addAnsTemplate(body: $body) 
  @rest(
    type: "JadwalLibur"
    path: "api/reservasi/addjadwallibur"
    headers:"s"
    method: "POST"
    bodyKey: "body"
  ){
    id_dokter
      start_date
      end_date
      message
            
  }
}
`;
export const DELETE_JADWAL_LIBUR= gql`
mutation deleteJadwalLibur( $id: String!) {
  deleteJadwalLibur(id: $id) 
  @rest(
    type: "JadwalLibur"
    path: "api/reservasi/deletejadwallibur/{args.id}"
    headers:"s"
    method: "DELETE"
   
  ){
    id_dokter
      start_date
      end_date
      message
            
  }
}
`;


export const GET_CONFIGS = gql`query configs {
  configs @rest(type: "Configs",path: "api/branch/allconfig") {
    about_banner
    about_text
    address_pickup
    admin_fee
    dokul_configs
    dokul_paymethods
    dokul_shippingmethods
    dokul_gudangs
    dokul_kliniks
    id
    is_pickup_klinik
    konsul_banner
    konsul_text
    name
    page_meta
    page_title
    picture
    dokul_payments
  }
    
  }
  `;

  export const GET_CONFIGS_KONSUL_ANSTEMPLATE = gql`query ansTemplate {
    ansTemplate @rest(type: "AnsTemplate",path: "api/branch/konsulAnsTemplate") {
      branch_id
      id
      urutan
      name
      text
      konsul_id
    }
      
    }
    `;
    export const UPDATE_TEMPLATE_JAWABAN_KONSULTASI= gql`
mutation updateAnsTemplate($id: String!, $body: String!) {
  updateAnsTemplate(id: $id, body: $body) 
  @rest(
    type: "AnsTemplate"
    path: "api/konsultasi/templateJawaban"
    headers:"s"
    method: "PUT"
    bodyKey: "body"
  ){
    branch_id
      id
      name
      text
            
  }
}
`;
export const ADD_TEMPLATE_JAWABAN_KONSULTASI= gql`
mutation addAnsTemplate( $body: String!) {
  addAnsTemplate(body: $body) 
  @rest(
    type: "AnsTemplate"
    path: "api/konsultasi/templateJawaban"
    headers:"s"
    method: "POST"
    bodyKey: "body"
  ){
    branch_id
      id
      name
      text
            
  }
}
`;
export const DELETE_TEMPLATE_JAWABAN_KONSULTASI= gql`
mutation addAnsTemplate( $id: String!) {
  addAnsTemplate(id: $id) 
  @rest(
    type: "AnsTemplate"
    path: "api/konsultasi/templateJawaban/{args.id}"
    headers:"s"
    method: "DELETE"
   
  ){
    branch_id
      id
      name
      text
            
  }
}
`;

  export const   GET_TRANSACTIONS = gql`query getTransactions (
    $page: String!
    $size: String!
    $id:String!
    $date:String!
    $provider:String!
    $nominal:String!
) {
    transactions(page:$page,size: $size,id:$id,date:$date,provider:$provider,nominal:$nominal) @rest( type: "Transactions",path: "api/order/transactionlist?size={args.size}&page={args.page}&date={args.date}&provider={args.provider}&id={args.id}&nominal={args.nominal}") {
      transactions @type(name: "Transaction") { 
        id
        type
        tanggal
        keterangan
        nominal
        mutasi
        methode_id
        modify_date
      },
        currentPage
        totalItems
        totalPages
    }   
  }
`;

  export const UPDATE_CONFIGS= gql`
mutation UpdateConfigs($id: String!, $body: String!) {
  updateConfigs(id: $id, body: $body) 
  @rest(
    type: "Configs"
    path: "api/branch/allconfig"
    headers:"s"
    method: "PUT"
    bodyKey: "body"
  ){
    about_banner
    about_text
    address_pickup
    admin_fee
    dokul_configs
    dokul_paymethods
    dokul_shippingmethods
    dokul_kliniks
    id
    is_pickup_klinik
    konsul_banner
    konsul_text
    name
    page_meta
    page_title
    picture
            
  }
}
`;
export const CREATE_CONFIGS= gql`
mutation UpdateConfigs($id: String!, $body: String!) {
  updateConfigs(id: $id, body: $body) 
  @rest(
    type: "Configs"
    path: "api/branch/allconfig"
    headers:"s"
    method: "POST"
    bodyKey: "body"
  ){
    about_banner
    about_text
    address_pickup
    admin_fee
    dokul_configs
    dokul_paymethods
    dokul_shippingmethods
    dokul_kliniks
    id
    is_pickup_klinik
    konsul_banner
    konsul_text
    name
    page_meta
    page_title
    picture
            
  }
}
`;

export const UPDATE_SHIPMENT_METHOD= gql`
mutation saveShipmentMethode($id: String!, $body: String!) {
  updateConfigs(id: $id, body: $body) 
  @rest(
    type: "Configs"
    path: "api/branch/allconfig"
    headers:"s"
    method: "PUT"
    bodyKey: "body"
  ){
    branch_id
    gudang_id
    id
    status
    
            
  }
}
`;




export const RELOAD_BANK_TRANS= gql`
mutation ReloadTransacitons($id: String!, $body: String!) {
  reloadTransacitons(id: $id, body: $body) 
  @rest(
    type: "Transactions"
    path: "api/integrasi/{args.id}"
    headers:"s"
    method: "PUT"
    bodyKey: "body"
  ){
    id
        type
        tanggal
        keterangan
        nominal
        mutasi
            
  }
}
`;

  export const GET_ROBOT_GUIDE = gql`query robots($name: String!) {
    robots(name:$name) @rest(type: "Robot",path: "api/branch/robotguide/{args.name}") {
      id
      name
      question
      answer
      result
      edge
    }
      
    }
    `;

    export const UPDATE_ROBOT_GUIDE= gql`
mutation UpdateRobot($id: String!, $body: String!) {
  updateRobot(id: $id, body: $body) 
  @rest(
    type: "Robot"
    path: "api/branch/robotguide/{args.id}"
    method: "PUT"
    bodyKey: "body"
  ){
    id
      name
    question
      answer
      result
            
  }
}
`;
export const UPDATE_ROBOT_GUIDE2= gql`
query QUpdateRobot($id: String!, $body: String!) {
  qupdateRobot(id: $id, body: $body) 
  @rest(
    type: "Robot"
    path: "api/branch/robotguide/{args.id}"
    method: "PUT"
    bodyKey: "body"
  ){
    id
      name
    question
      answer
      result
            
  }
}
`;
export const   GET_USERS = gql`query getUsers(
  $page: String!
  $size: String!
  $email:String!
  $first_name:String!
  $date:String!
  $role:String!
  $grade:String!
) {
  users(first_name:$first_name,page:$page,size: $size,email:$email,date:$date,role:$role,grade:$grade) @rest(type: "Users",path: "api/users?current_grade={args.grade}&size={args.size}&page={args.page}&email={args.email}&date={args.date}&role={args.role}&first_name={args.first_name}") {
    users @type(name: "User") { 
      id
      role_id
      user_id
      email
      dokul_user
    },
      currentPage
      totalItems
      totalPages
  }   
}
`;

export const   GET_USERSOLD = gql`query getUsers(
  $page: String!
  $size: String!
  $email:String!
  $first_name:String!
  $date:String!
  $role:String!
) {
  users(first_name:$first_name,page:$page,size: $size,email:$email,date:$date,role:$role) @rest(type: "Users",path: "api/users?size={args.size}&page={args.page}&email={args.email}&date={args.date}&role={args.role}&first_name={args.first_name}") {
    users @type(name: "User") { 
      id
      username
      email
      password
      first_name
      last_name
      display_name
      create_date
      modify_date
      role
      status
      wcid
      current_point
      current_grade
      current_slot
      current_address
      phone
      jenis_kulit
      warna_kulit
      branch_id
      role_id
    },
      currentPage
      totalItems
      totalPages
  }   
}
`;

export const   GET_USER_DETAIL = gql`mutation getUserDetail(
  $id:String!
) {
  userDetail(id:$id) @rest(type: "User",path: "api/users/userInfo/{args.id}") {
    branch_id
    create_date
    current_address
    current_grade
    current_point
    current_slot
    display_name
    dokul_addresses
    dokul_carts
    dokul_onlineslots
    dokul_orders
    dokul_user_products
    dokul_user_providers
    dokul_user_wishlists
    email
    first_name
    gender
    id
    image_url
    jenis_kulit
    last_name
    masalah_lain
    masalah_utama
    modify_date
    password
    phone
    role
    role_id
    status
    status_kawin
    tempat_lahir
    tgl_lahir
    username
    warna_kulit
    wcid
    dokul_user_roles
    dokul_voucher_privates
    is_validemail
  }   
}
`;

export const   GET_USER_DETAIL_QUERY = gql`query getUserDetail(
  $id:String!
) {
  userDetail(id:$id) @rest(type: "User",path: "api/users/userInfo/{args.id}") {
    branch_id
    create_date
    current_address
    current_grade
    current_point
    current_slot
    display_name
    dokul_addresses
    dokul_carts
    dokul_onlineslots
    dokul_orders
    dokul_user_products
    dokul_user_providers
    dokul_user_wishlists
    email
    first_name
    gender
    id
    image_url
    jenis_kulit
    last_name
    masalah_lain
    masalah_utama
    modify_date
    password
    phone
    role
    role_id
    status
    status_kawin
    tempat_lahir
    tgl_lahir
    username
    warna_kulit
    wcid
    dokul_user_roles
    dokul_voucher_privates
  }   
}
`;

export const GET_DOKPOINTS = gql`query points(
  $page: String!
  $size: String!
  $query:String!
)  {
  
  points @rest(type: "Point",path: "api/poin") {
      id,
      name,
      status,
      multipier_by,
      earned_type,
      earned,
      create_by
      modify_by
      dokul_point_rules,
      dokul_point_products
      dokul_point_categories
      dokul_point_grades
  }
}
`;
export const GET_VOUCHERS = gql`query vouchers(
  $page: String!
  $size: String!
  $query:String!
)  {
  
  vouchers @rest(type: "Voucher",path: "api/voucher") {
      id,
      name,
      create_date
      expired_date
      auto_apply
      is_limited
      is_active
      is_fixed
      voucher_value
      is_private
      isPrivate
      branch_id
      voucher_for
      voucher_max
      earn_type
      auto_show
      dokul_voucher_rules,
      dokul_voucher_products
      dokul_voucher_categories
      dokul_voucher_grades
      dokul_voucher_details
  }
}
`;
export const GET_LOG_DOKPOINTS = gql`query points(
  $page: String!
  $size: String!
  $query:String!
)  {
  pointlogs(page:$page,size: $size,query:$query) @rest(type: "PointLogs",path: "api/poin/logs?size={args.size}&page={args.page}&{args.query}") {
      PointLogs @type(name: "PointLog") {
          id
          earnedpoints
          redeempoints
          userid
          earneddate
          expirydate
          checkpoints
          earnedequauivalentamount
          redeemequauivalentamount
          orderid
          productid
          variationid
          refuserid
          reasonindetail
          totalpoints
          showmasterlog
          showuserlog
          nomineeid
          nomineepoints
      } ,
      currentPage
      totalItems
      totalPages
  }
}
  
`;
export const GET_ROLES = gql`query roles {
  roles @rest(type: "ROLE",path: "api/users/roles/list") {
    id
    dokul_role_permissions
    role_name
    status
    create_by
    create_date
  }
    
  }
  `;
export const UPDATE_DOKTER = gql`
mutation UpdateDokter($id: String!, $type: String!) {
  updateDokter(id: $id, body: $type) 
  @rest(
    type: "Dokter"
    path: "api/dokter"
    method: "PUT"
    bodyKey: "body"
  ){
    id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
  }
}
`;
export const UPDATE_JADWAL_PERAWATAN = gql`
mutation UpdateJadwalPerawatan($type: any!) {
  updateJadwalPerawatan(body: $type) 
  @rest(
    type: "JadwalPerawatan"
    path: "api/reservasi/addjadwalperawatan"
    method: "POST"
    bodyKey: "body"
  ){
    id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
  }
}
`;
export const SAVE_POINT_PRODUCTS = gql`
mutation savePointProducts($type: String!) {
  savePointProducts(body: $type) 
  @rest(
    type: "PointProduct"
    path: "api/poin/poinproducts"
    method: "PUT"
    bodyKey: "body"
  ){
    id
    product_id
    category_id
    point_id
    status
  }
}
`;
export const SAVE_POINT = gql`
mutation savePoint(
  $data: any
) {
    savePoint(body: $data)
  @rest(
    type: "Post"
    path: "api/poin"
    method: "POST"
    bodyKey: "body"
  ) {
  completed
}
}
`;
export const SAVE_VOUCHER_PRODUCTS = gql`
mutation saveVoucherProducts($type: String!) {
  saveVoucherProducts(body: $type) 
  @rest(
    type: "VoucherProduct"
    path: "api/voucher/voucherroducts"
    method: "PUT"
    bodyKey: "body"
  ){
    id
    product_id
    voucher_id
    status
  }
}
`;

export const SAVE_VOUCHER_CATEGORIES = gql`
mutation saveVoucherCategories($type: String!) {
  saveVoucherCategories(body: $type) 
  @rest(
    type: "VoucherProduct"
    path: "api/voucher/vouchercategory"
    method: "PUT"
    bodyKey: "body"
  ){
    id
    category_id
    voucher_id
    status
  }
}
`;

export const SAVE_VOUCHER_RULES = gql`
mutation saveVoucherRules($type: String!) {
  saveVoucherRules(body: $type) 
  @rest(
    type: "VoucherProduct"
    path: "api/voucher/voucherrules"
    method: "PUT"
    bodyKey: "body"
  ){
    id
    voucher_rule
    rule_math
    rule_value
    voucher_id
    status
    description
  }
}
`;

export const SAVE_VOUCHER = gql`
mutation saveVoucher(
  $data: any
) {
  saveVoucher(body: $data)
  @rest(
    type: "Post"
    path: "api/voucher"
    method: "POST"
    bodyKey: "body"
  ) {
  completed
}
}
`;
export const SAVE_TEMPLATE = gql`
mutation saveTemplate($type: String!) {
  saveTemplate( body: $type) 
  @rest(
    type: "JadwalTemplate"
    path: "api/konsultasi/template"
    method: "POST"
    bodyKey: "body"
  ){
    id
    template_name
    dokul_jadwal_sesis
  }
}
`;
export const SAVE_SESI_TEMPLATE = gql`
mutation saveSesiTemplate($type: String!) {
  saveSesiTemplate( body: $type) 
  @rest(
    type: "SesiTemplate"
    path: "api/konsultasi/sesitemplate"
    method: "POST"
    bodyKey: "body"
  ){
    id
  }
}
`;
export const DELETE_SESI_TEMPLATE = gql`
mutation deleteSesiTemplate($type: String!) {
  deleteSesiTemplate( body: $type) 
  @rest(
    type: "SesiTemplate"
    path: "api/konsultasi/sesitemplate/{args.body}"
    method: "DELETE"
    
  ){
    id
  }
}
`;
export const DELETE_TEMPLATE = gql`
mutation deleteSesiTemplate($type: String!) {
  deleteSesiTemplate( body: $type) 
  @rest(
    type: "JadwalTemplate"
    path: "api/konsultasi/template/{args.body}"
    method: "DELETE"
    
  ){
    id
  }
}
`;
export const UPDATE_JADWAL_SESI = gql`
mutation updateJadwalSesi($type: String!) {
  updateJadwalSesi(body: $type) 
  @rest(
    type: "JadwalSesi"
    path: "api/konsultasi/sesi"
    method: "PUT"
    bodyKey: "body"
  ){
    id
  }
}
`;
export const ADD_JADWAL_SESI = gql`
mutation addJadwalSesi($type: String!) {
  addJadwalSesi(body: $type) 
  @rest(
    type: "JadwalDokter"
    path: "api/dokter/addJadwal"
    method: "PUT"
    bodyKey: "body"
  ){
    id
  }
}
`;
export const UPDATE_STATUS_SESI = gql`
mutation updateStatusSesi($id: String!,$idsesi: String,$status: String!,$body:any,$tanggal:String!,$jadwalid:String!) {
  updateStatusSesi(id: $id,idsesi:$idsesi,status:$status,body:$body,tanggal:$tanggal,jadwalid:$jadwalid) 
  @rest(
    type: "Jadwal"
    path: "api/reservasi/updateSesiStatus/{args.id}/{args.idsesi}/{args.status}/{args.tanggal}/{args.jadwalid}"
    method: "put"
    bodyKey: "body"
  ){
    id
  }
}
`;
export const UPDATE_JADWAL_STATUS = gql`
mutation updateJadwalStatus($id: String!,$body:any,$status: String!) {
  updateJadwalStatus(id: $id,body:$body,status:$status) 
  @rest(
    type: "Jadwal"
    path: "api/reservasi/updateJadwalStatus/{args.id}/{args.status}"
    method: "PUT"
    bodyKey: "body"
  ){
    id
  }
}
`;
export const GET_RESERVASI = gql`query reservasiKonsul {
  reservasiKonsul @rest(type: "reservasiKonsul",path: "api/konsultasi/reservasi?page=0&size=10") {
      data @type(name: "ReservasiKonsul") { 
        id
        id_klinik
        id_parent
        create_by
        create_date
        modify_date
        email
        fullName
        description
        date_selected
        status_pasien
        phone
        nama_layanan
        status
        tujuan
        status_text
        dokul_service
        dokul_klinik
        dokul_dokter_konsultasi           
      } ,
    currentPage
    totalItems
    totalPages
      
  }

  }
`;
export const GET_KLINIKS = gql`query kliniks {
  
kliniks @rest(type: "Klinik",path: "api/dokter/klinik") {
  id
  dokul_dokter_konsultasis
  address
  banner
  branchid
  name
  tlp
}
  
}
`;

export const ADD_SERVICE = gql`
mutation updateMutation(
  $data: any
) {
  login(body: $data)
  @rest(
    type: "Post"
    path: "api/layanan"
    method: "POST"
    bodyKey: "body"
  ) {
  completed
}
}
`;
export const UPDATE_SERVICE = gql`
mutation updateMutation(
  $data: any
) {
  login(body: $data)
  @rest(
    type: "Put"
    path: "api/layanan"
    method: "PUT"
    bodyKey: "body"
  ) {
  completed
}
}
`;
export const ADD_SLOTS = gql`
    mutation addSlot(
      $data: any
    ) {
      slots(body: $data)
      @rest(
        type: "Post"
        path: "api/konsultasi/slot"
        method: "POST"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
  export const ADD_ROWS = gql`
    mutation updateRows(
      $data: any
    ) {
      updateRows(body: $data)
      @rest(
        type: "Post"
        path: "api/konten/rows"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
export const GET_CATEGORIES = gql`query categories {
    categories @rest(type: "Category",path: "api/products/categories") {
        id,
        name,
        description
    }
  }
`;
export const GET_TAGS = gql`query tags {
    tags @rest(type: "Tag",path: "api/products/tags") {
        id,
        name,
        description
    }
  }
`;
export const GET_DASHBOARD_DATA = gql`query dashboard {
  dashboard @rest(type: "Dashboard",path: "api/branch/dashboard") {
    cancelBysystem,
    cancelByuser,
    newUser
    order
    orderStatus
    reservasi
    successOrder
    transaction
    orderStatistic
    produkDiminati
latestOrder
salesLastMonth
salesLastYear
productOnCart
customerStatistic
  }
}
`;

export const GET_ORDER_BYLOCATION= gql`query orderbylocation {
  orderBylocation @rest(type: "OrderBylocation",path: "api/branch/orderBylocation") {
    orderBylocation
  }
}
`;
export const GET_ORDER_STATUS = gql`query orderstatus(
$option:String!
) {
  orderstatus(option:$option) @rest(type: "Orderstatus",path: "api/branch/orderStatus?{args.option}") {
    jkt
    bpn 
  }
}
`;
export const GET_ORDER_STATUSOK = gql`query orderstatus(
  $option:String!
  ) {
    orderstatus(option:$option) @rest(type: "Orderstatus",path: "api/branch/orderStatusok?{args.option}") {
      jkt
      bpn 
    }
  }
  `;
  export const GET_KONSUL_REPORT = gql`query konsulReport(
    $option:String!
    ) {
      konsulReport(option:$option) @rest(type: "KonsulReport",path: "api/konsultasi/kontrolReport?{args.option}") {
        jkt
        bpn 
      }
    }
    `;
export const GET_SALES_MONTH = gql`query saleslastmonth {
  saleslastmonth @rest(type: "Saleslastmonth",path: "api/branch/saleslastmonth") {
    saleslastmonth
  }
}
`;
export const GET_SALES_YEAR = gql`query salesLastYar {
  salesLastYar @rest(type: "Dashboard",path: "api/branch/saleslastyear") {
    data
    percent
    total
  }
}
`;
export const GET_PRODUK_DIMINATI = gql`query produkDiminati {
  produkDiminati @rest(type: "ProdukDiminati",path: "api/branch/pDiminati") {
    prodcount
    product_id
    product_name
    dokul_order
  }
}
`;
export const GET_LATEST_ORDER = gql`query latestOrder {
  latestOrder @rest(type: "LatestOrder",path: "api/branch/latestOrder") {
    id
    payment_method_text
    order_status_text
    grand_total
  }
}
`;
export const GET_PRODUCT_CART = gql`query pCart {
  pCart @rest(type: "pCart",path: "api/branch/pCart") {
    dokul_product
    dokul_products_sku
    prodcount
    product_id
    branch_id
  }
}
`;
export const GET_CUSTOMER_STATISTIC = gql`query customerStatistic {
  customerStatistic @rest(type: "CustomerStatistic",path: "api/branch/customerStatistic") {
    user_id
    dokul_user
    totalbelanja
    prodcount
  }
}
`;
export const GET_TODAY_ORDER2 = gql`query todayOrder {
  todayOrder @rest(type: "TodayOrder",path: "api/branch/todayOrder") {
    jumlahtransaksi
    tanggal
    total
  }
}
`;
export const GET_TODAY_ORDER = gql`
    query todayOrder(
      $data: any
    ) {
      todayOrder(body: $data)
      @rest(
        type: "TODAYORDER"
        path: "api/branch/todayOrder"
        method: "GET"
        bodyKey: "body"
      ) {
        jumlahtransaksi
        tanggal
        total
    }
    }
  `;
export const GET_TODAY_RESERVASI = gql`query todayReservasi {
  todayReservasi @rest(type: "TodayReservasi",path: "api/branch/todayReservasi") {
    jumlah
  }
}
`;
export const GET_TODAY_USER = gql`query todayUser {
  todayUser @rest(type: "TodayUser",path: "api/branch/todayUser") {
    jumlah
  }
}
`;
export const GET_TODAY_TRANSACTION = gql`query todayTransaction {
  todayTransaction @rest(type: "TodayTransaction",path: "api/branch/todayTransaction") {
    jumlahtransaksi
    tanggal
    total
  }
}
`;




export const   GET_ORDERS = gql`query getOrders (
  $page: String!
  $size: String!
  $id:String!
  $date:String!
  $dateFrom:String!
  $dateTo:String!
  $displayname:String!
  $grand_total:String!
  $branchid:String!
  $statusText:String!
  $layanan_kurir:String
  $premiumOrder:String
) {
  orders(page:$page,size: $size,id:$id,date:$date,dateFrom:$dateFrom,dateTo:$dateTo,displayname:$displayname,grand_total:$grand_total,statusText:$statusText,layanan_kurir:$layanan_kurir,branchid:$branchid,premiumOrder:$premiumOrder) @rest(type: "Orders",path: "api/order?size={args.size}&page={args.page}&id={args.id}&date={args.date}&dateFrom={args.dateFrom}&dateTo={args.dateTo}&displayname={args.displayname}&grand_total={args.grand_total}&statusText={args.statusText}&branchid={args.branchid}&layanan_kurir={args.layanan_kurir}&premiumOrder={args.premiumOrder}") {
      orders @type(name: "Order") { 
          id 
          admin_fee
          billing_address_id
          branch_id
          coupon_discount
          coupon_type
          coupon_used
          created_date
          current_grade
          grand_total
          is_usecoupon
          is_usepoint
          layanan_kurir
          modify_by
          modify_date
          ongkir
          order_status
          order_status_text
          payment_method
          payment_method_text
          point_earned
          point_used
          shipping_address_id
          total
          total_discount
          unix_payment
          user_id
          dokul_address,
          dokul_orders_items
          dokul_order_item_refunds
          dokul_user
          order_notes
          dokul_order_vouchers
          all_item,
          payment_status,
            bukti_bayar,
            nomor_resi
            alamat_kirim
            email
                        
        } ,
      currentPage
      totalItems
      totalPages
  }
 
 
}
`;
export const   GET_ORDERS_NOPAGE = gql`query getOrders (
  $page: String!
  $size: String!
  $id:String!
  $date:String!
  $dateFrom:String!
  $dateTo:String!
  $displayname:String!
  $grand_total:String!
  $branchid:String!
  $statusText:String!
  $layanan_kurir:String
  $premiumOrder:String
) {
  orders(page:$page,size: $size,id:$id,date:$date,dateFrom:$dateFrom,dateTo:$dateTo,displayname:$displayname,grand_total:$grand_total,statusText:$statusText,layanan_kurir:$layanan_kurir,branchid:$branchid,premiumOrder:$premiumOrder) @rest(type: "Orders",path: "api/order?size={args.size}&page={args.page}&id={args.id}&date={args.date}&dateFrom={args.dateFrom}&dateTo={args.dateTo}&displayname={args.displayname}&grand_total={args.grand_total}&statusText={args.statusText}&branchid={args.branchid}&layanan_kurir={args.layanan_kurir}&premiumOrder={args.premiumOrder}") {
      
          id 
          admin_fee
          billing_address_id
          branch_id
          coupon_discount
          coupon_type
          coupon_used
          created_date
          current_grade
          grand_total
          is_usecoupon
          is_usepoint
          layanan_kurir
          modify_by
          modify_date
          ongkir
          order_status
          order_status_text
          payment_method
          payment_method_text
          point_earned
          point_used
          shipping_address_id
          total
          total_discount
          unix_payment
          user_id
          dokul_address,
          dokul_orders_items
          dokul_order_item_refunds
          dokul_user
          order_notes
          dokul_order_vouchers
          all_item,
          payment_status,
            bukti_bayar,
            nomor_resi
            alamat_kirim
            email
                        
        
  }
 
 
}
`;
// transactions @rest(type: "Transactions",path: "api/order/transactionlist?size=100") {
//   transactions @type(name: "Transaction") { 
//     id
//     type
//     tanggal
//     keterangan
//     nominal
//     mutasi
//   }
// }

export const   GET_ORDERS_REFUND = gql`query getOrdersRefund (
  $page: String!
  $size: String!
  $id:String!
  $date:String!
  $displayname:String!
) {
  ordersRefund(page:$page,size: $size,id:$id,date:$date,displayname:$displayname) @rest(type: "OrdersRefund",path: "api/order/refund?size={args.size}&page={args.page}&id={args.id}&date={args.date}&displayname={args.displayname}") {
      ordersRefund @type(name: "OrderRefund") { 
          id
          item_id
          order_id
          refund_status
          dokul_order,
          dokul_orders_item
                        
        } ,
      currentPage
      totalItems
      totalPages
  }
  

 
}
`;
export const listMasalah = [
  "komedo",
  "Jerawat milia/kecil/beruntus",
  "Jerawat besar/bernanah/meradang",
  "Flek hitam",
  "Flek agak abu²",
  "Flek hitam merah",
  "Bekas jerawat merah",
  "Bekas jerawat hitam/gelap",
  "Bekas jerawat bopeng",
  "Wajah mudah merah",
  "Kulit kendur/kerutan"
]
export const jenisKulit = ["Kering", "Normal", "Berminyak", "Sensitif"]
export const warnaKulit = ["Cokelat", "Hitam", "Putih", "Sawo Matang"]
 export const jenisKelamin = ["Pria", "Wanita"]
export const status = ["Single",
  "Single akan menikah dalam waktu dekat",
  "Menikah, tidak menggunakan kontrasepsi",
  "Menikah, dan sudah kontrasepsi kurang dari 3 tahun",
  "Menikah, dan sudah kontrasepsi lebih dari 3 tahun",
  " Menikah, dan berencana hamil dalam waktu dekat",
  " Menikah dan menyusui"]
