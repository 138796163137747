import React from 'react'
import { Col, Label, Row } from 'reactstrap'

export default function FilterPremium(props) {
    return (
        <div className="d-flex flex-column border p-2">

            <Row>
                <Col md='12' ><h6 className="title  m-2">Keperluan</h6></Col>
                <Col md={4}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 3} onChange={() => props.setkeperluan(3)} />
                        <label className="form-check-label" htmlFor="byproduk">Semua</label>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 31} onChange={() => props.setkeperluan(31)} />
                        <label className="form-check-label" htmlFor="byproduk">Perdana wajah</label>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 32} onChange={() => props.setkeperluan(32)} />
                        <label className="form-check-label" htmlFor="byproduk">Perdana kulit</label>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 39} onChange={() => props.setkeperluan(39)} />
                        <label className="form-check-label" htmlFor="byproduk">Kontrol  wajah </label>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={props.keperluan == 33} onChange={() => props.setkeperluan(33)} />
                        <label className="form-check-label" htmlFor="byproduk">Kontrol  kulit </label>
                    </div>
                </Col>

            </Row>


            <hr />



            <Row>
                <Col md='12' ><h6 className="title  m-2">Status</h6></Col>
                <Col md={4}><div className="form-check">
                    <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 0} onChange={() => props.setstatusSelected(0)} />
                    <label className="form-check-label" htmlFor="status">Semua</label>
                </div></Col>
                <Col md={4}> <div className="form-check ">
                    <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 1} onChange={() => props.setstatusSelected(1)} />
                    <label className="form-check-label" htmlFor="status">Menunggu pembayaran</label>
                </div></Col>
                <Col md={4}><div className="form-check">
                    <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 9} onChange={() => props.setstatusSelected(9)} />
                    <label className="form-check-label" htmlFor="status">Menunggu data pasien</label>
                </div></Col>
                <Col md={4}><div className="form-check">
                    <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 2} onChange={() => props.setstatusSelected(2)} />
                    <label className="form-check-label" htmlFor="status">Upload struk</label>
                </div></Col>
                <Col md={4}><div className="form-check">
                    <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 8} onChange={() => props.setstatusSelected(8)} />
                    <label className="form-check-label" htmlFor="status">Menunggu apotek</label>
                </div></Col>
                <Col md={4}><div className="form-check">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 3} onChange={() => props.setstatusSelected(3)} />
                        <label className="form-check-label" htmlFor="status">Belum dijawab 1</label>
                    </div>
                </Col>
                <Col md={4}>  <div className="form-check">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 4} onChange={() => props.setstatusSelected(4)} />
                        <label className="form-check-label" htmlFor="status">Sudah dijawab 1</label>
                    </div>
                </Col>
                <Col md={4}><div className="form-check">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 5} onChange={() => props.setstatusSelected(5)} />
                        <label className="form-check-label" htmlFor="status">Belum dijawab 2</label>
                    </div>
                </Col>
                <Col md={4}> <div className="form-check">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 6} onChange={() => props.setstatusSelected(6)} />
                        <label className="form-check-label" htmlFor="status">Sudah dijawab 2</label>
                    </div>
                </Col>
                <Col md={4}> <div className="form-check">
                        <input className="form-check-input" type="radio" name="status" id="status" checked={props.statusSelected == 7} onChange={() => props.setstatusSelected(7)} />
                        <label className="form-check-label" htmlFor="status">Belum dijawab 1 & 2</label>
                    </div>
                </Col>
            </Row>
            
            <hr />
            <Label>Dokter premium:</Label>
            <div className="d-flex flex-row ">
                {props.dataDokter && props.dataDokter.users.users.filter(x => x.role_id == 14).map((dr, idxdr) => {
                    return <div key={idxdr} className="form-check p-2 m-2 ">
                        <input className="form-check-input" type="checkbox" name="status" value={dr.dokul_user.id} id="status" onChange={props.changeDokter} />
                        <label className="form-check-label" htmlFor="status">{dr.dokul_user.first_name} {dr.dokul_user.last_name}</label>
                    </div>
                })}



            </div>

        </div>
    )
}
