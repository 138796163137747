import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
import data from '../../../assets/data/category';
import Datatable from '../../common/datatable';
import { ToastContainer, toast } from 'react-toastify';
export class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedCategory: {}
        };
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    onDelete = () => {

    }
    onEdit = (cat) => {
        this.setState({
            selectedCategory: cat
        }, () => this.onOpenModal())
    }
    handleChange = (e) => {
        const { name, value } = e.target
        let selectedCategory = this.state.selectedCategory
        selectedCategory[name] = value
        this.setState({
            selectedCategory: selectedCategory
        })
    }
    saveCategory = () => {
        this.props.saveCategory(this.state.selectedCategory).then(result => {
            this.onCloseModal()
        })



    }

    render() {
        const { open, selectedCategory } = this.state
        const { saveCategory } = this.props
        return (
            <Fragment>
                {/* <!-- Container-fluid starts--> */}
                {/* <div className="container-fluid"> */}
                {/* <div className="row"> */}
                {/* <div className="col-sm-12">
                    <div className="mb-2">

                        <button type="button" className="btn btn-primary btn-small float-right" onClick={() => {
                            this.setState({
                                selectedCategory: {}
                            }, () => this.onOpenModal())
                        }}
                        >Tambah Kategori</button>
                    </div>
                </div> */}
                <div className="col-sm-12">
                    {/* <div className="card"> */}

                    <div className="mt-2">
                        <div className="btn-popup pull-right">


                            <Modal open={open} onClose={this.onCloseModal} >
                                <div className="modal-header">
                                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">{selectedCategory.id ? 'Edit category' : 'Add Category'}</h5>
                                </div>
                                <div className="modal-body">
                                    <form autoComplete='off'>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name" className="col-form-label" >Category Name :</label>
                                            <input type="text" className="form-control" name="name" value={selectedCategory.name} onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name" className="col-form-label" >Category Description :</label>
                                            <textarea rows={5} className="form-control" name="description" value={selectedCategory.description} onChange={this.handleChange} />
                                        </div>

                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={this.saveCategory}>Save</button>
                                    <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button>
                                </div>
                            </Modal>
                        </div>
                        <div className="clearfix"></div>
                        <div id="basicScenario" className="">
                            <ul className="list-group">
                            <li className={`list-group-item bg-info w-100`}>KATEGORI <span className="float-end text-success bg-white p-2" style={{cursor:"pointer"}} onClick={() => {
                            this.setState({
                                selectedCategory: {}
                            }, () => this.onOpenModal())
                        }}
                        >ADD</span></li>
                                <li className={`list-group-item ${selectedCategory.name == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    this.setState({ selectedCategory: {} })
                                    this.props.refreshProduct({} )
                                    }}>Semua kategori</li>
                                {this.props.data?.categories.map((cat,idx) =>
                                    <li key={idx}  className={`list-group-item ${selectedCategory.name == cat.name ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ selectedCategory: cat })
                                        this.props.refreshProduct(cat)

                                    }}>{cat.name}</li>
                                )}
                            </ul>
                            {/* <Datatable
                                            onDelete={this.onDelete}
                                            onEdit={this.onEdit}
                                            multiSelectOption={false}
                                            myData={this.props.data.categories} 
                                            hideFields={['__typename','id']}
                                            pageSize={10} 
                                            pagination={true}
                                            class="-striped -highlight" 
                                        /> */}
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Category

