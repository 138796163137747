import {Calendar,
    Home,
    Box,
    DollarSign,
    Tag,
    Clipboard,
    Camera,
    AlignLeft,
    UserPlus,
    Users,
    Chrome,
    BarChart, Settings, Archive, LogIn,Minimize2, Book, Coffee, Layers,File
} from 'react-feather';

export const MENUITEMS = [
    {
        allowedRole:[5,10,11],path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false,adminOnly:true
    },
    {
        allowedRole:[5,10,11,2,3,4],title: 'Data Produk', icon: Layers, type: 'link',path: '/products/list', active: false,
        //  children: [

        //     { path: '/products/categories', title: 'Kategori Produk', type: 'link' },
        //     { path: '/products/list', title: 'List Produk', type: 'link' },
        //     { path: '/products/add', title: 'Tambah produk', type: 'link' },
        // ]
    },
    {
        allowedRole:[5,10,11],title: 'Data Layanan', icon: Clipboard, type: 'link', active: false,  path: '/services/list'
        // children: [

        //     { title: 'List Layanan', icon: Box, type: 'link', active: false, path: '/services/list' },
        //     { title: 'Reservasi', icon: Box, type: 'link', active: false, path: '/services/reservasi' },

        // ]
    },
    // {
    //     title: 'Data Konsultasi', icon: Coffee, type: 'link',path: '/konsultasi/list', active: false
    // },
    {
        allowedRole:[5,10,11,16,17,18],title: 'Konsultasi', icon: DollarSign, type: 'sub', active: false, children: [
            {allowedRole:[5,10,11,16,17,18], path: '/konsultasi/list', title: 'Data konsultasi', type: 'link' },
            {allowedRole:[5,10,11,16,17,18], path: '/konsultasi/report', title: 'Laporan konsultasi', type: 'link' ,adminOnly:true},
            { allowedRole:[5,10,11],path: '/konsultasi/template', title: 'Template jawaban', type: 'link' ,adminOnly:true},
        ]
    },
    {
        allowedRole:[5,10,11,13],title: 'Data Reservasi', icon: Calendar, type: 'link',path: '/reservasi', active: false
    },
    // {
    //     title: 'Data Konsultasi', icon: Coffee, type: 'sub', active: false, children: [
    //         {
    //             title: 'Online', type: 'sub', active: false, children: [
    //                 { path: '/konsultasi/slots', title: 'Slot konsultasi', type: 'link' },
    //                 { path: '/chat/dokter', title: 'Konsultasi', type: 'link' },
    //             ]
    //         },
    //         { path: '/konsultasi/jadwal', title: 'Jadwal Dokter', type: 'link' },
    //         { path: '/konsultasi/reservasi', title: 'Reservasi', type: 'link' },
    //     ]
    // },
    { allowedRole:[5,10,11],path: '/point/list', icon: Book, title: 'Dokpoin', active: false, type: 'link',adminOnly:true },
    { allowedRole:[5,10,11],path: '/voucher/list', icon: File, title: 'Voucher', active: false, type: 'link',adminOnly:true },
    {
        allowedRole:[5,10,11,2,3,4,16,17,18],title: 'Penjualan', icon: DollarSign, type: 'sub', active: false, children: [
            { allowedRole:[5,10,11,2,3,4,16,17,18],path: '/sales/orders', title: 'Pesanan', type: 'link' },
            { allowedRole:[5,10,11,2,3,4,16,17,18],path: '/sales/orders/refund', title: 'Pengembalian', type: 'link' },
            { allowedRole:[5,10,11,2,3,4,16,17,18],path: '/sales/transactions', title: 'Log transaksi bank', type: 'link' },
            { allowedRole:[5,10,11,2,3,4,16,17,18],path: '/sales/report', title: 'Report', type: 'link' ,adminOnly:true},
        ]
    },


    { allowedRole:[5,10,11],path: '/artikels/list', icon: Book, title: 'Artikel', active: false, type: 'link' },
    // {
    //     title: 'Data Dokpoin', icon: Box, type: 'sub', active: false, children: [

    //         { path: '/point/list', title: 'Dokpoin', type: 'link' },
    //         { path: '/point/add', title: 'Tambah Dokpoin', type: 'link' },
    //         { path: '/point/log', title: 'Dokpoin Log', type: 'link' },

    //     ]
    // },
    {
        allowedRole:[5,10,11,2,3,4,], title: 'Data Pengguna', icon: UserPlus,  active: false, path: '/users/roles',type: 'link' 
        // children: [
        //     { path: '/users/roles', title: 'List Pengguna', type: 'link' },
        //     { path: '/users/create-user', title: 'Tambah pengguna', type: 'link' },
        // ]
    },
    {
        allowedRole:[5,10,11],title: 'Tanya Jawab Otomatis', icon: Minimize2, type: 'sub', active: false,adminOnly:true, children: [
            { path: '/rqa/1', title: 'Konsul Ekspres', type: 'link' },
            { path: '/rqa/2', title: 'Konsul Dokbot', type: 'link' },,
            { path: '/rqa/3', title: 'dr. Simon kontrol', type: 'link' },,
            { path: '/rqa/4', title: 'dr. Simon perdana', type: 'link' },,
            { path: '/rqa/5', title: 'dr. A Kontrol r ', type: 'link' },,
            { path: '/rqa/9', title: 'dr. A Kontrol kulit p', type: 'link' },,
            { path: '/rqa/7', title: 'dr. A Kontrol wajah p', type: 'link' },,
            { path: '/rqa/6', title: 'dr. A perdana r', type: 'link' },
            { path: '/rqa/10', title: 'dr. A Perdana kulit p', type: 'link' },
            { path: '/rqa/8', title: 'dr. A Perdana wajah p', type: 'link' },
        ]
    },
    {
        allowedRole:[5,10,11],title: 'Pengaturan', icon: Settings, type: 'link', path: '/settings',adminOnly:true,
        //  children: [
        //     { path: '/settings/jakarta', title: 'Jakarta', type: 'link' },
        //     { path: '/settings/balikpapan', title: 'Balikpapan', type: 'link' },
        //     { path: '/settings/profile', title: 'Lainnya', type: 'link' },

        // ]
    },
    // {
    //     title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
    //         { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
    //         { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Afiliasi', icon: Tag, type: 'sub', active: false, children: [
    //         { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
    //         { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
    //     ]
    // },
    

    // {
    //     title: 'Products', icon: Box, type: 'sub', active: false, children: [
    //         {
    //             title: 'Physical', type: 'sub', active: false, children: [
    //                 { path: '/products/physical/category', title: 'Category', type: 'link' },
    //                 { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
    //                 { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
    //                 { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
    //                 { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
    //             ]
    //         },
    //         {
    //             title: 'digital', type: 'sub', active: false, children: [
    //                 { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
    //                 { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
    //                 { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
    //                 { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
    //             ]
    //         },
    //     ]
    // },
    

    // {
    //     title: 'Reservasi', icon: DollarSign, type: 'sub', active: false, children: [
    //         { path: '/sales/orders', title: 'Orders', type: 'link' },
    //         { path: '/sales/transactions', title: 'Transactions', type: 'link' },
    //     ]
    // },
    

    // {
    //     title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
    //         { path: '/pages/list-page', title: 'List Page', type: 'link' },
    //         { path: '/pages/create-page', title: 'Create Page', type: 'link' },
    //     ]
    // },
    // {
    //     title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
    // },
    // {
    //     title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
    //         { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
    //         { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
    //     ]
    // },

    // {
    //     title: 'Localization', icon: Chrome, type: 'sub', children: [
    //         { path: '/localization/transactions', title: 'Translations', type: 'link' },
    //         { path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
    //         { path: '/localization/taxes', title: 'Taxes', type: 'link' }
    //     ]
    // },
    // {
    //     title: 'Reports', path: '/reports/report', icon: BarChart, type: 'link', active: false
    // },
    
    // {
    //     title: 'Invoice', path: '/invoice', icon: Archive, type: 'link', active: false
    // },
    // {
    //     title: 'Login', path: '/auth/login', icon: LogIn, type: 'link', active: false
    // }
]
