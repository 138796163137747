import React, { Component, Fragment, useMemo, useState } from 'react'
import { useTable } from "react-table";
import { withApollo } from '../../apollo'

import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import { GET_LIST_KONSULTASI, GET_LIST_KONSULTASI_KONTROL } from '../../constants/graph';
import { GET_DOKTERS, GET_KLINIKS, GET_RESERVASI, UPDATE_DOKTER } from '../../constants/graph';
import data from '../../assets/data/physical_list';
import Table from './table';
import JadwalDokter from './konsultasi.jadwal';
import TemplateJadwal from './konsultasi.template';
import ServiceReservasi from './service.reservasi';
import OnlineSlot from './konsultasi.online.slot'
import ListKontrol from './kontrol/list.kontrol';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

const ListKonsultasi = (props) => {

  const { loading, data, error } = useQuery(GET_LIST_KONSULTASI,);
  const [getDataSubmit, { loading: lSubmitKonsul, error: eSubmitKonsul, data: dSubmitKonsul, refetch }] = useLazyQuery(GET_LIST_KONSULTASI_KONTROL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      // setpage(data.submitkontrol.currentPage)
      // settotalPages(data.submitkontrol.totalPages)
      // setlistData(data.submitkontrol.data)
    }
  })
  if (loading || !props.user) return null
  const uRoles = props.user.dokul_user_roles
  

  return (
    <div>

      <Tabs>
        <TabList className="nav nav-tabs tab-coupon" >
          {/* <Tab className="nav-link">Reservasi</Tab> */}
          { uRoles.some(x => [5, 4, 10, 11, 3, 2, 12].indexOf(x.role_id) != -1)&&<Tab className="nav-link">Balikpapan</Tab>}
          { uRoles.some(x => [5, 4, 10, 11, 3, 2,14,18,16,17].indexOf(x.role_id) != -1)&&<Tab className="nav-link">Premium</Tab>}
          { uRoles.some(x => [5, 4, 10, 11, 3, 2,12].indexOf(x.role_id) != -1)&&<Tab className="nav-link">Jakarta</Tab>}
          { uRoles.some(x => [5, 4, 10, 11, 3, 2].indexOf(x.role_id) != -1)&&<Tab className="nav-link">Konsultasi Dokbot</Tab>}
          { uRoles.some(x => [5, 4, 10, 11, 3, 2].indexOf(x.role_id) != -1)&&<Tab className="nav-link">Konsultasi Ekspres</Tab>  }

        </TabList>


        {/* <TabPanel>
          <ServiceReservasi data={data} client={client} />
        </TabPanel> */}
       { uRoles.some(x => [5, 4, 10, 11, 3, 2, 12].indexOf(x.role_id) != -1)&& <TabPanel>
          {/* */}

          <ListKontrol currentUser={props.user} getDataSubmit={getDataSubmit} dSubmitKonsul={dSubmitKonsul} dataKonsul={data.konsultasi.filter(x => x.dokul_dokter.branch_id == 2 && x.type == 2)} branch_id={2} type={2} />

          {/* <JadwalDokter data={data} /> */}
        </TabPanel>}
        { uRoles.some(x => [5, 4, 10, 11, 3, 2, 14,16,17,18].indexOf(x.role_id) != -1)&&<TabPanel>
          {/* */}

          <ListKontrol currentUser={props.user} getDataSubmit={getDataSubmit} dSubmitKonsul={dSubmitKonsul} dataKonsul={data.konsultasi.filter(x => x.dokul_dokter.branch_id == 2 && x.type == 3)} branch_id={2}  type={3}/>

          {/* <JadwalDokter data={data} /> */}
        </TabPanel>}
        { uRoles.some(x => [5, 4, 10, 11, 3, 2,12].indexOf(x.role_id) != -1)&& <TabPanel>
          <ListKontrol currentUser={props.user} getDataSubmit={getDataSubmit} dSubmitKonsul={dSubmitKonsul} dataKonsul={data.konsultasi.filter(x => x.dokul_dokter.branch_id == 1 && x.type == 2)} data={data} branch_id={1} type={2}/>
          {/* <TemplateJadwal data={data} /> */}
          {/* <Table data={data.konsultasi} columns={columns} /> */}
        </TabPanel>}
        { uRoles.some(x => [5, 4, 10, 11, 3, 2].indexOf(x.role_id) != -1)&&<TabPanel>
          {/* <Table data={data.konsultasi} columns={columns} /> */}
        </TabPanel>}
        { uRoles.some(x => [5, 4, 10, 11, 3, 2].indexOf(x.role_id) != -1)&&<TabPanel>
          <OnlineSlot data={data} />
          {/* <Table data={data.konsultasi} columns={columns} /> */}
        </TabPanel>}
       
      </Tabs>
    </div>
  )
}
export default withApollo(ListKonsultasi)


