import React, { Component, Fragment, forwardRef, useState, useMemo } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";

import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { GET_DOKTERS, GET_JADWAL_TEMPLATES, GET_KLINIKS, GET_LIST_KONSULTASI_EKSPRESS, GET_LIST_SUBMIT_KONSULTASI, GET_RESERVASI, GET_SLOT_KONSULTASI, UPDATE_DOKTER } from '../../constants/graph';
import data from '../../assets/data/physical_list';
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
registerLocale('id', es)

const OnlineSlot = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [tgl, setTgl] = useState(new Date())
    const [idxDokter, setIdxDokter] = useState(0)
    const [idxKlinik, setIdxKlinik] = useState(0)
    const [jadwalBaru, setJadwalBaru] = useState({})
    const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const { loading, error, data } = useQuery(GET_SLOT_KONSULTASI)
    const { loading: lKonsulEks, error: eKonsulEks, data: dKonsulEks } = useQuery(GET_LIST_KONSULTASI_EKSPRESS)
    const [getDataSubmit, { loading: lSubmitKonsul, error: eSubmitKonsul, data: dSubmitKonsul }] = useLazyQuery(GET_LIST_SUBMIT_KONSULTASI)
    const client = useApolloClient()

    const [selectedTopic, setselectedTopic] = useState()
    const updateCache = (cache, { data }) => {

        const _updatedDokter = { ...data.updateDokter }
        const fragmentResult = cache.writeFragment({
            id: `Dokter:${_updatedDokter.id}`,
            fragment: gql`
        fragment dokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
        }
      `,
            data: _updatedDokter
        });
    };
    const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });


    const selectedDokter = client.readFragment({
        id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment readDokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
          }
        `,
    }, false);
    const { loading: loadingDokter, error: errorDokter, data: dataDokter } = useQuery(GET_DOKTERS)

 const fetchData=(state, instance)=> {
     console.log(state)
     let email
     if (state) state.forEach(element => {
        if (element.id == 'email') email = element.value
     })
    getDataSubmit({
        variables: {
            page: 0,
            size: 10,
            topicid: selectedTopic,
            email:email,
            robot_id: 1

        }
    })

 }
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }

    const columns = useMemo(
        () => [

            {
                Header: <b>Date</b>,
                accessor: "date_created",
                width: 200,
                
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.date_created).format("DD MMMM YYYY HH:mm")}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Pasien</b>,
                accessor: "email",
                filterable: true,
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.dokul_user.email}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Status</b>,
                accessor: "topic",
                width: 200,
                Cell: (cellInfo) =>
                    <div className="d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight">{cellInfo.original.is_publish == 1 ? "Selesai" : "Progress"}</div>
                        <div className="p-2 bd-highlight">{cellInfo.original.is_approved == 1 ? "Approved" : "Rejected"}</div>
                        
                    </div>,
                style: {
                    textAlign: 'left',
                }
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                width: 100,
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <span onClick={() => {
                            // setselectedReservasi(row.original)

                        }}>
                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                        </span>



                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        ])


    if (loading || loadingDokter || lKonsulEks || lSubmitKonsul) return "Loading"

    console.log(data.slots)
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3">
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Konsultasi Ekspress</li>
                            {/* <li className={`list-group-item ${selectedGudang == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => { }}>Semua gudang</li> */}


                            {/* {dataDokter.dokters.map((template, idx) =>
                                <li className={`list-group-item ${idxDokter == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    setIdxDokter(idx)

                                }}>{template.fullname}</li>

                            )} */}
                            {dKonsulEks.konsultasiekspress.filter(x => x.robot_id == 1).map((ke, ike) => <li key={ike} className={`list-group-item ${idxDokter == ike ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                setselectedTopic(ke.id)
                                getDataSubmit({
                                    variables: {
                                        page: 0,
                                        size: 10,
                                        topicid: ke.id,
                                        robot_id: 1

                                    }
                                })
                                setIdxDokter(ike)


                            }}>{ke.jawaban.replace("<p>", "").replace("</p>", "")}</li>)}

                        </ul>

                    </div>
                    <div className="col-sm-9">
                        <ul className="list-group">
                            {/* <li className={`list-group-item bg-primary w-100`}>Slot konsultasi  <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                // this.setState({
                                //     selectedCategory: {}
                                // }, () => this.onOpenModal())
                            }}
                            >ADD</span></li>
                            <li className={`list-group-item w-100`}>
                                <div className="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight">SLOT TERSISA :</div>
                                    <div className="p-2 bd-highlight">{data.slots.data.filter(sl => sl.dokul_user == null).length}</div>
                                </div>
                            </li> */}


                            {/* <li className={`list-group-item ${selectedGudang == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => { }}>Semua gudang</li> */}


                            {/* {data.slots.data.filter(sl=>sl.dokul_user!=null).map((dokter, idx) =>
                                <li className={`list-group-item ${idxDokter == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    

                                }}>{dokter.fullname}</li>

                            )} */}

                        </ul>
                        <ReactTable
                            data={dSubmitKonsul ? dSubmitKonsul.submitkonsultasis.data : []}
                            columns={columns}
                            defaultPageSize={10}
                            className={"-striped -highlight"}
                            showPagination={true}
                            page={dSubmitKonsul ? dSubmitKonsul.submitkonsultasis.currentPage : 0}
                            pages={dSubmitKonsul ? dSubmitKonsul.submitkonsultasis.totalPages : 0}
                            manual
                            onFilteredChange={(val) => fetchData(val)}


                        />

                        {/* <div className="card"> */}
                        {/* <div className="card-header bg-primary text-white">
                                <span className="float-left">
                                    <div class="d-flex flex-row bd-highlight ">
                                        <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">Sesi Konsultasi</h4></div>
                                      

                                    </div>
                                </span>

                            </div> */}

                        {/* <div className="card-body"> */}
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Slot konsultasi <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                // this.setState({
                                //     selectedCategory: {}
                                // }, () => this.onOpenModal())
                            }}
                            >ADD</span></li>
                            {/* {data.slots[idxDokter].dokul_jadwal_sesis.map((kon, idxKon) =>

                                <li className={`list-group-item `}  >
                                    <div className="d-flex flex-row justify-content-around">
                                        <div className="p-2 bd-highlight">{kon.sesi}</div>
                                        <div className="p-2 bd-highlight float-end">Jumlah pasien : {kon.jumlah_pasien}</div>
                                    </div>
                                </li>
                            )} */}
                        </ul>
                        {/* <div class="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                            <ToastContainer />
                        </div>

                        {/* {data.dokters[idxDokter].dokul_dokter_konsultasis.filter(ykon => ykon.type == 2).length > 0 ?
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                        <h6 className="border-bottom border-info"><i className="icofont icofont-globe" /> ONLINE </h6>
                                        <div className="p-2 bd-highlight mb-2">
                                            <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-left">
                                                <div className="d-flex flex-row">
                                           
                                                </div>
                                                <Link
                                                    to={`/products/edit/${data.dokters[idxDokter].dokul_dokter_konsultasis.find(onk => onk.type == 2).id_product}`}
                                                >
                                                    <span className="p-2 bd-highlight rounded bg-success">EDIT PRODUK </span>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                } */}
                    </div>
                </div>
            </div>
            <div className="clearfix" />
            {/* </div> */}
            {/* </div> */}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} >


                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Tambah jadwal</h5>

                    </div>
                    <div className="modal-body">
                        <h6 className="font-weight-bold">{data.slots.data[idxDokter]?.fullname}</h6>
                        {/* <h6 className="font-weight-bold">{data.templates[idxDokter]?.dokul_dokter_konsultasis[idxKlinik].dokul_klinik?.name}</h6> */}
                        <h6 className="font-weight-bold">{moment(tgl).format("dddd DD, MMMM YYYY")}</h6>


                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1" >Sesi</span>
                            </div>
                            <input type="text" className="form-control" onChange={(e) => setJadwalBaru({ name: e.target.value, jumlah_sesi: jadwalBaru.jumlah_sesi })} />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon12" >Jumlah</span>
                            </div>
                            <input type="number" className="form-control" onChange={(e) => setJadwalBaru({ jumlah_sesi: e.target.value, name: jadwalBaru.name })} />
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let _dokter = { ...data.templates[idxDokter] }
                            let _dokul_dokter_konsultasis = [..._dokter.dokul_dokter_konsultasis]
                            let _currentKonsultasi = { ..._dokul_dokter_konsultasis[idxKlinik] }
                            let currentJadwals = [..._currentKonsultasi.dokul_dokter_jadwals]

                            currentJadwals.push({
                                id_konsultasi: _currentKonsultasi.id,
                                name: jadwalBaru.name,
                                tanggal: moment(tgl).format("YYYY/MM/DD"),
                                jumlah_sesi: jadwalBaru.jumlah_sesi,
                                terpakai: 0,

                            })


                            _dokter['dokul_dokter_jadwal'] = currentJadwals
                            updateDokter({ variables: { id: 1, type: _dokter }, })


                            setModalOpen(false)
                        }}>Simpan</button>
                    </div>

                </div>

            </Modal>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}


export default withApollo(OnlineSlot)

