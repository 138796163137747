import React, { Component, Fragment, useState } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';


import { onError } from "@apollo/client/link/error";
import { isLoggedInVar } from '../../cache';
import history from '../../history';
//import { useHistory } from 'react-router';
import gql from 'graphql-tag';
import { LOGIN_MUTATION } from '../../constants/graph'
import { joinDokterPrivateRoom } from '../../provider/socket/emits';
import { Alert, FormFeedback, Input } from 'reactstrap';
import Loading from '../common/loading';

const GET_PRODUCTS = gql`query products {
    products @rest(type: "Product",path: "api/products") {
        name,pictures,sku,branchId,id
    }
  }
`;


//export class LoginTabset extends Component {
const LoginTabset = ({ type }) => {
    const [loginError, setloginError] = useState(false)
    const [errorMessage, seterrorMessage] = useState("")
    // const history = useHistory();
    const link = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
            );

        if (networkError) console.log(`[Network error]: ${networkError}`);
    });
    const [formState, setFormState] = useState({
        login: true,
        emptyPass: false,
        emptyEmail: false,
        email: '',
        password: '',
        name: ''
    });
    const [remainder, setremainder] = useState(false)

    const [login, { loading: lLoading }] = useMutation(LOGIN_MUTATION, {
        variables: {
            email: { email: formState.email, password: formState.password, remainder: remainder },
            password: formState.password
        },
        onCompleted: ({ login }) => {

            const uRoles = login.data.dokul_user_roles
            // console.log(uRoles)
            const requiredRole = uRoles.some(x => [5, 4, 10, 11, 3, 2, 12, 13, 14, 15, 16, 17, 18, 19].indexOf(x.role_id) != -1)

            // if (login.data.role_id == 5 || login.data.role_id == 4 || login.data.role_id == 10 || login.data.role_id == 11 || login.data.role_id == 3 || login.data.role_id == 2) {
            if (requiredRole) {
                localStorage.setItem("CURRENT_USER", JSON.stringify(login.data));

                //document.cookie = 'token=' + login.token
                // console.log(login)
                document.cookie = 'signedin=true'
                joinDokterPrivateRoom({ idDokter: login.data.id })
                isLoggedInVar(true);
                if (uRoles.some(x => [12, 14, 18].indexOf(x.role_id) != -1))
                    history.push('/konsultasi');
                else if (uRoles.some(x => [13].indexOf(x.role_id) != -1))
                    history.push('/reservasi');
                else
                    history.push('/sales/orders');
            } else {
                seterrorMessage("404: Not Found")
                setloginError(true)
            }

        }, onError: ({ graphQLErrors, networkError }) => {
            console.log(networkError.result)
            if (networkError.result)
                seterrorMessage(networkError.result.message)
            else
                seterrorMessage("Network error")
            setloginError(true)
            //  localStorage.setItem("AUTH_TOKEN", login.token);
            //history.push('/');
        },
    });
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         activeShow: true,
    //         startDate: new Date()
    //     }
    //     this.handleChange = this.handleChange.bind(this)
    // }

    // clickActive = (event) => {
    //     document.querySelector(".nav-link").classList.remove('show');
    //     event.target.classList.add('show');
    // }
    // handleChange(date) {
    //     this.setState({
    //         startDate: date
    //     });
    // }

    const routeChange = () => {


        history.push(`${process.env.PUBLIC_URL}/dashboard`);
    }
    const handleKeyPress = (e) => {

        if (e.keyCode === 13) {
            if (formState.password == "") setFormState({ ...formState, emptyPass: true })
            if (formState.email == "") setFormState({ ...formState, emptyEmail: true })
            if (!formState.email == "" && !formState.password == "") {
                login()
            }
        }
    }
    //render() {
    // var { loading, data } = useQuery(GET_PRODUCTS, {
    //     variables: {
    //         //type: type,
    //         indexFrom: 0,
    //         limit: 8
    //     }
    // });
    if (lLoading) return <Loading show={true} />
    return (
        <div>
            <Fragment>
                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                        <Tab className="nav-link" onClick={(e) => console.log("DR")}><User />Login</Tab>
                        {/* <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><Unlock />Register</Tab> */}
                    </TabList>

                    <TabPanel>
                        {loginError && <Alert color="danger" >{errorMessage}</Alert>}
                        <form className="form-horizontal auth-form p-1 needs-validation" novalidate>
                            <div className="form-group">
                                <input required name="email" type="email" className="form-control" placeholder="Username" id="exampleInputEmail1" value={formState.email} onChange={(e) => {
                                    setFormState({
                                        ...formState,
                                        email: e.target.value
                                    })
                                }} />
                            </div>
                            {/* <div class="input-group has-validation">
      <span className="input-group-text" id="inputGroupPrepend">@</span>
      <input type="text" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required/>
      <div className="invalid-feedback">
        Please choose a username.
      </div>
    </div> */}
                            <div className="form-group">
                                <Input autoComplete={false} required name="password" type="password" className="form-control" placeholder="Password" value={formState.password} onKeyDown={handleKeyPress} onChange={(e) => {

                                    setFormState({
                                        ...formState,
                                        password: e.target.value, emptyPass: false
                                    })
                                }} invalid={formState.emptyPass} />
                                <FormFeedback invalid> Password anda dibutuhkan</FormFeedback>

                            </div>
                            <div className="form-terms">
                                <div className="custom-control custom-checkbox mr-sm-2">
                                    {/* <input type="checkbox" className="custom-control-input" id="customControlAutosizing" /> */}
                                    <label className="d-block">
                                        <input className="checkbox_animated" id="chk-ani2" type="checkbox" />
                                        Reminder Me <span className="pull-right"> </span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-button">
                                <button className="btn btn-primary" type="button" onClick={() => {


                                    if (!formState.email == "" && !formState.password == "") {
                                        login()
                                    }

                                }}>Login</button>
                            </div>
                            <div className="form-footer">
                                {/* <span>Or Login up with social platforms</span>
                                <ul className="social">
                                    <li><a className="fa fa-facebook" href=""></a></li>
                                    <li><a className="fa fa-twitter" href=""></a></li>
                                    <li><a className="fa fa-instagram" href=""></a></li>
                                    <li><a className="fa fa-pinterest" href=""></a></li>
                                </ul> */}
                            </div>
                        </form>
                    </TabPanel>
                    {/* <TabPanel>
                            <form className="form-horizontal auth-form">
                                <div className="form-group">
                                    <input required="" name="login[username]" type="email" className="form-control" placeholder="Username" id="exampleInputEmail12" />
                                </div>
                                <div className="form-group">
                                    <input required="" name="login[password]" type="password" className="form-control" placeholder="Password" />
                                </div>
                                <div className="form-group">
                                    <input required="" name="login[password]" type="password" className="form-control" placeholder="Confirm Password" />
                                </div>
                                <div className="form-terms">
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                                        <label className="d-block">
                                            <input className="checkbox_animated" id="chk-ani2" type="checkbox" />
                                            I agree all statements in <span><a href="">Terms &amp; Conditions</a></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-button">
                                    <button className="btn btn-primary" type="submit" onClick={() => this.routeChange()}>Register</button>
                                </div>
                                <div className="form-footer">
                                    <span>Or Sign up with social platforms</span>
                                    <ul className="social">
                                        <li><a className="fa fa-facebook" href=""></a></li>
                                        <li><a className="fa fa-twitter" href=""></a></li>
                                        <li><a className="fa fa-instagram" href=""></a></li>
                                        <li><a className="fa fa-pinterest" href=""></a></li>
                                    </ul>
                                </div>
                            </form>
                        </TabPanel> */}
                </Tabs>
            </Fragment>
        </div>
    )
    // }
}

export default withRouter(LoginTabset)

