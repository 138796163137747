import React, { useState } from 'react'
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
// import CKEditors from "react-ckeditor-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment'
import { GET_DOKTERS, listMasalah, GET_KLINIKS, GET_LIST_KONSULTASI_EKSPRESS, GET_LIST_KONSULTASI_KONTROL, GET_LIST_SUBMIT_KONSULTASI, GET_RESERVASI, GET_SLOT_KONSULTASI, UPDATE_DOKTER, GET_PRODUCTS, GET_ROLES, UPDATE_USER_ROLE, UPDATE_USER_KONTROL, GET_CONFIGS, GET_LIST_VOUCHER_PRIVATE, GET_USER_DETAIL } from '../../../constants/graph';
import Profile from '../../users/user.detail';
function DetailPasien({ pasien, selectedKontrol, updateControlResult, dRoles ,dProducts}) {
    const [getUserDetail,{ loading:lUserDetail, data:dUserDetail, error:eUserDetail }] = useMutation(GET_USER_DETAIL);
    const [updateUserRoles] = useMutation(UPDATE_USER_ROLE)
    const [getPrivateVoucher, { loading: lPrivateVoucher, error: ePrivateVoucher, data: dPrivateVoucher }] = useLazyQuery(GET_LIST_VOUCHER_PRIVATE,{
        fetchPolicy:'network-only'
    })
    const [selectedUser, setselectedUser] = useState({ dokul_user: { dokul_user_roles: [] } })

    const [userDetail, setuserDetail] = useState({})
    const [contentUserRekamMedis, setcontentUserRekamMedis] = useState(selectedKontrol.dokul_user.medical_note)
    const [modalOpenChangeRole, setModalOpenChangeRole] = useState(false)
    const [modalDetailUser, setmodalDetailUser] = useState(false)
    const [userRoles, setuserRoles] = useState(selectedKontrol.dokul_user.dokul_user_roles)
    const handleGetUserDetail=()=>{
        getUserDetail({
            variables: {
                id: selectedKontrol.user_id
            }
        }).then((result) => {
            if (result.data) {
               
                setuserDetail(result.data.userDetail)
                setmodalDetailUser(true)
            }
        })
    }
    return (
        <div>
            <div className='bg-silver p-2'>
                <dl className="row p-2">
                    <dt className="col-sm-12 ">
                        <div className='d-flex flex-row bg-silver justify-content-between'>
                            <div className='mx-0'>
                                <div className="form-check form-switch">
                                    <input className="form-check-input bg-main" type="checkbox" id="flexSwitchCheckDefault2" defaultChecked={selectedKontrol.is_marked == 1} onChange={(e) => {
                                        if (e.target.checked) {
                                            // setselectedKontrol({ ...selectedKontrol, is_marked: 1 })
                                            updateControlResult(selectedKontrol.id, { is_marked: 1 }, false, false, false, false, false, false, true)
                                        } else {
                                            // setselectedKontrol({ ...selectedKontrol, is_marked: 0 })
                                            updateControlResult(selectedKontrol.id, { is_marked: 0 }, false, false, false, false, false, false, true)
                                        }
                                    }} />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">Beri tanda</label>
                                </div>
                            </div>
                            <div className="p-2">
                                <button className="btn btn-primary btn-xs float-end m-2" onClick={() => {
                                    handleGetUserDetail()
                                }}>Detail Akun</button></div>
                        </div>

                    </dt>


                    <dt className="col-sm-5 ">Nama</dt>
                    <dd className="col-sm-7">{selectedKontrol.dokul_user.first_name} {selectedKontrol.dokul_user.last_name}</dd>
                    <dt className="col-sm-5 ">Role</dt>
                    <dd className="col-sm-7">
                        <div className="row bg-light text-dark">
                            <div className="col-12">
                                {userRoles.map((x, idx) => <Label key={idx} className="w-100">{"- " + dRoles.roles.find(y => y.id == x.role_id)?.role_name}</Label>)}
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary btn-xs float-end m-2" onClick={() => {
                                    setModalOpenChangeRole(true)
                                }}>Edit</button></div>
                        </div>

                    </dd>
                    {/* </Row> */}
                    <dt className="col-sm-5">Jenis kelamin</dt>
                    <dd className="col-sm-7">{selectedKontrol.dokul_user.gender}</dd>
                    <dt className="col-sm-5">Usia</dt>
                    <dd className="col-sm-7">{moment().diff(moment(selectedKontrol.dokul_user.tgl_lahir), 'years', false)}</dd>
                    <dt className="col-sm-5">Status</dt>
                    <dd className="col-sm-7">{selectedKontrol.dokul_user.status_kawin}</dd>
                    <dt className="col-sm-5">Masalah utama</dt>
                    <dd className="col-sm-7">{selectedKontrol.dokul_user.masalah_utama}</dd>
                    <dt className="col-sm-5">Masalah lain</dt>
                    <dd className="col-sm-7">
                        {selectedKontrol.dokul_user.masalah_lain && selectedKontrol.dokul_user.masalah_lain.split(",").map((x, idx) => <Label key={idx}>{listMasalah[x]}</Label>)}
                    </dd>
                    <dt className="col-sm-12">Catatan medis</dt>
                    <dd className="col-sm-12 bg-silver p-2">
                    <CKEditor
                            editor={ ClassicEditor}
                            config={{
                                height: 100,
                                placeholder: "Catatan medis",
                                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
                            }}
                            activeclassName="bg-silver"
                            data={contentUserRekamMedis }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setcontentUserRekamMedis(data)
                            } }
                            />
                        {/* <CKEditors
                            config={{
                                height: 100,
                                placeholder: "Placeholder text...",
                                toolbarGroups: [
                                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                                    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                                    { name: 'links', groups: ['links'] },
                                    { name: 'insert', groups: ['insert'] }
                                ],
                                removeButtons: 'Source,Save,Templates,Cut,Undo,NewPage,Copy,ExportPdf,Preview,Print,Find,Replace,Redo,SelectAll,Scayt,Form,About,Maximize,TextColor,BGColor,ShowBlocks,Styles,Format,Font,FontSize,Image,Table,HorizontalRule,SpecialChar,Smiley,PageBreak,Iframe,Link,Unlink,Anchor,Language,BidiRtl,BidiLtr,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Blockquote,CreateDiv,Outdent,Indent,NumberedList,BulletedList,CopyFormatting,RemoveFormat,Superscript,Subscript,Strike'

                            }}
                            activeclassName="bg-silver"
                            content={contentUserRekamMedis}

                            events={{
                                "blur": (val) => {
                                    // let _product = { ...product }
                                    // _product.description = val.editor.getData()
                                    // setProduct(_product)
                                    // setcontentUserRekamMedis(val.editor.getData())
                                },
                                //"afterPaste": (val) => product.description=val.editor.getData(),
                                "change": (val) => {
                                    // let _product = { ...product }
                                    // // console.log(_product)
                                    // //console.log(val.editor.getData())
                                    // _product.description = val.editor.getData()
                                    // setProduct(_product)
                                    setcontentUserRekamMedis(val.editor.getData())
                                    // handleChangeDesc(val.editor.getData())
                                },
                            }}
                        /> */}

                        {/* {selectedKontrol.dokul_user.medical_note && <>
                                                <div className="bg-light p-2 text-dark" dangerouslySetInnerHTML={{ __html: selectedKontrol.dokul_user.medical_note }} />
                                            </>}
                                            <div className="bg-light p-2">
                                                <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                                    seteditedText(3)
                                                    setContent(selectedKontrol.dokul_user.medical_note)
                                                    setmodalEditText(true)
                                                }}>Edit</button>
                                            </div> */}

                        {contentUserRekamMedis != selectedKontrol.dokul_user.medical_note && <button className="btn btn-primary btn-xs mt-2  float-end" onClick={() => {
                            let _user = { ...selectedKontrol.dokul_user }
                            _user.medical_note = contentUserRekamMedis
                            updateControlResult(_user.id, { medical_note: contentUserRekamMedis }, false, false, true)
                        }}>save</button>
                        }
                    </dd>


                </dl>
            </div>
            <Modal isOpen={modalDetailUser} toggle={() => setmodalDetailUser(false)} centered fullscreen>
                <ModalHeader  className='bg-white' toggle={() => setmodalDetailUser(false)} ></ModalHeader>
                <ModalBody className='bg-light'>
                        {modalDetailUser&&<Profile 
                        dPrivateVoucher={dPrivateVoucher}
                         dProducts={dProducts} 
                         setselectedUser={setselectedUser} 
                         setModalOpenChangeRole={setModalOpenChangeRole} 
                         dUserDetail={userDetail} 
                         closeModal={() => setmodalDetailUser(false)} 
                         isNew={false}  />}
                         </ModalBody>
            </Modal>
            <Modal isOpen={modalOpenChangeRole} toggle={() => setModalOpenChangeRole(false)} centered size={"sm"} >

                {selectedKontrol && <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Ganti role  - {selectedKontrol.dokul_user.first_name}</h5>

                    </div>
                    <div className="modal-body">
                        <div className="form-group row m-2">


                            {dRoles.roles.map((role, idx) =>
                                <div key={idx} className="form-check">
                                    <input className="form-check-input" value={role.id} type="checkbox" name={`role_selected${idx}`} id="role_selected" checked={userRoles.some(dur => dur.role_id == role.id)} onChange={(e) => {
                                        // let cu = { ...selectedKontrol }
                                        // let du = { ...cu.dokul_user }
                                        let _userRoles = [...userRoles]
                                        if (e.target.checked) {
                                            //cu.role_id = e.target.value
                                            _userRoles.push({
                                                email: selectedKontrol.dokul_user.email,
                                                id: `${selectedKontrol.dokul_user.email}${e.target.value}`,
                                                role_id: e.target.value,
                                                user_id: selectedKontrol.dokul_user.id
                                            })

                                        } else {
                                            const idxcu = _userRoles.findIndex(x => x.role_id == e.target.value)
                                            _userRoles.splice(idxcu, 1)

                                        }
                                        // du.dokul_user_roles = _userRoles
                                        // cu.dokul_user = du
                                        // setselectedKontrol(cu)
                                        setuserRoles(_userRoles)

                                    }} />
                                    <label className="form-check-label" htmlFor={`role_selected${idx}`}>
                                        {role.role_name}
                                    </label>
                                </div>
                            )}

                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary btn-sm" onClick={() => {
                            updateUserRoles({
                                variables: { data: userRoles }
                            })
                            // let _users = { ...users }
                            // let curentUsers = [..._users.users]


                            // const idx = curentUsers.findIndex(x => x.id == selectedUser.id)
                            // curentUsers = curentUsers.splice(idx, 1)
                            // _users.users = curentUsers

                            setModalOpenChangeRole(false)
                            // postData(`${process.env.REACT_APP_API_HOST}/api/users/sync/role`, [selectedUser])
                        }}>Submit</button>
                        <button className="btn btn-secondary btn-sm " onClick={() => setModalOpenChangeRole(false)}>Cancel</button>

                    </div>

                </div>
                }

            </Modal>
        </div>
    )
}

export default DetailPasien