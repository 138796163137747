import React, { Component } from 'react'

export default class printout extends Component {


    render() {
        const { orders,startAt } = this.props
        const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
        return (


            <div className="border mr-0 print-container" style={{ margin: 10, padding: "0", width: 300 }}>


                <div className="card-body " style={{ margin: "0", padding: "0" }}>
                    {orders.map((order, index) =>
                        <div key={index} >
                            <div  className="page-break" />
                            <dl   className="border-bottom m-2 pb-2" style={{ fontSize: 10 }}>
                                <dt className="col-sm-12 mb-0">{order.shipping_address_id != 0 ? "" : "Ambil diklinik"}</dt>
                                {order.shipping_address_id != 0 ? <>
                                    <dd className="col-sm-12 mb-0">{order.nomor_cetak ? order.nomor_cetak+1 : startAt+(index + 1)}  {order.dokul_address.first_name} {order.dokul_address.last_name} {order.dokul_address.phone}</dd>
                                    {/* <dd className="col-sm-12 mb-0">{order.dokul_address.phone}</dd> */}
                                    <dd className="col-sm-12 mb-0">{order.dokul_address.address_1}</dd>
                                    <dd className="col-sm-12 mb-0">({order.dokul_address.address_state}) {order.dokul_address.address_city} {order.dokul_address.address_2} {order.dokul_address.address_postcode}</dd>
                                </> : <dd className="col-sm-12 mb-0">{order.nomor_cetak ? order.nomor_cetak+1 : index + 1} {order.dokul_user.first_name} {order.dokul_user.last_name} {order.dokul_user.phone}</dd>}
                                <dd className="col-sm-12 mb-2">Dari : Dokterkulitku 0817821107</dd>

                                <dd className="col-sm-12 mb-0">Order : {order.dokul_orders_items.filter(os=>os.status!=2).map(item => `${item.product_name}, `)} </dd>

                            </dl>
                        </div>
                    )}
                </div>

                {/* </div>

                        </div>


                    </div> */}
            </div>


        )
    }
}
