import React, { useState, useEffect,useContext,createContext } from "react";
import SocketContext from "./context";
import DispatchContext from "./disptachContext";
import { initSockets,initSockets2 } from "../socket";
//import { sendMessage } from "../../sockets";

//const SocketContext = createContext(null);

//       ^ initSockets is shown later on
const SocketProvider = (props) => {
  const [value, setValue] = useState({
    notif:[],
    message:[],
    konsulMessage:[],
    socketId:0,
    waconnect:{}
  });

useEffect(() => initSockets({ setValue }), [initSockets]);
function newMessage(message,konsul) {
  if(!konsul){
    setValue(state => {
      var oledMessageList=state.message
      if(!message)
        oledMessageList=[]
     // oledMessageList.push({body:message,fromMe:true})
        return { ...state, oledMessageList }
      
      });
}else{
  setValue(state => {
    console.log(state)
    var oledMessageList=state.konsulMessage
    if(oledMessageList.length>0){
      //cari id jika ada push ke dalem message nya
      let oldMsgUser=oledMessageList.find(om=>om.id==message.id)
      if(oldMsgUser){
       // oldMsgUser.userinfo=message.userinfo
        oldMsgUser.message.push(message.message)
      }else{
        oledMessageList.push({ id:message.id,message:[message.message], userinfo: message.userinfo })
      }
    }else{
      oledMessageList.push({ id:message.id,message:[message.message], userinfo: message.userinfo })
    }
    return { ...state, oledMessageList }
    
    });
}
}
// sendMessage = (message) => { 
//   this.setState(
//     state => ({
//       ...state,
//       status: state.status === 'playing' ? 'paused' : 'playing',
//     }),
//     () => null
//   );
// };
function countReducer(state, action) {
  console.log(action)
  switch (action.type) {
    case 'increment': {
      return {count: state.message}
    }
    case 'decrement': {
      return {count: state.message}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// Note, we are passing setValue ^ to initSockets
//const [dispatch] = React.useReducer(countReducer)
//const [state, dispatch] = React.useReducer(countReducer, {count: 0})
return(
  
    <SocketContext.Provider value={ value }>
      <DispatchContext.Provider value={ newMessage }>
      { props.children }
      </DispatchContext.Provider>
    </SocketContext.Provider>
  )
};



export  {SocketProvider};