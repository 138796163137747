



import React, { useState, useEffect, Fragment } from 'react';
import { GET_CATEGORIES, GET_TAGS, GET_PRODUCTS, GET_GUDANGS } from '../../../constants/graph'
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import { AvField, AvForm, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvInput } from 'availity-reactstrap-validation';
import one from '../../../assets/images/pro3/1.jpg'
import user from '../../../assets/images/user.png';
import { Edit, Trash2, Plus } from 'react-feather'
import { Alert, Badge, FormGroup, Label, Input, CustomInput, ListGroupItem, ListGroup, Popover, PopoverHeader, PopoverBody, UncontrolledPopover, Modal, ModalBody } from 'reactstrap';
import history from '../../../history';
import { useQuery, useMutation, readQuery, useApolloClient } from '@apollo/react-hooks';
import CategorySelect from '../category/category.select'

//import { useApolloClient, NormalizedCacheObject, QUERY } from '@apollo/client';

import gql from 'graphql-tag';
const UPDATE_TODO = gql`
  mutation UpdateTodo($id: String!, $type: String!) {
    updateTodo(id: $id, type: $type) {
      id
      type
    }
  }
`;
// const Hello = () => {
//     const { loading, error, data } = useQuery(GET_CATEGORIES,);
//     if (loading) return <p>Loading ...</p>;
//     return data;
// }
const ProdukVariant = (props) => {
    const listGudang = useQuery(GET_GUDANGS);
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    // const imgResource = 'http://localhost:81'//process.env.PUBLIC_URL
    const [activeImg, setActiveImg] = useState(0)
    //const [categories, setCategories] = useState([])
    const [popoverOpen, setPopoverOpen] = useState(false);
    const categories = []
    //const { loading2, data, error1 } = useQuery(GET_CATEGORIES,);
    //const { loading3, tags,categerror2 } = useQuery(GET_TAGS,);
    // const [, updateState] = React.useState();
    // const forceUpdate = React.useCallback(() => updateState({}), []);

    const toggle = () => setPopoverOpen(!popoverOpen);
    const [file, setFile] = useState()
    const [files, setFiles] = useState([])
    const [skuFiles, setSkuFiles] = useState([])
    const [pictures, setPicture] = useState([])

    // const [product,setProduct]=useState({})
    const [sku, setSku] = useState([])
    const [content, setContent] = useState("")
    //const client = useApolloClient();
    const [product, setProduct] = useState()
    const [openGudang, setopenGudang] = useState(false)
    const [selectedSku, setselectedSku] = useState()


    const client = useApolloClient();

    // useEffect(() => {
    //     // This effect uses the `value` variable,
    //     // so it "depends on" `value`.

    //     if (selectedSku != null) {
    //         setopenGudang(true)

    //     }
    //     //console.log(prod)
    // }, [selectedSku])

    useEffect(() => {
        // This effect uses the `value` variable,
        // so it "depends on" `value`.
        console.log("useefect varian")
        console.log(props.product)
        if (props.product) {
            // setPicture(product.dokul_product_images)
            // setContent(product.description)
            // setSku(product.dokul_products_skus)
            setProduct(props.product)

        }
        //console.log(prod)
    }, [props.product])
    useEffect(() => {

        // let _product = { ...product }
        // _product.dokul_products_skus = sku
        // // setProduct(_product)
        // props.handeleUpdateProduct(_product)

    }, [sku])
    const UPDATE_SKU = gql`
    mutation updateSkus(
      $data: any
      $id:ID!
    ) {
      skus(id:$id,body: $data)
      @rest(
        type: "Post"
        path: "api/products/sku/{args.id}"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
    const [updateProduct] = useMutation(UPDATE_SKU);

    useEffect(() => {
        props.setSkuFiles(skuFiles)
    }, [skuFiles])


    const handleValidSubmit = (event, values) => {
        let _product = { ...product }
        _product.dokul_products_skus = [...sku]
        //let _skus = [...sku];

        // console.log(sku)

        // return

        const formData = new FormData()
        files.forEach((f, i) => {

            formData.append('uploaded_file', f.file, `prod_${f.idx}_${f.file.name}`)
        })
        skuFiles.forEach((f, i) => {

            formData.append('uploaded_file', f, `sku_${i}_${f.name}`)
        })




        fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                data.d.forEach(ud => {
                    const isProd = ud.originalname.split("_")
                    // let _sku = {..._skus[isProd[1]]};
                    if (isProd[0] == 'prod') {
                        let listImg = [..._product.dokul_product_images]
                        let imgProd = { ...listImg[isProd[1]] }
                        imgProd.image_url = `/assets/images/uploads/${ud.filename}`
                        listImg[isProd[1]] = imgProd
                        _product.dokul_product_images = listImg;
                        //_product.dokul_product_images[isProd[1]]=imgProd//.image_url=`/assets/images/uploads/${ud.filename}`
                    }
                    if (isProd[0] == 'sku') {
                        let listSku = [..._product.dokul_products_skus]
                        let imgSku = { ...listSku[isProd[1]] }
                        imgSku.image_url = `/assets/images/uploads/${ud.filename}`
                        listSku[[isProd[1]]] = imgSku
                        _product.dokul_products_skus = listSku//.image_url=`/assets/images/uploads/${ud.filename}`
                        //_sku.image_url=`/assets/images/uploads/${ud.filename}`
                    }
                    //_skus[isProd[1]] = _sku;



                })
                //setSku(_skus)
                updateProduct({
                    variables: {
                        data: _product.dokul_products_skus,
                        id: _product.id
                    },
                    onCompleted: ({ login }) => {
                        //localStorage.setItem("AUTH_TOKEN", login.token);
                        console.log(login)
                        //document.cookie = 'token=' + login.token
                        // document.cookie = 'signedin=true'
                        // isLoggedInVar(true);

                    }, onError: ({ graphQLErrors, networkError }) => {
                        console.log(networkError)

                        console.log(graphQLErrors)
                        //  localStorage.setItem("AUTH_TOKEN", login.token);
                        //history.push('/');
                    },
                }).then(data => {
                    history.push('/products/list');
                })
            })
            .catch(error => {
                console.error(error)
            })


        //_product.dokul_products_skus=sku



    }

    const handleInvalidSubmit = (event, errors, values) => {
        //this.setState({ email: values.email, error: true });
    }

    //image upload
    const _handleSubmit = (e) => {
        e.preventDefault();
    }

    const _handleImgChange = (e, i) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        var oldpictures = [...pictures]
        var oldpic = { ...pictures[i] }
        var oldfiles = [...files]
        // const { dummyimgs } = state;
        oldfiles[i] = { idx: i, file: file };
        //console.log(file);
        reader.onloadend = () => {
            oldpic.image_url = reader.result;
            oldpictures[i] = oldpic;
            setFile(file)
            setPicture(oldpictures)
            setFiles(oldfiles)

            //product.pictures.splice(1,1)
            // product.pictures[0]=reader.result;
            //updateTodo({ variables: { id, type: "z" } });
            //    client.cache.modify({
            //     id: client.cache.identify(product),
            //     fields: {
            //       name(cachedName) {
            //         return cachedName.toUpperCase();
            //       },
            //     },
            //     /* broadcast: false // Include this to prevent automatic query refresh */
            //   });
            // setState({
            //     file: file,
            //     dummyimgs,
            // });
            console.log(reader)
        }
        reader.readAsDataURL(file)
    }
    const _handleImgAdd = (e, i) => {
        e.preventDefault();
        let _product = { ...product }
        let reader = new FileReader();
        let file = e.target.files[0];
        let _pictures = [...pictures]
        //const jmlPic = pictures.length;
        const newPic = { image_url: "", product_id: product.id }

        files.push({ idx: i, file: file });
        reader.onloadend = () => {
            newPic.image_url = reader.result;
            _pictures.push(newPic)
            _product.dokul_product_images = _pictures
            setFile(file)
            //setPicture(_pictures)
            setProduct(_product)
            setFiles(files)

            //product.pictures.splice(1,1)
            // product.pictures[0]=reader.result;
            //updateTodo({ variables: { id, type: "z" } });
            //    client.cache.modify({
            //     id: client.cache.identify(product),
            //     fields: {
            //       name(cachedName) {
            //         return cachedName.toUpperCase();
            //       },
            //     },
            //     /* broadcast: false // Include this to prevent automatic query refresh */
            //   });
            // setState({
            //     file: file,
            //     dummyimgs,
            // });

        }
        reader.readAsDataURL(file)
    }

    const _handleSkuImgChange = (e, i) => {
        e.preventDefault();
        let _product = { ...product }
        let _skus = [..._product.dokul_products_skus];

        let reader = new FileReader();
        let file = e.target.files[0];


        reader.onloadend = () => {
            // var posisi=this.state.posisi

            skuFiles[i] = file;
            console.log(props._index)
            // 2. Make a shallow copy of the item you want to mutate
            let _sku = { ..._skus[props._index] };
            // 3. Replace the property you're intested in
            _sku.image_url = reader.result;
            _sku.file = file;
            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
            _skus[props._index] = _sku;
            //  this.state.posisi=pitems

            // sku[i].image_url = reader.result;
            _product.dokul_products_skus = _skus
            props.handeleUpdateProduct(_product)

            setFile(file)
            setSkuFiles(skuFiles)

            setSku(_skus)
            // setState({
            //     file: file,
            //     sku,
            // });
            //console.log(reader)
        }
        reader.readAsDataURL(file)
    }

    const handleImageUpload = event => {
        //const files = event.target.files
        let _skus = [...sku];

        const formData = new FormData()
        files.forEach((f, i) => {

            formData.append('uploaded_file', f.file, `prod_${f.idx}_${f.file.name}`)
        })
        skuFiles.forEach((f, i) => {

            formData.append('uploaded_file', f, `sku_${i}_${f.name}`)
        })




        fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                data.d.forEach(ud => {
                    const isProd = ud.originalname.split("_")
                    let _sku = { ..._skus[isProd[1]] };
                    if (isProd[0] == 'prod') {
                        console.log("ini produk image" + isProd[1])
                        product.dokul_product_images[isProd[1]].image_url = `/assets/images/uploads/${ud.filename}`
                    }
                    if (isProd[0] == 'sku') {
                        console.log("ini sku image" + isProd[1])
                        _sku.image_url = `/assets/images/uploads/${ud.filename}`
                    }
                    _skus[isProd[1]] = _sku;

                })
                setSku(_skus)
            })
            .catch(error => {
                console.error(error)
            })
    }
    const handleSkuStatus = (e, i, idxGudang) => {
        let _product = { ...product }
        let newSku = [..._product.dokul_products_skus]
        let _sku = { ...newSku[props._index] }
        let _gudang_sku = [..._sku.dokul_gudang_skus]
        let _gudang = { ..._gudang_sku[idxGudang] }

        _gudang.avaibility = e.target.value
        _gudang_sku[idxGudang] = _gudang
        _sku.dokul_gudang_skus = _gudang_sku
        newSku[props._index] = _sku
        _product.dokul_products_skus=newSku
        props.handeleUpdateProduct(_product)

        // //console.log(e.target.checked)
        // let newSku = [...sku];
        // let _sku = { ...newSku[i] }
        // _sku.status = e.target.value;
        // newSku[i] = _sku
        // // setSku(state=>[...state])
        // setSku(newSku)

    }
    const handleSkuSSubscribe = (e, i, idxGudang) => {
        let _product = { ...product }
        let newSku = [..._product.dokul_products_skus]
        let _sku = { ...newSku[props._index] }
        let _gudang_sku = [..._sku.dokul_gudang_skus]
        let _gudang = { ..._gudang_sku[idxGudang] }

        _gudang.notif = e.target.checked ? 1 : 0;
        _gudang_sku[idxGudang] = _gudang
        _sku.dokul_gudang_skus = _gudang_sku
        newSku[props._index] = _sku
        _product.dokul_products_skus=newSku
        props.handeleUpdateProduct(_product)

    }
    const handleSkuDiscountGrade = (e, i) => {
        let _product = { ...product }
        let newSku = [..._product.dokul_products_skus]
        let text = "0000"
        let _sku = { ...newSku[props._index] }
        let currentGrade = _sku.discount_grade ? _sku.discount_grade.split('') : text.split('');
        let curentValueInt = parseInt(e.target.value)
        currentGrade[curentValueInt - 1] = e.target.checked ? e.target.value : "0";

        
        _sku.discount_grade = currentGrade.join('')
        newSku[props._index] = _sku
        _product.dokul_products_skus = newSku
        props.handeleUpdateProduct(_product)

        // _sku.discount_grade = currentGrade.join('')
        // newSku[i] = _sku
        // setSku(newSku)

    }
    const handleProductValueChange = (e) => {
        const { name, value } = e.target
        let _product = { ...product }
        _product[name] = value
        setProduct(_product)

    }
    const handleSkuValueChange = (e, i) => {
        const { name, value } = e.target
        
        // let newSku = [...sku];
        // let _sku = { ...newSku[i] }
        // _sku[name] = value
        // newSku[i] = _sku
        // setSku(newSku)
        let _product = { ...product }
        let _currentListSku = [..._product.dokul_products_skus]
        let _curentSku = { ..._currentListSku[props._index] }
       
        if(name=='name' && !value.includes('pk-') && product.is_premium==1){
            _curentSku[name] = 'pk-'+value
        }else{
            _curentSku[name] = value
        }

        if (_curentSku.discount > 0 && _curentSku.sale==1 )
            _curentSku.price = _curentSku.reg_price - (_curentSku.reg_price * _curentSku.discount) / 100
        else
            _curentSku.price = _curentSku.reg_price


        _currentListSku[props._index] = _curentSku
        _product.dokul_products_skus = _currentListSku
        props.handeleUpdateProduct(_product)

    }
    const handleProductRestrictChange = (e) => {
        //const { name, value } = e.target
        let _product = { ...product }
        _product["is_restricted"] = e != 1 ? 1 : 0
        console.log(_product)
        setProduct(_product)

    }
    const handleChangeCategory = (e, i, kategori) => {

        let _product = { ...product }
        let _categories = [..._product.dokul_tags_categories]

        const found = _categories.find(element => element.id == kategori.id);


        if (e.target.checked)
            _categories.push(kategori)
        else {


            const index = _categories.indexOf(found)

            _categories.splice(index, 1)

        }
        //console.log(product.dokul_tags_categories.le)
        _product.dokul_tags_categories = _categories;
        setProduct(_product)
        const pilu = `${file}${i}`;
        setFile(pilu)


    }
    if (!product) return <label>Loading</label>
    if (listGudang.loading) return "loading"
    const getGudang = (id) => {
        return client.readFragment({
            id: `Gudang:${id}`, // `id` is any id that could be returned by `dataIdFromObject`.
            fragment: gql`
          fragment getGudang on Gudang {
            id,
            nama
          }
        `,
        }, false);
    }

    // render() {
    return (

        <Fragment>

            <AvForm className="needs-validation add-product-form border p-2" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
                <div className="form form-label-center">

                    <div className='form-group row mb-2'>

                        <div className="col-12">
                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Gambar Variant :</label>

                                <div className="col-xl-8 col-sm-7">
                                    <div className="file-upload-product d-flex flex-nowrap">
                                        <div className='img-front m-1' style={{ padding: 0, width: 100 }}>
                                            <div className="box-input-file-lg"  >
                                                <img src={`${props.item.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${props.item.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                <div className="product-hover">
                                                    <ul>
                                                        <li>
                                                            <input className="upload" type="file" onChange={(e) => _handleSkuImgChange(e)} style={{ width: "100%", height: "100%" }} />
                                                            <button className="btn" type="button">
                                                                <Edit className="editBtn" />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Nama :</label>
                                <div className="col-xl-8 col-sm-7">
                                    <AvField className="form-control mb-0" name="name" id="validationCustom02" value={props.item.name} type="text" required onChange={(e) => {
                                        // let _skus = [...sku]
                                        // let _sku = { ..._skus[i] }
                                        // _sku.name = e.target.value
                                        // _skus[i] = _sku
                                        // setSku(_skus)
                                        handleSkuValueChange(e)

                                    }} />
                                </div>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Singkatan :</label>
                                <div className="col-xl-8 col-sm-7">
                                    <AvField className="form-control mb-0" name="nick_name" id="validationCustom02" value={props.item.nick_name} type="text" required onChange={(e) => {
                                        let _skus = [...sku]
                                        // let _sku = { ..._skus[i] }
                                        // _sku.nick_name = e.target.value
                                        // _skus[i] = _sku
                                        // setSku(_skus)
                                        handleSkuValueChange(e)

                                    }} />
                                </div>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Harga :</label>
                                <div className="col-xl-8 col-sm-7">
                                    <AvField className="form-control mb-0" name="reg_price" id="validationCustom02" value={props.item.reg_price} type="number" required onChange={(e) => {
                                        // let _skus = [...sku]
                                        // let _sku = { ..._skus[i] }
                                        // _sku.reg_price = e.target.value
                                        // if (_sku.discount > 0)
                                        //     _sku.price = _sku.reg_price - (_sku.reg_price * _sku.discount) / 100
                                        // else
                                        //     _sku.price = _sku.reg_price


                                        // _skus[i] = _sku
                                        // setSku(_skus)
                                        handleSkuValueChange(e)

                                    }} />
                                </div>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Berat :</label>
                                <div className="col-xl-3 col-sm-4">
                                    <div className="input-group">
                                        <input type="number" className="form-control" id="inlineFormInputGroupberat" placeholder="Berat" name="berat" value={props.item.berat} onChange={(e) => handleSkuValueChange(e)} />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"> <span className="text-muted">gram</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="form-group mb-3 row">
                                <label className="col-xl-3 col-sm-4 mb-0">Dimensi :</label>
                                <div className="col-xl-2 col-sm-2">
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Panjang" name="panjang" value={props.item.panjang} onChange={(e) => handleSkuValueChange(e)} />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><span className="text-muted">cm</span></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-2 col-sm-2">
                                    <div className="input-group">
                                        <input type="number" className="form-control" id="inlineFormInputGroupLebar" placeholder="Lebar" name="lebar" value={props.item.lebar} onChange={(e) => handleSkuValueChange(e)} />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"> <span className="text-muted">cm</span></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-2 col-sm-2">

                                    <div className="input-group">
                                        <input type="number" className="form-control" id="inlineFormInputGroupTinggi" placeholder="Tinggi" name="tinggi" value={props.item.tinggi} onChange={(e) => handleSkuValueChange(e)} />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text text-small"><span className="text-muted">cm</span></div>
                                        </div>
                                    </div>

                                </div>


                            </div>

                            <div className="form-group mb-3 row align-items-start">
                                <label className="col-xl-3 col-sm-4 mb-0">Discount :</label>
                                <div className="col-xl-8 col-sm-7">

                                    <div className="w-25">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={props.item.sale == 1} onChange={(e) => {

                                                // let _skus = [...sku]
                                                // let _sku = { ..._skus[i] }
                                                // _sku.sale = e.target.checked ? 1 : 0


                                                // _skus[i] = _sku
                                                // setSku(_skus)

                                                let _product = { ...product }
                                                let _currentListSku = [..._product.dokul_products_skus]
                                                let _curentSku = { ..._currentListSku[props._index] }
                                                if( !e.target.checked){
                                                    _curentSku.price=_curentSku.reg_price
                                                    _curentSku.discount=0
                                                }
                                                _curentSku.sale = e.target.checked ? 1 : 0
                                                _currentListSku[props._index] = _curentSku
                                                _product.dokul_products_skus = _currentListSku
                                                props.handeleUpdateProduct(_product)
                                            }} />

                                        </div>

                                        {props.item.sale == 1 ? <>

                                            <div className="p-0 bd-highlight">
                                                <div className="form-group">
                                                    <h6 className="border-bottom">Grade <small>( Yang mendapatkan )</small></h6>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" checked={props.item.discount_grade?.includes("1")} id="gridCheck1" value={1} onClick={(e) => handleSkuDiscountGrade(e)} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">Classic</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" checked={props.item.discount_grade?.includes("2")} type="checkbox" id="gridCheck1" value={2} onClick={(e) => handleSkuDiscountGrade(e)} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">Silver</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" checked={props.item.discount_grade?.includes("3")} type="checkbox" id="gridCheck1" value={3} onClick={(e) => handleSkuDiscountGrade(e)} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">Gold</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" checked={props.item.discount_grade?.includes("4")} type="checkbox" id="gridCheck1" value={4} onClick={(e) => handleSkuDiscountGrade(e)} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">Platinum</label>
                                                    </div>
                                                    <div className="form-group mt-2">
                                                        <h6 htmlFor="formGroupExampleInput2">Minimum Jumlah beli</h6>
                                                        <input type="number" name='discount_min' className="form-control col-2" value={props.item.discount_min} min="1" id="formGroupExampleInput2" placeholder="Masukan minimum jumlah pembelian untuk mendapat discount ini" onChange={(e) => {
                                                            // let _skus = [...sku]
                                                            // let _sku = { ..._skus[i] }
                                                            // _sku.discount_min = e.target.value
                                                            // _skus[i] = _sku
                                                            // setSku(_skus)
                                                            handleSkuValueChange(e)

                                                        }} />
                                                    </div>
                                                    <div className="form-group mt-2">
                                                        <h6 htmlFor="formGroupExampleInput2">Jumlah discount (%)</h6>
                                                        <AvField className="form-control mb-0 col-2" name="discount" id="validationCustom02" value={props.item.discount} type="number" onChange={(e) => {
                                                            //if (e.target.value > 0) {
                                                            // let _skus = [...sku]
                                                            // let _sku = { ..._skus[i] }
                                                            // _sku.discount = e.target.value
                                                            // if (e.target.value > 0)
                                                            //     _sku.price = _sku.reg_price - (_sku.reg_price * _sku.discount) / 100
                                                            // else
                                                            //     _sku.price = _sku.reg_price

                                                            // _skus[i] = _sku
                                                            // setSku(_skus)
                                                            //  }
                                                            handleSkuValueChange(e)

                                                        }} />
                                                    </div>
                                                    <div className="form-group mt-2">
                                                        <h6 htmlFor="formGroupExampleInput2">Harga setelah discount :</h6>
                                                        <AvField readOnly className="form-control mb-0" name="price" id="validationCustom02" value={props.item.price} type="number" required onChange={(e) => {
                                                        }} />
                                                    </div>
                                                </div>


                                            </div>

                                        </>

                                            : ""}


                                    </div>
                                </div>
                                <div className="valid-feedback">Looks good!</div>
                            </div>



                            <div className="form-group mb-3 row align-items-start">
                                <label className="col-xl-3 col-sm-4 mb-0 text-align-top align-items-start">Gudang : </label>
                                <div className="col-xl-8 col-sm-7">
                                    <div className="d-flex"><a className="btn btn-small bg-primary" onClick={() => {

                                        // setselectedSku(i)
                                        setopenGudang(true)
                                    }}>Tambah gudang</a></div>



                                    {props.item.dokul_gudang_skus.map((gudang, idxGudang) => {
                                        let fragmentGudang = getGudang(gudang.gudang_id)
                                        if (!fragmentGudang) return ""
                                        return <div className="w-100 mb-2" key={idxGudang}>
                                            <div className="p-0 bd-highlight">
                                                <h5 className="card-title border-bottom p-2"><span className="mr-5"><i className="icofont icofont-location-pin mr-2" style={{ fontSize: 15, padding: 0 }}></i></span>{fragmentGudang.nama}</h5>
                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name={`enablesubscribe-${idxGudang}`} checked={gudang.notif == 1 ? true : false} value={1} onChange={(e) => handleSkuSSubscribe(e, "i", idxGudang)} />
                                                        <label className="form-check-label text-danger" htmlFor="gridCheck1">Kirim notif jika stok berubah</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name={`statusstock-${idxGudang}`} checked={gudang.avaibility == 1 ? true : false} value={1} onChange={(e) => handleSkuStatus(e, "i", idxGudang)} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">Instock</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name={`statusstock-${idxGudang}`} checked={gudang.avaibility == 2 ? true : false} value={2} onChange={(e) => handleSkuStatus(e, "i", idxGudang)} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">Outstock</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name={`statusstock-${idxGudang}`} checked={gudang.avaibility == 4 ? true : false} value={4} onChange={(e) => handleSkuStatus(e, "i", idxGudang)} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">Preorder</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name={`statusstock-${idxGudang}`} checked={gudang.avaibility == 3 ? true : false} value={3} onChange={(e) => handleSkuStatus(e, "i", idxGudang)} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">Stock</label>
                                                    </div>
                                                    {gudang.avaibility == 3 || gudang.avaibility == 4 ?
                                                        <div className="form-group mt-2">
                                                            <h6 htmlFor="formGroupExampleInput2">Jumlah stok</h6>
                                                            <AvField className="form-control " name={`stock${idxGudang}`} id="validationCustomUsername" value={gudang.stock} type="number" required onChange={(e) => {
                                                                // let newSku = [...sku];
                                                                // let _sku = { ...newSku[i] }
                                                                // let _gudang_sku = [..._sku.dokul_gudang_skus]
                                                                // let _gudang = { ..._gudang_sku[idxGudang] }

                                                                // _gudang.stock = e.target.value
                                                                // _gudang_sku[idxGudang] = _gudang
                                                                // _sku.dokul_gudang_skus = _gudang_sku
                                                                // newSku[i] = _sku
                                                                // setSku(newSku)

                                                                let _product = { ...product }
                                                                let newSku = [..._product.dokul_products_skus]
                                                                let _sku = { ...newSku[props._index] }
                                                                let _gudang_sku = [..._sku.dokul_gudang_skus]
                                                                let _gudang = { ..._gudang_sku[idxGudang] }
                                                        
                                                                _gudang.stock = e.target.value
                                                                _gudang_sku[idxGudang] = _gudang
                                                                _sku.dokul_gudang_skus = _gudang_sku
                                                                newSku[props._index] = _sku
                                                                _product.dokul_products_skus=newSku
                                                                props.handeleUpdateProduct(_product)



                                                            }} />
                                                        </div> : ""}
                                                </div>

                                            </div>
                                        </div>

                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="text-end">
                   
                    <button type="button" className="btn btn-primary text-end" style={{ marginLeft: 5 }} onClick={(e) => {

                        setSku(state => [...state, {
                            id: null,
                            discount: 0,
                            image_url: "/assets/images/noimage.png",
                            name: "",
                            price: 0,
                            dokul_gudang_skus: [],// listGudang.data.gudangs.data.filter(_gd => _gd.branch_id == product.branch_id).map(gd => { return { gudang_id: gd.id, stock: 0, avaibility: 1, notif: 0, status: 1 } }),
                            product_id: product.id,
                            reg_price: 0,
                            status: "instock",
                            stock: 0,
                            total_sales: 0
                        }])

                    }}>Tambah variant</button>
                    </div> */}
            </AvForm>
            <Modal isOpen={openGudang} toggle={() => setopenGudang(false)} centered >
                <ModalBody>
                    <div className="list-group">
                        <li className="list-group-item active" aria-current="true">Pilih gudang</li>
                        {listGudang && listGudang.data.gudangs.data.filter(_gd => _gd.branch_id == product.branch_id).map((gd, idx) =>
                            <label className="list-group-item" key={idx}>

                                <input className="form-check-input me-1" type="checkbox" value={gd.id} checked={selectedSku != null ? sku[selectedSku].dokul_gudang_skus.some(gds => gds.gudang_id == gd.id) : false} onChange={(e) => {

                                    let _product = { ...product }
                                    let _currentListSku = [..._product.dokul_products_skus]
                                    let _curentSku = { ..._currentListSku[props._index] }
                                    let _currentListGudang = [..._curentSku.dokul_gudang_skus]


                                    const gudangIsExist = _currentListGudang.some(gds => gds.gudang_id == gd.id)

                                    if (gudangIsExist) {
                                        const currentGudangIdx = _currentListGudang.indexOf(gds => gds.gudang_id == gd.id)
                                        let currentGudang = { ..._currentListGudang[currentGudangIdx] }

                                        if (e.target.checked) {
                                            currentGudang.status = 3
                                        }
                                        else
                                            currentGudang.status = 1


                                    } else {
                                        _currentListGudang.push({ gudang_id: gd.id, stock: 0, avaibility: 1, notif: 0, status: 1 })

                                    }
                                    _curentSku.dokul_gudang_skus = _currentListGudang
                                    _currentListSku[props._index] = _curentSku
                                    _product.dokul_products_skus = _currentListSku
                                    // setProduct(_product)
                                    props.handeleUpdateProduct(_product)
                                    // setSku(_currentListSku)


                                }} />
                                {gd.nama}
                            </label>
                        )}
                    </div>
                </ModalBody>

            </Modal>
        </Fragment>
    )
    // }
}

export default ProdukVariant

