import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { Collapse, Button, CardBody, Card, Input } from 'reactstrap';
import { Loader } from 'react-feather';


export class Transactions_sales extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: this.props.data.transactions.totalPages,
            loading: false,
            checkedValues: [],
            myData: this.props.data.transactions.transactions,
            isOpen: false,
            monthSelected: 0,
            dateSelected: 0,
            allPM:[],
            dState:[],
            selectedDate: Date()
        }
        this.fetchData = this.fetchData.bind(this);
    }
    async fetchData(state, instance) {
        this.setState({dState:state},()=>console.log(""))
        let id;
        let date;
        let balik;
        let provider;
        let nominal;

        state.filtered.forEach(element => {
            console.log(element)
            if (element.id == 'id') id = element.value
            if (element.id == 'type') provider = element.value
            if (element.id == 'nominal') nominal = element.value
            if (element.id == 'tanggal') {
                var d = moment(element.value)
                var od = moment(this.state.selectedDate)
                if (d.date() == od.date())
                    balik = true;
                date = element.value

            }

        })
        if (balik) return
        // _dataId=this.state.allPM.
        

        const t = await this.props.getNextData(state.pageSize, state.page, this.state.allPM.toString(), date, provider,nominal)
        console.log(t)
        this.setState({
            myData: t.data.transactions.transactions,
            pages: t.data.transactions.totalPages,
            selectedDate: date
        })

        // console.log(t)
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        //this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        //   requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
        //     res => {
        //       // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        //       this.setState({
        //         // data: res.rows,
        //         // pages: res.pages,
        //         loading: false
        //       });
        //     }
        //   );
    }
    getPayName=(id)=>{


    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };
    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    openFilter = (i) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    render() {
        const { pageSize, myClass, multiSelectOption, pagination, dConfig } = this.props;
        const { myData, pages, loading, isOpen, selectedDate,allPM } = this.state
        const columns = [];

      


        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("#ID")}</b>,
        //         accessor: "id",
        //         Cell: (data) => {
        //             return '#' + data.original.id

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });
        columns.push(
            {
                Header: <b>{this.Capitalize("MUTASI")}</b>,
                accessor: "mutasi",
                filterable: true,
                width: 100,
                Cell: (data) => {
                    return <span className="badge badge-success">{data.original.mutasi}</span>

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("keterangan")}</b>,
                accessor: "keterangan",
                filterable: true,
                width: 500,
                Cell: (data) => {
                    return <span>{data.original.keterangan}</span>

                },
                style: {
                    textAlign: 'justify',
                    width: 200
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("provider")}</b>,
                accessor: "type",
                filterable: true,
                Cell: (data) => {
                    const pay = data.original.type
                    return <span className={`badge badge-${pay == 1 ? 'info' : pay == 2 ? 'warning' : pay == 3 ? 'primary' : 'light'}`}>{`${pay == 1 ? 'BCA' : pay == 2 ? 'MANDIRI' : pay == 3 ? 'QRIS' : "OTHER"}`}</span>



                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Tanggal")}</b>,
                accessor: "tanggal",
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <Input
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                        onChange={event => {
                            console.log(event.target)
                            onChange(event.target.value)
                        }
                        }
                        value={filter ? filter.value : ""}
                    />
                    //             <DatePicker 
                    //             selected={selectedDate}
                    // onChange={date =>this.changeDate(date)}
                    // dropdownMode="select"
                    // />
                ),
                Cell: (row) => {
                    return moment(row.original.modify_date).format("DD-MMMM-YYYY HH:mm:ss")
                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Nominal")}</b>,
                accessor: "nominal",
                filterable: true,
                Cell: (data) => {
                    return <b>{data.original.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>

                },
                style: {
                    textAlign: 'right'
                }
            });
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Payment")}</b>,
        //         accessor: "payment_method_text",
        //         Cell: (data) => {
        //             const pay = data.original.payment_method_text.toLowerCase()
        //             return <span className={`badge badge-${pay.includes('bca') ? 'info' : pay.includes('mandiri') ? 'warning' : pay.includes('qris') ? 'primary' : 'light'}`}>{`${pay.includes('bca') ? 'BCA' : pay.includes('mandiri') ? 'MANDIRI' : pay.includes('qris') ? 'QRIS' : pay}`}</span>

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });


        if (multiSelectOption != true) {
            columns.push(
                {
                    Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                        onClick={
                            (e) => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    this.handleRemoveRow()
                            }}>Delete</button>,
                    id: 'delete',
                    accessor: str => "delete",
                    sortable: false,
                    style: {
                        textAlign: 'center'
                    },
                    Cell: (row) => (
                        <div>
                            <span >
                                <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                    onChange={e => this.selectRow(e, row.original.id)} />
                            </span>
                        </div>
                    ),
                    //accessor: key,
                    style: {
                        textAlign: 'center'
                    }
                }
            )
     
        //else {
        //     columns.push(
        //         {
        //             Header: <b>Action</b>,
        //             id: 'delete',
        //             accessor: str => "delete",
        //             Cell: (row) => (
        //                 <div>
        //                     <span onClick={() => {
        //                         if (window.confirm('Are you sure you wish to delete this item?')) {
        //                             let data = myData;
        //                             data.splice(row.index, 1);
        //                             this.setState({ myData: data });
        //                         }
        //                         toast.success("Successfully Deleted !")

        //                     }}>
        //                         <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }}
        //                         ></i>
        //                     </span>



        //                     <span>
        //                         <Link
        //                             to={`/sales/transactions/detail/${row.original.id}`}
        //                         >
        //                             <i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
        //                         </Link>
        //                     </span>
        //                 </div>
        //             ),
        //             style: {
        //                 textAlign: 'center'
        //             },
        //             sortable: false
        //         }
        //     )
        }
        return (
            <Fragment>

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex flex-row bd-highlight  justify-content-between">
                                        <div className="d-flex flex-column bd-highlight justify-content-between">
                                            <div className="p-2 bd-highlight text-warning"><b>METODE PEMBAYARAN JAKARTA</b></div>
                                            {dConfig && dConfig.configs.find(x => x.id == 1).dokul_paymethods.map((v, k) =>
                                                <div key={k} className="p-2 bd-highlight">
                                                   
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox"  id="flexSwitchCheckDefault" onChange={(e)=>{
                                                            let _oldPM=[...allPM]
                                                            if(e.target.checked){
                                                                _oldPM.push(v.id)
                                                                this.setState({allPM:_oldPM},()=>this.fetchData(this.state.dState))


                                                            }else{
                                                               

                                                                const idx=_oldPM.findIndex(x=>x==v.id.toString())
                                                                _oldPM.splice(idx,1)
                                                                this.setState({allPM:_oldPM},()=>this.fetchData(this.state.dState))
                                                                
                                                                
                                                            }
                                                            
                                                        }}/>
                                                        <label className="form-check-label" for="flexSwitchCheckDefault"> {v.name} {v.nm_pemilik} {v.no_rek}</label>
                                                    </div>
                                                </div>


                                            )}
                                        </div>
                                        <div className="d-flex flex-column bd-highlight  justify-content-between">
                                            <div className="p-2 bd-highlight text-success"><b>METODE PEMBAYARAN BALIKPAPAN</b></div>
                                            {dConfig && dConfig.configs.find(x => x.id == 2).dokul_paymethods.map((v, k) =>
                                                <div key={k} className="p-2 bd-highlight">
                                                   
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={(e)=>{
                                                            let _oldPM=[...allPM]
                                                            if(e.target.checked){
                                                                _oldPM.push(v.id)
                                                                this.setState({allPM:_oldPM},()=>this.fetchData(this.state.dState))


                                                            }else{
                                                               

                                                                const idx=_oldPM.findIndex(x=>x==v.id.toString())
                                                                _oldPM.splice(idx,1)
                                                                this.setState({allPM:_oldPM},()=>this.fetchData(this.state.dState))
                                                                
                                                                
                                                            }
                                                            
                                                        }}/>
                                                    <label className="form-check-label" for="flexSwitchCheckDefault"> {v.name} {v.nm_pemilik} {v.no_rek}</label>
                                                </div>
                                            </div>

                                            )}
                                        </div>



                                    </div>


                                    {/* <button className="btn btn-small btn-info" onClick={() => {
                                        if (!this.props.mutationLoading)
                                            this.props.reloadTransactions({
                                                variables: {
                                                    body: {},
                                                    id: "cekMutasiBca"
                                                },
                                                onCompleted: (id) => {
                                                    //localStorage.setItem("AUTH_TOKEN", login.token);
                                                    console.log("Sukses")
                                                    //document.cookie = 'token=' + login.token
                                                    // document.cookie = 'signedin=true'
                                                    // isLoggedInVar(true);

                                                }, onError: ({ graphQLErrors, networkError }) => {
                                                    console.log(networkError)

                                                    console.log(graphQLErrors)
                                                    //  localStorage.setItem("AUTH_TOKEN", login.token);
                                                    //history.push('/');
                                                },
                                            }).then(data => {
                                                // return addEdge(params, els)
                                            })
                                    }}>{this.props.mutationLoading ? <Loader ></Loader> : "Cek mutasi BCA sekarang"}</button> */}


                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="transactions">
                                        <ReactTable
                                            data={myData}
                                            columns={columns}
                                            defaultPageSize={10}
                                            className={"-striped -highlight"}
                                            showPagination={pagination}
                                            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                            pages={pages} // Display the total number of pages
                                            loading={loading} // Display the loading overlay when we need it
                                            onFetchData={this.fetchData} // Request new data when things change
                                            sorted={this.state.sorted}
                                            onPageChange={(val) => this.setState({ dState: val }, (_) => this.fetchData(this.state.dState))}
                                            onSortedChange={(newSort, column) => {
                                                this.setState({ sorted: newSort });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Transactions_sales
