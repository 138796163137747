import React, { Fragment } from 'react';
import AdminUserChat from './chat.admin.user'
import DokterUserChat from './chat.dokter'

import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import style from "./style.scss";

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;
const GET_PRODUCTS = gql`query products {
    products @rest(type: "Product",path: "api/products") {
        name,
            dokul_products_skus
            branchId,
            dokul_tags_categories,
            dokul_product_images
            tags
            id
            description
    }
    layanans @rest(type: "Layanan",path: "api/layanan") {
        id
        name
        deskripsi
        picture
        banner
        type
        dokul_page
        dokul_kliniks
    }
    konsultasis @rest(type: "Konsultasi",path: "api/dokter/konsultasi") {
        id
        dokul_dokter
        dokul_klinik
        harga
        name
        status
    }
    dokters @rest(type: "Dokter",path: "api/dokter") {
        id
        dokul_dokter_konsultasis
        fullname
        picture
        picture
        available
    }
    kliniks @rest(type: "Klinik",path: "api/dokter/klinik") {
        id
        dokul_dokter_konsultasis
        address
        banner
        branchid
        name
        tlp
    }
  }
`;
const ADD_PRODUCT = gql`
    mutation updateMutation(
      $data: any
    ) {
      login(body: $data)
      @rest(
        type: "Post"
        path: "api/products"
        method: "POST"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
  const ADD_CATEGORIES = gql`
    mutation updateCategories(
      $data: any
    ) {
      updateCategories(body: $data)
      @rest(
        type: "Post"
        path: "api/products/categories"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
const GET_CATEGORIES = gql`query categories {
    categories @rest(type: "Category",path: "api/products/categories") {
        id,
        name,
        description
    }
  }
`;
const GET_TAGS = gql`query tags {
    tags @rest(type: "Tag",path: "api/products/tags") {
        id,
        name,
        description
    }
  }
`;
const CHATS = ({ match }) => {
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    //const params = useParams();

    if (match.params.page) {
         console.log(match.params.page)
        console.log(match.params.id)


    }
    const { loading, data, error } = useQuery(GET_PRODUCTS,);
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    if (error ) {
        console.log(error.message);
        Cookies.remove('signedin','/');
        Cookies.remove('jwt');
        


        history.push('/');
        //return <div>Error!</div>;
    }
   
    const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    let product;
    const [addProduct,{ loading: mutationLoading, error: mutationError,called:mutationCalled },] = useMutation(ADD_PRODUCT,);
    const saveProduct=(product)=>{
        addProduct({
            variables: {
                data: product
            },
            onCompleted: ({ login }) => {
                console.log("Sukses")
                history.push('/products');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })

    }
    const [addCategory,{ loading: mutationCategoryLoading, error: mutationCategoryError,called:mutationCategoryCalled },] = useMutation(ADD_CATEGORIES,);
    const saveCategory=(category)=>{
        const t=addCategory({
            variables: {
                data: category
            },
            onCompleted: (data) => {
                console.log("Sukses")
                history.push('/products/categories');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })
        return t

    }

if(match.params.page=='edit'){
    product = client.readFragment({
        id: `Product:${match.params.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment editProduct on Product {
            name,
            dokul_products_skus
            branchId,
            dokul_tags_categories,
            dokul_product_images
            id
            description
            tags
          }
        `,
    }, false);
}
    

    //daricahce
    // const { products } = client.readQuery({
    //     query: gql`
    //       query ReadProducts {
    //         products {
    //           id
    //         }
    //       }
    //     `,
    //   },false);

    // const todo = client.readFragment({
    //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment myTodo on Product {
    //         id
    //         name
    //       }
    //     `,
    // });
    //console.log(data);
    // setData(datanya)
    
    return (
        <Fragment>
            {loading? <div>Loading</div>:<>
            <Breadcrumb title="CHAT"  />
            {
                match.params.page == 'user' ?
                    <AdminUserChat data={data} id={match.params.id}  />
                    : match.params.page == 'detail' ?
                        <DokterUserChat />
                        : match.params.page == 'edit' ?
                            <DokterUserChat data={data} id={match.params.id} product={product} />
                            : match.params.page == 'add' ?
                                <DokterUserChat data={data} saveProduct={saveProduct} history={history} mutationCalled={mutationCalled} mutationError={mutationError}/>
                                :match.params.page == 'categories' ?
                                <DokterUserChat data={data} saveCategory={saveCategory} />
                                :
                                <DokterUserChat  data={data} />
            }
            </>
        }

        </Fragment>
    )

}

export default withApollo(CHATS)