import React, { Component, Fragment, forwardRef, useState, useEffect } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import CurrencyInput from 'react-currency-input-field';

import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { DELETE_SESI_TEMPLATE, DELETE_TEMPLATE, GET_DOKTERS, GET_JADWAL_TEMPLATES, GET_KLINIKS, GET_RESERVASI, SAVE_SESI_TEMPLATE, SAVE_TEMPLATE, UPDATE_DOKTER } from '../../constants/graph';
import data from '../../assets/data/physical_list';
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
registerLocale('id', es)

const layananType = ["", "Konsultasi", "Perawatan", "Tindakan"]

const TemplateJadwal = (props) => {
    const { loading: dokterLoading, error: dokterError, data: dokterData } = useQuery(GET_DOKTERS)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalSesiOpen, setmodalSesiOpen] = useState(false)
    const [newSesi, setnewSesi] = useState({})
    const [newTemplate, setnewTemplate] = useState({
        template_name: "",
        dokul_jadwal_sesis: [],
        id_klinik: "",
        id_dokter: "",
        id_layanan:"",
        is_static:0
    })
    const [tgl, setTgl] = useState(new Date())
    const [idxDokter, setIdxDokter] = useState(0)
    const [selectedHari, setselectedHari] = useState([])
    const [timeFrom, settimeFrom] = useState()
    const [timeTo, settimeTo] = useState()
    const [idxKlinik, setIdxKlinik] = useState(0)
    const [jadwalBaru, setJadwalBaru] = useState({})
    const columns = [];
    const listHari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
    const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const [getTemplates,{ loading, error, data }] = useLazyQuery(GET_JADWAL_TEMPLATES,{fetchPolicy:'network-only'})
    const client = useApolloClient()
    const updateCacheTemplate = (cache, { data }) => {
        // console.log(data.saveTemplate)
        getTemplates()
        setModalOpen(false)

        // console.log(data)
        //     const _updatedDokter = { ...data.updateDokter }
        //     const fragmentResult = cache.writeFragment({
        //         id: `Dokter:${_updatedDokter.id}`,
        //         fragment: gql`
        //     fragment dokter on Dokter{
        //         id
        //         dokul_dokter_konsultasis
        //         fullname
        //         picture
        //         picture
        //         available
        //     }
        //   `,
        //         data: _updatedDokter
        //     });
    };
    const [addTemplate] = useMutation(SAVE_TEMPLATE, { update: updateCacheTemplate });
    const [addSesiTemplate] = useMutation(SAVE_SESI_TEMPLATE,{
        onCompleted:()=>{
            getTemplates()
        }
    });
    const [deleteSesiTemplate] = useMutation(DELETE_SESI_TEMPLATE);
    const [deleteTemplate] = useMutation(DELETE_TEMPLATE);
    useEffect(() => {
        getTemplates()
    
      return () => {
        return false
      }
    }, [])
    

    const updateCache = (cache, { data }) => {

        const _updatedDokter = { ...data.updateDokter }
        const fragmentResult = cache.writeFragment({
            id: `Dokter:${_updatedDokter.id}`,
            fragment: gql`
        fragment dokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
        }
      `,
            data: _updatedDokter
        });
    };
    const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });
    const selectedDokter = client.readFragment({
        id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment readDokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
          }
        `,
    }, false);
    const { loading: loadingKlinik, error: errorKlinik, data: dataKlinik } = useQuery(GET_KLINIKS)


    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }

    if (loading || loadingKlinik || dokterLoading || !data) return "Loading"
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Template <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                setnewTemplate({
                                    template_name: "",
                                    dokul_jadwal_sesis: [],
                                    id_klinik: "",
                                    id_dokter: "",
                                    id_layanan:""
                                })
                                setModalOpen(true)
                            }}
                            >ADD</span></li>
                            {/* <li className={`list-group-item ${selectedGudang == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => { }}>Semua gudang</li> */}


                            {data.templates.map((template, idx) =>
                                <li key={idx} className={`list-group-item ${idxDokter == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    setIdxDokter(idx)
                                    setnewTemplate(template)

                                }}>
                                    <Row>
                                        <Col sm="8">
                                            {template.template_name}
                                        </Col>
                                        <Col sm="4">
                                            {idxDokter == idx && <button className='btn btn-icon float-end p-1 btn-danger m-1' onClick={() => {
                                                deleteTemplate(
                                                    {
                                                        variables: {
                                                            type: template.id
                                                        }
                                                    }
                                                )
                                            }} ><i className='icofont icofont-ui-delete'></i></button>}
                                            {idxDokter == idx && <button className='btn btn-icon float-end p-1 btn-light m-1' onClick={() => {
                                                setnewTemplate(data.templates[idxDokter])
                                                setModalOpen(true)
                                            }} ><i className='fa fa-pencil'></i></button>}
                                        </Col>

                                    </Row>

                                </li>

                            )}

                        </ul>

                    </div>
                    <div className="col-sm-8">
                        {/* <div className="card"> */}
                        {/* <div className="card-header bg-primary text-white">
                                <span className="float-left">
                                    <div className="d-flex flex-row bd-highlight ">
                                        <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">Sesi Konsultasi</h4></div>
                                      

                                    </div>
                                </span>

                            </div> */}

                        {/* <div className="card-body"> */}
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Template detail</li>
                            <li className={`list-group-item bg-light text-dark w-100`}>
                                <Row>
                                <Col md={2}>Static</Col><Col md={10}>
                                        <input type={'checkbox'} checked={data.templates[idxDokter]?.is_static} disabled />
                                    </Col>
                                    <Col md={2}>Dokter</Col><Col md={10}>
                                        {dokterData.dokters.filter(x => data.templates[idxDokter]?.id_dokter.includes(x.id)).map(x =>
                                            <Badge key={x.id} color='light' className='text-dark border m-2'>{x.fullname}</Badge>
                                        )}
                                    </Col>
                                    <Col md={2}>Klinik</Col><Col md={10}>
                                        {dataKlinik.kliniks.filter(x => data.templates[idxDokter]?.id_klinik.includes(x.id)).map(x =>
                                            <Badge key={x.id}  color='light' className='text-dark border m-2'>{x.name}</Badge>
                                        )}
                                    </Col>
                                    <Col md={2}>Type</Col><Col md={10}>
                                        {layananType.filter((x, i) => data.templates[idxDokter]?.id_layanan.includes(i)).map((x ,i)=>
                                            <Badge  key={i} color='light' className='text-dark border m-2'>{x}</Badge>
                                        )}
                                    </Col>
                                </Row>

                            </li>
                            <li className={`list-group-item bg-primary w-100`}>Sesi Template <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                setmodalSesiOpen(true)
                            }}
                            >ADD</span></li>
                            {data.templates.length > 0 && data.templates[idxDokter]?.dokul_jadwal_sesis.map((kon, idxKon) =>

                                <li key={idxKon} className={`list-group-item `}  >
                                    <div className="d-flex flex-row justify-content-around">
                                        <div className="p-2 bd-highlight">{kon.hari.split(",").map((hari, idxh) => <Badge key={idxh} className="p-1 m-1">{listHari[hari]}</Badge>)}</div>
                                        <div className="p-2 bd-highlight">{kon.sesi}</div>
                                        <div className="p-2 bd-highlight float-end">Jumlah pasien : {kon.jumlah_pasien}</div>
                                        <div className="p-2 bd-highlight float-end">Biaya : {kon.biaya}</div>
                                        <span className="bg-red rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                            let updateListTmp = []
                                            let currentTemp = { ...data.templates[idxDokter] }
                                            let newListTmp = [...currentTemp.dokul_jadwal_sesis]

                                            let idx = newListTmp.indexOf(kon)

                                            newListTmp.splice(idx, 1)

                                            // newListTmp.push(newListTmp)
                                            currentTemp.dokul_jadwal_sesis = newListTmp
                                            updateListTmp.push(currentTemp)
                                            deleteSesiTemplate({
                                                variables: {
                                                    type: kon.id
                                                },
                                            })

                                        }}><i className="icofont icofont-ui-close" /></span>
                                    </div>
                                </li>
                            )}
                        </ul>
                        {/* <div className="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                            <ToastContainer />
                        </div>

                        {/* {data.dokters[idxDokter].dokul_dokter_konsultasis.filter(ykon => ykon.type == 2).length > 0 ?
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                        <h6 className="border-bottom border-info"><i className="icofont icofont-globe" /> ONLINE </h6>
                                        <div className="p-2 bd-highlight mb-2">
                                            <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-left">
                                                <div className="d-flex flex-row">
                                           
                                                </div>
                                                <Link
                                                    to={`/products/edit/${data.dokters[idxDokter].dokul_dokter_konsultasis.find(onk => onk.type == 2).id_product}`}
                                                >
                                                    <span className="p-2 bd-highlight rounded bg-success">EDIT PRODUK </span>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                } */}
                    </div>
                </div>
            </div>
            <div className="clearfix" />
            {/* </div> */}
            {/* </div> */}
            <Modal isOpen={modalOpen} centered size='md' toggle={() => setModalOpen(false)}  >
                <ModalHeader className='bg-primary' toggle={() => setModalOpen(false)}> <h5>{newTemplate.id ? "Edit Template" : "Tambah template"}</h5></ModalHeader>
                <ModalBody className='bg-white'>
                    <h6 className="font-weight-bold">{data.templates[idxDokter]?.fullname}</h6>
                    <form>
                        <div className="form-group">
                            <label htmlFor="namatemplate">Nama Template</label>
                            <input type="text" className="form-control mt-2" id="namatemplate" defaultValue={newTemplate.template_name} placeholder="masukan nama template" onChange={(e) => {
                                setnewTemplate({
                                    ...newTemplate,
                                    template_name: e.target.value

                                })
                            }} />
                        </div>
                        <div className="form-group">
                        <Badge color='light' className='text-dark border m-2'><input className='m-1' type={'checkbox'} checked={newTemplate.is_static} onChange={(e)=>{
                                        
                                        setnewTemplate({
                                            ...newTemplate,
                                            is_static: e.target.checked?1:0
    
                                        })
                                    }}/>Static Jadwal</Badge>
                            
                        </div>

                        <div className="form-group mt-3">

                            <Row>

                                <Col md={2}>Klinik</Col><Col md={10}>
                                    {dataKlinik.kliniks.map(x =>
                                    <Badge key={x.id}  color='light' className='text-dark border m-2'><input className='m-1' type={'checkbox'} checked={newTemplate?.id_klinik.includes(x.id)} onChange={(e)=>{
                                        console.log("CLICKED")
                                        let oldKlinik=newTemplate.id_klinik.split(",")
                                        if(e.target.checked){
                                            oldKlinik.push(x.id)
                                        }else{
                                            const idx_=oldKlinik.findIndex(y=>x.id==y)
                                            oldKlinik.splice(idx_,1)
                                        }
                                        
                                        setnewTemplate({
                                            ...newTemplate,
                                            id_klinik: oldKlinik.join(',')
    
                                        })
                                    }}/>{x.name}</Badge>
                                    )}
                                </Col>

                            </Row>
                            {/* <select className="form-select" ariaLabel="Default select example" onChange={(e)=>{
                                    let oldKlinik=newTemplate.id_klinik.split(",")
                                    
                                    setnewTemplate({
                                        ...newTemplate,
                                        id_klinik: e.target.value

                                    })
                                }}>
                                    <option value=""  selected>All</option>
                                    <option value="1">Apotek Anda</option>
                                    <option value="2">Apotek S</option>
                                    <option value="3">Premium Paris</option>
                                    <option value="4">Jakarta</option>
                                </select> */}
                        </div>
                        <div className="form-group  mt-3">

                            <Row>
                            <Col md={2}>Dokter</Col><Col md={10}>
                                    {dokterData.dokters.map(x =>
                                    <Badge key={x.id}  color='light' className='text-dark border m-2'><input className='m-1' type={'checkbox'} checked={newTemplate?.id_dokter.includes(x.id)} onChange={(e)=>{
                                        console.log("CLICKED")
                                        let oldKlinik=newTemplate.id_dokter.split(",")
                                        if(e.target.checked){
                                            oldKlinik.push(x.id)
                                        }else{
                                            const idx_=oldKlinik.findIndex(y=>x.id==y)
                                            oldKlinik.splice(idx_,1)
                                        }
                                        
                                        setnewTemplate({
                                            ...newTemplate,
                                            id_dokter: oldKlinik.join(',')
    
                                        })
                                    }}/>{x.fullname}</Badge>
                                    )}
                                </Col>

                            </Row>
                            {/* <select className="form-select" ariaLabel="Default select example" onChange={(e)=>{
                                    let oldDokter=newTemplate.id_dokter.split(",")
                                    
                                    setnewTemplate({
                                        ...newTemplate,
                                        id_dokter: e.target.value

                                    })
                                }}>
                                    <option value="" selected>All</option>
                                    <option value="1">Dr Arthur</option>
                                    <option value="2">Dr Lusi</option>
                                    <option value="3">Dr Simon</option>
                                </select> */}
                        </div>
                        <div className="form-group mt-3">

                            <Row>

                            <Col md={2}>Type</Col><Col md={10}>
                                    {layananType.filter(x=>x!='').map((x,idx_)=>
                                    <Badge key={x.idx_}  color='light' className='text-dark border m-2'><input className='m-1' type={'checkbox'} checked={newTemplate?.id_layanan.includes(idx_+1)} onChange={(e)=>{
                                        console.log("CLICKED")
                                        let oldKlinik=newTemplate.id_layanan.split(",")
                                        if(e.target.checked){
                                            oldKlinik.push(idx_+1)
                                        }else{
                                            const idx__=oldKlinik.findIndex(y=>idx_+1==y)
                                            oldKlinik.splice(idx__,1)
                                        }
                                        
                                        setnewTemplate({
                                            ...newTemplate,
                                            id_layanan: oldKlinik.join(',')
    
                                        })
                                    }}/>{x}</Badge>
                                    )}
                                </Col>
                            </Row>
                            {/* <select className="form-select" ariaLabel="Default select example" onChange={(e)=>{
                                    let oldDokter=newTemplate.id_dokter.split(",")
                                    
                                    setnewTemplate({
                                        ...newTemplate,
                                        id_dokter: e.target.value

                                    })
                                }}>
                                    <option value="" selected>All</option>
                                    <option value="1">Dr Arthur</option>
                                    <option value="2">Dr Lusi</option>
                                    <option value="3">Dr Simon</option>
                                </select> */}
                        </div>


                    </form>



                </ModalBody>
                <ModalFooter className='bg-primary'>

                    <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                    <button type="button" className="btn btn-primary" onClick={(e) => {
                        let newListTmp = []

                        newListTmp.push(newTemplate)
                        addTemplate({
                            variables: {
                                type: newListTmp
                            },
                        })
                    }}>Simpan</button>


                </ModalFooter>
            </Modal>

            <Modal isOpen={modalSesiOpen} toggle={() => setmodalSesiOpen(false)} centered size='md' >
                <div className="modal-content bg-white">
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title">Tambah sesi</h5>

                    </div>
                    <div className="modal-body">
                        <h6 className="font-weight-bold">{data.templates[idxDokter]?.fullname}</h6>
                        <label >Hari :</label>
                        <div className='d-flex flex-row flex-nowrap'>
                            {listHari.map((hari, idxh) => {
                                return <Badge key={idxh} className='text-dark m-2 p-2' color={selectedHari.some(y => y == idxh) ? 'success' : 'light'} style={{ cursor: "pointer" }} onClick={() => {
                                    const isEksist = selectedHari.some(x => x == idxh)
                                    if (isEksist) {
                                        let _list = [...selectedHari]
                                        const deletedHari = _list.findIndex(x => x == idxh)
                                        _list.splice(deletedHari, 1)
                                        setselectedHari(_list)

                                    } else
                                        setselectedHari(x => [...x, idxh])

                                }}>{hari}</Badge>
                            })}
                        </div>
                        <form>
                            <Row>
                                <Col md={3}>
                                    Biaya :
                                </Col>
                                <Col md={9}>

                                    <CurrencyInput
                                        className='p-2 m-2 border rounded border-light'
                                        id="input-example"
                                        name="input-name"
                                        placeholder="Please enter a number"
                                        defaultValue={0}
                                        decimalsLimit={2}
                                        onValueChange={(value, name) => {
                                            let _newSesi = { ...newSesi }
                                            _newSesi.biaya = value
                                            setnewSesi(_newSesi)
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    Jam Mulai :
                                </Col>
                                <Col md={9}>
                                    <DatePicker
                                        className='m-2'
                                        selected={timeFrom}
                                        onChange={(date) => {
                                            let _newSesi = { ...newSesi }
                                            settimeFrom(date)
                                            _newSesi.timeFrom = moment(date).format("HH:mm")
                                            setnewSesi(_newSesi)
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Jam mulai"
                                        dateFormat="HH:mm"
                                    />
                                </Col>
                                <Col md={3}>
                                    Jam Akhir :
                                </Col>
                                <Col md={9}>
                                    <DatePicker
                                        className='m-2'
                                        selected={timeTo}
                                        onChange={(date) => {
                                            settimeTo(date)
                                            let _newSesi = { ...newSesi }
                                            _newSesi.timeTo = moment(date).format("HH:mm")
                                            setnewSesi(_newSesi)
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Jam mulai"
                                        dateFormat="HH:mm"
                                    />
                                </Col>
                                <Col md={3}>
                                    Jumlah pasien :
                                </Col>
                                <Col md={9}>
                                    <input type="number" className="form-control" id="jumlahpasien" onChange={(e) => {
                                        let _newSesi = { ...newSesi }
                                        _newSesi.jumlah_pasien = e.target.value
                                        setnewSesi(_newSesi)

                                    }} />
                                </Col>
                            </Row>

                            {/* <div className="form-group">
                                <label for="jammulai">Jam Mulai :</label>
                                <input type="time" className="form-control mt-1" id="jammulai" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.timeFrom = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div>
                            <div className="form-group">
                                <label for="jamakhir">Jam Akhir</label>
                                <input type="time" className="form-control" id="jamakhir" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.timeTo = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div> */}

                            {/* <div className="form-group">
                                <label for="jumlahpasien">Jumlah pasien</label>
                                <input type="number" className="form-control" id="jumlahpasien" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.jumlah_pasien = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div> */}


                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let updateListTmp = []
                            let currentTemp = { ...data.templates[idxDokter] }
                            let newListTmp = [...currentTemp.dokul_jadwal_sesis]

                            let newSesiTmp = {
                                template_id: currentTemp.id,
                                hari: selectedHari.toString(),
                                sesi: `${newSesi.timeFrom}-${newSesi.timeTo}`,
                                biaya: newSesi.biaya,
                                jumlah_pasien: newSesi.jumlah_pasien
                            }
                            newListTmp.push(newSesiTmp)
                            currentTemp.dokul_jadwal_sesis = newListTmp
                            // updateListTmp.push(currentTemp)

                            const newListTmpFilter = newListTmp.filter(x => !x.id)
                            // console.log(newListTmpFilter)
                            // return
                            addSesiTemplate({
                                variables: {
                                    type: newListTmpFilter
                                },
                            })

                            setmodalSesiOpen(false)
                        }}>Simpan</button>
                    </div>
                </div>
            </Modal>

            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}


export default withApollo(TemplateJadwal)

