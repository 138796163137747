import React, { useState, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import PointList from './point.list';
import PointAdd from './point'
import PointEdit from './point.edit'
import PointLogs from './point.logs'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import PrivateList from './private.list';
import { GET_CATEGORIES, GET_DOKPOINTS, GET_PRODUCTS_SIMPLE } from '../../constants/graph';

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;


const GET_PRODUCTS = gql`query points(
    $page: String!
    $size: String!
    $query:String!
)  {
    pointlogs(page:$page,size: $size,query:$query) @rest(type: "PointLogs",path: "api/poin/logs?size={args.size}&page={args.page}&{args.query}") {
        PointLogs @type(name: "PointLog") {
            id
            earnedpoints
            redeempoints
            userid
            earneddate
            expirydate
            checkpoints
            earnedequauivalentamount
            redeemequauivalentamount
            orderid
            productid
            variationid
            refuserid
            reasonindetail
            totalpoints
            showmasterlog
            showuserlog
            nomineeid
            nomineepoints
        } ,
        currentPage
        totalItems
        totalPages
    }
    points @rest(type: "Point",path: "api/poin") {
        id,
        name,
        status,
        multipier_by,
        earned_type,
        earned,
        dokul_point_rules,
        dokul_products,
        dokul_tags_categories
        dokul_point_grades
    }
    
    categories @rest(type: "Category",path: "api/products/categories") {
        id,
        name,
        description
    }
    products @rest(type: "Product",path: "api/products") {
        name,
            dokul_products_skus
            branchId,
            dokul_tags_categories,
            dokul_product_images
            tags
            id
            description
    }
  }
`;

const GET_TAGS = gql`query tags {
    tags @rest(type: "Tag",path: "api/products/tags") {
        id,
        name,
        description
    }
  }
`;
const SAVE_POINT = gql`
mutation savePoint(
  $data: any
) {
    savePoint(body: $data)
  @rest(
    type: "Post"
    path: "api/poin"
    method: "POST"
    bodyKey: "body"
  ) {
  completed
}
}
`;
const Points = ({ match }) => {
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    //const params = useParams();

    if (match.params.page) {
        console.log(match.params.page)
        console.log(match.params.id)


    }
    const [savePoint, { loading: mutationCategoryLoading, error: mutationCategoryError, called: mutationCategoryCalled },] = useMutation(SAVE_POINT,);
   
    const { loading, data, error } = useQuery(GET_PRODUCTS_SIMPLE, {
        variables: {
            brand: 'all',
            textSearch:'all'
        }
    });
    const { loading:lCategories, data:dCategories, error:eCategories } = useQuery(GET_CATEGORIES);
    
    const { loading:lPoint, data:dPoint, error:ePoint,fetchMore } = useQuery(GET_DOKPOINTS, {
        variables: {
            page: 0,
            size: 10,
            query: ''
        }
    });
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    if (error) {
        console.log(error);

        // Cookies.remove('signedin','/');
        // Cookies.remove('jwt');



        // history.push('/');
        //return <div>Error!</div>;
    }

    const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    let point;
    if (match.params.page == 'edit') {
        point = client.readFragment({
            id: `Point:${match.params.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
            fragment: gql`
          fragment editPoint on Point{
            id
            name
            status,
        multipier_by,
        earned_type,
        earned,
        dokul_point_rules,
        dokul_products,
        dokul_tags_categories
        dokul_point_grades
          }
        `,
        }, false);
    }
    const getNextData = (cpagesize, rpage, query) => {

        //     const UPDATE_MUTATION = gql`
        //     mutation updateMutation(
        //       $data: any
        //       $id:ID!
        //     ) {
        //       login(id:$id,body: $data)
        //       @rest(
        //         type: "Post"
        //         path: "api/products/{args.id}"
        //         method: "PUT"
        //         bodyKey: "body"
        //       ) {
        //       completed
        //     }
        //     }
        //   `;

        //    client.writeQuery({
        //     GET_CATEGORIES,
        //         data: {
        //           orders: {
        //             orders: [],
        //           },
        //         },
        //       });
        // orders().then(data=>{
        //     console.log(data)
        // })
        return fetchMore({
            variables: {
                page: rpage,
                size: cpagesize,
                query: query

            }
        })
        //   .then(dt=>{
        //       console.log(dt)
        //     return dt.data
        //   });
        //       const { orders,error } = client.readQuery({
        //         query: GET_ORDERS,
        //         variables: {
        //           page:rpage,
        //           size: 10

        //   }
        //       });
        //       if(error)
        //         return
        //return orders
    }


    //daricahce
    // const { products } = client.readQuery({
    //     query: gql`
    //       query ReadProducts {
    //         products {
    //           id
    //         }
    //       }
    //     `,
    //   },false);

    // const todo = client.readFragment({
    //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment myTodo on Product {
    //         id
    //         name
    //       }
    //     `,
    // });
    //console.log(data);
    // setData(datanya)

    return (
        <Fragment>
            {loading || lCategories? <div>Loading</div> : <>
                <Breadcrumb title="VOUCHER" parent="" />
                {
                    match.params.page == 'detail' ?
                            <PointList />
                            : match.params.page == 'edit' ?
                                <PointEdit listProduct={data.productssimple} id={match.params.id} point={point} savePoint={savePoint} />
                                : match.params.page == 'add' ?
                                    <PointAdd listProduct={data.productssimple} savePoint={savePoint} />
                                    : match.params.page == 'log' ?
                                        <PointLogs listProduct={data.productssimple} getNextData={getNextData} />
                                        :
                                        <Tabs>
                                            <TabList className="nav nav-tabs tab-coupon" >
                                                <Tab className="nav-link">Public Vouchers</Tab>
                                                <Tab className="nav-link">Private Vouchers</Tab>
                                                {/* <Tab className="nav-link">Logs</Tab> */}
                                            </TabList>
                                            <TabPanel>
                                                <PointList listCategories={dCategories.categories} listProduct={data.productssimple} />
                                            </TabPanel>
                                            <TabPanel>
                                            <PrivateList listProduct={data.productssimple} getNextData={getNextData} />
                                            </TabPanel>
                                            <TabPanel>
                                            {/* <PointLogs listProduct={data.productssimple} getNextData={getNextData} /> */}
                                            </TabPanel>
                                        </Tabs>
                }
            </>
            }

        </Fragment>
    )

}

export default withApollo(Points)
