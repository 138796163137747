import React, { Component } from 'react';
import { Spinner, Modal, Label } from 'reactstrap';
import man from '../../assets/images/dashboard/man.png'

export class Loading extends Component {

    render() {
        return (
            <Modal isOpen={this.props.show}  centered={true} >
                <div className="d-flex justify-content-center " style={{ backgroundColor: 'transparent' }}>
                    <div className='bg-white p-2 rounded text-center'>
                        <Spinner animation="grow" color="info"  title='Loading' />
                        <Label className='w-100 text-center'>{this.props.text?this.props.text:"Loading"}</Label>
                    </div>

                </div>

            </Modal>

            // <div className="d-flex justify-content-center vh-100" style={{ backgroundColor: 'rgba(52, 52, 52, 0.1)' }}>
            //     <Spinner animation="border" variant="primary" />

            // </div>


        )
    }
}

export default Loading
