import React, { useState, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import ServiceReservasi from './service.reservasi';
import {GET_SLOT_KONSULTASI,ADD_SLOTS,ADD_ROWS, GET_RESERVASI} from '../../constants/graph'
import TabSet from './tab.set';


const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

// const GET_KONSULTASI = gql`query konsultasi {
  
//     slots @rest(type: "Slots",path: "api/konsultasi/slot?page=0&size=10") {
//       data @type(name: "Slot") { 
//         id
//         create_date
//         sold_date
//         id_dokter
//         id_user
//         payment
//         payment_text
//         payment_status
//         status
//         description
//         dokul_dokter
//         dokul_user
                 
//       } ,
//     currentPage
//     totalItems
//     totalPages
      
//   }
//   dokters @rest(type: "Dokter",path: "api/dokter") {
//     id
//     dokul_dokter_konsultasis
//     fullname
//     picture
//     picture
//     available
// }
// kliniks @rest(type: "Klinik",path: "api/dokter/klinik") {
//     id
//     dokul_dokter_konsultasis
//     address
//     banner
//     branchid
//     name
//     tlp
// }
    
//   }
// `;

// const GET_CATEGORIES = gql`query categories {
//     categories @rest(type: "Category",path: "api/products/categories") {
//         id,
//         name,
//         description
//     }
//   }
// `;
// const GET_TAGS = gql`query tags {
//     tags @rest(type: "Tag",path: "api/products/tags") {
//         id,
//         name,
//         description
//     }
//   }
// `;


const Layanans = ({ match }) => {
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    //const params = useParams();

    if (match.params.page) {
        // console.log(match.params.page)
        // console.log(match.params.id)


    }
    // const { loading, data, error } = useQuery(GET_RESERVASI,);
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    // if (error ) {
    //     console.log(error.message);
    //     Cookies.remove('signedin','/');
    //     Cookies.remove('jwt');
        


    //     history.push('/');
    //     //return <div>Error!</div>;
    // }
   
    // const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    // let product;
    // const [addSlots,{ loading: mutationLoading, error: mutationError,called:mutationCalled },] = useMutation(ADD_SLOTS,);
 
    // const saveSlots=(service)=>{
    //   addSlots({
    //         variables: {
    //             data: service
    //         },
    //         onCompleted: (saveSlots ) => {
    //             console.log("Sukses")
    //             history.push('/konsultasi/slot');
    //         }, onError: ({ graphQLErrors, networkError }) => {
    //             console.log("ada eror")
    //             console.log(networkError)
    //             console.log(graphQLErrors)
    //         },
    //     })

    // }
    // const [addRow,{ loading: mutationCategoryLoading, error: mutationCategoryError,called:mutationCategoryCalled },] = useMutation(ADD_ROWS,);
    // const saveRow=(category)=>{
    //     const t=addRow({
    //         variables: {
    //             data: category
    //         },
    //         onCompleted: (data) => {
    //             console.log("Sukses")
    //             history.push('/products/categories');
    //         }, onError: ({ graphQLErrors, networkError }) => {
    //             console.log("ada eror")
    //             console.log(networkError)
    //             console.log(graphQLErrors)
    //         },
    //     })
    //     return t

    // }

// if(match.params.page=='edit'){
//     product = client.readFragment({
//         id: `Product:${match.params.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
//         fragment: gql`
//           fragment editProduct on Product {
//             name,
//             dokul_products_skus
//             branchId,
//             dokul_tags_categories,
//             dokul_product_images
//             id
//             description
//             tags
//           }
//         `,
//     }, false);
// }
// const handleImageUpload = async(file,name) => {
//   //const files = event.target.files
//   const formData = new FormData()
//   formData.append('uploaded_file', file, `${name}_${file.name}`)


//   const response= await fetch('http://localhost:9000/api/upload', {
//       method: 'POST',
//       body: formData
//   })
//       .then(response => response.json())
//       .then(data => {
//           console.log(data)
//           return data
          
//       })
//       .catch(error => {  
//           console.error(error)
//       })
// }
// response.ok;     // => false
//   response.status; // => 404
// const result=await response.json
// return result.json;
// }

    //daricahce
    // const { products } = client.readQuery({
    //     query: gql`
    //       query ReadProducts {
    //         products {
    //           id
    //         }
    //       }
    //     `,
    //   },false);

    // const todo = client.readFragment({
    //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment myTodo on Product {
    //         id
    //         name
    //       }
    //     `,
    // });
    //console.log(data);
    // setData(datanya)
    
    return (
        <Fragment>
            {/* {loading? <div>Loading</div>:<> */}
            <Breadcrumb title="Reservasi" />
            <TabSet />
            {/* <ServiceReservasi data={data} client={client} /> */}
            {/* </> */}
        {/* } */}

        </Fragment>
    )

}

export default withApollo(Layanans)


