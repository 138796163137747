import React, { Component,Fragment,useContext,useState,useEffect } from 'react'
import { ShoppingBag, Download, AlertCircle } from 'react-feather';
import SocketContext from '../../../provider/socketctx/context';
import { sendNewMessage } from "../../../provider/socket/emits";
import {Link} from 'react-router-dom'
const Notification = (props) => {
    const [mysmessage,setMymessage]=useState("")
    const { positionInLine, queueLength, message } = useContext(SocketContext);
    console.log(SocketContext)
    useEffect(() => {

    }, [message])
    //render() {
        return (
            <Fragment>
                
                        <ul className="notification-dropdown onhover-show-div p-0">
                            <li>Notification <span className="badge badge-pill badge-primary pull-right">{message.length}</span></li>
                           
                            {/* <li><Link to={`${process.env.PUBLIC_URL}/chat/user`} >
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0"><span><ShoppingBag /></span>Your order ready for Ship..!</h6>
                                        <p className="mb-0">oo</p>
                                    </div>
                                </div>
                                </Link>
                            </li> */}
                            
                            <li className="txt-dark" onClick={(e)=>sendNewMessage("ssss")}><a href="#">All</a> notification</li>
                        </ul>
            </Fragment>
        )
   // }
}

export default Notification
