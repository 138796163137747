import React, { useState, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import { GET_LAYANANS, GET_PRODUCTS } from '../../constants/graph';


const ProductResult = (props) => {
   
    const { loading, data, error } = useQuery(GET_LAYANANS,);
    const client = useApolloClient();
    
    

    
    if(loading) return "Loading"
    let product = client.readFragment({
        id: `Layanan:${props.prodId}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment readLayanan on Layanan {
            id
        name
        deskripsi
        picture
        banner
        type
        dokul_page
        dokul_kliniks
          }
        `,
    }, false);
    if(!product) return "loader"
    return (
     
         
          <div className="d-flex">
  <div className="p-1"> <img src={` ${process.env.REACT_APP_IMAGE_HOST}${product.picture}`} alt="" className="img-fluid lazyloaded m-0 p-0" style={{width:50}}/></div>
  <div className="p-1"> {product.name}</div>
  <div className="p-1 ml-auto"><i className="icofont icofont-close text-danger " onClick={()=>props.onDetailDelete()} style={{cursor:"pointer"}}/></div>
</div>
  
    )

}

export default withApollo(ProductResult)
