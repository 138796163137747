import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import data from '../../../assets/data/orders';
import Datatable from '../../common/datatable'
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { Alert, Badge, Col, Form, FormGroup, FormText, Label, Media, ModalBody, ModalFooter, Row, Table, Tooltip } from 'reactstrap';
import { Collapse, Button, CardBody, Card, Input } from 'reactstrap';
import { Popover, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import ModelOrderDetail from './model.order.detail'
import ModelOrderPrintout from './modal.order.printout'

import Tooltipitem from './TooltipItem'
import PoperItem from './popoveritem'
import ReactToPrint from 'react-to-print';
import history from '../../../history';
import Modal from 'react-responsive-modal';
import { ExportToExcel } from './exporttoexcel'
import OrderDetail from './model.order.detail'

import * as XLSX from 'xlsx';
import Loading from '../../common/loading';

export class OrderList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: 100,//this.props.data.orders.totalPages,
            loading: false,
            tblPage: 0,
            tblFilter: [],
            tblPageSize: 10,
            checkedValues: [],
            myData: [],//this.props.data.orders.orders,
            isOpen: false,
            monthSelected: 0,
            dateSelected: 0,
            selectedDate: Date(),
            selectedStatus: 0,
            orderSelected: {},
            showDetail: false,
            popoverOpen: false,
            modalOpen: false,
            popoverprintOpen: false,
            indexSelectedOrder: 0,
            modalOpenPrint: false,
            selectedstate: undefined,
            selectedBranch: this.props.selectedBranch,
            checkAll: false,
            resiResult: [],
            openResiModal: false,
            display_name: "",
            orderId: undefined,
            grandTotal: undefined,
            expedisi: undefined,
            modalOpenInputResi: false,
            premiumOrder:this.props.premiumOrder,


        }
        this.fetchData = this.fetchData.bind(this);
        this.changeOrderStatus = this.changeOrderStatus.bind(this)

    }
    toggle = () => this.setState({ popoverOpen: !this.state.popoverOpen });
    toggleprint = () => this.setState({ popoverprintOpen: !this.state.popoverprintOpen });

    async fetchData(state, instance) {
        //  console.log(state.pageSize)
        //  console.log(state.page)

        //  console.log(this.state.tblPage)
        //  console.log(this.state.tblPageSize)
        //  if(state.page ==0 && this.props.data) return
        //  state.page=1
        //    if(this.props.state)state=this.props.state


        let id;
        let date;
        let balik;
        let displayname;
        let grandTotal
        let statusText
        let layanan_kurir



        if (state) state.forEach(element => {
            if (element.id == 'order_status') statusText = element.value
            if (element.id == 'id') id = element.value
            if (element.id == 'dokul_user.display_name') displayname = element.value
            if (element.id == 'grand_total') grandTotal = element.value
            if (element.id == 'layanan_kurir') layanan_kurir = element.value

            if (element.id == 'created_date') {
                var d = moment(element.value)
                var od = moment(this.state.selectedDate)
                if (d.date() == od.date())
                    balik = true;
                date = element.value

            }

        })
        if (displayname && displayname.length < 3) return
        if (grandTotal && grandTotal.length < 2) return
        if (id && id.length < 2) return
        if (layanan_kurir && layanan_kurir.length < 2) return
        if (balik) return
        // this.setState({loading:true})
        const t = await this.props.getNextData(this.state.tblPageSize, this.state.tblPage, id, date, displayname, grandTotal, statusText, layanan_kurir,this.state.premiumOrder)
        // this.setState({loading:false})
        if (!t) return
        // this.props.setgridSetting(state)
        this.setState({
            myData: t.data.orders.orders,
            pages: t.data.orders.totalPages,
            selectedDate: date,
            selectedstate: state
        })

        // console.log(t)
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        //this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        //   requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
        //     res => {
        //       // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        //       this.setState({
        //         // data: res.rows,
        //         // pages: res.pages,
        //         loading: false
        //       });
        //     }
        //   );
    }
    componentDidMount = () => {
        this.props.getNextData(10, 0)
    }
    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
    async getOrdersByLike(url = '', data = {}) {
        // Default options are marked with *
        // setLoading(true)

        console.log(data)

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/order/sysncResi`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        // setLoading(false)
        // setloadingupdate(false)
        // let result = await response.json()
        if (response.ok)
            return response.json(); // parses JSON response into native JavaScript objects
        else
            return false
    }
    async submitResi(data, kurir, branch) {
        let result = []
        if (kurir == "jne")
            await this.asyncForEach(data, async (num) => {
                const resOrder = await this.getOrdersByLike("", { kodeUrut:num.nama.split(".")[0],branch: branch, resi: num.resi, kurir: kurir, kondisi: num.nama.split(".")[0], penerima: num.penerima,dateFrom:moment().subtract(1,'months').format('YYYY-MM-DD HH:mm:ss'),dateTo:moment().format('YYYY-MM-DD HH:mm:ss') })
                // console.log(resOrder)
                result.push({
                    resi: num,
                    orders: resOrder,
                    kurir: kurir,
                    branch: branch
                })

            })
        if (kurir == "sicepat")
            await this.asyncForEach(data, async (num) => {
                const resOrder = await this.getOrdersByLike("", { kodeUrut:num.nama.split(".")[0],branch: branch, kurir: kurir, kondisi: [num.kota, num.nama] })
                // console.log(resOrder)
                result.push({
                    resi: num,
                    orders: resOrder,
                    kurir: kurir,
                    branch: branch
                })

            })
        if (kurir == "j&t")
            await this.asyncForEach(data, async (num) => {
                const resOrder = await this.getOrdersByLike("", { branch: branch, resi: num.resi, kurir: kurir, kondisi:num.nama ,dateFrom:moment().subtract(1,'months').format('YYYY-MM-DD HH:mm:ss'),dateTo:moment().format('YYYY-MM-DD HH:mm:ss')}, num.phone)//,num.jalan,num.phone.substring(num.phone.length-4)
                // console.log(resOrder)
                result.push({
                    resi: num,
                    orders: resOrder,
                    kurir: kurir,
                    branch: branch
                })

            })
        this.setState({ loading: false })
        this.setState({ resiResult: result }, () => this.setState({ openResiModal: true }))

    }
    handleUpload = (e, kurir, branch) => {
        
        e.preventDefault();
        this.setState({ loading: true })
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        const submit = (data, kur, branch) => {
            
            this.submitResi(data, kur, branch)
        }
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            console.log(dataParse)
            // setFileUploaded(dataParse);
            // const dtaMap = dataParse.map(x => {
            //     return {
            //         email: x['0'],
            //         role_id: x['1']
            //     }
            // })
            // setlistDataUpload(dtaMap)
            // setModalOpen(true)
            // console.log(dataParse);
            let dataSearch = []
            if (kurir == 1)
                dataParse.forEach((x, idx) => {
                    if (idx != 0) {
                        if (x[6]) {
                            dataSearch = [...dataSearch, { kota: x[6], nama: x[8], resi: x[3], penerima: x[12] }]
                        }
                    }

                })
            if (kurir == 2)
                dataParse.forEach((x, idx) => {

                    if (idx != 0) {
                        if (x[4]) {
                            let urut = x[4].split(".")
                            // if (alamat.length >= 3)
                                dataSearch = [...dataSearch, { prop: "", phone: "", kota: "", jalan: "", nama: urut[0], resi: x[0] }]
                        }

                        // if (x[6]) {
                        //     let alamat = x[6].split(",")
                        //     if (alamat.length >= 3)
                        //         dataSearch = [...dataSearch, { prop: alamat[0], phone: x[5], kota: alamat[1], jalan: alamat[2].substring(1, 20), nama: x[4], resi: x[0] }]
                        // }
                    }

                })
            if (kurir == 3)
                dataParse.forEach((x, idx) => {
                    if (idx != 0) {
                        if (x[6]) {
                            dataSearch = [...dataSearch, { kota: x[6], nama: x[12], resi: x[3] }]
                        }
                    }

                })
            submit(dataSearch, kurir == 1 ? 'jne' : kurir == 2 ? 'j&t' : 'sicepat', branch)
            //  submitResi(dataSearch,kurir==1?'J&T':kurir==2?'jne':'sicepat')

            // console.log(dtaMap);
            // postData(`${process.env.REACT_APP_API_HOST}/api/users/sync/role`, dtaMap)
        };
        reader.readAsBinaryString(f)
    }


    componentDidUpdate(prevProps, prevState) {


        if (this.props.selectedBranch != this.state.selectedBranch) {
            // console.log("ada update branch")
            this.setState({ selectedBranch: this.props.selectedBranch, tblPage: 0 }, () => this.fetchData(this.state.selectedstate))
        }
        // console.log(this.state.selectedBranch)
        // console.log(this.props.selectedBranch)
        // console.log(prevProps.selectedBranch)
        // console.log(prevProps)
        // console.log(prevState)

        // this.setState({
        //     myData: this.props.data.orders.orders,
        // })
    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }
    selectRowAll = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: [],
                checkAll: false
            });
        } else {
            this.setState({
                checkedValues: this.props.data.orders.orders.map((item, i) => item.id),
                checkAll: true
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    changeOrderStatus = (id, order) => {
        //console.log("ganti status")
        // let order = { ...this.state.order }
        // order.order_status = status
        // order.order_status_text = this.state.orderStatus[status]
        this.props.updateOrder({
            variables: { id: id, type: order },
        });

        this.setState({
            modalOpenInputResi: false
        })


    }

    handleSubmitResi = async (e, resi) => {
        this.setState({ loading: true })
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/order/submitResi`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ id: e.target.value, resi: resi }) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        this.setState({ loading: false })

        if (response.ok)
            return true // parses JSON response into native JavaScript objects
        else
            return false



    }
    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    openFilter = (i) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    onOpenModal = (index) => {
        this.setState({ indexSelectedOrder: index }, () => this.setState({ modalOpen: true }))

    };

    onCloseModal = () => {
        this.setState({ modalOpen: false });
    };
    onOpenModalPrint = (index) => {
        // this.setState({indexSelectedOrder:index},()=>this.setState({ modalOpenPrint: true }))
        this.setState({ modalOpenPrint: true })

    };

    onCloseModalPrint = () => {
        this.setState({ modalOpenPrint: false });
    };
    isDoubleAmount = (values) => {
        var valueArr = values.map(function (item) { return item.grand_total });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx
        });
        return isDuplicate
    }
    render() {

        const { pageSize, myClass, multiSelectOption, pagination, updateOrder, gridSetting, setgridSetting, setselectedBranch, selectedBranch } = this.props;
        const { orderSelected, display_name, orderId, grandTotal, expedisi, tblPage, tblFilter, tblPageSize, myData, pages, loading, isOpen, selectedDate, modalOpen, indexSelectedOrder, checkedValues, modalOpenPrint, checkAll, resiResult, openResiModal, modalOpenInputResi,premiumOrder } = this.state

        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        const allBranchRole = [5, 4]
        const jktRole = [2, 11]
        const bpnRole = [3, 10]
        const columns = [];
        const apotekROle=[16,17]
        const adminPremiumRole=[18]
        const packerJkt=[19]
        const packerBpn=[15]
        const orderStatusArray = [
            // { id: 1, status: 1, text: "Order Diterima" },
            { id: 1, status: 1, text: "Menunggu pembayaran" },
            { id: 99, status: 12, text: "Verifikasi struk pembayaran" },
            { id: 2, status: 2, text: "Pembayaran terverifikasi" },
            { id: 3, status: 31, text: "Packing untuk expedisi" },
            { id: 3, status: 32, text: "Packing untuk driver" },
            { id: 99, status: 311, text: "Sedang rekap" },
            // { id: 2, status: 321, text: "Packing untuk driver sudah print" },
            // { id: 2, status: 33, text: "Dalam pengiriman" },
            { id: 2, status: 21, text: "Menunggu stock beberapa item" },
            { id: 99, status: 22, text: "Menunggu stock " },
            { id: 99, status: 61, text: "Sedang diracik apotek" },
            { id: 4, status: 41, text: "Batal beberapa item" },
            { id: 4, status: 42, text: "Batal oleh admin" },
            { id: 99, status: 43, text: "Batal oleh system" },
            { id: 2, status: 5, text: "Sudah dikirim" },
            { id: 2, status: 51, text: "Selesai sudah review" }

        ]


        columns.push(
            {
                headerClassName: '.hh',
                Header: <span>
                    <Button
                        className="p-1 btn-sm m-0"
                        color="secondary"
                        id={"Popover-"}
                        type="button"
                    >
                        Bulk<br />Action
                    </Button>
                    <Popover
                        placement={"top"}
                        isOpen={this.state.popoverOpen}
                        target={"Popover-"}
                        toggle={this.toggle}
                    >
                        <PopoverHeader >
                            <span>Bulk action</span> <button className="btn btn-xs float-end" onClick={this.toggle}><i className="fa fa-close" /></button></PopoverHeader>
                        <PopoverBody>
                            <div className="d-flex flex-column bd-highlight">
                                {this.props.data && this.isDoubleAmount(this.props.data.orders.orders.filter(ord => checkedValues.includes(ord.id) && ord.unix_payment > 0)) && <Alert color='warning'>Terdapat nominal yang identik, beresiko pembayaran tidak valid</Alert>}
                                <div className="p-1 bd-highlight">
                                    {this.props.data && <ExportToExcel tipe={1} apiData={this.props.data.orders.orders.filter(ord => checkedValues.includes(ord.id))} fileName={"REKAP_ORDER"} />}
                                    {/* {[
                                        {
                                            placement: "left",
                                            text: "Packing",
                                            icon: "icofont icofont-box",
                                            warna: "rgb(0, 128, 255)",
                                            status: 31

                                        },
                                        {
                                            placement: "top",
                                            text: "Packing bike delivery",
                                            icon: "icofont icofont-motor-bike",
                                            warna: "rgb(0, 1, 10)",
                                            status: 32
                                        },
                                        {
                                            placement: "top",
                                            text: "Sudah kirim",
                                            icon: "icofont icofont-fast-delivery",
                                            warna: "rgb(102, 255, 178)",
                                            status: 33
                                        },

                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`bulk-action-${i}`} icon={"icofont icofont-adjust "} aksi={() => {
                                            let selectedsOrder = this.props.data.orders.orders.filter(ord => checkedValues.includes(ord.id))
                                            const updatesOrder = selectedsOrder.map(so => {
                                                let _so = { ...so }
                                                _so.order_status = tooltip.status
                                                _so.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                return _so
                                            })

                                            // console.log(updatesOrder)
                                            let du = {
                                                listId: checkedValues,
                                                order_status: tooltip.status,
                                                order_status_text: orderStatusArray.find(st => st.status == tooltip.status).text
                                            }
                                            this.props.updateOrderBulk({
                                                variables: { id: i, type: du },
                                            });
                                            this.setState({
                                                checkedValues: []
                                            }, () => this.toggle())

                                        }} />;
                                    })} */}
                                </div>
                                <div className="p-2 bd-highlight">
                                    {[

                                        {
                                            placement: "left",
                                            text: "Pembayaran Diterima",
                                            icon: "icofont icofont-thumbs-up",
                                            warna: "rgb(40, 167, 69)",
                                            status: 2
                                        },
                                        {
                                            placement: "left",
                                            text: "Sedang Rekap",
                                            icon: "icofont icofont-print",
                                            warna: "rgb(177, 230, 84)",
                                            status: 311
                                        },
                                        {
                                            placement: "left",
                                            text: `Packing  ${false ? 'pengambilan diklinik' : `Expedisi `}`,
                                            icon: `${"icofont icofont-box"}`,
                                            warna: "rgb(0, 128, 255)",
                                            status: 31
                                        },
                                        {
                                            placement: "left",
                                            text: "Dalam Pengiriman",
                                            icon: "icofont icofont-paper-plane",
                                            warna: "rgb(0, 128, 255)",
                                            status: 5
                                        }



                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`bulk-action-b1${i}`} icon={"icofont icofont-adjust "} aksi={() => {
                                            let selectedsOrder = this.props.data.orders.orders.filter(ord => checkedValues.includes(ord.id))
                                            const updatesOrder = selectedsOrder.map(so => {
                                                // let _so = { ...so }
                                                // _so.order_status = tooltip.status
                                                // _so.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                return {
                                                    id: so.id,
                                                    dokul_orders_items: so.dokul_orders_items,

                                                }
                                            })
                                            let du = {
                                                listId: checkedValues,
                                                listOrders: updatesOrder,
                                                order_status: tooltip.status,
                                                order_status_text: orderStatusArray.find(st => st.status == tooltip.status).text
                                            }
                                            this.props.updateOrderBulk({
                                                variables: { id: i, type: du },
                                            });
                                            this.setState({
                                                checkedValues: []
                                            }, () => this.toggle())

                                        }} />;
                                        // return <Tooltipitem key={i} item={tooltip} id={`${row.original.id}-${i}`} icon={"icofont icofont-adjust "} aksi={
                                        //     (e) => {
                                        //         let order = { ...row.original }
                                        //         order.order_status = tooltip.status
                                        //         order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                        //         //ganti status item yang selain cancel menjadi 0 agar ikut status order
                                        //         let _orderItems = order.dokul_orders_items.filter(oif => oif.status != 2).map(oi => {
                                        //             let _oi = { ...oi }
                                        //             _oi.status = 0
                                        //             return _oi
                                        //         })
                                        //         order.dokul_orders_items = _orderItems;

                                        //         this.changeOrderStatus(row.original.id, order)
                                        //     }
                                        // } />;
                                    })}
                                </div>

                                <div className="p-2 bd-highlight mt-2 mb-2">

                                    {[
                                        {
                                            placement: "top",
                                            text: "Menunggu semua stock barang",
                                            icon: "icofont icofont-time",
                                            warna: "rgb(204, 102, 0)",
                                            status: 22

                                        },

                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`bulk-action-b2${i}`} icon={"icofont icofont-adjust "} aksi={() => {
                                            let selectedsOrder = this.props.data.orders.orders.filter(ord => checkedValues.includes(ord.id))
                                            const updatesOrder = selectedsOrder.map(so => {
                                                // let _so = { ...so }
                                                // _so.order_status = tooltip.status
                                                // _so.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                return {
                                                    id: so.id,
                                                    dokul_orders_items: so.dokul_orders_items,

                                                }
                                            })
                                            let du = {
                                                listId: checkedValues,
                                                listOrders: updatesOrder,
                                                order_status: tooltip.status,
                                                order_status_text: orderStatusArray.find(st => st.status == tooltip.status).text
                                            }
                                            this.props.updateOrderBulk({
                                                variables: { id: i, type: du },
                                            });
                                            this.setState({
                                                checkedValues: []
                                            }, () => this.toggle())

                                        }} />;
                                    })}

                                    {[

                                        {
                                            placement: "right",
                                            text: "Batalkan pesanan",
                                            icon: "icofont icofont-close-squared",
                                            warna: "rgb(255, 0, 0)",
                                            status: 42
                                        },
                                        {
                                            placement: "left",
                                            text: "Sedang diracik Apotek",
                                            icon: "icofont icofont-hour-glass",
                                            warna: "rgb(177, 230, 84))",
                                            status: 61
                                        },
                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`bulk-action-b3${i}`} icon={"icofont icofont-adjust "} aksi={() => {
                                            let selectedsOrder = this.props.data.orders.orders.filter(ord => checkedValues.includes(ord.id))
                                            const updatesOrder = selectedsOrder.map(so => {
                                                // let _so = { ...so }
                                                // _so.order_status = tooltip.status
                                                // _so.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                return {
                                                    id: so.id,
                                                    dokul_orders_items: so.dokul_orders_items,

                                                }
                                            })
                                            let du = {
                                                listId: checkedValues,
                                                listOrders: updatesOrder,
                                                order_status: tooltip.status,
                                                order_status_text: orderStatusArray.find(st => st.status == tooltip.status).text
                                            }
                                            this.props.updateOrderBulk({
                                                variables: { id: i, type: du },
                                            });
                                            this.setState({
                                                checkedValues: []
                                            }, () => this.toggle())

                                        }} />;
                                    })}
                                </div>

                            </div>

                        </PopoverBody>
                    </Popover>
                </span>,
                id: 'delete',
                accessor: str => "delete",
                sortable: false,
                style: {
                    textAlign: 'center',
                    padding: "0px"
                },
                width: 90,
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <div >
                        <span className={``}>
                            <input type="checkbox" name={"checkAll"} defaultChecked={checkAll}
                                onChange={e => this.selectRowAll(e)} />
                        </span>
                    </div>
                ),
                Cell: (row) => (
                    <div >
                        <span className="m-2">{row.index + 1 + (tblPage * tblPageSize)}</span>
                        <span className={`${row.original.branch_id == 1 ? "bg-warning" : "bg-success"}`}>
                            <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                onChange={e => this.selectRow(e, row.original.id)} />
                        </span>
                    </div>
                ),
                //accessor: key,
                // style: {
                //     textAlign: 'center'
                // }
            }
        )
        // columns.push(
        //     {
        //         Header: <b>{this.Capitalize("Order ID")}</b>,
        //         accessor: "id",
        //         filterable: true,
        //         Cell: (data) => {
        //             return '#' + data.original.id

        //         },
        //         style: {
        //             textAlign: 'center'
        //         }
        //     });

        columns.push(
            {
                Header: <b>{this.Capitalize("ID")}</b>,
                accessor: "id",
                filterable: true,
                // Filter: ({ filter, onChange }) => {
                //     // console.log(filter)
                //     return <div class="input-group mb-3">
                //     <input type="text" class="form-control" placeholder="Order ID" aria-label="Order ID" value={ filter?.value} aria-describedby="basic-addon2" 
                //     onKeyDown={(e)=>{
                //         if(e.keyCode==13)  onChange(e.target.value)
                //     }}
                //     onChange={(e)=>{
                //         // this.setState({orderId:e.target.value})
                //     }} />
                //     {/* <span class="input-group-text" id="basic-addon2" onClick={()=>{
                //         // onChange(orderId)
                //     }}><small><i className="fa fa-search" /></small></span> */}
                //   </div>
                // },

                Cell: (data) => {
                    return <div className="text-dark text-decoration-none"
                        // to={`/sales/orders/detail/${data.original.id}`}
                        onClick={() => this.setState({ orderSelected: data.original }, (_) => this.setState({ showDetail: true }))}
                    >
                        <>

                            <p>#{data.original.branch_id == 1 ? "J" : "B"}{data.original.id}</p>
                        </>
                    </div>

                },
                style: {
                    textAlign: 'left'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Customer")}</b>,
                accessor: "dokul_user.display_name",
                filterable: true,
                width: 150,
                // Filter: ({ filter, onChange }) => {
                //     // console.log(filter)
                //     return <div class="input-group mb-3">
                //     <input type="text" class="form-control" placeholder="Customer" aria-label="Customer" value={filter?.value} aria-describedby="basic-addon2" 
                //     onKeyDown={(e)=>{
                //         if(e.keyCode==13)  onChange(e.target.value)
                //     }}
                //     onChange={(e)=>{
                //        // this.setState({display_name:e.target.value})
                //     }} />
                //     {/* <span class="input-group-text" id="basic-addon2" onClick={()=>{
                //        // onChange(display_name)
                //     }}><small><i className="fa fa-search" /></small></span> */}
                //   </div>
                // },

                Cell: (data) => {
                    return <div className="text-dark text-decoration-none"
                        // to={`/sales/orders/detail/${data.original.id}`}
                        onClick={() => this.setState({ orderSelected: data.original }, (_) => this.setState({ showDetail: true }))}
                    >
                        <>
                            <p>{data.original.dokul_user.first_name} {data.original.dokul_user.last_name}</p>
                        </>
                    </div>

                },
                style: {
                    textAlign: 'left'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Status")}</b>,
                accessor: "order_status",
                filterable: true,
                width: 180,
                Filter: ({ filter, onChange }) => (
                    <select className="form-control form-control-sm" value={filter ? filter.value : ""} onChange={event => {
                        this.setState({ selectedStatus: event.target.value })
                        onChange(event.target.value)

                    }
                    }>
                        <option selected>Semua</option>
                        {/* <option>reminder-repurcha</option>
                        <option>pesanan-sudah-dik</option>
                        <option>orderan-selesai</option>
                        <option>on-hold</option>
                        <option>cancelled-by-robo</option>
                        <option>cancelled</option>
                        <option>batal-otomatis</option>
                        <option>stokadaygkoson720</option>
                        <option>sedang-direkap</option>
                        <option>packing-oleh-bagi</option>
                        <option>pembayaran-telah-</option>
                        <option>pembayaran-diteri</option>
                        <option>batalotomatis</option>
                        <option>pesanan-sudah-di-</option>*/}

                        {orderStatusArray.map(x => <><option className="border bg-success" value={x.status} defaultChecked={this.state.selectedStatus == x.id}>{x.text}</option> {x.id == 99 && <option disabled>_________________</option>}</>)}

                    </select>
                ),
                Cell: (data) => {

                    // if (data.original.payment_status == 2) {
                    //     return <div className="text-dark text-decoration-none"
                    //         // to={`/sales/orders/detail/${data.original.id}`}
                    //         onClick={() => this.setState({ orderSelected: data.original }, (_) => this.setState({ showDetail: true }))}
                    //     ><span className={`p-2 rounded badge bg-info`}>Pembayaran Terkonfirmasi</span></div>
                    // }
                    // else 
                    // const listPreorder=this.props.dProduct.products.filter(x=>x.dokul_products_skus.some(x2=>x2.dokul_gudang_skus.some(x3=>x3.avaibility==4))).map(x=>x.id)
                    if (data.original.order_status == 12 && data.original.payment_status != 2) {
                        return <div className="text-white text-decoration-none"
                            // to={`/sales/orders/detail/${data.original.id}`}
                            onClick={() => this.setState({ orderSelected: data.original }, (_) => this.setState({ showDetail: true }))}
                        ><span className={`p-2 rounded badge bg-info text-danger border`}>Verifikasi struk pembayaran</span></div>
                    }
                    // if ((data.original.order_status == 1 || data.original.order_status == 12 || data.original.order_status == 2) && data.original.payment_status == 2) {
                    else if (data.original.order_status == 2 || (data.original.order_status == 12 && data.original.payment_status == 2)) {
                       console.log(this.props.listPreorder);
                        return <div className="text-white text-decoration-none"
                            // to={`/sales/orders/detail/${data.original.id}`}
                            onClick={() => this.setState({ orderSelected: data.original }, (_) => this.setState({ showDetail: true }))}
                        >
                            <span className={`p-2 rounded badge bg-info`}>Pembayaran terverifikasi</span>
                            {data.original.dokul_orders_items.some(x=>this.props.listPreorder.some(x2=>x2.dokul_products_sku.dokul_product.id==x.product_id))&&<p>PREORDER ITEM </p>}
                            {/* {console.log(listPreorder)}
                            {console.log(data.original.dokul_orders_items.some(x=>listPreorder.some(x2=>x2==x.product_id)))} */}
                            </div>
                    }
                    else if (data.original.dokul_order_item_refunds.length > 0) {
                        return <div className="text-white text-decoration-none"
                            // to={`/sales/orders/detail/${data.original.id}`}
                            onClick={() => this.setState({ orderSelected: data.original }, (_) => this.setState({ showDetail: true }))}
                        ><>
                                <span className={`p-2 rounded badge order_status_${data.original.order_status}`}>{data.original.order_status_text}</span>
                                {/* <br /> */}
                                {data.original.order_status != 41 && data.original.order_status != 42 ? <span className={`p-2 rounded badge text-danger`}>beberapa item cancel</span> : ""}
                            </></div>
                    } else {
                        return <div className="text-white text-decoration-none"
                            // to={`/sales/orders/detail/${data.original.id}`}
                            onClick={() => this.setState({ orderSelected: data.original }, (_) => this.setState({ showDetail: true }))}
                        ><>
                                {data.original.order_status == 321 || data.original.order_status == 311 ? <i className="icofont icofont-print p-1" /> : ""}
                                {/* <br /> */}
                                <span className={`p-2 rounded badge order_status_${data.original.order_status} text-white`}>{data.original.order_status_text}</span>
                            </>
                         
                            </div>
                    }
                    

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("order date")}</b>,
                accessor: "created_date",
                filterable: true,
                Filter: ({ filter, onChange }) => {

                    return <Input
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                        onChange={event => {

                            onChange(event.target.value)
                        }
                        }
                        value={filter ? filter.value : ""}
                    />
                    //             <DatePicker 
                    //             selected={selectedDate}
                    // onChange={date =>this.changeDate(date)}
                    // dropdownMode="select"
                    // />
                },
                Cell: (row) => {
                    let now = moment()
                    let days = now.diff(moment(row.original.created_date), "days")
                    let hours = now.diff(moment(row.original.created_date), "hours")
                    let weeks = now.diff(moment(row.original.created_date), "weeks")
                    return <div className="text-dark text-decoration-none"
                        // to={`/sales/orders/detail/${data.original.id}`}
                        onClick={() => this.setState({ orderSelected: row.original }, (_) => this.setState({ showDetail: true }))}
                    >
                        <span>{`${days != 0 ? days + " Hari" : ""}  `} {hours - (days * 24)} Jam</span>
                    </div>
                },
                style: {
                    textAlign: 'center'
                }
            });
            user && (allBranchRole.concat(adminPremiumRole,bpnRole,jktRole).some(x => user.dokul_user_roles.some(y => y.role_id == x)) ) && !packerBpn.concat(packerJkt).some(x => user.dokul_user_roles.some(y => y.role_id == x))&& columns.push(
            {
                Header: <b>{this.Capitalize("Total")}</b>,
                accessor: "grand_total",
                filterable: true,
                // Filter: ({ filter, onChange }) => {
                //     //console.log(filter)
                //     return <div class="input-group mb-3">
                //     <input type="text" class="form-control" placeholder="TOTAL" aria-label="grand_total" value={filter?.value} aria-describedby="basic-addon2" 
                //     onKeyDown={(e)=>{
                //         if(e.keyCode==13)  onChange(e.target.value)
                //         // else this.setState({grandTotal:e.target.value})
                //     }}
                //     onChange={(e)=>{
                //         // this.setState({grandTotal:e.target.value})
                //     }} />
                //     {/* <span class="input-group-text" id="basic-addon2" onClick={(e)=>{
                //         // e.preventDefault()
                //         //  onChange(filter?.value)
                //     }}><small><i className="fa fa-search" /></small></span> */}
                //   </div>
                // },

                Cell: (data) => {
                    return <div className="text-dark text-decoration-none"
                        onClick={() => this.setState({ orderSelected: data.original }, (_) => this.setState({ showDetail: true }))}
                    ><b className="text-dark text-decoration-none">{data.original.grand_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>
                    </div>

                },
                style: {
                    textAlign: 'right'
                }
            });
            user && (allBranchRole.concat(adminPremiumRole).some(x => user.dokul_user_roles.some(y => y.role_id == x)) || premiumOrder==0)&& !packerBpn.concat(packerJkt).some(x => user.dokul_user_roles.some(y => y.role_id == x))&&columns.push(
            {
                Header: <b>{this.Capitalize("Payment")}</b>,
                accessor: "payment_method_text",
                Cell: (data) => {
                    if (data.original.payment_method == 0) return <Badge><strong>VOUCHER</strong></Badge>
                    const payT = this.props.config && this.props.config.configs.find(cfg => cfg.id == data.original.branch_id).dokul_paymethods.find(pcfg => pcfg.id == data.original.payment_method)
                    if (!payT) return "UNKNOWN"
                    const payType = payT.type
                    const pay = data.original.payment_method_text.toLowerCase()
                    return <Link
                        to={`/sales/orders/detail/${data.original.id}`}
                    ><>
                            {payType < 30 &&
                                <span className={`row p-2  mb-2 rounded badge badge-${pay.includes('bca') ? 'info' : pay.includes('mandiri') ? 'warning' : pay.includes('qris') ? 'primary' : 'secondary rounded'}`}>{`${pay.includes('qris') ? "QR BCA" : pay.includes('mandiri') ? 'MANDIRI' : pay.includes('bca') ? 'BCA' : pay.toUpperCase()}`}</span>
                            }
                            {payType > 30 && payType < 40 && <Badge><strong>CREDIT CARD</strong></Badge>}
                            {payType === 41 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/ovo-logo.svg`} className='img-fluid  w-50' />}
                            {payType === 42 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/shopeepay-logo.svg`} className='img-fluid  w-50' />}
                            {payType === 43 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/dana-logo.svg`} className='img-fluid  w-50' />}

                            {payType === 51 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bca-logo.svg`} className='img-fluid  w-50' />}
                            {payType === 52 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/mandiri-logo.svg`} className='img-fluid w-50' /></>}
                            {payType === 53 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bri-logo.svg`} className='img-fluid  w-50' />}
                            {payType === 54 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bni-logo.svg`} className='img-fluid  w-50' />}
                            {payType === 55 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/permata-logo.svg`} className='img-fluid  w-50' />}
                            {payType === 56 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bsi-logo.svg`} className='img-fluid  w-50' />}
                            {payType === 61 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/qris-logo.svg`} className='img-fluid  w-50' />}
                            <br />
                            {/* {this.props.config&&this.props.config.find(cfg=>cfg.id==data.original.branch_id).dokul_paymethods.find(pcfg=>pcfg.id==data.original.payment_method).type} */}
                            {/* <span className={`text-center`}>TERKONFIRMASI</span> */}
                        </>
                    </Link>
                },
                style: {
                    textAlign: 'center'
                }
            });


        columns.push(
            {
                Header: <b>{this.Capitalize("Expedisi")}</b>,
                accessor: "layanan_kurir",
                filterable: true,
                // Filter: ({ filter, onChange }) => {
                //     console.log(filter)
                //     return <div class="input-group mb-3">
                //     <input type="text" class="form-control" name="layanan_kurir" placeholder="Kurir" value={expedisi} aria-label="Kurir" aria-describedby="basic-addon2" 
                //     onKeyPress={(e)=>{
                //         if(e.keyCode==13 || e.which === 13)  onChange(e.target.value)
                //         // else this.setState({expedisi:e.target.value})
                //     }}
                //     onChange={(e)=>{
                //        // this.setState({expedisi:e.target.value})
                //     }} />
                //     {/* <span class="input-group-text" id="basic-addon2" onClick={()=>{
                //        // onChange(expedisi)
                //     }}><small><i className="fa fa-search" /></small></span> */}
                //   </div>
                // },
                Cell: (data) => {
                    const pay = data.original.layanan_kurir.split(" ")[0].toLowerCase()
                    return <Link className="text-dark text-decoration-none"
                        to={`/sales/orders/detail/${data.original.id}`}
                    >
                        <>
                            <span className={`w-100 p-2 text-dark text-center`}>{pay}</span><br />
                            {data.original.nomor_resi != "" && <span className={`text-center`}>{data.original.nomor_resi}</span>}
                        </>
                    </Link>


                },
                style: {
                    textAlign: 'center'
                }
            });



        if (multiSelectOption == true) {
            premiumOrder==0&&columns.push(
                {
                    Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                        onClick={
                            (e) => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    this.handleRemoveRow()
                            }}>Delete</button>,
                    id: 'delete',
                    accessor: str => "delete",
                    sortable: false,
                    style: {
                        textAlign: 'center'
                    },
                    Cell: (row) => (
                        <div>
                            <span >
                                <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                    onChange={e => this.selectRow(e, row.original.id)} />
                            </span>
                        </div>
                    ),
                    //accessor: key,
                    style: {
                        textAlign: 'center'
                    }
                }
            )
        } else {

            user && (allBranchRole.concat(adminPremiumRole).some(x => user.dokul_user_roles.some(y => y.role_id == x)) || premiumOrder==0)&&columns.push(
                {
                    Header: <b>Action</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    width: 200,
                    Cell: (row) => {
                        var kurir = row.original.layanan_kurir.toLowerCase()
                        //console.log(row)
                        if (row.original.order_status != 5) {
                            return <div className="d-flex flex-column bd-highlight">
                                {/* <span className="border p-2 rounded m-1"  >
                                    <Link
                                        to={`/sales/orders/detail/${row.original.id}`}
                                    >
                                        <i className="icofont icofont-box" style={{ fontSize: 20, padding: 0, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                                    </Link>
                                </span> */}



                                {row.original.order_status != 421 ? <div className="p-2 bd-highlight">
                                    {[

                                        {
                                            placement: "left",
                                            text: "Pembayaran Diterima",
                                            icon: "icofont icofont-thumbs-up",
                                            warna: "rgb(40, 167, 69)",
                                            status: 2
                                        },
                                        {
                                            placement: "left",
                                            text: `Packing  ${kurir == '0' ? 'pengambilan diklinik' : `Expedisi ${kurir}`}`,
                                            icon: `${kurir.includes('jne') ? "iconjne" : kurir.includes('pos') ? "iconpos" : kurir.includes('j&t') ? "iconjnt" : kurir.includes('sicepat') ? "iconsicepat" : "icofont icofont-box"}`,
                                            warna: "rgb(0, 128, 255)",
                                            status: 31
                                        },
                                        {
                                            placement: "left",
                                            text: "Sedang Rekap",
                                            icon: "icofont icofont-print",
                                            warna: "rgb(177, 230, 84)",
                                            status: 311
                                        },
                                        {
                                            placement: "left",
                                            text: "Dalam Pengiriman",
                                            icon: "icofont icofont-paper-plane",
                                            warna: "rgb(0, 128, 255)",
                                            status: 5
                                        },

                                        // {
                                        //     placement: "left",
                                        //     text: "JNE",
                                        //     icon: "iconpos",
                                        //     warna: "rgb(0, 128, 255)",
                                        //     status:31
                                        // },
                                        // {
                                        //     placement: "left",
                                        //     text: "JNE",
                                        //     icon: "iconjnt",
                                        //     warna: "rgb(0, 128, 255)",
                                        //     status:31
                                        // },
                                        // {
                                        //     placement: "left",
                                        //     text: "JNE",
                                        //     icon: "iconsicepat",
                                        //     warna: "rgb(0, 128, 255)",
                                        //     status:31
                                        // },
                                        // {
                                        //     placement: "top",
                                        //     text: "Packing bike delivery",
                                        //     icon: "icofont icofont-motor-bike",
                                        //     warna: "rgb(0, 1, 10)",
                                        //     status:32
                                        // },
                                        // {
                                        //     placement: "top",
                                        //     text: "Sudah kirim",
                                        //     icon: "icofont icofont-fast-delivery",
                                        //     warna: "rgb(102, 255, 178)",
                                        //     status:33
                                        // },

                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`${row.original.id}-${i}`} icon={"icofont icofont-adjust "} aksi={
                                            (e) => {
                                                let order = { ...row.original }
                                                order.order_status = tooltip.status
                                                order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                // ganti status item yang selain cancel menjadi 0 agar ikut status order
                                                // let _orderItems = order.dokul_orders_items.filter(oif => oif.status != 2).map(oi => {
                                                //     let _oi = { ...oi }
                                                //     _oi.status = 0
                                                //     return _oi
                                                // })

                                                let _orderItems = order.dokul_orders_items.map(oi => {
                                                    let _oi = { ...oi }
                                                    if (oi.status != 2) _oi.status = 0
                                                    return _oi
                                                })
                                                order.dokul_orders_items = _orderItems;

                                                this.changeOrderStatus(row.original.id, order)
                                            }
                                        } />;
                                    })}
                                </div> : ""}
                                {row.original.order_status != 421 ? <div className="p-2 bd-highlight mt-2 mb-2">
                                    {[
                                        {
                                            placement: "top",
                                            text: "Menunggu beberapa stock barang",
                                            title: "Pilih item",
                                            icon: "icofont icofont-time",
                                            warna: "rgb(255, 102, 102)",
                                            skus: row.original.dokul_orders_items,
                                            statusDirubah: 1,
                                            status: 21
                                        },

                                    ].map((tooltip, i) => {
                                        return <PoperItem key={i} item={tooltip} id={`${row.original.id}-${i}-2`} icon={"icofont icofont-adjust "} aksi={
                                            (skus) => {
                                                let order = { ...row.original }
                                                order.dokul_orders_items = skus
                                                order.order_status = tooltip.status
                                                order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                this.changeOrderStatus(row.original.id, order)
                                            }
                                        } />;
                                    })}
                                    {[
                                        {
                                            placement: "top",
                                            text: "Menunggu semua stock barang",
                                            icon: "icofont icofont-time",
                                            warna: "rgb(204, 102, 0)",
                                            status: 22

                                        },

                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`${row.original.id}-${i}-2`} icon={"icofont icofont-adjust "} aksi={
                                            (e) => {
                                                let order = { ...row.original }
                                                let newItems = row.original.dokul_orders_items.map(it => {
                                                    let _it = { ...it }
                                                    _it.status = 1
                                                    return _it
                                                })
                                                order.dokul_orders_items = newItems
                                                order.order_status = tooltip.status
                                                order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                this.changeOrderStatus(row.original.id, order)
                                            }
                                        } />;
                                    })}
                                    {[
                                        {
                                            placement: "top",
                                            text: "Batalkan beberapa barang",
                                            title: "Pilih item",
                                            icon: "icofont icofont-exclamation-square",
                                            warna: "rgb(255, 102, 102)",
                                            skus: row.original.dokul_orders_items,
                                            statusDirubah: 2,
                                            status: 41
                                        },

                                    ].map((tooltip, i) => {
                                        return <PoperItem key={i} item={tooltip} id={`${row.original.id}-${i}-3`} icon={"icofont icofont-adjust "} aksi={
                                            (skus) => {
                                                let order = { ...row.original }
                                                order.dokul_orders_items = skus
                                                order.order_status = tooltip.status
                                                order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                this.changeOrderStatus(row.original.id, order)
                                            }
                                        } />;
                                    })}
                                    {[

                                        {
                                            placement: "right",
                                            text: "Batalkan pesanan",
                                            icon: "icofont icofont-close-squared",
                                            warna: "rgb(255, 0, 0)",
                                            status: 42
                                        }
                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`${row.original.id}-${i}-3`} icon={"icofont icofont-adjust "} aksi={
                                            (e) => {
                                                let order = { ...row.original }
                                                let newItems = row.original.dokul_orders_items.map(it => {
                                                    let _it = { ...it }
                                                    _it.status = 2
                                                    return _it
                                                })
                                                order.dokul_orders_items = newItems
                                                order.order_status = tooltip.status
                                                order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                this.changeOrderStatus(row.original.id, order)
                                            }
                                        } />;
                                    })}
                                </div> : ""}

                                {row.original.order_status != 421 ? <div className="p-2 bd-highlight mt-2 mb-2">
                                    
                                    {[

                                        {
                                            placement: "bottom",
                                            text: "Sedang Diracik Apotek",
                                            icon: "icofont icofont-hour-glass",
                                            warna: "rgb(177, 230, 84)",
                                            status: 61
                                        }
                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`${row.original.id}-${i}-61`} icon={"icofont icofont-adjust "} aksi={
                                            (e) => {
                                                let order = { ...row.original }
                                                let newItems = row.original.dokul_orders_items.map(it => {
                                                    let _it = { ...it }
                                                    _it.status = 61
                                                    return _it
                                                })
                                                order.dokul_orders_items = newItems
                                                order.order_status = tooltip.status
                                                order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                this.changeOrderStatus(row.original.id, order)
                                            }
                                        } />;
                                    })}
                                </div> : ""}

                                {/* {row.original.order_status != 42 ? <div className="p-2 bd-highlight">
                                    {[
                                        {
                                            placement: "top",
                                            text: "Batalkan beberapa barang",
                                            title: "Pilih item",
                                            icon: "icofont icofont-exclamation-square",
                                            warna: "rgb(255, 102, 102)",
                                            skus: row.original.dokul_orders_items,
                                            statusDirubah: 2,
                                            status: 41
                                        },

                                    ].map((tooltip, i) => {
                                        return <PoperItem key={i} item={tooltip} id={`${row.original.id}-${i}-3`} icon={"icofont icofont-adjust "} aksi={
                                            (skus) => {
                                                let order = { ...row.original }
                                                order.dokul_orders_items = skus
                                                order.order_status = tooltip.status
                                                order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                this.changeOrderStatus(row.original.id, order)
                                            }
                                        } />;
                                    })}

                                    {[

                                        {
                                            placement: "right",
                                            text: "Batalkan pesanan",
                                            icon: "icofont icofont-close-squared",
                                            warna: "rgb(255, 0, 0)",
                                            status: 42
                                        }
                                    ].map((tooltip, i) => {
                                        return <Tooltipitem key={i} item={tooltip} id={`${row.original.id}-${i}-3`} icon={"icofont icofont-adjust "} aksi={
                                            (e) => {
                                                let order = { ...row.original }
                                                let newItems = row.original.dokul_orders_items.map(it => {
                                                    let _it = { ...it }
                                                    _it.status = 2
                                                    return _it
                                                })
                                                order.dokul_orders_items = newItems
                                                order.order_status = tooltip.status
                                                order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                this.changeOrderStatus(row.original.id, order)
                                            }
                                        } />;
                                    })}
                                </div> : ""} */}
                                <div className="p-2 bd-highlight">
                                    {/* <span className="border p-2 rounded m-1" onClick={()=>this.onOpenModal(row.index)}><i className="icofont icofont-eye" style={{ fontSize: 20, padding: 0, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i></span>
                              */}
                                    {/* <span className="border p-2 rounded m-1 action"  >
                                        <Link className="text-dark text-decoration-none" 
                                            to={`/sales/orders/detail/${row.original.id}`}
                                        >
                                            <i className="icofont icofont-eye" style={{ fontSize: 20, padding: 0, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                                        </Link>
                                    </span> */}
                                    {/* {row.original.order_status != 42 ? <>
                                        {[

                                            {
                                                placement: "right",
                                                text: "Pesanan selesai",
                                                icon: "icofont icofont-checked",
                                                warna: "rgb(40, 167, 69)",
                                                status: 5
                                            }
                                        ].map((tooltip, i) => {
                                            return <Tooltipitem key={i} item={tooltip} id={`${row.original.id}-${i}-4`} icon={"icofont icofont-adjust "} aksi={
                                                (e) => {
                                                    let order = { ...row.original }
                                                    order.order_status = tooltip.status
                                                    order.order_status_text = orderStatusArray.find(st => st.status == tooltip.status).text
                                                    this.changeOrderStatus(row.original.id, order)
                                                }
                                            } />;
                                        })}
                                    </> : ""} */}

                                </div>





                            </div>
                        } else {
                            return <span className="border p-2 rounded m-1 ml-3" onClick={() => this.setState({ orderSelected: row.original }, () => this.setState({ modalOpenInputResi: true }))} >
                                {/* <Link className="text-dark text-decoration-none"
                                    to={`/sales/orders/detail/${row.original.id}`}
                                >
                                    
                                </Link> */}
                                <i className="icofont icofont-edit" style={{ fontSize: 20, padding: 0, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i> Edit resi
                            </span>

                        }
                    },
                    style: {
                        textAlign: 'left'
                    },
                    sortable: false
                }
            )
        }
        if (this.state.showDetail) return <OrderDetail dProduct={this.props.dProduct} dPrivateVoucher={this.props.dPrivateVoucher} order={this.state.orderSelected} updateOrder={updateOrder} config={this.props.config} setviewDetailOrder={() => this.setState({ showDetail: false })} />
        if (loading) return <Loading  />
        return (
            <Fragment>
                <div className="container-fluid">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="">
                                    {/* <section className="w-100 border p-2">  
                                        <Form> 
                                            <Row>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="csname">
                                                            Nama pelanggan
                                                        </Label>
                                                        <Input
                                                            id="csname"
                                                            name="csname"
                                                            placeholder="Nama pelanggan"
                                                            type="text"
                                                        />
                                                    </FormGroup>

                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="receiptname">
                                                            Nama penerima
                                                        </Label>
                                                        <Input
                                                            id="receiptname"
                                                            name="receiptname"
                                                            placeholder="Nama penerima"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="receiptname">
                                                            Tanggal pemesanan
                                                        </Label>
                                                        <Input
                                                            id="receiptname"
                                                            name="receiptname"
                                                            placeholder="Nama penerima"
                                                            type="date"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                   <Label for="csname"></Label>
                                                    <Input
                                                            id="receiptname"
                                                            name="receiptname"
                                                            placeholder="Nama penerima"
                                                            type="date"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label for="csname">
                                                            Status order
                                                        </Label>
                                                        <Col md={6}>
                                                        <FormGroup check >
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Check me out
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check >
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Check me out
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Check me out
                                                            </Label>
                                                        </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                        <FormGroup check >
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Check me out
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check >
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Check me out
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Check me out
                                                            </Label>
                                                        </FormGroup>
                                                        </Col>
                                                    </FormGroup>

                                                </Col>
                                                
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="csname">
                                                            Dokpoin
                                                        </Label>
                                                        <FormGroup check>
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Pakai
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Tidak pakai 
                                                            </Label>
                                                        </FormGroup>
                                                    </FormGroup>

                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="csname">
                                                            Voucher
                                                        </Label>
                                                        <FormGroup check>
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Pakai
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Input type="checkbox" />
                                                            <Label check>
                                                                Tidak pakai 
                                                            </Label>
                                                        </FormGroup>
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                            


                                        </Form>
                                    </section> */}
                                </div>
                                {/* <div className="container">
                                    <Button color="primary" onClick={this.openFilter} style={{ marginBottom: '1rem' }}><i className='fa fa-filter'></i></Button>
                                    <Collapse isOpen={isOpen}>
                                        <Card>
                                            <CardBody>
                                                Anim pariatur cliche reprehenderit,
                                                enim eiusmod high life accusamus terry richardson ad squid. Nihil
                                                anim keffiyeh helvetica, craft beer labore wes anderson cred
                                                nesciunt sapiente ea proident.
          </CardBody>
                                        </Card>
                                    </Collapse>
                                </div> */}



                                <div className="card-body order-datatable">
                                    {user && (allBranchRole.some(x => user.dokul_user_roles.some(y => y.role_id == x)) || jktRole.some(x => user.dokul_user_roles.some(y => y.role_id == x))) && <div className="d-flex justify-content-between">

                                        <div className="d-flex flex-row ">



                                        </div>
                                        <div className="d-flex flex-row ">
                                            <label htmlFor="fileUpload11" className="m-2">
                                                <Badge color="warning" className="p-2 bg-primary pointer" style={{ cursor: "pointer" }}>
                                                    Upload resi JNE
                                                    <input id="fileUpload11" type="file" multiple accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        onChange={(e) => this.handleUpload(e, 1, 1)}
                                                        style={{ cursor: "pointer", display: "none", }} />
                                                </Badge></label>
                                            <label htmlFor="fileUpload12" className="m-2">
                                                <Badge color="warning" className="p-2 bg-primary pointer" style={{ cursor: "pointer" }}>
                                                    Upload resi J&T
                                                    <input id="fileUpload12" type="file" multiple accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        onChange={(e) => this.handleUpload(e, 2, 1)}
                                                        style={{ cursor: "pointer", display: "none", }} />
                                                </Badge></label>
                                            <label htmlFor="fileUpload13" className="m-2">
                                                <Badge color="warning" className="p-2 bg-primary pointer" style={{ cursor: "pointer" }}>
                                                    Upload resi SICEPAT
                                                    <input id="fileUpload13" type="file" multiple accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        onChange={(e) => this.handleUpload(e, 3, 1)}
                                                        style={{ cursor: "pointer", display: "none", }} />
                                                </Badge></label>
                                        </div>
                                    </div>}
                                    {user && (allBranchRole.some(x => user.dokul_user_roles.some(y => y.role_id == x)) || bpnRole.some(x => user.dokul_user_roles.some(y => y.role_id == x))) && <div className="d-flex justify-content-between">

                                        <div className="d-flex flex-row ">



                                        </div>
                                        <div className="d-flex flex-row ">
                                            <label htmlFor="fileUpload" className="m-2">
                                                <Badge className="p-2 bg-primary pointer" style={{ cursor: "pointer" }}>
                                                    Upload resi JNE
                                                    <input id="fileUpload" type="file" multiple accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        onChange={(e) => this.handleUpload(e, 1, 2)}
                                                        style={{ cursor: "pointer", display: "none", }} />
                                                </Badge></label>
                                            <label htmlFor="fileUpload1" className="m-2">
                                                <Badge className="p-2 bg-primary pointer" style={{ cursor: "pointer" }}>
                                                    Upload resi J&T
                                                    <input id="fileUpload1" type="file" multiple accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        onChange={(e) => this.handleUpload(e, 2, 2)}
                                                        style={{ cursor: "pointer", display: "none", }} />
                                                </Badge></label>
                                            <label htmlFor="fileUpload2" className="m-2">
                                                <Badge className="p-2 bg-primary pointer" style={{ cursor: "pointer" }}>
                                                    Upload resi SICEPAT
                                                    <input id="fileUpload2" type="file" multiple accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        onChange={(e) => this.handleUpload(e, 3, 2)}
                                                        style={{ cursor: "pointer", display: "none", }} />
                                                </Badge></label>
                                        </div>



                                    </div>}
                                    <div className="d-flex justify-content-between">
                                        {user && user.dokul_user_roles.some(x => x.role_id == 4 || x.role_id == 5) && <div className="d-flex flex-row ">

                                            <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(1)}>JAKARTA</div>
                                            <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(2)}>BALIKPAPAN</div>

                                            <div className={`p-2  pointer ${!selectedBranch ? 'bg-primary' : ''}`} onClick={() => setselectedBranch()}>ALL</div>
                                        </div>}

                                        <div className="d-flex flex-row ">

                                            <div className={`p-2 `} >TOTAL {this.props.data?.orders.totalItems} data</div>
                                        </div>
                                        <div className="d-flex flex-row ">
                                            {this.props.data && <ExportToExcel tipe={1} apiData={this.props.data.orders.orders} fileName={"REKAP_ORDER"} />}
                                            {this.props.data && <ExportToExcel tipe={2} apiData={this.props.data.orders.orders} fileName={"SUMMARY_ORDER"} />}
                                        </div>

                                    </div>
                                    {user && (user.dokul_user_roles.some(x => x.role_id == 10 || x.role_id == 5||x.role_id == 4 || x.role_id == 3)  || selectedBranch == 2)  && <div className="d-flex flex-row mt-2">
                                    <div className={`p-2  pointer ${premiumOrder == 0 ? 'bg-primary' : ''}`} onClick={() => this.setState({premiumOrder:0},()=>this.fetchData(this.state.tblFilter))}>REGULER</div>

                                        <div className={`p-2  pointer ${premiumOrder == 'pw_' ? 'bg-primary' : ''}`} onClick={() => this.setState({premiumOrder:"pw_"},()=>this.fetchData(this.state.tblFilter))}>PREMIUM WAJAH</div>

                                        <div className={`p-2  pointer ${premiumOrder == 'pk_'? 'bg-primary' : ''}`} onClick={() => this.setState({premiumOrder:'pk_'},()=>this.fetchData(this.state.tblFilter))}>PREMIUM KULIT</div>
                                    </div>
                                    }
                                    {user && (user.dokul_user_roles.some(x =>x.role_id == 18)  || selectedBranch == 2)  && <div className="d-flex flex-row mt-2">
                               
                                        <div className={`p-2  pointer ${premiumOrder == 'pw_' ? 'bg-primary' : ''}`} onClick={() => this.setState({premiumOrder:"pw_"},()=>this.fetchData(this.state.tblFilter))}>PREMIUM WAJAH</div>

                                        <div className={`p-2  pointer ${premiumOrder == 'pk_'? 'bg-primary' : ''}`} onClick={() => this.setState({premiumOrder:'pk_'},()=>this.fetchData(this.state.tblFilter))}>PREMIUM KULIT</div>
                                    </div>
                                    }
                                    <div className="d-flex justify-content-end"><span className="float-right mb-1">
                                        {[

                                            // {
                                            //     placement: "top",
                                            //     text: "Cetak order status packing",
                                            //     icon: "icofont icofont-print",
                                            //     warna: "rgb(0, 1, 10)",
                                            //     status: 321
                                            // },

                                        ].map((tooltip, i) => {
                                            return <Tooltipitem key={i} item={tooltip} id={`bulk-action-${i}`} icon={"icofont icofont-adjust "} aksi={() => {

                                                this.onOpenModalPrint(321)
                                                // let selectedsOrder=this.props.data.orders.orders.filter(ord=>checkedValues.includes(ord.id))
                                                // const updatesOrder=selectedsOrder.map(so=>{
                                                //     let _so={...so}
                                                //     _so.order_status=tooltip.status
                                                //     _so.order_status_text = orderStatusArray.find(st=>st.status==tooltip.status).text
                                                //     return _so
                                                // })

                                                // console.log(updatesOrder)
                                                // let du={
                                                //     listId: checkedValues,
                                                //     order_status:tooltip.status,
                                                //     order_status_text:orderStatusArray.find(st=>st.status==tooltip.status).text
                                                // }
                                                // this.props.updateOrderBulk({ 
                                                //     variables: { id: i, type: du },
                                                // });
                                                // this.setState({
                                                //     checkedValues:[]
                                                // },()=>this.toggle())

                                            }} />;
                                        })}

                                        {/* <Button
                  className="p-1 btn-sm"
                  color="dark"
                  id={"Popoverprint-"}
                  type="button"
                ><i className="icofont icofont-print" style={{fontSize:20}}></i></Button>
                <Popover
                  placement={"left"}
                  isOpen={this.state.popoverprintOpen}
                  target={"Popoverprint-"}
                  toggle={this.toggleprint}
                >
                  <PopoverHeader>Cetak label</PopoverHeader>
                  <PopoverBody>
                   <div class="d-flex flex-column bd-highlight">
                   <div className="p-2 bd-highlight">
                   {[
                                    {
                                        placement: "left",
                                        text: "Packing Expedisi",
                                        icon: "icofont icofont-box",
                                        warna: "rgb(0, 128, 255)",  
                                        status:311

                                    },
                                    {
                                        placement: "top",
                                        text: "Packing bike delivery",
                                        icon: "icofont icofont-motor-bike",
                                        warna: "rgb(0, 1, 10)",
                                        status:321
                                    },

                                ].map((tooltip, i) => {
                                    return <Tooltipitem  item={tooltip} id={`bulk-action-${i}`} icon={"icofont icofont-adjust "} aksi={()=>{

                                        this.onOpenModalPrint(321)
                                        
                                        
                                    }}/>;
                                })}
                            </div>
                            
                       </div>

                  </PopoverBody>
                </Popover> */}
                                    </span>

                                        {/* <button className="btn btn-success mb-3" onClick={()=>{
                                        
                                    }}>Export order</button> */}

                                    </div>
                                    <ReactTable
                                        getTdProps={(state, rowInfo, column, instance) => {

                                            if (rowInfo && rowInfo.row) {
                                                return {
                                                    onClick: (e) => {
                                                        //console.log(e.target.className)
                                                        if (e.target.type != "checkbox" && !e.target.className.includes("icon") && !e.target.className.includes("action"))
                                                            console.log(e.target)
                                                        //   this.setState({
                                                        //     selected: rowInfo.index
                                                        //   },()=>{
                                                        //     history.push(`${process.env.PUBLIC_URL}/sales/orders/detail/${rowInfo.original.id}`);
                                                        //   })
                                                    },
                                                    style: {
                                                        //   background: rowInfo.index === this.state.selected ? '#c2b179' : 'white',
                                                        //   color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                                    }
                                                }
                                            } else {
                                                return {}
                                            }
                                        }}

                                        data={this.props.data ? this.props.data.orders.orders : []}
                                        // indexKey={10}
                                        // initialState={}
                                        columns={columns}
                                        className={"-striped -highlight"}
                                        showPagination={pagination}
                                        pageSizeOptions={[5, 10, 20, 25, 50, 100, 250, 500]}
                                        manual={true}// Forces table not to paginate or sort automatically, so we can handle it server-side
                                        pages={this.props.data ? this.props.data.orders.totalPages : pages}// Display the total number of pages
                                        loading={loading} // Display the loading overlay when we need it
                                        // onFetchData={this.fetchData}//{(s,i)=>this.setState({tblPage:s.page},(_)=>this.fetchData(s,i))} // Request new data when things change
                                        sorted={this.state.sorted}
                                        autoResetPage={true}
                                        defaultPageSize={tblPageSize}
                                        page={this.props.data ? this.props.data.orders.currentPage : tblPage}//{tblPage}
                                        showPageJump={false}
                                        defaultFiltered={tblFilter}
                                        onPageChange={(val) => this.setState({ tblPage: val }, (_) => this.fetchData(this.state.tblFilter))}
                                        onFilteredChange={(val) => this.setState({ tblFilter: val }, (_) => this.fetchData(val))}
                                        onPageSizeChange={(val) => this.setState({ tblPageSize: val }, (_) => this.fetchData(this.state.tblFilter))}
                                        loadingText="Mengambil data dari server"
                                        nextText="Selanjutnya"
                                        previousText="Sebelumnya"
                                        showPaginationTop={true}
                                        pageText="Halaman"
                                        ofText="dari"
                                        rowsText="Baris"
                                        onSortedChange={(newSort, column) => {
                                            this.setState({ sorted: newSort });
                                        }}

                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={modalOpen} onClose={this.onCloseModal} >

                    {this.props.data && <ModelOrderDetail dProduct={this.props.dProduct} dPrivateVoucher={this.props.dPrivateVoucher} order={this.props.data.orders.orders[indexSelectedOrder]} updateOrder={this.props.updateOrder} />}
                </Modal>
                <Modal open={modalOpenPrint} onClose={this.onCloseModalPrint} center >

                    {this.props.data && <ModelOrderPrintout orders={this.props.data.orders.orders} />}
                </Modal>

                <Modal open={modalOpenInputResi} onClose={() => {
                    this.setState({ modalOpenInputResi: false })
                }} center >

                    <ModalBody>
                        <div className="mb-3">
                            <label for="nomorresi" className="form-label">Nomor resi : </label>
                            <input type="text" className="form-control" id="nomorresi" value={orderSelected.nomor_resi} aria-describedby="nomorresi" onChange={(e) => this.setState({ orderSelected: { ...orderSelected, nomor_resi: e.target.value } })} />
                            <div id="nomorresi" className="form-text">{orderSelected.dokul_address?.first_name} - {orderSelected.layanan_kurir}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button value={orderSelected.id} onClick={(e) => this.changeOrderStatus(orderSelected.id, orderSelected)} className="btn btn-primary btn-xs">Submit resi</button>
                    </ModalFooter>

                </Modal>

                <Modal open={openResiModal} onClose={() => {
                    this.setState({ openResiModal: false })
                }} center >

                    <Table>
                        <thead>
                            <tr>
                                <th>#NOMOR</th>
                                <th>pencarian kurir</th>
                                <th>pencarian kata</th>
                                <th>ditemukan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resiResult.map((x, idx) =>
                                <tr key={idx} className={`${x.orders.length == 1 && x.branch == 1 ? 'bg-warning text-dark' : x.orders.length == 1 && x.branch != 1 ? 'bg-success text-dark' : 'bg-light text-dark'}`}>
                                    <th >{x.resi.resi}</th>
                                    <td>{x.kurir}</td>
                                    <td>
                                        {x.kurir == 'jne' ?
                                            <>
                                                <div>nama:{x.resi.nama}</div>
                                                <div>kota:{x.resi.kota}</div>
                                            </> : x.kurir == 'j&t' ?
                                                <>
                                                    <div>nama:{x.resi.nama.split(" ")[0]}</div>
                                                    <div>prop:{x.resi.prop}</div>
                                                    <div>kota:{x.resi.kota}</div>
                                                    <div>tlp:{x.resi.phone.substring(x.resi.phone.length - 4)}</div>
                                                    {/* <div>jln:{x.resi.jalan}</div> */}
                                                </> : <>
                                                    <div>nama:{x.resi.nama}</div>
                                                    <div>kota:{x.resi.kota}</div>
                                                </>
                                        }


                                    </td>
                                    <td>
                                        {x.orders.length == 1 && x.orders.map(xy =>
                                            <div className="d-flex ">
                                                <div><span>#{xy.id}</span></div>
                                                <div><p dangerouslySetInnerHTML={{ __html: xy.alamat_kirim.replace("/n", "<br />") }} /></div>

                                            </div>
                                        )}
                                        {x.orders.length != 1 && x.orders.map(xy =>
                                            <div className="d-flex flex-column mb-2 ">
                                                <div><span>#{xy.id}</span></div>
                                                <div><span>Tanggal order: {moment(xy.created_date).format("DD MMM YY HH:mm")}</span></div>
                                                <div><span>Tanggal selesai: {moment(xy.modify_date).format("DD MMM YY HH:mm")}</span></div>
                                                <div ><p dangerouslySetInnerHTML={{ __html: xy.alamat_kirim.replace("/n", "<br />") }} /></div>
                                                {xy.nomor_resi == "" && <div><button value={xy.id} onClick={(e) => this.handleSubmitResi(e, x.resi.resi)} className="btn btn-primary btn-xs">Submit resi</button></div>}

                                            </div>
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal>
            </Fragment>
        )
    }
}

export default OrderList
