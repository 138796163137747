import React, { Component ,Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Tabset from './point.tabset';

export class PointEdit extends Component {
    render() {
       const {data,point,savePoint}=this.props
        return (
            <Fragment>
               
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Edit point rules</h5>
                        </div>
                        <div className="card-body">
                            <Tabset point={point} data={data} savePoint={savePoint}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PointEdit
