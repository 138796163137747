import React , { Component,Fragment,useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject,NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../../apollo'
import {GET_CATEGORIES,GET_TAGS,GET_DASHBOARD_DATA,GET_ORDERS, GET_LATEST_ORDER} from '../../../constants/graph'

import Loading from '../../common/loading'
import Errorpage from '../../common/Errorpage'
const Latestorder = ({ props }) => {
    //const [data,setData]=useState(props.data)
   const { loading, data, error,fetchMore , refetch, networkStatus} = useQuery(GET_LATEST_ORDER);
   if (loading) return <Loading />;
   if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    return (
        <div className="card">
                                <div className="card-header">
                                    <h5>Latest Orders</h5>
                                   <div className="float-right"> <a href="/" className="btn btn-primary">View All Orders</a></div>
                                </div>
                                <div className="card-body">
                                    <div className="user-status table-responsive latest-order-table">
                                        <table className="table table-bordernone">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Order ID</th>
                                                    <th scope="col">Order Total</th>
                                                    <th scope="col">Payment Method</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.latestOrder.map((p,index)=>
                                                    <tr key={index}>
                                                    <td>#{p.id}</td>
                                                    <td className="digits">{p.grand_total.toLocaleString(undefined, {maximumFractionDigits:2})}</td>
                                                    <td className="font-danger">{p.payment_method_text}</td>
                                                    <td className="digits">{p.order_status_text}</td>
                                                </tr>
                                                    )}
                                                
                                                
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </div>
                            </div>
    )
}
export default withApollo(Latestorder)
//export default ordertoday
