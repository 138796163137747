import React, { useState, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import ServiceList from './service.list';
import ServiceEdit from './service.edit';
import ServiceReservasi from './service.reservasi';
import { GET_LAYANANS } from '../../constants/graph';


const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;
  


const ADD_SERVICE = gql`
    mutation updateMutation(
      $data: any
    ) {
      login(body: $data)
      @rest(
        type: "Post"
        path: "api/layanan"
        method: "POST"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
  const ADD_ROWS = gql`
    mutation updateRows(
      $data: any
    ) {
      updateRows(body: $data)
      @rest(
        type: "Post"
        path: "api/konten/rows"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
const GET_CATEGORIES = gql`query categories {
    categories @rest(type: "Category",path: "api/products/categories") {
        id,
        name,
        description
    }
  }
`;
const GET_TAGS = gql`query tags {
    tags @rest(type: "Tag",path: "api/products/tags") {
        id,
        name,
        description
    }
  }
`;
const Layanans = ({ match }) => {
  const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    //const params = useParams();

    if (match.params.page) {
        // console.log(match.params.page)
        // console.log(match.params.id)


    }
    const { loading, data, error } = useQuery(GET_LAYANANS,);
    const [selectedBranch, setselectedBranch] = useState(1)
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    if (error ) {
        console.log(error.message);
        Cookies.remove('signedin','/');
        Cookies.remove('jwt');
        


        history.push('/');
        //return <div>Error!</div>;
    }
   
    const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    let product;
    const [addService,{ loading: mutationLoading, error: mutationError,called:mutationCalled },] = useMutation(ADD_SERVICE,);
    const saveService=(service)=>{
      addService({
            variables: {
                data: service
            },
            onCompleted: (saveService ) => {
                console.log("Sukses")
                history.push('/products');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })

    }
    const [addRow,{ loading: mutationCategoryLoading, error: mutationCategoryError,called:mutationCategoryCalled },] = useMutation(ADD_ROWS,);
    const saveRow=(category)=>{
        const t=addRow({
            variables: {
                data: category
            },
            onCompleted: (data) => {
                console.log("Sukses")
                history.push('/products/categories');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })
        return t

    }

// if(match.params.page=='edit'){
//     product = client.readFragment({
//         id: `Product:${match.params.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
//         fragment: gql`
//           fragment editProduct on Product {
//             name,
//             dokul_products_skus
//             branchId,
//             dokul_tags_categories,
//             dokul_product_images
//             id
//             description
//             tags
//           }
//         `,
//     }, false);
// }
const handleImageUpload = async(file,name) => {
  //const files = event.target.files
  const formData = new FormData()
  formData.append('uploaded_file', file, `${name}_${file.name}`)


  const response= await fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
      method: 'POST',
      body: formData
  })
      .then(response => response.json())
      .then(data => {
          console.log(data)
          return data
          
      })
      .catch(error => {  
          console.error(error)
      })
// }
// response.ok;     // => false
//   response.status; // => 404
// const result=await response.json
// return result.json;
}

    //daricahce
    // const { products } = client.readQuery({
    //     query: gql`
    //       query ReadProducts {
    //         products {
    //           id
    //         }
    //       }
    //     `,
    //   },false);

    // const todo = client.readFragment({
    //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment myTodo on Product {
    //         id
    //         name
    //       }
    //     `,
    // });
    //console.log(data);
    // setData(datanya)
    
    return (
        <Fragment>
            {loading? <div>Loading</div>:<>
            <Breadcrumb title="Layanan" />
            {user&&user.role_id==5&&<div className="d-flex flex-row mb-5">
          <div className="p-2">Dokterkulitku : </div>
          <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(1)}>JAKARTA</div>
          <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(2)}>BALIKPAPAN</div>
        </div>}
            {
                match.params.page == 'list' ?
                    <ServiceList data={data} selectedBranch={selectedBranch} saveService={addService} handleImageUpload={handleImageUpload} mutationLoading={mutationLoading} mutationError={mutationError}/>
                    : match.params.page == 'reservasi' ?
                        <ServiceReservasi data={data} />
                        : match.params.page == 'edit' ?
                            <ServiceEdit data={data.layanans.find(l=>l.id==match.params.id)} id={match.params.id} saveRow={addRow}/>
                            : match.params.page == 'add' ?
                                <ServiceList data={data} saveProduct={saveService} history={history} mutationCalled={mutationCalled} mutationError={mutationError}/>
                                :match.params.page == 'categories' ?
                                <ServiceList data={data} />
                                :
                                <ServiceList data={data} saveService={addService} handleImageUpload={handleImageUpload} mutationLoading={mutationLoading} mutationError={mutationError} />
            }
            </>
        }

        </Fragment>
    )

}

export default withApollo(Layanans)
