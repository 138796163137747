import React, { useState, useEffect, Fragment } from 'react';
import { GET_CATEGORIES, GET_TAGS, GET_PRODUCTS, GET_GUDANGS } from '../../constants/graph'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
// import Breadcrumb from '../../common/breadcrumb';
// import CKEditors from "react-ckeditor-component";
// import { AvField, AvForm, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvInput } from 'availity-reactstrap-validation';
// import one from '../../../assets/images/pro3/1.jpg'
// import user from '../../../assets/images/user.png';
// import { Edit, Trash2, Plus } from 'react-feather'
import { Alert, Badge, FormGroup, Label, Input, CustomInput, ListGroupItem, ListGroup, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import history from '../../history';
import { useQuery, useMutation, readQuery, useApolloClient } from '@apollo/react-hooks';
// import CategorySelect from '../category/category.select'
// import ProdukDetail from '../layout/produk-detail';
// import ProdukVariant from '../layout/produk-variant';
import Loading from '../common/loading'
//import { useApolloClient, NormalizedCacheObject, QUERY } from '@apollo/client';
import ServicPage from './layout/service-page';
import ServiceDetail from './layout/service-detail';
import gql from 'graphql-tag';
const UPDATE_TODO = gql`
  mutation UpdateTodo($id: String!, $type: String!) {
    updateTodo(id: $id, type: $type) {
      id
      type
    }
  }
`;
const Hello = () => {
    const { loading, error, data } = useQuery(GET_CATEGORIES,);
    if (loading) return <p>Loading ...</p>;
    return data;
}
const EditService = (props) => {
    const [isLoading, setisLoading] = useState(false)
    // useQuery(GET_GUDANGS,)
    //console.log(props)
    // const [content, setBg] = useState("white")
    // const setBg = () => {
    //     const randomColor = Math.floor(Math.random()*16777215).toString(16);
    //   }
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    // const imgResource = 'http://localhost:81'//process.env.PUBLIC_URL
    const [activeImg, setActiveImg] = useState(0)
    //const [categories, setCategories] = useState([])
    const [popoverOpen, setPopoverOpen] = useState(false);
    const categories = []
    //const { loading2, data, error1 } = useQuery(GET_CATEGORIES,);
    //const { loading3, tags,categerror2 } = useQuery(GET_TAGS,);
    // const [, updateState] = React.useState();
    // const forceUpdate = React.useCallback(() => updateState({}), []);

    const toggle = () => setPopoverOpen(!popoverOpen);
    const [file, setFile] = useState()
    const [files, setFiles] = useState([])
    const [skuFiles, setSkuFiles] = useState([])
    const [pictures, setPicture] = useState([])

    // const [product,setProduct]=useState({})
    const [sku, setSku] = useState([])
    const [content, setContent] = useState("")
    //const client = useApolloClient();
    const [product, setProduct] = useState(props.product)

    // const product = client.readFragment({
    //     id: `Product:${props.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment editProduct on Product {
    //         name,
    //         dokul_products_skus
    //         branchId,
    //         dokul_tags_categories,
    //         dokul_product_images
    //         id
    //         description
    //         dokul_tags
    //       }
    //     `,
    // }, false);
    // const product = client.readQuery({
    //     query: gql`
    //       query readProduct{
    //         Product{name,
    //         dokul_products_skus
    //         branchId,
    //         dokul_tags_categories,
    //         dokul_product_images
    //         id
    //         description
    //         dokul_tags
    //         }
    //       }
    //     `,
    // }, false);
    //const [updateTodo] = useMutation(UPDATE_TODO);
    // const [prod, setPprod] = useState(product)
    //var prod = product;
    //const client = useApolloClient();
    // const getGudang = (id) => {
    //     return client.readFragment({
    //         id: `Gudang:${id}`, // `id` is any id that could be returned by `dataIdFromObject`.
    //         fragment: gql`
    //           fragment getGudang on Gudang {
    //             id,
    //             nama
    //           }
    //         `,
    //     }, false);
    // }

    useEffect(() => {
        // This effect uses the `value` variable,
        // so it "depends on" `value`.
        console.log("useefect")
        if (product) {
            setPicture(product.dokul_product_images)
            setContent(product.description)
            setSku(product.dokul_products_skus)

        }
        //console.log(prod)
    }, [product])
    const UPDATE_MUTATION = gql`
    mutation updateMutation(
      $data: any
      $id:ID!
    ) {
      login(id:$id,body: $data)
      @rest(
        type: "Post"
        path: "api/products/{args.id}"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
    // this.setState({ email: values.email });
    const [updateProduct] = useMutation(UPDATE_MUTATION);
    // const { categories } = readQuery({
    //     query: gql`
    //       query readCategories {
    //         categories{
    //           id,name,description
    //         }
    //       }
    //     `
    //   });

    //     if(!product) return <div />
    console.log(product)


    const handleValidSubmit = (event, values) => {
        //console.log(prod.id)
        //delete prod.__typename;
        // prod.name="GANTI NAMA"
        let _product = { ...product }
        _product.dokul_products_skus = [...sku]
        //let _skus = [...sku];

        const formData = new FormData()
        files.forEach((f, i) => {

            formData.append('uploaded_file', f.file, `prod_${f.idx}_${f.file.name}`)
        })
        skuFiles.forEach((f, i) => {

            formData.append('uploaded_file', f, `sku_${i}_${f.name}`)
        })




        fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                data.d.forEach(ud => {
                    const isProd = ud.originalname.split("_")
                    // let _sku = {..._skus[isProd[1]]};
                    if (isProd[0] == 'prod') {
                        let listImg = [..._product.dokul_product_images]
                        let imgProd = { ...listImg[isProd[1]] }
                        imgProd.image_url = `/assets/images/uploads/${ud.filename}`
                        listImg[isProd[1]] = imgProd
                        _product.dokul_product_images = listImg;
                        //_product.dokul_product_images[isProd[1]]=imgProd//.image_url=`/assets/images/uploads/${ud.filename}`
                    }
                    if (isProd[0] == 'sku') {
                        let listSku = [..._product.dokul_products_skus]
                        let imgSku = { ...listSku[isProd[1]] }
                        imgSku.image_url = `/assets/images/uploads/${ud.filename}`
                        listSku[[isProd[1]]] = imgSku
                        _product.dokul_products_skus = listSku//.image_url=`/assets/images/uploads/${ud.filename}`
                        //_sku.image_url=`/assets/images/uploads/${ud.filename}`
                    }
                    //_skus[isProd[1]] = _sku;



                })
                //setSku(_skus)
                updateProduct({
                    variables: {
                        data: _product,
                        id: _product.id
                    },
                    onCompleted: ({ login }) => {
                        //localStorage.setItem("AUTH_TOKEN", login.token);
                        console.log(login)
                        //document.cookie = 'token=' + login.token
                        // document.cookie = 'signedin=true'
                        // isLoggedInVar(true);

                    }, onError: ({ graphQLErrors, networkError }) => {
                        console.log(networkError)

                        console.log(graphQLErrors)
                        //  localStorage.setItem("AUTH_TOKEN", login.token);
                        //history.push('/');
                    },
                }).then(data => {
                    history.push('/products/list');
                })
            })
            .catch(error => {
                console.error(error)
            })


        //_product.dokul_products_skus=sku



    }

    const handleInvalidSubmit = (event, errors, values) => {
        //this.setState({ email: values.email, error: true });
    }

    //image upload
    const _handleSubmit = (e) => {
        e.preventDefault();
    }

    const _handleImgChange = (e, i) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        var oldpictures = [...pictures]
        var oldpic = { ...pictures[i] }
        var oldfiles = [...files]
        // const { dummyimgs } = state;
        oldfiles[i] = { idx: i, file: file };
        //console.log(file);
        reader.onloadend = () => {
            oldpic.image_url = reader.result;
            oldpictures[i] = oldpic;
            setFile(file)
            setPicture(oldpictures)
            setFiles(oldfiles)

            //product.pictures.splice(1,1)
            // product.pictures[0]=reader.result;
            //updateTodo({ variables: { id, type: "z" } });
            //    client.cache.modify({
            //     id: client.cache.identify(product),
            //     fields: {
            //       name(cachedName) {
            //         return cachedName.toUpperCase();
            //       },
            //     },
            //     /* broadcast: false // Include this to prevent automatic query refresh */
            //   });
            // setState({
            //     file: file,
            //     dummyimgs,
            // });
            console.log(reader)
        }
        reader.readAsDataURL(file)
    }
    const _handleImgAdd = (e, i) => {
        e.preventDefault();
        let _product = { ...product }
        let reader = new FileReader();
        let file = e.target.files[0];
        let _pictures = [...pictures]
        //const jmlPic = pictures.length;
        const newPic = { image_url: "", product_id: product.id }

        files.push({ idx: i, file: file });
        reader.onloadend = () => {
            newPic.image_url = reader.result;
            _pictures.push(newPic)
            _product.dokul_product_images = _pictures
            setFile(file)
            //setPicture(_pictures)
            setProduct(_product)
            setFiles(files)

            //product.pictures.splice(1,1)
            // product.pictures[0]=reader.result;
            //updateTodo({ variables: { id, type: "z" } });
            //    client.cache.modify({
            //     id: client.cache.identify(product),
            //     fields: {
            //       name(cachedName) {
            //         return cachedName.toUpperCase();
            //       },
            //     },
            //     /* broadcast: false // Include this to prevent automatic query refresh */
            //   });
            // setState({
            //     file: file,
            //     dummyimgs,
            // });

        }
        reader.readAsDataURL(file)
    }

    const _handleSkuImgChange = (e, i) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        skuFiles[i] = file;
        reader.onloadend = () => {
            // var posisi=this.state.posisi


            let _skus = [...sku];
            // 2. Make a shallow copy of the item you want to mutate
            let _sku = { ..._skus[i] };
            // 3. Replace the property you're intested in
            _sku.image_url = reader.result;
            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
            _skus[i] = _sku;
            //  this.state.posisi=pitems

            // sku[i].image_url = reader.result;
            setFile(file)
            setSkuFiles(skuFiles)

            setSku(_skus)
            // setState({
            //     file: file,
            //     sku,
            // });
            //console.log(reader)
        }
        reader.readAsDataURL(file)
    }

    const handleImageUpload = event => {
        //const files = event.target.files
        let _skus = [...sku];

        const formData = new FormData()
        files.forEach((f, i) => {

            formData.append('uploaded_file', f.file, `prod_${f.idx}_${f.file.name}`)
        })
        skuFiles.forEach((f, i) => {

            formData.append('uploaded_file', f, `sku_${i}_${f.name}`)
        })




        fetch('http://localhost:9000/api/upload', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                data.d.forEach(ud => {
                    const isProd = ud.originalname.split("_")
                    let _sku = { ..._skus[isProd[1]] };
                    if (isProd[0] == 'prod') {
                        console.log("ini produk image" + isProd[1])
                        product.dokul_product_images[isProd[1]].image_url = `/assets/images/uploads/${ud.filename}`
                    }
                    if (isProd[0] == 'sku') {
                        console.log("ini sku image" + isProd[1])
                        _sku.image_url = `/assets/images/uploads/${ud.filename}`
                    }
                    _skus[isProd[1]] = _sku;

                })
                setSku(_skus)
            })
            .catch(error => {
                console.error(error)
            })
    }
    const handleSkuStatus = (e, i) => {


        //console.log(e.target.checked)
        let newSku = [...sku];
        let _sku = { ...newSku[i] }
        _sku.status = e.target.value;
        newSku[i] = _sku
        // setSku(state=>[...state])
        setSku(newSku)

    }
    const handleSkuSSubscribe = (e, i) => {
        let newSku = [...sku];
        let _sku = { ...newSku[i] }
        _sku.suscribe_change = e.target.checked ? 1 : 0;
        newSku[i] = _sku
        setSku(newSku)

    }
    const handleSkuDiscountGrade = (e, i) => {
        let newSku = [...sku];
        let text = "0000"
        let _sku = { ...newSku[i] }
        let currentGrade = _sku.discount_grade ? _sku.discount_grade.split('') : text.split('');
        let curentValueInt = parseInt(e.target.value)
        currentGrade[curentValueInt - 1] = e.target.checked ? e.target.value : "0";
        _sku.discount_grade = currentGrade.join('')
        newSku[i] = _sku
        setSku(newSku)

    }
    const handleProductValueChange = (e) => {
        const { name, value } = e.target
        let _product = { ...product }
        _product[name] = value
        setProduct(_product)

    }
    const handleSkuValueChange = (e, i) => {
        const { name, value } = e.target
        let newSku = [...sku];
        let _sku = { ...newSku[i] }
        _sku[name] = value
        newSku[i] = _sku
        setSku(newSku)

    }
    const handleProductRestrictChange = (e) => {
        //const { name, value } = e.target
        let _product = { ...product }
        _product["is_restricted"] = e != 1 ? 1 : 0
        console.log(_product)
        setProduct(_product)

    }
    const handleChangeCategory = (e, i, kategori) => {

        let _product = { ...product }
        let _categories = [..._product.dokul_tags_categories]

        const found = _categories.find(element => element.id == kategori.id);


        if (e.target.checked)
            _categories.push(kategori)
        else {


            const index = _categories.indexOf(found)

            _categories.splice(index, 1)

        }
        //console.log(product.dokul_tags_categories.le)
        _product.dokul_tags_categories = _categories;
        setProduct(_product)
        const pilu = `${file}${i}`;
        setFile(pilu)


    }
    return (

        <Fragment>
            {product || isLoading ? <Loading /> : <>
                <div className="container-fluid">
               
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header"><h5>Edit Layanan</h5></div>
                                <div className="card-body">
                                
                                    <Tabs>
                                        <TabList className="nav nav-tabs tab-coupon" >
                                            <Tab className="nav-link">Layanan detail</Tab>
                                            <Tab className="nav-link">Halaman layanan</Tab>
                                        </TabList>
                                        <TabPanel>
                                         <ServiceDetail data={props.data} />
                                         </TabPanel>
                                        <TabPanel>
                                           <ServicPage data={props.data} saveRow={props.saveRow} />
                                        </TabPanel>
                                    </Tabs>


                                    {/* <div className="row product-adding">
                                       

                                        <div className="col-xl-12 pt-3 mt-3 border-top">

                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>

                    </div>

                    
                </div>
            </>}
            
        </Fragment>
    )
}

export default EditService
