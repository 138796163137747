import React, { Component, Fragment, useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject, NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../../apollo'
import { GET_CATEGORIES, GET_TAGS, GET_DASHBOARD_DATA, GET_ORDERS, GET_SALES_MONTH } from '../../../constants/graph'
import { Chart } from "react-google-charts";
import Loading from '../../common/loading'
import Errorpage from '../../common/Errorpage'
const Salesmonth = ({ props }) => {
    //const [data,setData]=useState(props.data)
    const LineOptions = {
        hAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        vAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        colors: ['#ff8084'],
        legend: 'none',
    }
    const { loading, data, error, fetchMore, refetch, networkStatus } = useQuery(GET_SALES_MONTH);
    if (loading) return <Loading />;
    if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    return (
        <div className="card order-graph sales-carousel">
            <div className="card-header">
                <h6>Sales last month</h6>
                <div className="row">
                    <div className="col-12">
                        <div className="small-chartjs">
                            <div className="flot-chart-placeholder" id="simple-line-chart-sparkline-3">
                                <Chart
                                    height={'60px'}
                                    chartType="LineChart"
                                    loader={<div>Loading Chart</div>}
                                    data={data.saleslastmonth.saleslastmonth.data}
                                    options={LineOptions}
                                    legend_toggle
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-12">
                    <div className="value-graph">
                        <h3>42% <span><i className="fa fa-angle-up font-primary"></i></span></h3>
                    </div>
                </div> */}
                </div>
            </div>
            <div className="card-body">
                <div className="media">
                    <div className="media-body">

                        {/* <h2 className="mb-0">{data?.saleslastmonth.saleslastmonth.total?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2> */}
                        {/* <p>{data.salesLastMonth.percent} <span><i className="fa fa-angle-up"></i></span></p> */}
                    </div>
                    <div className="bg-primary b-r-8">
                        <div className="d-flex flex-row">
                            <div className="p-2">
                                <div className="small-box">
                                    <Briefcase />
                                </div>
                            </div>
                            <div className=" p-2 d-flex flex-column">
                                <span className="mb-2">Sales Last Month</span>
                                <h4 className="mb-0 ">Rp {data?.saleslastmonth.saleslastmonth.total?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h4>
                            </div>
                        </div>

                       
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withApollo(Salesmonth)
//export default ordertoday
