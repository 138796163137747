import React, { Component, useEffect } from 'react'
import man from '../../../assets/images/dashboard/man.png'
import { withApollo } from '../../../apollo'
import Cookies from 'js-cookie'
import history from '../../../history';
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { IS_LOGGED_IN } from '../../../constants/graph';
const roleNameList=[
    {
        "id": "1",
        "role_name": "customer",
        "create_by": "0",
        "create_date": "2021-06-12 13:12:26",
        "status": "1"
    },
    {
        "id": "2",
        "role_name": "shopmanager_jkt",
        "create_by": "0",
        "create_date": "2021-06-12 13:12:49",
        "status": "1"
    },
    {
        "id": "3",
        "role_name": "shopmanager_bpn",
        "create_by": "0",
        "create_date": "2021-06-12 13:12:57",
        "status": "1"
    },
    {
        "id": "4",
        "role_name": "shopmanager_jkt_bpn",
        "create_by": "0",
        "create_date": "2021-06-12 13:13:10",
        "status": "1"
    },
    {
        "id": "5",
        "role_name": "administrator_jkt_bpn",
        "create_by": "0",
        "create_date": "2021-06-12 13:13:26",
        "status": "1"
    },
    {
        "id": "6",
        "role_name": "dokulers_bpn",
        "create_by": "0",
        "create_date": null,
        "status": "1"
    },
    {
        "id": "7",
        "role_name": "dokulers_jkt",
        "create_by": "0",
        "create_date": null,
        "status": "1"
    },
    {
        "id": "8",
        "role_name": "dokbot",
        "create_by": "0",
        "create_date": null,
        "status": "0"
    },
    {
        "id": "9",
        "role_name": "dokulers_bpn_jkt",
        "create_by": "0",
        "create_date": null,
        "status": "1"
    },
    {
        "id": "10",
        "role_name": "administrator_bpn",
        "create_by": null,
        "create_date": null,
        "status": "1"
    },
    {
        "id": "11",
        "role_name": "administrator_jkt",
        "create_by": null,
        "create_date": null,
        "status": "1"
    },
    {
        "id": "12",
        "role_name": "dokter reguler",
        "create_by": "0",
        "create_date": null,
        "status": "1"
    },
    {
        "id": "13",
        "role_name": "resepsionis",
        "create_by": "0",
        "create_date": null,
        "status": "1"
    },
    {
        "id": "14",
        "role_name": "dokter premium",
        "create_by": "0",
        "create_date": null,
        "status": "1"
    },
    {
        "id": "15",
        "role_name": "Packer",
        "create_by": null,
        "create_date": null,
        "status": "1"
    },
    {
        "id": "16",
        "role_name": "Apotek 1 (Produk Wajah)",
        "create_by": null,
        "create_date": null,
        "status": "1"
    },
    {
        "id": "17",
        "role_name": "Apotek 2 (Produk Kulit)",
        "create_by": null,
        "create_date": null,
        "status": "1"
    },
    {
        "id": "18",
        "role_name": "Admin Premium",
        "create_by": null,
        "create_date": null,
        "status": "1"
    }
]
//export class User_panel extends Component {
const User_panel = ({ match }) => {
    const { data } = useQuery(IS_LOGGED_IN);
    const adminRoleId=[5,10,11]
    const user = JSON.parse(localStorage.getItem('CURRENT_USER'));


    // useEffect(() => {

        // console.log(user.role_id)
        // console.log(data.isLoggedIn)
        if (!data.isLoggedIn) {
            history.push('/');
        }

    // }, [data])
    // render() {
    return (
        <div>
            <div className="sidebar-user text-center">
                <div><img className="img-60 rounded-circle lazyloaded blur-up" src={`${process.env.REACT_APP_IMAGE_HOST}/${user.image_url}`} alt="" />
                </div>
                <h6 className="mt-3 f-14">{`${user?.first_name} ${user?.last_name}`}</h6>
                {user.dokul_user_roles.map((dur,idx)=>
                <p key={idx}>{roleNameList.find(x=>x.id==dur.role_id)?.role_name}</p>
                )}
            </div>
        </div>
    )
    //  }
}

export default withApollo(User_panel)

