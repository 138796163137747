import React, { Component, useState, useContext, useEffect, useMemo } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings } from 'react-feather'
import Modal from 'react-responsive-modal';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { GET_CONFIGS, UPDATE_SHIPMENT_METHOD } from '../../constants/graph'
import SocketContext from '../../provider/socketctx/context';
import DispatchContext from '../../provider/socketctx/disptachContext';
import QRCode from 'qrcode.react'
import { gql } from 'graphql-tag';
import moment from 'moment'
import DatePicker from "react-datepicker";
import ReactTable from 'react-table';

import { Badge, Button, Col, ListGroup, ListGroupItem, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import Loading from '../common/loading';
const Housekeeping = (props) => {
    const { positionInLine, queueLength, message, socketId, waconnect } = useContext(SocketContext);
    const newMessage = useContext(DispatchContext)
    const { loading, data, error, fetchMore } = useQuery(GET_CONFIGS,);
    const [waCode, setWaCode] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpenEdit, setModalOpenEdit] = useState(false)
    const [editData, seteditData] = useState(null)
    const [editDataKlinik, seteditDataKlinik] = useState(null)
    const [idxDataKlinik, setidxDataKlinik] = useState(0)
    const [mediaData, setmediaData] = useState([])
    const [mediaDataFiltered, setmediaDataFiltered] = useState([])
    const [startDate, setstartDate] = useState(moment().subtract(1, 'month').toDate())
    const [startIndex, setstartIndex] = useState(0)
    const [currentPage, setcurrentPage] = useState(0)
    const [selectedFolder, setselectedFolder] = useState()
    const [listSelected, setlistSelected] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [generatedLink, setgeneratedLink] = useState()
   const [pageSize, setpageSize] = useState(10)
    useEffect(() => {
      if(selectedFolder){
          setModalOpen(true)
      }
    }, [selectedFolder])
    
    const getMediaKonsul = () => {
        fetch("https://api.dokterkulitku.com/api/upload/konsulmedia?limit=100&offset=0", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(waconnect)
        }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // let listData=[]
                    // for (const [key, value] of Object.entries(result)) {
                    //     listData.push({email:key,data:value})
                    //   }
                    // const sortdata=result.data.sort(function(a, b) {
                    //     return a.isi - b[1];
                    // });
                    setmediaData(result.data)
                },
                (error) => {
                }
            )
    }
    const generateDownload = () => {
        fetch("https://api.dokterkulitku.com/api/upload/downloadKonsulFile?limit=100&offset=0", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(listSelected)
        }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // let listData=[]
                    // for (const [key, value] of Object.entries(result)) {
                    //     listData.push({email:key,data:value})
                    //   }
                    console.log(result)
                    setgeneratedLink(result)
                    setisLoading(false)
                    setModalOpenEdit(true)
                },
                (error) => {
                }
            )
    }
    const hapusFiles = (data) => {
        setisLoading(true)
        fetch("https://api.dokterkulitku.com/api/upload/deleteKonsulFile?limit=100&offset=0", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // let listData=[]
                    // for (const [key, value] of Object.entries(result)) {
                    //     listData.push({email:key,data:value})
                    //   }
                    
                    setisLoading(false)
                    getMediaKonsul()
                },
                (error) => {
                }
            )
    }
    useEffect(() => {
        getMediaKonsul()
    }, [])

    useEffect(() => {
        console.log(waconnect)
        if (waconnect.sessid) {
            delete waconnect['sessid'];
            fetch("https://foryou.io/api/integrasi/authWa", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(waconnect)
            }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        setModalOpen(false)
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                    }
                )
        }
    }, [waconnect])
    const authWhatsapp = () => {
        let auth = {
            sessid: socketId,
            WABrowserId: '"Js9vUCYhUmk7p+/4rhIzWg=="',
            WASecretBundle: '{"key":"MDIXLJAKGoQzI0IKxU2JJMLfWGAM8Osbmyx0F6v6y/s=","encKey":"1XMMGbwE1TloHwoVtcL6sAygMAYCQhHNzimMe8RAyRE=","macKey":"MDIXLJAKGoQzI0IKxU2JJMLfWGAM8Osbmyx0F6v6y/s="}',
            WAToken1: '"c4xsVO5XOePAJZpndLBf1tOezQZ5XNH00co58Gnc7To="',
            WAToken2: '"1@d8NnJiK+V4KxzHNuXtWrGTO6ne6zR2aF710q6aodw9DNiaxXJr6XKFEwotBjyCD8goN0lZf40Uw/Cw=="'
        }
        fetch("https://foryou.io/api/integrasi/authWa", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(auth)
        }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    setModalOpen(false)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }
    const getWaInit = () => {
        let myheaders = {
            "sessid": socketId
        }
        fetch("https://foryou.io/api/integrasi/initWa", { headers: myheaders })
            .then(res => res.json())
            .then(
                (result) => {
                    setWaCode(result.code)
                    setModalOpen(true)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }
    const updateCache = (cache, { data }) => {
        seteditData(null)
        seteditDataKlinik(null)
        setidxDataKlinik(null)
        console.log(data)
    };
    const [updateconfigs, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(UPDATE_SHIPMENT_METHOD, { update: updateCache });
    const columns = useMemo(
        () => [
            {
                Header: <b></b>,
                accessor: "id",
                width: "100%",

                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className='d-flex flex-row'>
                                <div className='p-2'><Button color='light' onClick={()=>setselectedFolder(cellInfo.original)}>{cellInfo.original.folder.split('/')[5]}</Button></div>
                                <div className='p-2 ms-auto'>{hitungTotalSize(cellInfo.original.isi)}</div>
                                <div className='p-2'>
                                    <input type={"checkbox"} defaultChecked={listSelected.some(x=>x.folder==cellInfo.original.folder.split('/')[5])} onChange={(e)=>{
                                      
                                      const nd={folder:cellInfo.original.folder.split('/')[5],files:getFilteredFiles(cellInfo.original.isi)}
                                      selectRow(e,nd)

                                    }}/>
                                </div>
                            </div>
                    </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
        ]
    )
    const selectRow = (e, i) => {
        e.preventDefault()
        console.log(e)
        if (e.target.checked) {
            setlistSelected(x=>[...x,i])
            console.log(listSelected)
        } else {
            let oldList=[...listSelected]
            const deletedIdx=oldList.findIndex(x=>x.folder==i.folder)
            console.log(deletedIdx)
            oldList.splice(deletedIdx,1)
            setlistSelected(oldList)
            
        }
    }
    const selectRowAll = (e) => {
        // const nd={folder:cellInfo.original.folder.split('/')[5],files:getFilteredFiles(cellInfo.original.isi)}
        // const idxData=(currentPage+1)*pageSize
       const selecteds= mediaData.slice(currentPage*pageSize,currentPage*pageSize+pageSize).map(x=>{
            return {folder:x.folder.split('/')[5],files:getFilteredFiles(x.isi)}

        })
        setlistSelected(selecteds)
                                      
        if (!e.target.checked) {
            
        } else {
            
        }
    }
    if (loading) return "LOADING"
    // console.log(data)
    const { dokul_configs, dokul_paymethods, dokul_kliniks } = props.data
    const hitungTotalSize = (data) => {
        console.log("hitungulang")
        const filteredData = data.filter(x => {
            console.log(moment(startDate).diff(moment(x.desc.birthtime),'days') > 0)
            console.log(moment(x.desc.birthtime).toDate())
            console.log(moment().toDate())
            console.log(moment(startDate))
            return moment(startDate).diff(moment(x.desc.birthtime),'days') > 0
        })
        // console.log(filteredData)
        const ukuran = filteredData.reduce((prevval, curval) => {

            return prevval + curval.desc.size
        }, 0)
        const fileSizeInMegabytes = ukuran / 1000000
        return `${Math.ceil(fileSizeInMegabytes)} MB`
    }
    const getFilteredFiles = (data) => {
        const filteredData = data.filter(x => {
            console.log( moment(startDate).diff(moment(x.desc.birthtime),'days') > 0)
            console.log(moment(x.desc.birthtime).toDate())
            console.log(moment().toDate())
            return moment(startDate).diff(moment(x.desc.birthtime),'days') > 0
        })
        
        
        return filteredData.map(x=>x.path)
    }
    const hitungTotalAllSize = () => {
        const allMedia=mediaData.reduce((prev,cur)=>cur.isi.concat(prev),[])
        const filteredData = allMedia.filter(x => {
            // console.log(moment(startDate).diff(moment(),'month'))
            // console.log(moment(x.desc.birthtime).toDate())
            // console.log(moment().toDate())
            return moment(startDate).diff(moment(x.desc.birthtime),'days') > 0
        })
        // console.log(filteredData)
        const ukuran = filteredData.reduce((prevval, curval) => {

            return prevval + curval.desc.size
        }, 0)
        const fileSizeInMegabytes = ukuran / 1000000
        return `${Math.ceil(fileSizeInMegabytes)} MB`
    }
    const rehitungTotalAllSize = (data) => {
        const allMedia=data.reduce((prev,cur)=>cur.isi.concat(prev),[])
        const filteredData = allMedia.filter(x => {
            // console.log(moment(startDate).diff(moment(),'month'))
            // console.log(moment(x.desc.birthtime).toDate())
            // console.log(moment().toDate())
            return moment(startDate).diff(moment(x.desc.birthtime),'days') > 0
        })
        // console.log(filteredData)
        const ukuran = filteredData.reduce((prevval, curval) => {

            return prevval + curval.desc.size
        }, 0)
        const fileSizeInMegabytes = ukuran / 1000000
        return `${Math.ceil(fileSizeInMegabytes)} MB`
    }
    // const buildPaging = () => {
    //     const totalPage=mediaData.length / 20
    //     // let listJumlah=[]
    //     // for (let index = 0; index < Math.round(totalPage); index++) {
    //     //     listJumlah.push(index)
            
    //     // }
    //     return <Pagination>
    //         <PaginationItem>
    //             <PaginationLink
    //                 first
    //                 href="#"
    //             />
    //         </PaginationItem>
    //         <PaginationItem>
    //             <PaginationLink
    //                 href="#"
    //                 previous
    //             />
    //         </PaginationItem>
    //         <PaginationItem>
    //             <PaginationLink href="#">
    //                 {currentPage}
    //             </PaginationLink>
    //         </PaginationItem>
    //         <PaginationItem>
    //             <PaginationLink href="#">
    //                 {currentPage+1}
    //             </PaginationLink>
    //         </PaginationItem>
    //         <PaginationItem>
    //             <PaginationLink href="#">
    //                 {Math.round(totalPage)-1}
    //             </PaginationLink>
    //         </PaginationItem>
    //         <PaginationItem>
    //             <PaginationLink
    //              href="#"
    //              onClick={()=>{
    //                 setcurrentPage(totalPage)
    //                 setstartIndex(mediaData.length-20)
    //             }}
    //              >
    //                 {Math.round(totalPage)}
    //             </PaginationLink>
    //         </PaginationItem>

    //         <PaginationItem>
    //             <PaginationLink
    //                 href="#"
    //                 next
    //                 onClick={()=>{
    //                     setcurrentPage(x=>x+1)
    //                     setstartIndex(x=>x+20)
    //                 }}
    //             />
    //         </PaginationItem>
    //         <PaginationItem>
    //             <PaginationLink
    //                 href="#"
    //                 last
    //             />
    //         </PaginationItem>
    //     </Pagination>
    // }
    
   
    return (
        <div className="row">
           <Loading show={isLoading} text="Generate Link" />
            <div className="col-12 tab-pane fade show active">
                <h5 className="f-w-600 f-16 border-bottom text-info">KONSULTASI PHOTO</h5>
                <div className="table-responsive profile-table mb-3">
                    <div className="form-group row mb-2">
                        <label className="col-xl-3 col-md-3">Data Sebelum</label>
                        <div className="col-md-7">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setstartDate(date)}
                                dateFormat="dd MMMM yyyy"
                            />
                        </div>
                    </div>
                </div>
                <div className="table-responsive profile-table mb-3">
                    <div className="form-group row mb-2">
                        <label className="col-xl-3 col-md-3">Total Data</label>
                        <div className="col-md-7">
                            {hitungTotalAllSize()}
                        </div>
                    </div>
                </div>
                <div className="table-responsive profile-table mb-3">
                    <div className="form-group row mb-2">
                        <Button className="col-xl-3 col-md-3" onClick={()=>{
                            const selecteds= mediaData.map(x=>{
                                return {folder:x.folder.split('/')[5],files:getFilteredFiles(x.isi)}
                    
                            })
                            const filteredSelected=selecteds.filter(x=>x.files.length>0)
                            // const tot=rehitungTotalAllSize(selecteds)

                            // console.log(filteredSelected)

                            hapusFiles(selecteds)
                        }}>Delete Data</Button>
                    </div>
                </div>
                {mediaData && <ReactTable
                            data={mediaData}
                            columns={columns}
                            defaultPageSize={pageSize}
                           // manual={true}
                            className={"-striped -highlight"}
                            showPagination={true}
                            // autoResetPage={true}
                            // page={props.dSubmitKonsul.submitkontrol.currentPage}
                            // pages={props.dSubmitKonsul.submitkontrol.totalPages}
                            onPageChange={(e) => setcurrentPage(e)}
                            onPageSizeChange={(e) => setpageSize(e)}   
                            // defaultFiltered={stateQuery}
                            // filterable={true}
                            ThComponent={()=><div className='m-3'>Data terpilih :
                             <Button className='float-end m-2' color='danger' onClick={()=>{
                                 hapusFiles(listSelected)
                             }}>Hapus</Button> <Button  className='float-end m-2' color='success' onClick={(e)=>{
                                // setisLoading(true)
                                // console.log(listSelected)
                                generateDownload()
                            }}>Download</Button>
                            <Button  className='float-end m-2' color='success' onClick={(e)=>{
                                console.log(currentPage)
                                console.log(pageSize)
                                selectRowAll(e)

                            }}>Pilih semua</Button></div>}
                            loadingText="Mengambil data dari server"
                            nextText="Selanjutnya"
                            previousText="Sebelumnya"
                            showPaginationTop={true}
                            pageText="Halaman"
                            ofText="dari"
                            rowsText="Baris"
                            // resolveData={(dt)=>console.log(dt)}
                            // onFetchData={(val) => fetchData(val)}
                            //onFilteredChange={(val) => fetchData(val)}


                        />}
                {/* <Row className='border'>
                    {mediaData.slice(startIndex, startIndex+20).map((md, idxmd) => {

                        return <Col md="6" key={idxmd} className="border">
                            <div className='d-flex flex-row'>
                                <div className='p-2'>{md.folder.split('/')[5]}</div>
                                <div className='p-2 ms-auto'>{hitungTotalSize(md.isi)}</div>
                                <div className='p-2'>
                                    <input type={"checkbox"} />
                                </div>
                            </div>

                        </Col>
                    })}


                </Row>
                <Row className='mt-3'>
                    {buildPaging()}
                </Row> */}
            </div>


            {/* <div className="col-12 tab-pane fade show active">
                <h5 className="f-w-600 f-16 border-bottom text-info">Struk</h5>

            </div> */}

            <Modal open={modalOpen} onClose={() => setModalOpen(false)} center={true}>
                <div className="card-body">
                    {selectedFolder&&<ListGroup>
                        {selectedFolder.isi.map((dt,dtx)=>{
                            return <ListGroupItem>
                               <div className='d-flex flex-row'>
                                <div className='p-2'>{dt.path}<br/>{dt.desc.birthtime}</div>
                                <div className='p-2 ms-auto'>{dt.desc.size/1000000} MB</div>
                                <div className='p-2'>
                                    <Button>Download</Button>
                                </div>
                            </div>
                            </ListGroupItem>
                        })}
                    </ListGroup>}
                    
                </div>
                {/* <ModelOrderDetail order={this.props.data.orders.orders[indexSelectedOrder]} updateOrder={this.props.updateOrder}  /> */}
            </Modal>
            <Modal open={modalOpenEdit} onClose={() => setModalOpenEdit(false)} center={true} >
                {generatedLink&&<div className="card-body">
                    <a href={generatedLink.urldownload} target="_blank">{generatedLink.urldownload}</a>
                </div>}
                {/* <ModelOrderDetail order={this.props.data.orders.orders[indexSelectedOrder]} updateOrder={this.props.updateOrder}  /> */}
            </Modal>
        </div>
    )
}
export default withApollo(Housekeeping)
