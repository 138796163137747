import React from 'react'
import { Badge, Collapse } from 'reactstrap'

import moment from 'moment'
import { useState } from 'react'

function HistoryKontrol({ selectedKontrol,setselectedKontrol }) {
    const [isOpen, setisOpen] = useState(false)
    async function getQ_A(_id) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolQa/${_id}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            ///body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    return (
        <div>
            <h4 className="bg-primary p-2 "> Riwayat konsul pasien <button className="btn btn-xs btn-main border text-small float-end" onClick={()=>setisOpen(!isOpen)}>{isOpen?"Hide":"Show"}</button></h4>
            <Collapse isOpen={isOpen}>
            <div className="d-flex flex-column flex-wrap">
                {selectedKontrol.history.filter(x=>x.branch_id==selectedKontrol.branch_id).map((x, idx) =><div key={idx} className="d-flex flex-row border-bottom" >
                    
                    <Badge className={`border p-2 m-2 col-4 ${x.id == selectedKontrol.id ? "bg-primary" :x.status==1?"bg-cred": "bg-light text-dark"}`} onClick={async () => {
                        const _dataQa = await getQ_A(x.id)
                        // const _dataHistory = await getHistory(row.original.user_id)

                        // console.log(_dataQa)
                        let _selectedKontrol = { ...x, dokul_user: selectedKontrol.dokul_user }
                        _selectedKontrol["qa"] = _dataQa ? _dataQa.qa : []
                        _selectedKontrol["result"] = _dataQa ? _dataQa.result : {}
                        _selectedKontrol["history"] = selectedKontrol.history
                        // if (_dataQa.result) {
                        //     setContent(_dataQa.result.dokul_konsultasi_kontrol_result?.result_text)
                        //     const _cproduct = _dataQa.result.dokul_konsultasi_kontrol_result_produks.map(x2 => {
                        //         return dProduct.products.find(p => p.id == x2.produk_id)
                        //     })
                        //     // setselectedProduct(_cproduct)
                        // }
                         setselectedKontrol(_selectedKontrol)
                       // setselectedKontrol({ ...x, history: selectedKontrol.history })
                    }}>{moment(x.created_date).format("DD MMMM YYYY")} <br/>{x.dokterhandle?.first_name } {x.dokterhandle?.last_name}</Badge>
                    <div className='p-2'>{x.dokul_konsultasi_kontrol_result?.note}</div>
                    </div>
                    
                )}


            </div>
            </Collapse>
        </div>
    )
}

export default HistoryKontrol