import React , { Component,Fragment,useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject,NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
import {GET_CATEGORIES,GET_TAGS,GET_DASHBOARD_DATA,GET_ORDERS, GET_ORDER_STATUS, GET_ORDER_BYLOCATION} from '../../constants/graph'
import { Chart } from "react-google-charts";

import Loading from '../common/loading'
import Errorpage from '../common/Errorpage'
const Locationorder = ({ props }) => {
    //const [data,setData]=useState(props.data)
    const pieOptions = {
        title: "dsds",
        pieHole: 1,
        slices: [
            {
                color: "#ff8084"
            },
            {
                color: "#13c9ca"
            },
            {
                color: "#f0b54d"
            },
        ],
        tooltip: {
            showColorCode: false
        },
        chartArea: { left: 10, top: 10, width: '560px', height: '100%' },
        //legend: "none",
        is3D: true,
    };
   const { loading, data, error,fetchMore , refetch, networkStatus} = useQuery(GET_ORDER_BYLOCATION);
   if (loading) return <Loading />;
    if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
   
    return (
        <div className="col-xl-6 col-sm-12 xl-50">
                                        <div className="order-graph sm-order-space">
                                            <h6>Orders By Location</h6>
                                            <div className="peity-chart-dashboard text-center" style={{ display: 'block', margin: '0 auto' }}>
                                                <Chart
                                                    chartType="PieChart"
                                                    data={data.orderBylocation.orderBylocation}
                                                    options={pieOptions}
                                                    graph_id="PieChart"
                                                    width={"100%"}
                                                    height={"250px"}
                                                    legend_toggle
                                                />
                                            </div>
                                            {/* <div className="order-graph-bottom sales-location">
                                                    {data.orderStatus.map(ol=>
                                                            <div className="media">
                                                            <div className="order-shape-primary"></div>
                                                            <div className="media-body">
                                                                <h6 className="mb-0 mr-0">{ol[0]} <span className="pull-right">{ol[1]}%</span></h6>
                                                            </div>
                                                        </div>
                                                        )}
                                                    
                                                    
                                                </div> */}
                                        </div>
                                    </div>
    )
}
export default withApollo(Locationorder)
//export default ordertoday
