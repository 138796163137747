import React, { Component, Fragment, forwardRef, useState, useMemo, useEffect, useCallback } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
// import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge, CustomInput, Alert, ModalFooter, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import moment from 'moment'
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { GET_DOKTERS, GET_KLINIKS, GET_LIST_VOUCHER_PRIVATE, GET_PRODUCTS, GET_RESERVASI, GET_ROLES, UPDATE_DOKTER } from '../../constants/graph';
import data from '../../assets/data/physical_list';
import { useQuery, useMutation, useLazyQuery, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import * as XLSX from 'xlsx';
import Profile from './user.detail';

registerLocale('id', es)

const Roles = (props) => {
    const adminRoleId = [5, 10, 11]
    const shopManRole = [2,3,4]
    const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
    const [userDetail, setuserDetail] = useState({})
    const [addNew, setaddNew] = useState(false)
    const [getPrivateVoucher, { loading: lPrivateVoucher, error: ePrivateVoucher, data: dPrivateVoucher }] = useLazyQuery(GET_LIST_VOUCHER_PRIVATE,{
        fetchPolicy:'network-only'
    })

    const hadleGetUserDetail = async (id) => {
        let param = `page=${0}&size=${100}&status=1`
        getPrivateVoucher({
            variables: {
                page: 0,
                size: 10,
                keperluan: undefined,
                email: undefined,
                params: `${param}`

            }
        })  
        await props.getUserDetail({
            variables: {
                id: id
            }
        }).then((result) => {
            if (result.data) {
                setaddNew(false)
                setuserDetail(result.data.userDetail)
                setuserDetailOpen(true)
            }
        })

    }
    const columns = useMemo(
        () => [
            {
                // first group - TV Show
                Header: "",
                // First group columns
                columns: [
                    {
                        Header: "Email",
                        accessor: "dokul_user.email",
                        filterable: true
                    },
                    {
                        Header: "Name",
                        accessor: "dokul_user.first_name",
                        filterable: true,
                        Cell: (row) => (`${row.original.dokul_user.first_name}`),
                    },
                    {
                        Header: "Grade",
                        accessor: "dokul_user.current_grade",
                        filterable: true,
                        Cell: (row) => (userGrade[row.original.dokul_user.current_grade]),
                        style: { textAlign: 'center' }
                    },
                    {
                        Header: "Action",
                        accessor: "id",
                        Cell: (row) => (<>
                            <button className="btn btn-success btn-xs text-light m-1" onClick={() => hadleGetUserDetail(row.original.dokul_user.id)}><i className="icofont icofont-eye" style={{ fontSize: 12 }}></i></button>
                            <button className="btn btn-primary btn-xs text-light m-1" onClick={() => {

                                setaddNew(false)

                                setselectedUser(row.original)
                                setModalOpenChangeRole(true)
                            }
                            }><i className="icofont icofont-edit" style={{ fontSize: 12 }}></i></button>
                            {row.original.dokul_user.status != 9 && <button className="btn btn-danger-gradien  btn-xs text-danger m-1" onClick={() => hadleGetUserDetail(row.original.dokul_user.id)}><i className="icofont icofont-ban" style={{ fontSize: 12 }}></i></button>}
                            {row.original.dokul_user.status == 9 && <button className="btn btn-light-gradien btn-xs text-success m-1" onClick={() => hadleGetUserDetail(row.original.dokul_user.id)}><i className="icofont icofont-check-circled" style={{ fontSize: 14 }}></i></button>}

                        </>),
                        style: { textAlign: 'center' }
                    }
                ]
            },

        ],
        []
    );
    const [users, setUsers] = useState()
    const [totalPages, setTotalPages] = useState()
    const [selectedUser, setselectedUser] = useState({ dokul_user: { dokul_user_roles: [] } })

    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpenChangeRole, setModalOpenChangeRole] = useState(false)
    const [tgl, setTgl] = useState(new Date())
    const [idxDokter, setIdxDokter] = useState(99)
    const [idxKlinik, setIdxKlinik] = useState(0)
    const [jadwalBaru, setJadwalBaru] = useState({})
    const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const userGrade = ['', 'Classic', 'Silver', 'Gold', 'Platinum']
    const { loading, error, data } = useQuery(GET_ROLES)
    const [uploadData, setuploadData] = useState(false)
    const [uploadSuccess, setuploadSuccess] = useState(false)
    const [uploadError, setuploadError] = useState(false)
    const [uploadResult, setuploadResult] = useState([])
    const [listDataUpload, setlistDataUpload] = useState([])
    const [getDataLoading, setgetDataLoading] = useState(false)
    const [selectedRoles, setselectedRoles] = useState([])
    const [userDetailOpen, setuserDetailOpen] = useState(false)
    const [tblFilter, settblFilter] = useState([])
    const [tblpageSize, settblpageSize] = useState(10)
    const [tblPage, settblPage] = useState(0)
    const fetchData = async (states, instance) => {
        console.log("Fetch data")
        let first_name;
        let date;
        let balik;
        let email;
        let grade;
        const state = tblFilter ? tblFilter : states
        console.log(state)
        state.forEach(element => {
            if (element.id == 'dokul_user.email') email = element.value
            if (element.id == 'dokul_user.first_name') first_name = element.value
            if (element.id == 'dokul_user.current_grade') grade = element.value
            // if (element.id == 'created_date') {
            //     var d = moment(element.value)
            //     var od = moment(this.state.selectedDate)
            //     if (d.date() == od.date())
            //         balik = true;
            //     date = element.value

            // }

        })
        if (balik) return

        const t = await props.getNextData(tblpageSize, tblPage, data?.roles[idxDokter]?.id, email, first_name, date, grade)
        setUsers(t.data.users)
        setTotalPages(t.data.users.totalPages)
        // setcurrentStateTable(state)



    }
    useEffect(() => {


        // console.log(tblPage)
        // console.log(tblpageSize)
        // console.log(tblFilter)
        // console.log(idxDokter)
        if (props.data) {
            console.log("reload")
            fetchData([])
        }
    }, [tblPage, tblpageSize, tblFilter, idxDokter])


    const reloadData = async (idx) => {
        // fetchData()
        // setgetDataLoading(true)
        // const t = await props.getNextData(10, 0, undefined, undefined, undefined, data?.roles[idx].id)
        // setUsers(t.data.users)
        // setgetDataLoading(false)

    }
    useEffect(() => {

        if (!props.data)
            props.getNextData(10, 0, 1)
    }, [])

    useEffect(() => {
        if (props.data)
            setUsers(props.data.users)
    }, [props.data])

    const handleUpload = (e) => {

        e.preventDefault();

        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            // setFileUploaded(dataParse);
            const dtaMap = dataParse.map(x => {
                return {
                    email: x['0'],
                    role_id: x['1']
                }
            })
            setlistDataUpload(dtaMap)
            setModalOpen(true)
            // console.log(dataParse);
            // console.log(dtaMap);
            // postData(`${process.env.REACT_APP_API_HOST}/api/users/sync/role`, dtaMap)
        };
        reader.readAsBinaryString(f)
    }
    const client = useApolloClient()
    const updateCache = (cache, { data }) => {

        const _updatedDokter = { ...data.updateDokter }
        const fragmentResult = cache.writeFragment({
            id: `Dokter:${_updatedDokter.id}`,
            fragment: gql`
        fragment dokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
        }
      `,
            data: _updatedDokter
        });
    };
    const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });

    const selectedDokter = client.readFragment({
        id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment readDokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
          }
        `,
    }, false);
    const { loading: loadingKlinik, error: errorKlinik, data: dataKlinik } = useQuery(GET_KLINIKS)

    async function postData(url = '', data = {}) {
        if (!modalOpenChangeRole)
            setuploadData(true)

        // Default options are marked with *
        //  setLoading(true)
        fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(data => {
            if (modalOpenChangeRole) {
                setModalOpenChangeRole(false)

            } else {
                if (data.status == 200) {

                    setuploadSuccess(true)
                    // setLoading(false)
                    // seteditStock(false)
                    // props.refetch()
                    return data.json();
                } else {
                    setuploadError(true)
                    // setLoading(false)
                    // seterrorUpdate(true)

                }
            }

        }).catch(err => {

        });

        // return response.json(); // parses JSON response into native JavaScript objects
    }
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }
    useEffect(() => {

    }, [totalPages])

    if (loading || loadingKlinik) return "Loading"
    return (
        <Fragment>
            <Modal isOpen={uploadData} centered backdrop>
                <ModalBody className="text-center bg-light">
                    <>
                        {!uploadError || !uploadSuccess && <div className="spinner-border text-info spinner-border-lg" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>}
                        {uploadError && <Alert color="danger">Upload user gagal silahkan ulangi kembali</Alert>}
                        {uploadSuccess && <Alert color="success">Upload user berhasil</Alert>}
                    </>
                </ModalBody>
                <ModalFooter className="bg-light">{uploadError || uploadSuccess && <button className="btn btn-sm btn-dark" onClick={() => {
                    setuploadData(false)
                    setuploadError(false)
                    setuploadSuccess(false)
                    setModalOpen(false)
                }}>OK</button>}</ModalFooter>
            </Modal>
            <Modal isOpen={userDetailOpen} fullscreen centered size="md">
                <ModalHeader className="bg-primary" toggle={() => setuserDetailOpen(false)}>
                    User Detail
                </ModalHeader>
                <ModalBody className="bg-light">
                    <Profile dPrivateVoucher={dPrivateVoucher} dProducts={props.dProducts} setselectedUser={setselectedUser} setModalOpenChangeRole={setModalOpenChangeRole} dUserDetail={userDetail} closeModal={() => setuserDetailOpen(false)} isNew={addNew} />
                </ModalBody>
                <ModalFooter className="bg-light">{uploadError || uploadSuccess && <button className="btn btn-sm btn-dark" onClick={() => {
                    setuploadData(false)
                    setuploadError(false)
                    setuploadSuccess(false)
                    setModalOpen(false)
                }}>OK</button>}</ModalFooter>
            </Modal>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-row bd-highlight justify-content-sm-between border-bottom mb-2">
                                    <h4 className="text-info">Roles</h4>
                                    {/* <span className="text-info" style={{ cursor: "pointer" }} onClick={(e) => setModalOpen(true)}>Tambah role</span> */}
                                </div>
                                <div className={`p-2 m-1 d-flex flex-row bd-highlight justify-content-sm-between card ${idxDokter==99 ? "bg-primary" : "bg-white"}`}  >
                                        <span className={`${idxDokter!=99 ? "text-dark" : "text-white"}`} style={{ cursor: "pointer" }} onClick={() => {
                                            setIdxDokter(99)
                                            // reloadData(idx)
                                        }}>All</span>
                                       
                                    </div>
                                {data.roles.map((role, idx) => {

                                    if (!adminRoleId.some(x => user.dokul_user_roles.some(dur => dur.role_id == x)) && (role.role_name.includes("administrator") || role.role_name.includes("shopmanager"))) return ""
                                    else return <div key={idx} className={`p-2 m-1 d-flex flex-row bd-highlight justify-content-sm-between card ${idxDokter == idx ? "bg-primary" : "bg-white"}`}  >
                                        <span className={`${idxDokter != idx ? "text-dark" : "text-white"}`} style={{ cursor: "pointer" }} onClick={() => {
                                            setIdxDokter(idx)
                                            reloadData(idx)
                                        }}>{role.role_name}</span>
                                        <span className={`bg-light text-dark rounded p-1`} style={{ cursor: "pointer" }} onClick={(e) => {
                                            setuserDetail({
                                                dokul_user_roles: [
                                                    {
                                                        dokul_user_role: { role_name: role.role_name }
                                                    }
                                                ],
                                                role_id: role.id,
                                                image_url: 'assets/images/user.png',
                                                dokul_orders: [],
                                                dokul_user_products: [],
                                                dokul_carts: [],
                                                status: 2
                                            })
                                            setaddNew(true)
                                            setuserDetailOpen(true)
                                        }}><small>Add</small></span>
                                    </div>
                                }


                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div className="card">
                            {/* <div className="card-header bg-primary text-white">
                                <span className="float-left">
                                    <div className="d-flex flex-row bd-highlight ">
                                        <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">Role Permissions</h4></div>
                                        <div className="p-0 bd-highlight"><DatePicker
                                            locale={"id"}
                                            dateFormat="dd MMMM yyyy"
                                            selected={tgl}
                                            onChange={(date) => setTgl(date)}
                                            customInput={<ExampleCustomInput />}
                                        /></div>

                                    </div>
                                </span>
                                <h5>Jadwal konsultasi</h5>

                            </div> */}
                            <div className="card-body">
                                <div className="d-flex flex-row bd-highlight justify-content-between ">
                                    <div className="p-2 bd-highlight">
                                        <h4 className="text-dark mr-2">{data.roles[idxDokter]?.role_name}</h4>
                                    </div>
                                    <div className=" bd-highlight ml-auto">
                                        {/* <button className="btn btn-info btn-xs" onClick={()=>setModalOpen(true)}>Import Data</button> */}

                                        <label htmlFor="fileUpload" className="m-2">
                                            <Badge className="p-2 bg-primary pointer" style={{ cursor: "pointer" }}>
                                                Import Data : 
                                                <input id="fileUpload" type="file" multiple accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                    onChange={(e) => handleUpload(e)}
                                                    style={{ cursor: "pointer" }}/>
                                            </Badge></label>

                                    </div>
                                    {/* <div className="p-0 bd-highlight"><DatePicker
                                            locale={"id"}
                                            dateFormat="dd MMMM yyyy"
                                            selected={tgl}
                                            onChange={(date) => setTgl(date)}
                                            customInput={<ExampleCustomInput />}
                                        /></div> */}



                                </div>
                                <ReactTable
                                    data={users ? users.users : []}//{props.data.users?props.data.users.users:[]}
                                    columns={columns}
                                    defaultPageSize={10}
                                    className={"-striped -highlight"}
                                    showPagination={props.pagination}
                                    manual={true} // Forces table not to paginate or sort automatically, so we can handle it server-side
                                    pages={users ? users.totalPages : totalPages} // Display the total number of pages
                                    loading={getDataLoading}
                                    defaultFiltered={tblFilter}
                                    page={users ? users.currentPage : tblPage}//{tblPage}
                                    onPageChange={(val) => settblPage(val)}
                                    onFilteredChange={(val) => settblFilter(val)}
                                    onPageSizeChange={(val) => settblpageSize(val)}
                                // Display the loading overlay when we need it
                                // onFetchData={fetchData} // Request new data when things change
                                // sorted={this.state.sorted}
                                // onSortedChange={(newSort, column) => {
                                //     this.setState({ sorted: newSort });
                                // }}
                                />
                                {/* <div className="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <ToastContainer />
                                </div>
                                {/* {data.roles[idxDokter].dokul_role_permissions.map((kon, idxKon) =>
                                    kon.type == 1 && kon.status == 1 ?
                                        <div className="d-flex flex-column bd-highlight mb-3">
                                            <h6 className="border-bottom border-info"><i className="icofont icofont-hospital" /> {kon.dokul_klinik.name}
                                                <span className="bg-success rounded p-1 float-right m-2" style={{ fontSize: 10 }} onClick={() => {
                                                    setIdxKlinik(idxKon)
                                                    setModalOpen(true)
                                                }}><i className="icofont icofont-plus" /></span></h6>
                                            {kon.dokul_dokter_jadwals.filter(xjd => compareDate(xjd.tanggal, tgl)).length > 0 ?
                                                kon.dokul_dokter_jadwals.filter(xjd => compareDate(xjd.tanggal, tgl)).map(jd =>
                                                    <div className="p-2 bd-highlight border mb-2">
                                                        <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-between">
                                                            <div className="d-flex flex-row">
                                                                <div className="p-2 bd-highlight">{jd.name}</div>
                                                                <div className="p-2 bd-highlight ml-5">Jumlah sesi : {jd.jumlah_sesi}</div>
                                                            </div>
                                                            <div className="p-2 bd-highlight">Terpakai : {jd.terpakai}</div>

                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <h6 className="text-center"> Tidak ada jadwal</h6>
                                            }

                                        </div>
                                        :
                                        ""
                                )} */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={false} toggle={() => setModalOpen(false)} >


                <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Judul</h5>

                    </div>
                    <div className="modal-body">
                        <div className="form-group row">
                            <label className="col-xl-3 col-md-4">Name</label>
                            <div className="col-md-7">
                                <input className="form-control" name="name" id="name-point" type="text" value={"point.name"} required="" onChange={(e) => {
                                    const { name, value } = e.target
                                }} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-md-4">Enable</label>
                            <div className="col-md-7">
                                <span>
                                    <input type="checkbox" value={"point.name"} required="" onChange={(e) => {
                                        const { name, value } = e.target
                                    }} />
                                </span>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-xl-3 col-md-4">Admin web</label>
                            <div className="col-md-7">
                                <span>
                                    <input type="checkbox" value={"point.name"} required="" onChange={(e) => {
                                        const { name, value } = e.target
                                    }} />
                                </span>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-xl-3 col-md-4">Shopping web</label>
                            <div className="col-md-7">
                                <span>
                                    <input type="checkbox" value={"point.name"} required="" onChange={(e) => {
                                        const { name, value } = e.target
                                    }} />
                                </span>
                            </div>
                        </div>

                        <div className="d-flex flex-row bd-highlight justify-content-sm-between border-bottom mb-2">
                            <h4 className="text-success">Permission</h4>
                        </div>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Modul</th>
                                    <th scope="col">Create</th>
                                    <th scope="col">Read</th>
                                    <th scope="col">Update</th>
                                    <th scope="col">Delete</th>

                                </tr>
                            </thead>
                            <tbody>
                                {["Configuration", "Produk", "Kategori Produk", "Layanan", "Reservasi Layanan", "Konsultasi", "Online Slot", "Jadwal Konsultasi", "Reservasi Konsultasi,", "Pesanan", "Pengembalian", "Bank Logs", "Artikel", "Reward Point", "Point logs", "Pengguna", "Role"].map((modul, idx) =>
                                    <tr key={idx}>
                                        <th scope="row">{modul}</th>
                                        <td><input className="form-control" type="checkbox" onChange={(e) => { }} /></td>
                                        <td><input className="form-control" type="checkbox" onChange={(e) => { }} /></td>
                                        <td><input className="form-control" type="checkbox" onChange={(e) => { }} /></td>
                                        <td><input className="form-control" type="checkbox" onChange={(e) => { }} /></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>



                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let _dokter = { ...data.roles[idxDokter] }
                            let _dokul_dokter_konsultasis = [..._dokter.dokul_dokter_konsultasis]
                            let _currentKonsultasi = { ..._dokul_dokter_konsultasis[idxKlinik] }
                            let currentJadwals = [..._currentKonsultasi.dokul_dokter_jadwals]

                            currentJadwals.push({
                                id_konsultasi: _currentKonsultasi.id,
                                name: jadwalBaru.name,
                                tanggal: moment(tgl).format("YYYY/MM/DD"),
                                jumlah_sesi: jadwalBaru.jumlah_sesi,
                                terpakai: 0,

                            })

                            ///_currentKonsultasi.dokul_dokter_jadwals=currentJadwals
                            // _dokul_dokter_konsultasis[idxKlinik]=_currentKonsultasi
                            _dokter['dokul_dokter_jadwal'] = currentJadwals
                            updateDokter({ variables: { id: 1, type: _dokter }, })


                            setModalOpen(false)
                        }}>Simpan</button>
                    </div>

                </div>

            </Modal>
            <Modal isOpen={modalOpenChangeRole} toggle={() => setModalOpenChangeRole(false)} centered size={"sm"} >

                <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Change role - {selectedUser.first_name}</h5>

                    </div>
                    <div className="modal-body">
                        <div className="form-group row m-2">


                            {data.roles.filter(x=>{
                                if(adminRoleId.some(x => user.dokul_user_roles.some(dur => dur.role_id == x))) return true
                                else{
                                    return [6,7,9,1].some(x2=>x2==x.id)
                                }
                            }).map((role, idx) =>
                                <div key={idx} className="form-check">
                                    <input className="form-check-input" value={role.id} type="checkbox" name={`role_selected${idx}`} id="role_selected" checked={selectedUser.dokul_user.dokul_user_roles.some(dur => dur.role_id == role.id)} onChange={(e) => {
                                        let cu = { ...selectedUser }
                                        let du = { ...cu.dokul_user }
                                        let _userRoles = [...du.dokul_user_roles]
                                        if (e.target.checked) {
                                            //cu.role_id = e.target.value
                                            _userRoles.push({
                                                email: selectedUser.dokul_user.email,
                                                id: `${selectedUser.dokul_user.email}${e.target.value}`,
                                                role_id: e.target.value,
                                                user_id: selectedUser.dokul_user.id,
                                                dokul_user_role: role
                                            })

                                        } else {
                                            const idxcu = _userRoles.findIndex(x => x.role_id == e.target.value)
                                            _userRoles.splice(idxcu, 1)

                                        }
                                        du.dokul_user_roles = _userRoles
                                        cu.dokul_user = du
                                        setselectedUser(cu)

                                    }} />
                                    <label className="form-check-label" htmlFor={`role_selected${idx}`}>
                                        {role.role_name}
                                    </label>
                                </div>
                            )}

                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary btn-sm" onClick={() => {
                            props.refetch({
                                variables: { data: selectedUser.dokul_user.dokul_user_roles }
                            })
                            let _users = { ...users }
                            let curentUsers = [..._users.users]


                            const idx = curentUsers.findIndex(x => x.id == selectedUser.id)
                            curentUsers = curentUsers.splice(idx, 1)
                            _users.users = curentUsers

                            setuserDetail(selectedUser.dokul_user)

                            setModalOpenChangeRole(false)
                            // postData(`${process.env.REACT_APP_API_HOST}/api/users/sync/role`, [selectedUser])
                        }}>Submit</button>
                        <button className="btn btn-secondary btn-sm " onClick={() => setModalOpenChangeRole(false)}>Cancel</button>

                    </div>

                </div>

            </Modal>

            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} centered size={"sm"} >


                <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Import data user</h5>

                    </div>
                    <div className="modal-body">
                        <div className="form-group row">

                            <div className="col-md-7">
                                {data.roles.map((role, idx) => {
                                    const panjangx = listDataUpload.filter(x => x.role_id == role.id).length
                                    if (panjangx > 0) return <div className="d-flex flex-row">
                                        <div className="p-2"><h4>{role.role_name}</h4></div>
                                        <div className="p-2"> = </div>
                                        <div className="p-2"><h4>{panjangx}</h4></div>
                                    </div>
                                    else return ""


                                }
                                )}

                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary btn-sm" onClick={() => {
                            postData(`${process.env.REACT_APP_API_HOST}/api/users/sync/role`, listDataUpload)
                        }}>Import</button>
                        <button className="btn btn-secondary btn-sm " onClick={() => setModalOpen(false)}>Batalkan</button>
                    </div>

                </div>

            </Modal>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}


export default Roles

