import React, { useState, Fragment, useEffect } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import PointList from './point.list';
import PointAdd from './point'
import PointEdit from './point.edit'
import PointLogs from './point.logs'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import PointOrder from './point.order';
import { GET_PRODUCTS_SIMPLE } from '../../constants/graph';

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;


const GET_PRODUCTS = gql`query points(
    $page: String!
    $size: String!
    $query:String!
)  {
   
    points @rest(type: "Point",path: "api/poin") {
        id,
        name,
        status,
        multipier_by,
        earned_type,
        earned,
        dokul_point_rules,
        dokul_products,
        dokul_tags_categories
        dokul_point_grades
    }
    
    categories @rest(type: "Category",path: "api/products/categories") {
        id,
        name,
        description
    }
    products @rest(type: "Product",path: "api/products") {
        name,
            dokul_products_skus
            branchId,
            dokul_tags_categories,
            dokul_product_images
            tags
            id
            description
    }
  }
`;
const GET_POINT_LOGS = gql`query points(
    $page: String!
    $size: String!
    $query:String!
)  {
    pointlogs(page:$page,size: $size,query:$query) @rest(type: "PointLogs",path: "api/poin/logs?size={args.size}&page={args.page}&{args.query}") {
        PointLogs @type(name: "PointLog") {
            id
            user_id
            user_wcid
            created_date
            expiry_date
            log_type
            type_value
            point
            before_point
            after_point
            create_by
            branch_id
            order_id
        } ,
        currentPage
        totalItems
        totalPages
    }
  }
`;
const GET_CATEGORIES = gql`query categories {
    categories @rest(type: "Category",path: "api/products/categories") {
        id,
        name,
        description
    }
  }
`;
const GET_ORDER_POINT = gql`query orderPoin(
    $page: String!
    $size: String!
    $query:String!
)  {
    orderPoin(page:$page,size: $size,query:$query)  @rest(type: "OrderPoin",path: "api/poin/synclog?size={args.size}&page={args.page}&{args.query}") {
        orders @type(name: "OrdersPoints") {
        admin_fee
alamat_kirim
billing_address_id
branch_id
bukti_bayar
coupon_discount
coupon_type
coupon_used
created_date
current_grade
dokul_orders_items
email
grand_total
id
is_usecoupon
is_usepoint
layanan_kurir
modify_by
modify_date
nomor_cetak
nomor_resi
ongkir
order_notes
order_status
order_status_text
payment_method
payment_method_text
payment_status
point_earned
point_used
shipping_address_id
total
total_discount
transaction_id
unix_payment
user_id
user_wcid
wcid
dokul_point_logs
} ,
currentPage
totalItems
totalPages
}
    
  }
`;
const GET_TAGS = gql`query tags {
    tags @rest(type: "Tag",path: "api/products/tags") {
        id,
        name,
        description
    }
  }
`;
const SAVE_POINT = gql`
mutation savePoint(
  $data: any
) {
    savePoint(body: $data)
  @rest(
    type: "Post"
    path: "api/poin"
    method: "POST"
    bodyKey: "body"
  ) {
  completed
}
}
`;
const Points = ({ match }) => {
    const [selectedBranch, setselectedBranch] = useState(1)
    useEffect(() => {
        fetchMore({
            variables: {
                page: 0,
                size: 10,
                query: 'branch_id='+selectedBranch

            }
        })
    }, [selectedBranch])
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    //const params = useParams();

    if (match.params.page) {
    }
    const [savePoint, { loading: mutationCategoryLoading, error: mutationCategoryError, called: mutationCategoryCalled },] = useMutation(SAVE_POINT,);
    const { loading, data, error, fetchMore } = useQuery(GET_PRODUCTS_SIMPLE, {
        variables: {
            brand:'all',
            textSearch:'all'
        }
    });
    const { loading:lPoinLogs, data:dPoinLogs, error:ePoinLogs, fetchMore:fmPoinLogs } = useQuery(GET_POINT_LOGS, {
        variables: {
            page: 0,
            size: 10,
            query: 'branch_id='+selectedBranch
        }
    });
    const { loading:lOrderPoin, data:dOrderPoin, error:eOrderPoin, fetchMore:fmOrderPoin } = useQuery(GET_ORDER_POINT, {
        variables: {
            page: 0,
            size: 10,
            query: 'branch_id='+selectedBranch
        }
    });
    
   
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    if (error) {
        console.log(error);

        // Cookies.remove('signedin','/');
        // Cookies.remove('jwt');



        // history.push('/');
        //return <div>Error!</div>;
    }

    const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    let point;
    if (match.params.page == 'edit') {
        point = client.readFragment({
            id: `Point:${match.params.id}`, // `id` is any id that could be returned by `dataIdFromObject`.
            fragment: gql`
          fragment editPoint on Point{
            id
            name
            status,
        multipier_by,
        earned_type,
        earned,
        dokul_point_rules,
        dokul_products,
        dokul_tags_categories
        dokul_point_grades
          }
        `,
        }, false);
    }
    const getNextData = (cpagesize, rpage, query) => {

        //     const UPDATE_MUTATION = gql`
        //     mutation updateMutation(
        //       $data: any
        //       $id:ID!
        //     ) {
        //       login(id:$id,body: $data)
        //       @rest(
        //         type: "Post"
        //         path: "api/products/{args.id}"
        //         method: "PUT"
        //         bodyKey: "body"
        //       ) {
        //       completed
        //     }
        //     }
        //   `;

        //    client.writeQuery({
        //     GET_CATEGORIES,
        //         data: {
        //           orders: {
        //             orders: [],
        //           },
        //         },
        //       });
        // orders().then(data=>{
        //     console.log(data)
        // })
        return fmPoinLogs({
            variables: {
                page: rpage,
                size: cpagesize,
                query: query

            }
        })
        //   .then(dt=>{
        //       console.log(dt)
        //     return dt.data
        //   });
        //       const { orders,error } = client.readQuery({
        //         query: GET_ORDERS,
        //         variables: {
        //           page:rpage,
        //           size: 10

        //   }
        //       });
        //       if(error)
        //         return
        //return orders
    }
    const getNextData2 = (cpagesize, rpage, query) => {

        //     const UPDATE_MUTATION = gql`
        //     mutation updateMutation(
        //       $data: any
        //       $id:ID!
        //     ) {
        //       login(id:$id,body: $data)
        //       @rest(
        //         type: "Post"
        //         path: "api/products/{args.id}"
        //         method: "PUT"
        //         bodyKey: "body"
        //       ) {
        //       completed
        //     }
        //     }
        //   `;

        //    client.writeQuery({
        //     GET_CATEGORIES,
        //         data: {
        //           orders: {
        //             orders: [],
        //           },
        //         },
        //       });
        // orders().then(data=>{
        //     console.log(data)
        // })
        return fmOrderPoin({
            variables: {
                page: rpage,
                size: cpagesize,
                query: query

            }
        })
        //   .then(dt=>{
        //       console.log(dt)
        //     return dt.data
        //   });
        //       const { orders,error } = client.readQuery({
        //         query: GET_ORDERS,
        //         variables: {
        //           page:rpage,
        //           size: 10

        //   }
        //       });
        //       if(error)
        //         return
        //return orders
    }


    //daricahce
    // const { products } = client.readQuery({
    //     query: gql`
    //       query ReadProducts {
    //         products {
    //           id
    //         }
    //       }
    //     `,
    //   },false);

    // const todo = client.readFragment({
    //     id: 1, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment myTodo on Product {
    //         id
    //         name
    //       }
    //     `,
    // });
    //console.log(data);
    // setData(datanya)

    return (
        <Fragment>
            {loading || lOrderPoin ? <div>Loading</div> : <>
                <Breadcrumb title="DokPoin" parent="Point" />
                {/* <div className="d-flex flex-row mb-5">
          <div className="p-2">Dokterkulitku : </div>
          <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(1)}>JAKARTA</div>
          <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() => setselectedBranch(2)}>BALIKPAPAN</div>
        </div> */}
                {
                    match.params.page == 'detail' ?
                            <PointList />
                            : match.params.page == 'edit' ?
                                <PointEdit data={data} id={match.params.id} point={point} savePoint={savePoint} />
                                : match.params.page == 'add' ?
                                    <PointAdd data={data} savePoint={savePoint} />
                                    : match.params.page == 'log' ?
                                        <PointLogs data={data} getNextData={getNextData} />
                                        :
                                        <Tabs>
                                            <TabList className="nav nav-tabs tab-coupon" >
                                                <Tab className="nav-link">Roles</Tab>
                                                <Tab className="nav-link">Logs</Tab>
                                                <Tab className="nav-link">Koreksi</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <PointList listProduct={data.productssimple} selectedBranch={selectedBranch} />
                                            </TabPanel>
                                            <TabPanel>
                                            <PointLogs data={dPoinLogs} getNextData={getNextData} selectedBranch={selectedBranch} />
                                            </TabPanel>
                                            <TabPanel>
                                            <PointOrder data={dOrderPoin} getNextData={getNextData2} selectedBranch={selectedBranch} />
                                            </TabPanel>
                                            
                                        </Tabs>
                }
            </>
            }

        </Fragment>
    )

}

export default withApollo(Points)
