import React , { Component,Fragment,useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject,NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
import {GET_CATEGORIES,GET_TAGS,GET_DASHBOARD_DATA,GET_ORDERS, GET_SALES_YEAR} from '../../constants/graph'
import { Chart } from "react-google-charts";
import Loading from '../common/loading'
import Errorpage from '../common/Errorpage'

const Salesyear = ({ props }) => {
    const LineOptions1 = {
        hAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        vAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        colors: ['#13c9ca'],
        chartArea: { left: 0, top: 0, width: '100%', height: '100%' },
        legend: 'none',
    }
    //const [data,setData]=useState(props.data)
   const { loading, data, error,fetchMore , refetch, networkStatus} = useQuery(GET_SALES_YEAR);
   if (loading) return <Loading />;
    if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    return (
        <div className="card order-graph sales-carousel">
        <div className="card-header">
            <h6>Sales Last Year</h6>
            <div className="row">
                <div className="col-12">
                    <div className="small-chartjs">
                        <div className="flot-chart-placeholder" id="simple-line-chart-sparkline">
                            <Chart
                                height={'60px'}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={data.salesLastYar.data}
                                options={LineOptions1}
                            //legend_toggle
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="col-6">
                        <div className="value-graph">
                            <h3>20% <span><i className="fa fa-angle-up font-secondary"></i></span></h3>
                        </div>
                    </div> */}
            </div>
        </div>
        <div className="card-body">
            <div className="media">
                <div className="media-body">
                    <span>Last Year Sales</span>
                    <h2 className="mb-0">{data.salesLastYar.total}</h2>
                    {/* <p>{data.salesLastYar.percent} <span><i className="fa fa-angle-up"></i></span></p> */}
                </div>
                <div className="bg-secondary b-r-8">
                    <div className="small-box">
                        <CreditCard />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default withApollo(Salesyear)
//export default ordertoday
