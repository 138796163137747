import React , { Component,Fragment,useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject,NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
import {GET_CATEGORIES,GET_TAGS,GET_DASHBOARD_DATA,GET_ORDERS, GET_7LAST_ORDER, GET_ORDER_STATUS} from '../../constants/graph'
import { Pie, Doughnut, Bar, Line } from 'react-chartjs-2';
import {
    pieOptions,
    doughnutOption,
    lineOptions,
    buyOption,
    employeeData,
    employeeOptions
} from '../../constants/chartData'
import Loading from '../common/loading'
import Errorpage from '../common/Errorpage'
const Statisticorder = ({ props }) => {
    //const [data,setData]=useState(props.data)
   const { loading, data, error,fetchMore , refetch, networkStatus} = useQuery(GET_ORDER_STATUS);
   if (loading) return <Loading />;
   if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    return (
        <div className="col-xl-6 xl-100">
                                        <div className="order-graph xl-space">
                                            <h6>Orders statistic</h6>
                                            <div className="ct-4 flot-chart-container">
                                                <Line data={{
                                                    labels: data.orderstatus.label.map(lbl => lbl.hari),
                                                    datasets: [
                                                        {
                                                            lagend: 'none',
                                                            data: data.orderstatus.label.map(lbl => lbl.jumlah),
                                                            borderColor: '#ff8084',
                                                            backgroundColor: 'rgba(255, 128, 132, 0.1)',
                                                            fill: 'origin',
                                                        }
                                                    ]
                                                }} options={employeeOptions} />
                                            </div>
                                        </div>
                                    </div>
    )
}
export default withApollo(Statisticorder)
//export default ordertoday
