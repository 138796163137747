import React from 'react'
import * as FileSaver from "file-saver";

import moment from 'moment';
// import * as XLSX from "xlsx";
import XLSX from "xlsx-js-style"
export const ExportToExcel = ({ apiData, fileName, tipe, configData }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData_, fileName) => {
        if (tipe == 4) {
            const colWidths = [
                { wch: 15 },
                { wch: 30 },
                { wch: 40 },
                { wch: 60 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 }
            ]
            var aoa = [
                ["TANGGAL", "Nama", "Alamat", "ITEMS", "TOTAL", "ONGKIR", "ADMIN", "KODE UNIK", "Total discount", "GRAND TOTAL", "Transfer To"]
            ];
            console.log(configData);
            let aoi = apiData.reduce((res, x) => {
                const payMethod = configData.payMethod?.find(px => px.id == x.payment_method)
                const xenditAccount = configData.payment?.find(px => px.id == payMethod.pay_id)
                let _alamatKirim = x.alamat_kirim.replaceAll("Kabupaten", "Kab").replaceAll("Kelurahan", "Kel").replaceAll("Kecamatan", "Kec").replaceAll("kabupaten", "Kab").replaceAll("kelurahan", "Kel").replaceAll("kecamatan", "Kec").split('\n')
                if (!_alamatKirim[2]) _alamatKirim = x.alamat_kirim.replaceAll("Kabupaten", "Kab").replaceAll("Kelurahan", "Kel").replaceAll("Kecamatan", "Kec").replaceAll("kabupaten", "Kab").replaceAll("kelurahan", "Kel").replaceAll("kecamatan", "Kec").split('/n')
                // const _nama=_alamatKirim[0].split(' ')
                // let addr=x.dokul_address!=null?x.dokul_address:{}
                // let _phone=x.dokul_address&&x.dokul_address!=null&&x.dokul_address.phone!="" && 
                // x.dokul_address.phone!=undefined 
                // ? x.dokul_address.phone
                // :x.dokul_user.phone
                // res.push([

                //     x.dokul_user.display_name,
                //     _alamatKirim[2] + " " + _alamatKirim[3] + " " + _alamatKirim[4] + " " + _alamatKirim[5],
                //     "PRODUK nama", "PRODUK harga", "jumlah", "subtotal",
                //     x.total,
                //     x.ongkir,
                //     x.admin_fee,
                //     x.unix_payment

                // ])
                // { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
                // { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
                // { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: "E9E9E9" } } } },
                // { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } }
                let items = x.dokul_orders_items.reduce((resp, xp) => {

                    return resp.concat(`${xp.product_name}  ( ${xp.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} X ${xp.quaintiy} ) =   ${xp.subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} \n`);
                }, "")


                res.push([
                    moment(x.created_date).format('DD-MM-YYYY HH:mm'),
                    x.dokul_user.display_name,
                    { v: _alamatKirim[2] + " \n" + _alamatKirim[3] + " \n" + _alamatKirim[4] + "\n " + _alamatKirim[5], t: "s", s: { alignment: { wrapText: true } }, wpx: 300 },
                    { v: items, t: "s", s: { alignment: { wrapText: true } }, wpx: 300 },
                    x.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    x.ongkir.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    x.admin_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    x.unix_payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    x.coupon_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    x.grand_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    xenditAccount?.pay_name
                ])
                return res;
            }, [...aoa])

            var ws = XLSX.utils.aoa_to_sheet(aoi);
            ws['!cols'] = colWidths
            // const COL_WIDTH = 400;

            // /* Excel column "C" -> SheetJS column index 2 == XLSX.utils.decode_col("C") */
            // var COL_INDEX = 2;

            // /* create !cols array if it does not exist */
            // if(!ws["!cols"]) ws["!cols"] = [];

            // /* create column metadata object if it does not exist */
            // if(!ws["!cols"][COL_INDEX]) ws["!cols"][COL_INDEX] = {wch: 8};
            // if(!ws["!cols"][1]) ws["!cols"][1] = {wch: 8};
            // if(!ws["!cols"][0]) ws["!cols"][0] = {wch: 8};

            // /* set column width */
            // ws["!cols"][COL_INDEX].wpx = COL_WIDTH;
            // ws["!cols"][1].wpx = 400;
            // ws["!cols"][0].wpx = 200;
            /* create workbook and export */
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // const ws = XLSX.utils.json_to_sheet(apiData);
            // ws.Range["D2"].CellStyle.WrapText = true;
            // const range= XLSX.utils.decode_range("D1:D2")
            // const cellRef = XLSX.utils.encode_cell({ r: 2, c: 3 });
            // console.log(ws);
            // // ws["!merges"] = [
            // //     XLSX.utils.decode_range("D1:D2")
            // //   ];
            // cellRef
            // ws[cellRef].s = {
            //     alignment: { horizontal: "center" },
            //     font: { bold: true }
            //   };
            // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            // let rows = getRows(wb.Sheets, rowIndex = 3)
            // let cell_b_2 = getCells(rows, colIndex = 2)[[1]]

            // let cs = CellStyle(wb, alignment = Alignment(wrapText = TRUE))

            // setCellStyle(cell_b_2, cs) 
            // IWorksheet worksheet = workbook.Worksheets[0];


            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array", cellStyles: true, });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }else{
            const ws = XLSX.utils.json_to_sheet(apiData_);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array", cellStyles: true, });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };


    return (
        <button className="btn btn-success btn-xs m-1" onClick={(e) => {
            let _apiData = []

            if (tipe == 3)
                _apiData = apiData.map(x => {
                    return {
                        "ID": x.id,
                        "Nama Depan": x.dokul_address.first_name,
                        "Nama Belakang": x.dokul_address.last_name,
                        "Kurir Kirim": x.layanan_kurir,
                        "Nomor HP": x.dokul_address.phone,
                        "Alamat Kirim": x.dokul_address.address_1 + " " + x.dokul_address.address_2 + " " + x.dokul_address.address_city + " " + x.dokul_address.address_state + " " + x.dokul_address.address_postcode,
                        "SKU": x.dokul_orders_items.reduce((prev, curr, currentIndex) => {
                            const islast = (x.dokul_orders_items.length - 1) == currentIndex ? "#" : ""
                            if (prev != "") {

                                return prev + "," + `${curr.sku_name}(${curr.quaintiy})${islast}`
                            }
                            else {

                                return `${curr.sku_name}(${curr.quaintiy})${islast}`
                            }

                        }, ""),
                        "Notes": x.order_notes,
                    }
                })

            if (tipe == 1)
                _apiData = apiData.map((x, _index) => {
                    let _alamatKirim = x.alamat_kirim.replaceAll("Kabupaten", "Kab").replaceAll("Kelurahan", "Kel").replaceAll("Kecamatan", "Kec").replaceAll("kabupaten", "Kab").replaceAll("kelurahan", "Kel").replaceAll("kecamatan", "Kec").split('\n')
                    if (!_alamatKirim[2]) _alamatKirim = x.alamat_kirim.replaceAll("Kabupaten", "Kab").replaceAll("Kelurahan", "Kel").replaceAll("Kecamatan", "Kec").replaceAll("kabupaten", "Kab").replaceAll("kelurahan", "Kel").replaceAll("kecamatan", "Kec").split('/n')
                    const _nama = _alamatKirim[0].split(' ')
                    let addr = x.dokul_address != null ? x.dokul_address : {}
                    let _phone = x.dokul_address && x.dokul_address != null && x.dokul_address.phone != "" &&
                        x.dokul_address.phone != undefined
                        ? x.dokul_address.phone
                        : x.dokul_user.phone
                    const urutan = _index + 1
                    const stringId = x.id.toString()
                    return {
                        "ID": x.id,
                        "Nama Depan": _nama[0],//x.dokul_address.first_name,
                        "Nama Belakang": _nama.length > 1 ? `${_nama[1]}` : ``,//x.dokul_address.last_name,
                        "No Urut": stringId.substring(stringId.length - 4, stringId),
                        "Kurir Kirim": x.layanan_kurir,
                        "Nomor HP": _alamatKirim[1],//_phone,
                        // "Alamat Kirim":addr.address_1 +" "+addr.address_city+" "+ addr.address_state +" "+addr.address_postcode ,
                        "Alamat Kirim": _alamatKirim[2] + " " + _alamatKirim[3] + " " + _alamatKirim[4] + " " + _alamatKirim[5],
                        "SKU": x.all_item.reduce((prev, curr, currentIndex) => {
                            const islast = (x.all_item.length - 1) == currentIndex ? "#" : ""
                            const namaProduk = curr.sku_name.includes("pw-") || curr.sku_name.includes("pk-") ? `${curr.product_name} ${curr.sku_name.toUpperCase()}` : curr.sku_name.toUpperCase()
                            if (prev != "") {

                                return prev + "," + `${namaProduk}(${curr.quaintiy})${islast}`
                            }
                            else {

                                return `${namaProduk}(${curr.quaintiy})${islast}`
                            }

                        }, ""),
                        "Notes": x.order_notes,
                    }
                })

            if (tipe == 2) {
                let result = []
                let allItems = []
                let skus = []

                let totalOrder = 0
                let totalOngkir = 0
                let totalPoin = 0
                let totalVoucher = 0
                let totalUnix = 0
                let totalAdmin = 0
                apiData.forEach(x => {
                    totalUnix += x.unix_payment
                    totalAdmin += x.admin_fee
                    totalOrder += x.total
                    totalOngkir += x.ongkir
                    totalPoin += x.point_used
                    totalVoucher += x.coupon_discount
                    allItems = allItems.concat(x.dokul_orders_items)

                })
                let group = allItems.reduce((r, a) => {
                    // console.log("a", a);
                    // console.log('r', r);
                    r[a.sku_name] = [...r[a.sku_name] || [], a];
                    return r;
                }, {});

                for (const key in group) {

                    _apiData.push({
                        "SKU": key,
                        "TOTAL QTY": group[key].reduce((prev, curr) => {
                            if (prev == 0)
                                return curr.quaintiy
                            else
                                return prev + curr.quaintiy

                        }, 0),
                        "TOTAL PRICE": group[key].reduce((prev, curr) => {
                            if (prev == 0)
                                return curr.subtotal
                            else
                                return prev + curr.subtotal

                        }, 0)
                    })

                }
                _apiData.push({
                    "SKU": "",
                    "TOTAL QTY": "",
                    "TOTAL PRICE": ""
                })
                _apiData.push({
                    "SKU": "TOTAL ORDER : ",
                    "TOTAL QTY": totalOrder,
                    "TOTAL PRICE": ""
                })
                _apiData.push({
                    "SKU": "TOTAL ONGKIR : ",
                    "TOTAL QTY": totalOngkir,
                    "TOTAL PRICE": ""
                })
                _apiData.push({
                    "SKU": "TOTAL POIN : ",
                    "TOTAL QTY": totalPoin,
                    "TOTAL PRICE": ""
                })
                _apiData.push({
                    "SKU": "TOTAL VOUCHER : ",
                    "TOTAL QTY": totalVoucher,
                    "TOTAL PRICE": ""
                })
                _apiData.push({
                    "SKU": "TOTAL ADMIN: ",
                    "TOTAL QTY": totalAdmin,
                    "TOTAL PRICE": ""
                })
                _apiData.push({
                    "SKU": "TOTAL UNIX : ",
                    "TOTAL QTY": totalUnix,
                    "TOTAL PRICE": ""
                })



            }
            // if (tipe == 4)
            //     _apiData = apiData.map((x, _index) => {
            //         let _alamatKirim = x.alamat_kirim.replaceAll("Kabupaten", "Kab").replaceAll("Kelurahan", "Kel").replaceAll("Kecamatan", "Kec").replaceAll("kabupaten", "Kab").replaceAll("kelurahan", "Kel").replaceAll("kecamatan", "Kec").split('\n')
            //         if (!_alamatKirim[2]) _alamatKirim = x.alamat_kirim.replaceAll("Kabupaten", "Kab").replaceAll("Kelurahan", "Kel").replaceAll("Kecamatan", "Kec").replaceAll("kabupaten", "Kab").replaceAll("kelurahan", "Kel").replaceAll("kecamatan", "Kec").split('/n')
            //         const _nama = _alamatKirim[0].split(' ')
            //         let addr = x.dokul_address != null ? x.dokul_address : {}
            //         let _phone = x.dokul_address && x.dokul_address != null && x.dokul_address.phone != "" &&
            //             x.dokul_address.phone != undefined
            //             ? x.dokul_address.phone
            //             : x.dokul_user.phone
            //         const urutan = _index + 1
            //         const stringId = x.id.toString()
            //         //Nama, alamat, Produk, sub total, ongkir, admin, kode unik, totalan, transfer ke rek mana, tgl
            //         return {
            //             "ID": x.id,
            //             "Nama Depan": x.dokul_user.display_name,//x.dokul_address.first_name,
            //             "Alamat Kirim": _alamatKirim[2] + " " + _alamatKirim[3] + " " + _alamatKirim[4] + " " + _alamatKirim[5],
            //             "Produk name": "dsdjsad \r\n dsadasd",
            //             "Produk price": "dsdjsad \r\n dsadasd",
            //             "Produk qty": "dsdjsad \r\n dsadasd",
            //             "Produk subtot": "dsdjsad \r\n dsadasd",
            //             "Sub total": x.total,
            //             "Ongkir": x.ongkir,//_phone,
            //             "Admin": x.admin_fee,//_phone,
            //             "Kode Unik": x.unix_payment,//_phone,
            //             "Total discount": x.coupon_discount,//_phone,
            //             "Grand total": x.grand_total,//_phone,
            //             "Transfer To": x.payment_method,//_phone,
            //             // "SKU": [{'dasda':'hbjh'}]
            //             // x.all_item.reduce((prev, curr,currentIndex) => {
            //             //     const islast=(x.all_item.length-1)==currentIndex?"#":""
            //             //     const namaProduk=curr.sku_name.includes("pw-")||curr.sku_name.includes("pk-")?`${curr.product_name} ${curr.sku_name.toUpperCase()}`:curr.sku_name.toUpperCase()
            //             //     if (prev != ""){

            //             //         return prev + "," + `${namaProduk}(${curr.quaintiy})${islast}`
            //             //     }
            //             //     else{

            //             //         return `${namaProduk}(${curr.quaintiy})${islast}`
            //             //     }

            //             // }, ""),
            //             // "Notes": x.order_notes,
            //         }
            //     })

            // _apiData.forEach(x=>{
            //     delete x.__typename
            //     delete x.id
            //     delete x.admin_fee
            //     delete x.billing_address_id
            //     delete x.branch_id
            //     delete x.coupon_discount
            //     delete x.coupon_type
            //     delete x.coupon_used

            // })
            exportToCSV(_apiData, fileName)

        }}><i className="fa fa-file-excel-o m-1" />{tipe == 1 ? "Export Order" : tipe == 2 ? "Export summary" :tipe == 4 ?"DOWNLOAD REPORT": "Export rekap"}</button>
    );
};