import React, { Component, Fragment, useState } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Edit, Trash2 } from 'react-feather'
import data from '../../../assets/data/category';
import Datatable from '../../common/datatable';
import { ToastContainer, toast } from 'react-toastify';
import { withApollo } from '../../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { GET_GUDANGS } from '../../../constants/graph';
const ServiceThumb = (props) => {
    // const { loading, data, error } = useQuery(GET_GUDANGS,);
    // const [selectedGudang, setselectedGudang] = useState(null)
   
    const myData = props.layanan
    // if(!data)return <div>Loading</div>
    return (
       
            <div className="col-xl-3 col-sm-6" >
                <div className="">
                    <div className="products-admin">
                        <div className="card-body product-box">
                            <div className="img-wrapper">
                                <div className="lable-block">
                                    {(myData.tag === 'new') ? <span className="lable3">{myData.tag}</span> : ''}
                                    {(myData.discount === 'on sale') ? <span className="lable4">{myData.discount}</span> : ''}
                                </div>
                                <div className="front">
                                    <a className="bg-size"><img className="img-fluid blur-up bg-img lazyloaded" src={`${process.env.REACT_APP_IMAGE_HOST}${myData.picture}`} /></a>
                                    <div className="product-hover">
                                        <ul>
                                            <li>
                                                <Link
                                                    to={`/services/edit/${myData.id}`}
                                                    className='btn'>
                                                    <Edit className="editBtn" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="product-detail">
                                <h6 className="text-center p-2">{myData.name}</h6>
                                {/* <div className="rating">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div> */}
                                {/* <a> <h6 >{myData.name}</h6></a> */}
                                {/* <hr />
                                                        <div className="d-flex flex-column">
                                                            {myData.dokul_products_skus.map((sku, i) =>
                                                                <div className="p-2 text-center">{sku.name}</div>

                                                            )}

                                                        </div> */}
                                {/* <ul className="ukuran-variant">
                                                            {myData.dokul_products_skus.map((sku, i) =>
                                                                <li key={i} ><span className="p-2 rounded bg-light0">{sku.name}</span></li>
                                                            )}

                                                        </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    )
}
// }


export default withApollo(ServiceThumb)

