import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery, writeQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject, NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../../apollo'
import { GET_CATEGORIES, GET_TAGS, GET_DASHBOARD_DATA, GET_ORDERS, GET_7LAST_ORDER, GET_ORDER_STATUS, GET_ORDER_STATUSOK, GET_PRODUCTS, GET_KONSUL_REPORT, GET_CONFIGS_KONSUL_ANSTEMPLATE, UPDATE_TEMPLATE_JAWABAN_KONSULTASI, ADD_TEMPLATE_JAWABAN_KONSULTASI, DELETE_TEMPLATE_JAWABAN_KONSULTASI, GET_LIST_KONSULTASI } from '../../../constants/graph'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';
import { Pie, Doughnut, Bar, Line } from 'react-chartjs-2';
import {
    pieOptions,
    doughnutOption,
    lineOptions,
    buyOption,
    employeeData,
    employeeOptions
} from '../../../constants/chartData'
import Loading from '../../common/loading'
import Errorpage from '../../common/Errorpage'
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import id from 'date-fns/locale/id';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Badge, Col, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
registerLocale('id', id)
setDefaultLocale('id');
const ckconfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    // PluginArray:[Emoji,
    //     EmojiPeople,
    //     EmojiNature,
    //     EmojiPlaces,
    //     EmojiFood,
    //     EmojiActivity,
    //     EmojiObjects,
    //     EmojiSymbols,
    //     EmojiFlags,]

};
const TemplateJawaban = (props) => {
    //const [data,setData]=useState(props.data)
    const { loading, data, error } = useQuery(GET_LIST_KONSULTASI,);
    const { loading: lAnsTemplate, data: dAnsTemplate, error: eAnsTemplate } = useQuery(GET_CONFIGS_KONSUL_ANSTEMPLATE);
    const [updateTemplate, { loading: updateLoading }] = useMutation(UPDATE_TEMPLATE_JAWABAN_KONSULTASI, {
        onCompleted: (data) => {
            setmodalProduk(false)
        }
    })
    const [addTemplate, { loading: addLoading }] = useMutation(ADD_TEMPLATE_JAWABAN_KONSULTASI, {
        onCompleted: (data) => {
            setmodalProduk(false)
        }
    })
    const [deleteTemplate, { loading: deleteLoading }] = useMutation(DELETE_TEMPLATE_JAWABAN_KONSULTASI, {
        onCompleted: (data) => {


        }
    })
    const [selectedTemplate, setselectedTemplate] = useState({})
    const [newTemplate, setnewTemplate] = useState()
    const [saveEnable, setsaveEnable] = useState(false)
    const [modalProduk, setmodalProduk] = useState(false)
    const [content, setcontent] = useState()
    const [newcontent, setnewcontent] = useState()
    useEffect(() => {

        if (selectedTemplate.text) {
            setcontent(selectedTemplate.text)
        }
    }, [selectedTemplate])

    if (lAnsTemplate || loading) return <Loading />;
    if (eAnsTemplate || error) return <Errorpage errorCode={500} errorMessage={`${eAnsTemplate}`} />

    return (
        <Row>

            <Col md="3">

                <ListGroup>
                    <ListGroupItem color={"light"}>
                        <div className='d-flex flex-row'>
                            <div className='p-2'><h4>List template</h4></div>
                        </div>


                        {/* <p dangerouslySetInnerHTML={{ __html: x.dokul_robot_question ? replaceTagsUser(x.dokul_robot_question.pertanyaan) : "Tujuan kontrol" }} /> */}
                    </ListGroupItem>
                    {dAnsTemplate.ansTemplate.filter(x => props.selectedBranch == 3 || x.branch_id == props.selectedBranch).map((x, idx) => {
                        return <ListGroupItem key={idx} color={selectedTemplate.id == x.id ? "dark" : "light"} onClick={() => setselectedTemplate(x)}>
                            <div className='d-flex flex-row justify-content-between m-0 '>
                                <div className='p-2'> {x.name}</div>
                                <div className='p-2 text-end'><button className='btn btn-xs bg-red' onClick={() => {
                                    deleteTemplate({
                                        variables: {
                                            id: x.id
                                        }
                                    })
                                }}>X</button></div>
                            </div>



                            {/* <p dangerouslySetInnerHTML={{ __html: x.dokul_robot_question ? replaceTagsUser(x.dokul_robot_question.pertanyaan) : "Tujuan kontrol" }} /> */}
                        </ListGroupItem>
                    })}
                </ListGroup>

            </Col>

            <Col md="9">
                <div className='d-flex flex-row mb-2 bg-white border'>
                    <div className='p-2'><button className='btn btn-sm btn-primary' onClick={() => {
                        setnewcontent()
                        setnewTemplate({})
                        setmodalProduk(true)
                    }}>Add</button></div>
                    <div className='p-2'><button disabled={(content == selectedTemplate.text && !saveEnable)} className='btn btn-sm btn-primary' onClick={() => {
                        updateTemplate({
                            variables: {
                                id: selectedTemplate.id,
                                body: { ...selectedTemplate, text: content }
                            }
                        })
                    }}>Save</button></div>
                </div>
                {selectedTemplate.text && <div className='p-2' >
                    <Label >Untuk Konsultasi</Label>
                    <div className='d-flex flex-row flex-wrap'>
                        {data.konsultasi.filter(x => x.dokul_dokter.branch_id == selectedTemplate.branch_id && (x.type == 2 || x.type == 3) && x.status != 0).map((dt, idt) =>
                            <Badge className={`m-2 p-2 ${dt.dokul_dokter.branch_id == 1 ? 'bg-warning' : 'bg-success'}`}>
                                <input type={"checkbox"} className="mr-2" checked={selectedTemplate.konsul_id.split(",").indexOf(dt.id.toString())!=-1} onChange={(e)=>{
                                    let currentData=selectedTemplate.konsul_id.split(",")
                                    currentData.push(dt.id)
                                    setselectedTemplate(x => { return { ...selectedTemplate, konsul_id: currentData.join() } })
                                    setsaveEnable(true)
                                }} />{dt.name}
                            </Badge>
                        )}
                    </div>

                </div>
                }
                <div className='p-2' >
                    {selectedTemplate.text && <FormGroup >
                        <Label >Index</Label>
                        <Input className='w-25' type="number" value={selectedTemplate.urutan}
                            onChange={(e) => {
                                setselectedTemplate(x => { return { ...selectedTemplate, urutan: e.target.value } })
                                setsaveEnable(true)
                            }} />
                    </FormGroup>}
                </div>

                <div className='p-2' >
                    {selectedTemplate.text ?
                        <FormGroup >
                            <Label >Text</Label><CKEditor
                                editor={ClassicEditor}
                                config={ckconfig}
                                activeclassName="bg-silver"
                                data={content}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setcontent(data)

                                }}

                            /></FormGroup>
                        : "No template selected"}
                </div>
                <Modal isOpen={modalProduk} toggle={() => setmodalProduk(false)} centered size="lg">
                    <ModalHeader className="bg-primary" toggle={() => setmodalProduk(false)}>Add Template</ModalHeader>
                    <ModalBody className="bg-light text-dark">
                        <FormGroup >
                            <Label >Name</Label>
                            <Input type="text" onChange={(e) => {
                                let _currentSelectedProduct = { ...newTemplate }

                                _currentSelectedProduct.name = e.target.value

                                setnewTemplate(_currentSelectedProduct)
                            }} />
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name='templateBranch' onClick={(e) => {
                                    let _currentSelectedProduct = { ...newTemplate }
                                    if (e.target.checked) {
                                        _currentSelectedProduct.branch_id = 1
                                    }
                                    setnewTemplate(_currentSelectedProduct)
                                }} />{' '} Jakarta

                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name='templateBranch' onClick={(e) => {
                                    let _currentSelectedProduct = { ...newTemplate }
                                    if (e.target.checked) {
                                        _currentSelectedProduct.branch_id = 2
                                    }
                                    setnewTemplate(_currentSelectedProduct)
                                }} />{' '} Balikpapan

                            </Label>
                        </FormGroup>
                        <CKEditor
                            editor={ClassicEditor}
                            config={ckconfig}
                            activeclassName="bg-silver"
                            data={newcontent}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setnewcontent(data)

                            }}

                        />
                    </ModalBody>
                    <ModalFooter className='bg-white'>
                        <div className='d-flex flex-row mb-2 bg-white border'>
                            <div className='p-2'><button className='btn btn-sm btn-primary' onClick={() => {
                                setnewcontent()
                                setnewTemplate({})
                                setmodalProduk(false)
                            }}>Cancel</button></div>
                            <div className='p-2'><button disabled={newcontent == newTemplate?.text} className='btn btn-sm btn-primary' onClick={() => {
                                addTemplate({
                                    variables: {
                                        body: { ...newTemplate, text: newcontent }
                                    }
                                })
                            }}>Save</button></div>
                        </div>
                    </ModalFooter>

                </Modal>



            </Col>
        </Row>

    )
}
export default withApollo(TemplateJawaban)
//export default ordertoday
