import React, { useState, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import ArtikelList from './artikel.list';
import ArtikelEdit from './artikel.edit';

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;
const GET_ARTIKELS = gql`query artikels {
  artikels @rest(type: "Artikel",path: "api/artikel") {
        id
        title
        status
        create_date
        banner
        page_id
        create_by
        dokul_page
        short_desc
    }
    
    
  }
`;
const ADD_ARTIKEL = gql`
    mutation updateMutation(
      $data: any
    ) {
      artikel(body: $data)
      @rest(
        type: "Post"
        path: "api/artikel"
        method: "POST"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;
  const ADD_ROWS = gql`
    mutation updateRows(
      $data: any
    ) {
      updateRows(body: $data)
      @rest(
        type: "Post"
        path: "api/konten/rows"
        method: "PUT"
        bodyKey: "body"
      ) {
      completed
    }
    }
  `;

const Artikels = ({ match }) => {
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
    //const params = useParams();

    if (match.params.page) {
        // console.log(match.params.page)
        // console.log(match.params.id)


    }
    const { loading, data, error } = useQuery(GET_ARTIKELS,);
    // const { loading2, categories, error1 } = useQuery(GET_CATEGORIES,);
    // const { loading3, tags, error2 } = useQuery(GET_TAGS,);
    if (error ) {
        console.log(error.message);
        Cookies.remove('signedin','/');
        Cookies.remove('jwt');
        


        history.push('/');
        //return <div>Error!</div>;
    }
   
    const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    let product;
    const [addArtikel,{ loading: mutationLoading, error: mutationError,called:mutationCalled },] = useMutation(ADD_ARTIKEL,);
    const saveArtikel=(service)=>{
      addArtikel({
            variables: {
                data: service
            },
            onCompleted: (saveArtikel ) => {
                console.log("Sukses")
                history.push('/products');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })

    }
    const [addRow,{ loading: mutationCategoryLoading, error: mutationCategoryError,called:mutationCategoryCalled },] = useMutation(ADD_ROWS,);
    const saveRow=(category)=>{
        const t=addRow({
            variables: {
                data: category
            },
            onCompleted: (data) => {
                console.log("Sukses")
                history.push('/products/categories');
            }, onError: ({ graphQLErrors, networkError }) => {
                console.log("ada eror")
                console.log(networkError)
                console.log(graphQLErrors)
            },
        })
        return t

    }


const handleImageUpload = async(file,name) => {
  //const files = event.target.files
  const formData = new FormData()
  formData.append('uploaded_file', file, `${name}_${file.name}`)


  const response= await fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
      method: 'POST',
      body: formData
  })
      .then(response => response.json())
      .then(data => {
          console.log(data)
          return data
          
      })
      .catch(error => {  
          console.error(error)
      })
    }
    
    return (
        <Fragment>
            {loading? <div>Loading</div>:<>
            <Breadcrumb title="Artikel" />
            {
                match.params.page == 'list' ?
                    <ArtikelList data={data} saveArtikel={addArtikel} handleImageUpload={handleImageUpload} mutationLoading={mutationLoading} mutationError={mutationError}/>
                    : match.params.page == 'detail' ?
                        <ArtikelList data={data} />
                        : match.params.page == 'edit' ?
                            <ArtikelEdit data={data.artikels.find(l=>l.id==match.params.id)} id={match.params.id} saveRow={addRow}/>
                            : match.params.page == 'add' ?
                                <ArtikelList data={data} saveProduct={saveArtikel} history={history} mutationCalled={mutationCalled} mutationError={mutationError}/>
                                :match.params.page == 'categories' ?
                                <ArtikelList data={data} />
                                :
                                <ArtikelList data={data} saveArtikel={addArtikel} handleImageUpload={handleImageUpload} mutationLoading={mutationLoading} mutationError={mutationError} />
            }
            </>
        }

        </Fragment>
    )

}

export default withApollo(Artikels)
