import React, { Component, Fragment, useState } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
import data from '../../../assets/data/category';
import Datatable from '../../common/datatable';
import { ToastContainer, toast } from 'react-toastify';
import { withApollo } from '../../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { GET_GUDANGS } from '../../../constants/graph';
const Stock = (props) => {
    const { loading, data, error } = useQuery(GET_GUDANGS,);
    const [selectedGudang, setselectedGudang] = useState(0)
    const listFilterStock=[
        "Semua",
        "InStock",
        "OutStock",
        "Manage stock",
        "Preorder",
        "Stock kurang dari 10"
    ]
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         open: false,
    //         selectedCategory: {}
    //     };
    // }
    // onOpenModal = () => {
    //     this.setState({ open: true });
    // };

    // onCloseModal = () => {
    //     this.setState({ open: false });
    // };
    // onDelete = () => {

    // }
    // onEdit = (cat) => {
    //     this.setState({
    //         selectedCategory: cat
    //     }, () => this.onOpenModal())
    // }
    // handleChange = (e) => {
    //     const { name, value } = e.target
    //     let selectedCategory = this.state.selectedCategory
    //     selectedCategory[name] = value
    //     this.setState({
    //         selectedCategory: selectedCategory
    //     })
    // }
    // saveCategory = () => {
    //     this.props.saveCategory(this.state.selectedCategory).then(result => {
    //         this.onCloseModal()
    //     })



    // }

    // render() {
    //     const { open, selectedCategory } = this.state
    //     const { saveCategory } = this.props
    if (!data) return <div>Loading</div>
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            {/* <div className="container-fluid"> */}
            {/* <div className="row"> */}
            {/* <div className="title">
                <h4 className="card-header">Gudang</h4>
            </div> */}
            {/* <div className="container bg-white">
                {data.gudangs.data.map(cat =>
                    <span className={`badge border p-2 m-2 ${selectedGudang == cat.nama ? "badge-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                        setselectedGudang(cat.nama)

                    }}>{cat.nama}</span>
                    // <li className={`list-group-item ${selectedCategory.name==cat.name?"bg-primary":""}`} style={{cursor:"pointer"}} onClick={()=>{
                    //     this.setState({selectedCategory:cat})
                    //     this.props.refreshProduct(cat)

                    // }}>{cat.name}</li>
                )}

            </div> */}

            <ul className="list-group">
                <li className={`list-group-item bg-info w-100`}>Stock </li>
                {/* <li className={`list-group-item ${selectedGudang == 0 ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => { 
                    setselectedGudang(0)
                    props.setselectedStock(0)
                }}>Semua</li> */}
                {listFilterStock.map((cat,idx) =>
                    <li key={idx} className={`list-group-item ${props.typeStock == idx ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                        setselectedGudang(idx)
                        props.setselectedStock(idx)

                    }}>{cat}</li>
                )}
            </ul>
            {/* <div className="col-sm-12">
                <div className="mb-2">

                    <button type="button" className="btn btn-primary btn-small float-left mt-2" onClick={() => {
                        // this.setState({
                        //     selectedCategory: {}
                        // }, () => this.onOpenModal())
                    }}
                    >Tambah Gudang</button>
                </div>
            </div> */}
            <div className="col-sm-12">
                {/* <div className="card"> */}

                <div className="mt-2">
                    <div className="btn-popup pull-right">


                        <Modal open={false} onClose={() => { }} >
                            <div className="modal-header">
                                {/* <h5 className="modal-title f-w-600" id="exampleModalLabel2">{selectedCategory.id ? 'Edit category' : 'Add Category'}</h5> */}
                            </div>
                            <div className="modal-body">
                                <form autoComplete='off'>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name" className="col-form-label" >Category Name :</label>
                                        <input type="text" className="form-control" name="name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name" className="col-form-label" >Category Description :</label>
                                        <textarea rows={5} className="form-control" name="description" />
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => { }}>Save</button>
                                <button type="button" className="btn btn-secondary" onClick={() => { }}>Close</button>
                            </div>
                        </Modal>
                    </div>
                    <div className="clearfix"></div>
                    <div id="basicScenario" className="">
                        {/* <ul className="list-group">
                                <li className={`list-group-item ${selectedCategory.name==undefined?"bg-primary":""}`} style={{cursor:"pointer"}} onClick={()=>this.setState({selectedCategory:{}})}>Semua</li>
                                {this.props.data.categories.map(cat=>
                                    <li className={`list-group-item ${selectedCategory.name==cat.name?"bg-primary":""}`} style={{cursor:"pointer"}} onClick={()=>{
                                        this.setState({selectedCategory:cat})
                                        this.props.refreshProduct(cat)

                                    }}>{cat.name}</li>
                                    )}
                                </ul> */}
                    </div>
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}
// }


export default withApollo(Stock)

