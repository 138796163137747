import React , { Component,Fragment,useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery,writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject,NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
import {GET_CATEGORIES,GET_TAGS,GET_DASHBOARD_DATA,GET_ORDERS, GET_TODAY_USER} from '../../constants/graph'

import Loading from '../common/loading'
import Errorpage from '../common/Errorpage'
const Usertoday = ({ props }) => {
    //const [data,setData]=useState(props.data)
   const { loading, data, error,fetchMore , refetch, networkStatus} = useQuery(GET_TODAY_USER);
   if (loading) return <Loading />;
   if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    return (
        <div className="col-xl-3 col-md-6 xl-50">
        <div className="card o-hidden widget-cards">
            <div className="bg-danger card-body">
                <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                        <div className="align-self-center text-center"><Users className="font-danger" /></div>
                    </div>
                    <div className="media-body col-8"><span className="m-0">New Users</span>
                        <h3 className="mb-0"><CountUp className="counter" end={data.todayUser.jumlah} /></h3><small> Today</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default withApollo(Usertoday)
//export default ordertoday
