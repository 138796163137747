import React, { Component, useState,useEffect } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import TablePaging from './TablePaging';
import ServiceThumb from './layout/service-thumb';

const ServiceList = (props) => {
    const [modalOpen, setmodalOpen] = useState(false)
    const [selectedService, setselectedService] = useState({})
    const [filter, setfilter] = useState({ kliniks: [] })
    const [myData, setmyData] = useState([])

    const handleFilteringData = () => {

        let response = []
        if (filter.kliniks.length > 0) {
            response = props.data.layanans.filter(dt => dt.dokul_kliniks.every(kl => filter.kliniks.indexOf(kl.id) > -1))

        }
        return response

    }
    useEffect(() => {
        console.log(filter.kliniks)
        let response = props.data.layanans.filter(dt => dt.dokul_kliniks.every(kl => kl.branchid==props.selectedBranch))
        if (filter.byDokter && !filter.byPerawat){
            response = response.filter(dt => dt.type==1)

        }
        if(filter.byPerawat && !filter.byDokter){
            response = response.filter(dt => dt.type==2)
        }

        if (filter.kliniks.length > 0) {
            response = response.filter(dt => dt.dokul_kliniks.every(kl => filter.kliniks.indexOf(kl.id) > -1))

        }
        // setmyData(response)
        setmyData(props.data.layanans)

    }, [filter,props.selectedBranch])
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         open: false,
    //         imageHasChange: false,
    //         myData: this.props.data.layanans,
    //         file: user,
    //         selectedService: {}
    //     };
    // }
    // onOpenModal = () => {
    //     this.setState({ open: true });
    // };

    // onCloseModal = () => {
    //     this.setState({ open: false });
    // };
    // onDelete = () => {

    // }
    // onEdit = (cat) => {
    //     this.setState({
    //         selectedService: cat
    //     }, () => this.onOpenModal())
    // }
    // handleChange = (e) => {
    //     const { name, value } = e.target
    //     let selectedService = { ...this.state.selectedService }
    //     selectedService[name] = value
    //     this.setState({
    //         selectedService: selectedService
    //     })
    //     console.log(selectedService)
    // }
    // saveService = () => {
    //     let newService = { ...this.state.selectedService }
    //     if (this.state.imageHasChange) {
    //         const formData = new FormData()
    //         formData.append('uploaded_file', this.state.file, `${newService.name}_${this.state.file.name}`)


    //         fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
    //             method: 'POST',
    //             body: formData
    //         }).then(response => response.json())
    //             .then(data => {
    //                 console.log(data)
    //                 newService.picture = `/assets/images/uploads/${data.d[0].filename}`
    //                 newService.deskripsi = newService.name
    //                 // newService.type = 2
    //                 newService.banner = `/assets/images/uploads/${data.d[0].filename}`
    //                 const t = this.props.saveService({
    //                     variables: {
    //                         data: newService
    //                     },
    //                     onCompleted: (saveService) => {
    //                         console.log("Sukses")
    //                         // history.push('/products');
    //                     }, onError: ({ graphQLErrors, networkError }) => {
    //                         console.log("ada eror")
    //                         console.log(networkError)
    //                         console.log(graphQLErrors)
    //                     },
    //                 }).then(data => {
    //                     // console.log(data) 
    //                     this.onCloseModal()
    //                 })

    //             })

    //             .catch(error => {
    //                 console.error(error)
    //             })
    //     } else {
    //         const t = this.props.saveService({
    //             variables: {
    //                 data: newService
    //             },
    //             onCompleted: (saveService) => {
    //                 console.log("Sukses")
    //                 // history.push('/products');
    //             }, onError: ({ graphQLErrors, networkError }) => {
    //                 console.log("ada eror")
    //                 console.log(networkError)
    //                 console.log(graphQLErrors)
    //             },
    //         }).then(data => {
    //             // console.log(data) 
    //             this.onCloseModal()
    //         })

    //     }



    // }
    // selectRow = (e, i) => {
    //     if (!e.target.checked) {
    //         this.setState({
    //             checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
    //         });
    //     } else {
    //         this.state.checkedValues.push(i);
    //         this.setState({
    //             checkedValues: this.state.checkedValues
    //         })
    //     }
    // }

    // handleRemoveRow = () => {
    //     const selectedValues = this.state.checkedValues;
    //     const updatedData = this.state.myData.filter(function (el) {
    //         return selectedValues.indexOf(el.id) < 0;
    //     });
    //     this.setState({
    //         myData: updatedData
    //     })
    //     toast.success("Successfully Deleted !")
    // };

    // renderEditable = (cellInfo) => {
    //     return (
    //         <div
    //             style={{ backgroundColor: "#fafafa" }}
    //             contentEditable
    //             suppressContentEditableWarning
    //             onBlur={e => {
    //                 const data = [...this.state.myData];
    //                 data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
    //                 this.setState({ myData: data });
    //             }}
    //             dangerouslySetInnerHTML={{
    //                 __html: this.state.myData[cellInfo.index][cellInfo.column.id]
    //             }}
    //         />
    //     );
    // }
    // renderImage = (cellInfo) => {
    //     console.log(cellInfo)
    //     return (
    //         <div
    //             style={{ backgroundColor: "#fafafa" }}
    //             suppressContentEditableWarning
    //             dangerouslySetInnerHTML={{
    //                 __html: `<img src="${process.env.REACT_APP_IMAGE_HOST}${cellInfo.row.original.picture}" style="width:50px;height:50px;"/>`
    //             }}
    //         />
    //     );
    // }

    // Capitalize(str) {
    //     return str.charAt(0).toUpperCase() + str.slice(1);
    // }
    // changeImageContent = (e, showmodal) => {
    //     let selectedService = { ...this.state.selectedService }
    //     let reader = new FileReader();
    //     let file = e.target.files[0];




    //     reader.onloadend = () => {
    //         selectedService.picture = reader.result;
    //         this.setState({
    //             imageHasChange: true,
    //             file: file,
    //             selectedService: selectedService
    //         })

    //     }
    //     reader.readAsDataURL(file)

    // }


    // render() {

    // const myData = 
    // const { saveCategory } = props
    // const columns = [];
    // columns.push(
    //     {
    //         Header: <b>{this.Capitalize("name")}</b>,
    //         accessor: "name",
    //         //Cell: editable,
    //         style: {
    //             textAlign: 'center'
    //         }
    //     });
    // columns.push(
    //     {
    //         Header: <b>{this.Capitalize("image")}</b>,
    //         accessor: "picture",
    //         width: 100,
    //         Cell: this.renderImage,
    //         style: {
    //             textAlign: 'center',
    //         }
    //     });
    // columns.push(
    //     {
    //         Header: <b>{this.Capitalize("Jenis")}</b>,
    //         accessor: "type",
    //         Cell: (cellInfo) => cellInfo.row.original.type == 1 ? 'Oleh dokter' : 'Oleh perawat',
    //         style: {
    //             textAlign: 'center'
    //         }
    //     });
    // columns.push(
    //     {
    //         Header: <b>{this.Capitalize("klinik")}</b>,
    //         id: "dokul_kliniks",
    //         width: 300,
    //         Cell: (cellInfo) => {
    //             return <ListGroup>{cellInfo.row.original.dokul_kliniks.map(kl => {
    //                 return <ListGroupItem className="justify-content-between">{kl.name}</ListGroupItem>
    //             })}
    //             </ListGroup>
    //         },
    //         style: {
    //             textAlign: 'center',

    //         }
    //     });
    // columns.push(
    //     {
    //         Header: <b>{this.Capitalize("page")}</b>,
    //         accessor: "id",
    //         width: 100,
    //         Cell: (row) => {
    //             return <Link to={`${process.env.PUBLIC_URL}/services/edit/${row.row.original.id}`} ><i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }}></i></Link>

    //         },
    //         style: {
    //             textAlign: 'center',
    //         }
    //     });
    // columns.push(
    //     {
    //         Header: <b>Action</b>,
    //         id: 'delete',
    //         width: 100,
    //         accessor: str => "delete",
    //         Cell: (row) => (
    //             <div>
    //                 <span onClick={() => {
    //                     if (window.confirm('Are you sure you wish to delete this item?')) {
    //                         let data = myData;
    //                         data.splice(row.row.index, 1);
    //                         this.setState({ myData: data });
    //                         toast.success("Successfully Deleted !")
    //                     }
    //                     //toast.success("Successfully Deleted !")

    //                 }}>
    //                     <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }} onClick={this.onDelete}
    //                     ></i>
    //                 </span>

    //                 <span onClick={() => {
    //                     this.setState({
    //                         selectedService: row.row.original
    //                     }, () => this.onOpenModal())

    //                 }}>
    //                     <i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
    //                 </span>



    //             </div>
    //         ),
    //         style: {
    //             textAlign: 'center'
    //         },
    //         sortable: false
    //     }
    // )

    return (
        <>
            <div className="container-fluid bg-white">
                <div className="row">
                    <div className="card col-md-12">
                        {/* <Gudang data={props.data} saveCategory={props.saveCategory} refreshProduct={setselectedCategory} /> */}


                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">

                        <div className="card col-sm-12">
                            <h4 className="card-header">Filter</h4>
                            <ul className="list-group">
                                <li className="list-group-item active" aria-current="true">Tindakan</li>
                                <label className="list-group-item">
                                    <input className="form-check-input me-1" type="checkbox" value="" onChange={(e) => {
                                        let _filter = { ...filter }
                                        if (e.target.checked)
                                            _filter.byDokter=true
                                        else {
                                            _filter.byDokter=false
                                        }
                                       
                                        setfilter(_filter)

                                    }} />
                                    Tindakan oleh dokter
                                </label>
                                <label className="list-group-item">
                                    <input className="form-check-input me-1" type="checkbox" value="" onChange={(e) => {
                                        let _filter = { ...filter }
                                        if (e.target.checked)
                                            _filter.byPerawat=true
                                        else {
                                            _filter.byPerawat=false
                                        }
                                       
                                        setfilter(_filter)

                                    }} />
                                    Tindakan oleh perawat
                                </label>
                            </ul>
                            {/* <CategoryList data={props.data} saveCategory={props.saveCategory} refreshProduct={setselectedCategory} /> */}
                        </div>

                        <div className="list-group">
                            <li className="list-group-item active" aria-current="true">Klinik</li>
                            {props.data.kliniks.filter(_kl=>_kl.branchid==props.selectedBranch).map((kl, idx) => {
                                return <label className="list-group-item" key={idx}>
                                    <input className="form-check-input me-1" type="checkbox" value={kl.id} onChange={(e) => {
                                        let _filter = { ...filter }
                                        let _kliniks = [..._filter.kliniks]
                                        if (e.target.checked)
                                            _kliniks.push(kl.id)
                                        else {
                                            const _idx = _kliniks.indexOf(kl.id)
                                            _kliniks.splice(_idx, 1)
                                        }
                                        _filter.kliniks = _kliniks
                                        setfilter(_filter)

                                    }} />
                                    {kl.name}
                                </label>
                            })}
                        </div>

                    </div>
                    <div className="col-md-9">

                        <div className="row products-admin ratio_asos">
                            <div className="card col-sm-12">
                                <h4 className="card-header">Daftar Layanan</h4>

                            </div>
                            <div className="col-sm-12">
                                <div className="mb-2">

                                    <button type="button" className="btn btn-primary btn-small float-left" onClick={() => {
                                        setmodalOpen(true)
                                    }}
                                    >Tambah Layanan</button>
                                </div>
                            </div>

                            {/* {!loading ? */}

                            {myData.map((layanan,idxser) => {
                                return <ServiceThumb key={idxser} layanan={layanan} />
                            })}
                            {/* : '' */}
                            {/* } */}

                        </div>
                    </div>
                </div>
            </div>
            <Modal open={modalOpen} onClose={() => setmodalOpen(false)} >
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">Tambah layanan</h5>
                </div>
                <div className="modal-body">
                    <form autoComplete='off'>
                        <div className='img-front'><div className="box-input-file-lg"  >

                            <img src={`${selectedService.picture?.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${selectedService.picture}`} width={100} height={100} className="img-fluid image_zoom_1 blur-up lazyloaded"></img>
                            <div className="product-hover">
                                <ul>
                                    <li><div className="box-input-file-lg"  >
                                        <button className="btn" type="button">
                                            <input className="upload" id="fileUpload" type="file" style={{ cursor: "pointer" }} onChange={() => { }} />
                                            <Edit className="editBtn" />
                                        </button>
                                    </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        </div>

                        <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label" >Nama layanan :</label>
                            <input type="text" className="form-control" name="name" value={selectedService.name} onChange={() => { }} />
                        </div>
                        <fieldset class="form-group">
                            <div class="row">
                                <legend class="col-form-label col-sm-2 pt-0 ">Klinik</legend>
                                <div class="col-sm-12">
                                    {props.data.kliniks.map((kl, idx) => {
                                        return <div className="form-check" key={idx}>
                                            <input className="form-check-input" type="checkbox" name="klinik" id="exampleRadios1" value={kl.id} checked={selectedService.dokul_kliniks?.find(dk => dk.id == kl.id)} onChange={(e) => {
                                                // const { checked, value } = e.target
                                                // let selectedService = { ...this.state.selectedService }
                                                // let kliniks = [...selectedService.dokul_kliniks]
                                                // const index = kliniks.indexOf(kl)

                                                // if (checked)
                                                //     kliniks.push(kl)
                                                // else
                                                //     kliniks.splice(index, 1)
                                                // selectedService.dokul_kliniks = kliniks
                                                // console.log(selectedService)
                                                // this.setState({
                                                //     selectedService: selectedService
                                                // })
                                            }} />
                                            <label className="form-check-label" for="exampleRadios1">{kl.name}</label>
                                        </div>

                                    })}


                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group">
                            <div class="row">
                                <legend class="col-form-label col-sm-6 pt-0  ">Tindakan oleh</legend>
                                <div class="col-sm-12">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="type" id="exampleRadios1" value="1" checked={selectedService.type == 1} onChange={() => { }} />
                                        <label className="form-check-label" for="exampleRadios1">Tindakan oleh dokter</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="type" id="exampleRadios2" value="2" checked={selectedService.type == 2} onChange={() => { }} />
                                        <label className="form-check-label" for="exampleRadios2">Tindakan oleh perawat</label>
                                    </div>
                                </div></div>
                        </fieldset>
                        {/* <div className="form-group">
                                                    <label htmlFor="recipient-name" className="col-form-label" >Category Description :</label>
                                                    <textarea rows={5} className="form-control" name="description"  value={selectedService.description}  onChange={this.handleChange} />
                                                </div> */}

                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => { }}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => { }}>Close</button>
                </div>
            </Modal>
        </>
        // <Fragment>
        //     {/* <!-- Container-fluid starts--> */}
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div className="col-sm-12">
        //                 <div className="card">
        //                     <div className="card-header">
        //                         <h5>Layanan</h5>
        //                     </div>
        //                     <div className="card-body">
        //                         <div className="btn-popup pull-right">

        //                             <button type="button" className="btn btn-primary" onClick={() => {
        //                                 this.setState({
        //                                     selectedService: {picture:user,dokul_kliniks:[],type:1,imageHasChange:false}
        //                                 }, () => this.onOpenModal())
        //                             }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Tambah layanan</button>
        // <Modal open={open} onClose={this.onCloseModal} >
        //     <div className="modal-header">
        //         <h5 className="modal-title f-w-600" id="exampleModalLabel2">{selectedService.id ? 'Edit layanan' : 'Tambah layanan'}</h5>
        //     </div>
        //     <div className="modal-body">
        //         <form autoComplete='off'>
        //             <div className='img-front'><div className="box-input-file-lg"  >

        //                 <img src={`${selectedService.picture?.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${selectedService.picture}`} width={100} height={100} className="img-fluid image_zoom_1 blur-up lazyloaded"></img>
        //                 <div className="product-hover">
        //                     <ul>
        //                         <li><div className="box-input-file-lg"  >
        //                             <button className="btn" type="button">
        //                                 <input className="upload" id="fileUpload" type="file" style={{ cursor: "pointer" }} onChange={this.changeImageContent} />
        //                                 <Edit className="editBtn" />
        //                             </button>
        //                         </div>
        //                         </li>
        //                     </ul>
        //                 </div>

        //             </div>

        //             </div>

        //             <div className="form-group">
        //                 <label htmlFor="recipient-name" className="col-form-label" >Nama layanan :</label>
        //                 <input type="text" className="form-control" name="name" value={selectedService.name} onChange={this.handleChange} />
        //             </div>
        //             <fieldset class="form-group">
        //                 <div class="row">
        //                     <legend class="col-form-label col-sm-2 pt-0 ">Klinik</legend>
        //                     <div class="col-sm-12">
        //                         {this.props.data.kliniks.map((kl,idx)=>{
        //                             return <div className="form-check" key={idx}>
        //                             <input className="form-check-input" type="checkbox" name="klinik" id="exampleRadios1" value={kl.id} checked={selectedService.dokul_kliniks?.find(dk=>dk.id==kl.id)} onChange={(e)=>{
        //                                 const {checked,value}=e.target
        //                                 let selectedService={...this.state.selectedService}
        //                                 let kliniks=[...selectedService.dokul_kliniks]
        //                                 const index=kliniks.indexOf(kl)

        //                                 if(checked)
        //                                     kliniks.push(kl)
        //                                 else
        //                                     kliniks.splice(index,1)
        //                                 selectedService.dokul_kliniks=kliniks
        //                                 console.log(selectedService)
        //                                 this.setState({
        //                                     selectedService:selectedService
        //                                 })
        //                             }} />
        //                             <label className="form-check-label" for="exampleRadios1">{kl.name}</label>
        //                         </div>

        //                         })}


        //                     </div>
        //                     </div>
        //             </fieldset>
        //             <fieldset class="form-group">
        //                 <div class="row">
        //                     <legend class="col-form-label col-sm-6 pt-0  ">Tindakan oleh</legend>
        //                     <div class="col-sm-12">
        //                         <div className="form-check">
        //                             <input className="form-check-input" type="radio" name="type" id="exampleRadios1" value="1" checked={selectedService.type==1} onChange={this.handleChange}  />
        //                             <label className="form-check-label" for="exampleRadios1">Tindakan oleh dokter</label>
        //                         </div>
        //                         <div className="form-check">
        //                             <input className="form-check-input" type="radio" name="type" id="exampleRadios2" value="2" checked={selectedService.type==2} onChange={this.handleChange} />
        //                             <label className="form-check-label" for="exampleRadios2">Tindakan oleh perawat</label>
        //                         </div>
        //                     </div></div>
        //             </fieldset>
        //             {/* <div className="form-group">
        //                 <label htmlFor="recipient-name" className="col-form-label" >Category Description :</label>
        //                 <textarea rows={5} className="form-control" name="description"  value={selectedService.description}  onChange={this.handleChange} />
        //             </div> */}

        //         </form>
        //     </div>
        //     <div className="modal-footer">
        //         <button type="button" className="btn btn-primary" onClick={this.saveService}>Save</button>
        //         <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button>
        //     </div>
        // </Modal>
        //                         </div>
        //                         <div className="clearfix"></div>
        //                         <div id="basicScenario" className="product-physical">
        //                             {myData.map(layanan=>{
        //                                 return <ServiceThumb layanan={layanan} />
        //                             })}
        //                             {/* <Datatable
        //                                 onDelete={this.onDelete}
        //                                 editLink={"/services/edit/"}
        //                                 onEdit={this.onEdit}
        //                                 multiSelectOption={false}
        //                                 myData={this.props.data.layanans}
        //                                 hideFields={['__typename', 'id', 'banner', 'type', 'dokul_kliniks', 'dokul_content_rows', 'deskripsi', 'dokul_page']}
        //                                 listFields={[]}
        //                                 pageSize={10}
        //                                 pagination={true}
        //                                 class="-striped -highlight"
        //                             /> */}
        //                             {/* <ReactTable
        //                                 data={myData}
        //                                 columns={columns}
        //                                 defaultPageSize={10}
        //                                 className={"-striped -highlight"}
        //                                 showPagination={true}
        //                             /> */}
        //                             {/* <TablePaging data={myData} columns={columns} showPagination={true} defaultPageSize={10} /> */}

        //                             {/* <ToastContainer /> */}
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     {/* <!-- Container-fluid Ends--> */}
        // </Fragment>
    )
}
// }

export default ServiceList

