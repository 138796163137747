import React from 'react';

export const data =[
    {
        name: "Gray Brody",
        transfer_id: "14783112",
        date: "Nov 20, 2018",
        total:"$745",
    },
    {
        name: "Perez Alonzo",
        transfer_id: "87541221",
        date: "Dec 25, 2018",
        total:"$8136",
    },
    {
        name: "woters maxine",
        transfer_id: "213514462",
        date: "Feb 04, 2019",
        total:"$564",
    },
    {
        name: "Rowan torres",
        transfer_id: "7512785568",
        date: "Jan 07, 2019",
        total:"$2364",
    },
    {
        name: "Lane Skylar",
        transfer_id: "7614585124",
        date: "Apr 15, 2019",
        total:"$671",
    },
    {
        name: "alexander",
        transfer_id: "574225447",
        date: "Mar 21, 2019",
        total:"$8914",
    },
    {
        name: "christian",
        transfer_id: "235896144",
        date: "Mar 28, 2019",
        total:"$7952",
    },
    {
        name: "stephanie",
        transfer_id: "5781425474",
        date: "Feb 30, 2019",
        total:"$1236",
    },
    {
        name: "victoria",
        transfer_id: "636512214",
        date: "Mar 12, 2019",
        total:"$8914",
    },
    {
        name: "campbell",
        transfer_id: "461178242",
        date: "Apr 19, 2019",
        total:"$94174",
    },
    {
        name: "austin",
        transfer_id: "548212314",
        date: "Jan 26, 2019",
        total:"$8914",
    },
    {
        name: "julian",
        transfer_id: "6124612367",
        date: "Mar 28, 2019",
        total:"$3125",
    }
]
export default data