import React, { Component, Fragment } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';

import CKEditors from "react-ckeditor-component";
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import Pagination from '../common/pagination'

export class ArtikelList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            imageHasChange: false,
            myData: this.props.data.artikels,
            file: user,
            selectedArtikel: {},
            totalData: this.props.data.artikels.length,
            totalPages: null,
            currentData: [],
            currentPage: null
        };
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    onDelete = () => {

    }
    onEdit = (cat) => {
        this.setState({
            selectedArtikel: cat
        }, () => this.onOpenModal())
    }
    handleChange = (e) => {
        const { name, value } = e.target
        let selectedArtikel = { ...this.state.selectedArtikel }
        selectedArtikel[name] = value
        this.setState({
            selectedArtikel: selectedArtikel
        })

    }
    saveArtikel = () => {
        let newService = this.state.selectedArtikel
        if (this.state.imageHasChange) {
            const formData = new FormData()
            formData.append('uploaded_file', this.state.file, `${newService.name}_${this.state.file.name}`)


            fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    console.log(data)
                    newService.picture = `/assets/images/uploads/${data.d[0].filename}`
                    newService.deskripsi = newService.name
                    // newService.type = 2
                    newService.banner = `/assets/images/uploads/${data.d[0].filename}`
                    const t = this.props.saveArtikel({
                        variables: {
                            data: newService
                        },
                        onCompleted: (saveArtikel) => {
                            console.log("Sukses")
                            // history.push('/products');
                        }, onError: ({ graphQLErrors, networkError }) => {
                            console.log("ada eror")
                            console.log(networkError)
                            console.log(graphQLErrors)
                        },
                    }).then(data => {
                        // console.log(data) 
                        this.onCloseModal()
                    })

                })

                .catch(error => {
                    console.error(error)
                })
        } else {
            const t = this.props.saveArtikel({
                variables: {
                    data: newService
                },
                onCompleted: (saveArtikel) => {
                    console.log("Sukses")
                    // history.push('/products');
                }, onError: ({ graphQLErrors, networkError }) => {
                    console.log("ada eror")
                    console.log(networkError)
                    console.log(graphQLErrors)
                },
            }).then(data => {
                // console.log(data) 
                this.onCloseModal()
            })

        }



    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    renderImage = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                suppressContentEditableWarning
                dangerouslySetInnerHTML={{
                    __html: `<img src=${process.env.REACT_APP_IMAGE_HOST}${this.state.myData[cellInfo.index][cellInfo.column.id]} style="width:50px;height:50px;"/>`
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    changeImageContent = (e, showmodal) => {
        let selectedArtikel = { ...this.state.selectedArtikel }
        let reader = new FileReader();
        let file = e.target.files[0];




        reader.onloadend = () => {
            selectedArtikel.picture = reader.result;
            this.setState({
                imageHasChange: true,
                file: file,
                selectedArtikel: selectedArtikel
            })

        }
        reader.readAsDataURL(file)

    }
    onPageChanged = data => {
        const { myData } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentData = myData.slice(offset, offset + pageLimit);

        this.setState({ currentPage, currentData, totalPages });
    }


    render() {

        const { open, selectedArtikel, myData, imageHasChange, currentPage, totalData, totalPages, currentData } = this.state
        const { saveCategory } = this.props
        const columns = [];

        columns.push(
            {
                Header: <b>{this.Capitalize("banner")}</b>,
                accessor: "banner",
                width: 100,
                Cell: this.renderImage,
                style: {
                    textAlign: 'center',
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("title")}</b>,
                accessor: "title",
                Cell: (row) =>
                (<div className="card" style={{ width: "18rem" }}>
                    <img className="card-img-top" src="..." alt="Card image cap" />
                    <div className="card-body">
                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                </div>)
                ,
                style: {
                    textAlign: 'center'
                }
            });

        columns.push(
            {
                Header: <b>Action</b>,
                id: 'delete',
                width: 100,
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <span onClick={() => {
                            if (window.confirm('Are you sure you wish to delete this item?')) {
                                let data = myData;
                                data.splice(row.index, 1);
                                this.setState({ myData: data });
                                toast.success("Successfully Deleted !")
                            }
                            //toast.success("Successfully Deleted !")

                        }}>
                            <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }} onClick={this.onDelete}
                            ></i>
                        </span>
                        <Link to={`${process.env.PUBLIC_URL}/artikels/edit/${row.original.id}`} >

                            {/* <span onClick={() => {
                            this.setState({
                                selectedArtikel: row.original
                            }, () => this.onOpenModal())

                        }}> */}
                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                        </Link>



                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )
        const headerClass = ['text-dark py-2 pr-4 m-0', currentPage ? 'border-gray border-right' : ''].join(' ').trim();
        const config = {
            extraAllowedContent: 'iframe[*];div(*);h4(*);ul(*);li(*);button(*)',
            toolbarGroups: [
                { name: 'document', groups: ['mode', 'document', 'doctools'] },
                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                { name: 'forms', groups: ['forms'] },
                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                { name: 'links', groups: ['links'] },
                { name: 'insert', groups: ['insert'] },
                { name: 'styles', groups: ['styles'] },
                { name: 'colors', groups: ['colors'] },
                { name: 'tools', groups: ['tools'] },
                { name: 'others', groups: ['others'] },
                { name: 'about', groups: ['about'] },
                '/',
                '/'
            ],

            removeButtons: 'Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,TextColor,BGColor,About,Styles'
        };

        return (
            <Fragment>
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">

                </div>
                <div className="container-fluid bg-white">
                    <div className="row d-flex flex-row py-0">
                        <div className="w-100 px-4 py-1 d-flex flex-row flex-wrap align-items-center justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                                <h4 className={headerClass}>
                                    <strong className="text-secondary">{totalData}</strong> Artikel
              </h4>
                                {currentPage && (
                                    <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                                        Page <span className="font-weight-bold">{currentPage}</span> / <span className="font-weight-bold">{totalPages}</span>
                                    </span>
                                )}

                            </div>
                            <div className="d-flex flex-row py-4 align-items-center">
                                <Pagination totalRecords={this.props.data.artikels.length} pageLimit={12} pageNeighbours={2} onPageChanged={this.onPageChanged} />
                            </div>
                            <button type="button" className="btn btn-primary" onClick={() => {
                                this.setState({
                                    selectedArtikel: { banner: "/assets/images/default/banner.jpeg", dokul_kliniks: [], type: 1, dokul_page: {}, imageHasChange: false }
                                }, () => this.onOpenModal())
                            }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Add artikel</button>
                        </div>
                    </div>
                    <div className="row">{currentData.map(dt =>

                        <div className="card col-3" >
                            <img className="card-img-top" src={`${process.env.REACT_APP_IMAGE_HOST}${dt.banner}`} alt="Card image cap" />
                            <div className="card-body">
                                <h5 className="card-title">{dt.title}</h5>
                                <div
                                    //style={{ backgroundColor: "#fafafa" }}
                                    suppressContentEditableWarning
                                    dangerouslySetInnerHTML={{
                                        __html: dt.short_desc
                                    }}
                                />
                                
                            </div>
                            <div className="card-footer bg-transparent border-warning" style={{padding:10}}>
                            <div className="pull-left">
                                    <Link to={`${process.env.PUBLIC_URL}/artikels/edit/${dt.id}`} >

                                        <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>Edit page
                                    </Link>
           </div>
                            <div className="pull-right">
                                    
                                    <span onClick={() => {
                                        this.setState({
                                            selectedArtikel: dt
                                        }, () => this.onOpenModal())

                                    }}>
                                        <i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                                    </span>
                                    <span onClick={() => {
                                        if (window.confirm('Are you sure you wish to delete this item?')) {
                                            let data = myData;
                                            data.splice(1, 1);
                                            this.setState({ myData: data });
                                            toast.success("Successfully Deleted !")
                                        }
                                        //toast.success("Successfully Deleted !")

                                    }}>
                                        <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }} onClick={this.onDelete}
                                        ></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                        {/* <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Artikel List</h5>
                                </div>
                                <div className="card-body">
                                    <div className="btn-popup pull-right">

                                        
                                       
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical"> */}
                        {/* <Datatable
                                            onDelete={this.onDelete}
                                            editLink={"/services/edit/"}
                                            onEdit={this.onEdit}
                                            multiSelectOption={false}
                                            myData={this.props.data.layanans}
                                            hideFields={['__typename', 'id', 'banner', 'type', 'dokul_kliniks', 'dokul_content_rows', 'deskripsi', 'dokul_page']}
                                            listFields={[]}
                                            pageSize={10}
                                            pagination={true}
                                            class="-striped -highlight"
                                        /> */}
                        {/* <ReactTable
                                            data={myData}
                                            columns={columns}
                                            defaultPageSize={10}
                                            className={"-striped -highlight"}
                                            showPagination={true}
                                        /> */}
                        <ToastContainer />
                        {/* </div>
                                </div>
                            </div>
                        </div> */}
                        <Modal open={open} onClose={this.onCloseModal} >
                            <div className="modal-header">
                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{selectedArtikel.id ? 'Edit artikel' : 'Add artikel'}</h5>
                            </div>
                            <div className="modal-body">
                                <form autoComplete='off'>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name" className="col-form-label" >Tags :</label>
                                        <input type="text" className="form-control" name="metatag" value={selectedArtikel.dokul_page?.metatag} onChange={(e) => {
                                            {

                                                let selectedArtikel = { ...this.state.selectedArtikel }

                                                selectedArtikel.dokul_page = { metatag: e.target.value }
                                                this.setState({
                                                    selectedArtikel: selectedArtikel
                                                })
                                            }
                                        }} />
                                    </div>
                                    <div className='img-front'><div className="box-input-file-lg"  >

                                        <img src={`${selectedArtikel.picture?.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${selectedArtikel.banner}`} width={"100%"} className="img-fluid image_zoom_1 blur-up lazyloaded"></img>
                                        <div className="product-hover">
                                            <ul>
                                                <li><div className="box-input-file-lg"  >
                                                    <button className="btn" type="button">
                                                        <input className="upload" id="fileUpload" type="file" style={{ cursor: "pointer" }} onChange={this.changeImageContent} />
                                                        <Edit className="editBtn" />
                                                    </button>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="recipient-name" className="col-form-label" >Title :</label>
                                        <input type="text" className="form-control" name="title" value={selectedArtikel.title} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name" className="col-form-label" >Short description :</label>

                                        <CKEditors
                                            activeclassName="p10"
                                            content={selectedArtikel.short_desc}
                                            config={config}
                                            events={{
                                                "blur": (val) => {

                                                },
                                                "afterPaste": (val) => console.log("val"),
                                                "change": (val) => {
                                                    //console.log(val.editor.getData())
                                                    //product.description=val.editor.getData()
                                                    //this.onChangeContent(val.editor.getData())

                                                    let selectedArtikel = { ...this.state.selectedArtikel }
                                                    selectedArtikel["short_desc"] = val.editor.getData()
                                                    this.setState({
                                                        selectedArtikel: selectedArtikel
                                                    })
                                                },
                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.saveArtikel}>Save</button>
                                <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button>
                            </div>
                        </Modal>

                    </div>
                </div>
                <div className="clearfix"></div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default ArtikelList

