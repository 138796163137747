import React, { Component, useState, useContext, useEffect } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings } from 'react-feather'
// import Modal from 'react-responsive-modal';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { CREATE_CONFIGS, GET_CONFIGS, UPDATE_CONFIGS } from '../../constants/graph'
import SocketContext from '../../provider/socketctx/context';
import DispatchContext from '../../provider/socketctx/disptachContext';
import gql from 'graphql-tag';
import QRCode from 'qrcode.react'
import { Edit, Trash2, Plus } from 'react-feather'

import { Badge, Button, Col, Collapse, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const paymentMethods = [
    {
        name: "Xendit QR", provider: [
            { name: "QR", type: 61, imgurl: '/assets/images/dkb/qris-logo.svg', accept_pay: 1, active_for: [] }
        ]
    },
    {
        name: "Xendit Virtual Account", provider: [
            { name: "VABSI", type: 56, imgurl: '/assets/images/dkb/bsi-logo.svg', accept_pay: 1, active_for: [] },
            { name: "VAPERMATA", type: 55, imgurl: '/assets/images/dkb/permata-logo.svg', accept_pay: 1, active_for: [] },
            { name: "VABNI", type: 54, imgurl: '/assets/images/dkb/bni-logo.svg', accept_pay: 1, active_for: [] },
            { name: "VABRI", type: 53, imgurl: '/assets/images/dkb/bri-logo.svg', accept_pay: 1, active_for: [] },
            { name: "VAMANDIRI", type: 52, imgurl: '/assets/images/dkb/mandiri-logo.svg', accept_pay: 1, active_for: [] },
            { name: "VABCA", type: 51, imgurl: '/assets/images/dkb/bca-logo.svg', accept_pay: 1, active_for: [] }
        ]
    },
    {
        name: "Xendit Ewallet", provider: [
            { name: "SHOPEEPAY", type: 42, imgurl: '/assets/images/dkb/shopeepay-logo.svg', accept_pay: 1, active_for: [] },
            { name: "DANA", type: 43, imgurl: '/assets/images/dkb/dana-logo.svg', accept_pay: 1, active_for: [] },
            { name: "OVO", type: 41, imgurl: '/assets/images/dkb/ovo-logo.svg', accept_pay: 1, active_for: [] }
        ]
    },
    {
        name: "Xendit Kartu Kredit", provider: [
            { name: "MASTER CARD/JCB/VISA", type: 31, imgurl: '/assets/images/dkb/visa-logo.svg', accept_pay: 1, active_for: [] }
        ]
    },
]

const BranchOrder = (props) => {
    const adminRoleId = [5, 10, 11]
    const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
    const [metode, setmetode] = useState()
    const [fileAdd, setfileAdd] = useState()
    const [file, setfile] = useState()
    const [errorData, seterrorData] = useState([])
    const [collapseList, setcollapseList] = useState([])
    //  const { loading, data, error, fetchMore } = useQuery(GET_CONFIGS,);
    const updateCache = (cache, { data }) => {

        seteditPayMethod(null)
        seteditPengambilanKlinik(null)
        setidxPayMethod(null)
        // // Fetch the todos from the cache
        // // const existingTodos = cache.readQuery({
        // //   query: GET_ORDERS
        // // });
        // // console.log("HOLAAAAAA")
        // // console.log(data)
        // // var curentConfigs=cache.readFragment({
        // //     id: `Configs:${1}`, // `id` is any id that could be returned by `dataIdFromObject`.
        // //     fragment: gql`
        // //       fragment readConfig on Configs {
        // //         dokul_shippingmethods
        // //       }
        // //     `,
        // // }, false);
        // // var currentSM=[...curentConfigs.dokul_shippingmethods]
        // // var updateShip={...currentSM.find(cs=>cs.id==1)}
        // // updateShip.status=0
        // // currentSM[0]=updateShip
        // // console.log(curentConfigs)

        // const fragmentResult = cache.writeFragment({
        //     id: `Configs:1`,
        //     fragment: gql`
        //   fragment Config on Configs{
        //     dokul_shippingmethods
        //   }
        // `,
        //     data: data.updateConfigs
        //     //  {
        //     //   __typename: 'Configs',
        //     //   id:1,
        //     //   dokul_shippingmethods:currentSM
        //     // }
        // });
        // console.log(fragmentResult)
        // // Add the new todo to the cache
        // // const newTodo = data.insert_todos.returning[0];
        // // cache.writeQuery({
        // //   query: GET_ROBOT_GUIDE,
        // //   data: data.updateRobot
        // // });

    };
    const [updateconfigs, { loading: mutationLoading, error: mutationError, called: mutationCalled },] = useMutation(UPDATE_CONFIGS, {
        onCompleted(data) {
            seteditPayMethod(null)
            seteditPengambilanKlinik(null)
            setidxPayMethod(null)
            // console.log(data)
            props.refetch()
            setModalOpen(false)
        }
    })
    const [createconfig, { loading: createLoading, error: createError, called: createCalled },] = useMutation(CREATE_CONFIGS, {
        onCompleted(data) {
            seteditPayMethod(null)
            seteditPengambilanKlinik(null)
            setidxPayMethod(null)
            // console.log(data)
            props.refetch()
        }
    });

    const { positionInLine, queueLength, message, socketId, waconnect } = useContext(SocketContext);
    const newMessage = useContext(DispatchContext)

    const [editPengambilanKlinik, seteditPengambilanKlinik] = useState(null)
    const [editPayMethod, seteditPayMethod] = useState(null)
    const [idxPayMethod, setidxPayMethod] = useState(null)

    const [waCode, setWaCode] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        //console.log(waconnect)
        if (waconnect.sessid) {
            delete waconnect['sessid'];
            fetch("http://localhost:9001/api/integrasi/authWa", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(waconnect)
            }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        setModalOpen(false)

                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {

                    }
                )


        }

    }, [waconnect])

    const getWaInit = () => {
        let myheaders = {
            "sessid": socketId
        }
        fetch("http://localhost:9001/api/integrasi/initWa", { headers: myheaders })
            .then(res => res.json())
            .then(
                (result) => {
                    setWaCode(result.code)
                    setModalOpen(true)

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                }
            )
    }

    const _handleAddImgChange = (e, i) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            let _metode = { ...metode };
            _metode.image_url = reader.result;
            setmetode(_metode)
            setfileAdd(file)
        }
        reader.readAsDataURL(file)
    }
    const _handleImgChange = (e, i) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            let _metode = { ...editPayMethod };
            _metode.image_url = reader.result;
            seteditPayMethod(_metode)
            setfile(file)
        }
        reader.readAsDataURL(file)
    }
    const doCreate = () => {
        let _metode = { ...metode }
        let _error = []
        if (_metode.type == 9) {
            if (!_metode.name) _error.push("name")
            if (!_metode.private_key) _error.push("private_key")
            if (!_metode.public_key) _error.push("public_key")
        } else {

            if (!_metode.name) _error.push("name")
            if (!_metode.no_rek) _error.push("no_rek")
            if (!_metode.nm_pemilik) _error.push("nm_pemilik")
            if (!_metode.nm_account) _error.push("nm_account")
            if (!_metode.pass_account) _error.push("pass_account")
            if (!fileAdd && _metode.type == 21) _error.push("image")
        }
        // console.log(_metode)
        seterrorData(_error)
        if (_error.length > 0) return
        const payMethods = _metode.paymentMethods.reduce((prev, cur) => {
            const _x = cur.provider.map(x => {
                return {
                    name: x.name,
                    no_rek: "",
                    nm_account: x.name,
                    pass_account: "",
                    type: x.type,
                    image_url: x.imgurl,
                    status: 1,
                    branch_id: _metode.branch_id,
                    nm_pemilik: x.name,
                    accept_pay: 1,
                    for_konsul: 0,
                    description: "",
                    active_for: x.active_for.join(","),
                }
            })
            return prev.concat(_x)

        }, [])
        _metode.paymentMethods = payMethods


        if (fileAdd) {
            const formData = new FormData()
            formData.append('uploaded_file', fileAdd, `paymethode_${fileAdd.name}`)

            fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    data.d.forEach(ud => {
                        _metode.image_url = `/assets/images/uploads/${ud.filename}`
                    })
                    //setSku(_skus)
                    createconfig({
                        context: {
                            headers: {
                                "option": "PAYMENT"
                            }
                        },
                        variables: {
                            body: _metode,
                            id: "payment"
                        }
                    }).then(data => {
                        setModalOpen(false)
                    })
                })
                .catch(error => {
                    console.error(error)
                })
        } else {

            createconfig({
                context: {
                    headers: {
                        "option": "PAYMENT"
                    }
                },
                variables: {
                    body: _metode,
                    id: "payment"
                }
            }).then(data => {
                setModalOpen(false)
            })
        }
    }


    const doUpdate = () => {
        let _metode = { ...editPayMethod }
        if (file) {
            const formData = new FormData()
            formData.append('uploaded_file', file, `paymethode_${file.name}`)





            fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {

                    data.d.forEach(ud => {
                        _metode.image_url = `/assets/images/uploads/${ud.filename}`
                    })
                    //setSku(_skus)
                    updateconfigs({
                        context: {
                            headers: {
                                "option": "PAYMENT"
                            }
                        },
                        variables: {
                            body: _metode,
                            id: "payment"
                        }
                    }).then(data => {
                        setModalOpen(false)
                    })
                })
                .catch(error => {
                    console.error(error)
                })
        } else {
            updateconfigs({
                context: {
                    headers: {
                        "option": "PAYMENT"
                    }
                },
                variables: {
                    body: _metode,
                    id: "payment"
                }
            }).then(data => {
                setModalOpen(false)
            })
        }
    }
    const isActivePay = (val, type) => {
        const arrVal = val.split(",")

        return arrVal.indexOf(type.toString()) != -1
    }


    // if (loading) return "LOADING"
    //console.log(data)
    const { dokul_configs, dokul_paymethods, dokul_payments, dokul_shippingmethods } = props.data
    return (
        <div className="row">

            <div className="col-sm-12">


                <div className="account-setting deactivate-account">
                    <h5 className="f-w-600 f-16 border-bottom text-info pb-2">Pengambilan ke klinik</h5>
                    <div className="row">
                        <label className="d-block mb-2 col-12" >
                            <input className="checkbox_animated" id="chk-ani3" type="checkbox" disabled={editPengambilanKlinik != null ? false : true} checked={editPengambilanKlinik != null ? editPengambilanKlinik[0].nilai.trim() == "1" : dokul_configs.find(ktk => ktk.name.includes("shipment_on_klinik_status")).nilai.trim() == "1"} onChange={(e) => {
                                let _editRows = [...editPengambilanKlinik]
                                let _editRow = { ..._editRows[0] }
                                _editRow.nilai = e.target.checked ? "1" : "0"
                                _editRows[0] = _editRow
                                seteditPengambilanKlinik(_editRows)
                            }} />YA
                        </label>
                        <div className="form-group row mb-2">
                            <label className="col-xl-3 col-md-3">Alamat pengambilan</label>
                            <div className="col-md-9">
                                <textarea className="form-control" name="name" disabled={editPengambilanKlinik != null ? false : true} id="name-point" value={editPengambilanKlinik != null ? editPengambilanKlinik[1].nilai : dokul_configs.find(ktk => ktk.name.includes("shipment_on_klinik_alamat")).nilai} type="text" required="" onChange={(e) => {
                                    let _editRows = [...editPengambilanKlinik]
                                    let _editRow = { ..._editRows[1] }
                                    _editRow.nilai = e.target.value
                                    _editRows[1] = _editRow
                                    seteditPengambilanKlinik(_editRows)
                                }} />
                            </div>
                        </div>

                    </div>
                    <button href="#" className={`btn ${editPengambilanKlinik != null ? 'btn-primary' : ''} mt-2 float-end`} onClick={() => {
                        if (editPengambilanKlinik == null) {
                            seteditPengambilanKlinik([{ ...dokul_configs.find(ktk => ktk.name.includes("shipment_on_klinik_status")) }, { ...dokul_configs.find(ktk => ktk.name.includes("shipment_on_klinik_alamat")) }])

                        } else {
                            updateconfigs({
                                context: {
                                    headers: {
                                        "option": "CONFIG"
                                    }
                                },
                                variables: {
                                    body: editPengambilanKlinik,
                                    id: "config"
                                }
                            })
                        }

                    }}>{editPengambilanKlinik != null ? 'Save' : 'Edit'}</button>

                </div>

                <div className="account-setting deactivate-account">
                    <h5 className="f-w-600 f-16 border-bottom text-info pb-2">Metode pembayaran</h5>
                    <div className="tab-pane fade show active col-12">


                        <ul className="list-group">
                            {user && user.dokul_user_roles.some(x => adminRoleId.some(y => y == x.role_id)) && <li className={`list-group-item `} >
                                <div className="mb-3">
                                    <button type="button" className={`btn float-end mt-2 btn-primary `} onClick={() => {
                                        setmetode({ branch_id: props.branch_id, status: 1, accept_pay: 1, type: 9, paymentMethods: paymentMethods, image_url: "/assets/images/dkb/xendit.png" })
                                        setModalOpen(true)

                                    }}>Tambah Metode</button>
                                </div>
                            </li>
                            }
                            {dokul_payments.map((dpg, dpgi) => {
                                return <li key={dpgi} className={`list-group-item `} >
                                    <div className="row bg-primary p-2">
                                        <Col md='6'>
                                            <h4 className='float-start m-2'>{dpg.pay_name}</h4>
                                            <p><Badge color={`${dpg.is_live ? 'success' : 'warning'}`}>{`${dpg.is_live ? 'LIVE' : 'TEST'}`} MODE</Badge></p>
                                        </Col>
                                        <Col md='2'></Col>
                                        <Col md='2'><button className='float-end btn btn-info btn-sm' onClick={() => {
                                            setcollapseList(x => {
                                                let _oldData = [...x]
                                                if (_oldData.some(x2 => x2 == dpg.id)) {
                                                    if (_oldData.length == 1)
                                                        _oldData = []
                                                    else
                                                        _oldData = _oldData.splice(_oldData.indexOf(dpg.id), 1)

                                                    return _oldData
                                                } else {
                                                    _oldData.push(dpg.id)
                                                    return _oldData
                                                }
                                            })

                                        }}>{collapseList.some(x => x == dpg.id) ? 'Hide' : 'Show'}</button></Col>
                                        <Col md='2'>
                                            <button className='float-end btn btn-secondary btn-sm' onClick={() => {
                                                setmetode({ ...dpg, isEdit: true })
                                                setModalOpen(true)

                                            }}>edit</button>
                                        </Col>




                                    </div>
                                    <Collapse isOpen={collapseList.some(x => x == dpg.id)}>

                                        <div className="mb-3">
                                            <ul className="list-group">
                                                <li className={`list-group-ite bg-white w-100`}>
                                                    <div className="row">

                                                        {dokul_paymethods.filter(x => x.type < 30 && x.pay_id == dpg.id).map((kl, idx) =>
                                                            <div key={idx} className="col-sm-6">
                                                                <div className="border rounded  m-2 p-2">

                                                                    <div className="form-group row mb-2">
                                                                        <label className="col-xl-3 col-md-3">Logo</label>
                                                                        <div className="col-md-9">
                                                                            <div className='img-front m-1' style={{ padding: 0, width: 100 }}>
                                                                                <div className="box-input-file-lg"  >
                                                                                    {editPayMethod != null && idxPayMethod == idx ?
                                                                                        <img src={`${editPayMethod.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${editPayMethod.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                                                        : <img src={`${kl.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                                                    }
                                                                                    {/* <img src={`${pictures[activeImg] && pictures[activeImg]?.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${pictures[activeImg]?.image_url}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" /> */}
                                                                                    <div className="product-hover">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <input title={""} className="upload text-center" type="file" onChange={(e) => _handleImgChange(e)} />

                                                                                                <i className="fa fa-edit" />

                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* </div>
                                                    <div className="col-sm-8"> */}

                                                                    {kl.type < 30 ? <>
                                                                        <div className="form-group row mb-2">
                                                                            <label className="col-xl-3 col-md-3">Name</label>
                                                                            <div className="col-md-9">
                                                                                <input className="form-control" name="name" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.name : kl.name} type="text" required="" onChange={(e) => {
                                                                                    let _editRow = { ...editPayMethod }
                                                                                    _editRow.name = e.target.value
                                                                                    seteditPayMethod(_editRow)
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-2">
                                                                            <label className="col-xl-3 col-md-3">Nomor rekening</label>
                                                                            <div className="col-md-9">
                                                                                <input className="form-control" name="no_rek" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.no_rek : kl.no_rek} type="text" required="" onChange={(e) => {
                                                                                    let _editRow = { ...editPayMethod }
                                                                                    _editRow.no_rek = e.target.value
                                                                                    seteditPayMethod(_editRow)
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-2">
                                                                            <label className="col-xl-3 col-md-3">Pemilik rekening</label>
                                                                            <div className="col-md-9">
                                                                                <input className="form-control" name="nm_pemilik" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.nm_pemilik : kl.nm_pemilik} type="text" required="" onChange={(e) => {
                                                                                    let _editRow = { ...editPayMethod }
                                                                                    _editRow.nm_pemilik = e.target.value
                                                                                    seteditPayMethod(_editRow)
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-2">
                                                                            <label className="col-xl-3 col-md-3">Nama Akun</label>
                                                                            <div className="col-md-9">
                                                                                <input className="form-control" name="nm_account" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.nm_account : kl.nm_account} type="text" required="" onChange={(e) => {
                                                                                    let _editRow = { ...editPayMethod }
                                                                                    _editRow.nm_account = e.target.value
                                                                                    seteditPayMethod(_editRow)
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-2">
                                                                            <label className="col-xl-3 col-md-3">Password akun</label>
                                                                            <div className="col-md-9">
                                                                                <input className="form-control" name="pass_account" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.pass_account : kl.pass_account} type="password" required="" onChange={(e) => {
                                                                                    let _editRow = { ...editPayMethod }
                                                                                    _editRow.pass_account = e.target.value
                                                                                    seteditPayMethod(_editRow)
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                    </> : <>
                                                                    </>
                                                                    }

                                                                    <Row>
                                                                        {kl.type < 30 && <Col md={12}>
                                                                            <div className="form-group row mb-2">

                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? editPayMethod.accept_pay == 1 : kl.accept_pay == 1} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        _editRow.accept_pay = e.target.checked ? 1 : 0
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} /> Otomatis ambil transaksi bank</label>

                                                                                </div>
                                                                            </div></Col>}
                                                                        <Col md={6}>

                                                                            <div className="form-group row mb-2">
                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 1) : isActivePay(kl.active_for, 1)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(1)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(1)
                                                                                            arrData.splice(idxPay, 1)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Online Shop</label>

                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row mb-2">
                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 2) : isActivePay(kl.active_for, 2)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(2)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(2)
                                                                                            arrData.splice(idxPay, 1)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Konsultasi Reguler</label>

                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="form-group row mb-2">

                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 3) : isActivePay(kl.active_for, 3)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(3)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(3)
                                                                                            arrData.splice(idxPay, 1)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Reservasi</label>

                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row mb-2">


                                                                                <div className="col-md-12">
                                                                                    <label className=" mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 4) : isActivePay(kl.active_for, 4)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(4)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(4)
                                                                                            arrData.splice(idxPay, 1)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Konsultasi Premium</label>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                    </Row>

                                                                    <div className='mt-2 bg-light p-2'>
                                                                        {editPayMethod != null && idxPayMethod == idx ? <button type="button" className={`btn border  mt-2 btn-light`} onClick={() => {

                                                                            setidxPayMethod()
                                                                            seteditPayMethod(null)
                                                                        }}>Cancel</button> : ""}
                                                                        {user && user.dokul_user_roles.some(x => adminRoleId.some(y => y == x.role_id)) && <button type="button" className={`btn border mt-2 ${editPayMethod != null && idxPayMethod == idx ? "btn-primary" : "btn-light"}`} onClick={() => {
                                                                            if (editPayMethod == null) {
                                                                                setidxPayMethod(idx)
                                                                                seteditPayMethod(kl)
                                                                            } else {
                                                                                if (file)
                                                                                    doUpdate()
                                                                                else
                                                                                    updateconfigs({
                                                                                        context: {
                                                                                            headers: {
                                                                                                "option": "PAYMENT"
                                                                                            }
                                                                                        },
                                                                                        variables: {
                                                                                            body: editPayMethod,
                                                                                            id: "payment"
                                                                                        }
                                                                                    })

                                                                            }


                                                                        }}>{editPayMethod != null && idxPayMethod == idx ? "Save" : "Edit"}</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                        {dokul_paymethods.filter(x => x.type > 30 && x.pay_id == dpg.id).map((kl, idx) =>
                                                            <div key={idx} className="col-sm-6">
                                                                <div className="border rounded  m-2 p-2">

                                                                    <div className="form-group row mb-2">
                                                                        <div className="col-xl-3 col-md-3">
                                                                            <FormGroup className={`border px-2 rounded ${kl.status != 0 ? 'border-success' : 'border-danger'}`}>
                                                                                
                                                                                <Label className={`${kl.status != 0 ? 'text-success' : 'text-danger'}`}> {`${kl.status != 0 ? 'Aktif' : 'Tidak Aktif'}`}</Label>
                                                                                {editPayMethod != null && idxPayMethod == idx &&
                                                                                    <div className="form-check form-switch">

                                                                                        <input className="form-check-input" type="checkbox" value={""} defaultChecked={editPayMethod.status != 0} id={"pmaktif" + kl.id} onChange={(e) => {
                                                                                           
                                                                                            let _editRow = { ...editPayMethod }
                                                                                            _editRow.status = e.target.checked ? 1 : 0
                                                                                            seteditPayMethod(_editRow)

                                                                                        }} />
                                                                                        <label className="form-check-label" htmlFor={"flexSwitchCheckDefault2" + kl.id} > </label>
                                                                                    </div>
                                                                                }



                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="col-md-9">
                                                                            <div className='img-front m-1' style={{ padding: 0, width: 100 }}>
                                                                                <div className="box-input-file-lg"  >
                                                                                    {editPayMethod != null && idxPayMethod == idx ?
                                                                                        <img src={`${editPayMethod.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${editPayMethod.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                                                        : <img src={`${kl.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                                                    }
                                                                                    <div className="product-hover">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <input title={""} className="upload text-center" type="file" onChange={(e) => _handleImgChange(e)} />

                                                                                                <i className="fa fa-edit" />

                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Row>
                                                                        {kl.type < 30 && <Col md={12}>
                                                                            <div className="form-group row mb-2">

                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? editPayMethod.accept_pay == 1 : kl.accept_pay == 1} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        _editRow.accept_pay = e.target.checked ? 1 : 0
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} /> Otomatis ambil transaksi bank</label>

                                                                                </div>
                                                                            </div></Col>}
                                                                        <Col md={6}>

                                                                            <div className="form-group row mb-2">
                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 1) : isActivePay(kl.active_for, 1)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(1)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(1)
                                                                                            arrData.splice(idxPay, 1)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Online Shop</label>

                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row mb-2">
                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 5) : isActivePay(kl.active_for, 5)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(5)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(5)
                                                                                            arrData.splice(idxPay, 5)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Online Shop Premium</label>

                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row mb-2">
                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 2) : isActivePay(kl.active_for, 2)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(2)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(2)
                                                                                            arrData.splice(idxPay, 1)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Konsultasi Reguler</label>

                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="form-group row mb-2">

                                                                                <div className="col-md-12">
                                                                                    <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 3) : isActivePay(kl.active_for, 3)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(3)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(3)
                                                                                            arrData.splice(idxPay, 1)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Reservasi</label>

                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row mb-2">


                                                                                <div className="col-md-12">
                                                                                    <label className=" mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 4) : isActivePay(kl.active_for, 4)} onChange={(e) => {

                                                                                        let _editRow = { ...editPayMethod }
                                                                                        let arrData = _editRow.active_for.split(",")
                                                                                        if (e.target.checked) {
                                                                                            arrData.push(4)
                                                                                        } else {
                                                                                            const idxPay = arrData.indexOf(4)
                                                                                            arrData.splice(idxPay, 1)


                                                                                        }
                                                                                        _editRow.active_for = arrData.join(",")
                                                                                        seteditPayMethod(_editRow)
                                                                                    }} />Konsultasi Premium</label>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                    </Row>

                                                                    <div className='mt-2 bg-light p-2'>
                                                                        {editPayMethod != null && idxPayMethod == idx ? <button type="button" className={`btn border  mt-2 btn-light`} onClick={() => {

                                                                            setidxPayMethod()
                                                                            seteditPayMethod(null)
                                                                        }}>Cancel</button> : ""}
                                                                        {user && user.dokul_user_roles.some(x => adminRoleId.some(y => y == x.role_id)) && <button type="button" className={`btn border mt-2 ${editPayMethod != null && idxPayMethod == idx ? "btn-primary" : "btn-light"}`} onClick={() => {
                                                                            if (editPayMethod == null) {
                                                                                setidxPayMethod(idx)
                                                                                seteditPayMethod(kl)
                                                                            } else {
                                                                                if (file)
                                                                                    doUpdate()
                                                                                else
                                                                                    updateconfigs({
                                                                                        context: {
                                                                                            headers: {
                                                                                                "option": "PAYMENT"
                                                                                            }
                                                                                        },
                                                                                        variables: {
                                                                                            body: editPayMethod,
                                                                                            id: "payment"
                                                                                        }
                                                                                    })

                                                                            }


                                                                        }}>{editPayMethod != null && idxPayMethod == idx ? "Save" : "Edit"}</button>
                                                                        }

                                                                    </div>

                                                                </div>
                                                            </div>)}

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Collapse>
                                </li>

                            })}


                            <li className={`list-group-item `} >
                                <div className="mb-3">
                                    <ul className="list-group">
                                        {/* <li className={`list-group-ite bg-white w-100`}>
                                            <div className="row">
                                                <div className="col-sm-12 bg-primary p-2"><h4>Transfer Bank</h4></div>
                                                {dokul_paymethods.filter(x => x.type < 30).map((kl, idx) =>
                                                    <div className="col-sm-6">
                                                        <div className="border rounded  m-2 p-2">

                                                            <div className="form-group row mb-2">
                                                                <label className="col-xl-3 col-md-3">Logo</label>
                                                                <div className="col-md-9">
                                                                    <div className='img-front m-1' style={{ padding: 0, width: 100 }}>
                                                                        <div className="box-input-file-lg"  >
                                                                            {editPayMethod != null && idxPayMethod == idx ?
                                                                                <img src={`${editPayMethod.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${editPayMethod.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                                                : <img src={`${kl.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                                            }
                                                                    <div className="product-hover">
                                                                                <ul>
                                                                                    <li>
                                                                                        <input title={""} className="upload text-center" type="file" onChange={(e) => _handleImgChange(e)} />

                                                                                        <i className="fa fa-edit" />

                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           

                                                            {kl.type < 30 ? <>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Name</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="name" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.name : kl.name} type="text" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.name = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Nomor rekening</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="no_rek" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.no_rek : kl.no_rek} type="text" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.no_rek = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Pemilik rekening</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="nm_pemilik" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.nm_pemilik : kl.nm_pemilik} type="text" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.nm_pemilik = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Nama Akun</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="nm_account" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.nm_account : kl.nm_account} type="text" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.nm_account = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Password akun</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="pass_account" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.pass_account : kl.pass_account} type="password" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.pass_account = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                            </>
                                                            }

                                                            <Row>
                                                                {kl.type < 30 && <Col md={12}>
                                                                    <div className="form-group row mb-2">

                                                                        <div className="col-md-12">
                                                                            <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? editPayMethod.accept_pay == 1 : kl.accept_pay == 1} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                _editRow.accept_pay = e.target.checked ? 1 : 0
                                                                                seteditPayMethod(_editRow)
                                                                            }} /> Otomatis ambil transaksi bank</label>

                                                                        </div>
                                                                    </div></Col>}
                                                                <Col md={6}>

                                                                    <div className="form-group row mb-2">
                                                                        <div className="col-md-12">
                                                                            <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 1) : isActivePay(kl.active_for, 1)} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                let arrData = _editRow.active_for.split(",")
                                                                                if (e.target.checked) {
                                                                                    arrData.push(1)
                                                                                } else {
                                                                                    const idxPay = arrData.indexOf(1)
                                                                                    arrData.splice(idxPay, 1)


                                                                                }
                                                                                _editRow.active_for = arrData.join(",")
                                                                                seteditPayMethod(_editRow)
                                                                            }} />Online Shop</label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mb-2">
                                                                        <div className="col-md-12">
                                                                            <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 2) : isActivePay(kl.active_for, 2)} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                let arrData = _editRow.active_for.split(",")
                                                                                if (e.target.checked) {
                                                                                    arrData.push(2)
                                                                                } else {
                                                                                    const idxPay = arrData.indexOf(2)
                                                                                    arrData.splice(idxPay, 1)


                                                                                }
                                                                                _editRow.active_for = arrData.join(",")
                                                                                seteditPayMethod(_editRow)
                                                                            }} />Konsultasi Reguler</label>

                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="form-group row mb-2">

                                                                        <div className="col-md-12">
                                                                            <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 3) : isActivePay(kl.active_for, 3)} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                let arrData = _editRow.active_for.split(",")
                                                                                if (e.target.checked) {
                                                                                    arrData.push(3)
                                                                                } else {
                                                                                    const idxPay = arrData.indexOf(3)
                                                                                    arrData.splice(idxPay, 1)


                                                                                }
                                                                                _editRow.active_for = arrData.join(",")
                                                                                seteditPayMethod(_editRow)
                                                                            }} />Reservasi</label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mb-2">


                                                                        <div className="col-md-12">
                                                                            <label className=" mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 4) : isActivePay(kl.active_for, 4)} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                let arrData = _editRow.active_for.split(",")
                                                                                if (e.target.checked) {
                                                                                    arrData.push(4)
                                                                                } else {
                                                                                    const idxPay = arrData.indexOf(4)
                                                                                    arrData.splice(idxPay, 1)


                                                                                }
                                                                                _editRow.active_for = arrData.join(",")
                                                                                seteditPayMethod(_editRow)
                                                                            }} />Konsultasi Premium</label>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                            </Row>

                                                            <div className='mt-2 bg-light p-2'>
                                                                {editPayMethod != null && idxPayMethod == idx ? <button type="button" className={`btn border  mt-2 btn-light`} onClick={() => {

                                                                    setidxPayMethod()
                                                                    seteditPayMethod(null)
                                                                }}>Cancel</button> : ""}
                                                                {user && user.dokul_user_roles.some(x => adminRoleId.some(y => y == x.role_id)) && <button type="button" className={`btn border mt-2 ${editPayMethod != null && idxPayMethod == idx ? "btn-primary" : "btn-light"}`} onClick={() => {
                                                                    if (editPayMethod == null) {
                                                                        setidxPayMethod(idx)
                                                                        seteditPayMethod(kl)
                                                                    } else {
                                                                        if (file)
                                                                            doUpdate()
                                                                        else
                                                                            updateconfigs({
                                                                                context: {
                                                                                    headers: {
                                                                                        "option": "PAYMENT"
                                                                                    }
                                                                                },
                                                                                variables: {
                                                                                    body: editPayMethod,
                                                                                    id: "payment"
                                                                                }
                                                                            })

                                                                    }


                                                                }}>{editPayMethod != null && idxPayMethod == idx ? "Save" : "Edit"}</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                <div className="col-sm-12 bg-primary p-2"><h4>XENDIT</h4></div>


                                                {dokul_paymethods.filter(x => x.type > 30).map((kl, idx) =>
                                                    <div className="col-sm-6">
                                                        <div className="border rounded  m-2 p-2">

                                                            <div className="form-group row mb-2">
                                                                <label className="col-xl-3 col-md-3">Logo</label>
                                                                <div className="col-md-9">
                                                                    <div className='img-front m-1' style={{ padding: 0, width: 100 }}>
                                                                        <div className="box-input-file-lg"  >
                                                                            {editPayMethod != null && idxPayMethod == idx ?
                                                                                <img src={`${editPayMethod.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${editPayMethod.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                                                : <img src={`${kl.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${kl.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                                                            }
                                                                            <div className="product-hover">
                                                                                <ul>
                                                                                    <li>
                                                                                        <input title={""} className="upload text-center" type="file" onChange={(e) => _handleImgChange(e)} />

                                                                                        <i className="fa fa-edit" />

                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            {kl.type < 30 ? <>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Name</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="name" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.name : kl.name} type="text" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.name = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Nomor rekening</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="no_rek" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.no_rek : kl.no_rek} type="text" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.no_rek = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Pemilik rekening</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="nm_pemilik" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.nm_pemilik : kl.nm_pemilik} type="text" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.nm_pemilik = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Nama Akun</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="nm_account" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.nm_account : kl.nm_account} type="text" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.nm_account = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-2">
                                                                    <label className="col-xl-3 col-md-3">Password akun</label>
                                                                    <div className="col-md-9">
                                                                        <input className="form-control" name="pass_account" disabled={editPayMethod != null && idxPayMethod == idx ? false : true} id="name-point" value={editPayMethod != null && idxPayMethod == idx ? editPayMethod.pass_account : kl.pass_account} type="password" required="" onChange={(e) => {
                                                                            let _editRow = { ...editPayMethod }
                                                                            _editRow.pass_account = e.target.value
                                                                            seteditPayMethod(_editRow)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                            </>
                                                            }

                                                            <Row>
                                                                {kl.type < 30 && <Col md={12}>
                                                                    <div className="form-group row mb-2">

                                                                        <div className="col-md-12">
                                                                            <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? editPayMethod.accept_pay == 1 : kl.accept_pay == 1} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                _editRow.accept_pay = e.target.checked ? 1 : 0
                                                                                seteditPayMethod(_editRow)
                                                                            }} /> Otomatis ambil transaksi bank</label>

                                                                        </div>
                                                                    </div></Col>}
                                                                <Col md={6}>

                                                                    <div className="form-group row mb-2">
                                                                        <div className="col-md-12">
                                                                            <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 1) : isActivePay(kl.active_for, 1)} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                let arrData = _editRow.active_for.split(",")
                                                                                if (e.target.checked) {
                                                                                    arrData.push(1)
                                                                                } else {
                                                                                    const idxPay = arrData.indexOf(1)
                                                                                    arrData.splice(idxPay, 1)


                                                                                }
                                                                                _editRow.active_for = arrData.join(",")
                                                                                seteditPayMethod(_editRow)
                                                                            }} />Online Shop</label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mb-2">
                                                                        <div className="col-md-12">
                                                                            <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 2) : isActivePay(kl.active_for, 2)} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                let arrData = _editRow.active_for.split(",")
                                                                                if (e.target.checked) {
                                                                                    arrData.push(2)
                                                                                } else {
                                                                                    const idxPay = arrData.indexOf(2)
                                                                                    arrData.splice(idxPay, 1)


                                                                                }
                                                                                _editRow.active_for = arrData.join(",")
                                                                                seteditPayMethod(_editRow)
                                                                            }} />Konsultasi Reguler</label>

                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="form-group row mb-2">

                                                                        <div className="col-md-12">
                                                                            <label className="mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 3) : isActivePay(kl.active_for, 3)} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                let arrData = _editRow.active_for.split(",")
                                                                                if (e.target.checked) {
                                                                                    arrData.push(3)
                                                                                } else {
                                                                                    const idxPay = arrData.indexOf(3)
                                                                                    arrData.splice(idxPay, 1)


                                                                                }
                                                                                _editRow.active_for = arrData.join(",")
                                                                                seteditPayMethod(_editRow)
                                                                            }} />Reservasi</label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mb-2">


                                                                        <div className="col-md-12">
                                                                            <label className=" mt-2" ><input disabled={editPayMethod != null && idxPayMethod == idx ? false : true} className="checkbox_animated" id="chk-ani" type="checkbox" checked={editPayMethod != null && idxPayMethod == idx ? isActivePay(editPayMethod.active_for, 4) : isActivePay(kl.active_for, 4)} onChange={(e) => {

                                                                                let _editRow = { ...editPayMethod }
                                                                                let arrData = _editRow.active_for.split(",")
                                                                                if (e.target.checked) {
                                                                                    arrData.push(4)
                                                                                } else {
                                                                                    const idxPay = arrData.indexOf(4)
                                                                                    arrData.splice(idxPay, 1)


                                                                                }
                                                                                _editRow.active_for = arrData.join(",")
                                                                                seteditPayMethod(_editRow)
                                                                            }} />Konsultasi Premium</label>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                            </Row>

                                                            <div className='mt-2 bg-light p-2'>
                                                                {editPayMethod != null && idxPayMethod == idx ? <button type="button" className={`btn border  mt-2 btn-light`} onClick={() => {

                                                                    setidxPayMethod()
                                                                    seteditPayMethod(null)
                                                                }}>Cancel</button> : ""}
                                                                {user && user.dokul_user_roles.some(x => adminRoleId.some(y => y == x.role_id)) && <button type="button" className={`btn border mt-2 ${editPayMethod != null && idxPayMethod == idx ? "btn-primary" : "btn-light"}`} onClick={() => {
                                                                    if (editPayMethod == null) {
                                                                        setidxPayMethod(idx)
                                                                        seteditPayMethod(kl)
                                                                    } else {
                                                                        if (file)
                                                                            doUpdate()
                                                                        else
                                                                            updateconfigs({
                                                                                context: {
                                                                                    headers: {
                                                                                        "option": "PAYMENT"
                                                                                    }
                                                                                },
                                                                                variables: {
                                                                                    body: editPayMethod,
                                                                                    id: "payment"
                                                                                }
                                                                            })

                                                                    }


                                                                }}>{editPayMethod != null && idxPayMethod == idx ? "Save" : "Edit"}</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>)}
                                            </div>

                                        </li> */}
                                    </ul>
                                </div>




                            </li>



                        </ul>


                    </div>






                </div>

            </div>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} centered size={"lg"}>

                <ModalHeader toggle={() => setModalOpen(false)} className="bg-primary text-white">{metode && !metode.isEdit ? "Tambah metode bayar</ModalHeader" : "Edit metode bayar"}</ModalHeader>
                {metode && !metode.isEdit ? <ModalBody className="bg-white">
                    <ul className="list-group">
                        <li className={`list-group-ite bg-white w-100`}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="file-upload-product d-flex flex-nowrap">



                                        <div className='img-front m-1' style={{ padding: 0 }}>
                                            <div className="box-input-file-lg"  >
                                                <img src={`${metode?.image_url.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${metode.image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />

                                                {/* <img src={`${process.env.REACT_APP_IMAGE_HOST}${metode.image_url}`} alt="" className="img-fluid image_zoom_1 blur-up lazyloaded" /> */}
                                                <div className="product-hover">
                                                    <ul>
                                                        <li>
                                                            <input title={""} className="upload text-center" type="file" onChange={(e) => _handleAddImgChange(e)} />

                                                            <i className="fa fa-edit" />

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {errorData.includes("image") && <small className="text-danger">Gambar QRIS dibutuhkan</small>}
                                </div>
                                <div className="col-sm-8">
                                    <div className="form-group row mb-2">
                                        <label className="col-xl-3 col-md-3">Tipe pembayaran</label>
                                        <div className="col-md-9">

                                            <div className='d-flex flex-row wrap '>
                                                <div className="custom-control custom-radio custom-control-inline m-2">
                                                    <input type="radio" id={`nis_fixed`} name={`nis_fixed`} value={9} className="custom-control-input" checked={metode?.type == 9} onChange={(e) => {
                                                        if (!e.target.checked) return
                                                        let _metode = { ...metode }
                                                        _metode.type = e.target.value
                                                        _metode.image_url = "/assets/images/dkb/xendit.png"
                                                        _metode.paymentMethods = paymentMethods
                                                        setmetode(_metode)
                                                    }} />
                                                    <label className="custom-control-label ml-2" htmlFor="customRadioInline1">XENDIT</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline m-2">
                                                    <input type="radio" id={`nis_fixed`} name={`nis_fixed`} value={11} className="custom-control-input" checked={metode?.type == 11} onChange={(e) => {
                                                        if (!e.target.checked) return
                                                        let _metode = { ...metode }
                                                        _metode.type = e.target.value
                                                        _metode.image_url = "/assets/images/dkb/bca.png"
                                                        setmetode(_metode)
                                                    }} />
                                                    <label className="custom-control-label ml-2" htmlFor="customRadioInline1">BANK BCA</label>
                                                </div>

                                                <div className="custom-control custom-radio custom-control-inline  m-2">
                                                    <input type="radio" id={`nis_fixed`} name={`nis_fixed`} className="custom-control-input" value={12} onChange={(e) => {
                                                        if (!e.target.checked) return
                                                        let _metode = { ...metode }
                                                        _metode.type = e.target.value
                                                        _metode.image_url = "/assets/images/dkb/mandiri.png"
                                                        setmetode(_metode)

                                                    }} />
                                                    <label className="custom-control-label ml-2" htmlFor="customRadioInline1">BANK MANDIRI</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline  m-2">
                                                    <input type="radio" id={`nis_fixed`} name={`nis_fixed`} className="custom-control-input" value={21} onChange={(e) => {
                                                        if (!e.target.checked) return
                                                        let _metode = { ...metode }
                                                        _metode.type = e.target.value
                                                        _metode.image_url = "/assets/images/noimage.png"
                                                        setmetode(_metode)

                                                    }} />
                                                    <label className="custom-control-label ml-2" htmlFor="customRadioInline1">QRIS</label>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    {metode && metode.type == 9 ? <>
                                        <div className="form-group row mb-2">
                                            <label className="col-xl-3 col-md-3">Name</label>
                                            <div className="col-md-9">
                                                <input className="form-control" name="name" id="name-point" type="text" required="" onChange={(e) => {
                                                    let _metode = { ...metode }
                                                    _metode.name = e.target.value
                                                    setmetode(_metode)

                                                }} />
                                            </div>
                                            {errorData.includes("name") && <small className="text-danger">Nama XENDIT  dibutuhkan</small>}
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-xl-3 col-md-3">Private Key</label>
                                            <div className="col-md-9">
                                                <input className="form-control" name="norek" id="name-point" type="text" required="" onChange={(e) => {
                                                    let _metode = { ...metode }
                                                    _metode.private_key = e.target.value
                                                    setmetode(_metode)

                                                }} />
                                            </div>
                                            {errorData.includes("private_key") && <small className="text-danger">Private Key XENDIT dibutuhkan</small>}
                                        </div>
                                        <div className="form-group row mb-2">
                                            <label className="col-xl-3 col-md-3">Public Key</label>
                                            <div className="col-md-9">
                                                <input className="form-control" name="norek" id="name-point" type="text" required="" onChange={(e) => {
                                                    let _metode = { ...metode }
                                                    _metode.public_key = e.target.value
                                                    setmetode(_metode)

                                                }} />
                                            </div>
                                            {errorData.includes("public_key") && <small className="text-danger">Public key XENDIT dibutuhkan</small>}
                                        </div>
                                        {metode && metode.type == 9 && metode.paymentMethods?.map((pm, ipm) => {
                                            return <section key={ipm} >
                                                <div className='p-1 w-100 bg-secondary text-dark'><h4>{pm.name}</h4></div>

                                                {pm.provider && pm.provider.map((prov, iprov) =>
                                                    <div key={iprov} className='d-flex flex-column border m-2'>
                                                        <div className='p-1 w-50 bg-white text-dark'><img src={`${process.env.REACT_APP_IMAGE_HOST}${prov.imgurl}`} className="img-fluid w-25 blur-up lazyloaded" /></div>
                                                        <div className='row  p-2'>

                                                            {["OLS", "Reservasi", "Konsultasi Reguler", "Konsultasi Premium", "OLS Premium",].map((payfor, ipm) =>
                                                                <div key={ipm} className='col-6'>
                                                                    <div className="form-group row mb-2">

                                                                        <div className="col-md-3">
                                                                            <label className=" col-4" ><input className="checkbox_animated" id="chk-ani" type="checkbox" checked={prov.active_for.indexOf(ipm + 1) != -1} onChange={(e) => {


                                                                                let _metode = { ...metode }
                                                                                let _pm = { ...pm }
                                                                                let _prov = { ...prov }
                                                                                let _paymentMethods = [..._prov.active_for]
                                                                                if (e.target.checked) {
                                                                                    _paymentMethods.push(ipm + 1)

                                                                                } else {
                                                                                    _paymentMethods.splice(_paymentMethods.indexOf(ipm + 1), 1)

                                                                                }

                                                                                _prov.active_for = _paymentMethods
                                                                                _pm.provider[iprov] = _prov
                                                                                _metode.paymentMethods[ipm] = _pm
                                                                                setmetode(_metode)
                                                                            }} /> </label>

                                                                        </div>
                                                                        <label className="col-xl-9 col-md-9">{payfor}</label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>


                                                    </div>
                                                )}

                                            </section>
                                        })}
                                    </> :
                                        <>
                                            <div className="form-group row mb-2">
                                                <label className="col-xl-3 col-md-3">Name</label>
                                                <div className="col-md-9">
                                                    <input className="form-control" name="name" id="name-point" type="text" required="" onChange={(e) => {
                                                        let _metode = { ...metode }
                                                        _metode.name = e.target.value
                                                        setmetode(_metode)

                                                    }} />
                                                </div>
                                                {errorData.includes("name") && <small className="text-danger">Nama metode rekening dibutuhkan</small>}
                                            </div>
                                            <div className="form-group row mb-2">
                                                <label className="col-xl-3 col-md-3">Nomor rekening</label>
                                                <div className="col-md-9">
                                                    <input className="form-control" name="norek" id="name-point" type="text" required="" onChange={(e) => {
                                                        let _metode = { ...metode }
                                                        _metode.no_rek = e.target.value
                                                        setmetode(_metode)

                                                    }} />
                                                </div>
                                                {errorData.includes("no_rek") && <small className="text-danger">Nomor rekening dibutuhkan</small>}
                                            </div>
                                            <div className="form-group row mb-2">
                                                <label className="col-xl-3 col-md-3">Pemilik rekening</label>
                                                <div className="col-md-9">
                                                    <input className="form-control" name="nm_pemilik" id="name-point" type="text" required="" onChange={(e) => {
                                                        let _metode = { ...metode }
                                                        _metode.nm_pemilik = e.target.value
                                                        setmetode(_metode)
                                                    }} />
                                                </div>
                                                {errorData.includes("nm_pemilik") && <small className="text-danger">Nama pemilik rekening dibutuhkan</small>}
                                            </div>
                                            <div className="form-group row mb-2">
                                                <label className="col-xl-3 col-md-3">Nama Akun</label>
                                                <div className="col-md-9">
                                                    <input className="form-control" name="nm_account" id="name-point" type="text" required="" onChange={(e) => {

                                                        let _metode = { ...metode }
                                                        _metode.nm_account = e.target.value
                                                        setmetode(_metode)
                                                    }} />
                                                </div>
                                                {errorData.includes("nm_account") && <small className="text-danger">Nama akun dibutuhkan</small>}
                                            </div>
                                            <div className="form-group row mb-2">
                                                <label className="col-xl-3 col-md-3">Password akun</label>
                                                <div className="col-md-9">
                                                    <input className="form-control" name="pass_account" id="name-point" type="password" required="" onChange={(e) => {
                                                        let _metode = { ...metode }
                                                        _metode.pass_account = e.target.value
                                                        setmetode(_metode)
                                                    }} />
                                                </div>
                                                {errorData.includes("pass_account") && <small className="text-danger">Password akun dibutuhkan</small>}
                                            </div>

                                            <div className="form-group row mb-2">
                                                <label className="col-xl-3 col-md-3">Otomatis ambil transaksi bank</label>
                                                <div className="col-md-9">
                                                    <label className=" col-4 mt-2" ><input className="checkbox_animated" id="chk-ani" type="checkbox" defaultChecked={true} onChange={(e) => {


                                                        let _metode = { ...metode }
                                                        _metode.accept_pay = e.target.checked ? 1 : 0
                                                        setmetode(_metode)
                                                    }} /> </label>

                                                </div>
                                            </div>
                                            <div className="form-group row mb-2">
                                                <label className="col-xl-3 col-md-3">Tampilkan Pembayaran</label>
                                                <div className="col-md-9">
                                                    <label className=" col-4 mt-2" ><input className="checkbox_animated" id="chk-ani" type="checkbox" defaultChecked={true} onChange={(e) => {

                                                        let _metode = { ...metode }
                                                        _metode.status = e.target.checked ? 1 : 0
                                                        setmetode(_metode)

                                                    }} /> </label>

                                                </div>
                                            </div>
                                            <div className="form-group row mb-2">
                                                <label className="col-xl-3 col-md-3">Pembayaran Konsul</label>
                                                <div className="col-md-9">
                                                    <label className=" col-4 mt-2" ><input className="checkbox_animated" id="chk-ani" type="checkbox" defaultChecked={true} onChange={(e) => {

                                                        let _metode = { ...metode }
                                                        _metode.for_konsul = e.target.checked ? 1 : 0
                                                        setmetode(_metode)

                                                    }} /> </label>

                                                </div>
                                            </div>

                                        </>}



                                    <button type="button" className={`btn float-end mt-2 btn-primary`} onClick={() => {

                                        doCreate()

                                    }}>Save</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </ModalBody> : <ModalBody className='bg-white'>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input value={metode?.pay_name} onChange={(e) => {
                            let _oldPM = { ...metode }
                            _oldPM.pay_name = e.target.value
                            setmetode(_oldPM)
                        }} />

                    </FormGroup>

                    {!metode?.pay_name.includes("Bank") && <FormGroup>
                        <Label>Private KEY</Label>
                        <Input value={metode?.pay_key} onChange={(e) => {
                            let _oldPM = { ...metode }
                            _oldPM.pay_key = e.target.value
                            setmetode(_oldPM)
                        }} />

                    </FormGroup>}
                    {!metode?.pay_name.includes("Bank") && <FormGroup>
                        <Label>Public KEY</Label>
                        <Input value={metode?.pub_key} onChange={(e) => {
                            let _oldPM = { ...metode }
                            _oldPM.pub_key = e.target.value
                            setmetode(_oldPM)
                        }} />

                    </FormGroup>}
                    <FormGroup className='border px-2'>
                        <Label>Live Mode</Label>
                        <div className="form-check form-switch">

                            <input className="form-check-input" type="checkbox" defaultChecked={metode?.is_live} id="flexSwitchCheckDefault2" onChange={(e) => {
                                let _oldPM = { ...metode }
                                if (e.target.checked) {
                                    _oldPM.is_live = 1
                                    setmetode(_oldPM)


                                } else {
                                    _oldPM.is_live = 0
                                    setmetode(_oldPM)
                                }

                            }} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault2"> </label>
                        </div>


                    </FormGroup>
                    <FormGroup className='border px-2'>
                        <Label>Aktif</Label>
                        <div className="form-check form-switch">

                            <input className="form-check-input" type="checkbox" defaultChecked={metode?.status} id="flexSwitchCheckDefault" onChange={(e) => {
                                let _oldPM = { ...metode }
                                if (e.target.checked) {
                                    _oldPM.status = 1
                                    setmetode(_oldPM)


                                } else {
                                    _oldPM.status = 0
                                    setmetode(_oldPM)
                                }

                            }} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"> </label>
                        </div>


                    </FormGroup>
                    <hr />
                    <Button onClick={() => {
                        // console.log(metode)
                        // return

                        updateconfigs({
                            context: {
                                headers: {
                                    "option": "PAYMENTPARENT"
                                }
                            },
                            variables: {
                                body: metode,
                                id: "paymentparent"
                            }
                        })
                    }}>Simpan</Button>


                </ModalBody>}

            </Modal>
        </div>
    )
}

export default withApollo(BranchOrder)
