import React, { useContext, useRef, useEffect, useState, useCallback } from "react";
import SocketContext from '../../provider/socketctx/context';
import DispatchContext from '../../provider/socketctx/disptachContext';
import { sendNewMessage } from "../../provider/socket/emits";
import Tabset from './tabset-profile'
import history from '../../history';
import { useDropzone } from "react-dropzone";
import { withApollo } from '../../apollo'

import moment from 'moment'
//const SocketContext = createContext(null);
const Lobby = (props) => {
    //console.log(props)
    // const onDrop = useCallback(acceptedFiles => {
    //     // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
    //     console.log(acceptedFiles);
    //   }, []);
    // const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //     onDrop
    //   });
    const [selectedProductDrag, setSelectedProductDrag] = useState(null)


    const { positionInLine, queueLength, message } = useContext(SocketContext);
    const newMessage = useContext(DispatchContext)
    const bottomRef = useRef();
    const [mymessage, setMyMessage] = useState("")
    const [datakeranjang, setKranjang] = useState({ items: [] })
    const [dataproduk, setProduk] = useState({ items: props.data.products })
    const onDragOver = (event) => {
        event.preventDefault();
    }
    const onDrop = (event) => {
        // console.log(selectedProductDrag)
        if (selectedProductDrag) {
            const panajang = message[props.id]?.message.length
            let { from, to, socketId } = message[props.id]?.message[0]
            if (selectedProductDrag.type == 2) {
                if (!socketId)
                    sendNewMessage({ id: message[props.id].id, message: [{ body: selectedProductDrag.prod.name, fromMe: true, type: selectedProductDrag.type, from: from, to: to, socketId: socketId, id: selectedProductDrag.prod.id, imgurl: selectedProductDrag.prod.dokul_product_images[0].image_url }], userinfo: {} });
                else
                    sendNewMessage({ id: socketId, message: [{ body: selectedProductDrag.prod.name, fromMe: true, type: selectedProductDrag.type, from: from, to: to, socketId: socketId, id: selectedProductDrag.prod.id, imgurl: selectedProductDrag.prod.dokul_product_images[0].image_url }], userinfo: {} });
            }
            if (selectedProductDrag.type == 3) {
                if (!socketId)
                    sendNewMessage({ id: message[props.id].id, message: [{ body: selectedProductDrag.prod.name, fromMe: true, type: selectedProductDrag.type, from: from, to: to, socketId: socketId, id: selectedProductDrag.prod.id, imgurl: selectedProductDrag.prod.picture }], userinfo: {} });
                else
                    sendNewMessage({ id: socketId, message: [{ body: selectedProductDrag.prod.name, fromMe: true, type: selectedProductDrag.type, from: from, to: to, socketId: socketId, id: selectedProductDrag.prod.id, imgurl: selectedProductDrag.prod.picture }], userinfo: {} });
            }

            // sendNewMessage({ id: message[props.id].id, message: [{ body: selectedProductDrag.prod.name, fromMe: true, type: selectedProductDrag.type }], userinfo: {} });
            message[props.id].message.push({ body: selectedProductDrag.prod.name, fromMe: true, type: selectedProductDrag.type })
            newMessage("hhh")

        }
        console.log(selectedProductDrag?.name);
    }
    if (!message.length > 0) {
        history.push('/dashboard');
    }
    const filterProduct = (e) => {
        if (e.target.value != "") {
            var prodFIlter = props.data.products.filter(pr => pr.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setProduk({ items: prodFIlter })
        }
        else
            setProduk({ items: props.data.products })


    }
    const scrollToBottom = () => {
        if (bottomRef.current)
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });

    };
    useEffect(() => {
        scrollToBottom()
    }, [message.join(",")])

    useEffect(() => {

    }, [message])



    return (
        <div className="container" >
            {/* <input className="dropzone-input" {...getInputProps()} /> */}
            <h3 className=" text-center">PENGUNJUNG</h3>
            <div className="messaging">
                <div className="inbox_msg">
                    {/* <div className="inbox_people">
                        <div className="headind_srch">
                            <div className="recent_heading">
                                <h4>Recent</h4>
                            </div>
                            <div className="srch_bar">
                                <div className="stylish-input-group">
                                    <input type="text" className="search-bar" placeholder="Search" />
                                    <span className="input-group-addon">
                                        <button type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                                    </span> </div>
                            </div>
                        </div>
                        <div className="inbox_chat">
                            <div className="chat_list active_chat">
                                <div className="chat_people">
                                    <div className="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                                    <div className="chat_ib">
                                        <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                                        <p>Konsultasi jerawat</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}
                    <div className="mesgs" onDrop={event => onDrop(event)}
                        onDragOver={(event => onDragOver(event))}>
                        <div className="msg_history">


                            {message[props.id]?.message.map((msg, i) => {

                                return msg.fromMe ?
                                    <div key={i} ref={bottomRef} className="outgoing_msg">
                                        <div className="sent_msg">
                                            <p>{msg.body}</p>
                                            <span className="time_date"> {moment(msg.create_date).format("HH:mm:ss")}  </span> </div>
                                    </div>
                                    :
                                    <div ref={bottomRef} key={i} className="incoming_msg"   >
                                        <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                                        <div className="received_msg">
                                            <div className="received_withd_msg">
                                                <p>{msg.body}</p>
                                                <span className="time_date"> {moment(msg.create_date).format("HH:mm:ss")}     </span></div>
                                        </div>
                                    </div>


                            })}

                            {/* <div ref={bottomRef} className="list-bottom"></div> */}
                        </div>

                        <div className="type_msg">
                            <div className="input_msg_write">
                                <input type="text" value={mymessage} className="write_msg" placeholder="Type a message" onChange={(e) => setMyMessage(e.target.value)} />
                                <button className="msg_send_btn" type="button" onClick={() => {
                                    const panajang = message[props.id]?.message.length
                                    let { from, to, socketId } = message[props.id]?.message[0]
                                    if (!socketId) {
                                        console.log("SOCKETID false")
                                        sendNewMessage({ id: message[props.id].id, message: [{ body: mymessage, fromMe: true, type: 1, from: from, to: to, socketId: socketId }], userinfo: {} });
                                    } else {
                                        console.log("SOCKETID YES")
                                        sendNewMessage({ id: socketId, message: [{ body: mymessage, fromMe: true, type: 1, from: from, to: to, socketId: socketId }], userinfo: {} });
                                    }


                                    message[props.id].message.push({ body: mymessage, fromMe: true, type: 1 })
                                    //newMessage(props.props.info.email);

                                    setMyMessage("")
                                    newMessage("hhh")
                                    //scrollToBottom()
                                }}><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                            </div>
                        </div>

                    </div>
                    <div className="panel-bantuan">
                        <div className="row">

                            <div className="col-md-12">
                                <Tabset setSelectedProductDrag={setSelectedProductDrag} datakeranjang={datakeranjang} dataproduk={dataproduk} dataLayanans={props.data.layanans} data={props.data} filterProduct={filterProduct} dataUser={message[props.id]?.userinfo} setKranjang={setKranjang} />

                            </div>
                        </div>

                    </div>
                </div>



            </div></div>

    )
}
//export default Lobby;
export default withApollo(Lobby)