import React, { Component, Fragment } from 'react'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings } from 'react-feather'
import { Badge, Input, Col, FormGroup, Label, legend, Button, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap'
export class OrderTabset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order: this.props.order,
            popoverOpen: false,
            popoverOpen2: false,
            popoverOpen3: false,
            selectedItem: [],
            orderStatus: [
                '',
                'Waiting payment',
                'Waiting an item',
                'Packing',
                'Deliver',
                'Canceled',
                'Refund an items',
                'Refund order',
                'Completed'
            ],
            itemStatus: [
                '',
                'Waiting payment',
                'Waiting stock',
                'Packing',
                'Deliver',
                'Refund',
                'Completed'
            ]

        }
    }
    toggle = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        })
    }
    toggle2 = (resi) => {
        let order = { ...this.state.order }
        // order.order_status=status
        // order.order_status_text=this.state.orderStatus[status]
        this.props.updateOrder({ variables: { id: order.id, type: order } });
        this.setState({
            popoverOpen2: !this.state.popoverOpen2
        })

    }
    toggle3 = () => {
        this.setState({
            popoverOpen3: !this.state.popoverOpen3
        })

    }
    changeOrderStatus = (status) => {
        let order = { ...this.state.order }
        order.order_status = status
        order.order_status_text = this.state.orderStatus[status]
        this.props.updateOrder({ variables: { id: order.id, type: order } });
        this.setState({
            order: order,
            popoverOpen: !this.state.popoverOpen
        })


    }
    changePaymentStatus = (status) => {

        let order = { ...this.state.order }
        order.payment_status = status
        this.props.updateOrder({ variables: { id: order.id, type: order } });
        this.setState({
            order: order,
            popoverOpen3: !this.state.popoverOpen3
        })


    }
    changeItemsStatus = (status) => {
        let order = { ...this.state.order }
        let dokul_orders_items = [...order.dokul_orders_items]
        dokul_orders_items.forEach((item, index) => {
            let orderItem = { ...item }
            if (this.state.selectedItem.includes(item.id)) {
                orderItem.status = status
                dokul_orders_items[index] = orderItem

            }
        })
        order.dokul_orders_items = dokul_orders_items
        this.props.updateOrder({ variables: { id: order.id, type: order } });
        this.setState({ order: order })


    }
    selectAllItem = (e) => {
        let si = []
        if (e.target.checked)
            si = this.state.order.dokul_orders_items.map(item => item.id)

        this.setState({
            selectedItem: si
        })


    }
    changeItemSelect = (e, i) => {
        let si = this.state.selectedItem
        const index = si.indexOf(i)
        if (e.target.checked)
            si.push(i)
        else
            si.splice(index, 1)

        this.setState({
            selectedItem: si
        })

    }
    render() {

        const { order, popoverOpen, popoverOpen2, orderStatus, popoverOpen3, selectedItem, itemStatus } = this.state
        const { updateOrder } = this.props


        return (
            <div>






                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                        <Tab className="nav-link"><User className="mr-2" />Order</Tab>
                        <Tab className="nav-link"><Settings className="mr-2" />Item</Tab>
                        <Tab className="nav-link"><Settings className="mr-2" />Payment</Tab>
                        <Tab className="nav-link"><Settings className="mr-2" />Shipping</Tab>
                    </TabList>

                    <TabPanel>
                        <Popover
                            placement="right"
                            isOpen={popoverOpen}
                            target="PopoverLegacy-n"
                            toggle={this.toggle}
                        >
                            <PopoverHeader>Select status</PopoverHeader>

                            <div className="text-justify">
                                <div className="row" style={{ margin: 10 }}>
                                    <div className="col">


                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.order_status == 1 ? true : false} onClick={(e) => this.changeOrderStatus(1)} />
                                            <span className="badge badge-info" style={{ padding: 5 }}>Waiting payment</span>
                                        </label>
                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.order_status == 2 ? true : false} onClick={(e) => this.changeOrderStatus(2)} />
                                            <span className="badge badge-dark" style={{ padding: 5 }}>Waiting an items</span>
                                        </label>
                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.order_status == 3 ? true : false} onClick={(e) => this.changeOrderStatus(3)} />
                                            <span className="badge badge-secondary" style={{ padding: 5 }}>Packing</span>
                                        </label>
                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.order_status == 4 ? true : false} onClick={(e) => this.changeOrderStatus(4)} />
                                            <span className="badge badge-light" style={{ padding: 5 }}>Delivered</span>
                                        </label>
                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.order_status == 5 ? true : false} onClick={(e) => this.changeOrderStatus(5)} />
                                            <span className="badge badge-cred" style={{ padding: 5 }}>Canceled</span>
                                        </label>
                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.order_status == 6 ? true : false} onClick={(e) => this.changeOrderStatus(6)} />
                                            <span className="badge badge-warning" style={{ padding: 5 }}>Refund an items</span>
                                        </label>
                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.order_status == 7 ? true : false} onClick={(e) => this.changeOrderStatus(7)} />
                                            <span className="badge badge-red" style={{ padding: 5 }}>Refund order</span>
                                        </label>
                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.order_status == 8 ? true : false} onClick={(e) => this.changeOrderStatus(8)} />
                                            <span className="badge badge-success" style={{ padding: 5 }}>Completed</span>
                                        </label>

                                    </div>
                                </div>
                                {/* <button type="button" className="btn btn-primary  mb-2" onClick={this.toggle}>Update status</button> */}
                            </div>

                        </Popover>
                        <div className="tab-pane fade show active">
                            <h5 className="f-w-600 f-16">Order detail</h5>
                            <div className="table-responsive profile-table">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>ID:</td>
                                            <td>{order.id}</td>
                                        </tr>
                                        <tr>
                                            <td>Date:</td>
                                            <td>{order.created_date}</td>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <td>

                                                <h6>{orderStatus[order.order_status]} <Badge color="secondary" id="PopoverLegacy-n" style={{ cursor: 'pointer' }}><i className="fa fa-pencil"></i></Badge></h6>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total:</td>
                                            <td>{order.total}</td>
                                        </tr>
                                        <tr>
                                            <td>Admin fee:</td>
                                            <td>{order.admin_fee}</td>
                                        </tr>
                                        <tr>
                                            <td>Shiping fee:</td>
                                            <td>{order.ongkir}</td>
                                        </tr>
                                        <tr>
                                            <td>Payment code:</td>
                                            <td>{order.unix_payment}</td>
                                        </tr>
                                        <tr>
                                            <td>Point used:</td>
                                            <td>{order.point_used}</td>
                                        </tr>
                                        <tr>
                                            <td>Point earned:</td>
                                            <td>{order.point_earned}</td>
                                        </tr>
                                        <tr>
                                            <td>Coupon used:</td>
                                            <td>{order.coupon_used}</td>
                                        </tr>
                                        <tr>
                                            <td>total discount:</td>
                                            <td>{order.total_discount}</td>
                                        </tr>
                                        <tr>
                                            <td>Grand total:</td>
                                            <td>{order.grand_total}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        {/* <div className="tab-pane fade"> */}
                        {order.dokul_orders_items.map(item =>
                            <div className="row" style={{ marginBottom: 10 }}>
                                <div className="col-md-1">
                                    <span >
                                        <input type="checkbox" name={"itemstatus"} checked={selectedItem.includes(item.id)} onChange={(e) => this.changeItemSelect(e, item.id)} />

                                    </span>
                                </div>
                                <div className="col-md-2">
                                    <span className={`text-center badge badge-${item.status == 1 ? 'secondary' : item.status == 2 ? 'warning' : item.status == 3 ? 'success' : item.status == 4 ? 'primary' : item.status == 5 ? 'Success' : 'light'}`}>{`${itemStatus[item.status]}`}</span>
                                </div>
                                <div className="col-md-4">
                                    {item.product_name}
                                </div>
                                <div className="col-md-2">
                                    {item.price} X {item.quaintiy}
                                </div>
                                <div className="col-md-3">
                                    {item.subtotal}
                                </div>


                                <hr />
                            </div>
                        )}
                        <div className="row" style={{ marginBottom: 10 }}>
                            <div className="col-md-2">
                                <span >
                                    <input type="checkbox" name={"itemstatus"} onChange={this.selectAllItem} /> Select all

                                    </span>
                            </div>
                            {selectedItem.length > 0 ?
                                <div className="col-md-10">
                                    <FormGroup tag="fieldset" row>
                                        <legend className="col-form-label col-sm-12">With selected:</legend>
                                        <Col md={10}>

                                            <FormGroup check>
                                                <Input type="radio" name="radio2" id="radio2-option2" onChange={(e) => this.changeItemsStatus(1)} />
                                                <Label check for="radio2-option2" className="badge badge-warning">{itemStatus[1]}</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" name="radio2" id="radio2-option2" onChange={(e) => this.changeItemsStatus(2)} />
                                                <Label check for="radio2-option2" className="badge badge-primary">{itemStatus[2]}</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" name="radio2" id="radio2-option2" onChange={(e) => this.changeItemsStatus(3)} />
                                                <Label check for="radio2-option2" className="badge badge-success">{itemStatus[3]}</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" name="radio2" id="radio2-option2" onChange={(e) => this.changeItemsStatus(4)} />
                                                <Label check for="radio2-option2" className="badge badge-secondary">{itemStatus[4]}</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" name="radio2" id="radio2-option2" onChange={(e) => this.changeItemsStatus(4)} />
                                                <Label check for="radio2-option2" className="badge badge-red">{itemStatus[5]}</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input type="radio" name="radio2" id="radio2-option2" onChange={(e) => this.changeItemsStatus(5)} />
                                                <Label check for="radio2-option2" className="badge badge-info">{itemStatus[6]}</Label>
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                </div> : ''
                            }


                            <hr />
                        </div>
                        {/* </div> */}
                    </TabPanel>
                    <TabPanel>
                        <Popover
                            placement="right"
                            isOpen={popoverOpen3}
                            target="PopoverLegacy-3"
                            toggle={this.toggle3}
                        >
                            <PopoverHeader>Select status</PopoverHeader>

                            <div className="text-justify">
                                <div className="row" style={{ margin: 10 }}>
                                    <div className="col">


                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.payment_status == 2 ? true : false} onClick={(e) => this.changePaymentStatus(2)} />
                                            <span className="badge badge-success" style={{ padding: 5 }}>Confirmed</span>
                                        </label>
                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked={order.payment_status == 1 ? true : false} onClick={(e) => this.changePaymentStatus(1)} />
                                            <span className="badge badge-cred" style={{ padding: 5 }}>Unconfirmed</span>
                                        </label>


                                    </div>
                                </div>
                                {/* <button type="button" className="btn btn-primary  mb-2" onClick={this.toggle}>Update status</button> */}
                            </div>

                        </Popover>

                        <div className="tab-pane fade show active">
                            <h5 className="f-w-600 f-16">Payment detail</h5>
                            <div className="table-responsive profile-table">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Methode:</td>
                                            <td>{order.payment_method == 1 ? 'Bank Transfer BCA' : order.payment_method == 2 ? 'Bank Transfer MANDIRI' : 'QRIS'}: {order.payment_method_text}</td>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <td> <h6>{order.payment_status == 2 ? 'Confirmed' : 'Unconfirmed'} <Badge color="secondary" id="PopoverLegacy-3" style={{ cursor: 'pointer' }}><i className="fa fa-pencil"></i></Badge></h6></td>
                                        </tr>
                                        <tr>
                                            <td>Payment code:</td>
                                            <td>{order.unix_payment}</td>
                                        </tr>
                                        <tr>
                                            <td>Struk:</td>
                                            <td>
                                                <img className="img-fluid blur-up bg-img lazyloaded" src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/uploads/buktibayar/${order.bukti_bayar}`} />

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                    {order.shipping_address_id!=0?
                        <Popover
                            placement="right"
                            isOpen={popoverOpen2}
                            target="PopoverLegacy-2"
                            toggle={this.toggle2}
                        >
                            <PopoverHeader>Input tracking number</PopoverHeader>

                            <div className="text-center">
                                <div className="row" style={{ margin: 10 }}>
                                    <div className="col">


                                        <label className="d-block mb-2" >
                                            <input className="radio_animated" value={order.nomor_resi} id="edo-ani2" type="text" name="rdo-ani" onChange={(e) => {

                                                let order = { ...this.state.order }
                                                order.nomor_resi = e.target.value
                                                //this.props.updateOrder({ variables: { id:order.id, type: order } });
                                                this.setState({ order: order })
                                            }} />
                                        </label>


                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary  mb-2" onClick={this.toggle2}>Update</button>
                            </div>

                        </Popover>:""}
                        
                        <div className="tab-pane fade show active">
                            <h5 className="f-w-600 f-16">Shipment detail</h5>
                            <div className="table-responsive profile-table">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Courir service:</td>
                                            <td> {order.shipping_address_id!=0?order.layanan_kurir:"Ambil diklinik"}</td>
                                        </tr>
                                        {order.shipping_address_id!=0?
                                        <>
                                        <tr>
                                            <td>Tracking number:</td>
                                            <td><h6>{order.nomor_resi} <Badge color="secondary" id="PopoverLegacy-2" style={{ cursor: 'pointer' }}><i className="fa fa-pencil"></i></Badge></h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Receiver:</td>
                                            <td>{order.dokul_address.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone:</td>
                                            <td>{order.dokul_address.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Address:</td>
                                            <td>{order.dokul_address.address_1}</td>
                                        </tr>
                                        <tr>
                                            <td>Province:</td>
                                            <td>{order.dokul_address.address_state}</td>
                                        </tr>
                                        <tr>
                                            <td>City:</td>
                                            <td>{order.dokul_address.address_city}</td>
                                        </tr>
                                        <tr>
                                            <td>District:</td>
                                            <td>{order.dokul_address.address_2}</td>
                                        </tr>
                                        <tr>
                                            <td>Postcode:</td>
                                            <td>{order.dokul_address.address_postcode}</td>
                                        </tr>
</>:""}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default OrderTabset
