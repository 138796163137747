import React, { Component, Fragment, forwardRef, useState, useMemo, useEffect, useCallback } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import { Col, Collapse, Container, FormGroup, Input, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../../common/datatable';
import ReactTable from 'react-table';
import CurrencyInput from 'react-currency-input-field';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import CKEditors from "react-ckeditor-component";
import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { GET_DOKTERS, listMasalah, GET_KLINIKS, GET_LIST_KONSULTASI_EKSPRESS, GET_LIST_KONSULTASI_KONTROL, GET_LIST_SUBMIT_KONSULTASI, GET_RESERVASI, GET_SLOT_KONSULTASI, UPDATE_DOKTER, GET_PRODUCTS, GET_ROLES, UPDATE_USER_ROLE, UPDATE_USER_KONTROL, GET_CONFIGS, GET_CONFIGS_KONSUL_ANSTEMPLATE, DELETE_USER_KONTROL, GET_USERS, GET_KONSUL_PAYMENT_DETAIL, GET_PRODUCTS_SIMPLE } from '../../../constants/graph';
import data from '../../../assets/data/physical_list';
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { withApollo } from '../../../apollo'
import ReactImageMagnify from 'react-image-magnify';
import TableView from './table.view';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CreateProductKhusus from './create.produk'
import { updateSlotKonsul } from "../../../provider/socket/emits";
import Ckinput from './ckinput';
import DetailKontrol from './Detail.kontrol';
import Loading from '../../common/loading';
import ActionQuestion from '../../common/ActionQuestion';
import FilterReguler from './FilterReguler';
import FilterPremium from './FilterPremium';
import { validate } from 'graphql';

registerLocale('id', es)
moment.locale('id')
const ListKontrol = (props) => {
    const client = useApolloClient();
    const { loading, data: dataDokter, error, fetchMore, refetch } = useQuery(GET_USERS, {
        variables: {
            page: 0,
            size: 100,
            role: '12,14'

        }
    });
    const [getXenditDetail, { loading: lXenditDetail, error: eXenditDetail, data: dXenditDetail }] = useLazyQuery(GET_KONSUL_PAYMENT_DETAIL, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const charegDetail = JSON.parse(data.konsulPaymentDetail.charge.data_submit)
            console.log(charegDetail)
            setchargeDetail(charegDetail)
            setmodalXenditOpen(true)
        }
    })
    const [chargeDetail, setchargeDetail] = useState({})
    const [updateUserRoles] = useMutation(UPDATE_USER_ROLE)
    const [dataKonsul, setdataKonsul] = useState(props.dataKonsul)
    const [createProduct, setcreateProduct] = useState(false)
    const [loadingImage, setloadingImage] = useState(false)
    const [showLoading, setshowLoading] = useState(false)
    const { loading: lProduct, data: dProduct, error: eProduct } = useQuery(GET_PRODUCTS_SIMPLE,{
        variables:{
            textSeach:'all',
            brand:'all'
        }
    });
    const { loading: lConfig, data: dConfig, error: eConfig } = useQuery(GET_CONFIGS);
    const { loading: lAnsTemplate, data: dAnsTemplate, error: eAnsTemplate } = useQuery(GET_CONFIGS_KONSUL_ANSTEMPLATE);

    const [modalOpenChangeRole, setModalOpenChangeRole] = useState(false)
    const [colapseFilter, setcolapseFilter] = useState(false)
    const [collapseSetting, setcollapseSetting] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date())
    const [page, setpage] = useState(0)
    const [totalPages, settotalPages] = useState(0)
    const [showActionQuestion, setshowActionQuestion] = useState(false)
    const [showActionQuestionValid, setshowActionQuestionValid] = useState(false)
    const [pageSize, setpageSize] = useState(10)
    const [graphType, setgraphType] = useState(2)
    const [keperluan, setkeperluan] = useState(3)
    const [branchId, setbranchId] = useState(props.branch_id)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalXenditOpen, setmodalXenditOpen] = useState(false)
    const [modalOpenImage, setmodalOpenImage] = useState(false)
    const [selectedImage, setselectedImage] = useState()
    const [selectedKontrol, setselectedKontrol] = useState()
    const [selectedProduct, setselectedProduct] = useState([])
    const [modalProduk, setmodalProduk] = useState(false)
    const [textSeach, settextSeach] = useState("")
    const [stateQuery, setstateQuery] = useState([])
    const [deletedKonsul, setdeletedKonsul] = useState()
    const [validKonsul, setvalidKonsul] = useState()
    const status = ['', 'Menunggu pembayaran', 'Upload struk', 'Belum dijawab 1', 'Sudah dijawab 1', 'Belum dijawab 2', 'Sudah dijawab 2', '', '', 'Batal oleh system']
    const [statusSelected, setstatusSelected] = useState(0)

    // const { loading, error, data } = useQuery(GET_SLOT_KONSULTASI)
    const { loading: lRoles, error: eRoles, data: dRoles } = useQuery(GET_ROLES)
    const [requestParam, setrequestParam] = useState("")

    useEffect(() => {

        fetchData()
    }, [keperluan, props.branch_id, page, pageSize, statusSelected])
    useEffect(() => {
      if(validKonsul) setshowActionQuestionValid(true)
    
      return () => {
        return false
      }
    }, [validKonsul])
    

    const [dokterSelected, setdokterSelected] = useState([])
    const changeDokter = useCallback(
        (e) => {
            if (e.target.checked)
                setdokterSelected(x => [...x, e.target.value])
            else {

                setdokterSelected(x => x.filter(x2 => x2 != e.target.value))
            }
        },
        [setdokterSelected],
    )


    const updateCache = (cache, { data }) => {
        // console.log(data)
        const _updatedKontrol = { ...data.updateSubmitKontrol }

        const fragmentResult = cache.writeFragment({
            id: `SubmitKontrol:${_updatedKontrol.id}`,
            fragment: gql`
        fragment submitkontrol on SubmitKontrol{
            id
            status  
        }
      `,
            data: _updatedKontrol
        });
    };
    const [updateStatusKonsul] = useMutation(UPDATE_USER_KONTROL, { update: updateCache })
    const deleteCache = (cache, { data }) => {
        console.log(cache)
        console.log(data)
        // const existingKontrols = cache.readQuery({ query: GET_LIST_KONSULTASI_KONTROL, });
        // const newKontrols ={...existingKontrols}
        // const newKontrolsd = existingKontrols.dSubmitKonsul.data.filter(t => (t.id == 1));
        // newKontrols.data=newKontrolsd
        // cache.writeQuery({
        //     query: GET_LIST_KONSULTASI_KONTROL,
        //     data: { todos: newKontrols }
        // });
        // const currentData=dSubmitKonsul.submitkontrol
        // cache.evict({id:data.deleteSubmitKontrol.id})
        // cache.gc()
        // cache.modify({
        //     id: cache.identify(currentData),
        //     fields: {
        //         data(existingCommentRefs, { readField }) {
        //             console.log(existingCommentRefs)
        //             // return existingCommentRefs.filter(
        //             //     commentRef => idToRemove !== readField('id', commentRef)
        //             // );
        //         },
        //     },
        let email
        const curPar = {
            page: 0,
            size: 10,
            keperluan: keperluan,
            email: email,
            params: requestParam

        }
        let data_ = client.readQuery({ query: GET_LIST_KONSULTASI_KONTROL, variables: curPar });
        // console.log(data_)
        // let removedProduct = _.remove(GET_LIST_KONSULTASI_KONTROL.submitkontrol.data, function(konsul) { return konsul.id === 0 })
        // data_.submitkontrol.totalItems = 0
        // data_.submitkontrol.totalItems = 0

        client.writeQuery({
            query: GET_LIST_KONSULTASI_KONTROL,
            variables: curPar,
            data: { ...data_, totalItems: 0, data: [] }
        });
        //     // fields: {
        //     //     submitkontrol(dSubmitKonsul, { readField }) {
        //     //         console.log(dSubmitKonsul)
        //     //         let _curdata={...dSubmitKonsul}
        //     //         let _data= _curdata.data.filter(t => (t.id == 1));
        //     //         _curdata.data=_data
        //     //     return _curdata
        //     //   },
        //     // },
        // });
    };
    const [deleteKonsul] = useMutation(DELETE_USER_KONTROL, { update: deleteCache })

    // const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });


    // const selectedDokter = client.readFragment({
    //     id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
    //     fragment: gql`
    //       fragment readDokter on Dokter{
    //         id
    //         dokul_dokter_konsultasis
    //         fullname
    //         picture
    //         picture
    //         available
    //       }
    //     `,
    // }, false);
    // const { loading: loadingDokter, error: errorDokter, data: dataDokter } = useQuery(GET_DOKTERS)
    async function getQ_A(_id) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolQa/${_id}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            ///body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).catch(err => {
            setshowLoading(false)
        })
        if (response.ok)
            return response.json(); // parses JSON response into native JavaScript objects
        else
            return false
    }
    async function updateKonsultasi(_data) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/updateKonsultasi/${_data.id}`, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(_data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    async function getHistory(_id) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/kontrolHistory/${_id}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            ///body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }

    async function updateControlResult(_id, data, resultText = false, answerQuestion = false, medicalNote = false, product = false, note = false, refund = false, marked = false) {
        // console.log("update")
        // console.log(data)
        // return
        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/updateResult/${_id}`, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.

            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                "resultText": resultText,
                "answerQuestion": answerQuestion,
                "medicalNote": medicalNote,
                "product": product,
                "note": note,
                "refund": refund,
                "marked": marked,
                "idResult": selectedKontrol.result.dokul_konsultasi_kontrol_result?.id
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    const fetchData = (_state, instance) => {
        const state = _state ? _state : stateQuery
        // console.log(state)
        let param = `page=${page}&size=${pageSize}&`
        let email
        if (state && Array.isArray(state)) state.forEach(element => {
            if (element.id == 'email') email = element.value
            param = param + element.id + '=' + element.value + '&'
        })
        let listKonsulId = props.dataKonsul.map(x => x.id)

        let _keperluan=keperluan == 2 ? "rutin" : keperluan == 1 ? 'darurat' : keperluan == 4 ? "perdana" : keperluan>10?keperluan:''
        if(!isNaN(_keperluan )&& keperluan>10){
            listKonsulId=[keperluan]
            _keperluan=''
        }

        param = param + `branch_id=${props.branch_id}&listKonsulId=${listKonsulId.toString()}&masalah=${_keperluan}&status=${statusSelected}&startDate=${startDate}&startDate=${endDate}&byDokter=${dokterSelected.toString()}`
        //setstateQuery(state)
        setrequestParam(param)
        props.getDataSubmit({
            variables: {
                page: 0,
                size: 10,
                keperluan: _keperluan,
                email: email,
                params: `${param}`

            }
        })

    }
    // const setKontrol=useCallback(
    //   (konsul) => {
    //     first
    //   },
    //   [setse],
    // )

    // const refetchData = () => {
    //     const param = `page=${page}&size=${pageSize}&branch_id=${branchId}&masalah=${keperluan == 2 ? 'rutin' : keperluan == 1 ? 'iritasi' : ''}&status=${statusSelected}&startDate=${startDate}&startDate=${endDate}`
    //     //setstateQuery(state)
    //     getDataSubmit({
    //         variables: {
    //             page: 0,
    //             size: 10,
    //             keperluan: keperluan,
    //             email: undefined,
    //             params: `${requestParam}`

    //         }
    //     })

    // }
    useEffect(() => {
        // fetchData()

    }, [])
    useEffect(() => {
        if (selectedKontrol) {
            setModalOpen(true)
        }
    }, [selectedKontrol])
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }
    const handelValueChange = (e) => {
        const { value, name } = e.target
        let _c = stateQuery.find(x => x.id == name)
        if (!_c) {
            setstateQuery(x => [...x, { id: name, value: value }])
        } else {

            setstateQuery(x => {
                const _idx = x.findIndex(y => y.id == name)
                x.splice(_idx, 1)
                return [...x, { id: name, value: value }]
            })

        }

    }
    const CustomTableFilter = ({ filter, onChange }) => {
        return (
            <Input type="search" value={filter ? filter.value : ''}
                onChange={event => (event ? onChange(event.currentTarget.value) : "")} />
        );
    };

    const sleep = () => {
        return new Promise(resolve => setTimeout(resolve, 1000))
    }
    const handleXenditDetail = () => {

    }

    const columns = useMemo(
        () => [
            {
                Header: <b>No</b>,
                accessor: "id",
                width: 50,

                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className={`p-2 ${cellInfo.original.is_marked == 1 ? "bg-info" : "bg-light"} rounded text-dark`}>
                        {(cellInfo.index + 1) + (page * pageSize)}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },

            {
                Header: <b>Tanggal</b>,
                accessor: "created_date",
                width: 200,

                Cell: (cellInfo) => {
                    const jamsisa=moment().diff(moment(cellInfo.original.created_date), "hours")%24
                    return <div className="d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight">
                            {moment(cellInfo.original.created_date).format("DD MMMM YYYY HH:mm")}
                        </div>
                        <div className="bg-highlight text-center">
                            {moment().diff(moment(cellInfo.original.created_date), "days")>0&&moment().diff(moment(cellInfo.original.created_date), "days")+" Hari"}   {jamsisa>0&&" "+jamsisa+" Jam"}
                        </div>
                        {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                    </div>
                },//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Nominal</b>,
                accessor: "grand_total",
                width: 100,
                // filterable: true,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.grand_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>

                </div>,
                // Filter: CustomTableFilter,
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Masalah</b>,
                accessor: "masalah",
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.masalah}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Pasien</b>,
                accessor: "first_name",
                // filterable: true,
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.dokul_user.first_name} {cellInfo.original.dokul_user.last_name}
                        {/* {cellInfo.original.dokul_user.tgl_lahir} */}
                    </div>
                   {props.currentUser.id==45252&& <>
                    <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.dokul_user.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight">
                        <span className="bg-success p-2 rounded">{cellInfo.original.dokul_user.status_kawin}</span>
                    </div>
                    <div className="p-2 bd-highlight">
                        <span className="bg-success p-2 rounded">{cellInfo.original.dokul_user.phone}</span>
                    </div>
                    </>}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),

                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Email</b>,
                accessor: "email",
                // filterable: true,
                width: 200,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.dokul_user.email}
                    </div>
                    {/* <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div> */}
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Status</b>,
                accessor: "status",
                width: 200,
                Cell: (cellInfo) => {
                    if (cellInfo.original.status == 98) return <div className="d-flex flex-column bd-highlight mb-3"><Badge color='danger' className='p-2'> Deleted</Badge></div>
                    if (cellInfo.original.is_rerequest == 1) return <div className="d-flex flex-column bd-highlight mb-3"><Badge color='danger' className='p-2'> Menunggu data tambahan</Badge></div>
                    if (cellInfo.original.status == 32) return <div className="d-flex flex-column bd-highlight mb-3"><Badge color='primary' className='p-2'> Menunggu data pasien</Badge></div>
                    const _status = cellInfo.original.status_admin ? cellInfo.original.status_admin==42?4:cellInfo.original.status_admin  : cellInfo.original.status// cellInfo.original.status > 10 ? cellInfo.original.status.toString().charAt(0) : cellInfo.original.status
                    let _nextQA = ""
                    // if (_status == 3 || _status == 31) {
                    //     _nextQA = cellInfo.original.result_question ? 2 : 1
                    // }
                    // if (_status == 4 || _status == 41) {
                    //     _nextQA = cellInfo.original.question_answer ? 2 : 1
                    // }

                    return <div className="d-flex flex-column bd-highlight mb-3">
                        {cellInfo.original.dokterhandle && <Label className='text-main'><strong>By : {cellInfo.original.dokterhandle?.first_name} {cellInfo.original.dokterhandle?.last_name}</strong></Label>}
                        
                        <Badge className={`p-2 ${_status == 1 && "bg-success"} ${_status == 2 && "bg-success"} ${_status == 9 && "bg-danger"} ${_status == 3 && "bg-info"} ${_status == 4 && "bg-secondary"}`}>{status[_status] ? status[_status] : status[_status]}  {_nextQA} </Badge>
                        { cellInfo.original.produk_required==1 &&cellInfo.original.konsul_type==3  && cellInfo.original.status!=9 &&<Label className='text-danger text-center'><strong>Menunggu Apotek</strong></Label>}
                        {cellInfo.original.bukti_bayar && <Badge className="btn-outline text-info pointer bg-light p-2 align-self-center m-2" onClick={() => {
                            // console.log(cellInfo.original)
                            setloadingImage(true)
                            setselectedImage(`/assets/images/uploads/buktibayar/${cellInfo.original.bukti_bayar}`)

                            setmodalOpenImage(true)
                        }}> Lihat struk</Badge>}

                    </div>
                },
                style: {
                    textAlign: 'left',
                }
            },
            {
                Header: <b>Pembayaran</b>,
                accessor: "created_date",
                // filterable: true,
                width: 80,
                Cell: (cellInfo) => {
                    const payMethode = cellInfo.original.dokul_paymethod
                    return <div className="d-flex flex-column bd-highlight mb-3">
                        {payMethode.type < 30 && <Badge className="p-2 bg-info">
                            {cellInfo.original.dokul_paymethod.name}
                        </Badge>}
                        {payMethode.type > 30 && payMethode.type < 40 && <Badge className="p-2 bg-info">Credit Card</Badge>}
                        {payMethode.type === 41 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/ovo-logo.svg`} className='img-fluid  w-75' />}
                        {payMethode.type === 42 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/shopeepay-logo.svg`} className='img-fluid  w-75' />}
                        {payMethode.type === 43 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/dana-logo.svg`} className='img-fluid  w-75' />}

                        {payMethode.type === 51 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bca-logo.svg`} className='img-fluid  w-75' /></>}
                        {payMethode.type === 52 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/mandiri-logo.svg`} className='img-fluid w-75' /></>}
                        {payMethode.type === 53 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bri-logo.svg`} className='img-fluid  w-75' /></>}
                        {payMethode.type === 54 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bni-logo.svg`} className='img-fluid  w-75' /></>}
                        {payMethode.type === 55 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/permata-logo.svg`} className='img-fluid  w-75' /></>}
                        {payMethode.type === 56 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bsi-logo.svg`} className='img-fluid  w-75' /></>}


                    </div>
                },//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                width: 200,
                accessor: str => "delete",
                Cell: (row) => (
                    row.original.status == 98) ? "" : (<div>
                        {(row.original.status == 1 || row.original.status == 2 || row.original.status == 11 || row.original.status == 21) && <span className="border  p-1 m-1" onClick={async () => {
                        //  console.log(row.original);
                           setvalidKonsul(row.original)
                           

                        }}>
                            <i className="fa fa-thumbs-up" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                            {row.original.status }
                        </span>}
                        {row.original.status>2&&<span className="border p-1 m-1" onClick={async () => {
                            setshowLoading(true)
                            const _dataQa = await getQ_A(row.original.id)
                            const _dataHistory = await getHistory(row.original.user_id)
                            setshowLoading(false)
                            // console.log(_dataQa)
                            let _selectedKontrol = { ...row.original }
                            _selectedKontrol["qa"] = _dataQa ? _dataQa.qa : []
                            _selectedKontrol["result"] = _dataQa ? _dataQa.result : {}
                            _selectedKontrol["history"] = _dataHistory ? _dataHistory.history : []
                            if (_dataQa.result) {
                                // setContent(_dataQa.result.dokul_konsultasi_kontrol_result?.result_text)
                                // setContent2(_dataQa.result.question_answer)
                                // setcontentUserRekamMedis(_selectedKontrol.dokul_user.medical_note)
                                const _cproduct = _dataQa.result.dokul_konsultasi_kontrol_result_produks.map(x => {
                                    const _prod = dProduct.productssimple.find(p => p.id == x.produk_id)
                                    if(_prod)
                                        return { ..._prod, limit_order: x.limit_order, status: x.status }
                                    else{
                                        return { ...x.dokul_product, limit_order: x.limit_order, status: x.status }
                                    }
                                })
                                setselectedProduct(_cproduct)
                            }
                            setselectedKontrol(_selectedKontrol)

                        }}>
                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                        </span>
            }

                        <span className="border  p-1 m-1" onClick={async () => {
                            setshowActionQuestion(true)
                            setdeletedKonsul(row.original)
                            // deleteKonsul({ variables: { id: row.original.id } })

                        }}>
                            <i className="fa fa-times" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(252, 7, 3)', cursor: 'pointer' }}></i>
                        </span>
                        <span className="border  p-1 m-1" onClick={async () => {
                            getXenditDetail({
                                variables: {
                                    id: row.original.id
                                }
                            })
                            // setshowActionQuestion(true)
                            // setdeletedKonsul(row.original)
                            // deleteKonsul({ variables: { id: row.original.id } })

                        }}>
                            <i className="fa fa-money" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(0,128, 255)', cursor: 'pointer' }}></i>
                        </span>



                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        ])

    const handleLoadedImg = () => {
        setloadingImage(false)
    }
    // if (loading  || lConfig || lAnsTemplate) return   <Loading show={true} />

    // console.log(data.slots)
    return (
        <Fragment>
            <Loading show={lConfig || lAnsTemplate || showLoading} />
            <ActionQuestion show={showActionQuestion} title={"Hapus data"} question={"Apakah yakin untuk menghapus data ini?"} setCancel={() => setshowActionQuestion(false)} setOk={() => {
                deleteKonsul({ variables: { id: deletedKonsul.id } })
                setshowActionQuestion(false)
            }} />
            <ActionQuestion show={showActionQuestionValid} title={"Valid payment"} question={"Apakah yakin untuk validasi payment data ini?"} setCancel={() => setshowActionQuestionValid(false)} setOk={() => {
                 updateStatusKonsul(
                    {
                        variables: {
                            data: { status: validKonsul.konsul_type == 3 ? 32 : 3 },
                            id: validKonsul.id
                        }
                    }
                )
              
                setshowActionQuestionValid(false)

            }} />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="col-12 bg-main border p-2">
                    <div className=" p-0 mb-3 d-flex flex-column ">
                        <div className='d-flex flex-row bg-white text-main w-100 p-2 border-bottom'>
                            <h4 className='w-100'> {props.branch_id == 1 ? "Jakarta" : "Balikpapan"}</h4>
                            <button className='btn btn-xs btn-primary flex-shrink-1' onClick={() => setcollapseSetting(!collapseSetting)}>{collapseSetting ? "Hide setting" : "Show setting"}</button>
                        </div>

                        <Collapse isOpen={collapseSetting} className="row border-bottom">
                            {dataKonsul.filter(x => x.status != 100).map((dkonsul, idx) =>
                                <div key={idx} className="col-6 p-2">
                                    <div><h5 className='w-100 bg-primary p-2'>{dkonsul.name}</h5></div>
                                    <div className='mx-0 p-2'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input bg-main" type="checkbox" id={`aktif${dkonsul.id}`} defaultChecked={dkonsul.status == 1} onChange={(e) => {
                                                const _ck = [...dataKonsul]
                                                const idxKonsul = _ck.findIndex(x => x.id == dkonsul.id)
                                                const curKonsul = { ..._ck[idxKonsul] }
                                                if (e.target.checked) {
                                                    curKonsul.status = 1
                                                } else {
                                                    curKonsul.status = 0
                                                }
                                                _ck[idxKonsul] = curKonsul
                                                setdataKonsul(_ck)
                                            }} />
                                            <label className="form-check-label" htmlFor={`aktif${dkonsul.id}`}>Enable</label>
                                        </div>
                                        <hr />
                                        <Row>
                                            <Col md="4">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input bg-main" type="checkbox" id={`unlimit${dkonsul.id}`} defaultChecked={dkonsul.is_unlimited == 1} onChange={(e) => {
                                                        const _ck = [...dataKonsul]
                                                        const idxKonsul = _ck.findIndex(x => x.id == dkonsul.id)
                                                        const curKonsul = { ..._ck[idxKonsul] }
                                                        if (e.target.checked) {
                                                            curKonsul.is_unlimited = 1
                                                        } else {
                                                            curKonsul.is_unlimited = 0
                                                        }
                                                        _ck[idxKonsul] = curKonsul
                                                        setdataKonsul(_ck)
                                                    }} />
                                                    <label className="form-check-label" htmlFor={`unlimit${dkonsul.id}`}>Unlimited</label>
                                                </div>
                                            </Col>
                                            {dkonsul.is_unlimited == 0 && <Col md="8">
                                                <FormGroup row>
                                                    <Label
                                                        for="slotperdana"
                                                        size="sm"
                                                        sm={6}
                                                    >Available slot</Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            bsSize="sm"
                                                            id="slotperdana"
                                                            name="slotperdana"
                                                            defaultValue={dkonsul.dokul_user_konsultasi_kontrols.length}
                                                            type="number"
                                                            onChange={(e) => {

                                                                const _ck = [...dataKonsul]
                                                                const idxKonsul = _ck.findIndex(x => x.id == dkonsul.id)
                                                                const curKonsul = { ..._ck[idxKonsul] }
                                                                curKonsul.max_pasien = parseInt(e.target.value)
                                                                _ck[idxKonsul] = curKonsul
                                                                setdataKonsul(_ck)
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            }
                                        </Row>

                                        <hr />
                                        <FormGroup row>
                                            <Label
                                                for="slotperdana"
                                                size="sm"
                                                sm={6}
                                            >Biaya</Label>
                                            <Col sm={6}>
                                                {/* <Input
                                                    bsSize="sm"
                                                    id="slotperdana"
                                                    name="slotperdana"
                                                    defaultValue={dkonsul.harga}
                                                    type="number"
                                                    onChange={(e) => {


                                                    }}
                                                /> */}
                                                <CurrencyInput
                                                    className='p-2 m-2 border rounded border-light'
                                                    id="input-example"
                                                    name="input-name"
                                                    placeholder="Please enter a number"
                                                    defaultValue={dkonsul.harga}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => {
                                                        // let _newSesi = { ...newSesi }
                                                        // _newSesi.biaya =value
                                                        // setnewSesi(_newSesi)
                                                        const _ck = [...dataKonsul]
                                                        const idxKonsul = _ck.findIndex(x => x.id == dkonsul.id)
                                                        const curKonsul = { ..._ck[idxKonsul] }
                                                        curKonsul.harga = value
                                                        _ck[idxKonsul] = curKonsul
                                                        setdataKonsul(_ck)
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <hr />
                                        <FormGroup row>
                                            <Label
                                                for="slotperdana"
                                                size="sm"
                                                sm={6}
                                            >Voucher</Label>
                                            <Col sm={6}>
                                                {/* <Input
                                                    bsSize="sm"
                                                    id="slotperdana"
                                                    name="slotperdana"
                                                    defaultValue={dkonsul.voucher_back}
                                                    type="number"
                                                    onChange={(e) => {


                                                    }}
                                                /> */}
                                                <CurrencyInput
                                                    className='p-2 m-2 border rounded border-light'
                                                    id="input-example"
                                                    name="input-name"
                                                    placeholder="Please enter a number"
                                                    defaultValue={dkonsul.voucher_back}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => {
                                                        const _ck = [...dataKonsul]
                                                        const idxKonsul = _ck.findIndex(x => x.id == dkonsul.id)
                                                        const curKonsul = { ..._ck[idxKonsul] }
                                                        curKonsul.voucher_back = value
                                                        _ck[idxKonsul] = curKonsul
                                                        setdataKonsul(_ck)
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <hr />
                                        <FormGroup row>
                                            <Label
                                                for="refundback"
                                                size="sm"
                                                sm={6}
                                            >Refund</Label>
                                            <Col sm={6}>
                                                {/* <Input
                                                    bsSize="sm"
                                                    id="slotperdana"
                                                    name="slotperdana"
                                                    defaultValue={dkonsul.voucher_back}
                                                    type="number"
                                                    onChange={(e) => {


                                                    }}
                                                /> */}
                                                <CurrencyInput
                                                    className='p-2 m-2 border rounded border-light'
                                                    id="input-example"
                                                    name="input-name"
                                                    placeholder="Please enter a number"
                                                    defaultValue={dkonsul.refund_back}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => {
                                                        const _ck = [...dataKonsul]
                                                        const idxKonsul = _ck.findIndex(x => x.id == dkonsul.id)
                                                        const curKonsul = { ..._ck[idxKonsul] }
                                                        curKonsul.refund_back = value
                                                        _ck[idxKonsul] = curKonsul
                                                        setdataKonsul(_ck)
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>


                                        <hr />
                                        <button className='btn btn-primary btn-xs' onClick={() => {
                                            const _ck = [...dataKonsul]
                                            const idxKonsul = _ck.findIndex(x => x.id == dkonsul.id)
                                            const curKonsul = { ..._ck[idxKonsul] }
                                            updateKonsultasi(curKonsul)
                                        }}>Save</button>
                                    </div>
                                </div>
                            )}
                            {/* <button className='btn btn-primary btn-xs' onClick={()=>{
                                                   updateSlotKonsul({konsulId:4,max_pasien:10,current_pasien:10,status:0})
                                                }}>testsocket</button> */}
                            {/* <div className="col-6 p-2">
                                <div><h5 className='w-100 bg-primary p-2'>Kontrol</h5>
                                    <div className='mx-0'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input bg-main" type="checkbox" id="aktifkonsulkonstrol" defaultChecked={dConfig.configs.find(x=>x.id==1).dokul_configs.find(x=>x.name=="konsul_kontrol").nilai=="1"} onChange={(e) => {
                                                if (e.target.checked) {
                                                    // setselectedKontrol({ ...selectedKontrol, is_marked: 1 })
                                                    // updateControlResult(selectedKontrol.id, { is_marked: 1 }, false, false, false, false, false, false, true)
                                                } else {
                                                    // setselectedKontrol({ ...selectedKontrol, is_marked: 0 })
                                                    // updateControlResult(selectedKontrol.id, { is_marked: 0 }, false, false, false, false, false, false, true)
                                                }
                                            }} />
                                            <label className="form-check-label" htmlFor="aktifkonsulkonstrol">Enable</label>
                                        </div>
                                        <FormGroup row>
                                            <Label
                                                for="slotkontrol"
                                                size="sm"
                                                sm={4}
                                            >Slot perhari</Label>
                                            <Col sm={3}>
                                                <Input
                                                    bsSize="sm"
                                                    id="slotkontrol"
                                                    name="slotkontrol"
                                                    value={dConfig.configs.find(x=>x.id==1).dokul_configs.find(x=>x.name=="konsul_kontrol_max").nilai}
                                                    type="number"
                                                />
                                            </Col>
                                            <Col sm={5}>
                                            <button className='btn btn-primary btn-xs'>Save</button>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div> */}
                        </Collapse>

                    </div>


                    <div className=" p-0 mb-3 d-flex flex-column ">

                        <div>
                            <button className='btn btn-xs btn-primary m-2 float-end' onClick={() => setcolapseFilter(!colapseFilter)}>{colapseFilter ? "Hide filter" : "Show filter"}</button>
                        </div>
                    </div>

                </div>
                <Collapse isOpen={colapseFilter}>
                    <div className="row">
                        <div className="col-7 m-0 boder">
                            {/* <div className="d-flex flex-row border">
                        <h6 className="title m-2">Dokterkulitku</h6>
                        <div className="form-check p-2 ms-auto">
                            <input className="form-check-input" type="checkbox" name="branch" id="branch" checked={branchSelected == 1} onClick={() => setbranchSelected(1)} />
                            <label className="form-check-label" htmlFor="branch">Jakarta</label>
                        </div>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="checkbox" name="branch" id="branch" checked={branchSelected == 2} onClick={() => setbranchSelected(1)} />
                            <label className="form-check-label" htmlFor="branch">Balikpapan</label>
                        </div>

                    </div> */}
                            {props.type!=3&&<FilterReguler dataDokter={dataDokter} changeDokter={changeDokter}  setkeperluan={setkeperluan} keperluan={keperluan} statusSelected={statusSelected} setstatusSelected={setstatusSelected}/>}
                            {props.type==3&&<FilterPremium dataDokter={dataDokter} changeDokter={changeDokter}  setkeperluan={setkeperluan} keperluan={keperluan} statusSelected={statusSelected} setstatusSelected={setstatusSelected}/>}

                        </div>
                        <div className="col-5 m-0 border">
                            <div className="d-flex flex-row ">
                                <h6 className="title  m-2">Total</h6>
                                <div className="form-check p-2 ms-auto">
                                    <Input type="search" name="grand_total" value={stateQuery.find(x => x.id == 'grand_total')?.value}
                                        onChange={handelValueChange} />
                                </div>

                            </div>


                            <div className="d-flex flex-row ">
                                <h6 className="title  m-2">Nama Pasien</h6>
                                <div className="form-check p-2 ms-auto">
                                    <Input type="search" name="first_name" value={stateQuery.find(x => x.id == 'first_name')?.value}
                                        onChange={handelValueChange} />
                                </div>

                            </div>
                            <div className="d-flex flex-row ">
                                <h6 className="title  m-2">Email Pasien</h6>
                                <div className="form-check p-2 ms-auto">
                                    <Input type="search" name="email" value={stateQuery.find(x => x.id == 'email')?.value}
                                        onChange={handelValueChange} />
                                </div>

                            </div>
                            <div className="d-flex flex-row border">
                                <h6 className="title m-2">Ditandai</h6>
                                <div className="form-check p-2 ms-auto">
                                    <input className="form-check-input" type="checkbox" name="is_marked" id="is_marked" checked={stateQuery.find(x => x.id == 'is_marked')?.value == 1} onChange={(e) => {
                                        if (e.target.checked) {
                                            setstateQuery(x => [...x, { id: "is_marked", value: 1 }])
                                        } else {
                                            setstateQuery(x => {
                                                const _idx = x.findIndex(y => y.id == "is_marked")
                                                x.splice(_idx, 1)
                                                return [...x]
                                            })
                                        }
                                    }} />
                                    {/* <label className="form-check-label" htmlFor="branch">Jakarta</label> */}
                                </div>


                            </div>

                        </div>
                        <div className="col-12">
                            <div className=" p-0 mb-3 d-flex justify-content-end border-end border-start border-bottom">
                                <button className='btn btn-xs btn-primary m-2' onClick={() => { fetchData() }}>Reload</button>
                            </div>
                        </div>
                    </div>
                </Collapse>
                <div className="row">
                    <div className="col-sm-3">


                    </div>
                    <div className="col-sm-12">
                        {/* <TableView pageSize={pageSize} listData={listData} page={page} totalPages={totalPages} fetchData={fetchData} /> */}

                        {props.dSubmitKonsul && <ReactTable
                            data={props.dSubmitKonsul ? props.dSubmitKonsul.submitkontrol.data : []}
                            columns={columns}
                            defaultPageSize={pageSize}
                            manual={true}
                            className={"-striped -highlight"}
                            showPagination={true}
                            autoResetPage={true}
                            page={props.dSubmitKonsul.submitkontrol.currentPage}
                            pages={props.dSubmitKonsul.submitkontrol.totalPages}
                            onPageChange={(e) => setpage(e)}
                            onPageSizeChange={(e) => setpageSize(e)}
                            defaultFiltered={stateQuery}
                            loadingText="Mengambil data dari server"
                            nextText="Selanjutnya"
                            previousText="Sebelumnya"
                            showPaginationTop={true}
                            pageText="Halaman"
                            ofText="dari"
                            rowsText="Baris"
                            // onFetchData={(val) => fetchData(val)}
                            onFilteredChange={(val) => fetchData(val)}


                        />}

                        {/* <div className="card"> */}
                        {/* <div className="card-header bg-primary text-white">
                                <span className="float-left">
                                    <div className="d-flex flex-row bd-highlight ">
                                        <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">Sesi Konsultasi</h4></div>
                                      

                                    </div>
                                </span>

                            </div> */}

                        {/* <div className="card-body"> */}

                        {/* <div className="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                            <ToastContainer />
                        </div>

                        {/* {data.dokters[idxDokter].dokul_dokter_konsultasis.filter(ykon => ykon.type == 2).length > 0 ?
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                        <h6 className="border-bottom border-info"><i className="icofont icofont-globe" /> ONLINE </h6>
                                        <div className="p-2 bd-highlight mb-2">
                                            <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-left">
                                                <div className="d-flex flex-row">
                                           
                                                </div>
                                                <Link
                                                    to={`/products/edit/${data.dokters[idxDokter].dokul_dokter_konsultasis.find(onk => onk.type == 2).id_product}`}
                                                >
                                                    <span className="p-2 bd-highlight rounded bg-success">EDIT PRODUK </span>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                } */}
                    </div>
                </div>
            </div>
            <div className="clearfix" />
            {/* </div> */}
            {/* </div> */}
            <Modal isOpen={modalProduk} toggle={() => setmodalProduk(false)} centered size={`${createProduct ? "lg" : "lg"}`}>
                <ModalHeader className="bg-primary" toggle={() => setmodalProduk(false)}>{createProduct ? "Create" : "Tambah"} rekomendasi produk</ModalHeader>
                <ModalBody className="bg-light text-dark">
                    {!createProduct && <Row>
                        {/* {dProduct && selectedKontrol && dProduct.products.filter(x => x.name.toLowerCase().includes(textSeach.toLowerCase())).map((x, idx) => */}
                        <Input className="mt-2 mb-3" type="search" placeholder="Cari produk" onChange={(e) => settextSeach(e.target.value)} />

                        {dProduct && selectedKontrol && dProduct.productssimple.filter(x => x.branch_id == selectedKontrol.branch_id && x.name.toLowerCase().includes(textSeach.toLowerCase())).map((x, idx) =>
                            <FormGroup key={idx} check className="col-6">
                                <Label check >
                                    <Input type="checkbox" checked={selectedProduct.some(p => p.id == x.id)} onClick={(e) => {
                                        let _currentSelectedProduct = [...selectedProduct]
                                        if (e.target.checked) {
                                            _currentSelectedProduct.push(x)
                                        } else {
                                            const _idx = _currentSelectedProduct.findIndex(_p => _p.id == x.id)
                                            _currentSelectedProduct.splice(_idx, 1)
                                        }
                                        setselectedProduct(_currentSelectedProduct)
                                    }} />{' '}
                                    {x.name}{x.branch_id == 1 ? " ( JAKARTA )" : " ( BALIKPAPAN )"}
                                </Label>
                            </FormGroup>)}
                    </Row>}
                    {createProduct && <Row>
                        <CreateProductKhusus />
                    </Row>}
                </ModalBody>

            </Modal>


            <Modal isOpen={modalOpen} toggle={() => {

                setModalOpen(false)
                setselectedKontrol()

            }} centered fullscreen className='border'>

                <ModalHeader className="bg-primary" toggle={() => {

                    setModalOpen(false)
                    setselectedKontrol()
                }}>Detail Kontrol Konsultasi</ModalHeader>
                {selectedKontrol && <DetailKontrol ansTemplate={dAnsTemplate.ansTemplate.filter(x => x.branch_id == props.branch_id)} dConfig={dConfig.configs.find(x => x.id == props.branch_id)} selectedKontrol={selectedKontrol} setModalOpenRoot={setModalOpen} selectedProduct={selectedProduct} fetchData={fetchData} />}

            </Modal>
            <Modal isOpen={modalXenditOpen} toggle={() => {

                setmodalXenditOpen(false)

            }} centered>

                <ModalHeader className="bg-primary" toggle={() => {

                    setmodalXenditOpen(false)
                }}>Payment Charge</ModalHeader>

                {dXenditDetail && <ModalBody className='bg-white'>
                    {dXenditDetail.konsulPaymentDetail.charge?.payment_type > 50 && <dl className="row">
                        <dt className="col-sm-3">Account</dt>
                        <dd className="col-sm-9">{chargeDetail.account_number}</dd>

                        <dt className="col-sm-3">Bank</dt>
                        <dd className="col-sm-9">{chargeDetail.bank_code}</dd>

                        <dt className="col-sm-3">External ID</dt>
                        <dd className="col-sm-9">{chargeDetail.external_id}</dd>


                    </dl>}
                    {dXenditDetail.konsulPaymentDetail.charge?.payment_type > 40 && dXenditDetail.konsulPaymentDetail.charge?.payment_type < 50 && <dl className="row">
                        <dt className="col-sm-3">Account</dt>
                        <dd className="col-sm-9">{chargeDetail.channel_properties?.mobile_number}</dd>

                        <dt className="col-sm-3">Channel</dt>
                        <dd className="col-sm-9">{chargeDetail.channel_code}</dd>

                        {chargeDetail.channel_code?.includes("SHOPEE") && <><dt className="col-sm-3">Payment Link</dt>
                            <dd className="col-sm-9">
                                <p style={{ overflowWrap: 'break-word' }}>{chargeDetail.channel_code?.includes("SHOPEE") && chargeDetail.actions.mobile_deeplink_checkout_url}</p>
                            </dd>
                        </>}


                    </dl>}
                    {dXenditDetail.konsulPaymentDetail.charge?.payment_type > 30 && dXenditDetail.konsulPaymentDetail.charge?.payment_type < 40 && <dl className="row">
                        <dt className="col-sm-3">Account</dt>
                        <dd className="col-sm-9">{chargeDetail.account_number}</dd>

                        <dt className="col-sm-3">Bank</dt>
                        <dd className="col-sm-9">{chargeDetail.bank_code}</dd>

                        <dt className="col-sm-3">External ID</dt>
                        <dd className="col-sm-9">{chargeDetail.external_id}</dd>


                    </dl>}
                </ModalBody>}

            </Modal>
            {/* <Modal className="bg-white rounded" size='xl' isOpen={false} toggle={() => setmodalEditText(false)}>
                <ModalHeader className="bg-light" toggle={() => setmodalEditText(false)}>
                    {editedText == 1 ? "Hasil konsultasi/kontrol" : editedText == 2 ? "Jawaban pertanyaan pasien" : editedText == 3 ? "Catatan medis pasien" : editedText == 4 ? "Catatan konsultasi" : ""}

                </ModalHeader >
                <ModalBody className="bg-white">
                    <CKEditors
                        // config={{ height: 400 }}
                        activeclassName="p10"
                        content={content}
                        events={{
                            "blur": (val) => {
                                // let _product = { ...product }
                                // _product.description = val.editor.getData()
                                // setProduct(_product)
                                setContent(val.editor.getData())
                            },
                            //"afterPaste": (val) => product.description=val.editor.getData(),
                            "change": (val) => {
                                // let _product = { ...product }
                                // // console.log(_product)
                                // //console.log(val.editor.getData())
                                // _product.description = val.editor.getData()
                                // setProduct(_product)
                                setContent(val.editor.getData())
                                // handleChangeDesc(val.editor.getData())
                            },
                        }}
                    />
                </ModalBody>
                <ModalFooter className="bg-light">
                    <button className="btn btn-primary btn-xs" onClick={() => {
                        let _selectedKontrol = { ...selectedKontrol }
                        let _user = { ...selectedKontrol.dokul_user }
                        let _result = { ..._selectedKontrol.result }
                        let _dokul_konsultasi_kontrol_result = { ..._result.dokul_konsultasi_kontrol_result }
                        switch (editedText) {
                            case 1:
                                _dokul_konsultasi_kontrol_result.result_text = content
                                if (_dokul_konsultasi_kontrol_result.id)
                                    updateControlResult(_dokul_konsultasi_kontrol_result.id, { result_text: content }, true)
                                //   setselectedKontrol(x=>{return {...x,result['dokul_konsultasi_kontrol_result'].result_text:content}})
                                break;
                            case 2:
                                _result.question_answer = content
                                updateControlResult(selectedKontrol.id, { question_answer: content }, false, true)
                                break;
                            case 3:
                                _user.medical_note = content
                                updateControlResult(_user.id, { medical_note: content }, false, false, true)
                                break;
                            case 4:
                                _dokul_konsultasi_kontrol_result.note = content
                                if (_dokul_konsultasi_kontrol_result.id)
                                    updateControlResult(_dokul_konsultasi_kontrol_result.id, { note: content }, false, false, false, false, true)
                                break;
                            default:
                            // code block
                        }
                        _result.dokul_konsultasi_kontrol_result = _dokul_konsultasi_kontrol_result
                        _selectedKontrol.result = _result
                        _selectedKontrol.dokul_user = _user
                        setselectedKontrol(_selectedKontrol)
                        setmodalEditText(false)

                    }}>Save</button>
                </ModalFooter>
            </Modal> */}
            <Modal isOpen={modalOpenChangeRole} toggle={() => setModalOpenChangeRole(false)} centered size={"sm"} >

                {selectedKontrol && <div className="modal-content bg-white">
                    <div className="modal-header">
                        <h5 className="modal-title">Ganti role  - {selectedKontrol.dokul_user.first_name}</h5>

                    </div>
                    <div className="modal-body">
                        <div className="form-group row m-2">


                            {dRoles.roles.map((role, idx) =>
                                <div key={idx} className="form-check">
                                    <input className="form-check-input" value={role.id} type="checkbox" name={`role_selected${idx}`} id="role_selected" checked={selectedKontrol.dokul_user.dokul_user_roles.some(dur => dur.role_id == role.id)} onChange={(e) => {
                                        let cu = { ...selectedKontrol }
                                        let du = { ...cu.dokul_user }
                                        let _userRoles = [...du.dokul_user_roles]
                                        if (e.target.checked) {
                                            //cu.role_id = e.target.value
                                            _userRoles.push({
                                                email: selectedKontrol.dokul_user.email,
                                                id: `${selectedKontrol.dokul_user.email}${e.target.value}`,
                                                role_id: e.target.value,
                                                user_id: selectedKontrol.dokul_user.id
                                            })

                                        } else {
                                            const idxcu = _userRoles.findIndex(x => x.role_id == e.target.value)
                                            _userRoles.splice(idxcu, 1)

                                        }
                                        du.dokul_user_roles = _userRoles
                                        cu.dokul_user = du
                                        setselectedKontrol(cu)

                                    }} />
                                    <label className="form-check-label" htmlFor={`role_selected${idx}`}>
                                        {role.role_name}
                                    </label>
                                </div>
                            )}

                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary btn-sm" onClick={() => {
                            updateUserRoles({
                                variables: { data: selectedKontrol.dokul_user.dokul_user_roles }
                            })
                            // let _users = { ...users }
                            // let curentUsers = [..._users.users]


                            // const idx = curentUsers.findIndex(x => x.id == selectedUser.id)
                            // curentUsers = curentUsers.splice(idx, 1)
                            // _users.users = curentUsers

                            setModalOpenChangeRole(false)
                            // postData(`${process.env.REACT_APP_API_HOST}/api/users/sync/role`, [selectedUser])
                        }}>Submit</button>
                        <button className="btn btn-secondary btn-sm " onClick={() => setModalOpenChangeRole(false)}>Cancel</button>

                    </div>

                </div>
                }

            </Modal>
            {/* <!-- Container-fluid Ends--> */}
            <Modal isOpen={modalOpenImage} toggle={() => setmodalOpenImage(false)} size={selectedKontrol ? "xl" : "md"} centered >
                {!selectedKontrol && <ModalHeader className="bg-primary" toggle={() => setmodalOpenImage(false)}></ModalHeader>}
                <ModalBody className="bg-light p-1" style={{ justifyContent: "center", alignItems: "center" }} >

                    {/* <ReactImageMagnify {...{
                        className:"row",
                        imageClassName:"col-4",
                        lensStyle: {
                            background: 'hsla(0, 0%, 100%, .3)',
                            border: '1px solid #ccc'
                          },
                        // imageStyle:{width:"25px",height:"25px"},
                        // enlargedImageClassName:"col-6",
                        enlargedImageContainerClassName:"w-75",
                        smallImage: {
                            alt: 'Dokterkulitku',
                            // isFluidWidth: true,
                            width:300,
                            // height:300,
                            src: `${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`,
                            // sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                        },
                        largeImage: {
                            src: `${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`,
                            width: 1200,
                            height: 1800
                        }
                    }} /> */}

                    {!selectedKontrol && <InnerImageZoom src={`${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`} zoomSrc={`${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`} />}
                    {selectedKontrol && <TransformWrapper
                        initialScale={1}
                        wheel={{ wheelDisabled: true }}
                        // initialPositionX={100}
                        // initialPositionY={100}
                        // disabled
                        centerOnInit

                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <div className="tools m-2 border rounded">
                                    <button className='btn btn-outline btn-xs border m-1' onClick={() => zoomIn()}>+ Zoom In</button>
                                    <button className='btn btn-outline btn-xs border m-1' onClick={() => zoomOut()}>- Zoom Out</button>
                                    <button className='btn btn-info btn-xs m-1' onClick={() => resetTransform()}>Reset</button>
                                    <button className='btn btn-danger btn-xs m-1 float-end' onClick={() => setmodalOpenImage(false)}>CLose</button>
                                    {rest.state.scale}
                                </div>
                                <TransformComponent>
                                    <div className="d-flex justify-content-center flex-row">
                                        <img src={`${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`} alt="test" style={{ width: "100%", margin: "auto" }} />

                                    </div>
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                    }
                    {loadingImage && <div className="d-flex justify-content-center flex-row">
                        <Spinner className='self-align-center' animation="grow" variant="info" color='primary' />
                    </div>}

                    <img style={{ display: loadingImage ? 'none' : 'none' }} onLoad={() => setloadingImage(false)} src={`${process.env.REACT_APP_IMAGE_HOST}${selectedImage}`} className="img-fluid image_zoom_1 blur-up lazyloaded m-2" />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}


export default withApollo(ListKontrol)

