import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient,NormalizedCacheObject,gql,ApolloLink,InMemoryCache } from '@apollo/react-hooks';
//import { InMemoryCache } from 'apollo-cache-inmemory';
//import { HttpLink } from 'apollo-link-http';
import fetch from 'isomorphic-unfetch';
import {isLoggedInVar} from './cache'
import { relayStylePagination } from "@apollo/client/utilities";
import Cookies from 'js-cookie'


import { RestLink } from 'apollo-link-rest';
const restLink = new RestLink({ uri: `${process.env.REACT_APP_API_HOST}/` , headers: {
  //authorization: `Bearer ${Cookies.get('token')}`
},credentials: 'include',fetch});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('auth_token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: `Bearer ${Cookies.get('token')}`
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

let apolloClient = null;

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export function withApollo(PageComponent, { ssr = true } = {}) {
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    const client = apolloClient || initApolloClient(apolloState);
    // console.log(client)
    // console.log(pageProps)
    return (
      <ApolloProvider client={client}>
        <PageComponent client={client} {...pageProps} />
      </ApolloProvider>
    );
  };

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName =
      PageComponent.displayName || PageComponent.name || 'Component';

    if (displayName === 'App') {
      console.warn('This withApollo HOC only works with PageComponents.');
    }

    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async ctx => {
      const { AppTree } = ctx;

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      const apolloClient = (ctx.apolloClient = initApolloClient({}, ctx.req && ctx.req.headers.cookie))

      // Run wrapped getInitialProps methods
      let pageProps = {};
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      }

      // Only on the server:
      if (typeof window === 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            const { getDataFromTree } = await import('@apollo/react-ssr');
            await getDataFromTree(
              <AppTree
                pageProps={{
                  ...pageProps,
                  apolloClient,
                }}
              />
            );
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            console.error('Error while running `getDataFromTree`', error);
          }

          // getDataFromTree does not call componentWillUnmount
          // head side effect therefore need to be cleared manually
          //Head.rewind();
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClient.cache.extract();

      return {
        ...pageProps,
        apolloState,
      };
    };
  }

  return WithApollo;
}
function offsetFromCursor(items, cursor, readField) {
  // Search from the back of the list because the cursor we're
  // looking for is typically the ID of the last item.
  for (let i = items.length - 1; i >= 0; --i) {
    const item = items[i];
    // Using readField works for both non-normalized objects
    // (returning item.id) and normalized references (returning
    // the id field from the referenced entity object), so it's
    // a good idea to use readField when you're not sure what
    // kind of elements you're dealing with.
    if (readField("id", item) === cursor) {
      // Add one because the cursor identifies the item just
      // before the first item in the page we care about.
      return i + 1;
    }
  }
  // Report that the cursor could not be found.
  return -1;
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
function initApolloClient(initialState) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState);
  }

  return apolloClient;
}

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
function createApolloClient(initialState = {}) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
 // export 
  const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    cartItems: [ID!]!
  }
`;

  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link:restLink,// authLink.concat(restLink),
  //  link: new HttpLink({
  //     uri: process.env.API_URL,//process.env.API_URL, // Server URL (must be absolute)
  //     credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  //     fetch,
  //   }),
    cache: new InMemoryCache({
      //dataIdFromObject: object => object.id,
      typeDefs:typeDefs,
      typePolicies: {
        Query: {
          fields: {
             orders: relayStylePagination(),
            
            isLoggedIn: {
              read() {
                return true;
              }
            },
            keyArgs: ["type"],

            // merge(existing, incoming, {
            //   args: { cursor },
            //   readField,
            // }) {
            //   const merged = existing ? existing.slice(0) : [];
            //   let offset = offsetFromCursor(merged, cursor, readField);
            //   // If we couldn't find the cursor, default to appending to
            //   // the end of the list, so we don't lose any data.
            //   if (offset < 0) offset = merged.length;
            //   // Now that we have a reliable offset, the rest of this logic
            //   // is the same as in offsetLimitPagination.
            //   for (let i = 0; i < incoming.length; ++i) {
            //     merged[offset + i] = incoming[i];
            //   }
            //   return merged;
            // },
  
            // // If you always want to return the whole list, you can omit
            // // this read function.
            // read(existing, {
            //   args: { cursor, limit = existing.length },
            //   readField,
            // }) {
            //   if (existing) {
            //     let offset = offsetFromCursor(existing, cursor, readField);
            //     // If we couldn't find the cursor, default to reading the
            //     // entire list.
            //     if (offset < 0) offset = 0;
            //     return existing.slice(offset, offset + limit);
            //   }
            // },
            
            // products: {
            //   read: products => {
            //     return products || [];
            //   },
            // },
            // cartItems: {
            //   read() {
            //     return cartItemsVar();
            //   }
            // },
    
            // launches: {
            //   // ...field policy definitions...
            // }
          }
        }
      }
    }).restore(initialState)
  });
}