import React, { Component, useState, useContext, useEffect } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Settings } from 'react-feather'
import Modal from 'react-responsive-modal';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { GET_CONFIGS } from '../../constants/graph'
import SocketContext from '../../provider/socketctx/context';
import DispatchContext from '../../provider/socketctx/disptachContext';

import QRCode from 'qrcode.react'

const BranchKlinik = () => {
    const { positionInLine, queueLength, message, socketId, waconnect } = useContext(SocketContext);
    const newMessage = useContext(DispatchContext)
    const { loading, data, error, fetchMore } = useQuery(GET_CONFIGS,);
    const [waCode, setWaCode] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        console.log(waconnect)
        if (waconnect.sessid) {
            delete waconnect['sessid'];
            fetch("http://localhost:9001/api/integrasi/authWa", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(waconnect)
            }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        setModalOpen(false)

                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {

                    }
                )


        }

    }, [waconnect])

    const getWaInit = () => {
        let myheaders = {
            "sessid": socketId
        }
        fetch("http://localhost:9001/api/integrasi/initWa", { headers: myheaders })
            .then(res => res.json())
            .then(
                (result) => {
                    setWaCode(result.code)
                    setModalOpen(true)

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                }
            )
    }

    if (loading) return "LOADING"
    console.log(data)
    const { dokul_configs, dokul_paymethods,dokul_kliniks } = data.configs[0]
    return (
        <div className="row">

            <div className="tab-pane fade show active">
                <h5 className="f-w-600 f-16"></h5>
                <div className="table-responsive profile-table">
                {dokul_kliniks.map(kl =>
                    <div className="card" >
                        <div className="card-body">
                            <h5 className="card-title">{kl.name}</h5>
                            <h6 className="card-subtitle mb-2 text-muted"></h6>
                            <p className="card-text">{kl.address}</p>
                            <p className="card-text">Telepon : {kl.tlp}</p>
                            <button href="#" className="btn btn-primary">Edit</button>
                        </div>
                    </div>
                )}
                    
                </div>
            </div>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} center={true}>
                <div className="card-body">
                    <h6 className="card-title">Connect whatsapp</h6>
                    <QRCode size={256} value={waCode} />
                </div>
                {/* <ModelOrderDetail order={this.props.data.orders.orders[indexSelectedOrder]} updateOrder={this.props.updateOrder}  /> */}
            </Modal>
        </div>
    )
}

export default withApollo(BranchKlinik)
