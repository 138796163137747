import React, { Component, Fragment, forwardRef, useState } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import { Collapse } from 'reactstrap';
import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { GET_DOKTERS, GET_JADWAL_TEMPLATES, GET_KLINIKS, GET_RESERVASI, UPDATE_DOKTER, UPDATE_JADWAL_SESI } from '../../constants/graph';
import data from '../../assets/data/physical_list';
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
registerLocale('id', es)

const Jadwal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [modalSesiOpen, setmodalSesiOpen] = useState(false)
    const [addFromTemplate, setaddFromTemplate] = useState(false)
    const [tgl, setTgl] = useState(new Date())
    const [idxDokter, setIdxDokter] = useState(0)
    const [idxKlinik, setIdxKlinik] = useState(0)
    const [jadwalBaru, setJadwalBaru] = useState({})
    const [selectedTmp, setselectedTmp] = useState()
    const columns = [];
    const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const { loading, error, data } = useQuery(GET_DOKTERS)
    const { loading: tmpLoad, error: tmpErr, data: tmpData } = useQuery(GET_JADWAL_TEMPLATES)
    const client = useApolloClient()
    const updateCache = (cache, { data }) => {

        const _updatedDokter = { ...data.updateDokter }
        const fragmentResult = cache.writeFragment({
            id: `Dokter:${_updatedDokter.id}`,
            fragment: gql`
        fragment dokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
        }
      `,
            data: _updatedDokter
        });
    };
    const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });
    const [updateSesi] = useMutation(UPDATE_JADWAL_SESI);
    const selectedDokter = client.readFragment({
        id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment readDokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
          }
        `,
    }, false);
    const { loading: loadingKlinik, error: errorKlinik, data: dataKlinik } = useQuery(GET_KLINIKS)


    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }

    if (loading || loadingKlinik) return "Loading"
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Dokter 
                            {/* <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                            
                            }}
                            >ADD</span> */}
                            </li>
                            {/* <li className={`list-group-item ${selectedGudang == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => { }}>Semua gudang</li> */}


                            {data.dokters.map((dokter, idx) =>
                                <li className={`list-group-item ${idxDokter == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    setIdxDokter(idx)

                                }}>{dokter.fullname}</li>

                            )}

                        </ul>

                    </div>
                    <div className="col-sm-8">
                        <div className="card">
                            <div className="card-header bg-primary text-white">
                                <span className="float-left">
                                    <div class="d-flex flex-row bd-highlight ">
                                        <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                        <div className="p-0 bd-highlight"><DatePicker
                                            locale={"id"}
                                            dateFormat="dd MMMM yyyy"
                                            selected={tgl}
                                            onChange={(date) => setTgl(date)}
                                            customInput={<ExampleCustomInput />}
                                        /></div>

                                    </div>
                                </span>
                                {/* <h5>Jadwal konsultasi</h5> */}

                            </div>
                            <div className="card-body">
                                {/* <div class="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <ToastContainer />
                                </div>
                                {data.dokters[idxDokter].dokul_dokter_konsultasis.map((kon, idxKon) =>
                                    kon.type == 1 && kon.status == 1 ?
                                        <div className="d-flex flex-column bd-highlight mb-3">
                                            <h6 className="border-bottom border-info"><i className="icofont icofont-hospital" /> {kon.dokul_klinik.template_name}
                                                <span className="bg-success rounded p-1 float-right m-2" style={{ fontSize: 10 }} onClick={() => {
                                                    setaddFromTemplate(false)
                                                    setIdxKlinik(idxKon)
                                                    setModalOpen(true)
                                                }}><i className="icofont icofont-plus" /></span>
                                                <span className="bg-success rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                    setaddFromTemplate(true)
                                                    setIdxKlinik(idxKon)
                                                    setModalOpen(true)
                                                }}>Tambah dari template</span>
                                            </h6>
                                            {kon.dokul_dokter_jadwals.filter(xjd => compareDate(xjd.tanggal, tgl)).length > 0 ?
                                                kon.dokul_dokter_jadwals.filter(xjd => compareDate(xjd.tanggal, tgl)).map(jd =>
                                                    <div className="p-2 bd-highlight border mb-2 ">
                                                        <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-between">
                                                            <div className="d-flex flex-row">
                                                                <div className="p-2 bd-highlight">{jd.template_name}</div>
                                                                <div className="p-2 bd-highlight ml-5">{jd.status == 2 || jd.status == 3?<del>Jumlah sesi : {jd.jumlah_sesi}</del>:<>Jumlah sesi : {jd.jumlah_sesi}</>}</div>
                                                            </div>
                                                            <div className="p-2 bd-highlight">Terpakai : {jd.terpakai}</div>
                                                            <div className={`p-2 rounded-3 ${jd.status == 2 ? "bg-warning" : jd.status == 3 ? "bg-red" : "bg-success"}`}>{jd.status == 2 ? "Blocked" : jd.status == 3 ? "Canceled" :jd.status == 4? "Full Reserved": "Available"}</div>
                                                            <div className="d-flex flex-row">
                                                                <span className="bg-warning rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                                    let _sesi = { ...jd }
                                                                    _sesi.status = 2
                                                                    updateSesi({ variables: { type: _sesi }, })
                                                                }}><i className="icofont icofont-ui-remove" /></span>
                                                                <span className="bg-red rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                                                    let _sesi = { ...jd }
                                                                    _sesi.status = 3
                                                                    updateSesi({ variables: { type: _sesi }, })
                                                                }}><i className="icofont icofont-ui-delete" /></span>
                                                                <span className="bg-success rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => setmodalSesiOpen(true)}><i className="icofont icofont-eye" /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <h6 className="text-center"> Tidak ada jadwal</h6>
                                            }

                                        </div>
                                        :
                                        ""
                                )}
                                {data.dokters[idxDokter].dokul_dokter_konsultasis.filter(ykon => ykon.type == 2).length > 0 ?
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                        <h6 className="border-bottom border-info"><i className="icofont icofont-globe" /> ONLINE </h6>
                                        <div className="p-2 bd-highlight mb-2">
                                            <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-left">
                                                <div className="d-flex flex-row">
                                                    {/* <div className="p-2 bd-highlight bg-danger">SLOT IS CLOSED</div> */}

                                                </div>
                                                <Link
                                                    to={`/products/edit/${data.dokters[idxDokter].dokul_dokter_konsultasis.find(onk => onk.type == 2).id_product}`}
                                                >
                                                    <span className="p-2 bd-highlight rounded bg-success">EDIT PRODUK </span>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div class="clearfix" />
                </div>
            </div>
            <Modal open={modalSesiOpen} onClose={() => setmodalSesiOpen(false)} >
            <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Sesi konsultasi detail</h5>

                    </div>
                    <div className="modal-body">
                        <h6 className="font-weight-bold">{data.dokters[idxDokter]?.fullname}</h6>
                        <h6 className="font-weight-bold">{data.dokters[idxDokter]?.dokul_dokter_konsultasis[idxKlinik].dokul_klinik?.name}</h6>
                        <h6 className="font-weight-bold">{moment(tgl).format("dddd DD, MMMM YYYY")}</h6>
                        


                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let _dokter = { ...data.dokters[idxDokter] }
                            let _dokul_dokter_konsultasis = [..._dokter.dokul_dokter_konsultasis]
                            let _currentKonsultasi = { ..._dokul_dokter_konsultasis[idxKlinik] }
                            let currentJadwals = [..._currentKonsultasi.dokul_dokter_jadwals]

                            if (addFromTemplate) {
                                let _listSesi = tmpData.templates[selectedTmp].dokul_jadwal_sesis.map(_sesi => {
                                    return {
                                        id_konsultasi: _currentKonsultasi.id,
                                        name: _sesi.sesi,
                                        tanggal: moment(tgl).format("YYYY/MM/DD"),
                                        jumlah_sesi: _sesi.jumlah_pasien,
                                        terpakai: 0,

                                    }
                                })
                                currentJadwals = [...currentJadwals, ..._listSesi]
                            } else {
                                currentJadwals.push({
                                    id_konsultasi: _currentKonsultasi.id,
                                    name: jadwalBaru.name,
                                    tanggal: moment(tgl).format("YYYY/MM/DD"),
                                    jumlah_sesi: jadwalBaru.jumlah_sesi,
                                    terpakai: 0,

                                })
                            }

                            ///_currentKonsultasi.dokul_dokter_jadwals=currentJadwals
                            // _dokul_dokter_konsultasis[idxKlinik]=_currentKonsultasi
                            _dokter['dokul_dokter_jadwal'] = currentJadwals
                            updateDokter({ variables: { id: 1, type: _dokter }, })


                            setModalOpen(false)
                        }}>Simpan</button>
                    </div>

                </div>
            </Modal>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} >


                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Tambah jadwal</h5>

                    </div>
                    <div className="modal-body">
                        <h6 className="font-weight-bold">{data.dokters[idxDokter]?.fullname}</h6>
                        <h6 className="font-weight-bold">{data.dokters[idxDokter]?.dokul_dokter_konsultasis[idxKlinik].dokul_klinik?.name}</h6>
                        <h6 className="font-weight-bold">{moment(tgl).format("dddd DD, MMMM YYYY")}</h6>
                        {addFromTemplate ? <>
                            {tmpData.templates.map((template, idx) =>
                                <>
                                    <li className={`list-group-item ${selectedTmp == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                        setselectedTmp(idx)

                                    }}>{template.template_name}</li>

                                    <Collapse isOpen={idx == selectedTmp}>
                                        <div className="d-flex flex-column bd-highlight mb-3">

                                            {template.dokul_jadwal_sesis.map(sesi => <div className="p-2 bd-highlight"><span className="float-start">{sesi.sesi}</span><span className="float-end">{sesi.jumlah_pasien} Pasien</span></div>)}

                                        </div>

                                    </Collapse>
                                </>
                            )}


                        </> : <>

                            <div class="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1" >Sesi</span>
                                </div>
                                <input type="text" className="form-control" onChange={(e) => setJadwalBaru({ name: e.target.value, jumlah_sesi: jadwalBaru.jumlah_sesi })} />
                            </div>
                            <div class="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon12" >Jumlah</span>
                                </div>
                                <input type="number" className="form-control" onChange={(e) => setJadwalBaru({ jumlah_sesi: e.target.value, name: jadwalBaru.name })} />
                            </div>
                        </>}


                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let _dokter = { ...data.dokters[idxDokter] }
                            let _dokul_dokter_konsultasis = [..._dokter.dokul_dokter_konsultasis]
                            let _currentKonsultasi = { ..._dokul_dokter_konsultasis[idxKlinik] }
                            let currentJadwals = [..._currentKonsultasi.dokul_dokter_jadwals]

                            if (addFromTemplate) {
                                let _listSesi = tmpData.templates[selectedTmp].dokul_jadwal_sesis.map(_sesi => {
                                    return {
                                        id_konsultasi: _currentKonsultasi.id,
                                        name: _sesi.sesi,
                                        tanggal: moment(tgl).format("YYYY/MM/DD"),
                                        jumlah_sesi: _sesi.jumlah_pasien,
                                        terpakai: 0,

                                    }
                                })
                                currentJadwals = [...currentJadwals, ..._listSesi]
                            } else {
                                currentJadwals.push({
                                    id_konsultasi: _currentKonsultasi.id,
                                    name: jadwalBaru.name,
                                    tanggal: moment(tgl).format("YYYY/MM/DD"),
                                    jumlah_sesi: jadwalBaru.jumlah_sesi,
                                    terpakai: 0,

                                })
                            }

                            ///_currentKonsultasi.dokul_dokter_jadwals=currentJadwals
                            // _dokul_dokter_konsultasis[idxKlinik]=_currentKonsultasi
                            _dokter['dokul_dokter_jadwal'] = currentJadwals
                            updateDokter({ variables: { id: 1, type: _dokter }, })


                            setModalOpen(false)
                        }}>Simpan</button>
                    </div>

                </div>

            </Modal>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}


export default Jadwal

