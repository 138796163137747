import React, { Component, Fragment, forwardRef, useState } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";

import moment from 'moment'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/id';
import { GET_DOKTERS, GET_JADWAL_TEMPLATES, GET_KLINIKS, GET_RESERVASI, SAVE_TEMPLATE, UPDATE_DOKTER } from '../../constants/graph';
import data from '../../assets/data/physical_list';
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { withApollo } from '../../apollo'
registerLocale('id', es)

const TemplateJadwal = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [modalSesiOpen, setmodalSesiOpen] = useState(false)
    const [newSesi, setnewSesi] = useState({})
    const [newTemplate, setnewTemplate] = useState({})
    const [tgl, setTgl] = useState(new Date())
    const [idxDokter, setIdxDokter] = useState(0)
    const [idxKlinik, setIdxKlinik] = useState(0)
    const [jadwalBaru, setJadwalBaru] = useState({})
    const columns = [];
    const status = ['', 'Reservasi Baru', 'Selesai', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Baru', 'Pasien lama']
    const { loading, error, data } = useQuery(GET_JADWAL_TEMPLATES)
    const client = useApolloClient()
    const updateCacheTemplate = (cache, { data }) => {
        // console.log(data.saveTemplate)

        // console.log(data)
        //     const _updatedDokter = { ...data.updateDokter }
        //     const fragmentResult = cache.writeFragment({
        //         id: `Dokter:${_updatedDokter.id}`,
        //         fragment: gql`
        //     fragment dokter on Dokter{
        //         id
        //         dokul_dokter_konsultasis
        //         fullname
        //         picture
        //         picture
        //         available
        //     }
        //   `,
        //         data: _updatedDokter
        //     });
    };
    const [addTemplate] = useMutation(SAVE_TEMPLATE, { update: updateCacheTemplate });



    const updateCache = (cache, { data }) => {

        const _updatedDokter = { ...data.updateDokter }
        const fragmentResult = cache.writeFragment({
            id: `Dokter:${_updatedDokter.id}`,
            fragment: gql`
        fragment dokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
        }
      `,
            data: _updatedDokter
        });
    };
    const [updateDokter] = useMutation(UPDATE_DOKTER, { update: updateCache });
    const selectedDokter = client.readFragment({
        id: `Dokter:${idxDokter}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment readDokter on Dokter{
            id
            dokul_dokter_konsultasis
            fullname
            picture
            picture
            available
          }
        `,
    }, false);
    const { loading: loadingKlinik, error: errorKlinik, data: dataKlinik } = useQuery(GET_KLINIKS)


    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span onClick={onClick} ref={ref} className="bg-info rounded p-2"><i className="icofont icofont-ui-calendar" /></span>
    ));
    const compareDate = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        d1.setHours(0, 0, 0, 0)
        d2.setHours(0, 0, 0, 0)
        return d1.getTime() === d2.getTime()
    }

    if (loading || loadingKlinik) return "Loading"
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Template <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                setModalOpen(true)
                            }}
                            >ADD</span></li>
                            {/* <li className={`list-group-item ${selectedGudang == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => { }}>Semua gudang</li> */}


                            {data.templates.map((template, idx) =>
                                <li className={`list-group-item ${idxDokter == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    setIdxDokter(idx)

                                }}>{template.template_name}</li>

                            )}

                        </ul>

                    </div>
                    <div className="col-sm-8">
                        {/* <div className="card"> */}
                        {/* <div className="card-header bg-primary text-white">
                                <span className="float-left">
                                    <div class="d-flex flex-row bd-highlight ">
                                        <div className="p-0 bd-highlight"> <h4 className="text-white mr-2">Sesi Konsultasi</h4></div>
                                      

                                    </div>
                                </span>

                            </div> */}

                        {/* <div className="card-body"> */}
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Sesi konsultasi <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                setmodalSesiOpen(true)
                            }}
                            >ADD</span></li>
                            {data.templates[idxDokter].dokul_jadwal_sesis.map((kon, idxKon) =>

                                <li className={`list-group-item `}  >
                                    <div className="d-flex flex-row justify-content-around">
                                        <div className="p-2 bd-highlight">{kon.sesi}</div>
                                        <div className="p-2 bd-highlight float-end">Jumlah pasien : {kon.jumlah_pasien}</div>
                                        <span className="bg-red rounded p-1 float-right m-2" style={{ fontSize: 10, cursor: "pointer" }} onClick={() => {
                                            let updateListTmp = []
                                            let currentTemp = { ...data.templates[idxDokter] }
                                            let newListTmp = [...currentTemp.dokul_jadwal_sesis]

                                            let idx=newListTmp.indexOf(kon)

                                            newListTmp.splice(idx,1)
                                            
                                           // newListTmp.push(newListTmp)
                                            currentTemp.dokul_jadwal_sesis = newListTmp
                                            updateListTmp.push(currentTemp)
                                            addTemplate({
                                                variables: {
                                                    type: updateListTmp
                                                },
                                            })
                                            
                                        }}><i className="icofont icofont-ui-close" /></span>
                                    </div>
                                </li>
                            )}
                        </ul>
                        {/* <div class="d-flex flex-row bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"> <h4 className=" mr-2">{moment(tgl).format("dddd DD, MMMM YYYY")}</h4></div>
                                    <div className="p-2 bd-highlight"><DatePicker
                                        locale={"id"}
                                        dateFormat="dd MMMM yyyy"
                                        selected={tgl}
                                        onChange={(date) => this.setTgl(date)}
                                        customInput={<ExampleCustomInput />}
                                    /></div>
                                    <div className="p-2 bd-highlight"><span className="bg-success rounded p-2" onClick={() => {

                                    }}><i className="icofont icofont-plus" /></span></div>
                                </div> */}



                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                            <ToastContainer />
                        </div>

                        {/* {data.dokters[idxDokter].dokul_dokter_konsultasis.filter(ykon => ykon.type == 2).length > 0 ?
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                        <h6 className="border-bottom border-info"><i className="icofont icofont-globe" /> ONLINE </h6>
                                        <div className="p-2 bd-highlight mb-2">
                                            <div className="d-flex flex-row bd-highlight mb-0 justify-content-sm-left">
                                                <div className="d-flex flex-row">
                                           
                                                </div>
                                                <Link
                                                    to={`/products/edit/${data.dokters[idxDokter].dokul_dokter_konsultasis.find(onk => onk.type == 2).id_product}`}
                                                >
                                                    <span className="p-2 bd-highlight rounded bg-success">EDIT PRODUK </span>
                                                </Link>


                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                } */}
                    </div>
                </div>
            </div>
            <div class="clearfix" />
            {/* </div> */}
            {/* </div> */}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Tambah template</h5>

                    </div>
                    <div className="modal-body">
                        <h6 className="font-weight-bold">{data.templates[idxDokter]?.fullname}</h6>
                        <form>
                            <div className="form-group">
                                <label for="namatemplate">Nama Template</label>
                                <input type="text" className="form-control" id="namatemplate" placeholder="masukan nama template" onChange={(e) => {
                                    setnewTemplate({
                                        name: e.target.value,
                                        dokul_jadwal_sesis: []
                                    })
                                }} />
                            </div>


                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let newListTmp = []

                            newListTmp.push(newTemplate)
                            addTemplate({
                                variables: {
                                    type: newListTmp
                                },
                            })
                        }}>Simpan</button>
                    </div>
                </div>
            </Modal>

            <Modal open={modalSesiOpen} onClose={() => setmodalSesiOpen(false)} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Tambah sesi</h5>

                    </div>
                    <div className="modal-body">
                        <h6 className="font-weight-bold">{data.templates[idxDokter]?.fullname}</h6>
                        <form>
                            <div className="form-group">
                                <label for="jammulai">Jam Mulai</label>
                                <input type="time" className="form-control" id="jammulai" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.timeFrom = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div>
                            <div className="form-group">
                                <label for="jamakhir">Jam Akhir</label>
                                <input type="time" className="form-control" id="jamakhir" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.timeTo = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div>

                            <div className="form-group">
                                <label for="jumlahpasien">Jumlah pasien</label>
                                <input type="number" className="form-control" id="jumlahpasien" onChange={(e) => {
                                    let _newSesi = { ...newSesi }
                                    _newSesi.jumlah_pasien = e.target.value
                                    setnewSesi(_newSesi)

                                }} />
                            </div>


                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)} >Batal</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            let updateListTmp = []
                            let currentTemp = { ...data.templates[idxDokter] }
                            let newListTmp = [...currentTemp.dokul_jadwal_sesis]

                            let newSesiTmp = {
                                template_id: currentTemp.id,
                                sesi: `${newSesi.timeFrom}-${newSesi.timeTo}`,
                                jumlah_pasien: newSesi.jumlah_pasien
                            }
                            newListTmp.push(newSesiTmp)
                            currentTemp.dokul_jadwal_sesis = newListTmp
                            updateListTmp.push(currentTemp)
                            addTemplate({
                                variables: {
                                    type: updateListTmp
                                },
                            })
                            setmodalSesiOpen(false)
                        }}>Simpan</button>
                    </div>
                </div>
            </Modal>

            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}


export default withApollo(TemplateJadwal)

