import React, { Component, Fragment, useMemo, useState, useEffect } from 'react'
//import Breadcrumb from '../../common/breadcrumb';
//import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge, ModalHeader, ModalBody, ModalFooter, Button, Modal, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import OptionHeader from '../common/header_components/optionheader';
import user from '../../assets/images/dashboard/user.png';
import gql from 'graphql-tag';
import 'moment/locale/id'
import DatePicker from "react-datepicker";
import { Collapse } from 'reactstrap';
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';


import moment from 'moment'
import { GET_DOKTERS, GET_KLINIKS, GET_RESERVASI } from '../../constants/graph';
const GET_RESERVASI_FILTER = gql`query reservasiKonsul(
    $page: String!
    $size: String!
    $qry:String!
) {
    reservasiKonsul(page:$page,size: $size,qry:$qry) @rest(type: "reservasiKonsul",path: "api/konsultasi/reservasi?page={args.page}&size={args.size}&{args.qry}") {
        data @type(name: "ReservasiKonsul") { 
          id
          id_klinik
          id_parent
          create_by
          create_date
          modify_date
          email
          fullName
          description
          date_selected
          status_pasien
          phone
          sesi
          tgl_lahir
          nama_layanan
          status
          tujuan
          status_text
          dokul_service
          dokul_klinik
          dokul_dokter_konsultasi   
          payment_status
          grand_total        
        } ,
      currentPage
      totalItems
      totalPages

    }

    }
  `;
const payment_status = ['', 'Menunggu pembayaran', 'Terverifikasi']
const Reservasi = (props) => {
    const [filterSesiOpen, setfilterSesiOpen] = useState(true)
    const [filterDokterOpen, setfilterDokterOpen] = useState(true)
    const [filterKlinikOpen, setfilterKlinikOpen] = useState(true)
    const [filterStatusOpen, setfilterStatusOpen] = useState(true)
    const [filterKeperluanOpen, setfilterKeperluanOpen] = useState(true)
    const [open, setopen] = useState(false)
    const [selectedReservasi, setselectedReservasi] = useState({ dokul_dokter_konsultasi: { dokul_dokter: {} } })
    const [filterString, setfilterString] = useState("")
    const [myData, setmyData] = useState([])
    const [selectedDate, setselectedDate] = useState(new Date())
    const [selectedDateTo, setselectedDateTo] = useState(new Date())
    const [selectedKeperluan, setselectedKeperluan] = useState([])
    const [selectedSesi, setselectedSesi] = useState([])
    const [selectedPic, setselectedPic] = useState([])
    const [selectedKlinik, setselectedKlinik] = useState([])
    const [selectedStatus, setselectedStatus] = useState([1,2])
    const [fieldFilter, setfieldFilter] = useState([])
    // const { loading, error, data, refetch } = useQuery(GET_RESERVASI_FILTER, {
    //     variables: {
    //         page: 0,
    //         size: 100,
    //         qry: `date_selected=${selectedDate}&selectedDateTo=${selectedDateTo}`
    //     }
    // })
    const [getDataReservasi, { loading, error, data, }] = useLazyQuery(GET_RESERVASI_FILTER, {
        variables: {
            page: 0,
            size: 100,
            qry: `date_selected=${moment(selectedDate).format("YYYY-MM-DD")}&selectedDateTo=${moment(selectedDateTo).format("YYYY-MM-DD")}&status=${selectedStatus.join(',')}`
        }
    })
    const { loading: lDokter, error: eDokter, data: dDokter } = useQuery(GET_DOKTERS)
    const { loading: lKlinik, error: eKlinik, data: dKlinik } = useQuery(GET_KLINIKS)

    const sesiList = ["11:00-11:30", "11:30-12:00", "12:00-12:30", "12:30-13:00", "13:00-13:30", "13:30-14:00", "14:00-14:30", "14:30-15:00"]
    const dokterList = ["DR Simon", "DR Lusi", "DR Arthur"]
    const status = ['', 'Reservasi Baru', 'Selesai','','','','','','', 'Dibatalkan']
    moment.locale('id')
    const statusPasien = ['', 'Pasien Lama', 'Pasien Baru']

    const [nestedModal, setNestedModal] = useState(false);
    const [closeAll, setCloseAll] = useState(false);

    const toggle = () => setopen(!open);
    const toggleNested = () => {
        setNestedModal(!nestedModal);
        setCloseAll(false);
    }
    const toggleAll = () => {
        setNestedModal(!nestedModal);
        setCloseAll(true);
    }

    useEffect(() => {
        if (data)
            setmyData(data.reservasiKonsul.data)
    }, [data])

    useEffect(() => {
        if (selectedDate)
            getDataReservasi()
    }, [selectedDate,selectedStatus])

    useEffect(() => {
        if (data) {
            let _newListData = [...data.reservasiKonsul.data]
            if (selectedKeperluan.length > 0) {
                _newListData = _newListData.filter(_fd => selectedKeperluan.indexOf(_fd.tujuan) != -1)

            }
            if (selectedPic.length > 0) {
                _newListData = _newListData.filter(_fd => selectedPic.indexOf(_fd.dokul_dokter_konsultasi.iddokter) != -1 && _fd.tujuan == 1)

            }
            if (selectedKlinik.length > 0) {
                _newListData = _newListData.filter(_fd => selectedKlinik.indexOf(_fd.dokul_klinik.id) != -1)

            }
            if (selectedSesi.length > 0) {
                _newListData = _newListData.filter(_fd => selectedSesi.indexOf(_fd.sesi) != -1)

            }
            setmyData(_newListData)
        }
    }, [selectedKeperluan, selectedKlinik, selectedPic, selectedSesi])

    useEffect(() => {
        if (selectedReservasi.id)
            setopen(true)
    }, [selectedReservasi])

    // const getPaymentMethod=(type)=>{
    //     return <div className="row">
    //     <div className="col-6 mb-2">
    //         <div className="mt-2 mr-2 bd-highlight">Pembayaran melalui  </div>
    //     </div>
    //     <div className="col-6 mb-2">
    //         <div className="mt-2 mr-2 ml-auto bd-highlight">
    //             {type < 30 && payMethode.name}
    //             {type > 30 && payMethode.type < 40 && <>
    //                 {paymentDetail && paymentDetail.card_brand == "VISA" && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/visa-logo.svg`} className='img-fluid  w-25' /><br /><strong>Card Number : {paymentDetail.masked_card_number}</strong></>}
    //                 {paymentDetail && paymentDetail.card_brand == "MASTERCARD" && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/mc-logo.svg`} className='img-fluid  w-25' /><br /><strong>Card Number : {paymentDetail.masked_card_number}</strong></>}
    //                 {paymentDetail && paymentDetail.card_brand == "JCB" && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/jcb-logo.svg`} className='img-fluid  w-25' /><br /><strong>Card Number : {paymentDetail.masked_card_number}</strong></>}

    //             </>}
    //             {payMethode.type === 41 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/ovo-logo.svg`} className='img-fluid  w-50' />}
    //             {payMethode.type === 42 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/shopeepay-logo.svg`} className='img-fluid  w-50' />}
    //             {payMethode.type === 43 && <Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/dana-logo.svg`} className='img-fluid  w-50' />}

    //             {payMethode.type === 51 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bca-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
    //             {payMethode.type === 52 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/mandiri-logo.svg`} className='img-fluid w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
    //             {payMethode.type === 53 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bri-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
    //             {payMethode.type === 54 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bni-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
    //             {payMethode.type === 55 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/permata-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}
    //             {payMethode.type === 56 && <><Media src={`${process.env.REACT_APP_IMAGE_HOST}/assets/images/bsi-logo.svg`} className='img-fluid  w-50' /><br /><strong>Virtual Account : {paymentDetail.account_number}</strong></>}


    //         </div>
    //         {payMethode.type < 30 && <div className="mt-2 mr-2 ml-auto bd-highlight"> {`${payMethode.nm_pemilik} / ${payMethode.no_rek}`}</div>}
    //     </div>
    //     </div>
    // }


    const columns = useMemo(
        () => [

            {
                Header: <b>Pasien</b>,
                //accessor: "create_date",
                width: 150,
                Cell: (cellInfo) => <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="p-2 bd-highlight">
                        {cellInfo.original.fullName}
                    </div>
                    <div className="p-2 bd-highlight">
                        {moment(cellInfo.original.tgl_lahir).format("DD, MMMM YYYY")}
                    </div>
                    <div className="p-2 bd-highlight"><span className="bg-success p-2 rounded">{statusPasien[cellInfo.original.status_pasien]}</span></div>
                </div>,//moment(cellInfo.original.create_date).format("YYYY-MM-DD HH:mm:ss"),
                style: {
                    textAlign: 'justify',
                }
            },
            {
                Header: <b>Tujuan</b>,
                accessor: "fullName",
                width: 200,
                Cell: (cellInfo) =>
                    <div className="d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight">
                            {cellInfo.original.tujuan == 1 ? "Konsultasi" : cellInfo.original.tujuan == 2 ? "Perawatan" : "Tindakan"}
                        </div>
                        <div className="p-2 bd-highlight">
                            {moment(cellInfo.original.date_selected).format("dddd DD, MMMM YYYY")}
                        </div>
                        <div className="p-2 bd-highlight">
                            {cellInfo.original.sesi}
                        </div>
                        <div className="p-2 bd-highlight"><span className={`${cellInfo.original.status!=9?'bg-info':'bg-cred'} p-2 rounded`}>{status[cellInfo.original.status]}</span></div>

                        {cellInfo.original.grand_total > 0 ? <>
                            <div className="p-2 bd-highlight">
                                Biaya : {cellInfo.original.grand_total.toLocaleString("id-ID", {
                                    style: "currency", currency: "IDR", minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </div>
                            <div className="p-2 bd-highlight ">
                                {cellInfo.original.status!=9&&<Badge color={`${cellInfo.original.payment_status == 1 ? 'warning' : 'info'}`}>{payment_status[cellInfo.original.payment_status]}</Badge>}
                            </div>
                        </> : <div className="p-2 bd-highlight">
                            Bebas Biaya
                        </div>}



                    </div>,
                style: {
                    textAlign: 'left',
                }
            },
            {
                Header: <b>Layanan</b>,
                accessor: "date_selected",
                Cell: (cellInfo) =>
                    <div className="d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight">
                            {cellInfo.original.tujuan == 1 ? cellInfo.original.dokul_dokter_konsultasi.dokul_dokter.fullname : ""}
                        </div>
                        {/* <div className="p-2 bd-highlight">
                            {cellInfo.original.nama_layanan}
                        </div> */}
                        <div className="p-2 bd-highlight">
                            {cellInfo.original.dokul_klinik?.address}
                        </div>

                    </div>,//cellInfo.original.dokul_dokter_konsultasi.dokul_dokter.fullname,
                style: {
                    textAlign: 'left',
                }
            },
            // {
            //     Header: <b>Biaya</b>,
            //     accessor: "date_selected",
            //     Cell: (cellInfo) =>
            //         <div className="d-flex flex-column bd-highlight mb-3">
            //             {cellInfo.original.grand_total > 0 ? <>
            //                 <div className="p-2 bd-highlight">
            //                     {cellInfo.original.grand_total.toLocaleString("id-ID", {
            //                         style: "currency", currency: "IDR", minimumFractionDigits: 0,
            //                         maximumFractionDigits: 0
            //                     })}
            //                 </div>
            //                 <div className="p-2 bd-highlight ">
            //                     <Badge color={`${cellInfo.original.payment_status == 1 ? 'warning' : 'info'}`}>{payment_status[cellInfo.original.payment_status]}</Badge>
            //                 </div>
            //             </> : <div className="p-2 bd-highlight">
            //                 Bebas Biaya
            //             </div>}


            //         </div>,//cellInfo.original.dokul_dokter_konsultasi.dokul_dokter.fullname,
            //     style: {
            //         textAlign: 'left',
            //     }
            // },
            // {
            //     Header: <b>Klinik</b>,
            //     // accessor: "date_selected",
            //     Cell: (cellInfo) => cellInfo.original.dokul_klinik?.address,
            //     style: {
            //         textAlign: 'center',
            //     }
            // },
            {
                Header: <b>Action</b>,
                id: 'delete',
                width: 100,
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <span onClick={() => {
                            setselectedReservasi(row.original)

                        }}>
                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                        </span>



                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        ])

    if (loading || lDokter || lKlinik) return "Loading"

    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3">
                        <div className="col-sm-12">


                            <div className="list-group">
                                <li className="list-group-item active" aria-current="true">Filter</li>

                                <li className="list-group-item bg-light text-dark" aria-current="true">Tanggal</li>
                                <li className="list-group-item bg-white text-dark" aria-current="true">
                                    <label>Mulai : </label>
                                    {/* <input className="form-control" name="name" id="name-point" type="date" defaultValue={selectedDate} onChange={(e) => {
                                        console.log(e.target.value)
                                        setselectedDate(e.target.value)
                                    }} /> */}
                                    <DatePicker
                                        className='m-2'
                                        selected={selectedDate}
                                        onChange={(date) => {
                                            setselectedDate(date)
                                        }}
                                        dateFormat="dd MMMM yyyy"
                                    />
                                </li>
                                <li className="list-group-item bg-white text-dark" aria-current="true">
                                    <label>Sampai : </label>
                                    {/* <input className="form-control" name="selectedDateTo" id="name-pointto" type="date" defaultValue={selectedDateTo} onChange={(e) => {
                                        console.log(e.target.value)
                                        selectedDateTo(e.target.value)
                                    }} /> */}
                                    <DatePicker
                                        className='m-2'
                                        selected={selectedDateTo}
                                        onChange={(date) => {
                                            setselectedDateTo(date)
                                        }}
                                        dateFormat="dd MMMM yyyy"
                                    />
                                </li>

                                <li className="list-group-item bg-light text-dark" aria-current="true" onClick={() => {
                                    setfilterKeperluanOpen(!filterKeperluanOpen)
                                }}>Keperluan</li>
                                <Collapse isOpen={filterKeperluanOpen}>
                                    {["Konsultasi", "Perawatan", "Tindakan dokter"].map((dr, idx) =>
                                        <label className="list-group-item" key={idx}>
                                            <input className="form-check-input me-1" type="checkbox" value={dr} onChange={(e) => {

                                                let _selectedTujuan = [...selectedKeperluan]
                                                if (e.target.checked)
                                                    _selectedTujuan.push(idx + 1)
                                                else
                                                    _selectedTujuan.splice(_selectedTujuan.indexOf(idx + 1), 1)

                                                setselectedKeperluan(_selectedTujuan)


                                            }} />
                                            {dr}
                                        </label>
                                    )}
                                </Collapse>
                                <li className="list-group-item bg-light text-dark" aria-current="true" onClick={() => {
                                    setfilterDokterOpen(!filterDokterOpen)
                                }}>Dokter</li>
                                <Collapse isOpen={filterDokterOpen}>
                                    {dDokter.dokters.map((dr, idx) =>
                                        <label className="list-group-item" key={idx}>
                                            <input className="form-check-input me-1" type="checkbox" value={dr.id} onChange={(e) => {
                                                let _selected = [...selectedPic]
                                                if (e.target.checked)
                                                    _selected.push(dr.id)
                                                else
                                                    _selected.splice(_selected.indexOf(dr.id), 1)

                                                setselectedPic(_selected)

                                            }} />
                                            {dr.fullname}
                                        </label>
                                    )}
                                </Collapse>

                                <li className="list-group-item bg-light text-dark" aria-current="true" onClick={() => {
                                    setfilterKlinikOpen(!filterKlinikOpen)
                                }}>Klinik</li>
                                <Collapse isOpen={filterKlinikOpen}>
                                    {dKlinik.kliniks.map((kl, idx) => {
                                        return <label className="list-group-item" key={idx}>
                                            <input className="form-check-input me-1" type="checkbox" value={kl.id} onChange={(e) => {
                                                let _selected = [...selectedKlinik]

                                                if (e.target.checked) {
                                                    _selected.push(kl.id)

                                                } else {
                                                    _selected.splice(_selected.indexOf(kl.id), 1)
                                                }
                                                setselectedKlinik(_selected)
                                            }} />
                                            {kl.name}
                                        </label>
                                    })}
                                </Collapse>

                                <li className="list-group-item bg-light text-dark" aria-current="true" onClick={() => {
                                    setfilterSesiOpen(!filterSesiOpen)
                                }}>Sesi</li>
                                <Collapse isOpen={filterSesiOpen}>
                                    {myData.reduce((prev, cur) => {
                                        if (prev.indexOf(cur.sesi) == -1) {
                                            prev.push(cur.sesi)
                                        }
                                        return prev;

                                    }, []).map((kl, idx) => {
                                        return <label className="list-group-item" key={idx}>
                                            <input className="form-check-input me-1" type="checkbox" value={kl} onChange={(e) => {
                                                let _selected = [...selectedSesi]

                                                if (e.target.checked) {
                                                    _selected.push(kl)

                                                } else {
                                                    _selected.splice(_selected.indexOf(kl), 1)
                                                }
                                                setselectedSesi(_selected)
                                            }} />
                                            {kl}
                                        </label>
                                    })}
                                </Collapse>
                                <li className="list-group-item bg-light text-dark" aria-current="true" onClick={() => {
                                    setfilterStatusOpen(!filterStatusOpen)
                                }}>Status</li>
                                <Collapse isOpen={filterStatusOpen}>
                                    <label className="list-group-item" >
                                        <input className="form-check-input me-1" type="checkbox" value={1} checked={selectedStatus.some(x=>x==1)} onChange={(e) => {
                                           let _selected = [...selectedStatus]

                                           if (e.target.checked) {
                                               _selected.push(1)

                                           } else {
                                               _selected.splice(_selected.indexOf(1), 1)
                                           }
                                           setselectedStatus(_selected)
                                        }} />
                                        Menunggu Pembayaran
                                    </label>
                                    <label className="list-group-item" >
                                        <input className="form-check-input me-1" type="checkbox" value={2} checked={selectedStatus.some(x=>x==2)} onChange={(e) => {
                                           let _selected = [...selectedStatus]

                                           if (e.target.checked) {
                                               _selected.push(2)

                                           } else {
                                               _selected.splice(_selected.indexOf(2), 1)
                                           }
                                           setselectedStatus(_selected)
                                        }} />
                                       Terverifikasi
                                    </label>
                                    <label className="list-group-item" >
                                        <input className="form-check-input me-1" type="checkbox" value={3} checked={selectedStatus.some(x=>x==3)} onChange={(e) => {
                                           let _selected = [...selectedStatus]

                                           if (e.target.checked) {
                                               _selected.push(3)

                                           } else {
                                               _selected.splice(_selected.indexOf(3), 1)
                                           }
                                           setselectedStatus(_selected)
                                        }} />
                                       Batal
                                    </label>
                                </Collapse>


                            </div>


                        </div>
                    </div>
                    <div className="col-sm-9">

                        {/* <div className="card">
                                <div className="card-header">
                                    <h5>Reservasi</h5>

                                </div>
                                <div className="card-body"> */}


                        {/* <div className="btn-popup pull-right"> */}

                        {/* <button type="button" className="btn btn-primary" onClick={() => {
                                            this.setState({
                                                selectedReservasi: {picture:user,dokul_kliniks:[],type:1,imageHasChange:false}
                                            }, () => this.onOpenModal())
                                        }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Add Service</button> */}
                        <Modal isOpen={open} toggle={() => setopen(false)} centered className="bg-white" size={"lg"}>
                            <div className="modal-header">
                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{selectedReservasi.id ? 'Reservasi detail' : 'Add service'}</h5>
                            </div>


                            <ModalBody>

                                <div className="card" >
                                    <div className="card-body row">
                                        <div className="col-4">
                                            {selectedReservasi.tujuan == 1 ?
                                                <img src={`${selectedReservasi.dokul_dokter_konsultasi?.dokul_dokter?.picture?.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${selectedReservasi.dokul_dokter_konsultasi.dokul_dokter?.picture}`} ></img>
                                                :
                                                <img src={`${selectedReservasi.dokul_service?.picture?.includes('data:image') ? '' : process.env.REACT_APP_IMAGE_HOST}${selectedReservasi.dokul_service?.picture}`} ></img>
                                            }
                                        </div>
                                        <div className="col-8">
                                            <h2 className="card-title">{selectedReservasi.tujuan == 2 ? selectedReservasi.dokul_dokter_konsultasi?.dokul_dokter.fullname : selectedReservasi.nama_layanan}</h2>
                                            <p className="card-text">{selectedReservasi.dokul_klinik?.name}</p>
                                            <p className="card-text">{selectedReservasi?.dokul_klinik?.address}</p>

                                            <dl class="row text-dark">
                                                <dt className="col-sm-5">Tanggal reservasi</dt>
                                                <dd className="col-sm-7">{moment(selectedReservasi?.create_date).format("dddd ,DD MMMM YYYY HH:mm")}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Tanggal layanan</dt>
                                                <dd className="col-sm-7">{moment(selectedReservasi?.date_selected).format("dddd ,DD MMMM")}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Sesi</dt>
                                                <dd className="col-sm-7">{selectedReservasi.sesi}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Nama</dt>
                                                <dd className="col-sm-7">{selectedReservasi?.fullName}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Status pasien</dt>
                                                <dd className="col-sm-7">{selectedReservasi?.status_pasien == 1 ? 'Pasien Lama' : 'Pasien Baru'}</dd>
                                                <hr />

                                                <dt className="col-sm-5 text-truncate">Email</dt>
                                                <dd className="col-sm-7">{selectedReservasi?.email}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Phone</dt>
                                                <dd className="col-sm-7">{selectedReservasi?.phone}</dd>
                                                <hr />

                                                <dt className="col-sm-5">Keluhan</dt>
                                                <dd className="col-sm-7">{selectedReservasi.description}</dd>
                                                <hr />


                                            </dl>
                                        </div>

                                    </div>


                                </div>
                            </ModalBody>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-danger" onClick={toggleNested}>Batalkan reservasi</button>
                                <button type="button" className="btn btn-secondary" onClick={() => setopen(false)}>Close</button>
                            </div>
                            <Modal isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggle : undefined} className="bg-white">
                                <ModalHeader>Masukan alasan pembatalan</ModalHeader>
                                <ModalBody>
                                    <Input type="textarea" />
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={toggleNested}>Batal</Button>{' '}
                                    <Button color="secondary" onClick={toggleAll}>Simpan</Button>
                                </ModalFooter>
                            </Modal>
                        </Modal>
                        {/* </div> */}
                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                            {/* <Datatable
                                            onDelete={this.onDelete}
                                            editLink={"/services/edit/"}
                                            onEdit={this.onEdit}
                                            multiSelectOption={false}
                                            myData={this.props.data.layanans}
                                            hideFields={['__typename', 'id', 'banner', 'type', 'dokul_kliniks', 'dokul_content_rows', 'deskripsi', 'dokul_page']}
                                            listFields={[]}
                                            pageSize={10}
                                            pagination={true}
                                            className="-striped -highlight"
                                        /> */}
                            <ReactTable
                                data={myData}
                                columns={columns}
                                defaultPageSize={10}
                                className={"-striped -highlight"}
                                showPagination={true}
                                NoDataComponent={() => <div className="rt-noData">Data tidak ditemukan</div>}
                            />
                            {/* <ToastContainer /> */}
                        </div>
                    </div>
                </div>
                {/* </div>
                    </div> */}
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}
// }
export default withApollo(Reservasi)

