import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Collapse, Input, Row } from 'reactstrap'
import RekomendasiProduk from './Rekomendasi.produk'
// import CKEditors from "react-ckeditor-component";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from './ClassicEditor'
// import Emoji from '@phudak/ckeditor5-emoji/src/emoji';
// import EmojiPeople from "@phudak/ckeditor5-emoji/src/emoji-people";
// import EmojiNature from "@phudak/ckeditor5-emoji/src/emoji-nature";
// import EmojiFood from "@phudak/ckeditor5-emoji/src/emoji-food";
// import EmojiActivity from "@phudak/ckeditor5-emoji/src/emoji-activity";
// import EmojiObjects from "@phudak/ckeditor5-emoji/src/emoji-objects";
// import EmojiPlaces from "@phudak/ckeditor5-emoji/src/emoji-places";
// import EmojiSymbols from "@phudak/ckeditor5-emoji/src/emoji-symbols";
// import EmojiFlags from "@phudak/ckeditor5-emoji/src/emoji-flags";
import moment from 'moment'


const ckconfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    // PluginArray:[Emoji,
    //     EmojiPeople,
    //     EmojiNature,
    //     EmojiPlaces,
    //     EmojiFood,
    //     EmojiActivity,
    //     EmojiObjects,
    //     EmojiSymbols,
    //     EmojiFlags,]

};

function ResultKontrol({ selectedKontrol, submitControlResult, updateControlResult, refetchData, setModalOpen, selectedProductRoot, ansTemplate, catatanSebelum }) {
    const [content, setContent] = useState("")//useState(selectedKontrol.result.dokul_konsultasi_kontrol_result?.result_text)
    const [content2, setContent2] = useState()
    const [catatan, setcatatan] = useState(selectedKontrol.result.dokul_konsultasi_kontrol_result?.note)
    const [isRefund, setisRefund] = useState(selectedKontrol.result.dokul_konsultasi_kontrol_result && selectedKontrol.result.dokul_konsultasi_kontrol_result?.refund == 1)
    const [voucherCashback, setvoucherCashback] = useState(selectedKontrol.konsul_type != 3)
    const [loadingSubmit, setloadingSubmit] = useState(false)
    const [selectedProduct, setselectedProduct] = useState(selectedProductRoot)
    const [deletedProducts, setdeletedProducts] = useState([])
    const [newProducts, setnewProducts] = useState([])
    const [jawaban1, setjawaban1] = useState(true)
    const [jawaban2, setjawaban2] = useState(true)
    const [showProduct, setshowProduct] = useState(false)
    const [selectedTag, setselectedTag] = useState()
    const [singgleSelect, setsinggleSelect] = useState(false)
    const [kodeProduk, setkodeProduk] = useState(81)
    const [kodeHarga, setkodeHarga] = useState(81)
    const [hasilKode, sethasilKode] = useState()
    const [collpseList, setcollpseList] = useState([])
    const [showAllList, setshowAllList] = useState(false)
    const [requiredProduk, setrequiredProduk] = useState(selectedKontrol.produk_required)
    const [listDeleted, setlistDeleted] = useState([])
    var editor1 = useRef()
    async function deleteHistory(_id) {

        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/konsultasi/deleteComment/${_id}`, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            ///body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        return response.json(); // parses JSON response into native JavaScript objects
    }
    const combineCode = (array, total) => {
        const kHarga = parseInt(kodeHarga)
        const kProduk = parseInt(kodeProduk)

        var maxLoops = 999, randomized;
        do {
            randomized = array.map(function (value) {
                return Math.floor(Math.random() * (value + 1));
            });
            maxLoops--;
        } while (maxLoops > 0 && randomized.reduce(function (a, b, cIndex) {
            if (cIndex != 2) return a + b;
            if (cIndex == 2) return a * b;
        }) !== kProduk);

        var maxLoops2 = 999, randomized2;
        do {
            randomized2 = array.map(function (value) {
                return Math.floor(Math.random() * (value + 1));
            });
            maxLoops2--;
        } while (maxLoops2 > 0 && randomized2.reduce(function (a, b, cIndex) {
            if (cIndex != 2) return a + b;
            if (cIndex == 2) return a * b;
        }) !== kHarga);

        const rdm = Math.floor(Math.random() * (99 - 10)) + 10;

        const hasil = `${rdm.toString().at(0)}${randomized.join("")}${randomized2.join("")}${rdm.toString().at(1)}`
        sethasilKode(hasil)
    }
    const [tagProduk, settagProduct] = useState([
        { name: 'sabun', produks: [] },
        { name: 'essence', produks: [] },
        { name: 'serum', produks: [] },
        { name: 'pagi1', produks: [] },
        { name: 'pagi2', produks: [] },
        { name: 'malam1', produks: [] },
        { name: 'malam2', produks: [] },
        { name: 'mask', produks: [] },
        { name: 'totol', produks: [] },

    ])
    const changeContent = useCallback(
        (val) => {
            setContent(val)
        },
        [content],
    )
    useEffect(() => {
        // console.log(selectedKontrol)
        // setContent(selectedKontrol.result.dokul_konsultasi_kontrol_result?.result_text)
        // setContent2(selectedKontrol.result?.question_answer)
        setcatatan(selectedKontrol.result.dokul_konsultasi_kontrol_result?.note)
        setisRefund(selectedKontrol.result.dokul_konsultasi_kontrol_result && selectedKontrol.result.dokul_konsultasi_kontrol_result?.refund == 1)

    }, [selectedKontrol])
    useEffect(() => {
        if (selectedTag != undefined) {
            setshowProduct(true)
        }
    }, [selectedTag])

    const replaceTagsProduk = (word, curTag) => {
        if (!word || word == null) return word
        let result = word
        let _catatan = catatan!=undefined ? catatan : ""
        curTag.forEach(x => {
            const produk = x.produks.reduce((prev, cur) => prev + cur.nick_name + ", ", "")
            const produkFull = x.produks.reduce((prev, cur) => prev + cur.name + ", ", "")
            var replace = `{${x.name}}`;
            var re = new RegExp(replace, "g");
            if (x.produks.length > 0) {
                // result = result.replace(/{x.name}/gi, `<b>${selectedKontrol.dokul_user.gender}</b>`)
                result = result.replace(re, `<b>${produkFull} </b>`);//result.replaceAll(`{${x.name}}`, `<b>${produk} </b>`)
                _catatan = _catatan.includes(produk) ? _catatan : _catatan + " " + produk
                // result = result.replace(/{pagi1}/gi, `<b>${selectedKontrol.dokul_user.gender}</b>`)
                // // const _statusKawin=userData.accountinfo.gender=="Pria"?statusPria[userData.accountinfo.status_kawin]:status[userData.accountinfo.status]
                // result = result.replace(/{pagi2}/gi, `<b>${selectedKontrol.dokul_user.status_kawin}</b>`)

                // result = result.replace(/{malam1}/gi, `<b>${selectedKontrol.dokul_user.status_kawin}</b>`)
                // result = result.replace(/{malam2}/gi, `<b>${selectedKontrol.dokul_user.status_kawin}</b>`)
                // result = result.replace(/{essence}/gi, `<b>${selectedKontrol.dokul_user.status_kawin}</b>`)
                // result = result.replace(/{serum}/gi, `<b>${selectedKontrol.dokul_user.status_kawin}</b>`)
            }


        })
        //replace tag user
        result = result.replace(/{nama}/gi, `<b>${selectedKontrol.dokul_user.first_name}</b>`)

        setcatatan(_catatan)

        setContent(result)
    }
    const _checkTagIsExist = (_tag) => {
        if (!content) return false
        if (_tag.produks.length > 0) return true
        return content.includes(`{${_tag.name}}`)
    }

    // console.log(selectedKontrol)
    return (
        <div>
            <h4 className="bg-primary p-2 mt-2">Hasil</h4>
            <div className="d-flex flex-column">
                <div className='d-flex flex-row bg-silver'>
                    <div className='p-2'>Refund biaya :</div>
                    <div className='m-2'>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-main" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={isRefund} onChange={(e) => {
                                // if (selectedKontrol.result.dokul_konsultasi_kontrol_result&&selectedKontrol.result.dokul_konsultasi_kontrol_result.id)
                                //     updateControlResult(selectedKontrol.result.dokul_konsultasi_kontrol_result.id, { refund: 0 }, false, false, false, false, false, true)
                                // let _dokul_konsultasi_kontrol_result = selectedKontrol.result.dokul_konsultasi_kontrol_result != null ? selectedKontrol.result.dokul_konsultasi_kontrol_result : {}
                                // _dokul_konsultasi_kontrol_result.refund = e.target.checked ? 1 : 0
                                // const _result = { ...selectedKontrol.result, dokul_konsultasi_kontrol_result: _dokul_konsultasi_kontrol_result }

                                setisRefund(e.target.checked ? 1 : 0)
                                // setselectedKontrol(x => {
                                //     return { ...x, result: _result }
                                // })

                                // let curResult = { ...selectedKontrol.result }
                                // let konResult = { ...curResult.dokul_konsultasi_kontrol_result }
                                // konResult.refund = e.target.checked ? 1 : 0
                                // curResult.dokul_konsultasi_kontrol_result = konResult
                                // setselectedKontrol({ ...selectedKontrol, result: curResult })
                            }} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                        </div>
                    </div>
                    <div className='p-2'>Voucher  :</div>
                    <div className='m-2'>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-main" type="checkbox" disabled={selectedKontrol.konsul_type == 3} id="flexSwitchCheckDefault" defaultChecked={voucherCashback} onChange={(e) => {

                                setvoucherCashback(e.target.checked)
                            }} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                        </div>
                    </div>


                    {/* <input className="form-check-input" type="radio" name="refund" id="refund" value="0" checked={(!selectedKontrol.result.dokul_konsultasi_kontrol_result || selectedKontrol.result.dokul_konsultasi_kontrol_result?.refund == 0)} onClick={async () => {
                                            if (selectedKontrol.result.dokul_konsultasi_kontrol_result)
                                                await updateControlResult(selectedKontrol.result.dokul_konsultasi_kontrol_result.id, { refund: 0 }, false, false, false, false, false, true)
                                            let curResult = { ...selectedKontrol.result }
                                            let konResult = { ...curResult.dokul_konsultasi_kontrol_result }
                                            konResult.refund = 0
                                            curResult.dokul_konsultasi_kontrol_result = konResult
                                            setselectedKontrol({ ...selectedKontrol, result: curResult })
                                        }} />
                                        <label className="form-check-label mr-3" htmlFor="refund">Tidak</label>
                                        {" "}
                                        <input className="form-check-input" type="radio" name="refund" id="refund" value="1" checked={selectedKontrol.result.dokul_konsultasi_kontrol_result?.refund == 1} onClick={async () => {
                                            if (selectedKontrol.result.dokul_konsultasi_kontrol_result) {
                                                await updateControlResult(selectedKontrol.result.dokul_konsultasi_kontrol_result.id, { refund: 1 }, false, false, false, false, false, true)
                                                let curResult = { ...selectedKontrol.result }
                                                let konResult = { ...curResult.dokul_konsultasi_kontrol_result }
                                                konResult.refund = 1
                                                curResult.dokul_konsultasi_kontrol_result = konResult
                                                setselectedKontrol({ ...selectedKontrol, result: curResult })
                                            }
                                        }} />
                                        <label className="form-check-label" htmlFor="refund">Ya</label> */}

                </div>
                <div className='d-flex flex-column bg-silver mt-2 p-2'>
                    {/* <div className='p-2'>Catatan sebelumnya : {selectedKontrol.history[0].}</div> */}
                    <div className='p-2'>Catatan :</div>
                    <Input type='textarea' value={catatan ? catatan : ""} onChange={(e) => {
                        // let _dokul_konsultasi_kontrol_result = selectedKontrol.result.dokul_konsultasi_kontrol_result ? selectedKontrol.result.dokul_konsultasi_kontrol_result : {}
                        // _dokul_konsultasi_kontrol_result.note = e.target.value
                        // const _result = { ...selectedKontrol.result, dokul_konsultasi_kontrol_result: _dokul_konsultasi_kontrol_result }
                        setcatatan(e.target.value)
                        // setselectedKontrol(x => {
                        //     return { ...x, result: _result }
                        // })

                    }}></Input>

                    {/* {selectedKontrol.result.dokul_konsultasi_kontrol_result && <>
                                            <div className="bg-light p-2" dangerouslySetInnerHTML={{ __html: selectedKontrol.result.dokul_konsultasi_kontrol_result.note }} />
                                        </>} */}
                </div>
                {/* <div className='d-flex flex-column bg-silver mt-2 p-2'>
                    
                    <Row className='mb-2'>
                        <Col md='3'>
                            <div className='p-2'>Produk :</div>
                            <Input type='number' max={81}  value={kodeProduk}  onChange={(e) => setkodeProduk(e.target.value)}></Input>
                        </Col>
                        <Col md='3'>
                            <div className='p-2'>Harga :</div>
                            <Input type='number' max={81} value={kodeHarga} onChange={(e) => setkodeHarga(e.target.value)}></Input>
                        </Col>
                        <Col md='6'>
                            <div className='p-2'>Hasil :</div>
                            <Input type='text' value={hasilKode}></Input>
                        </Col>
                    </Row>

                    <Button onClick={() => combineCode([9, 9, 9], 13)}>Refresh</Button>

                    
                </div> */}
                <div className='d-flex flex-row flex-wrap bg-silver mt-2'>
                    {tagProduk.map((x, idx) => {
                        if (_checkTagIsExist(x)) {
                            return <div className='p-2' key={idx}><button className='btn btn-xs btn-light' onClick={(e) => {
                                // replaceTagsProduk(content)
                                setselectedTag(idx)


                            }}>{x.name}</button></div>
                        } else {
                            return ""
                        }
                    }
                    )}
                    {/* <div className='p-2'><button className='btn btn-xs btn-light'>pagi1</button></div>
                        <div className='p-2'><button className='btn btn-xs btn-light'>pagi2</button></div>
                        <div className='p-2'><button className='btn btn-xs btn-light'>malam1</button></div>
                        <div className='p-2'><button className='btn btn-xs btn-light'>malam2</button></div>
                        <div className='p-2'><button className='btn btn-xs btn-light'>essence</button></div>
                        <div className='p-2'><button className='btn btn-xs btn-light'>serum</button></div> */}
                </div>
                {/* <div className="bg-light p-2">
                                        <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                            seteditedText(4)
                                            setContent(selectedKontrol.result.dokul_konsultasi_kontrol_result?.note)
                                            setmodalEditText(true)
                                        }}>Edit</button>
                                    </div> */}
                <div className='d-flex flex-row bg-primary'>
                    <div className='p-2'>Show All :</div>
                    <div className='m-2'>
                        <div className="form-check form-switch">
                            <input className="form-check-input bg-main" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={showAllList} onChange={(e) => {
                                setshowAllList(!showAllList)
                            }} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                        </div>
                    </div>
                </div>
                {selectedKontrol.result.dokul_konsultasi_kontrol_histories.filter(x=>listDeleted.indexOf(x.id)==-1).map((hk, ihk) => <>
                    <div key={ihk} className='d-flex flex-column bg-grey mt-2'>
                        <div className={`d-flex flex-row bg-grey mt-2 ${hk.type == 1 ? "text-success" : "text-info"} border-bottom`}>
                            <span className='p-2'>{hk.type == 1 ? "Dokter :" : "Pasien :"} </span>
                            <span className="text-small p-2">{moment(hk.created_date).format("DD MMMM YYYY HH:mm")}</span>
                            <button className="btn btn-light btn-xs m-2 ms-auto" onClick={() => {
                                // seteditedText(0)
                                // setContent(selectedKontrol.result.dokul_konsultasi_kontrol_result?.result_text)
                                //setjawaban2(!jawaban2)
                                // setmodalEditText(true)
                                const idxcol = collpseList.indexOf(hk.id)
                                if (idxcol != -1) {

                                    const newList = [...collpseList]
                                    if (newList.length > 1)
                                        newList.splice(idxcol, 1)
                                    else
                                        newList.pop()
                                    setcollpseList(newList)

                                } else {
                                    setcollpseList(x => [...x, hk.id])

                                }
                            }}>{collpseList.indexOf(hk.id) != -1 ? <i className='icofont icofont-eye-blocked text-danger' /> : <i className='icofont icofont-eye text-success' />}</button>
                            <button className="btn btn-light btn-xs m-2 " onClick={async () => {
                                await deleteHistory(hk.id)
                                setlistDeleted(x=>[...x,hk.id])

                            }}><i className='icofont icofont-error text-danger' /></button>
                        </div>
                        <Collapse isOpen={collpseList.indexOf(hk.id) != -1 || showAllList} >
                            <div className="bg-light p-2 text-dark" dangerouslySetInnerHTML={{ __html: hk.content }} />
                        </Collapse>
                    </div>
                    {/* <h6 className="mt-2">Dokter :</h6>
                                        <span className="text-muted text-small">{moment(selectedKontrol.result.answer_date).format("DD MMMM YYYY HH:mm")}</span>

                                        <div className="bg-light p-2" dangerouslySetInnerHTML={{ __html: selectedKontrol.result.question_answer }} />
                                        <div className="bg-light p-2">
                                            <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                                // seteditedText(2)
                                                // setContent(selectedKontrol.result.question_answer)
                                                // setmodalEditText(true)
                                            }}>Edit</button>
                                        </div> */}
                </>)}

                {/* {selectedKontrol.result.result_question && <>
                                        <h6 className="mt-2">Dokter :</h6>
                                        <span className="text-muted text-small">{moment(selectedKontrol.result.answer_date).format("DD MMMM YYYY HH:mm")}</span>

                                        <div className="bg-light p-2" dangerouslySetInnerHTML={{ __html: selectedKontrol.result.question_answer }} />
                                        <div className="bg-light p-2">
                                            <button className="btn btn-primary btn-xs w-25  float-end" onClick={() => {
                                                seteditedText(2)
                                                setContent(selectedKontrol.result.question_answer)
                                                setmodalEditText(true)
                                            }}>Edit</button>
                                        </div>
                                    </>} */}

                <div className='d-flex flex-row bg-silver mt-2'>
                    <span className='p-2'>Dokter : </span>
                    {selectedKontrol.result.dokul_konsultasi_kontrol_result && <span className="text-small p-2">{moment().format("DD MMMM YYYY HH:mm")}</span>}
                    <button className="btn btn-primary btn-xs m-2 ms-auto" onClick={() => {
                        // setContent(selectedKontrol.result.dokul_konsultasi_kontrol_result?.result_text)
                        setjawaban1(!jawaban1)
                    }}>{jawaban1 ? "Hide" : "Show"}</button>
                </div>

                <div className='d-flex flex-row flex-wrap'>
                    {ansTemplate.filter(x => x.konsul_id.split(",").indexOf(selectedKontrol.result.konsul_id.toString()) != -1).map((x, idx) =>

                        <div key={idx} className='p-2'><button className='btn btn-xs btn-light' onClick={(e) => {

                            editor1.current.model.change(writer => {
                                const viewFragment = editor1.current.data.processor.toView(x.text);
                                const modelFragment = editor1.current.data.toModel(viewFragment);
                                editor1.current.model.insertContent(modelFragment, editor1.current.model.document.selection);
                            });
                            // if (content)
                            //     setContent(ct => ct.concat( x.text))
                            // else
                            //     setContent(x.text)
                        }}>{x.name}</button></div>
                    )}
                </div>

                {/* <Ckinput content={content} changeContent={changeContent} /> */}

                <Collapse isOpen={jawaban1} >
                    <div className='bg-silver p-1' >
                        <CKEditor
                            editor={ClassicEditor}
                            config={ckconfig}
                            activeclassName="bg-silver"
                            data={content ? content : ""}
                            onReady={editor => {
                                editor.editing.view.document.on('change:isFocused', (evt, data, isFocused) => {
                                    editor1.current = editor
                                });

                                editor.editing.view.focus();

                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                changeContent(data)
                            }}


                        />
                    </div>

                </Collapse>



            </div>


            <RekomendasiProduk newProducts={newProducts} deletedProducts={deletedProducts} setnewProducts={setnewProducts} setdeletedProducts={setdeletedProducts} selectedTag={selectedTag} setrequiredProduk={setrequiredProduk} setshowProduct={setshowProduct} showProduct={showProduct} selectedKontrol={selectedKontrol} selectedProduct={selectedProduct} setselectedProduct={setselectedProduct} produkTags={tagProduk[selectedTag]?.produks} singgleSelect={singgleSelect} setSinggleProduct={(dt) => {
                if (dt) {
                    let curTag = [...tagProduk]
                    // let idxTag = curTag.findIndex(x => x.name == selectedTag)
                    curTag[selectedTag] = { ...curTag[selectedTag], produks: dt }
                    settagProduct(curTag)
                    replaceTagsProduk(content, curTag)
                    setselectedProduct(cp => cp.concat(dt))
                    setnewProducts([...newProducts,dt])
                    setselectedTag()
                }

                setshowProduct(false)


            }} />

            <div className="mt-2"><button disabled={loadingSubmit} className={`btn btn-success w-100`} onClick={async () => {
            //    console.log(deletedProducts);
            //    console.log(newProducts);
            //    return
               let submitData = {
                    resultText: content,//selectedKontrol.result.dokul_konsultasi_kontrol_result.result_text,
                    resultNote: catatan,
                    resultRefund: isRefund,
                    resultProduct: selectedProduct,
                    voucherCashback: voucherCashback,
                    requiredProduk:requiredProduk,
                    newProducts,
                    deletedProducts

                }
              
                
                if (!selectedKontrol.result.dokul_konsultasi_kontrol_result || !selectedKontrol.result.dokul_konsultasi_kontrol_result.id) {
                    
                //     console.log(submitData)
                // return
                const _result = await submitControlResult(selectedKontrol.id, submitData)

                } else {
                    console.log("masuk")
                    console.log(submitData)
                // return
                    // submitData.result_text = content
                    submitData.question_answer = content
                    submitData.note = catatan
                    submitData.refund = isRefund
                    submitData.voucherCashback = voucherCashback
                    await updateControlResult(selectedKontrol.id, submitData, true, true, false, true, true, true)
                }
                refetchData()
                setModalOpen(false)
            }}>Submit</button> </div>

        </div>
    )
}

export default ResultKontrol