import React, { useEffect, useRef, useState } from 'react'
import { Col, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import CreateProductKhusus from './create.produk'
import { useQuery, useMutation, readQuery, useLazyQuery } from '@apollo/react-hooks';
import { DELETE_LIST_KONSULTASI_HISTORY_PRODUK, GET_LIST_KONSULTASI_HISTORY_PRODUK, GET_LIST_KONSULTASI_KONTROL, GET_PRODUCTS, GET_PRODUCTS_SIMPLE } from '../../../constants/graph';

function RekomendasiProduk({ deletedProducts,newProducts,setnewProducts,setdeletedProducts,selectedProduct, setselectedProduct,setrequiredProduk, selectedKontrol, showProduct, setshowProduct, setSinggleProduct, produkTags, singgleSelect, selectedTag }) {
    const { loading: lProduct, data: dProduct, error: eProduct } = useQuery(GET_PRODUCTS_SIMPLE,{
        variables:{
            textSeach:'all',
            brand:'all'
        }
    });
    const [getHistoryProduct, { loading: lHistoryProduct, error: eHistoryProduct, data: dHistoryProduct }] = useLazyQuery(GET_LIST_KONSULTASI_HISTORY_PRODUK, {
        fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => {
        //     setpage(data.submitkontrol.currentPage)
        //     settotalPages(data.submitkontrol.totalPages)
        //     // setlistData(data.submitkontrol.data)
        // }
    })
    const [deleteProdukHistory, { loading: lDeleteProdukHistory }] = useMutation(DELETE_LIST_KONSULTASI_HISTORY_PRODUK, {
        onCompleted: (data) => {

            setdeletedProdukHistoryId(x => [...x, data.deleteHistoryProduk.id])
        }

    })
    const searchInput = useRef(null)
    // const [requiredProduk, setrequiredProduk] = useState(selectedKontrol.produk_required)
    const [modalProduk, setmodalProduk] = useState(false)
    const [createProduct, setcreateProduct] = useState(false)
    // const [selectedProduct, setselectedProduct] = useState(resultProduct)
    const [textSeach, settextSeach] = useState("")
    const [listSelectedProducts, setlistSelectedProducts] = useState([])
    const [historyProdukModal, sethistoryProdukModal] = useState(false)
    const [deletedProdukHistoryId, setdeletedProdukHistoryId] = useState([])
    const [newProdukList, setnewProdukList] = useState([])

    //     useEffect(() => {
    //         if(modalProduk) searchInput.current.focus()
    // console.log(searchInput)

    //     }, [modalProduk])

    useEffect(() => {
        if (historyProdukModal) {
            setmodalProduk(true)
        }


    }, [historyProdukModal])

    useEffect(() => {
        // if(listSelectedProducts.length>0)
        //      handleCloseModal(produkTags?true:false)
    }, [listSelectedProducts])



    useEffect(() => {
        // console.log(produkTags)
        // console.log(showProduct)
        if (!produkTags) {
            setlistSelectedProducts(selectedProduct)
        } else {
            setlistSelectedProducts(produkTags)
        }
    }, [produkTags, selectedProduct])



    const handleFilterProduct = (_prod) => {
        if (historyProdukModal) {
            return dHistoryProduct.historyProdukKonsultasi.some(p => p.dokul_konsultasi_kontrol_result_produks.some(y => y.produk_id == _prod.id))
        }
        // console.log(_prod)
        // _prod.dokul_products_skus.some(x2=>x2.nick_name.toLowerCase().includes(textSeach.toLowerCase())
        return _prod.dokul_products_skus.some(x => x.nick_name && (x.nick_name.toLowerCase().includes(textSeach.toLowerCase()) || x.name.toLowerCase().includes(textSeach.toLowerCase()) || _prod.name.toLowerCase().includes(textSeach.toLowerCase())))
    }
    const handleCloseModal = (isClose = true, selected) => {
        if (showProduct) setshowProduct(false)
        settextSeach("")

        sethistoryProdukModal(false)

        if (produkTags) {

            setSinggleProduct(selected)
            //setmodalProduk(false)
        } else {
            setselectedProduct(listSelectedProducts)

        }
        setlistSelectedProducts([])
        if (isClose) setmodalProduk(false)
    }
    const handleCloseAddProdukKhusus = (newData) => {
        // console.log(newData)
        // setnewProdukList(x => [...x, newData])
        setselectedProduct(x=>[...x,newData])
        setnewProducts(x=>[...x,newData])
        setmodalProduk(false)
    }
    const handlePress = e => {
        console.log(e)
        if (e.key === 'Enter') {

            const jmlFilter = dProduct.productssimple.filter(x1 => handleFilterProduct(x1)).filter(x => x.branch_id == selectedKontrol.branch_id)
            if (jmlFilter.length == 1) {

                let _currentSelectedProduct = [...listSelectedProducts]

                _currentSelectedProduct.push({ ...jmlFilter[0], limit_order: 0, status: 1 })
                // setlistSelectedProducts(_currentSelectedProduct)
                settextSeach("")
                sethistoryProdukModal(false)
                setselectedProduct(_currentSelectedProduct)
                setmodalProduk(false)
            }
        }
    }


    return (
        <div>
            <div className='d-flex flex-row bg-primary ' >
                <h4 className="p-2 ">Panggil Apotek</h4>
                <div className='m-2'>
                    <div className="form-check form-switch  float-end">
                        <input className="form-check-input bg-main" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={selectedKontrol.produk_required} onChange={(e) => {
                                // console.log(e.target.checked)
                                setrequiredProduk(e.target.checked)
                        }} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-row'>
                <div className='p-2'>
                    <button className="btn btn-info btn-xs float-end" onClick={async () => {

                        getHistoryProduct({ variables: { id: selectedKontrol.user_id } })
                        setdeletedProdukHistoryId([])
                        setcreateProduct(false)
                        sethistoryProdukModal(true)
                    }}>History produk konsultasi</button>
                </div>
                <div className='p-2'><button className="btn btn-primary btn-xs float-end" onClick={async () => {
                    // await sleep()
                    setcreateProduct(false)
                    setmodalProduk(true)

                }}>Tambah produk</button>
                </div>
                <div className='p-2'>
                    <button className="btn btn-secondary btn-xs float-end" onClick={() => {
                        setcreateProduct(true)
                        setmodalProduk(true)
                    }}>Create produk khusus</button>
                </div>
            </div>
            <ListGroup className='bg-silver p-2' style={{ minHeight: 100 }}>
                <Row>
                    <div className='col-2'>
                        #
                    </div>
                    <div className='col-7'>
                        Nama produk
                    </div>
                    <div className='col-2'>
                        Limit order
                    </div>
                    <div className='col-1'>

                    </div>
                </Row>
                {selectedProduct && selectedProduct.length > 0 ? selectedProduct.map((x, idx) => {
                    // const _pr = dProduct.products.find(_xpr => _xpr.id == x.produk_id)
                    return <ListGroupItem key={idx} color="light" className="">
                        <Row>
                            <div className='col-2'>
                                <img src={`${process.env.REACT_APP_IMAGE_HOST}${x.dokul_product_images[0].image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            </div>
                            
                            <div className='col-7'>
                                <span className="p-2">{x.name}</span><br />
                                <span className="p-2">{x.dokul_products_skus.find(sx => sx.id == 455)?.name}</span>
                                {x.name != x.dokul_products_skus[0].name && <span className="p-2">{x.dokul_products_skus[0].name}</span>}



                            </div>
                            <div className='col-2'>
                                <div className='d-flex flex-row'>
                                    <div className='mx-0'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" defaultChecked={x.status == 3} onChange={(e) => {
                                                let _currentSelectedProduct = [...selectedProduct]
                                                const idxProd = _currentSelectedProduct.findIndex(_x => _x.id == x.id)
                                                if (e.target.checked) {
                                                    _currentSelectedProduct[idxProd] = { ...x, status: 3 }
                                                } else {
                                                    _currentSelectedProduct[idxProd] = { ...x, status: 1 }
                                                }
                                                setselectedProduct(_currentSelectedProduct)

                                            }} />
                                            {/* <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">Beri tanda</label> */}
                                        </div>
                                    </div>
                                </div>
                                {x.status == 3 && <Input type='number' min={0} placeholder='Limit order' value={x.limit_order} onChange={(e) => {
                                    let _currentSelectedProduct = [...selectedProduct]
                                    const idxProd = _currentSelectedProduct.findIndex(_x => _x.id == x.id)

                                    _currentSelectedProduct[idxProd] = { ...x, limit_order: e.target.value }
                                    setselectedProduct(_currentSelectedProduct)
                                }
                                } />
                                }
                            </div>
                            <div className='col-1'>
                                <button className='btn btn-xs btn-danger' onClick={() => {
                                    let _currentSelectedProduct = [...selectedProduct]
                                    let _currentNewProduct = [...newProducts]
                                    const idxProd = _currentSelectedProduct.findIndex(_x => _x.id == x.id)
                                    _currentSelectedProduct.splice(idxProd, 1)
                                    const _idxn = _currentNewProduct.findIndex(_p => _p.id == x.id)
                                            if(_idxn!=-1){
                                                _currentNewProduct.splice(_idxn, 1)
                                                setnewProducts(_currentNewProduct)
                                            }else{

                                                setdeletedProducts(xo=>[...xo,{ ...x }])
                                            }
                                    setselectedProduct(_currentSelectedProduct)
                                }}><i className='fa fa-times'></i></button>
                            </div>
                        </Row>
                    </ListGroupItem>


                }) : <div className="bg-light p-2 text-center text-muted"><h4>Belum ada produk direkomendasikan</h4></div>
                }

                {/* start list new produk */}

                {newProdukList.map((x, idx) => {
                    // const _pr = dProduct.products.find(_xpr => _xpr.id == x.produk_id)
                    return <ListGroupItem key={idx} color="light" className="">
                        <Row>
                            <div className='col-2'>
                                <img src={`${process.env.REACT_APP_IMAGE_HOST}${x.dokul_product_images[0].image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                            </div>
                            <div className='col-7'>
                                <span className="p-2">{x.name}</span><br />
                                <span className="p-2">{x.dokul_products_skus.find(sx => sx.id == 455)?.name}</span>
                                {x.name != x.dokul_products_skus[0].name && <span className="p-2">{x.dokul_products_skus[0].name}</span>}


                            </div>
                            <div className='col-2'>
                                <div className='d-flex flex-row'>
                                    <div className='mx-0'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" defaultChecked={x.userproduk.status == 3} onChange={(e) => {
                                                // let _currentSelectedProduct = [...selectedProduct]
                                                // const idxProd = _currentSelectedProduct.findIndex(_x => _x.id == x.id)
                                                // if (e.target.checked) {
                                                //     _currentSelectedProduct[idxProd] = { ...x, status: 3 }
                                                // } else {
                                                //     _currentSelectedProduct[idxProd] = { ...x, status: 1 }
                                                // }
                                                // setselectedProduct(_currentSelectedProduct)

                                            }} />
                                            {/* <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">Beri tanda</label> */}
                                        </div>
                                    </div>
                                </div>
                                {x.userproduk.status == 3 && <Input type='number' min={0} placeholder='Limit order' value={x.userproduk.limit_order} onChange={(e) => {
                                    // let _currentSelectedProduct = [...selectedProduct]
                                    // const idxProd = _currentSelectedProduct.findIndex(_x => _x.id == x.id)

                                    // _currentSelectedProduct[idxProd] = { ...x, limit_order: e.target.value }
                                    // setselectedProduct(_currentSelectedProduct)
                                }
                                } />
                                }
                            </div>
                            <div className='col-1'>
                                <button className='btn btn-xs btn-danger' onClick={() => {
                                    // let _currentSelectedProduct = [...selectedProduct]
                                    // const idxProd = _currentSelectedProduct.findIndex(_x => _x.id == x.id)
                                    // _currentSelectedProduct.splice(idxProd, 1)
                                    // setselectedProduct(_currentSelectedProduct)
                                }}><i className='fa fa-times'></i></button>
                            </div>
                        </Row>
                    </ListGroupItem>


                })}



                <div className="d-flex flex-column bg-light p-2 ">



                </div>
                {/* <span className="float-right"><button className="btn btn-success btn-xs" onClick={() => setmodalProduk(true)}>Add</button></span> */}
            </ListGroup>

            <Modal autoFocus={false} isOpen={modalProduk || showProduct} toggle={() => {
                // if (showProduct) setshowProduct(false)
                // settextSeach("")
                // setmodalProduk(false)
                // sethistoryProdukModal(false)
                // if (showProduct) {
                //     setSinggleProduct(listSelectedProducts)
                // } else {
                //     setselectedProduct(listSelectedProducts)
                // }
                // setlistSelectedProducts([])
                handleCloseModal()
            }} centered size={`${createProduct ? "lg" : "lg"}`} scrollable>
                <ModalHeader className="bg-primary" toggle={() => {
                    handleCloseModal()
                }}>{historyProdukModal ? "History" : createProduct ? "Create" : "Tambah"} rekomendasi produk</ModalHeader>

                {!historyProdukModal && <ModalBody className="bg-light text-dark">
                    {!createProduct && <Row>
                        {/* {dProduct && selectedKontrol && dProduct.products.filter(x => x.name.toLowerCase().includes(textSeach.toLowerCase())).map((x, idx) => */}
                        <Input className="mt-2 mb-3" autoFocus={true} ref={searchInput} type="search" placeholder="Cari produk" onChange={(e) => settextSeach(e.target.value)} onKeyDown={handlePress} />

                        {listSelectedProducts && dProduct && dProduct.productssimple.filter(x1 => handleFilterProduct(x1)).filter(x => x.branch_id == selectedKontrol.branch_id).map((x, idx) =>
                            <FormGroup key={idx} check className="col-6">
                                <Label check >
                                    <Input type="checkbox" checked={listSelectedProducts.some(p => p.id == x.id)} onChange={(e) => {
                                        let _currentSelectedProduct = [...listSelectedProducts]
                                        let _currentNewProduct = [...newProducts]
                                        let _currentDeletedProduct = [...deletedProducts]

                                        if (e.target.checked) {
                                            _currentSelectedProduct.push({ ...x, limit_order: 0, status: 1 })
                                            setnewProducts(xo=>[...xo,{ ...x, limit_order: 0, status: 1 }])
                                        } else {
                                            const _idx = _currentSelectedProduct.findIndex(_p => _p.id == x.id)
                                            _currentSelectedProduct.splice(_idx, 1)

                                            //jika baru di add
                                            const _idxn = _currentNewProduct.findIndex(_p => _p.id == x.id)
                                            if(_idxn!=-1){
                                                _currentNewProduct.splice(_idxn, 1)
                                                setnewProducts(_currentNewProduct)
                                            }else{

                                                setdeletedProducts(xo=>[...xo,{ ...x }])
                                            }


                                        }
                                        setlistSelectedProducts(_currentSelectedProduct)
                                        if (produkTags)
                                            handleCloseModal(false, _currentSelectedProduct)
                                        // setSinggleProduct(_currentSelectedProduct)
                                        // setselectedProduct(_currentSelectedProduct)
                                    }} />{' '}
                                    {x.name}{x.branch_id == 1 ? " ( JAKARTA )" : " ( BALIKPAPAN )"}{x.is_premium == 1 ? " ( Premium )" : ""}
                                </Label>
                            </FormGroup>)}
                    </Row>}
                    {createProduct && <Row>
                        <CreateProductKhusus selectedKontrol={selectedKontrol} handleCloseModal={handleCloseAddProdukKhusus} />
                    </Row>}
                </ModalBody>}

                {historyProdukModal && dHistoryProduct && <ModalBody className="bg-light text-dark">

                    {listSelectedProducts && dHistoryProduct.historyProdukKonsultasi.map((x0, idx) => {
                        return x0.dokul_konsultasi_kontrol_result_produks.map(x1 => {
                            const x = dProduct.productssimple.find(xp => xp.id == x1.produk_id)

                            if (!x || deletedProdukHistoryId.some(x2 => x2 == x1.id))
                                return ""
                            return <Row className='border m-2'>
                                <div className='col-2 p-2'>
                                    <img src={`${process.env.REACT_APP_IMAGE_HOST}${x.dokul_product_images[0].image_url}`} className="img-fluid image_zoom_1 blur-up lazyloaded" />
                                </div>
                                <div className='col-7 p-2'>
                                    <span className="p-2">{x.name}</span>
                                </div>

                                <div className='col-1 p-2'>
                                    <button className='btn btn-xs btn-danger' onClick={() => {
                                        deleteProdukHistory({
                                            variables: {
                                                id: x1.id
                                            }
                                        })
                                    }}><i className='fa fa-times'></i></button>
                                </div>
                            </Row>
                        }
                        )


                    })}


                </ModalBody>}

                {/* {showProduct == false && <ModalBody className="bg-light text-dark">
                    {!createProduct && <Row>
                        <Input className="mt-2 mb-3" type="search" placeholder="Cari produk" onChange={(e) => settextSeach(e.target.value)} />

                        {dProduct && selectedKontrol && dProduct.products.filter(x1 => handleFilterProduct(x1)).filter(x => x.branch_id == selectedKontrol.branch_id).map((x, idx) =>
                            <FormGroup key={idx} check className="col-6">
                                <Label check >
                                    <Input type="checkbox" checked={selectedProduct.some(p => p.id == x.id)} onClick={(e) => {
                                        let _currentSelectedProduct = [...selectedProduct]

                                        if (e.target.checked) {
                                            _currentSelectedProduct.push({ ...x, limit_order: 0, status: 1 })
                                        } else {
                                            const _idx = _currentSelectedProduct.findIndex(_p => _p.id == x.id)
                                            _currentSelectedProduct.splice(_idx, 1)
                                        }
                                        setselectedProduct(_currentSelectedProduct)
                                    }} />{' '}
                                    {x.name}{x.branch_id == 1 ? " ( JAKARTA )" : " ( BALIKPAPAN )"}
                                </Label>
                            </FormGroup>)}
                    </Row>}
                    {createProduct && <Row>
                        <CreateProductKhusus />
                    </Row>}
                </ModalBody>
                } */}

            </Modal>
        </div>
    )
}

export default RekomendasiProduk