import React, { Component, Fragment, useState } from 'react';
import Breadcrumb from './common/breadcrumb';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import CountUp from 'react-countup';
import { Chart } from "react-google-charts";
import CanvasJSReact from '../assets/canvas/canvasjs.react';
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject, NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../apollo'
import { Pie, Doughnut, Bar, Line } from 'react-chartjs-2';
import Loading from './common/loading'
import Errorpage from './common/Errorpage'
import {
    pieOptions,
    doughnutOption,
    lineOptions,
    buyOption,
    employeeData,
    employeeOptions
} from '../constants/chartData'
// image impoer
import user2 from '../assets/images/dashboard/user2.jpg';
import user1 from '../assets/images/dashboard/user1.jpg';
import man from '../assets/images/dashboard/man.png';
import user from '../assets/images/dashboard/user.png';
import designer from '../assets/images/dashboard/designer.jpg'
import TODAYORDER from './dashboard/order.today'
import TRANSACTIONTODAY from './dashboard/transaction.today'
import CUSTOMERSSTATUS from './dashboard/customer.status'
import LATESTORDER from './dashboard/latest.order'
import ORDERBYLOCATION from './dashboard/order.bylocation'
import ORDERSTATISTIC from './dashboard/order.statistic'

import PRODUKDIMINATI  from './dashboard/produk.dimintai'
import  SALESMONTH from './dashboard/sales.month'
import  SALESYEAR from './dashboard/sales.year'
import  RESERVASITODAY from './dashboard/reservasi.today'
import  USERTODAY from './dashboard/newuser.today'

import  PRODUCTCART from './dashboard/produk.cart'
import { GET_CATEGORIES, GET_TAGS, GET_DASHBOARD_DATA, GET_ORDERS } from '../constants/graph'

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


//export class Dashboard extends Component {
const Dashboard = ({ match }) => {
    const [selectedBranch, setselectedBranch] = useState(1)
    const user = JSON.parse(localStorage.getItem('CURRENT_USER'));


    // useEffect(() => {

       // console.log(user.role_id)
    //  render() {


    //const { loading, data, error, fetchMore, refetch, networkStatus } = useQuery(GET_DASHBOARD_DATA);

    // const lineData = {
    //     labels: ['100', '200', '300', '400', '500', '600', '700', '800'],
    //     datasets: [
    //         {
    //             lagend: 'none',
    //             data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
    //             borderColor: "#ff8084",
    //             backgroundColor: "#ff8084",
    //             borderWidth: 2
    //         },
    //         {
    //             lagend: 'none',
    //             data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
    //             borderColor: "#a5a5a5",
    //             backgroundColor: "#a5a5a5",
    //             borderWidth: 2
    //         }
    //     ]
    // };

    // const buyData = {
    //     labels: ["", "10", "20", "30", "40", "50"],
    //     datasets: [{
    //         backgroundColor: "transparent",
    //         borderColor: "#13c9ca",
    //         data: [20, 5, 80, 10, 100, 15],
    //     },
    //     {
    //         backgroundColor: "transparent",
    //         borderColor: "#a5a5a5",
    //         data: [0, 50, 20, 70, 30, 27],
    //     },
    //     {
    //         backgroundColor: "transparent",
    //         borderColor: "#ff8084",
    //         data: [0, 30, 40, 10, 86, 40],
    //     }]
    // }

    // const doughnutOptions = {
    //     title: "",
    //     pieHole: 0.35,
    //     pieSliceBorderColor: "none",
    //     colors: ['#ff8084', '#13c9ca', '#a5a5a5'],
    //     legend: {
    //         position: "none"
    //     },
    //     pieSliceText: "none",
    //     tooltip: {
    //         trigger: "none"
    //     },
    //     animation: {
    //         startup: true,
    //         easing: 'linear',
    //         duration: 1500,
    //     },
    //     chartArea: { left: 0, top: 10, width: '360px', height: '100%' },
    //     enableInteractivity: false,
    // }
    const pieOptions = {
        title: "dsds",
        pieHole: 1,
        slices: [
            {
                color: "#ff8084"
            },
            {
                color: "#13c9ca"
            },
            {
                color: "#f0b54d"
            },
        ],
        tooltip: {
            showColorCode: false
        },
        chartArea: { left: 10, top: 10, width: '560px', height: '100%' },
        //legend: "none",
        is3D: true,
    };
    const LineOptions = {
        hAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        vAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        colors: ['#ff8084'],
        legend: 'none',
    }
    const LineOptions1 = {
        hAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        vAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        colors: ['#13c9ca'],
        chartArea: { left: 0, top: 0, width: '100%', height: '100%' },
        legend: 'none',
    }
    const LineOptions2 = {
        hAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        vAxis: {
            textPosition: 'none', baselineColor: 'transparent',
            gridlineColor: 'transparent',
        },
        colors: ['#f5ce8a'],
        chartArea: { left: 0, top: 0, width: '100%', height: '100%' },
        legend: 'none',
    }
    // const LineOptions3 = {
    //     hAxis: {
    //         textPosition: 'none', baselineColor: 'transparent',
    //         gridlineColor: 'transparent',
    //     },
    //     vAxis: {
    //         textPosition: 'none', baselineColor: 'transparent',
    //         gridlineColor: 'transparent',
    //     },
    //     colors: ['#a5a5a5'],
    //     chartArea: { left: 0, top: 0, width: '100%', height: '100%' },
    //     legend: 'none',
    // }

    // if (networkStatus === NetworkStatus.refetch) return 'Refetching!';
    // if (loading) return <Loading />;
    // if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
    // //console.log(data)
    return (

        <Fragment>
            <Breadcrumb title="Dashboard" parent="Dashboard" />
            {user.role_id==5&&<div className="d-flex flex-row mb-5">
        
        <div className={`p-2 pointer ${selectedBranch==1?'bg-primary':''}`} onClick={()=>setselectedBranch(1)}>JAKARTA</div>
        <div className={`p-2  pointer ${selectedBranch==2?'bg-primary':''}`} onClick={()=>setselectedBranch(2)}>BALIKPAPAN</div>
        <div className={`p-2  pointer ${selectedBranch==3?'bg-primary':''}`} onClick={()=>setselectedBranch(3)}>ALL</div>
      </div>}
            <div className="container-fluid">
                <div className="row">
                    <TODAYORDER />
                    <TRANSACTIONTODAY />
                    <RESERVASITODAY />
                    <USERTODAY />


                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Order status</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <ORDERBYLOCATION />
                                   <ORDERSTATISTIC />
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-6 xl-100">
                        <PRODUKDIMINATI />
                    </div>
                    <div className="col-xl-6 xl-100">
                    <LATESTORDER />
                    </div>
                    <div className="col-xl-6 col-md-6 xl-50">
                        
                            <SALESMONTH></SALESMONTH>
                            
                        
                    </div>
                    <div className="col-xl-6 col-md-6 xl-50">
                        <SALESYEAR />
                    </div>



                    <PRODUCTCART />
                    <CUSTOMERSSTATUS />

                </div>
            </div>
        </Fragment>

    )
    //}
}
// javascript:void(0)

export default withApollo(Dashboard)
