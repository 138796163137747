import React, { Component, Fragment, useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject, NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../../apollo'
import { GET_CATEGORIES, GET_TAGS, GET_DASHBOARD_DATA, GET_ORDERS, GET_CUSTOMER_STATISTIC } from '../../../constants/graph'

import user2 from '../../../assets/images/dashboard/user2.jpg';
import Loading from '../../common/loading'
import Errorpage from '../../common/Errorpage'
const Customerstatus = ({ props }) => {
    //const [data,setData]=useState(props.data)
    const { loading, data, error, fetchMore, refetch, networkStatus } = useQuery(GET_CUSTOMER_STATISTIC);

    if (loading) return <Loading />;
    if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;

    return (
        <div className="col-xl-6 xl-100">
            <div className="card height-equal">
                <div className="card-header">
                    <h5>Peringkat pembelian pelanggan</h5>
                </div>
                <div className="card-body">
                    <div className="user-status table-responsive products-table">
                        <table className="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Grade</th>
                                    <th scope="col">jumlah order</th>
                                    <th scope="col">total belanja</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.customerStatistic.map((c, index) =>
                                    <tr key={index}>
                                        <td className="bd-t-none u-s-tb">
                                            <div className="align-middle image-sm-size"><img className="img-radius align-top m-r-15 rounded-circle blur-up lazyloaded" src={user2} alt="" data-original-title="" title="" />
                                                <div className="d-inline-block">
                                                    <h6>{c.dokul_user?.display_name} <span className="text-muted digits"></span></h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{c.dokul_user?.current_grade}</td>
                                        <td className="digits">{c.prodcount}</td>
                                        <td className="digits">{c.totalbelanja}</td>
                                    </tr>

                                )}


                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default withApollo(Customerstatus)
//export default ordertoday
