import React, { useState, Fragment, useEffect } from 'react'
import Breadcrumb from '../common/breadcrumb';
import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import { GET_PRODUCTS } from '../../constants/graph';


const ProductResult = (props) => {


  // const { loading, data, error } = useQuery(GET_PRODUCTS,);
  // const client = useApolloClient();

  // const [product, setproduct] = useState()
  // useEffect(() => {
  //   if (data) {
  //     let _pro = data.products.find(_p => _p.id ==props.prodId)
  //     setproduct(_pro)
  //   }
  // }, [data])

  // if (!data) return "Loading"


  // let product = client.readFragment({
  //     id: `Product:114`, // `id` is any id that could be returned by `dataIdFromObject`.
  //     fragment: gql`
  //       fragment readProduct on Product {
  //         name,
  //         dokul_products_skus
  //         branchId,
  //         dokul_tags_categories,
  //         dokul_product_images
  //         id
  //         description
  //         dokul_product_descriptions
  //         tags
  //         is_restricted 
  //         nick_name
  //         video
  //       }
  //     `,
  // }, true);


  if (!props.dataProduct) return "Product not found"
  return (
    <Fragment>

      <div className="d-flex flex-row">
        <div className="p-1"> <img src={` ${process.env.REACT_APP_IMAGE_HOST}${props.dataProduct.dokul_product_images[0]?.image_url}`} alt="" className="img-fluid lazyloaded m-0 p-0" style={{ width: 50 }} /></div>
        <div className="p-1"> {props.dataProduct.name}</div>
        <div className="p-1 ml-auto"> <i className="icofont icofont-close text-danger pointer" style={{cursor:"pointer"}} onClick={()=>props.onDetailDelete()} /></div>
      </div>
    </Fragment>
  )

}

export default withApollo(ProductResult)
