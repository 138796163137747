import React, { Component, Fragment, useMemo, useState } from 'react'
import { useTable } from "react-table";
import { withApollo } from '../../apollo'

import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
//import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
//import data from '../../../assets/data/category';
import Datatable from '../common/datatable';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'
import { Edit, Trash2, Plus } from 'react-feather'
import user from '../../assets/images/dashboard/user.png';
import { GET_LIST_KONSULTASI } from '../../constants/graph';
import { GET_DOKTERS, GET_KLINIKS, GET_RESERVASI, UPDATE_DOKTER } from '../../constants/graph';
import data from '../../assets/data/physical_list';
// import Table from './table';
import JadwalDokter from './konsultasi.jadwal';
import TemplateJadwal from './konsultasi.template';
import ServiceReservasi from './service.reservasi';
import JadwalPerawatan from './perawatan.jadwal';
import JadwalTindakan from './tindakan.jadwal';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import JadwalLibur from './JadwalLibur';

const TabSet = () => {

  // const { loading, data, error } = useQuery(GET_RESERVASI,);
  const client = useApolloClient();
  // if (loading) return null
  return (
    <div>

      <Tabs>
        <TabList className="nav nav-tabs tab-coupon" >
          {/* <Tab className="nav-link">Reservasi</Tab> */}
          <Tab className="nav-link">Data Reservasi</Tab>
          <Tab className="nav-link">Reservasi Konsultasi</Tab>
          <Tab className="nav-link">Reservasi Perawatan</Tab>
          <Tab className="nav-link">Reservasi Tindakan</Tab>
          <Tab className="nav-link">Template Jadwal</Tab>
          <Tab className="nav-link">Jadwal Libur </Tab>
        </TabList>
        {/* <TabPanel>
          <ServiceReservasi data={data} client={client} />
        </TabPanel> */}
        <TabPanel>
          <ServiceReservasi data={data} client={client} />
          {/* <JadwalDokter data={data} /> */}
        </TabPanel>
        <TabPanel>
          <JadwalDokter data={data} />

          {/* <Table data={data.konsultasi} columns={columns} /> */}
        </TabPanel>
        <TabPanel>
          <JadwalPerawatan data={data} />

          {/* <Table data={data.konsultasi} columns={columns} /> */}
        </TabPanel>
        <TabPanel>
          <JadwalTindakan data={data} />

          {/* <Table data={data.konsultasi} columns={columns} /> */}
        </TabPanel>
        <TabPanel>
          <TemplateJadwal data={data} />
          {/* <OnlineSlot data={data} /> */}
          {/* <Table data={data.konsultasi} columns={columns} /> */}
        </TabPanel>
        <TabPanel>
          <JadwalLibur data={data} />
          {/* <OnlineSlot data={data} /> */}
          {/* <Table data={data.konsultasi} columns={columns} /> */}
        </TabPanel>

      </Tabs>
    </div>
  )
}
export default withApollo(TabSet)


