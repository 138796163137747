import React, { Component, Fragment, useState } from 'react'
import Breadcrumb from '../common/breadcrumb';
//import data from '../../assets/data/listCoupons';
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { withApollo } from '../../apollo'
import { Collapse, ModalHeader } from 'reactstrap';
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import { GET_LIST_KONSULTASI, GET_DOKPOINTS, GET_PRODUCTS, SAVE_POINT_PRODUCTS, SAVE_POINT, GET_CATEGORIES } from '../../constants/graph';
import { Alert, Badge, FormGroup, Label, Input, Button, ListGroup, ListGroupItem } from 'reactstrap';
import Modal from 'react-responsive-modal';
import { Edit, Trash2, Plus, XCircle, X, Search } from 'react-feather'
import gql from 'graphql-tag';
const ListPoint = ({listProduct}) => {
    const { loading, data: dokPoint, error } = useQuery(GET_DOKPOINTS,);
    const { loading:lCategories, data:dCategories, error:eCategories } = useQuery(GET_CATEGORIES,);
    
    // const { loading: loadingProduct, data: listProduct, error: errorProduct } = useQuery(GET_PRODUCTS,);
    const [filterProduct, setfilterProduct] = useState("")
    const [selectedIndex, setsselectedIndex] = useState(0)
    const [expandEarned, setexpandEarned] = useState(false)
    const [expandResProd, setexpandResProd] = useState(false)
    const [expandresCat, setexpandresCat] = useState(false)
    const [expandRules, setexpandRules] = useState(false)
    const userGrade = ['', 'Classic', 'Silver', 'Gold', 'Platinum']
    const valueCheck = ['', 'ORDER::USEPOINT', 'ORDER::TOTAL', 'ORDERITEM::QUANTITY', 'ORDERITEM::SUBTOTAL']
    const [openModalProduct, setModalProduct] = useState(false)
    const [openModalCat, setModalCat] = useState(false)
    const [openModalAdd, setopenModalAdd] = useState(false)
    const [editedPoint, seteditedPoint] = useState(null)
    const [newPoint, setnewPoint] = useState(null)
    const client = useApolloClient()
    const getProduct = (idproduct) => client.readFragment({
        id: `Product:${idproduct}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment editProduct on Product {
            name,
            dokul_products_skus
            branchId,
            dokul_tags_categories,
            dokul_product_images
            id
            description
            dokul_product_descriptions
            tags
            is_restricted 
            nick_name
            video
          }
        `,
    }, false);

    const getCategories = (idcategori) => client.readFragment({
        id: `Category:${idcategori}`, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment getCategori on Category {
            id,
      name,
      description
          }
        `,
    }, false);

    const updateCachePoint = (cache, { data }) => {

        console.log(data)

        const _newListProduct = [...data.savePointProducts]
        let _point = { ...dokPoint.points[selectedIndex] }
        _point.dokul_point_products = _newListProduct
        const fragmentResult = cache.writeFragment({
            id: `Point:${_point.id}`,
            fragment: gql`
        fragment point on Point{
            id,
      name,
      status,
      multipier_by,
      earned_type,
      earned,
      dokul_point_rules,
      dokul_point_products
      dokul_point_categories
      dokul_point_grades
        }
      `,
            data: _point
        });
    };
    const updateCachePointCat = (cache, { data }) => {

        console.log(data)

        const _newListProduct = [...data.savePointProducts]
        let _point = { ...dokPoint.points[selectedIndex] }
        _point.dokul_point_categories = _newListProduct
        const fragmentResult = cache.writeFragment({
            id: `Point:${_point.id}`,
            fragment: gql`
        fragment point on Point{
            id,
      name,
      status,
      multipier_by,
      earned_type,
      earned,
      dokul_point_rules,
      dokul_point_products
      dokul_point_categories
      dokul_point_grades
        }
      `,
            data: _point
        });
    };
    const [savePointProducts] = useMutation(SAVE_POINT_PRODUCTS, { update: updateCachePoint });
    const [savePointCategories] = useMutation(SAVE_POINT_PRODUCTS, { update: updateCachePointCat });

    const [savePoint, { loading: mutationCategoryLoading, error: mutationCategoryError, called: mutationCategoryCalled },] = useMutation(SAVE_POINT,);

    if (loading ) return "loading"
    
    return (
        <Fragment>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Dokpoin <span className="float-end text-success bg-white p-2" style={{ cursor: "pointer" }} onClick={() => {
                                setnewPoint({status:2,create_by:0,modify_by:0,
                                    dokul_point_grades:[
                                        {grade_id:1,earned:0,},{grade_id:2,earned:0,},{grade_id:3,earned:0,},{grade_id:4,earned:0,}
                                    ]
                                })
                                setopenModalAdd(true)
                            }}
                            >ADD</span></li>
                            {/* <li className={`list-group-item ${selectedGudang == undefined ? "bg-primary" : ""}`} style={{ cursor: "pointer" }} onClick={() => { }}>Semua gudang</li> */}


                            {dokPoint.points.map((poin, idx) =>
                                <li key={idx} className={`list-group-item ${selectedIndex == idx ? "bg-dark" : ""}`} style={{ cursor: "pointer" }} onClick={() => {
                                    seteditedPoint(null)
                                    setsselectedIndex(idx)

                                }}>{poin.name}</li>

                            )}

                        </ul>

                    </div>
                    <div className="col-sm-8">
                        <ul className="list-group">
                            <li className={`list-group-item bg-primary w-100`}>Poin earned </li>

                            <li className={`list-group-item bg-white w-100`}>

                                <div className="form-group row mb-2">

                                    <label className="col-xl-3 col-md-4">Name</label>
                                    <div className="col-md-7">
                                        <input className="form-control" name="name" disabled={editedPoint == null ? true : false} id="name-point" type="text" value={dokPoint.points[selectedIndex].name} required="" onChange={(e) => {
                                            let _editedPoint = { ...editedPoint }
                                            _editedPoint.name = e.target.value
                                            seteditedPoint(_editedPoint)


                                        }} />
                                    </div>
                                </div>


                                <div className="form-group row mb-2 ">
                                    <label className="col-xl-3 col-md-4">Status</label>
                                    <div className="col-md-7">
                                        <div className=" checkbox-space">
                                            <label className="d-block">
                                                <input className="checkbox_animated" disabled={editedPoint == null ? true : false} id="chk-ani2" type="checkbox" checked={editedPoint == null ?dokPoint.points[selectedIndex].status == 1 ? true : false:editedPoint.status == 1 ? true : false} onChange={(e) => {
                                                    let _editedPoint = { ...editedPoint }
                                                    _editedPoint.status = e.target.checked ? 1 : 2
                                                    seteditedPoint(_editedPoint)

                                                }} />
                                                Enable the rule
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* <li className={`list-group-item  w-100`}
                                onClick={() => {
                                    setexpandEarned(!expandEarned)
                                }}>Poin Earned</li> */}
                            <li className={`list-group-item  w-100`}>
                                <Collapse isOpen={true}>
                                    <div className="tab-pane bg-white fade active show">
                                        <form className="needs-validation" noValidate="">

                                            <div className="row">
                                                <div className="col-sm-12">

                                                    <div className="form-group row mb-2">
                                                        <label className="col-xl-3 col-md-4">Basic earned</label>
                                                        <div className="col-md-7">
                                                            <input disabled={editedPoint == null ? true : false} className="form-control " name="earned_type" id="earned_type-point" type="text" value={editedPoint == null ?dokPoint.points[selectedIndex].earned:editedPoint.earned} required="" onChange={(e) => {
                                                                let _editedPoint = { ...editedPoint }
                                                                _editedPoint.earned = e.target.value
                                                                seteditedPoint(_editedPoint)

                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row align-items-start">
                                                        <label className="col-xl-3 col-md-4">Earned increase by grade</label>
                                                        <div className="col-md-7">

                                                            {[1, 2, 3, 4].map((grade,idx) => {
                                                                let currentreward = editedPoint == null ?dokPoint.points[selectedIndex].dokul_point_grades.find(rew => rew.grade_id == grade):editedPoint.dokul_point_grades.find(rew => rew.grade_id == grade)
                                                                const rewards = currentreward ? currentreward.earned : 0
                                                                return <div key={idx} className="form-group row mb-2">
                                                                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label"><span className={`p-2 bg-${grade == 1 ? 'classic' : grade == 2 ? 'silver' : grade == 3 ? 'gold' : 'platinum'} text-${grade == 1 ? 'light' : grade == 2 ? 'dark' : grade == 3 ? 'dark' : 'dark'} w-100 rounded`}>{userGrade[grade]}</span></label>
                                                                    <div className="col-sm-8">
                                                                        <input disabled={editedPoint == null ? true : false} type="number" className="form-control" value={rewards} onChange={(e) => {
                                                                            let _currentreward = { ...currentreward }
                                                                            let _editedPoint = { ...editedPoint }
                                                                            let listRewGrade = [..._editedPoint.dokul_point_grades]

                                                                            let _idx = listRewGrade.findIndex(rew => rew.grade_id == grade)
                                                                            _currentreward.earned = e.target.value
                                                                            listRewGrade[_idx] = _currentreward
                                                                            _editedPoint.dokul_point_grades = listRewGrade
                                                                            seteditedPoint(_editedPoint)


                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            }
                                                            )}
                                                        </div>
                                                    </div>
                                                    <button type="button" className={`btn float-end mt-2 ${editedPoint == null ? "btn-light" : "btn-primary "}`} onClick={() => {
                                                        if (editedPoint == null)
                                                            seteditedPoint(dokPoint.points[selectedIndex])
                                                        else {
                                                            savePoint({
                                                                variables: {
                                                                    data: editedPoint
                                                                }
                                                            }).then(res => seteditedPoint(null))

                                                        }
                                                    }}>{editedPoint == null ? "Edit" : "Save"}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Collapse>
                            </li>

                            <li className={`list-group-item  w-100`}
                                onClick={() => {
                                    setexpandResProd(!expandResProd)
                                }}>Product restriction</li>
                            <li className={`list-group-item  w-100`}>
                                <Collapse isOpen={expandResProd}>
                                    <div className="tab-pane card fade active show">
                                        <form className="needs-validation" noValidate="">
                                            <div className="form-group row">

                                                <div className="col-xl-12 col-sm-12">
                                                    <ListGroup>
                                                        {dokPoint.points[selectedIndex].dokul_point_products.map((product,idxp) => {
                                                            let _prod = getProduct(product.product_id)
                                                            return <ListGroupItem key={idxp} className="justify-content-between" ><img className="img-fluid blur-up bg-img lazyloaded w-25" src={`${process.env.REACT_APP_IMAGE_HOST}${_prod.dokul_product_images[0]?.image_url}`} /><span style={{ verticalAlign: "middle" }} className="ml-3"> {_prod.name} </span></ListGroupItem>

                                                            //     <Button close aria-label="Cancel"> 
                                                            //     <span aria-hidden>&ndash; {product.name}</span>
                                                            //   </Button>
                                                        })}
                                                        <ListGroupItem className="justify-content-between" ><button type="button" className="btn btn-primary" onClick={(e) => { setModalProduct(true) }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">EDIT </button></ListGroupItem>
                                                    </ListGroup>

                                                </div>

                                            </div>

                                        </form>

                                    </div>

                                </Collapse>
                            </li>

                            <li className={`list-group-item  w-100`}
                                onClick={() => {
                                    setexpandresCat(!expandresCat)
                                }}>Category restriction</li>
                            <li className={`list-group-item  w-100`}>
                                <Collapse isOpen={expandresCat}>
                                    <div className="tab-pane card fade active show">
                                        <form className="needs-validation" noValidate="">
                                            <div className="form-group row">

                                                <div className="col-xl-8 col-sm-7">
                                                    <ListGroup>
                                                        {dokPoint.points[selectedIndex].dokul_point_categories.map(cat => {
                                                            let _categori = getCategories(cat.category_id)
                                                            return <ListGroupItem className="justify-content-between" ><span style={{ verticalAlign: "middle" }}> {_categori?.name} </span></ListGroupItem>

                                                            //     <Button close aria-label="Cancel">
                                                            //     <span aria-hidden>&ndash; {product.name}</span>
                                                            //   </Button>
                                                        })}
                                                        <ListGroupItem className="justify-content-between" ><button type="button" className="btn btn-primary" onClick={(e) => { setModalCat(true) }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">EDIT </button></ListGroupItem>
                                                    </ListGroup>

                                                </div>

                                            </div>

                                        </form>

                                    </div>

                                </Collapse>
                            </li>

                            <li className={`list-group-item  w-100`}
                                onClick={() => {
                                    setexpandRules(!expandRules)
                                }}>Point rules</li>
                            <li className={`list-group-item  w-100`}>
                                <Collapse isOpen={expandRules}>
                                    <div className="tab-pane card fade active show">
                                        <form className="needs-validation" noValidate="">
                                            <div className="form-group row">

                                                <div className="col-xl-8 col-sm-7">
                                                    <ListGroup>
                                                        {dokPoint.points[selectedIndex].dokul_point_rules.map(rule => {
                                                            return <ListGroupItem className="justify-content-between" ><i className='fa fa-close' style={{ cursor: 'pointer', }}></i><span style={{ verticalAlign: "middle" }}> {valueCheck[rule.rule_code]}  {rule.rule_math} {rule.rule_value}</span></ListGroupItem>

                                                            //     <Button close aria-label="Cancel">
                                                            //     <span aria-hidden>&ndash; {product.name}</span>
                                                            //   </Button>
                                                        })}
                                                        <ListGroupItem className="justify-content-between" ><button type="button" className="btn btn-primary" onClick={(e) => { }} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Add </button></ListGroupItem>
                                                    </ListGroup>

                                                </div>

                                            </div>

                                        </form>

                                    </div>

                                </Collapse>
                            </li>
                        </ul>

                    </div>
                    {/* <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Dokpoin rules</h5>
                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="category-table order-table coupon-list-delete">
                                        <ReactTable
                                            data={myData}
                                            columns={columns}
                                            defaultPageSize={pageSize}
                                            className={myClass}
                                            showPagination={pagination}
                                        />
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
            <Modal open={openModalProduct} onClose={() => setModalProduct(false)} >
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">{'Add product to point'}</h5>
                </div>
                <div className="modal-body">
                    <div className="row">


                        <div className="col-sm-12 ">

                            <div className="form-group">

                                <input className={"form-control-plaintext "} type="search" placeholder="Search.." onChange={(e) => {
                                    setfilterProduct(e.target.value)

                                }} />
                                <span className="d-sm-none mobile-search"><Search /></span>
                            </div>
                            {listProduct.filter(p => p.name.toLowerCase().includes(filterProduct)).map((p, i) =>
                                // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                <FormGroup check>
                                <Input key={i} type="checkbox" id={`prd ${i}`} label={p.name} checked={dokPoint.points[selectedIndex].dokul_point_products.some(ct1 => ct1.product_id == p.id)} value={p} onChange={(e) => {
                                    let curPoint = { ...dokPoint.points[selectedIndex] }
                                    let selecttedProducts = [...curPoint.dokul_point_products]

                                    if (e.target.checked) {
                                        let poinPro = {
                                            product_id: p.id,
                                            status: 1,
                                            point_id: curPoint.id
                                        }
                                        selecttedProducts.push(poinPro)
                                    }
                                    else {
                                        const index = selecttedProducts.findIndex(pr => pr.product_id == p.id)
                                        selecttedProducts.splice(index, 1)
                                    }
                                    curPoint.dokul_point_products = selecttedProducts
                                    savePointProducts({
                                        variables: { type: curPoint }
                                    })
                                    // this.setState({
                                    //     point: curPoint

                                    // })

                                }} />
                                <Label>{p.name}</Label>
                                </FormGroup>

                            )}

                        </div>

                    </div>
                </div>
                <div className="modal-footer">
                    {/* <button type="button" className="btn btn-primary" onClick={() => this.addRules()}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button> */}
                </div>
            </Modal>

            <Modal open={openModalCat} onClose={() => setModalCat(false)} >
                <ModalHeader>
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">{'Add category to point'}</h5>
                    </ModalHeader>
                <div className="modal-body">
                    <div className="row">


                        <div className="col-sm-12 ">

                            {/* <div className="form-group">

                                <input className={"form-control-plaintext "} type="search" placeholder="Search.." onChange={(e) => {
                                    setfilterProduct(e.target.value)

                                }} />
                                <span className="d-sm-none mobile-search"><Search /></span>
                            </div> */}
                            {dCategories?.categories.map((p, i) =>
                                // <Badge key={i} color="light">{kategori.description} <i className="fa fa-close"></i></Badge>
                                <FormGroup
    check
  >
                                <Input key={i} type="checkbox" id={`prd ${i}`} label={p.name} checked={dokPoint.points[selectedIndex].dokul_point_categories.some(ct1 => ct1.category_id == p.id)} value={p} onChange={(e) => {
                                    let curPoint = { ...dokPoint.points[selectedIndex] }
                                    let selecttedProducts = [...curPoint.dokul_point_categories]

                                    if (e.target.checked) {
                                        let poinPro = {
                                            category_id: p.id,
                                            status: 1,
                                            point_id: curPoint.id
                                        }
                                        selecttedProducts.push(poinPro)
                                    }
                                    else {
                                        const index = selecttedProducts.findIndex(pr => pr.category_id == p.id)
                                        selecttedProducts.splice(index, 1)
                                    }
                                    curPoint.dokul_point_categories = selecttedProducts
                                    curPoint.subject=2
                                    // console.log(curPoint)

                                    // return
                                    savePointCategories({
                                        variables: { type: curPoint }
                                    })
                                    // this.setState({
                                    //     point: curPoint

                                    // })

                                }} />
                                <Label>{p.name}</Label>
                                </FormGroup>

                            )}

                        </div>

                    </div>
                </div>
                <div className="modal-footer">
                    {/* <button type="button" className="btn btn-primary" onClick={() => this.addRules()}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Close</button> */}
                </div>
            </Modal>

            <Modal open={openModalAdd} onClose={() => setopenModalAdd(false)} >
                <div className="modal-header">
                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">{'Add new dokpoin'}</h5>
                </div>
                {newPoint==null?"":<div className="modal-body">
                    <div className="row">


                        <div className="col-sm-12 ">

                            <ul className="list-group">
                                <li className={`list-group-item bg-primary w-100`}>Poin earned </li>

                                <li className={`list-group-item bg-white w-100`}>

                                    <div className="form-group row mb-2">

                                        <label className="col-xl-3 col-md-4">Name</label>
                                        <div className="col-md-7">
                                            <input className="form-control" name="name"  id="name-point" type="text" defaultValue={""} required="" onChange={(e) => {
                                                let _editedPoint = { ...newPoint }
                                                _editedPoint.name = e.target.value
                                                setnewPoint(_editedPoint)


                                            }} />
                                        </div>
                                    </div>


                                    <div className="form-group row mb-2 ">
                                        <label className="col-xl-3 col-md-4">Status</label>
                                        <div className="col-md-7">
                                            <div className=" checkbox-space">
                                                <label className="d-block">
                                                    <input className="checkbox_animated" id="chk-ani2" type="checkbox" defaultChecked={ false} onChange={(e) => {
                                                        let _editedPoint = { ...newPoint }
                                                        _editedPoint.status = e.target.checked ? 1 : 2
                                                        setnewPoint(_editedPoint)

                                                    }} />
                                                    Enable the rule
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className={`list-group-item  w-100`}>
                                    <Collapse isOpen={true}>
                                        <div className="tab-pane bg-white fade active show">
                                            <form className="needs-validation" noValidate="">

                                                <div className="row">
                                                    <div className="col-sm-12">

                                                        <div className="form-group row mb-2">
                                                            <label className="col-xl-3 col-md-4">Basic earned</label>
                                                            <div className="col-md-7">
                                                                <input d className="form-control " name="earned_type" id="earned_type-point" type="text" defaultValue={""} required="" onChange={(e) => {
                                                                    let _editedPoint = { ...newPoint }
                                                                    _editedPoint.earned = e.target.value
                                                                    setnewPoint(_editedPoint)

                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row align-items-start">
                                                            <label className="col-xl-3 col-md-4">Earned increase by grade</label>
                                                            <div className="col-md-7">

                                                                {[1, 2, 3, 4].map(grade => {
                                                                     let currentreward = newPoint.dokul_point_grades.find(rew => rew.grade_id == grade)
                                                                     const rewards = currentreward ? currentreward.earned : 0
                                                                    return <div className="form-group row mb-2">
                                                                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label"><span className={`p-2 bg-${grade == 1 ? 'classic' : grade == 2 ? 'silver' : grade == 3 ? 'gold' : 'platinum'} text-${grade == 1 ? 'light' : grade == 2 ? 'dark' : grade == 3 ? 'dark' : 'dark'} w-100 rounded`}>{userGrade[grade]}</span></label>
                                                                        <div className="col-sm-8">
                                                                            <input  type="number" min="1" className="form-control" defaultValue={0} onChange={(e) => {
                                                                                 let _currentreward = { ...currentreward }
                                                                                let _editedPoint = { ...newPoint }
                                                                                let listRewGrade = [..._editedPoint.dokul_point_grades]

                                                                                let _idx = listRewGrade.findIndex(rew => rew.grade_id == grade)
                                                                                _currentreward.earned = e.target.value
                                                                                listRewGrade[_idx] = _currentreward
                                                                                _editedPoint.dokul_point_grades = listRewGrade
                                                                                seteditedPoint(_editedPoint)


                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Collapse>
                                </li>

                            </ul>

                        </div>

                    </div>
                </div>}
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => {
                        savePoint({
                            variables: {
                                data: newPoint
                            }
                        }).then(res => setopenModalAdd(false))
                     }}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setopenModalAdd(false)}>Cancel</button>
                </div>
            </Modal>

        </Fragment>
    )
}
// }
export default withApollo(ListPoint)
