import moment from 'moment';
import React, { Component, Fragment } from 'react'
import { Alert, Badge, Col, FormGroup, Input, Label, List, ListGroup, ListGroupItem, ListGroupItemHeading, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import CreateProductKhusus from '../konsultasi/kontrol/create.produk'
import designer from '../../assets/images/dashboard/designer.jpg';
import Tabset_profile from './tabset-user';
const vouchersStatus = ['', 'Kosong', 'Dimiliki', "Terpakai", "Expired"]
export class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = { modalProdukKhusus: false, suksesshow: false, userProducts: props.dUserDetail.dokul_user_products, dUserDetail: props.dUserDetail, edited: false, newPassword: "", changePassword: false, modalAddVoucher: false, vouchers: [], modalAddProduk: false, produkSearch: "", deletedVouchers: [] };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateRole = this.updateRole.bind(this);
    }
    handleChange(event) {
        const { name, value } = event.target
        let dtUser = { ...this.state.dUserDetail }
        dtUser[name] = value
        this.setState({ dUserDetail: dtUser });
    }
    componentDidUpdate(prevProps) {
        // console.log(this.props.dProducts.products)
        // console.log(this.props.dUserDetail)

        // console.log(prevProps.dUserDetail)
        if (this.props.dUserDetail.dokul_user_roles.length != prevProps.dUserDetail.dokul_user_roles.length) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.setState({ dUserDetail: this.props.dUserDetail })
        }
    }


    async handleSubmit() {
        let newData = { ...this.state.dUserDetail }
        delete newData.dokul_carts
        delete newData.dokul_orders
        delete newData.dokul_user_role

        delete newData.dokul_user_products
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/adminCreate`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(newData) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        // event.preventDefault();
        // setLoading(false)
        // setloadingupdate(false)
        // let result = await response.json()
        if (response.ok) {
            this.setState({ changePassword: false, suksesshow: true })

        }
        else
            this.setState({ submitError: true })


    }
    async handleSubmitNewPassword() {
        let newData = { user_id: this.state.dUserDetail.id, newpass: this.state.newPassword }
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/setPasswordByAdmin`, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(newData) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        if (response.ok)
            this.props.closeModal()
        else
            this.setState({ submitError: true })


    }
    async handleSubmitNewVoucher(data) {

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/voucher/assign`, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        if (response.ok)
            this.props.closeModal()
        else
            this.setState({ submitError: true })



    }
    async handleDeleteVoucher(data) {

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/voucher/delete`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).catch(err => {
            // return { ok: "" }
        });
        if (response.ok) {
            this.setState({ deletedVouchers: [...this.state.deletedVouchers, data.id] })
        } else
            this.setState({ submitError: true })



    }
    async handleAddProduct(data, isDelete = false) {

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/products/produkUser`, {
            method: isDelete ? 'PUT' : 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        if (response.ok)
            console.log("OK")
        else
            this.setState({ submitError: true })


    }

    async updateEmailValid(userid, isvalid) {

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/setEmailValid`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ user_id: userid, isvalid: isvalid }) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        if (response.ok)
            console.log("OK")
        else
            this.setState({ submitError: true })


    }

    async submitData() {
        // Default options are marked with *
        // setLoading(true)

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/adminCreate`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).catch(err => {
            return { ok: "" }
        });
        // setLoading(false)
        // setloadingupdate(false)
        // let result = await response.json()
        if (response.ok)
            return response.json(); // parses JSON response into native JavaScript objects
        else
            return false
    }
    generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.,@_=()%$#@!",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        this.setState({ newPassword: retVal });
    }

    updateRole() {
        this.props.setselectedUser({ dokul_user: this.state.dUserDetail })
        this.props.setModalOpenChangeRole(true)
    }
    isExpired(dateExpired) {

        return moment().diff(moment(dateExpired), 'days') > 0
    }
    render() {
        const { deletedVouchers, userProducts, dUserDetail, suksesshow, submitError, edited, newPassword, modalProdukKhusus, changePassword, modalAddVoucher, vouchers, modalAddProduk, produkSearch } = this.state
        const { isNew, setselectedUser, setModalOpenChangeRole, dPrivateVoucher, dProducts } = this.props
        const listMasalah = [
            "komedo",
            "Jerawat milia/kecil/beruntus",
            "Jerawat besar/bernanah/meradang",
            "Flek hitam",
            "Flek agak abu²",
            "Flek hitam merah",
            "Bekas jerawat merah",
            "Bekas jerawat hitam/gelap",
            "Bekas jerawat bopeng",
            "Wajah mudah merah",
            "Kulit kendur/kerutan"
        ]
        const jenisKulit = ["Kering", "Normal", "Berminyak", "Sensitif"]
        const warnaKulit = ["Cokelat", "Hitam", "Putih", "Sawo Matang"]
        const jenisKelamin = ["Pria", "Wanita"]
        const status = ["Single",
            "Single akan menikah dalam waktu dekat",
            "Menikah, tidak menggunakan kontrasepsi",
            "Menikah, dan sudah kontrasepsi kurang dari 3 tahun",
            "Menikah, dan sudah kontrasepsi lebih dari 3 tahun",
            " Menikah, dan berencana hamil dalam waktu dekat",
            " Menikah dan menyusui"]

        return (
            <Fragment>
                <Modal size='xl' isOpen={modalProdukKhusus} toggle={() => this.setState({ modalProdukKhusus: false })} centered backdrop="static">
                    <ModalHeader toggle={() => this.setState({ modalProdukKhusus: false })} className='bg-primary'>Tambah Produk Khusus</ModalHeader>

                    <ModalBody className='bg-light text-dark'>
                        <CreateProductKhusus privateUser={dUserDetail} selectedKontrol={{ ss: "" }} handleCloseModal={(d) => console.log(d)} />
                    </ModalBody>
                    <ModalFooter className='bg-light'>
                        {submitError && <Alert color='danger'>Simpan produk gagal, silahkan coba kembali</Alert>}
                    </ModalFooter>
                </Modal>
                <Modal size='sm' isOpen={changePassword} toggle={() => this.setState({ changePassword: false })} centered backdrop="static">
                    <ModalHeader toggle={() => this.setState({ changePassword: false })} className='bg-primary'>Ganti password</ModalHeader>
                    <ModalBody className='bg-light'>
                        <FormGroup>

                            <Input type="text" name="newPass" id="newPass" placeholder="password baru" value={newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
                        </FormGroup>
                        {/* <input type='text' value={newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} /> */}
                    </ModalBody>

                    <ModalFooter className='bg-light'>
                        {submitError && <Alert color='danger'>Simpan password gagal, silahkan coba kembali</Alert>}
                        <button disabled={newPassword == ""} className='btn btn-primary btn-sm m-2' onClick={() => this.handleSubmitNewPassword()}>Save</button>
                        <button className='btn btn-success btn-sm m-2' onClick={() => this.generatePassword()}>Generate</button>
                    </ModalFooter>
                </Modal>
                <Modal size='md' isOpen={modalAddVoucher} toggle={() => this.setState({ modalAddVoucher: false })} centered backdrop="static" scrollable>
                    <ModalHeader toggle={() => this.setState({ modalAddVoucher: false })} className='bg-primary'>Tambah voucher</ModalHeader>
                    <ModalBody className='bg-light'>
                        <ListGroup>
                            {dPrivateVoucher && dPrivateVoucher.voucherPrivate.data.map((x, idx) =>
                                <ListGroupItem key={idx}>
                                    <div className='d-flex flex-row justify-align-between'>
                                        <div className='p-2'><input type='checkbox' onChange={(e) => {
                                            if (e.target.checked) {
                                                const _vcrAdd = { ...x, user_id: dUserDetail.id, status: 2 }
                                                this.setState({ vouchers: [...vouchers, _vcrAdd] })
                                            } else {
                                                let _vcr = [...vouchers]
                                                let _idx = _vcr.findIndex(_idxvcr => _idxvcr.id == x.id)
                                                _vcr.splice(_idx, 1)
                                                this.setState({ vouchers: _vcr })
                                            }
                                        }} /> </div>
                                        <div className='p-2'>{x.code} <span className={`${x.branch_id == 1 ? "text-success" : "text-warning"}`}>({x.branch_id == 1 ? "JKT" : "BPN"})</span></div>
                                        <div className='p-2'><span className={`${x.branch_id == 1 ? "text-success" : "text-warning"}`}>({x.active_days} hari)</span></div>
                                        <div className='p-2 ms-auto'>{x.amount.toLocaleString("ID-id", { style: "currency", currency: "IDR" })}</div>

                                    </div>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </ModalBody>

                    <ModalFooter className='bg-light'>
                        {submitError && <Alert color='danger'>Tambah voucher gagal, silahkan coba kembali</Alert>}
                        <button className='btn btn-primary btn-sm m-2' onClick={() => this.handleSubmitNewVoucher(vouchers)}>Submit</button>
                        <button className='btn btn-light btn-sm m-2' onClick={() => this.setState({ modalAddVoucher: false })}>Cancel</button>
                    </ModalFooter>
                </Modal>

                <Modal size='xl' isOpen={modalAddProduk} toggle={() => this.setState({ modalAddProduk: false })} centered backdrop="static" scrollable>
                    <ModalHeader toggle={() => this.setState({ modalAddProduk: false })} className='bg-primary'>Tambah akses produk</ModalHeader>
                    <ModalBody className='bg-light'>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}><Input type='search' placeholder='Cari produk' onChange={(e) => this.setState({ produkSearch: e.target.value })}></Input></Col>
                            <Col md={6}>
                                <ListGroup>
                                    <ListGroupItemHeading className='bg-warning  p-2 mt-2' >Produk Jakarta</ListGroupItemHeading>
                                    {dProducts.filter(x => x.branch_id == 1 && x.name.toLowerCase().includes(produkSearch.toLowerCase())).map((x, idx) =>
                                        <ListGroupItem key={idx}>
                                            <div className='d-flex flex-row justify-align-between'>
                                                <div className='p-2'>
                                                    <input type='checkbox' defaultChecked={userProducts.some(cp => cp.product_id == x.id)} onChange={(e) => {

                                                        if (e.target.checked) {
                                                            const _prdAdd = { user_id: dUserDetail.id, product_id: x.id, is_approved: 1, is_publish: 1 }
                                                            this.handleAddProduct(_prdAdd, false)
                                                            this.setState({ userProducts: [...userProducts, _prdAdd] })

                                                        } else {
                                                            let _vcr = [...userProducts]
                                                            let _idxall = _vcr.filter(_idxvcr => _idxvcr.product_id == x.id).map(ridx => ridx.id)
                                                            _idxall.forEach(dp => {
                                                                const _idx = _vcr.findIndex(dpx => dpx.id == dp)
                                                                _vcr.splice(_idx, 1)
                                                            })

                                                            const _prdDel = { user_id: dUserDetail.id, product_id: x.id }

                                                            this.handleAddProduct(_prdDel, true)
                                                            this.setState({ userProducts: _vcr })
                                                        }
                                                    }} />
                                                </div>
                                                <div className='p-2'>{x.name}</div>
                                                {/* <div className='p-2 ms-auto'>{x.branch_id==1?"Ja"}</div> */}

                                            </div>
                                        </ListGroupItem>
                                    )}
                                </ListGroup>
                            </Col>
                            <Col md={6}>
                                <ListGroup>
                                    <ListGroupItemHeading className='bg-success p-2 mt-2' >Produk Balikpapan</ListGroupItemHeading>
                                    {dProducts.filter(x => x.branch_id == 2 && x.name.toLowerCase().includes(produkSearch.toLowerCase())).map((x, idx) =>
                                        <ListGroupItem key={idx}>
                                            <div className='d-flex flex-row justify-align-between'>
                                                <div className='p-2'>
                                                    <input type='checkbox' checked={userProducts.some(cp => cp.product_id == x.id)} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            const _prdAdd = { user_id: dUserDetail.id, product_id: x.id, is_approved: 1, is_publish: 1 }
                                                            this.handleAddProduct(_prdAdd, false)
                                                            this.setState({ userProducts: [...userProducts, _prdAdd] })

                                                        } else {
                                                            let _vcr = [...userProducts]
                                                            let _idxall = _vcr.filter(_idxvcr => _idxvcr.product_id == x.id).map(ridx => ridx.id)
                                                            _idxall.forEach(dp => {
                                                                const _idx = _vcr.findIndex(dpx => dpx.id == dp)
                                                                _vcr.splice(_idx, 1)
                                                            })


                                                            const _prdDel = { user_id: dUserDetail.id, product_id: x.id }

                                                            this.handleAddProduct(_prdDel, true)
                                                            this.setState({ userProducts: _vcr })
                                                        }
                                                    }} />
                                                </div>
                                                <div className='p-2'>{x.name}</div>
                                                {/* <div className='p-2 ms-auto'>{x.branch_id}</div> */}

                                            </div>
                                        </ListGroupItem>
                                    )}
                                </ListGroup>
                            </Col>
                        </Row>

                    </ModalBody>

                    <ModalFooter className='bg-light'></ModalFooter>
                </Modal>

                <div className="container-fluid">
                    {submitError && <Alert color="danger">Simpan data gagal</Alert>}
                    {suksesshow && <Alert color="success">Simpan data berhasil</Alert>}
                    {/* <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="profile-details text-center">
                                    <img src={designer} alt="" className="img-fluid img-90 rounded-circle blur-up lazyloaded" />
                                    <h5 className="f-w-600 f-16 mb-0">John deo</h5>
                                    <span>johndeo@gmail.com</span>
                                    <div className="social">
                                        <div className="form-group btn-showcase">
                                            <button className="btn social-btn btn-fb d-inline-block"> <i className="fa fa-facebook"></i></button>
                                            <button className="btn social-btn btn-twitter d-inline-block"><i className="fa fa-google"></i></button>
                                            <button className="btn social-btn btn-google d-inline-block mr-0"><i className="fa fa-twitter"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="project-status">
                                    <h5 className="f-w-600 f-16">Employee Status</h5>
                                    <div className="media">
                                        <div className="media-body">
                                            <h6>Performance <span className="pull-right">80%</span></h6>
                                            <div className="progress sm-progress-bar">
                                                <div className="progress-bar bg-primary" role="progressbar" style={{width: '90%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <h6>Overtime <span className="pull-right">60%</span></h6>
                                            <div className="progress sm-progress-bar">
                                                <div className="progress-bar bg-secondary" role="progressbar" style={{width: '60%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <h6>Leaves taken <span className="pull-right">50%</span></h6>
                                            <div className="progress sm-progress-bar">
                                                <div className="progress-bar bg-danger" role="progressbar" style={{width: '50%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card profile-card">
                            <div className="card-body">
                              
                            </div>
                        </div>
                    </div>
                </div> */}
                    <div className="container rounded bg-white mt-1 mb-5 text-dark">
                        <div className="row">
                            <div className="col-md-4 border-right">
                                <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                                    <img className="rounded-circle mt-5" width="150px" style={{ minHeight: 150 }} src={`${process.env.REACT_APP_IMAGE_HOST}/${dUserDetail.image_url}`} />
                                    <span className="font-weight-bold">{dUserDetail.first_name} {dUserDetail.last_name}</span>
                                    <span className="text-black-50">{dUserDetail.email} </span>

                                </div>
                                {/* <div className="d-flex flex-column align-items-start text-left p-3 py-1">
                                    
                                    <div className='border-bottom w-100 mb-3'><h4 className=''>Roles <button className='btn-success btn-xs float-right bg-main'>Add</button></h4></div>
                                    {dUserDetail.dokul_user_roles.map((x,idx)=><List key={idx}>{x.dokul_user_role.role_name}</List>)}
                                </div> */}
                                <div className="p-3 py-3">
                                    {dUserDetail.id ? <div className="d-flex justify-content-between  experience"><span>Roles</span><span className="border px-3 p-1 add-experience" onClick={() => {
                                        this.updateRole()
                                    }}><i className="fa fa-plus"></i>&nbsp;Edit</span></div> :
                                        <div className="d-flex justify-content-between  experience"><span>Roles</span></div>}
                                    <hr />
                                    {dUserDetail.dokul_user_roles.map((x, idx) =>
                                        <div key={idx} className="d-flex  flex-row justify-content-between border-bottom mb-1">

                                            <small className='p-2'>{x.dokul_user_role?.role_name}</small>
                                            {/* <small className='p-2'><Badge color='danger'>hapus</Badge></small> */}
                                        </div>
                                    )}

                                </div>
                            </div>

                            <div className="col-md-8 border-right container">
                                <div className="p-3 py-5">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4 className="text-right">Profile #{dUserDetail.id} </h4>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <label className="labels">Nama depan</label>
                                            {(edited || isNew) ? <input type="text" className="form-control" placeholder="nama depan" value={dUserDetail.first_name} name={"first_name"} onChange={this.handleChange} />
                                                : <input type="text" value={dUserDetail.first_name} className="form-control bg-grey" readOnly={!edited} />}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="labels">Nama belakang</label>
                                            {(edited || isNew) ? <input type="text" className="form-control" value={dUserDetail.last_name} placeholder="nama belakang" name={"last_name"} onChange={this.handleChange} />

                                                : <input type="text" value={dUserDetail.last_name} className="form-control bg-grey" readOnly={!edited} />}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6 py-2">
                                            <div><label className="labels">Email </label><span className='float-end'>Valid :  <input type={'checkbox'} defaultChecked={dUserDetail.is_validemail} onChange={() => this.updateEmailValid(dUserDetail.id, dUserDetail.is_validemail ? 0 : 1)}></input></span></div>
                                            {(isNew) ? <input type="text" className="form-control" placeholder="email" value={dUserDetail.email} name={"email"} onChange={this.handleChange} />
                                                : <input type="text" readOnly className="form-control" placeholder="email" value={dUserDetail.email} name={"email"} />}

                                        </div>
                                        {!dUserDetail.id && <div className="col-md-12 py-2"><label className="labels">Password</label><input type="password" className="form-control" placeholder="password" value={dUserDetail.password} name={"password"} onChange={this.handleChange} /></div>}

                                        <div className="col-md-6 py-2">
                                            <label className="labels">Phone</label>
                                            {(edited || isNew) ? <input type="text" className="form-control" placeholder="phone" value={dUserDetail.phone} name={"phone"} onChange={this.handleChange} />
                                                : <input type="text" value={dUserDetail.phone} className="form-control bg-grey" readOnly={!edited} />}
                                        </div>
                                        <div className="col-md-6 py-2"><label className="labels">Jenis kelamin</label>
                                            {(edited || isNew) && <select type="text" className="form-control" placeholder="Jenis kelamin" name="gender" value={dUserDetail.gender} onChange={this.handleChange}>
                                                {jenisKelamin.map((x, idx) => <option key={idx} value={x} defaultChecked={x.includes(dUserDetail.gender)}>{x}</option>)}
                                            </select>}
                                            {(!edited && !isNew) && <input type="text" value={dUserDetail.gender} className="form-control bg-grey" readOnly={!edited} />}
                                        </div>
                                        <div className="col-md-6 py-2">
                                            <label className="labels">Tanggal lahir</label>
                                            {(edited || isNew) ? <input type="date" className="form-control" placeholder="Tanggal lahir" name={"tgl_lahir"} value={dUserDetail.tgl_lahir} onChange={this.handleChange} />
                                                : <input type="text" value={dUserDetail.tgl_lahir} className="form-control bg-grey" readOnly={!edited} />}
                                        </div>
                                        <div className="col-md-6 py-2">
                                            <label className="labels">Status perkawinan</label>
                                            {(edited || isNew) ? <select type="text" className="form-control" placeholder="status perkawinan" name={"status_kawin"} value={dUserDetail.status_kawin} onChange={this.handleChange} >
                                                {status.map((x, idx) => <option key={idx}>{x}</option>)}
                                            </select> : <input type="text" value={dUserDetail.status_kawin} className="form-control bg-grey" readOnly={!edited} />}
                                        </div>

                                        <div className="col-md-6 py-2">
                                            <label className="labels">Jenis kulit</label>
                                            {(edited || isNew) ? <select type="text" className="form-control" placeholder="jenis kulit" name={"jenis_kulit"} value={dUserDetail.jenis_kulit} onChange={this.handleChange} >
                                                {jenisKulit.map(x => <option>{x}</option>)}
                                            </select> : <input type="text" value={dUserDetail.jenis_kulit} className="form-control bg-grey" readOnly={!edited} />}
                                        </div>
                                        <div className="col-md-6 py-2"><label className="labels">Masalah utama</label>
                                            {(edited || isNew) ? <select type="text" className="form-control" placeholder="masalah utama" name={"masalah_utama"} value={dUserDetail.masalah_utama} onChange={this.handleChange} >
                                                {listMasalah.map(x => <option>{x}</option>)}
                                            </select> : <input type="text" value={dUserDetail.masalah_utama} className="form-control bg-grey" readOnly={!edited} />}
                                        </div>
                                        <div className="col-md-6 py-2"><label className="labels">Masalah lain</label>
                                            {dUserDetail.masalah_lain ? dUserDetail.masalah_lain.split(",").map((x, idx) => <input key={idx} type="text" readOnly={!edited} className="form-control" placeholder="enter address line 2" value={listMasalah[x]} />)
                                                : <input type="text" value="" className="form-control bg-grey" readOnly={!edited} />}

                                        </div>
                                        {/* <div className="col-md-12"><label className="labels">Education</label><input type="text" className="form-control" placeholder="education" value="" /></div> */}
                                    </div>
                                    {/* <div className="row mt-3">
                                <div className="col-md-6"><label className="labels">Country</label><input type="text" className="form-control" placeholder="country" value="" /></div>
                                <div className="col-md-6"><label className="labels">State/Region</label><input type="text" className="form-control" value="" placeholder="state" /></div>
                            </div> */}
                                    <div className='d-flex flex-row border justify-content-between '>
                                        {(!edited && !isNew) && <div className="m-2 text-center mr-auto"><button className="btn btn-primary profile-button" type="button" onClick={() => this.setState({ changePassword: true })}>Ganti password</button></div>}
                                        {(edited || isNew) && <div className="m-2 "><button className="btn btn-primary profile-button" type="button" onClick={this.handleSubmit}>Simpan</button></div>}
                                        {(!edited && !isNew) && <div className="m-2 text-center"><button className="btn btn-primary profile-button" type="button" onClick={() => this.setState({ edited: true })}>Edit</button></div>}
                                        {edited && <div className="m-2 text-center"><button className="btn btn-danger profile-button" type="button" onClick={() => this.setState({ edited: false })}>Cancel</button></div>}
                                    </div>
                                </div>
                            </div>

                        </div>
                        {dUserDetail.id && <div className="row">
                            <div className="col-md-6">
                                <div className="p-3 py-3">
                                    <div className="d-flex justify-content-between align-items-center experience bg-info p-2 "><span className='h4'>Alamat</span></div><br />
                                    {dUserDetail.dokul_addresses.length > 0 ? dUserDetail.dokul_addresses.map((x, idx) =>
                                        <div key={idx} className="d-flex   bd-highlight border-bottom mb-1">
                                            <small className="p-2">{x.first_name} ({x.phone})</small>

                                            <small className="p-2">{x.address_state}</small>
                                            <small className="ms-auto p-2">{x.address_city}</small>
                                        </div>
                                    ) : <div className="d-flex justify-content-center align-items-center border bg-light p-2 text-dark"><h5 className="text-center text-grey ">Alamat Kosong</h5></div>}

                                </div>


                            </div>
                            <div className="col-md-6">
                                <div className="p-3 py-3">
                                    <div className="d-flex justify-content-between align-items-center experience bg-info p-2 "><span className='h4'>Riwayat pesanan</span><span className="border px-3 p-1 add-experience"><i className="fa fa-plus"></i>&nbsp;Tambah</span></div><br />
                                    {dUserDetail.dokul_orders.length > 0 ? dUserDetail.dokul_orders.map((x, idx) =>
                                        <div key={idx} className="d-flex   bd-highlight border-bottom mb-1">
                                            <small className="p-2">{moment(x.created_date).format("DD, MM 'YY")}</small>

                                            <small className="p-2">{x.order_status_text}</small>
                                            <small className="ms-auto p-2">{x.grand_total.toLocaleString("ID-id", { style: "currency", currency: "IDR" })}</small>
                                        </div>
                                    ) : <div className="d-flex justify-content-center align-items-center border bg-light p-2 text-dark"><h5 className="text-center text-grey ">Riwayat Pesan Kosong</h5></div>}

                                </div>


                            </div>
                            <div className="col-md-6">

                                <div className="p-3 py-3">
                                    <div className="d-flex justify-content-between align-items-center experience  bg-info p-2 "><span className='h4'>Isi keranjang</span><span className="border px-3 p-1 add-experience"><i className="fa fa-plus"></i>&nbsp;Tambah</span></div><br />
                                    {dUserDetail.dokul_carts.length > 0 ? dUserDetail.dokul_carts.map((x, idx) => {
                                        const _product = dProducts.find(xp => xp.id == x.product_id)
                                        return <div key={idx} className="d-flex justify-content-between align-items-center border-bottom mb-1">
                                            <small className="p-2">{_product?.branch_id == 1 ? "JKT" : "BPN"}</small>
                                            <small className="p-2">{_product?.name}</small>

                                            <small className="ms-auto p-2">{_product?.dokul_products_skus[0].price.toLocaleString("ID-id", { style: "currency", currency: "IDR" })}</small>

                                        </div>
                                    }
                                    ) : <div className="d-flex justify-content-center align-items-center border bg-light p-2 text-dark"><h5 className="text-center text-grey ">Isi Keranjang Kosong</h5></div>}

                                </div>


                            </div>
                            <div className="col-md-6">

                                <div className="p-3 py-3">
                                    <div className="d-flex justify-content-between align-items-center experience  bg-info p-2 "><span className='h4'>Produk akses</span><button className="btn btn-xs btn-light border border-dark px-3 p-1 add-experience pointer" onClick={() => this.setState({ modalProdukKhusus: true })}><i className="fa fa-plus"></i>&nbsp;Create Produk Khusus</button><button className="btn btn-xs btn-light border border-dark px-3 p-1 add-experience pointer" onClick={() => this.setState({ modalAddProduk: true })}><i className="fa fa-plus"></i>&nbsp;Tambah</button></div><br />
                                    {userProducts.length > 0 ? userProducts.reduce((p, c) => {
                                        return p.some(y => y.product_id == c.product_id) ? p : [...p, c]
                                    }, []).map((x, idx) => {
                                        const _product = dProducts.find(xp => xp.id == x.product_id)
                                        return <div key={idx} className="d-flex justify-content-between align-items-center border-bottom mb-1">
                                            <small className="p-2">{x.dokul_product?.branch_id == 1 ? "JKT" : "BPN"}</small>
                                            <small className="p-2">{x.dokul_product?.name}</small>
                                            {/* {x.dokul_product.is_premium==1&&<small className="p-2">Premium product</small>} */}
                                            {/* <small className="ms-auto p-2">{_product?.dokul_products_skus[0].price.toLocaleString("ID-id", { style: "currency", currency: "IDR" })}</small> */}

                                        </div>
                                    }
                                    ) : <div className="d-flex justify-content-center align-items-center border bg-light p-2 text-dark"><h5 className="text-center text-grey "> Produk Akses  Kosong</h5></div>}

                                </div>

                            </div>
                            <div className="col-md-6">

                                <div className="p-3 py-3">
                                    <div className="d-flex justify-content-between align-items-center experience  bg-info p-2 "><span className='h4'>Voucher</span><button className="btn btn-xs btn-light border border-dark px-3 p-1 add-experience pointer" onClick={() => this.setState({ modalAddVoucher: true })}><i className="fa fa-plus"></i>&nbsp;Tambah</button></div><br />
                                    {dUserDetail.dokul_voucher_privates.length > 0 ? dUserDetail.dokul_voucher_privates.filter(x => !deletedVouchers.some(y => y == x.id)).map((x, idx) => {

                                        return <div key={idx} className={`row border-bottom ${x.branch_id == 1 ? "text-warning" : "text-success"}`}>
                                            <div className='p-2 col-4'>
                                                <small className="row p-2">{dUserDetail.id}{x.code} </small>
                                                {x.status != 3 && <>
                                                    {this.isExpired(x.expired_date) ? <small className="row text-danger">Expired: {moment(x.expired_date).format("DD, MM 'YY")}</small> :
                                                        <small className="row ">Expired: {moment(x.expired_date).format("DD, MM 'YY")}</small>}
                                                </>
                                                }
                                            </div>

                                            <small className="col-4  p-2">{x.amount.toLocaleString("ID-id", { style: "currency", currency: "IDR" })}</small>
                                            <small className="col-3  p-2">{vouchersStatus[x.status]}</small>
                                            {x.status != 3 && <div className="col-1  p-2"><button className='btn btn-light btn-xs' onClick={() => this.handleDeleteVoucher(x)} ><i className='fa fa-times' /></button></div>}
                                        </div>
                                    }
                                    ) : <div className="d-flex justify-content-center align-items-center border bg-light p-2 text-dark"><h5 className="text-center text-grey ">Voucer  Kosong</h5></div>}

                                </div>

                            </div>
                        </div>}
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default Profile
