import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import { useQuery, useMutation, readQuery, writeQuery, useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject, NetworkStatus } from '@apollo/react-hooks';
import { withApollo } from '../../../apollo'
import { GET_CATEGORIES, GET_TAGS, GET_DASHBOARD_DATA, GET_ORDERS, GET_7LAST_ORDER, GET_ORDER_STATUS, GET_ORDER_STATUSOK, GET_PRODUCTS, GET_ORDERS_NOPAGE, GET_CONFIGS } from '../../../constants/graph'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';
import { Pie, Doughnut, Bar, Line } from 'react-chartjs-2';
import {
    pieOptions,
    doughnutOption,
    lineOptions,
    buyOption,
    employeeData,
    employeeOptions
} from '../../../constants/chartData'
import Loading from '../../common/loading'
import Errorpage from '../../common/Errorpage'
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import id from 'date-fns/locale/id';
import moment from 'moment';
import { Badge, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { ExportToExcel } from '../order/exporttoexcel';
registerLocale('id', id)
setDefaultLocale('id');
const DetatailReport = (props) => {
    //const [data,setData]=useState(props.data)
    // const { loading:lProduct, data:dProduct, error:eProduct } = useQuery(GET_PRODUCTS);
    const [getData, { loading, data, error, fetchMore, refetch, networkStatus }] = useLazyQuery(GET_ORDER_STATUSOK, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: { option: "branch=2" }
    });
    const [getOrderData, { loading: loadingDetail, data: dataDetail, error: errorDetail }] = useLazyQuery(GET_ORDERS_NOPAGE);
    const { loading: lConf, data: dConf, error: eConf } = useQuery(GET_CONFIGS,);
    const chartRef = useRef(null);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date())
    const [graphType, setgraphType] = useState(2)
    const [branchSelected, setbranchSelected] = useState(0)
    const [selectedOrderStatus, setselectedOrderStatus] = useState([])
    const [selectedRange, setselectedRange] = useState(2)

    const [orderstatus, setorderstatus] = useState({ label: [] })
    const [selectedProduct, setselectedProduct] = useState(0)
    const [modalProduk, setmodalProduk] = useState(false)
    const [paymentData, setpaymentData] = useState({ payMethod: [], payment: [] });


    const getDetailReport = () => {
        if (!selectedProduct || !startDate || !endDate) return
        let paydata
        if (selectedProduct != 3) {
            paydata = {
                payment: dConf.configs.find(br => br.id == selectedProduct).dokul_payments,
                payMethod: dConf.configs.find(br => br.id == selectedProduct).dokul_paymethods
            }
        } else {
            paydata = {
                payment: dConf.configs.find(br => br.id == 2).dokul_payments,
                payMethod: dConf.configs.find(br => br.id == 2).dokul_paymethods
            }
        }
        setpaymentData(paydata)
        if (selectedRange == 1) {
            
            getOrderData({
                variables: {
                    dateFrom: moment(startDate).startOf('month').format("YYYY-MM-DD"),
                    dateTo: moment(endDate).endOf("month").format("YYYY-MM-DD HH:mm:ss"),
                    statusText: 5,
                    branchid: selectedProduct==3?2:selectedProduct,
                    premiumOrder:selectedProduct==3?'all':0

                }
            })
        } else { 
        getOrderData({
            variables: {
                dateFrom: moment(startDate).format("YYYY-MM-DD"),
                dateTo: moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                statusText: 5,
                branchid: selectedProduct==3?2:selectedProduct,
                premiumOrder:selectedProduct==3?'all':0

            }
        })
    }

}
if (loading || loadingDetail) return <Loading show={true} />;
if (error) return <Errorpage errorCode={500} errorMessage={`${error}`} />;
return (
    <div className="col-xl-12 xl-100 text-dark">
        {/* <Modal isOpen={modalProduk} toggle={() => setmodalProduk(false)} centered size="lg">
                <ModalHeader className="bg-primary" toggle={() => setmodalProduk(false)}>Add product statistic</ModalHeader>
                <ModalBody className="bg-light text-dark">
                    {dProduct&&dProduct.products.map((x,idx )=> <FormGroup key={idx} check>
                        <Label check>
                            <Input type="checkbox" onClick={(e)=>{
                                let _currentSelectedProduct=[...selectedProduct]
                                if(e.target.checked){
                                    _currentSelectedProduct.push(x)
                                }
                                setselectedProduct(_currentSelectedProduct)
                            }} />{' '}
                            {x.name}
                        </Label>
                    </FormGroup>)}
                </ModalBody>

            </Modal> */}
        <div className="row">
            <div className="col">
                {/* <div className="d-flex flex-row border">
                        <h6 className="title m-2">Dokterkulitku</h6>
                        <div className="form-check p-2 ms-auto">
                            <input className="form-check-input" type="checkbox" name="branch" id="branch" checked={branchSelected == 1} onClick={() => setbranchSelected(1)} />
                            <label className="form-check-label" for="branch">Jakarta</label>
                        </div>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="checkbox" name="branch" id="branch" checked={branchSelected == 2} onClick={() => setbranchSelected(1)} />
                            <label className="form-check-label" for="branch">Balikpapan</label>
                        </div>

                    </div> */}
                <div className="d-flex flex-column border">
                    <h6 className="title  m-2">Filter</h6>
                    <div className="d-flex  border">
                        <h6 className="title  m-2">ORDER</h6>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={selectedProduct == 1} onClick={() => setselectedProduct(1)} />
                            <label className="form-check-label" for="byproduk">Jakarta</label>
                        </div>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={selectedProduct == 2} onClick={() => setselectedProduct(2)} />
                            <label className="form-check-label" for="byproduk">Balikpapan</label>
                        </div>
                        <div className="form-check m-2">
                            <input className="form-check-input" type="radio" name="byproduk" id="byproduk" checked={selectedProduct == 3} onClick={() => setselectedProduct(3)} />
                            <label className="form-check-label" for="byproduk">Premium</label>
                        </div>

                    </div>


                </div>


                {/* <div className="d-flex flex-column border-end border-start">

                    <h6 className="title m-2">Data by</h6>
                    <div className="form-check  m-2">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectedRange == 2} onClick={() => setselectedRange(2)} />
                        <label className="form-check-label" for="flexRadioDefault1">By Date</label>
                    </div>
                    <div className="form-check m-2">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectedRange == 1} onClick={() => setselectedRange(1)} />
                        <label className="form-check-label" for="flexRadioDefault1">By Month</label>
                    </div>

                </div> */}

                {selectedRange == 19 && <div class="input-group pb-2 d-flex  border-end border-start border-bottom">
                    <span class="input-group-text" id="basic-addon1">Start</span>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                    />
                    <span class="input-group-text" id="basic-addon1">End</span>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setendDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                    />
                </div>}
                {selectedRange == 2 && <div class="input-group p-2  d-flex  border-end border-start border-bottom">
                    <span class="input-group-text" id="basic-addon1">Start</span>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd MMMM yyyy"
                    />
                    <span class="input-group-text" id="basic-addon1">End</span>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setendDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        filterDate={(date) => {
                            const dif = moment(startDate).diff(moment(date), 'months');
                            return dif == 0
                        }}
                        dateFormat="dd MMMM yyyy"
                    />
                </div>}
                <div class=" p-0 mb-3 d-flex  border-end border-start border-bottom">

                    <button disabled={loadingDetail} className='btn btn-xs btn-primary m-2' onClick={() => getDetailReport()}>Load data</button>
                    {dataDetail && <ExportToExcel configData={paymentData} tipe={4} apiData={dataDetail.orders || []} fileName={`ORDER_REPORT_${selectedProduct==1?'JKT':'BPN'}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`} />}
                </div>

            </div>



        </div>


        <div className="order-graph xl-space">

        </div>
    </div >

)
}
export default withApollo(DetatailReport)
//export default ordertoday
