import React, { useState, Fragment,useMemo } from 'react'
import Breadcrumb from '../../common/breadcrumb';
//import datanya from '../../assets/data/physical_list';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../../apollo'
import { useQuery, useMutation, readQuery, writeQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import OrderList from './order.list';
import OrderRefund from "./order.refund"
import OrderDetail from './order.detail'
import TransactionList from '../transactions-sales'

import ReactTable from 'react-table';
import { GET_CATEGORIES, GET_TAGS, GET_PRODUCTS, GET_ORDERS_REFUND } from '../../../constants/graph'
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;
const UPDATE_ORDER = gql`
  mutation UpdateOrder($id: String!, $type: String!) {
    updateOrder(id: $id, body: $type) 
    @rest(
      type: "Orders"
      path: "api/order"
      method: "PUT"
      bodyKey: "body"
    ){
      __typename
      id 
            admin_fee
            billing_address_id
            branch_id
            coupon_discount
            coupon_type
            coupon_used
            created_date
            current_grade
            grand_total
            is_usecoupon
            is_usepoint
            layanan_kurir
            modify_by
            modify_date
            ongkir
            order_status
            order_status_text
            payment_method
            payment_method_text
            point_earned
            point_used
            shipping_address_id
            total
            total_discount
            unix_payment
            user_id
            dokul_address,
            dokul_orders_items  
            payment_status,
            bukti_bayar,
            nomor_resi
    }
  }
`;
const UPDATE_ORDER_BULK = gql`
  mutation UpdateOrderBulk($id: String!, $type: String!) {
    updateOrderBulk(id: $id, body: $type) 
    @rest(
      type: "Orders"
      path: "api/order/bulk"
      method: "PUT"
      bodyKey: "body"
    ){
      listId
      order_status
      order_status_text
    }
  }
`;
const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $email: String!
    $password: String!
  ) {
    orders(input: "Foo", body: $email)
    @rest(
      type: "Orders"
      path: "api/order?size=1"
      method: "GET"
      bodyKey: "body"
    ) {
    orders @type(name: "Order") { 
        id 
        admin_fee 
        is_usecoupon
        is_usepoint
        created_date
        grand_total
        total
        order_status
        order_status_text
        payment_method_text
        payment_method,
        dokul_address,
        dokul_orders_items             
      } ,
    currentPage
    totalItems
    totalPages
  }
  }
`;

// const GET_CATEGORIES = gql`query categories {
//     categories @rest(type: "Category",path: "api/products/categories") {
//         id
//         name
//         description
//     }
//   }
// `;
// const GET_TAGS = gql`query tags {
//     tags @rest(type: "Tag",path: "api/products/tags") {
//         id,
//         name,
//         description
//     }
//   }
// `;
const Orders = ({ match }) => {
    const columns = useMemo(
        () => [
          {
            // first group - TV Show
            Header: "REFUND ITEM'S",
            // First group columns
            columns: [
              {
                Header: "Order ID",
                accessor: "dokul_order.id"
              },
              {
                Header: "Customer",
                accessor: "dokul_order.dokul_user.display_name"
              },
              {
                Header: "Item",
                accessor: "dokul_orders_item.product_name"
              },
              {
                Header: "Jumlah",
                accessor: "dokul_orders_item.quaintiy"
              },
              {
                Header: "Harga",
                accessor: "dokul_orders_item.price"
              },
              {
                Header: "total",
                accessor: "dokul_orders_item.subtotal"
              },
              {
                Header: "Status",
                accessor: "refund_status"
              }
            ]
          },
        //   {
        //     // Second group - Details
        //     Header: "Details",
        //     // Second group columns
        //     columns: [
        //       {
        //         Header: "Item",
        //         accessor: "dokul_orders_item.product_name"
        //       },
        //       {
        //         Header: "Jumlah",
        //         accessor: "dokul_orders_item.quaintiy"
        //       },
        //       {
        //         Header: "Harga",
        //         accessor: "dokul_orders_item.price"
        //       },
        //       {
        //         Header: "total",
        //         accessor: "dokul_orders_item.subtotal"
        //       },
        //       {
        //         Header: "Status",
        //         accessor: "refund_status"
        //       }
        //     ]
        //   }
        ],
        []
      );
    




    const updateCache = (cache, { data }) => {

        // Fetch the todos from the cache
        // const existingTodos = cache.readQuery({
        //   query: GET_ORDERS
        // });
        // console.log("HOLAAAAAA")
        //console.log(data)
        const fragmentResult = cache.writeFragment({
            id: `Order:${data.updateOrder.id}`,
            fragment: gql`
          fragment order on Order{
            id 
            admin_fee
            billing_address_id
            branch_id
            coupon_discount
            coupon_type
            coupon_used
            created_date
            current_grade
            grand_total
            is_usecoupon
            is_usepoint
            layanan_kurir
            modify_by
            modify_date
            ongkir
            order_status
            order_status_text
            payment_method
            payment_method_text
            point_earned
            point_used
            shipping_address_id
            total
            total_discount
            unix_payment
            user_id
            dokul_address,
            dokul_orders_items  
            payment_status,
            bukti_bayar,
            nomor_resi
          }
        `,
            data: data.updateOrder
            //  {
            //   __typename: 'Order',
            //   order_status_text: '1952',
            //   total:1
            // }
        });
        // console.log(fragmentResult)
        // Add the new todo to the cache
        // const newTodo = data.insert_todos.returning[0];
        // cache.writeQuery({
        //   query: GET_ORDERS,
        //   data: {orders: [{}, ...existingTodos.orders.orders]}
        // });
    };
    const updateCacheBulk = (cache, { data }) => {

        // Fetch the todos from the cache
        const existingTodos = cache.readQuery({
            query: GET_ORDERS_REFUND
        });
        let _et2 = { ...existingTodos }
        let _et = { ..._et2.orders }

        let updatesOrder = _et.orders.map(ud => {
            let _ud = { ...ud }
            if (data.updateOrderBulk.listId.includes(_ud.id)) {
                _ud.order_status = data.updateOrderBulk.order_status
                _ud.order_status_text = data.updateOrderBulk.order_status_text

            }
            return _ud
        })
        _et.orders = updatesOrder
        _et2.orders = _et
        cache.writeQuery({
            query: GET_ORDERS_REFUND,
            data: _et2
        });
    };
    const [updateOrder] = useMutation(UPDATE_ORDER, { update: updateCache });
    const [updateOrderBulk] = useMutation(UPDATE_ORDER_BULK, { update: updateCacheBulk });
    const { loading, data, error, fetchMore } = useQuery(GET_ORDERS_REFUND, {
        variables: {
            page: 0,
            size: 10,
        }
    });


   // const client = useApolloClient();
    // const { datal } = useQuery(IS_LOGGED_IN);
    // const [datas, setData] = useState(datanya)
    let order;
    const [orders] = useMutation(LOGIN_MUTATION, {

        update(cache, { data: { orders } }) {


            cache.writeQuery({
                query: GET_ORDERS_REFUND,
                variables: {
                    page: 0,
                    size: 10

                },
                data: {
                    orders: []
                }
            });
        }


    });
    const getNextData = (cpagesize, rpage, id, date, displayname) => {


        return fetchMore({
            variables: {
                page: rpage,
                size: cpagesize,
                id: id,
                date: date,
                displayname: displayname

            }
        })

    }



    if (loading) return "Loading"
    return (
        <Fragment>
            <ReactTable 
                                        data={data.ordersRefund.ordersRefund}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className={"-striped -highlight"}
                                        // showPagination={pagination}
                                        // manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                        // pages={pages} // Display the total number of pages
                                        // loading={loading} // Display the loading overlay when we need it
                                        // onFetchData={this.fetchData} // Request new data when things change
                                        // sorted={this.state.sorted}
                                        // onSortedChange={(newSort, column) => {
                                        //     this.setState({ sorted: newSort });
                                        // }}
                                    />


        </Fragment>
    )

}

export default withApollo(Orders)
