import React, { useState, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import { Edit, Trash2 } from 'react-feather'
import { withApollo } from '../../apollo'
import { useQuery, useMutation, readQuery } from '@apollo/react-hooks';
import { useApolloClient, NormalizedCacheObject } from '@apollo/react-hooks';
import history from '../../history';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import {GET_SLOT_KONSULTASI,ADD_SLOTS,ADD_ROWS, GET_PRODUCTS, GET_PRODUCTS_SIMPLE} from '../../constants/graph'
import RobotCanvas from './RobotCanvas';


const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

// const GET_KONSULTASI = gql`query konsultasi {
  
//     slots @rest(type: "Slots",path: "api/konsultasi/slot?page=0&size=10") {
//       data @type(name: "Slot") { 
//         id
//         create_date
//         sold_date
//         id_dokter
//         id_user
//         payment
//         payment_text
//         payment_status
//         status
//         description
//         dokul_dokter
//         dokul_user
                 
//       } ,
//     currentPage
//     totalItems
//     totalPages
      
//   }
//   dokters @rest(type: "Dokter",path: "api/dokter") {
//     id
//     dokul_dokter_konsultasis
//     fullname
//     picture
//     picture
//     available
// }
// kliniks @rest(type: "Klinik",path: "api/dokter/klinik") {
//     id
//     dokul_dokter_konsultasis
//     address
//     banner
//     branchid
//     name
//     tlp
// }
    
//   }
// `;

// const GET_CATEGORIES = gql`query categories {
//     categories @rest(type: "Category",path: "api/products/categories") {
//         id,
//         name,
//         description
//     }
//   }
// `;
// const GET_TAGS = gql`query tags {
//     tags @rest(type: "Tag",path: "api/products/tags") {
//         id,
//         name,
//         description
//     }
//   }
// `;


const Robot = ({ match }) => {
    const { loading: loadingProduct, data: listProduct, error: errorProduct } = useQuery(GET_PRODUCTS_SIMPLE,{
      variables:{
        brand:'all',
        textSearch:'all'
      }
    });
    if (!Cookies.get('signedin')) {
        history.push('/');
    }
   if(!listProduct) return "Loading"
    
    return (
        <Fragment>
           
            <RobotCanvas  match={match}  listProduct={listProduct.productssimple}/>
        </Fragment>
    )

}

export default withApollo(Robot)
