import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { Collapse, Button, CardBody, Card, Input } from 'reactstrap';


export class Transactions_sales extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: this.props.data.orders.totalPages,
            loading: false,
            checkedValues: [],
            myData: this.props.data.orders.orders,
            isOpen: false,
            monthSelected: 0,
            dateSelected: 0,
            selectedDate: Date()
        }
        this.fetchData = this.fetchData.bind(this);
    }
    async fetchData(state, instance) {
        console.log(state)
        let id;
        let date;
        let balik;

        state.filtered.forEach(element => {
            if (element.id == 'id') id = element.value
            if (element.id == 'created_date') {
                var d = moment(element.value)
                var od = moment(this.state.selectedDate)
                if (d.date() == od.date())
                    balik = true;
                date=element.value

            }

        })
        if (balik) return

        const t = await this.props.getNextData(state.pageSize, state.page, id, date,)
        console.log(t)
        this.setState({
            myData: t.data.orders.orders,
            pages: t.data.orders.totalPages,
            selectedDate:date
        })

        // console.log(t)
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        //this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        //   requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
        //     res => {
        //       // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        //       this.setState({
        //         // data: res.rows,
        //         // pages: res.pages,
        //         loading: false
        //       });
        //     }
        //   );
    }
    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };
    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    openFilter = (i) => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { myData, pages, loading, isOpen, selectedDate } = this.state
        const columns = [];


        columns.push(
            {
                Header: <b>{this.Capitalize("Order ID")}</b>,
                accessor: "id",
                filterable: true,
                Cell: (data) => {
                    return '#' + data.original.id

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Status")}</b>,
                accessor: "order_status",
                Cell: (data) => {
                    return <span className="badge badge-success">{data.original.order_status_text}</span>

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("order date")}</b>,
                accessor: "created_date",
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <Input
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                        onChange={event => {
                            console.log(event.target)
                            onChange(event.target.value)
                        }
                        }
                        value={filter ? filter.value : ""}
                    />
                    //             <DatePicker 
                    //             selected={selectedDate}
                    // onChange={date =>this.changeDate(date)}
                    // dropdownMode="select"
                    // />
                ),
                Cell:(row)=>{
                    return moment(row.original.created_date).format("YYYY-MM-DD HH:mm:ss")
                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Total")}</b>,
                accessor: "grand_total",
                Cell: (data) => {
                    return data.original.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                },
                style: {
                    textAlign: 'center'
                }
            });
        columns.push(
            {
                Header: <b>{this.Capitalize("Payment")}</b>,
                accessor: "payment_method_text",
                Cell: (data) => {
                    const pay = data.original.payment_method_text.toLowerCase()
                    return <span className={`badge badge-${pay.includes('bca') ? 'info' : pay.includes('mandiri') ? 'warning' : pay.includes('qris') ? 'primary' : 'light'}`}>{`${pay.includes('bca') ? 'BCA' : pay.includes('mandiri') ? 'MANDIRI' : pay.includes('qris') ? 'QRIS' : pay}`}</span>

                },
                style: {
                    textAlign: 'center'
                }
            });


        if (multiSelectOption == true) {
            columns.push(
                {
                    Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                        onClick={
                            (e) => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    this.handleRemoveRow()
                            }}>Delete</button>,
                    id: 'delete',
                    accessor: str => "delete",
                    sortable: false,
                    style: {
                        textAlign: 'center'
                    },
                    Cell: (row) => (
                        <div>
                            <span >
                                <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                    onChange={e => this.selectRow(e, row.original.id)} />
                            </span>
                        </div>
                    ),
                    //accessor: key,
                    style: {
                        textAlign: 'center'
                    }
                }
            )
        } else {
            columns.push(
                {
                    Header: <b>Action</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    Cell: (row) => (
                        <div>
                            <span onClick={() => {
                                if (window.confirm('Are you sure you wish to delete this item?')) {
                                    let data = myData;
                                    data.splice(row.index, 1);
                                    this.setState({ myData: data });
                                }
                                toast.success("Successfully Deleted !")

                            }}>
                                <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }}
                                ></i>
                            </span>



                            <span>
                                <Link
                                    to={`/sales/orders/detail/${row.original.id}`}
                                >
                                    <i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)', cursor: 'pointer' }}></i>
                                </Link>
                            </span>
                        </div>
                    ),
                    style: {
                        textAlign: 'center'
                    },
                    sortable: false
                }
            )
        }
        return (
            <Fragment>
               
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Transaction Details</h5>
                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="transactions">
                                    <ReactTable
                                        data={myData}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className={"-striped -highlight"}
                                        showPagination={pagination}
                                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                        pages={pages} // Display the total number of pages
                                        loading={loading} // Display the loading overlay when we need it
                                        onFetchData={this.fetchData} // Request new data when things change
                                        sorted={this.state.sorted}
                                        onSortedChange={(newSort, column) => {
                                            this.setState({ sorted: newSort });
                                        }}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Transactions_sales
