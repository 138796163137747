import React, { Component, Fragment } from 'react'

export class Branch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBranch: props.selectedBranch,
        }
    }
    componentDidMount(){
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        if (user.dokul_user_roles.some(x => x.role_id == 5)) {
            this.setState({selectedBranch:3},()=>this.props.setselectedBranch(3))
            
        } else if (false) {

        }
    }
    render() {
        const { selectedBranch } = this.state
        const {setselectedBranch}=this.props
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        return (
            <Fragment>
                {user.dokul_user_roles.some(x => x.role_id == 5) && <div className="d-flex flex-row mb-5">

                    <div className={`p-2 pointer ${selectedBranch == 1 ? 'bg-primary' : ''}`} onClick={() => {
                        this.setState({selectedBranch:1})
                        setselectedBranch(1)
                        }}>JAKARTA</div>
                    <div className={`p-2  pointer ${selectedBranch == 2 ? 'bg-primary' : ''}`} onClick={() =>  {
                        this.setState({selectedBranch:2})
                        setselectedBranch(2)
                        }}>BALIKPAPAN</div>
                    <div className={`p-2  pointer ${selectedBranch == 3 ? 'bg-primary' : ''}`} onClick={() =>  {
                        this.setState({selectedBranch:3})
                        setselectedBranch(3)
                        }}>ALL</div>
                </div>}
            </Fragment>
        )
    }
}

export default Branch
